export const ConfigConstants = {
    MODULES: [
        {
            code: 'MODULE_SYS',
            type: 'systemadministration'
        },
        {
            code: 'MODULE_RVN_MANAGEMENT',
            type: 'revenuemanagement'
        },
        {
            code: 'MODULE_PA_MANAGEMENT',
            type: 'paymentmanagement'
        },
        {
            code: 'MODULE_GL',
            type: 'generalledger'
        },
        {
            code: 'MODULE_CO_MANAGEMENT',
            type: 'contractmanagement'
        },
        {
            code: 'MODULE_EX_MANAGEMENT',
            type: 'expendituremanagement'
        },
        {
            code: 'MODULE_EMP_MANAGEMENT',
            type: 'employeemanagement'
        },
        {
            code: 'MODULE_IA_MASTERS',
            type: 'masters'
        },
        {
            code: 'MODULE_SAAP',
            type: 'saap'
        },
        {
            code: 'MODULE_AAPE',
            type: 'aape'
        },
        {
            code: 'MODULE_REPORTS_AND_DASHBOARDS',
            type: 'reportsanddashboards'
        }
    ],
    SUB_MODULES: {
        systemadministration: [
            {
                code: 'SUB_MODULE_SYS_USER_MANAGEMENT',
                type: 'USER_MANAGEMENT'
            },
            {
                code: 'SUB_MODULE_SYS_COA',
                type: 'CHART_OF_ACCOUNTS'
            },
            {
                code: 'SUB_MODULE_SYS_MASTERS',
                type: 'COMMON_MASTERS'
            },
            {
                code: 'SUB_MODULE_SYS_WF_CONFIG',
                type: 'WORKFLOW_CONFIGURATION'
            },
            {
                code: 'SUB_MODULE_SYS_GV_CONFIG',
                type: 'GLOBAL_CONFIG'
            }
        ],

        revenuemanagement: [
            {
                code: 'SUB_MODULE_RVN_CHALLANS',
                type: 'GENERATE_CHALLANS'
            },
            {
                code: 'SUB_MODULE_RVN_CASHBOOKS',
                type: 'COLLECTION_AND_CASH_BOOKS'
            },
            {
                code: 'SUB_MODULE_RVN_VOUCHER',
                type: 'DEPOSIT_VOUCHER'
            },
            {
                code: 'SUB_MODULE_RVN_REPORTS',
                type: 'REPORTS'
            },
            {
                code: 'SUB_MODULE_RVN_MASTERS',
                type: 'MASTER_CONFIGURATION'
            },
            {
                code: 'SUB_MODULE_RVN_NON_FMIS',
                type: 'NON_FMIS'
            }
        ],
        paymentmanagement: [
            {
                code: 'SUB_MODULE_PA_INSTRUCTIONS',
                type: 'GENERATE_PAYMENT_INSTRUCTIONS'
            },
            {
                code: 'SUB_MODULE_PA_CHEQUE',
                type: 'CHEQUE_MANAGEMENT'
            }
        ],

        generalledger: [
            {
                code: 'SUB_MODULE_GL_MASTERS',
                type: 'GL_MASTER'
            },
            {
                code: 'SUB_MODULE_GL_JOURNALS',
                type: 'JOURNAL_LEDGER'
            }
        ],

        contractmanagement: [
            {
                code: 'SUB_MODULE_CO_SUPPIER',
                type: 'SUPPLIER_MANAGEMENT'
            },
            {
                code: 'SUB_MODULE_COC_MANAGEMENT',
                type: 'CONTRACT_MANAGEMENT'
            },
            {
                code: 'SUB_MODULE_CO_MASTERS',
                type: 'MASTERS'
            }
        ],

        expendituremanagement: [
            {
                code: 'SUB_MODULE_EX_BILLS',
                type: 'BILLS_MANAGEMENT'
            },
            {
                code: 'SUB_MODULE_EX_MASTERS',
                type: 'MASTER_CONFIGURATION'
            }
        ],

        employeemanagement: [
            {
                code: 'SUB_MODULE_EMP_MANAGEMENT',
                type: 'EMPLOYEE_MANAGEMENT'
            },
            {
                code: 'SUB_MODULE_EMP_MASTERS',
                type: 'EMPLOYEE_MASTERS'
            }
        ],
        masters: [
            {
                code: 'SUB_MODULE_GLOBAL_MASTERS',
                type: 'GLOBAL_MASTERS'
            },
            {
                code: 'SUB_MODULE_AUDIT_UNIVERSE_MANAGEMENT',
                type: 'AUDIT_UNIVERSE_MANAGEMENT'
            },
            {
                code: 'SUB_MODULE_RESOURCE_MANAGEMENT',
                type: 'RESOURCE_MANAGEMENT'
            }
        ],
        saap: [
            {
                code: 'SUB_MODULE_STRATEGIC_AUDIT_PLAN',
                type: 'STRATEGIC_AUDIT_PLAN'
            },
            {
                code: 'SUB_MODULE_ANNUAL_AUDIT_PLAN',
                type: 'ANNUAL_AUDIT_PLAN'
            }
        ],
        aape: [
            {
                code: 'SUB_MODULE_AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
                type: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION'
            }
        ],
        reportsanddashboards: []
    }
};
