import React from 'react';
import { useQuery } from '@apollo/client';
import Schema from '../../../schema/AppSchema';
import FDataTable from '../../../components/fDataTable/FDataTable';
import TableSchema from '../TableSchema';
import TopCardComponent from '../../../components/topComponents/TopCardComponent';
import './Requests.scss';

let pendingVA = process.env.PUBLIC_URL + '/assets/icons/PendingVA.svg';
let ActiceContracts = process.env.PUBLIC_URL + '/assets/icons/ActiceContracts.svg';
let ActiceContractsSel = process.env.PUBLIC_URL + '/assets/icons/ActiceContractsSel.svg';
let TotalContractsSel = process.env.PUBLIC_URL + '/assets/icons/TotalContractsSel.svg';

const Requests = () => {
    const { loading } = useQuery(Schema.users, {
        variables: { orderBy: { name: 'desc' } },
        fetchPolicy: 'no-cache'
    });

    const actionTemplate = () => {
        return (
            <button className="a-btn a-btn-primary a-btn-md a-keyboard-hover-only-div" onClick={() => {}}>
                Tag Role
            </button>
        );
    };

    const cards = [
        {
            count: 1,
            label: 'Approved Requests',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Approved Requests',
            selected: true
        },
        {
            count: 1,
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            count: 1,
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        }
    ];

    const requestData = [
        {
            id: '988098',
            first_name: 'Name',
            mdl_name: 'Middle name',
            last_name: 'Last name',
            department: 'Deprt',
            office: 'H office',
            zone: ' zone ofc',
            role: 'Role na',
            notifying_manager: 'notifying manager',
            workflow_status: 'workflow_status',
            actions: actionTemplate
        },
        {
            id: '988098',
            first_name: 'Name',
            mdl_name: 'Middle name',
            last_name: 'Last name',
            department: 'Deprt',
            office: 'H office',
            zone: ' zone ofc',
            role: 'Role na',
            notifying_manager: 'notifying manager',
            workflow_status: 'workflow_status',
            actions: actionTemplate
        }
    ];

    if (requestData) {
        TableSchema.UR_Requests[TableSchema.UR_Requests.length - 1].body = actionTemplate;
    }

    return (
        <div className="page-body">
            <TopCardComponent cards={cards} topGridProps={{ buttonLabel: 'Create Contract' }} />
            <div className="page-header">
                <span className="light-color">Requests</span>
            </div>
            <div className="row">
                <div className="col-md-12 m-t-15">
                    {loading ? (
                        'Loading'
                    ) : (
                        <FDataTable
                            rows={requestData}
                            columns={TableSchema.UR_Requests}
                            noResizableColumns={true}
                            emptyMessage={'Requests are yet to be created'}
                            className="Users urRequest"
                        ></FDataTable>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Requests;
