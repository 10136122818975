import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'appkit-react';

const UpdatePasswordDialog = (props) => {
    const handleCancelUpdatePW = () => {
        props.handleCancelUpdatePW();
    };

    return (
        <div>
            {props && (
                <Modal visible={props.showModal} onCancel={handleCancelUpdatePW} className={props.className} backdropClosable={false}>
                    {props.FModalHeaderUpdatePW ? <ModalHeader>{props.FModalHeaderUpdatePW()}</ModalHeader> : ''}

                    <ModalBody>{props.FModalBodyUpdatePW && props.FModalBodyUpdatePW()}</ModalBody>
                    {props.FModalFooterUpdatePW ? <ModalFooter className="p-10">{props.FModalFooterUpdatePW()}</ModalFooter> : ''}
                </Modal>
            )}
        </div>
    );
};

export default UpdatePasswordDialog;
