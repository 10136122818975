import React from 'react';
import IsAuthorized from '../commons/IsAuthorized';
import { Switch, Redirect, Route } from 'react-router-dom';
import Auth from '../pages/auth/Auth';

const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
export default AuthGaurd;
