import React from 'react';
import { useHistory } from 'react-router-dom';
import { BreadCrumbItems } from './BreadCrumbItems';

const BreadCRumb = ({ items, homeRoute }) => {
    let history = useHistory();
    return (
        <div className="p-b-15 f-12">
            <span className="appkiticon icon-primary icon-home-fill pointer" onClick={() => history.push(homeRoute)} />
            <span className="primary-color">&nbsp;/&nbsp;</span>
            {items &&
                items.map((item, index) =>
                    index === items.length - 1 ? (
                        <span key={item.name}>{BreadCrumbItems[item.key] ? BreadCrumbItems[item.key] : item.name}</span>
                    ) : (
                        <span key={item.name} className="primary-color pointer" onClick={() => history.push(item.redirectTo)}>
                            {BreadCrumbItems[item.key] ? BreadCrumbItems[item.key] : item.name}&nbsp;/&nbsp;
                        </span>
                    )
                )}
        </div>
    );
};

export default BreadCRumb;
