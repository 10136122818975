import React, { lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import IsAuthorized from '../commons/IsAuthorized';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import Auth from '../pages/auth/Auth';

const ViewReportActuals = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/ReportActuals/ReportActuals/ViewReportActuals')
);

const ConflictOfInterest = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/conflictOfInterest/ConflictOfInterest')
);
const CreateCOI = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/conflictOfInterest/CreateCOI')
);
const AuditAssignmentStatus = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/auditAssignmentStatus/AuditAssignmentStatus')
);
const SummaryAuditableArea = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/understandingAuditableEntity/SummaryAuditableArea/SummaryAuditableArea'
    )
);
const AuditCommencement = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/auditCommencementForm/AuditCommencement')
);
const CreateAuditCommencement = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/auditCommencementForm/CreateAuditCommencement')
);

const CreateSAA = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/understandingAuditableEntity/SummaryAuditableArea/CreateSAA'
    )
);

const RequestList = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/RequestList/RequestList')
);

const CreateRequestList = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/RequestList/CreateRequestList')
);
const SummaryAuditSubject = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/understandingAuditableEntity/SummaryAuditSubject/SummaryAuditSubject'
    )
);
const CreateSAS = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/understandingAuditableEntity/SummaryAuditSubject/CreateSAS'
    )
);
const AuditScope = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/auditScope/AuditScope')
);
const InherentRisk = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/inherentRisk/InherentRisk')
);
const CreateInherentRisk = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/inherentRisk/CreateInheritRisk'
    )
);
const ControlAssessment = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/ControlAssessment/ControlAssessment'
    )
);
const CreateControlAssessment = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/ControlAssessment/CreateControlAssessment'
    )
);
const CreateAuditAssignmentPlan = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditAssignmentPlan/CreateAuditAssignmentPlan')
);
const AuditAssignmentPlan = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditAssignmentPlan/AuditAssignmentPlan')
);
const AuditClosureForm = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/ReportActuals/AuditClosureForm')
);

const AuditMeeting = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditMeeting/AuditMeeting')
);

const CreateAuditMeeting = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditMeeting/CreateAuditMeeting')
);

const EngagementReport = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/EngagementReports/engagementReports')
);

const CreateEngagementReport = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/EngagementReports/createEngagementReports')
);
const AuditHistory = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditHistory/AuditHistory')
);
const AuditObservations = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditObservations/AuditObservations')
);
const AuditTestResults = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditTestResults/AuditTestResults')
);

const CreateAuditTestResults = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditTestResults/CreateAuditTestResult')
);
const AuditFindings = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditFindings/AuditFindings')
);
const CreateAuditFindings = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditFindings/ScheduleTabs')
);
const CreateFollowUpAuditFindings = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditFindings/FollowUpTabs')
);
const CreateAuditClosureForm = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/ReportActuals/CreateClosureForm')
);
const CreateResidualRiskAssessment = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/ResidualRiskAssessment/CreateResidualRiskAssessment'
    )
);
const ResidualRiskAssessment = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/ResidualRiskAssessment/ResidualRiskAssessment'
    )
);
const AuditProgram = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AuditProgram/AuditProgram')
);

const CreateReportActuals = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/ReportActuals/ReportActuals/CreateReportActuals')
);

const ReportActuals = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/ReportActuals/ReportActuals/ReportActuals')
);

const ResidualRiskConclusion = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/RiskAssessmentConclusion/RiskAssessmentConclusion'
    )
);
const CreateResidualRiskConclusion = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/RiskAssessmentConclusion/CreateRiskAssessmentConclusion'
    )
);
const AAPExecution = lazy(() => import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/AAPExecution'));

const CreateAuditScope = lazy(() =>
    import(
        '../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/riskAssessmentAndControl/auditScope/CreateAuditScope'
    )
);

const ReportList = lazy(() => import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/Reports/ReportList'));
const CreateReport = lazy(() => import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/Reports/CreateReport'));

const GenerateReport = lazy(() =>
    import('../pages/internalAudit/auditAssignmentPlanningAndExecution/annualAuditPlan/GenerateReport/GenerateReport')
);

const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuditAssignmentPlanningAndExecutionItems = MenuItemsSchema.AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION_TAKER;
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const AuditAssignmentPlanningAndExecutionBreadCrmb = (props) => {
    let AuditAssignmentPlanningAndExecutionValues = [{ name: 'Audit Assignment Planning and Execution', redirectTo: '/aape' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[5] === 'view' || subPath[5] === 'clone' || subPath[5] === 'create' || subPath[5] === 'edit') {
        obj = [
            {
                name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1),
                redirectTo: '/aape/' + subPath[2],
                key: subPath[2]
            },
            {
                name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1),
                redirectTo: '/aape/' + subPath[2] + '/' + subPath[3],
                key: subPath[3]
            },
            { name: subPath[5].charAt(0).toUpperCase() + subPath[5].slice(1), redirectTo: '', key: subPath[5] }
        ];
    } else if (subPath[4]) {
        obj = [
            {
                name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1),
                redirectTo: '/aape/' + subPath[2],
                key: subPath[2]
            },
            {
                name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1),
                redirectTo: '/aape/' + subPath[2] + '/' + subPath[3],
                key: subPath[3]
            },
            { name: subPath[4].charAt(0).toUpperCase() + subPath[4].slice(1), redirectTo: '', key: subPath[4] }
        ];
    } else if (subPath[3]) {
        obj = [
            {
                name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1),
                redirectTo: '/aape/' + subPath[2],
                key: subPath[2]
            },
            {
                name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1),
                redirectTo: '/aape/' + subPath[2] + '/' + subPath[3],
                key: subPath[3]
            }
        ];
    } else {
        obj = [
            {
                name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1),
                key: subPath[2],
                redirectTo: '/aape/aapexecution'
            }
        ];
    }
    AuditAssignmentPlanningAndExecutionValues = [...AuditAssignmentPlanningAndExecutionValues, ...obj];
    return AuditAssignmentPlanningAndExecutionValues;
};
const AapeRouting = (props) => {
    let url = props.parentProps.location.pathname;
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={AuditAssignmentPlanningAndExecutionBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={AAPExecution}
                    path={`${props.path}/aapexecution`}
                    exact={
                        url === '/aape/aapexecution' ||
                        url.includes(`${props.path}/aapexecution/scheduleaudits`) ||
                        url.includes(`${props.path}/aapexecution/administrativeactivities`) ||
                        url.includes(`${props.path}/aapexecution/followup`) ||
                        url.includes(`${props.path}/aapexecution/auditregister`)
                            ? false
                            : true
                    }
                    name="Audit Assignment Planning and Execution"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditAssignmentStatus}
                    exact
                    path={`${props.path}/aapexecution/auditassignmentstatus`}
                    name="Audit Assignment Status"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditAssignmentStatus}
                    exact
                    path={`${props.path}/aapexecution/auditstatus`}
                    name="Audit Status"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConflictOfInterest}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest`}
                    name="Conflict of Interest View"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCOI}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest/create`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCOI}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest/edit/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCOI}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest/edit/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCOI}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest/view/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCOI}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest/view/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCOI}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest/view/:id/approved`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCOI}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest/edit/:id/approved`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditProgram}
                    path={`${props.path}/aapexecution/aapauditprogram`}
                    name="Audit Program"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditHistory}
                    path={`${props.path}/aapexecution/audithistory`}
                    name="Audit History"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditObservations}
                    path={`${props.path}/aapexecution/auditobservations`}
                    name="Audit Observations"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditCommencement}
                    exact
                    path={`${props.path}/aapexecution/auditcommencementform`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditCommencement}
                    exact
                    path={`${props.path}/aapexecution/auditcommencementform/create`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditCommencement}
                    exact
                    path={`${props.path}/aapexecution/auditcommencementform/edit/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditCommencement}
                    exact
                    path={`${props.path}/aapexecution/auditcommencementform/edit/:id/approved`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditCommencement}
                    exact
                    path={`${props.path}/aapexecution/auditcommencementform/edit/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditCommencement}
                    exact
                    path={`${props.path}/aapexecution/auditcommencementform/view/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditCommencement}
                    exact
                    path={`${props.path}/aapexecution/auditcommencementform/view/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditCommencement}
                    exact
                    path={`${props.path}/aapexecution/auditcommencementform/view/:id/approved`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={SummaryAuditableArea}
                    exact
                    path={`${props.path}/aapexecution/summaryauditablearea`}
                    name="Summary Auditable Area"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAA}
                    exact
                    path={`${props.path}/aapexecution/summaryauditablearea/create`}
                    name="Create Summary Auditable Area"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAA}
                    exact
                    path={`${props.path}/aapexecution/summaryauditablearea/edit/:id`}
                    name="Create Summary Auditable Area"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAA}
                    exact
                    path={`${props.path}/aapexecution/summaryauditablearea/edit/:id/draft`}
                    name="Create Summary Auditable Area"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAA}
                    exact
                    path={`${props.path}/aapexecution/summaryauditablearea/view/:id`}
                    name="Create Summary Auditable Area"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAA}
                    exact
                    path={`${props.path}/aapexecution/summaryauditablearea/view/:id/draft`}
                    name="Create Summary Auditable Area"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAA}
                    exact
                    path={`${props.path}/aapexecution/summaryauditablearea/edit/:id/approved`}
                    name="Create Summary Auditable Area"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAA}
                    exact
                    path={`${props.path}/aapexecution/summaryauditablearea/view/:id/approved`}
                    name="Create Summary Auditable Area"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={RequestList}
                    exact
                    path={`${props.path}/aapexecution/requestlist`}
                    name="Request List"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={RequestList}
                    exact
                    path={`${props.path}/aapexecution/requestlistresponse`}
                    name="Request List"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateRequestList}
                    exact
                    path={`${props.path}/aapexecution/requestlistresponse/create`}
                    name="Create Request List"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateRequestList}
                    exact
                    path={`${props.path}/aapexecution/requestlistresponse/edit/:id/draft`}
                    name="Edit Request List"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateRequestList}
                    exact
                    path={`${props.path}/aapexecution/requestlistresponse/view/:id/draft`}
                    name="Edit Request List"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateRequestList}
                    exact
                    path={`${props.path}/aapexecution/requestlistresponse/edit/:id/approved`}
                    name="Edit Request List"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateRequestList}
                    exact
                    path={`${props.path}/aapexecution/requestlistresponse/view/:id/approved`}
                    name="Edit Request List"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateRequestList}
                    exact
                    path={`${props.path}/aapexecution/requestlistresponse/view/:id`}
                    name="Edit Request List"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateRequestList}
                    exact
                    path={`${props.path}/aapexecution/requestlistresponse/edit/:id`}
                    name="Edit Request List"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={SummaryAuditSubject}
                    exact
                    path={`${props.path}/aapexecution/summaryauditsubject`}
                    name="Summary Audit Subject"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAS}
                    exact
                    path={`${props.path}/aapexecution/summaryauditsubject/create`}
                    name="Create Summary Audit Subject"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAS}
                    exact
                    path={`${props.path}/aapexecution/summaryauditsubject/edit/:id`}
                    name="Create Summary Audit Subject"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAS}
                    exact
                    path={`${props.path}/aapexecution/summaryauditsubject/edit/:id/approved`}
                    name="Create Summary Audit Subject"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAS}
                    exact
                    path={`${props.path}/aapexecution/summaryauditsubject/edit/:id/draft`}
                    name="Create Summary Audit Subject"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAS}
                    exact
                    path={`${props.path}/aapexecution/summaryauditsubject/view/:id`}
                    name="Create Summary Audit Subject"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAS}
                    exact
                    path={`${props.path}/aapexecution/summaryauditsubject/view/:id/approved`}
                    name="Create Summary Audit Subject"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateSAS}
                    exact
                    path={`${props.path}/aapexecution/summaryauditsubject/view/:id/draft`}
                    name="Create Summary Audit Subject"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />

                <AuthGaurd
                    {...props}
                    component={AuditScope}
                    exact
                    path={`${props.path}/aapexecution/auditscope`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditScope}
                    exact
                    path={`${props.path}/aapexecution/auditscope/create`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditScope}
                    exact
                    path={`${props.path}/aapexecution/auditscope/edit/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditScope}
                    exact
                    path={`${props.path}/aapexecution/auditscope/edit/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditScope}
                    exact
                    path={`${props.path}/aapexecution/auditscope/view/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditScope}
                    exact
                    path={`${props.path}/aapexecution/auditscope/view/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={InherentRisk}
                    exact
                    path={`${props.path}/aapexecution/inherentrisk`}
                    name="Inherit Risk"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateInherentRisk}
                    exact
                    path={`${props.path}/aapexecution/inherentrisk/create`}
                    name="Inherit Risk"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateInherentRisk}
                    exact
                    path={`${props.path}/aapexecution/inherentrisk/edit/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateInherentRisk}
                    exact
                    path={`${props.path}/aapexecution/inherentrisk/edit/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateInherentRisk}
                    exact
                    path={`${props.path}/aapexecution/inherentrisk/view/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateInherentRisk}
                    exact
                    path={`${props.path}/aapexecution/inherentrisk/view/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateInherentRisk}
                    exact
                    path={`${props.path}/aapexecution/inherentrisk/view/:id/approved`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateInherentRisk}
                    exact
                    path={`${props.path}/aapexecution/inherentrisk/edit/:id/approved`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={ControlAssessment}
                    exact
                    path={`${props.path}/aapexecution/controlassessment`}
                    name="Control Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateControlAssessment}
                    exact
                    path={`${props.path}/aapexecution/controlassessment/create`}
                    name="Create Control Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateControlAssessment}
                    exact
                    path={`${props.path}/aapexecution/controlassessment/edit/:id/draft`}
                    name="Edit Control Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateControlAssessment}
                    exact
                    path={`${props.path}/aapexecution/controlassessment/edit/:id`}
                    name="Edit Control Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateControlAssessment}
                    exact
                    path={`${props.path}/aapexecution/controlassessment/view/:id/draft`}
                    name="View Control Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateControlAssessment}
                    exact
                    path={`${props.path}/aapexecution/controlassessment/view/:id`}
                    name="View Control Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={ResidualRiskAssessment}
                    exact
                    path={`${props.path}/aapexecution/residualriskassessment`}
                    name="Residual Risk Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskAssessment}
                    exact
                    path={`${props.path}/aapexecution/residualriskassessment/create`}
                    name="Create Residual Risk Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskAssessment}
                    exact
                    path={`${props.path}/aapexecution/residualriskassessment/edit/:id/draft`}
                    name="Edit Residual Risk Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskAssessment}
                    exact
                    path={`${props.path}/aapexecution/residualriskassessment/edit/:id`}
                    name="Edit Residual Risk Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskAssessment}
                    exact
                    path={`${props.path}/aapexecution/residualriskassessment/view/:id/draft`}
                    name="View Residual Risk Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskAssessment}
                    exact
                    path={`${props.path}/aapexecution/residualriskassessment/view/:id`}
                    name="View Residual Risk Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskAssessment}
                    exact
                    path={`${props.path}/aapexecution/residualriskassessment/edit/:id/approved`}
                    name="View Residual Risk Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskAssessment}
                    exact
                    path={`${props.path}/aapexecution/residualriskassessment/view/:id/approved`}
                    name="View Residual Risk Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={ResidualRiskConclusion}
                    exact
                    path={`${props.path}/aapexecution/riskassessmentconclusion`}
                    name="Residual Risk Conclusion"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskConclusion}
                    exact
                    path={`${props.path}/aapexecution/riskassessmentconclusion/create`}
                    name="Create Residual Risk Assessment"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskConclusion}
                    exact
                    path={`${props.path}/aapexecution/riskassessmentconclusion/edit/:id/draft`}
                    name="Edit Residual Risk Conclusion"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskConclusion}
                    exact
                    path={`${props.path}/aapexecution/riskassessmentconclusion/edit/:id`}
                    name="Edit Residual Risk Conclusion"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskConclusion}
                    exact
                    path={`${props.path}/aapexecution/riskassessmentconclusion/view/:id/approved`}
                    name="Edit Residual Risk Conclusion"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskConclusion}
                    exact
                    path={`${props.path}/aapexecution/riskassessmentconclusion/edit/:id/approved`}
                    name="Edit Residual Risk Conclusion"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskConclusion}
                    exact
                    path={`${props.path}/aapexecution/riskassessmentconclusion/view/:id/draft`}
                    name="View Residual Risk Conclusion"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateResidualRiskConclusion}
                    exact
                    path={`${props.path}/aapexecution/riskassessmentconclusion/view/:id`}
                    name="View Residual Risk Conclusion"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditAssignmentPlan}
                    exact
                    path={`${props.path}/aapexecution/auditassignmentplan`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditAssignmentPlan}
                    exact
                    path={`${props.path}/aapexecution/auditassignmentplan/create`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditAssignmentPlan}
                    exact
                    path={`${props.path}/aapexecution/auditassignmentplan/view/:id/draft`}
                    name="Audit Assignment Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditAssignmentPlan}
                    exact
                    path={`${props.path}/aapexecution/auditassignmentplan/edit/:id/draft`}
                    name="Audit Assignment Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditAssignmentPlan}
                    exact
                    path={`${props.path}/aapexecution/auditassignmentplan/view/:id`}
                    name="Audit Assignment Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditAssignmentPlan}
                    exact
                    path={`${props.path}/aapexecution/auditassignmentplan/edit/:id`}
                    name="Audit Assignment Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditClosureForm}
                    exact
                    path={`${props.path}/aapexecution/auditclosureform`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditMeeting}
                    exact
                    path={`${props.path}/aapexecution/auditmeetings`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditMeeting}
                    exact
                    path={`${props.path}/aapexecution/auditmeetings/create`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditMeeting}
                    exact
                    path={`${props.path}/aapexecution/auditmeetings/edit/:id/draft`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditMeeting}
                    exact
                    path={`${props.path}/aapexecution/auditmeetings/edit/:id`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditMeeting}
                    exact
                    path={`${props.path}/aapexecution/auditmeetings/view/:id`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditMeeting}
                    exact
                    path={`${props.path}/aapexecution/auditmeetings/view/:id/draft`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditClosureForm}
                    exact
                    path={`${props.path}/aapexecution/auditclosureform/create`}
                    name="Audit Closure Form"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditClosureForm}
                    exact
                    path={`${props.path}/aapexecution/auditclosureform/edit/:id/draft`}
                    name="Audit Closure Form"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditClosureForm}
                    exact
                    path={`${props.path}/aapexecution/auditclosureform/view/:id/draft`}
                    name="Audit Closure Form"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditClosureForm}
                    exact
                    path={`${props.path}/aapexecution/auditclosureform/view/:id`}
                    name="Audit Closure Form"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditClosureForm}
                    exact
                    path={`${props.path}/aapexecution/auditclosureform/edit/:id`}
                    name="Audit Closure Form"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditMeeting}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest/edit/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditMeeting}
                    exact
                    path={`${props.path}/aapexecution/conflictofinterest/edit/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditTestResults}
                    exact
                    path={`${props.path}/aapexecution/audittestresults`}
                    name="Audit Test Results"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditTestResults}
                    exact
                    path={`${props.path}/aapexecution/audittestresults/create`}
                    name="Audit Test Results"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditTestResults}
                    exact
                    path={`${props.path}/aapexecution/audittestresults/edit/:id/draft`}
                    name="Audit Test Results"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditTestResults}
                    exact
                    path={`${props.path}/aapexecution/audittestresults/view/:id/draft`}
                    name="Audit Test Results"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditTestResults}
                    exact
                    path={`${props.path}/aapexecution/audittestresults/edit/:id`}
                    name="Audit Test Results"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditTestResults}
                    exact
                    path={`${props.path}/aapexecution/audittestresults/view/:id/approved`}
                    name="Audit Test Results"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditTestResults}
                    exact
                    path={`${props.path}/aapexecution/audittestresults/edit/:id/approved`}
                    name="Audit Test Results"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditTestResults}
                    exact
                    path={`${props.path}/aapexecution/audittestresults/view/:id`}
                    name="Audit Test Results"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/create`}
                    name="Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/view/:id`}
                    name="Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/view/:id/draft`}
                    name="Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/edit/:id`}
                    name="Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/edit/:id/approved`}
                    name="Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/view/:id/approved`}
                    name="Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/view/:id/existapproved`}
                    name="Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/edit/:id/draft`}
                    name="Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/view/:id/existdraft`}
                    name="Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateFollowUpAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/followup/create`}
                    name="Follow-Up Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateFollowUpAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/followup/view/:id`}
                    name="Follow-Up Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateFollowUpAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/followup/view/:id/approved`}
                    name="Follow-Up Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateFollowUpAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/followup/view/:id/existapproved`}
                    name="Follow-Up Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateFollowUpAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/followup/view/:id/draft`}
                    name="Follow-Up Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateFollowUpAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/followup/edit/:id`}
                    name="Follow-Up Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateFollowUpAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/followup/edit/:id/approved`}
                    name="Follow-Up Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateFollowUpAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/followup/edit/:id/draft`}
                    name="Follow-Up Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateFollowUpAuditFindings}
                    exact
                    path={`${props.path}/aapexecution/auditfindings/followup/view/:id/existdraft`}
                    name="Follow-Up Audit Finding"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={ReportActuals}
                    exact
                    path={`${props.path}/aapexecution/reportactuals`}
                    name="Report Actuals"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReportActuals}
                    exact
                    path={`${props.path}/aapexecution/reportactuals/create`}
                    name="Report Actuals"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReportActuals}
                    exact
                    path={`${props.path}/aapexecution/reportactuals/view/:id`}
                    name="Report Actuals"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReportActuals}
                    exact
                    path={`${props.path}/aapexecution/reportactuals/view/:id/draft`}
                    name="Report Actuals"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={ViewReportActuals}
                    exact
                    path={`${props.path}/aapexecution/reportactuals/view/`}
                    name="Report Actuals"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReportActuals}
                    exact
                    path={`${props.path}/aapexecution/reportactuals/edit/:id`}
                    name="Report Actuals"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReportActuals}
                    exact
                    path={`${props.path}/aapexecution/reportactuals/view/:id/approved`}
                    name="Report Actuals"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReportActuals}
                    exact
                    path={`${props.path}/aapexecution/reportactuals/edit/:id/approved`}
                    name="Report Actuals"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReportActuals}
                    exact
                    path={`${props.path}/aapexecution/reportactuals/edit/:id/draft`}
                    name="Report Actuals"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={EngagementReport}
                    exact
                    path={`${props.path}/aapexecution/engagementReports`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEngagementReport}
                    exact
                    path={`${props.path}/aapexecution/engagementReports/create`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEngagementReport}
                    exact
                    path={`${props.path}/aapexecution/engagementReports/edit/:id/draft`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEngagementReport}
                    exact
                    path={`${props.path}/aapexecution/engagementReports/edit/:id`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEngagementReport}
                    exact
                    path={`${props.path}/aapexecution/engagementReports/view/:id`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEngagementReport}
                    exact
                    path={`${props.path}/aapexecution/engagementReports/edit/:id/approved`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEngagementReport}
                    exact
                    path={`${props.path}/aapexecution/engagementReports/view/:id/approved`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEngagementReport}
                    exact
                    path={`${props.path}/aapexecution/engagementReports/view/:id/draft`}
                    name="Audit Scope"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={GenerateReport}
                    exact
                    path={`${props.path}/aapexecution/generatereport`}
                    name="Generate Report"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={ReportList}
                    exact
                    path={`${props.path}/aapexecution/reports`}
                    name="Report"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReport}
                    exact
                    path={`${props.path}/aapexecution/reports/create`}
                    name="Report"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReport}
                    exact
                    path={`${props.path}/aapexecution/reports/view/:id`}
                    name="Report"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReport}
                    exact
                    path={`${props.path}/aapexecution/reports/view/:id/draft`}
                    name="Report"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReport}
                    exact
                    path={`${props.path}/aapexecution/reports/view/`}
                    name="Report"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReport}
                    exact
                    path={`${props.path}/aapexecution/reports/edit/:id`}
                    name="Report"
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateReport}
                    exact
                    path={`${props.path}/aapexecution/reports/edit/:id/draft`}
                    name="Report "
                    menuItems={AuditAssignmentPlanningAndExecutionItems}
                />
            </Switch>
        </div>
    );
};
export default AapeRouting;
