import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../TableSchema';
import { Dropdown } from 'primereact/dropdown';
import Calendar from '../../../../components/Calendar/CalendarComponent';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import ConfirmationModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../Validation';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { getDate, uniqueId } from '../../../../util';

const JourneyDetails = (props) => {
    const [rows, setRows] = useState(props.formStateObj?.Journey_Details);
    let viewMode = TableSchema.JOURNEY_DETAILS.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.mode !== 'view' ? TableSchema.JOURNEY_DETAILS : viewMode;
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false,
        modesOfTravel: ['Rail', 'Road', 'Airways', 'Waterways']
    });
    let emptyObj = {
        departure_from: '',
        departure_date: '',
        arrival_date: '',
        arrival_to: '',
        claim_allowed: '',
        fare_paid: '',
        travel_class: '',
        ticket_no: '',
        travel_mode: '',
        distance: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setRows(props.formStateObj?.Journey_Details);
        // eslint-disable-next-line
    }, [props.formStateObj?.Journey_Details]);

    const fields = [
        { key: 'distance', validators: 'numbersOnly', maxLength: 6 },
        { key: 'ticket_no', validators: 'required', pattern: '^[a-zA-Z0-9-/ ]*$', maxLength: 30 },
        { key: 'travel_class', validators: 'required', pattern: '^[a-zA-Z0-9-. ]*$', maxLength: 20 },
        { key: 'claim_allowed', validators: 'numbersOnly', maxLength: 10 },
        { key: 'fare_paid', validators: 'numbersOnly', maxLength: 10 }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);

        if (err[key] || (key === 'distance' && parseInt(ev) <= 0) || (typeof ev === 'string' && ev.trim() === '')) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        if (key === 'departure_date') {
            currentDetailsObj.arrival_date = '';
        }
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const saveForm = () => {
        currentDetailsObj.departure_date = getDate(currentDetailsObj.departure_date);
        currentDetailsObj.arrival_date = getDate(currentDetailsObj.arrival_date);
        currentDetailsObj.departure_from = currentDetailsObj.departure_from?.trim();
        currentDetailsObj.arrival_to = currentDetailsObj.arrival_to?.trim();
        if (state.actionType === 'edit') {
            let indx = rows.findIndex((a) => a.id === editObj.id);
            rows[indx] = currentDetailsObj;
            setRows(rows);
        } else {
            currentDetailsObj.id = uniqueId(20);
            rows.push(currentDetailsObj);
            setRows(rows);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Journey_Details', rows);
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                currentDetailsObj[key] === undefined ||
                currentDetailsObj[key] === '' ||
                (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === '')
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            newObj['departure_date'] = new Date(newObj.departure_date);
            newObj['arrival_date'] = new Date(newObj.arrival_date);
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = rows.filter((a) => {
            return a.id !== currentDetailsObj.id;
        });
        setRows(filteredRows);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Journey_Details', filteredRows);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    const headerGroup = () => {
        return (
            <ColumnGroup>
                <Row>
                    <Column header={'Departure'} colSpan={2} className="width-300" />
                    <Column header={'Arrival'} colSpan={2} className="width-300" />
                    <Column header="Distance in Kms" field="distance" rowSpan={3} className="width-150" />
                    <Column header="Mode of Travel" field="travel_mode" rowSpan={3} className="width-150" />
                    <Column header="Ticket No." field="ticket_no" rowSpan={3} className="width-150" />
                    <Column header="Entitled Class" field="travel_class" rowSpan={3} className="width-150" />
                    <Column header="Fare Paid" field="fare_paid" rowSpan={3} className="width-180" />
                    <Column header="Claim Allowed" field="claim_allowed" rowSpan={3} className="width-150 text-right" />
                    {props.mode !== 'view' && <Column header="" rowSpan={3} className="width-80" />}
                </Row>
                <Row>
                    <Column header="Date & Time" sortable field="departure_date" />
                    <Column header="From" sortable field="departure_from" />
                    <Column header="Date & Time" sortable field="arrival_date" />
                    <Column header="To" sortable field="arrival_to" />
                </Row>
            </ColumnGroup>
        );
    };

    let footerGroup = () => {
        let claims = 0;
        let fare = 0;
        if (rows.length > 0) {
            rows.forEach((ro) => {
                claims = claims + parseInt(ro.claim_allowed);
                fare = fare + parseInt(ro.fare_paid);
            });
        }
        return (
            <ColumnGroup>
                <Row>
                    <Column footer="Total" colSpan={9} />
                    {/* <Column footer={isNaN(fare) ? '' : fare} footerStyle={{ textAlign: 'right' }} /> */}
                    <Column footer={isNaN(claims) ? '' : claims} footerStyle={{ textAlign: 'right' }} />
                    {props.mode !== 'view' && <Column />}
                </Row>
            </ColumnGroup>
        );
    };
    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Journey details are yet to be created"
                    className="secondary-table"
                    scrollable
                    headerColumnGroup={headerGroup()}
                    mode={props?.mode}
                    footerGroup={footerGroup()}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className=" m-t-30 m-b-30 redline">
                            <div className="primary-color line-style">
                                <b>Departure</b>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Date & Time{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Calendar
                                            className="w-100"
                                            readOnlyInput={true}
                                            placeholder="Select Date & Time"
                                            appendTo={document.self}
                                            value={currentDetailsObj.departure_date}
                                            onChange={(e) => handleChange(e.target.value, 'departure_date')}
                                            dateFormat="dd M yy"
                                            showIcon
                                            showTime
                                            hourFormat="24"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            From{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="departure_from"
                                            type="text"
                                            value={currentDetailsObj.departure_from}
                                            maxLength={25}
                                            onChange={(e) => handleChange(e, 'departure_from')}
                                            placeholder="Enter From"
                                            hasError={errors && errors.departure_from}
                                            errMsg="Please enter valid From"
                                            alphanumeric='true'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" m-t-30 m-b-30 redline">
                            <div className="primary-color line-style">
                                <b>Arrival</b>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Date & Time{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        {/* {props.mode !== 'view' && !props.recordID && ( */}
                                        <Calendar
                                            className="w-100"
                                            minDate={currentDetailsObj.departure_date}
                                            disabled={!currentDetailsObj.departure_date}
                                            readOnlyInput={true}
                                            placeholder="Select Date & Time"
                                            appendTo={document.self}
                                            value={currentDetailsObj.arrival_date}
                                            onChange={(e) => handleChange(e.target.value, 'arrival_date')}
                                            dateFormat="dd M yy"
                                            showIcon
                                            showTime
                                            hourFormat="24"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">To{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="arrival_to"
                                            type="text"
                                            value={currentDetailsObj.arrival_to}
                                            maxLength={25}
                                            onChange={(e) => handleChange(e, 'arrival_to')}
                                            placeholder="Enter To"
                                            hasError={errors && errors.arrival_to}
                                            errMsg="Please enter valid To"
                                            alphanumeric='true'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" m-t-30 m-b-30 redline">
                            <div className="primary-color line-style">
                                <b>Other Details</b>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Distance in Kms{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="distance"
                                            type="text"
                                            value={currentDetailsObj.distance}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e, 'distance')}
                                            placeholder="Enter Distance in Kms"
                                            hasError={errors && errors.distance}
                                            errMsg="Please enter valid Distance in Kms"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Mode of Travel{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="travel_mode"
                                            value={currentDetailsObj.travel_mode}
                                            onChange={(e) => handleChange(e.value, 'travel_mode')}
                                            appendTo={document.self}
                                            options={state.modesOfTravel}
                                            placeholder="Select Mode of Travel"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Ticket No{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="ticket_no"
                                            type="text"
                                            value={currentDetailsObj.ticket_no}
                                            maxLength={30}
                                            onChange={(e) => handleChange(e, 'ticket_no')}
                                            placeholder="Enter Ticket No"
                                            hasError={errors && errors.ticket_no}
                                            errMsg="Please enter valid Ticket No"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Entitled Class{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="travel_class"
                                            type="text"
                                            value={currentDetailsObj.travel_class}
                                            maxLength={20}
                                            onChange={(e) => handleChange(e, 'travel_class')}
                                            placeholder="Enter Entitled Class"
                                            hasError={errors && errors.travel_class}
                                            errMsg="Please enter valid Entitled Class"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Fare Paid{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="fare_paid"
                                            type="text"
                                            value={currentDetailsObj.fare_paid}
                                            maxLength={10}
                                            onChange={(e) => handleChange(e, 'fare_paid')}
                                            placeholder="Enter Fare Paid"
                                            hasError={errors && errors.fare_paid}
                                            errMsg="Please enter valid Fare Paid"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Claim Allowed{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="claim_allowed"
                                            type="text"
                                            value={currentDetailsObj.claim_allowed}
                                            maxLength={10}
                                            onChange={(e) => handleChange(e, 'claim_allowed')}
                                            placeholder="Enter Claim Allowed"
                                            hasError={errors && errors.claim_allowed}
                                            errMsg="Please enter valid Claim Allowed"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.mode !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (rows?.length === 0 ? '' : 'Another ') + 'Journey Details'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Journey"
                    showModal={state.showDeleteModal}
                    name={'Journey Details : ' + currentDetailsObj.departure_from + ' - ' + currentDetailsObj.arrival_to}
                />
            )}
        </div>
    );
};

export default JourneyDetails;
