import React from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { serverDate } from '../../util';
import './CalendarComponent.scss';

const CalendarComponent = (props) => {
    const months = [
        { label: 'January', value: 0, index: 0 },
        { label: 'February', value: 1, index: 1 },
        { label: 'March', value: 2, index: 2 },
        { label: 'April', value: 3, index: 3 },
        { label: 'May', value: 4, index: 4 },
        { label: 'June', value: 5, index: 5 },
        { label: 'July', value: 6, index: 6 },
        { label: 'August', value: 7, index: 7 },
        { label: 'September', value: 8, index: 8 },
        { label: 'October', value: 9, index: 9 },
        { label: 'November', value: 10, index: 10 },
        { label: 'December', value: 11, index: 11 }
    ];
    const dateTemplate = (date) => {
        const d = serverDate();
        const day = d.getDate();
        const month = d.getMonth();
        const year = d.getFullYear();
        const flag = date.day === day && month === date.month && year === date.year;
        const selectedDate = new Date(props.value);
        const selectedDateFlag =
            date.day === selectedDate.getDate() && selectedDate.getMonth() === date.month && selectedDate.getFullYear() === date.year;
        let style = {
            backgroundColor: '#cdd3da',
            color: '#474f57',
            fontWeight: 'bold',
            borderRadius: '50%',
            width: '2.6em',
            height: '2.6em',
            lineHeight: '2.6em',
            padding: 0
        };
        if (!flag || selectedDateFlag) {
            style = {};
        }

        return <span style={style}>{date.day}</span>;
    };
    const monthNavigatorTemplate = (e) => {
        return (
            <Dropdown
                value={e.value}
                options={months}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                className="p-r-10"
            />
        );
    };
    const yearNavigatorTemplate = (e) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                className="p-ml-2"
            />
        );
    };
    return (
        <Calendar
            {...props}
            viewDate={serverDate()}
            dateTemplate={dateTemplate}
            monthNavigatorTemplate={props.monthNavigator && monthNavigatorTemplate}
            yearNavigatorTemplate={props.yearNavigator && yearNavigatorTemplate}
        />
    );
};
export default CalendarComponent;
