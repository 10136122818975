import React from 'react';
import ConfirmationModal from '../components/actionsComponent/confirmationModal';
import { eventClient } from '../apollo';
import Schema from '../schema/AppSchema';
import { useMutation, useLazyQuery } from '@apollo/client';

const RecallButton = (props) => {
    const [CanRecall] = useLazyQuery(Schema.wf_can_recall, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        onCompleted: (response) => {
            if (response?.wf_can_recall?.source_id) {
                props.handleCalRecallStatus(response.wf_can_recall?.is_inter_office);
            }
        },

    });

    const [ProceedToRecall] = useMutation(Schema.recall_next_step, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                props.growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.COMMONS.RecallAction
                });
                props.handleLoader(false);
                props.setCustomizedRows();
            }
        },
        onError: () => {
            props.handleLoader(false);
        }
    });

    const onClickRecall = () => {
        props.onClickRecall(props.rowData);
        CanRecall({ variables: { source_id: props.rowData.id } });
    };
    const handleContinue = (obj, otherState) => {
        props.handleLoader(true);
        let comments = otherState.comments.map((comment) => {
            if (comment.id === 'Other') {
                return otherState.otherComment;
            }
            return comment.label;
        });
        ProceedToRecall({
            variables: {
                source_id: props.source_id,
                comments: comments.length > 0 ? comments.join('#') : otherState.otherComment
            }
        });
        props.handleCancelModal();
    };

    return (
        <button className="a-btn-background-transparent a-icon-container m-l-5 m-r-5">
            <img
                className="pointer"
                height="15px"
                title="Recall"
                src={process.env.PUBLIC_URL + '/assets/icons/Recall.svg'}
                alt="Recall"
                onClick={onClickRecall}
            />
            {props.showConfirmModal && (
                <ConfirmationModal
                    showModal={props.showConfirmModal}
                    handleCancelModal={props.handleCancelModal}
                    handleContinue={handleContinue}
                    currentAction={{ name: 'Recall', comments_required: props.commentRequired }}
                    fullBodyContent={{ text: 'Are you sure you want to recall these details' }}
                    module={props.module}
                    fmis_function={props.fmis_function}
                    fmis_sub_function={props.fmis_sub_function ? props.fmis_sub_function : 'NA'}
                />
            )}
        </button>
    );
};
export default RecallButton;
