import React, { useState } from 'react';
import { Button } from 'appkit-react';
import { eventClient } from '../../apollo';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import ConfirmationModal from './confirmationModal';
import { Toast } from 'primereact/toast';
import IsAuthorized from '../../commons/IsAuthorized';
import { useHistory } from 'react-router-dom';
import CommonModal from '../../commons/CommonModal';

const ActionsComponents = (props) => {
    const [state, setState] = useState({
        actions: [{ name: 'Submit', status: 'ACCEPTED', kind: 'primary', comments_required: false }],
        showModal: false,
        currentAction: {}
    });
    const [growl, setGrowl] = useState();
    let history = useHistory();
    const [loaded, setLoaded] = useState(props.mode !== 'create' ? false : true);
    const [workflowUsers, setWorkflowUsers] = useState();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const showSave = props.screenName === 'Strategic Audit Plan' ? props.canProcess : true;

    useQuery(Schema.getWFActions, {
        skip: !props.source_id,
        variables: { source_id: props.source_id },
        client: eventClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.workflow_actions) {
                setState({ ...state, actions: data.workflow_actions?.actions });
                setLoaded(true);
                props.setActionsStatus && props.setActionsStatus(data.workflow_actions?.actions?.length > 0);
            }
        },
        onError: () => {
            props.handleLoader(false);
        }
    });
    const [getWorkflowUsers] = useLazyQuery(Schema.wf_assign_details, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                setWorkflowUsers(data.wf_assign_details);
                setState({ ...state, showModal: true });
                props.handleLoader(false);
                return;
            }
            props.handleLoader(false);
        },
        onError: () => {
            props.handleLoader(false);
        }
    });

    const [saveWorkFlowAction] = useMutation(Schema.upsertEntityRecord, {
        client: eventClient,
        onCompleted: (data) => {
            props.handleLoader(false);
            if (data && data.upsertEntityRecord?.status === 'SUCCESS') {
                if (
                    props.screenName !== 'COA Segment' ||
                    props.screenName !== 'Remittance Segment Configuration' ||
                    props.screenName !== 'Follow-Up Audit Configuration'
                )
                    setState({ ...state, actions: [] });
                if (props.growl) {
                    props.growl.show({ life: 6000, severity: 'success', summary: props.screenName + ' Saved Successfully' });
                } else {
                    growl.show({ life: 6000, severity: 'success', summary: props.screenName + ' Saved Successfully' });
                }
                setTimeout(() => {
                    if (props.windoReload) {
                        window.location.reload();
                    } else if (props.backNavigateRoute) {
                        history.push(props.backNavigateRoute);
                        window.location.reload();
                    } else if (props.screenName !== 'COA Segment' || props.screenName !== 'Remittance Segment Configuration') {
                        history.goBack();
                    }
                }, 2000);
            }
        },
        onError: () => {
            props.handleLoader(false);
        }
    });

    const handleCancel = (type) => {
        setState({ ...state, showModal: true, currentAction: { name: 'Discard', type: type ? type : null } });
    };

    const handleCancelModal = () => {
        setState({ ...state, showModal: false });
    };

    const handleDiscard = (obj) => {
        if (props.mode === 'create') {
            setState({ ...state, showModal: false });
            props.onCancel();
        } else if (obj.type === null && props.source_id) {
            setState({ ...state, showModal: false, Obj: obj });
            props.onCancel();
        } else if (obj.type === 'cancel') {
            setState({ ...state, showModal: false });
        }
    };

    const onActionClick = (obj) => {
        if (
            props.screen &&
            props.screen === 'createContract' &&
            (!props.getRecord()?.supplies || props.getRecord()?.supplies?.create.length === 0)
        ) {
            props.showGrowl('Contract Details are mandatory', 'error');
            props.handleLoader(false);
            return;
        }

        let varObj = {
            function_code: props.fmis_function,
            source_id: props.source_id,
            office_code: props.officeCode,
            action: obj?.status,
            record: props?.getRecord ? props.getRecord() : {}
        };
        if (props.auditors) {
            varObj.users = props.users;
        }
        getWorkflowUsers({ variables: varObj });
        setState({ ...state, currentAction: obj });
    };

    const handleSave = () => {
        if (props?.no_entity_id) props.onSave({}, {});
        else onClickSaveAction();
    };

    const handleContinue = (obj, otherState) => {
        if (obj.name === 'Discard') {
            handleDiscard(obj);
        } else if (obj.name === 'Save' && obj.type === 'DraftSave') {
            setState({ ...state, showModal: false });
            if (
                props.checkIsApproved &&
                (props.fmis_function === 'IA_CONTROLS_DEFINITION' ||
                    props.fmis_function === 'IA_RISK_DEFINITION' ||
                    props.fmis_function === 'IA_AUDIT_PROCEDURE_DEF')
            )
                setShowConfirmationModal(true);
            else handleSave();
        } else if (obj.name !== 'Discard' && obj.name !== 'Save' && obj.type !== 'DraftSave') {
            let comments = otherState.comments.map((comment) => {
                if (comment.id === 'Other') {
                    return otherState.otherComment;
                }
                return comment.label;
            });
            let wfParams = {
                office_code: workflowUsers.office_code,
                wf_conf_id: workflowUsers.wf_conf_id,
                current_phase: workflowUsers.current_phase,
                current_phase_seq: workflowUsers.current_phase_seq,
                next_phase: workflowUsers.next_phase,
                next_phase_seq: workflowUsers.next_phase_seq,
                action: state.currentAction.status,
                assign_to: otherState.assignedUser,
                comments: comments.length > 0 ? comments.join('#') : otherState.otherComment,
                fmis_function: props.fmis_function,
                mode: props?.isApproved ? 'UPDATE' : undefined
            };
            props.onSave(wfParams, state.currentAction);
            setState({ ...state, showModal: false });
        }
    };

    const wfActions = (btn) => {
        onActionClick(btn);
    };

    const saveWfAction = () => {
        setState({ ...state, currentAction: { name: 'Save', type: 'DraftSave' }, showModal: true });
    };

    const onClickSaveAction = () => {
        setShowConfirmationModal(false);
        let dataObj = typeof props.getRecord === 'function' ? props.getRecord() : {};
        let displayData = typeof props.getDisplayData === 'function' ? props.getDisplayData() : {};
        let bulkCreateData = typeof props.getBulkCreateData === 'function' ? props.getBulkCreateData() : '';
        let transactionID = localStorage.getItem('transaction_id');
        props.handleLoader(true);
        saveWorkFlowAction({
            variables: {
                module: props.module,
                fmis_function: props.fmis_function,
                fmis_sub_function: props.fmis_sub_function ? props.fmis_sub_function : 'NA',
                txn_id: transactionID && transactionID !== 'undefined' ? transactionID : undefined,
                entity_id: dataObj?.entity_id,
                source_id: props.source_id,
                record: dataObj,
                display_data: displayData,
                version_no: props.version_no,
                bulk_create_data: bulkCreateData
            }
        });
    };

    const buttonFunctionOne = (btn, index) => {
        if ((btn.name === 'Submit' || btn.name === 'Approve' || btn.name === 'Forward') && showSave) {
            return (
                <Button disabled={props.canDisable} size="sm" key={index} kind={btn.kind} onClick={() => wfActions(btn)}>
                    {btn.name}
                </Button>
            );
        }
    };

    const buttonFunctionTwo = (btn, index) => {
        if (
            btn.name !== 'Submit' &&
            btn.name !== 'Approve' &&
            btn.name !== 'Forward' &&
            btn.name !== 'Reject' &&
            btn.name !== 'Cancel' &&
            (!showSave ? btn.name !== 'Send For Revision' : true)
        ) {
            return (
                <Button disabled={props.canDisable} size="sm" key={index} kind={btn.kind} className="m-l-5" onClick={() => wfActions(btn)}>
                    {btn.name}
                </Button>
            );
        }
    };

    const buttonFunctionThree = (btn, index) => {
        if (((btn.name === 'Reject' && !props.isRejectNotRequired) || btn.name === 'Cancel') && props.screenName) {
            return (
                <Button disabled={props.canDisable} size="sm" key={index} kind={btn.kind} className="m-l-5" onClick={() => wfActions(btn)}>
                    {btn.name}
                </Button>
            );
        }
    };

    return (
        <div className="row p-l-5 p-r-10">
            <Toast ref={(el) => setGrowl(el)} />
            {loaded && (
                <div className="bottom-left-btm-div">
                    {((props.mode === 'view' && state.actions.length > 0 && !props.showEdit) ||
                        (props.approveEdit && !props.hideEdit && props.screenName)) && (
                        <IsAuthorized
                            privRequired={props.privRequired}
                            officeCode={props.officeCode}
                            yes={() => (
                                <Button size="sm" kind="primary" className="m-r-10 m-t-10" onClick={() => props.navigateToEdit()}>
                                    Edit
                                </Button>
                            )}
                        ></IsAuthorized>
                    )}
                </div>
            )}
            {loaded && (
                <div className="bottom-right-btm-div m-b-10 m-r-10">
                    {state.actions.map((btn, index) => {
                        return buttonFunctionOne(btn, index);
                    })}
                    {!props.approveEdit && (!props.source_id || props.mode === 'edit') && state.actions.length > 0 && showSave && (
                        <>
                            <Button disabled={props.minFieldsRequired} size="sm" kind="primary" onClick={saveWfAction} className="m-l-5">
                                {'Save'}
                            </Button>
                            {props?.buttons?.map((btn, index) => {
                                return (
                                    <>
                                        {props?.mode === btn.mode && (
                                            <Button
                                                className="m-l-5"
                                                disabled={props.canDisable}
                                                size="sm"
                                                key={index}
                                                kind={btn.kind}
                                                onClick={() => props.additinalButtonClick(btn.buttonClickType)}
                                            >
                                                {btn.name}
                                            </Button>
                                        )}
                                    </>
                                );
                            })}
                        </>
                    )}
                    {state.actions.map((btn, index) => {
                        return buttonFunctionTwo(btn, index);
                    })}
                    {state.actions.map((btn, index) => {
                        return buttonFunctionThree(btn, index);
                    })}
                    {props.approveEdit && props.mode === 'edit' && props.screenName && showSave && (
                        <>
                            <Button
                                disabled={props.mode === 'edit' ? props.canDisable : props.minFieldsRequired}
                                size="sm"
                                kind="primary"
                                onClick={() => {
                                    props.onSave();
                                }}
                                className="m-r-5"
                            >
                                {'Save'}
                            </Button>
                        </>
                    )}
                    {props.approveEdit && props.mode === 'edit' && props.screenName && (
                        <Button size="sm" kind="secondary" onClick={() => handleCancel()}>
                            Cancel
                        </Button>
                    )}
                    {!props.approveEdit && (!props.source_id || props.mode === 'edit') && state.actions.length > 0 && (
                        <Button className="m-l-5" size="sm" kind="secondary" onClick={() => handleCancel()}>
                            Discard
                        </Button>
                    )}
                </div>
            )}

            {state.showModal && (
                <ConfirmationModal
                    showModal={state.showModal}
                    handleCancel={handleCancel}
                    handleCancelModal={handleCancelModal}
                    handleContinue={handleContinue}
                    currentAction={state.currentAction}
                    module={props?.module}
                    fmis_function={props.fmis_function}
                    fmis_sub_function={props?.fmis_sub_function}
                    workflowUsers={workflowUsers}
                />
            )}
            {showConfirmationModal && (
                <CommonModal
                    showModal={showConfirmationModal}
                    title="Confirmation"
                    type="text"
                    text={window.EDIT_OF_APPROVAL_MESSAGES.OnSubmittingRiskMessage}
                    hideEdit={true}
                    save={true}
                    saveText="Yes"
                    cancelText="No"
                    submit={() => handleSave()}
                    closeModal={() => setShowConfirmationModal(false)}
                />
            )}
        </div>
    );
};

export default ActionsComponents;
