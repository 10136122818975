import { gql } from '@apollo/client';

export const GMSchema = {
	fetchAuditHierarchyLevels: gql`
        query auditHierarchyLevels($where: AuditHierarchyLevelWhereInput) {
            auditHierarchyLevels(where: $where) {
                id
                name
                child_level {
                    id
                    name
                    child_level {
                        id
                        name
                        child_level {
                            id
                            name
                            child_level {
                                id
                                name
                                child_level {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
	auditHierarchyDetailConfs: gql`
        query auditHierarchyDetailConfs($where: AuditHierarchyDetailConfWhereInput) {
            auditHierarchyDetailConfs(where: $where) {
                status
            }
        }
    `,
	auditHierarchyDetailConfsData: gql`
        query auditHierarchyDetailConfs(
            $where: AuditHierarchyDetailConfWhereInput!
            $orderBy: [AuditHierarchyDetailConfOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            auditHierarchyDetailConfs(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                ref
                id
                is_effective
                version_no
                is_latest
                version_user
                version_date
                status
            }
        }
    `,
	findOneAuditHierarchyDetailConf: gql`
        query auditHierarchyDetailConf($where: AuditHierarchyDetailConfWhereUniqueInput!) {
            auditHierarchyDetailConf(where: $where) {
                id
                ref
                status
                version_no
                version_user
                version_date
                is_effective
                is_latest
            }
        }
    `,

	fetchAuditLevels: gql`
        query auditHierarchyLevels($where: AuditHierarchyLevelWhereInput) {
            auditHierarchyLevels(where: $where) {
                id
                name
            }
        }
    `,
	auditPlanDefCount: gql`
        query auditPlanDefCount($where: AuditPlanDefWhereInput) {
            auditPlanDefCount(where: $where)
        }
    `,
	auditPlanDefs: gql`
        query auditPlanDefs($where: AuditPlanDefWhereInput) {
            auditPlanDefs(where: $where) {
                id
                ref
                risk_control_level
                audit_program_level
                mda_start_month
                mda_start_day
                mda_end_month
                mda_end_day
                type_of_auditplan
                status
                version_no
                version_user
                version_date
                is_latest
                is_effective
            }
        }
    `,
	auditPlanDef: gql`
        query auditPlanDef($where: AuditPlanDefWhereUniqueInput!) {
            auditPlanDef(where: $where) {
                id
                ref
                risk_control_level
                audit_program_level
                mda_start_month
                mda_start_day
                mda_end_month
                mda_end_day
                type_of_auditplan
                status
                version_no
                version_user
                version_date
                is_latest
                is_effective
            }
        }
    `,
	updateOneAuditPlanDef: gql`
        mutation updateOneAuditPlanDef($data: AuditPlanDefUpdateInput!, $where: AuditPlanDefWhereUniqueInput!) {
            updateOneAuditPlanDef(data: $data, where: $where) {
                id
            }
        }
    `,
	createOneAuditPlanDef: gql`
        mutation createOneAuditPlanDef($data: AuditPlanDefCreateInput!) {
            createOneAuditPlanDef(data: $data) {
                id
            }
        }
    `,
	deleteOneAuditPlanDef: gql`
        mutation deleteOneAuditPlanDef($where: AuditPlanDefWhereUniqueInput!) {
            deleteOneAuditPlanDef(where: $where) {
                id
            }
        }
    `,
	auditStructure: gql`
        query auditStructure {
            auditProgramStructures(where: { parent_id: { equals: null } }) {
                name
                child_level {
                    name
                    child_level {
                        name
                        child_level {
                            name
                            child_level {
                                name
                                child_level {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
	riskLikelihood: gql`
        query riskLikelihood {
            riskLikelihoodDefs {
                id
                risk_score
                descriptor
                narration
            }
        }
    `,
	riskImpact: gql`
        query riskImpact {
            entityRiskImpactDefs {
                id
                score
                descriptor
                risk_consequence_mappings {
                    narration
                    risk_consequence {
                        sno
                        category
                    }
                }
            }
        }
    `,
	controlScoreConfig: gql`
        query controlScoreConfig {
            controlScoreConfigDefs {
                id
                range_start
                range_end
                no_of_slabs
                control_scores {
                    slab_no
                    start_range
                    end_range
                    descriptor
                    interpretation
                    testing_required
                }
            }
        }
    `,
	riskEvaluationDefs: gql`
        query riskEvaluationDefs {
            riskEvaluationDefs {
                id
                descriptor
                colour_code
                interpretation_req_action
                risk_score
                risk_imapct {
                    score
                }
                risk_likelihood {
                    risk_score
                }
            }
        }
    `,
	riskAndControlTransactions: gql`
        query riskAndControlTransactions {
            riskAndControlTransactions {
                id
                name
                from_year
                to_year
                status
            }
        }
    `,
	fetchTransactionRiskRankings: gql`
        query transactionRiskRankings($where: TransactionRiskRankingWhereInput) {
            transactionRiskRankings(where: $where) {
                id
                sno
                applicability_from
                applicability_to
                start_range
                end_range
                no_of_slabs
                risk_score_def {
                    id
                }
                risk_criteria_def {
                    id
                }
            }
        }
    `,

	fetchTransactionRiskRankingById: gql`
        query transactionRiskRanking($where: TransactionRiskRankingWhereUniqueInput!) {
            transactionRiskRanking(where: $where) {
                id
                sno
                applicability_from
                applicability_to
                start_range
                end_range
                no_of_slabs
                risk_score_def {
                    period
                    start_range
                    end_range
                    no_of_slabs
                    risk_score_def_details {
                        slab_no
                        start_range
                        end_range
                        descriptor
                        audit_timing
                        colour
                    }
                }
                risk_criteria_def {
                    id
                    sno
                    criteria
                    weightage
                    mapppings {
                        description
                        risk_score
                    }
                }
            }
        }
    `,

	riskScoreDef: gql`
        query riskScoreDef($where: RiskScoreDefWhereUniqueInput!) {
            riskScoreDef(where: $where) {
                id
                period
                no_of_slabs
                end_range
                start_range
                risk_score_def_details {
                    slab_no
                    start_range
                    descriptor
                    colour
                    audit_timing
                    end_range
                }
            }
        }
    `,

	riskCriteriaDef: gql`
        query riskCriteriaDef($where: RiskCriteriaDefWhereUniqueInput!) {
            riskCriteriaDef(where: $where) {
                sno
                id
                criteria
                weightage
                mapppings {
                    description
                    risk_score_id
                }
            }
        }
    `,

	fetchRiskScoreDefDetails: gql`
        query riskScoreDefDetails($where: RiskScoreDefDetailWhereInput!) {
            riskScoreDefDetails(where: $where) {
                id
                slab_no
                descriptor
            }
        }
    `,

	gridViewData: gql`
        query gridViewData {
            gridViewData
        }
    `,
	auditHierarchyCodesInActive: gql`
        query auditHierarchyCodes(
            $where1: AuditHierarchyCodeWhereInput
            $orderBy: [AuditHierarchyCodeOrderByInput!]
            $where2: AuditHierarchyCodeWhereInput
        ) {
            auditHierarchyCodes: auditHierarchyCodes(where: $where1, orderBy: $orderBy) {
                id
                path
                name
                approval_status
                audit_level_id
                parent_id
                is_active
                audit_level {
                    name
                    id
                }
                transations {
                    ref
                    name
                    is_active
                    is_processed
                    ref
                }
                child_codes(where: $where2) {
                    name
                    id
                    path
                    audit_level_id
                    parent_id
                    approval_status
                    is_active
                    audit_level {
                        name
                        id
                    }
                    transations {
                        name
                        is_active
                        is_processed
                        ref
                    }
                    child_codes(where: $where2) {
                        name
                        id
                        path
                        audit_level_id
                        parent_id
                        approval_status
                        is_active
                        audit_level {
                            name
                            id
                        }
                        transations {
                            name
                            is_active
                            is_processed
                            ref
                        }
                        child_codes(where: $where2) {
                            name
                            id
                            path
                            audit_level_id
                            parent_id
                            approval_status
                            is_active
                            audit_level {
                                name
                                id
                            }
                            transations {
                                name
                                is_active
                                is_processed
                                ref
                            }
                            child_codes(where: $where2) {
                                name
                                id
                                path
                                audit_level_id
                                parent_id
                                approval_status
                                is_active
                                audit_level {
                                    name
                                    id
                                }
                                transations {
                                    name
                                    is_active
                                    is_processed
                                    ref
                                }
                                child_codes(where: $where2) {
                                    name
                                    id
                                    path
                                    audit_level_id
                                    parent_id
                                    approval_status
                                    is_active
                                    audit_level {
                                        name
                                        id
                                    }
                                    transations {
                                        name
                                        is_active
                                        is_processed
                                        ref
                                    }
                                    child_codes(where: $where2) {
                                        name
                                        id
                                        path
                                        audit_level_id
                                        parent_id
                                        approval_status
                                        is_active
                                        audit_level {
                                            name
                                            id
                                        }
                                        transations {
                                            name
                                            is_active
                                            is_processed
                                            ref
                                        }
                                        child_codes(where: $where2) {
                                            name
                                            id
                                            path
                                            audit_level_id
                                            parent_id
                                            approval_status
                                            is_active
                                            audit_level {
                                                name
                                                id
                                            }
                                            transations {
                                                name
                                                is_active
                                                is_processed
                                                ref
                                            }
                                            child_codes(where: $where2) {
                                                name
                                                id
                                                path
                                                audit_level_id
                                                parent_id
                                                approval_status
                                                is_active
                                                audit_level {
                                                    name
                                                    id
                                                }
                                                transations {
                                                    name
                                                    is_active
                                                    is_processed
                                                    ref
                                                }
                                                child_codes(where: $where2) {
                                                    name
                                                    id
                                                    path
                                                    audit_level_id
                                                    parent_id
                                                    approval_status
                                                    is_active
                                                    audit_level {
                                                        name
                                                        id
                                                    }
                                                    transations {
                                                        name
                                                        is_active
                                                        is_processed
                                                        ref
                                                    }
                                                    child_codes(where: $where2) {
                                                        name
                                                        id
                                                        path
                                                        audit_level_id
                                                        parent_id
                                                        approval_status
                                                        is_active
                                                        audit_level {
                                                            name
                                                            id
                                                        }
                                                        transations {
                                                            name
                                                            is_active
                                                            is_processed
                                                            ref
                                                        }
                                                        child_codes(where: $where2) {
                                                            name
                                                            id
                                                            path
                                                            audit_level_id
                                                            parent_id
                                                            approval_status
                                                            is_active
                                                            audit_level {
                                                                name
                                                                id
                                                            }
                                                            transations {
                                                                name
                                                                is_active
                                                                is_processed
                                                                ref
                                                            }
                                                            child_codes(where: $where2) {
                                                                name
                                                                id
                                                                path
                                                                audit_level_id
                                                                parent_id
                                                                approval_status
                                                                is_active
                                                                audit_level {
                                                                    name
                                                                    id
                                                                }
                                                                transations {
                                                                    name
                                                                    is_active
                                                                    is_processed
                                                                    ref
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
	auditHierarchyCodes: gql`
        query auditHierarchyCodes($where1: AuditHierarchyCodeWhereInput, $orderBy: [AuditHierarchyCodeOrderByInput!]) {
            auditHierarchyCodes: auditHierarchyCodes(where: $where1, orderBy: $orderBy) {
                id
                path
                name
                approval_status
                audit_level_id
                parent_id
                is_active
                audit_level {
                    name
                    id
                }
                child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                    name
                    id
                    path
                    audit_level_id
                    parent_id
                    approval_status
                    is_active
                    audit_level {
                        name
                        id
                    }
                    child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                        name
                        id
                        path
                        audit_level_id
                        parent_id
                        approval_status
                        is_active
                        audit_level {
                            name
                            id
                        }
                        child_codes(where: { transations: { none: { is_active : { equals: false } } } }) {
                            name
                            id
                            path
                            audit_level_id
                            parent_id
                            approval_status
                            is_active
                            audit_level {
                                name
                                id
                            }
                            child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                                name
                                id
                                path
                                audit_level_id
                                parent_id
                                approval_status
                                is_active
                                audit_level {
                                    name
                                    id
                                }
                                child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                                    name
                                    id
                                    path
                                    audit_level_id
                                    parent_id
                                    approval_status
                                    is_active
                                    audit_level {
                                        name
                                        id
                                    }
                                    child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                                        name
                                        id
                                        path
                                        audit_level_id
                                        parent_id
                                        approval_status
                                        is_active
                                        audit_level {
                                            name
                                            id
                                        }
                                        child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                                            name
                                            id
                                            path
                                            audit_level_id
                                            parent_id
                                            approval_status
                                            is_active
                                            audit_level {
                                                name
                                                id
                                            }
                                            child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                                                name
                                                id
                                                path
                                                audit_level_id
                                                parent_id
                                                approval_status
                                                is_active
                                                audit_level {
                                                    name
                                                    id
                                                }
                                                child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                                                    name
                                                    id
                                                    path
                                                    audit_level_id
                                                    parent_id
                                                    approval_status
                                                    is_active
                                                    audit_level {
                                                        name
                                                        id
                                                    }
                                                    child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                                                        name
                                                        id
                                                        path
                                                        audit_level_id
                                                        parent_id
                                                        approval_status
                                                        is_active
                                                        audit_level {
                                                            name
                                                            id
                                                        }
                                                        child_codes(where: { transations: { none: { is_active: { equals: false } } } }) {
                                                            name
                                                            id
                                                            path
                                                            audit_level_id
                                                            parent_id
                                                            approval_status
                                                            is_active
                                                            audit_level {
                                                                name
                                                                id
                                                            }
                                                            child_codes(
                                                                where: { transations: { none: { is_active: { equals: false } } } }
                                                            ) {
                                                                name
                                                                id
                                                                path
                                                                audit_level_id
                                                                parent_id
                                                                approval_status
                                                                is_active
                                                                audit_level {
                                                                    name
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
	auditHierarchyCodesInSap: gql`
        query auditHierarchyCodes($where: AuditHierarchyCodeWhereInput) {
            auditHierarchyCodes(where: $where) {
                id
                path
                name
                approval_status
                audit_level_id
                parent_id
                is_active
            }
        }
    `,
	auditHierarchyDetailConf: gql`
        query auditHierarchyDetailConf($where: AuditHierarchyDetailConfWhereUniqueInput!) {
            auditHierarchyDetailConf(where: $where) {
                id
                ref
                status
            }
        }
    `,
	AuditHierarchyReportDownload: gql`
        query {
            auditHierarchyReportDownload {
                status
                uploadResp {
                    id
                    mimetype
                    input_name
                    size
                    path
                }
            }
        }
    `,
	AuditHeirarchyFileUpload: gql`
        mutation importAuditHierarchyReport($file: Upload!) {
            importAuditHierarchyReport(data: $file) {
                status
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    id
                    input_name
                    path
                    mimetype
                    size
                }
            }
        }
    `,
	fetchAuditHierarchyForRiskSelection: gql`
        query {
            hierarchyCodesInTreeStructure(level_id: "ALL")
        }
    `,
	hierarchyCodesWrtPlan: gql`
        query hierarchyCodesWrtPlan($type: String!) {
            hierarchyCodesWrtPlan(type: $type)
        }
    `,
	followUpAuditConfs: gql`
        query followUpAuditConfs($where: FollowUpAuditConfWhereInput) {
            followUpAuditConfs(where: $where) {
                id
                ref
                treatment_of_followup_audit
                grouping_of_findigs
                placemnt_od_identifier
                is_active
                status
                version_no
                version_user
                version_date
            }
        }
    `,
	updateOneFollowUpAuditConf: gql`
        mutation updateOneFollowUpAuditConf($data: FollowUpAuditConfUpdateInput!, $where: FollowUpAuditConfWhereUniqueInput!) {
            updateOneFollowUpAuditConf(data: $data, where: $where) {
                id
            }
        }
    `,
	createOneFollowUpAuditConf: gql`
        mutation createOneFollowUpAuditConf($data: FollowUpAuditConfCreateInput!) {
            createOneFollowUpAuditConf(data: $data) {
                id
            }
        }
    `,
	getAuditObjectives: gql`
        query auditObjectiveDefs($where: AuditObjectiveDefWhereInput!, $orderBy: [AuditObjectiveDefOrderByInput!], $skip: Int, $take: Int) {
            auditObjectiveDefs(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                objective
                description
                status
                is_latest
                is_active
                audit_classification
                audit_classif_codes {
                    id
                    classif_level
                    classif_codes
                    classif_code_obj {
                        name
                    }
                }
                version_no
                version_user
                version_date
                is_latest
                is_effective
            }
        }
    `,
	getAuditObjectiveById: gql`
        query auditObjectiveDef($where: AuditObjectiveDefWhereUniqueInput!) {
            auditObjectiveDef(where: $where) {
                id
                ref
                objective
                description
                status
                is_active
                version_date
                version_no
                audit_classification
                audit_openions {
                    id
                }
                audit_classif_codes {
                    id
                    classif_level
                    classif_codes
                    classif_code_obj {
                        name
                    }
                }
                risk_mappings {
                    risk {
                        id
                    }
                    control {
                        id
                    }
                }
                version_no
                version_user
                version_date
            }
        }
    `,
	createOneAuditObjectiveDef: gql`
        mutation createOneAuditObjectiveDef($data: AuditObjectiveDefCreateInput!) {
            createOneAuditObjectiveDef(data: $data) {
                id
            }
        }
    `,
	updateOneAuditObjectiveDef: gql`
        mutation updateOneAuditObjectiveDef($data: AuditObjectiveDefUpdateInput!, $where: AuditObjectiveDefWhereUniqueInput!) {
            updateOneAuditObjectiveDef(data: $data, where: $where) {
                id
            }
        }
    `,
	auditObjCount: gql`
        query auditObjectiveDefCount($where: AuditObjectiveDefWhereInput) {
            auditObjectiveDefCount(where: $where)
        }
    `,
	auditHierarchyDetailConfCount: gql`
        query auditHierarchyDetailConfCount($where: AuditHierarchyDetailConfWhereInput) {
            auditHierarchyDetailConfCount(where: $where)
        }
    `,
	filterAuditObjectiveByClassifs: gql`
        query auditObjectiveDefs($where: AuditObjectiveDefWhereInput) {
            auditObjectiveDefs(where: $where) {
                id
                status
                objective
                ref
            }
        }
    `,
	fetchRisksList: gql`
        query riskDefs($where: RiskDefWhereInput) {
            riskDefs(where: $where) {
                ref
                id
                name
                description
                audit_classif_codes {
                    classif_level
                    classif_code
                }
            }
        }
    `,
	fetchRisksByAuditHierarchy: gql`
        query riskDefs($where: RiskDefWhereInput) {
            riskDefs(where: $where) {
                ref
                id
                name
                description
            }
        }
    `,
	getControlDefs: gql`
        query controlDefs($where: ControlDefWhereInput, $orderBy: [ControlDefOrderByInput!], $take: Int, $skip: Int) {
            controlDefs(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                ref
                status
                name
                category
                description
                control_type
                version_date
                is_active
                is_latest
                is_effective
            }
        }
    `,
	riskControlDefMappingsFilter: gql`
        query riskControlDefMappings($where: RiskControlDefMappingWhereInput) {
            riskControlDefMappings(where: $where) {
                riskdef_id
                control_def_id
                is_active
                control_def {
                    id
                    ref
                    name
                    is_active
                }
                risk {
                    ref
                    id
                    name
                    is_active
                }
                id
            }
        }
    `,
	riskControlDefMappings: gql`
        query controlDefs($controlDefwhere: ControlDefWhereInput, $mappingsWhere: RiskControlDefMappingWhereInput) {
            controlDefs: controlDefs(where: $controlDefwhere) {
                id
                ref
                name
                description
                control_type
                category
                status
                version_date
                is_active
            }
            mappings: riskControlDefMappings(where: $mappingsWhere) {
                control_def_id
                riskdef_id
                risk {
                    id
                    ref
                    name
                    description
                    category
                    is_active
                    is_latest
                    status
                }
            }
        }
    `,
	getRiskDefs: gql`
        query riskDefs($where: RiskDefWhereInput, $orderBy: [RiskDefOrderByInput!], $skip: Int, $take: Int) {
            riskDefs(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                name
                description
                category
                status
                version_no
                is_active
                is_latest
                is_effective
                version_date
                risk_control_mappings {
                    control_def {
                        id
                        ref
                        name
                        description
                        control_type
                        is_active
                    }
                }
            }
        }
    `,
	getRiskDefById: gql`
        query riskDefs($where: RiskDefWhereInput) {
            riskDefs(where: $where) {
                id
                ref
                name
                description
                category
                status
                is_latest
                version_date
                version_no
                ref
                is_active
                objective_mappings {
                    objective_id
                    control_id
                }
                audit_procedure_details {
                    control_id
                    procedure_def_id
                }
                audit_classif_codes {
                    classif_code
                    classif_level
                }
                risk_control_mappings {
					id
                    control_def_id
                    is_active
                    control_def {
                        id
                        ref
                        name
                        description
                        category
                        control_type
                        is_active
                        is_effective
                    }
                }
                version_no
            }
        }
    `,
	getRiskDefsForSelect: gql`
        query riskDefs($where: RiskDefWhereInput) {
            riskDefs(where: $where) {
                id
                ref
                name
            }
        }
    `,
	deleteOneRiskDef: gql`
        mutation deleteOneRiskDef($where: RiskDefWhereUniqueInput!) {
            deleteOneRiskDef(where: $where) {
                id
            }
        }
    `,
	getControlDefsFilter: gql`
        query riskDefs($where: RiskDefWhereInput) {
            riskDefs(where: $where) {
                id
                name
                ref
                risk_control_mappings {
                    control_def {
                        id
                        ref
                        name
                    }
                }
            }
        }
    `,
	getControlDefsForSelect: gql`
        query controlDefs($where: ControlDefWhereInput) {
            controlDefs(where: $where) {
                id
                name
            }
        }
    `,
	createManyRiskDefs: gql`
        mutation createManyRiskDef($data: RiskDefBulkCreateInput!) {
            createManyRiskDef(data: $data) {
                id
            }
        }
    `,
	createOneRiskDef: gql`
        mutation createOneRiskDef($data: RiskDefCreateInput!) {
            createOneRiskDef(data: $data) {
                id
            }
        }
    `,
	updateOneRiskDef: gql`
        mutation updateOneRiskDef($data: RiskDefUpdateInput!, $where: RiskDefWhereUniqueInput!) {
            updateOneRiskDef(data: $data, where: $where) {
                id
            }
        }
    `,
	controlDefCount: gql`
        query controlDefCount($where: ControlDefWhereInput) {
            controlDefCount(where: $where)
        }
    `,
	riskDefCount: gql`
        query riskDefCount($where: RiskDefWhereInput) {
            riskDefCount(where: $where)
        }
    `,
	deleteOneControlDef: gql`
        mutation deleteOneControlDef($where: ControlDefWhereUniqueInput!) {
            deleteOneControlDef(where: $where) {
                id
            }
        }
    `,
	controlDef: gql`
        query controlDef($where: ControlDefWhereUniqueInput!) {
            controlDef(where: $where) {
                id
                ref
                name
                description
                control_type
                category
                status
                is_active
                version_no
                version_date
                audit_procedure_details {
                    risk_id
                    procedure_def_id
                }
                risk_control_mappings {
                    id
                    riskdef_id
                    control_def_id
                    is_active
                    risk {
                        id
                        ref
                        name
                        description
                        category
                        status
                        is_active
                        is_effective
                    }
                    control_def {
                        id
                        ref
                        name
                        description
                        control_type
                        category
                        status
                        is_active
                    }
                }
            }
        }
    `,
	createManyControlDef: gql`
        mutation createManyControlDef($data: ControlDefBulkCreateInput!) {
            createManyControlDef(data: $data) {
                id
            }
        }
    `,
	createOneControlDef: gql`
        mutation createOneControlDef($data: ControlDefCreateInput!) {
            createOneControlDef(data: $data) {
                id
            }
        }
    `,
	updateOneControlDef: gql`
        mutation updateOneControlDef($data: ControlDefUpdateInput!, $where: ControlDefWhereUniqueInput!) {
            updateOneControlDef(data: $data, where: $where) {
                id
            }
        }
    `,
	createOneAuditHierarchyCode: gql`
        mutation createOneAuditHierarchyCode($data: AuditHierarchyCodeCreateInput!) {
            createOneAuditHierarchyCode(data: $data) {
                id
            }
        }
    `,

	updateOneAuditHierarchyCode: gql`
        mutation updateOneAuditHierarchyCode($data: AuditHierarchyCodeUpdateInput!, $where: AuditHierarchyCodeWhereUniqueInput!) {
            updateOneAuditHierarchyCode(data: $data, where: $where) {
                id
                version_no
                version_user
            }
        }
    `,
	updateManyAuditHierarchyCode: gql`
        mutation updateManyAuditHierarchyCode($data: AuditHierarchyCodeUpdateManyMutationInput!, $where: AuditHierarchyCodeWhereInput) {
            updateManyAuditHierarchyCode(data: $data, where: $where) {
                count
            }
        }
    `,
	updateOneAuditHierarchyDetailConf: gql`
        mutation updateOneAuditHierarchyDetailConf(
            $data: AuditHierarchyDetailConfUpdateInput!
            $where: AuditHierarchyDetailConfWhereUniqueInput!
        ) {
            updateOneAuditHierarchyDetailConf(data: $data, where: $where) {
                id
            }
        }
    `,
	createOneAuditHierarchyDetailConf: gql`
        mutation createOneAuditHierarchyDetailConf($data: AuditHierarchyDetailConfCreateInput!) {
            createOneAuditHierarchyDetailConf(data: $data) {
                id
            }
        }
    `,
	deleteOneAuditHierarchyDetailConf: gql`
        mutation deleteOneAuditHierarchyDetailConf($where: AuditHierarchyDetailConfWhereUniqueInput!) {
            deleteOneAuditHierarchyDetailConf(where: $where) {
                id
            }
        }
    `,
	deleteManyAuditHierarchyCode: gql`
        mutation deleteManyAuditHierarchyCode($where: AuditHierarchyCodeWhereInput!) {
            deleteManyAuditHierarchyCode(where: $where) {
                count
            }
        }
    `,
	guidelineOpinionCount: gql`
        query auditOpinionGuidelineConfCount($where: AuditOpinionGuidelineConfWhereInput) {
            auditOpinionGuidelineConfCount(where: $where)
        }
    `,
	getGuidelineAuditOpinions: gql`
        query auditOpinionGuidelineConfs(
            $where: AuditOpinionGuidelineConfWhereInput!
            $orderBy: [AuditOpinionGuidelineConfOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            auditOpinionGuidelineConfs(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                ref
                id
                no_of_slabs
                is_active
                version_no
                version_user
                version_date
                status
                is_active
                is_latest
                is_effective
                audit_objective {
                    objective
                }
                classif_codes {
                    classif_level
                    classif_code
                    classif_code_obj {
                        name
                    }
                }
            }
        }
    `,
	getGuidelineAuditOpinionById: gql`
        query auditOpinionGuidelineConf($where: AuditOpinionGuidelineConfWhereUniqueInput!) {
            auditOpinionGuidelineConf(where: $where) {
                ref
                id
                no_of_slabs
                classification
                is_active
                is_latest
                is_effective
                status
                version_no
                version_user
                version_date
                audit_objective {
                    id
                    ref
                    objective
                }
                classif_codes {
                    classif_level
                    classif_code
                    classif_code_obj {
                        name
                    }
                }

                audit_opinion_guideline_details {
                    slab_no
                    txn_fail_percent_start
                    txn_fail_percent_end
                    conclusion_opinion
                    description
                }
            }
        }
    `,
	createOneAuditOpinionGuideline: gql`
        mutation createOneAuditOpinionGuidelineConf($data: AuditOpinionGuidelineConfCreateInput!) {
            createOneAuditOpinionGuidelineConf(data: $data) {
                id
            }
        }
    `,
	updateOneAuditOpinionGuideline: gql`
        mutation updateOneAuditOpinionGuidelineConf(
            $data: AuditOpinionGuidelineConfUpdateInput!
            $where: AuditOpinionGuidelineConfWhereUniqueInput!
        ) {
            updateOneAuditOpinionGuidelineConf(data: $data, where: $where) {
                id
            }
        }
    `,
	auditHierarchyMdaMappings: gql`
        query auditHierarchyMdaMappings(
            $where: AuditHierarchyMDAMappingWhereInput!
            $orderBy: [AuditHierarchyMDAMappingOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            auditHierarchyMdaMappings(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                ref
                id
                status
                mda_profile_id
                version_no
                version_user
                version_date
                is_effective
                mda_profile {
                    id
                    mda_code
                    mda
                    auditable_area
                    auditable_level_id
                    auditable_area_code
                    iau_devision_detail {
                        iau_devision {
                            is_effective
                            id
                            code
                            alias
                        }
                    }
                }
            }
        }
    `,
	auditHierarchyMdaMapping: gql`
        query auditHierarchyMdaMapping($where: AuditHierarchyMDAMappingWhereUniqueInput!) {
            auditHierarchyMdaMapping(where: $where) {
                ref
                id
                mda_profile_id
                version_no
                version_user
                version_date
                audit_hierarchy {
                    classif_level
                    classif_code
                }
                mda_profile {
                    id
                    mda_code
                    mda
                    auditable_area
                    auditable_level_id
                    auditable_area_code
                    iau_devision_detail {
                        iau_devision {
                            is_effective
                            code
                            alias
                            id
                        }
                    }
                }
            }
        }
    `,
	mdaProfileDefGroupByMda: gql`
        query mdaProfileDefGroupByMda($where: MDAProfileDefWhereInput) {
            mdaProfileDefGroupByMda(where: $where)
        }
    `,
	auditHierarchyMdaMappingCount: gql`
        query auditHierarchyMdaMappingCount($where: AuditHierarchyMDAMappingWhereInput) {
            auditHierarchyMdaMappingCount(where: $where)
        }
    `,

	mdaHierarchyDownload: gql`
		query mdaHierarchyDownload($iauCode: String!) {
			mdaHierarchyDownload(iauCode: $iauCode)
		}
	`,


	createManyAuditHierarchyMDAMappings: gql`
        mutation createManyAuditHierarchyMDAMappings($data: AuditHierarchyMDAMappingBulkCreateInput!) {
            createManyAuditHierarchyMDAMappings(data: $data) {
                id
            }
        }
    `,
	createOneAuditHierarchyMDAMapping: gql`
        mutation createOneAuditHierarchyMDAMapping($data: AuditHierarchyMDAMappingCreateInput!) {
            createOneAuditHierarchyMDAMapping(data: $data) {
                id
            }
        }
    `,
	updateOneAuditHierarchyMDAMapping: gql`
        mutation updateOneAuditHierarchyMDAMapping(
            $data: AuditHierarchyMDAMappingUpdateInput!
            $where: AuditHierarchyMDAMappingWhereUniqueInput!
        ) {
            updateOneAuditHierarchyMDAMapping(data: $data, where: $where) {
                id
            }
        }
    `,
	deleteOneAuditHierarchyMDAMapping: gql`
        mutation deleteOneAuditHierarchyMDAMapping($where: AuditHierarchyMDAMappingWhereUniqueInput!) {
            deleteOneAuditHierarchyMDAMapping(where: $where) {
                id
            }
        }
    `,
	auditProcedureDefs: gql`
        query auditProcedureDefs($where: AuditProcedureDefWhereInput!, $orderBy: [AuditProcedureDefOrderByInput!], $skip: Int, $take: Int) {
            auditProcedureDefs(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                description
                status
                is_active
                is_latest
                version_date
                tests {
                    ref
                    test
                }
                audit_procedure_details {
                    risk_id
                    control_id
                    control {
                        ref
                    }
                }
                audit_classif_codes {
                    classif_level
                    classif_code
                }
                name
                version_no
                version_user
                version_date
                is_latest
                is_effective
            }
        }
    `,
	auditProcedureDef: gql`
        query auditProcedureDef($where: AuditProcedureDefWhereUniqueInput!) {
            auditProcedureDef(where: $where) {
                id
                ref
                is_latest
                description
                status
                is_active
                version_date
                version_no
                name
                tests {
                    ref
                    test
                }
                audit_procedure_details {
                    risk {
                        name
                        ref
                        audit_classif_codes {
                            classif_code
                        }
                        id
                    }
                    risk_id
                    control_id
                    control {
                        name
                        ref
                        id
                    }
                }
                audit_classif_codes {
                    classif_level
                    classif_code
                }
                version_no
                version_user
                version_date
            }
        }
    `,
	createOneAuditProcedureDef: gql`
        mutation createOneAuditProcedureDef($data: AuditProcedureDefCreateInput!) {
            createOneAuditProcedureDef(data: $data) {
                id
            }
        }
    `,
	updateOneAuditProcedureDef: gql`
        mutation updateOneAuditProcedureDef($data: AuditProcedureDefUpdateInput!, $where: AuditProcedureDefWhereUniqueInput!) {
            updateOneAuditProcedureDef(data: $data, where: $where) {
                id
            }
        }
    `,
	auditProcedureDefCount: gql`
        query auditProcedureDefCount($where: AuditProcedureDefWhereInput) {
            auditProcedureDefCount(where: $where)
        }
    `,
	deleteOneAuditProcedureDef: gql`
        mutation deleteOneAuditProcedureDef($where: AuditProcedureDefWhereUniqueInput!) {
            deleteOneAuditProcedureDef(where: $where) {
                id
            }
        }
    `,
	objectiveProcedureDefMappingCount: gql`
        query objectiveProcedureDefMappingCount($where: ObjectiveProcedureDefMappingWhereInput) {
            objectiveProcedureDefMappingCount(where: $where)
        }
    `,
	objectiveProcedureDefMapping: gql`
        query objectiveProcedureDefMapping($where: ObjectiveProcedureDefMappingWhereUniqueInput!) {
            objectiveProcedureDefMapping(where: $where) {
                id
                ref
                classification
                objective_id
                status
                is_active
                version_no
                version_date
                objective {
                    id
                    ref
                    objective
                    description
                    status
                    audit_classification
                }
                audit_procedure_details {
                    id
                    procedure_def_id
                    mapping_id
                    risk_id
                    control_id
                    risk {
                        id
                        ref
                        name
                        description
                        category
                        status
                    }
                    control {
                        id
                        ref
                        name
                        description
                        category
                        control_type
                        status
                    }
                }
                classif_codes {
                    id
                    source_id
                    classif_level
                    classif_code
                    classif_code_obj {
                        name
                    }
                }
            }
        }
    `,
	objectiveProcedureDefMappings: gql`
        query objectiveProcedureDefMappings(
            $where: ObjectiveProcedureDefMappingWhereInput!
            $orderBy: [ObjectiveProcedureDefMappingOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            objectiveProcedureDefMappings(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                classification
                objective_id
                status
                is_active
                version_no
                version_date
                objective {
                    id
                    ref
                    objective
                    description
                    status
                    audit_classification
                }
                audit_procedure_details {
                    id
                    procedure_def_id
                    mapping_id
                    risk_id
                    control_id
                    risk {
                        id
                        ref
                        name
                        description
                        category
                        status
                    }
                    control {
                        id
                        ref
                        name
                        description
                        category
                        control_type
                        status
                    }
                }
                classif_codes {
                    id
                    source_id
                    classif_level
                    classif_code
                    classif_code_obj {
                        name
                    }
                }
            }
        }
    `,
	createOneObjectiveProcedureDefMapping: gql`
        mutation createOneObjectiveProcedureDefMapping($data: ObjectiveProcedureDefMappingCreateInput!) {
            createOneObjectiveProcedureDefMapping(data: $data) {
                id
            }
        }
    `,
	updateOneObjectiveProcedureDefMapping: gql`
        mutation updateOneObjectiveProcedureDefMapping(
            $data: ObjectiveProcedureDefMappingUpdateInput!
            $where: ObjectiveProcedureDefMappingWhereUniqueInput!
        ) {
            updateOneObjectiveProcedureDefMapping(data: $data, where: $where) {
                id
            }
        }
    `,
	deleteOneObjectiveProcedureDefMapping: gql`
        mutation deleteOneObjectiveProcedureDefMapping($where: ObjectiveProcedureDefMappingWhereUniqueInput!) {
            deleteOneObjectiveProcedureDefMapping(where: $where) {
                count
            }
        }
    `,
	auditProcedureDefDetails: gql`
        query auditProcedureDefDetails(
            $where: AuditProcedureDefDetailWhereInput!
            $orderBy: [AuditProcedureDefDetailOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            auditProcedureDefDetails(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                control {
                    id
                    ref
                    name
                    description
                }
                risk {
                    id
                    ref
                    name
                    description
                }
                procedure_def {
                    id
                    description
                    ref
                    name
                    tests {
                        ref
                        test
                    }
                }
            }
        }
    `,
	auditTestDefs: gql`
        query auditTestDefs($where: AuditTestDefWhereInput!, $orderBy: [AuditTestDefOrderByInput!]) {
            auditTestDefs(where: $where, orderBy: $orderBy) {
                id
                test
                ref
            }
        }
    `,
	fetchRiskControlsWithProcedures: gql`
        query riskControlDefMappings(
            $where: RiskControlDefMappingWhereInput
            $orderBy: [RiskControlDefMappingOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            riskControlDefMappings(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                control_def {
                    ref
                    name
                    description
                    is_latest
                    is_effective
                    id
                    audit_procedure_details {
                        procedure_def_id
                        procedure_def {
                            ref
                            name
                            description
                            tests {
                                id
                                ref
                                test
                            }
                        }
                    }
                }
                risk {
                    ref
                    id
                    name
                    description
                    is_effective
                }
            }
        }
    `,
	iauDevisions: gql`
        query iauDevisions($where: IAUDevisionWhereInput) {
            iauDevisions(where: $where) {
                id
                ref
                code
                name
                alias
                is_effective
            }
        }
    `,
	mdaProfileDefs: gql`
        query mdaProfileDefs($where: MDAProfileDefWhereInput) {
            mdaProfileDefs(where: $where) {
                id
                ref
                mda_code
                mda
                auditable_area
                auditable_area_code
                from
                to
                iau_devision_detail {
                    iau_devision {
                        is_effective
                        id
                        code
                        alias
                    }
                }
            }
        }
    `,
	fetchAuditPlanDefs: gql`
        query auditPlanDefs($where: AuditPlanDefWhereInput) {
            auditPlanDefs(where: $where) {
                id
                ref
                risk_control_level
                audit_program_level
                mda_start_month
                mda_start_day
                mda_end_month
                mda_end_day
                type_of_auditplan
                status
                version_no
                version_user
            }
        }
    `
};

export default GMSchema;
