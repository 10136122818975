import { Switch } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import Users from '../pages/userRegistration/users/Users';
import AuthGaurd from './AuthGaurd';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';

const usersBreadcrumb = (props) => {
    let MainBreadCrumbValues = [{ name: 'System Administration', redirectTo: '/systemadministration' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[2] === 'view' || subPath[2] === 'clone' || subPath[2] === 'create' || subPath[2] === 'edit') {
        obj = [
            { name: subPath[1].charAt(0).toUpperCase() + subPath[1].slice(1), redirectTo: '/' + subPath[1], key: subPath[1] },
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '', key: subPath[2] }
        ];
    } else {
        obj = [{ name: subPath[1].charAt(0).toUpperCase() + subPath[1].slice(1), key: subPath[1], redirectTo: '' }];
    }

    MainBreadCrumbValues = [...MainBreadCrumbValues, ...obj];
    return MainBreadCrumbValues;
};
const RegisterNewUser = lazy(() => import('../pages/userRegistration/users/RegisterNewUser'));
const EditUser = lazy(() => import('../pages/userRegistration/users/EditUser'));
const AccessPrivileges = lazy(() => import('../pages/userRegistration/users/AcessPrivileges'));
const UsersRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={usersBreadcrumb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd {...props} component={Users} exact path={`${props?.path}`} name="Users" menuItems={MenuItemsSchema.USER_ITEMS} />
                <AuthGaurd
                    {...props}
                    component={RegisterNewUser}
                    exact
                    path={`${props?.path}/registerNewUser`}
                    name="Register New User"
                    menuItems={MenuItemsSchema.USER_ITEMS}
                />
                <AuthGaurd
                    {...props}
                    component={EditUser}
                    exact
                    path={`${props?.path}/view/:id`}
                    name="Edit User"
                    menuItems={MenuItemsSchema.USER_ITEMS}
                />
                <AuthGaurd
                    {...props}
                    component={EditUser}
                    exact
                    path={`${props?.path}/edit/:id`}
                    name="Edit User"
                    menuItems={MenuItemsSchema.USER_ITEMS}
                />
                <AuthGaurd
                    {...props}
                    component={EditUser}
                    exact
                    path={`${props?.path}/view/:id/:isDraft`}
                    name="Edit User"
                    menuItems={MenuItemsSchema.USER_ITEMS}
                />
                <AuthGaurd
                    {...props}
                    component={EditUser}
                    exact
                    path={`${props?.path}/edit/:id/:isDraft`}
                    name="Edit User"
                    menuItems={MenuItemsSchema.USER_ITEMS}
                />
                <AuthGaurd
                    {...props}
                    component={AccessPrivileges}
                    exact
                    path={`${props?.path}/accessPrivileges`}
                    name="Access Privileges"
                    menuItems={MenuItemsSchema.USER_ITEMS}
                />
                <AuthGaurd
                    {...props}
                    component={AccessPrivileges}
                    exact
                    path={`${props?.path}/exceptionTransaction`}
                    name="Access Privileges"
                    menuItems={MenuItemsSchema.USER_ITEMS}
                />
            </Switch>
        </div>
    );
};

export default UsersRouting;
