export const TableSchema = {
    PAY_COMMISSION: [
        {
            header: 'Pay Commission ID',
            field: 'ref',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Pay Commission ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px'
        },
        {
            header: 'Pay Commission',
            field: 'name',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Pay Commission',
            filterMatchMode: 'contains',
            filterElement: 'search',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '215px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            width: '120px',
            sortable: true,
            sortableDisabled: true,
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            body: '',
            width: '80px'
        }
    ],
    PAY_ENTITLEMENT: [
        {
            header: 'Pay Entitlement ID',
            field: 'ref',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Pay Entitlement ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px'
        },
        {
            header: 'Description',
            field: 'description',
            sortable: true,
            sortableDisabled: true,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Classification Level',
            field: 'classif_code_detail.name',
            sortable: true,
            sortableDisabled: true,
            filter: false,
            filterPlaceholder: 'Search by Employee Classification Level',
            filterMatchMode: 'contains',
            filterElement: 'search',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Pay Commission',
            field: 'pay_commission.name',
            sortable: true,
            sortableDisabled: true,
            filter: false,
            filterPlaceholder: 'Search by Pay Commission',
            filterMatchMode: 'contains',
            filterElement: null,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '215px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            sortable: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            body: '',
            width: '80px'
        }
    ],
    EMP_PAY_COMPONENT: [
        {
            header: 'Component ID',
            field: 'ref',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Component ID',
            filterMatchMode: 'contains',
            filterElement: 'search'
        },
        {
            header: 'Pay Entitlement ID',
            field: 'entitlement.ref',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Pay Entitlement ID',
            filterMatchMode: 'contains',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Component Type',
            field: 'component_type',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Component Type',
            filterMatchMode: 'contains',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Component Name',
            field: 'component_name',
            width: '160px',
            sortable: true,
            sortableDisabled: true,
            filter: false,
            filterPlaceholder: 'Search by Component Name',
            filterMatchMode: 'contains',
            fieldType: 'tooltip',
            checkField: 'have_sub_components',
            buttonType: 'popupcheck',
            filterElement: null
        },
        {
            header: 'Employee Classification Level',
            field: 'entitlement.classif_code_detail.name',
            width: '160px',
            sortable: true,
            sortableDisabled: true,
            filter: false,
            filterPlaceholder: 'Search by Employee Classification Level',
            filterMatchMode: 'contains',
            filterElement: null,
            fieldType: 'tooltip'
        },
        {
            header: 'Pay Commission',
            field: 'entitlement.pay_commission.name',
            width: '160px',
            sortable: true,
            sortableDisabled: true,
            filter: false,
            filterPlaceholder: 'Search by Pay Commission',
            filterMatchMode: 'contains',
            fieldType: 'tooltip',
            filterElement: null
        },
        {
            header: 'Recurring / Non-Recurring',
            field: 'is_recurring',
            width: '160px',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterPlaceholder: 'Search by Recurring / Non-Recurring',
            filterMatchMode: 'contains',
            filterElement: 'dropdown',
            filterOptions: [],
            fieldType: 'tooltip'
        },
        {
            header: 'Is Editable',
            field: 'is_editable',
            width: '100px',
            sortable: true,
            sortableDisabled: true,
            fieldType: 'tooltip'
        },
        {
            header: 'COA Code',
            field: 'coa_code',
            width: '160px',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterPlaceholder: 'Search by COA Code',
            filterMatchMode: 'contains',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Mandatory for all Employee Groups?',
            field: 'is_global',
            width: '160px',
            sortable: true,
            sortableDisabled: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Arrears Allowed?',
            field: 'arrears_allowed',
            width: '100px',
            sortable: true,
            sortableDisabled: true,
            fieldType: 'tooltip'
        },
        {
            header: 'HOA Code for Arrears',
            field: 'arrears_coa',
            width: '160px',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterPlaceholder: 'HOA Code for Arrears',
            filterMatchMode: 'contains',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterMatchMode: 'contains',
            filterElement: 'date',
            width: '200px',
            fieldType: 'tooltip'
        },

        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            body: '',
            width: '80px'
        }
    ],
    EMP_PAY_ENTITLEMENT_CMPNTS: [
        {
            header: 'Component ID',
            field: 'ref',
            width: '160px',
            sortable: true,
            filter: true,
            fieldType: 'button',
            buttonType: 'label',
            filterPlaceholder: 'Search by Component ID',
            filterMatchMode: 'contains',
            filterElement: null
        },
        {
            header: 'Component Type',
            field: 'component_type',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Component Type',
            filterMatchMode: 'contains',
            filterElement: null,
            fieldType: 'tooltip'
        },
        {
            header: 'Component Name',
            field: 'component_name',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Component Name',
            filterMatchMode: 'contains',
            fieldType: 'button',
            checkField: 'have_sub_components',
            buttonType: 'popupcheck',
            filterElement: null
        },
        {
            header: 'Recurring / Non-Recurring',
            field: 'is_recurring',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Recurring / Non-Recurring',
            filterMatchMode: 'contains',
            filterElement: null,
            fieldType: 'tooltip'
        },
        {
            header: 'Is Editable',
            field: 'is_editable',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'COA Code',
            field: 'coa_code',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by COA Code',
            filterMatchMode: 'contains',
            filterElement: null,
            fieldType: 'tooltip'
        },
        {
            header: 'Mandatory for all Employee Groups?',
            field: 'is_global',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Arrears Allowed?',
            field: 'arrears_allowed',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'HOA Code for Arrears',
            field: 'arrears_coa',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'HOA Code for Arrears',
            filterMatchMode: 'contains',
            filterElement: null,
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'version_date',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterMatchMode: 'contains',
            filterElement: 'date',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            body: '',
            width: '150px'
        }
    ],
    EMP_PAY_COMPONENT_VIEW_DETAILS: [
        {
            header: 'Component Name',
            field: 'sub_component_name',
            width: '160px'
        },
        {
            header: 'Recurring / Non-Recurring',
            field: 'is_recurring',
            width: '160px'
        },
        {
            header: 'Is Editable',
            field: 'is_editable',
            width: '100px'
        },
        {
            header: 'COA Code',
            field: 'coa_code',
            width: '160px'
        },
        {
            header: 'Mandatory for all Employee Groups?',
            field: 'is_global',
            width: '150px'
        },
        {
            header: 'Arrears Allowed?',
            field: 'arrears_allowed',
            width: '100px'
        },
        {
            header: 'HOA Code for Arrears',
            field: 'arrears_coa',
            width: '160px'
        }
    ],
    EMP_PAY_ENTITLEMENT_SUB_CMPNT: [
        {
            header: 'Sub Component Name',
            field: 'sub_component.name',
            width: '150px'
        },
        {
            header: 'Value Type',
            field: 'value_type',
            width: '100px'
        },
        {
            header: 'Employee Classification Level',
            field: 'classifLevel',
            fieldType: 'button',
            buttonType: 'label',
            buttonLabel: 'View Details',
            width: '150px'
        },
        {
            header: 'Recurring / Non-Recurring',
            field: 'is_recurring_label',
            width: '150px'
        },
        {
            header: 'Payout Month',
            field: 'payout_month_label',
            width: '150px'
        },
        {
            header: 'Mandatory for all Employee Groups ',
            field: 'is_global_label',
            width: '150px'
        },
        {
            header: 'Arrears Allowed?',
            field: 'arrears_allowed_label',
            width: '100px'
        },
        {
            header: 'COA Code',
            field: 'coa_code',
            width: '200px'
        },
        {
            header: 'HOA Code for Arrears',
            field: 'arrears_coa',
            width: '200px'
        },
        {
            header: 'isEditable',
            field: 'is_editing_label',
            width: '100px'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            view: true,
            width: '110px'
        }
    ],
    EMPLOYEE_PAY_ENTITLEMENTS: [
        {
            header: 'Pay Entitlement ID',
            field: 'ref',
            sortable: true,
            filter: true,
            fieldType: 'button',
            buttonType: 'label',
            filterPlaceholder: 'Search by Pay Entitlement ID',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Description',
            field: 'description',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Classification Level',
            field: 'classif_level_detail.name',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Employee Classification Level',
            filterMatchMode: 'contains',
            filterElement: null,
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Pay Commission',
            field: 'pay_commission.name',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Pay Commission',
            filterMatchMode: 'contains',
            filterElement: null,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Components',
            field: 'component',
            filter: false,
            fieldType: 'button',
            buttonType: 'label',
            buttonLabel: 'View Components',
            sortable: false,
            width: '170px'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'version_date',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterMatchMode: 'contains',
            filterElement: 'date',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Is Active',
            filterMatchMode: 'contains',
            filterElement: null,
            width: '120px',
            fieldType: 'tooltip'
        }
    ],
    PAY_COMPONENT: [
        {
            header: 'Pay Component ID',
            field: 'ref',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Pay Component ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '180px'
        },
        {
            header: 'Component Name',
            field: 'name',
            filter: true,
            expander: true,
            sortable: true,
            filterPlaceholder: 'Search by Component Name',
            filterMatchMode: 'contains',
            filterElement: 'search',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Has Sub-Components?',
            field: 'have_sub_components',
            width: '180px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '180px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '195px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            edit: true,
            delete: true,
            body: '',
            width: '100px'
        }
    ],
    ENTITLEMENT_CHANGE: [
        {
            header: 'Pay Entitlement Change ID',
            field: 'ref',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Pay Entitlement Change ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px'
        },
        {
            header: 'Pay Entitlement ID',
            field: 'entitle_ref',
            filter: true,
            expander: true,
            sortable: true,
            filterPlaceholder: 'Search by Pay Entitlement ID',
            filterMatchMode: 'contains',
            filterElement: 'search',
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Budget Office',
            field: 'budget_office_names',
            filter: true,
            expander: true,
            sortable: true,
            filterPlaceholder: 'Search by Budget Office',
            filterMatchMode: 'contains',
            filterElement: 'search',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Group',
            field: 'emp_group_names',
            filter: true,
            expander: true,
            sortable: true,
            filterPlaceholder: 'Search by Employee Group',
            filterMatchMode: 'contains',
            filterElement: 'search',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Component Names',
            field: 'component_names',
            filter: true,
            expander: true,
            sortable: true,
            filterPlaceholder: 'Search by Component Names',
            filterMatchMode: 'contains',
            filterElement: 'search',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '180px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '195px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            edit: true,
            delete: true,
            body: '',
            width: '80px'
        }
    ],
    SUB_COMPONENT: [
        {
            header: 'Sub-Component',
            field: 'name',
            fieldType: 'input',
            inputMaxlength: 20,
            alphabets: true
        },
        {
            header: '',
            fieldType: 'InlineActions',
            body: ''
        }
    ],
    PAY_ENTITLEMENT_COMPONENT_CLS_DETAIL: [
        {
            header: 'Employee Classification Level',
            field: 'classif_code_details.name',
            width: '150px'
        },
        {
            header: 'Value',
            field: 'value',
            width: '150px'
        }
    ],
    PAY_ENTITLEMENT_COMPONENT_CLS: [
        {
            header: 'Employee Classification Level',
            field: 'classif_code_details.name',
            width: '150px',
            fieldType: 'tooltip'
            /*
             * optionLabel: 'name',
             * fieldType: 'dropdownObj',
             * inputType: 'string',
             * inputPlaceholder: 'Select Employee Classification Level',
             * filter: false,
             */
        },
        {
            header: 'Value',
            field: 'value',
            width: '150px',
            fieldType: 'tooltip'
            /*
             * fieldType: 'clickable',
             * inputPlaceholder: 'Enter Value',
             * clickableLabel: 'Define Formula',
             * clickableRoute: 'popup',
             * inputMaxlength: 10,
             */
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '60px'
        }
    ],
    PAY_ENTITLEMENT_COMPONENT_APPLICABLE: [
        { header: 'Field Name', field: 'display_name', width: '100px', fieldType: 'tooltip' },
        { header: 'Type', field: 'display_type', width: '100px', fieldType: 'tooltip' },
        { header: 'Value', field: 'value', width: '100px', fieldType: 'tooltip' },
        { header: '', fieldType: 'actions', edit: true, delete: true, width: '60px' }
    ],
    EMPLOYEE_DETAILS: [
        {
            header: 'Employee Name & ID',
            field: 'nameAndID',
            fieldType: 'clickable',
            customView: true,
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Employee Name & ID',
            width: '230px'
        },
        {
            header: 'Employee Group',
            field: 'group_name',
            filter: true,
            filterPlaceholder: 'Employee Group',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            sortableDisabled: true,
            width: '220px',
            fieldType: 'tooltip'
        },
        {
            header: 'Budget Office',
            field: 'budget_office',
            filter: true,
            filterPlaceholder: 'Budget Office ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '220px',
            fieldType: 'tooltip'
        },
        {
            header: 'Deputation Office',
            field: 'deputation_office',
            filter: true,
            filterPlaceholder: 'Deputation Office ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '220px',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Status',
            field: 'active_status',
            filter: true,
            filterPlaceholder: 'Employee Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            sortable: true,
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Payroll Status',
            field: 'payroll_status',
            filter: true,
            filterPlaceholder: 'Payroll Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            sortable: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            sortable: true,
            width: '160px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            filterElement: 'date',
            filterOptions: [],
            filterPlaceholder: 'Last Update Date & Time',
            width: '200px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            width: '100px',
            body: ''
        }
    ],
    PAY_ENTITLEMENT_CHANGE: [
        {
            header: 'Pay Entitlement Change ID',
            field: 'ref',
            fieldType: 'clickable',
            customView: true,
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Pay Entitlement Change ID',
            width: '200px'
        },
        {
            header: 'Update',
            field: 'update',
            filter: true,
            filterPlaceholder: 'Update',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '100px'
        },
        {
            header: 'Employee Name & ID',
            field: 'empName',
            filter: true,
            filterPlaceholder: 'Employee Name & ID',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '180px'
        },
        {
            header: 'Budget Office',
            field: 'budgetOfc',
            filter: true,
            filterPlaceholder: 'Budget Office',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '150px'
        },
        {
            header: 'Component Name',
            field: 'component',
            filter: true,
            filterPlaceholder: 'Component Name',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '150px'
        },
        {
            header: 'Current Value',
            field: 'currentVal',
            width: '120px'
        },
        {
            header: 'Revised Value',
            field: 'revisedVal',
            width: '120px'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'version_date',
            filter: true,
            filterElement: 'date',
            filterOptions: [],
            filterPlaceholder: 'Last Update Date & Time',
            width: '180px'
        },
        {
            header: 'Is Active?',
            field: 'is_active',
            width: '120px'
        },
        {
            header: '',
            field: 'Actions',
            edit: true,
            delete: true,
            body: '',
            width: '150px'
        }
    ],
    ENTITLEMENT_CHANGES: [
        {
            header: 'Sl. No',
            field: 'slNo',
            width: '80px'
        },
        {
            header: 'Name',
            field: 'name',
            width: '100px'
        },
        {
            header: 'Component Type',
            field: 'compType',
            width: '100px'
        },
        {
            header: 'Value Type',
            field: 'valueType',
            width: '100px'
        },
        {
            header: 'Current Value',
            field: 'currentVal',
            width: '100px'
        },
        {
            header: 'Proposed Change',
            field: 'change',
            width: '100px'
        },
        {
            header: '',
            fieldType: 'actions',
            setting: true,
            body: '',
            width: '80px'
        }
    ],
    PAY_ENTITLE_EMPLOYEE_DETAILS: [
        {
            header: 'Employee Name & ID',
            field: 'ref',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Employee Name & ID',
            width: '200px'
        },
        {
            header: 'Employee Group',
            field: 'group',
            filter: true,
            filterPlaceholder: 'Employee Group',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '150px'
        },
        {
            header: 'Component',
            field: 'component',
            filter: true,
            filterPlaceholder: 'Component',
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            width: '120px'
        },
        {
            header: 'Current Value',
            field: 'currentVal',
            width: '130px'
        },
        {
            header: 'Variance',
            field: 'variance',
            width: '130px',
            fieldType: 'input',
            inputMaxlength: 10
        },
        {
            header: 'Revised Value',
            field: 'revisedVal',
            width: '130px'
        },
        {
            header: 'Remarks',
            field: 'remarks',
            fieldType: 'input',
            inputMaxlength: 30,
            width: '200px'
        },
        {
            header: 'Revised Monthly Pay',
            field: 'monthlyPay',
            fieldType: 'clickable',
            clickableRoute: 'popup',
            width: '170px'
        }
    ],
    REVISED_PAY: [
        {
            header: 'Sl. No',
            field: 'slNo',
            width: '40px'
        },
        {
            header: 'Employee Earnings & Deductions',
            field: 'component',
            filter: true,
            filterPlaceholder: 'Employee Earnings & Deductions',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Earning Amount',
            field: 'earningAmount',
            width: '70px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction Amount',
            field: 'deductionAmount',
            width: '80px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        }
    ],
    EMPLOYEE_GROUP: [
        {
            header: 'Employee Group ID',
            field: 'ref',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Employee Group ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Employee Group Name',
            field: 'name',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Employee Group Name',
            filterMatchMode: 'contains',
            filterElement: 'search',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Budget Office',
            field: 'budget_office',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Budget Office by ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Pay Entitlement ID',
            field: 'payEntitlement',
            filter: false,
            sortable: true,
            sortableDisabled: true,
            filterPlaceholder: 'Search by Pay Entitlement ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'HOA',
            field: 'hoa',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by HOA',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Select Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterMatchMode: 'contains',
            filterElement: 'date',
            width: '200px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            edit: true,
            delete: true,
            body: '',
            width: '80px'
        }
    ],
    EMPLOYEE_GROUP_PAYENTITLEMENT: [
        {
            header: 'Component Type',
            field: 'component_type',
            expander: true,
            sortable: false,
            filter: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Component Name',
            field: 'name',
            sortable: false,
            filter: false,
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Value Type',
            field: 'value_type',
            sortable: false,
            filter: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Value',
            field: 'value',
            fieldType: 'button',
            buttonType: 'popupcheck',
            sortable: false,
            filter: false,
            width: '300px'
        },
        {
            header: 'Mandatory',
            field: 'is_mandatory',
            fieldType: 'checkbox',
            width: '120px',
            headerAlign: 'center',
            bodyStyle: 'center'
        }
    ],
    EMPLOYEE_GROUP_COMPONENTVAL: [
        {
            header: 'Employee Classification Level',
            field: 'classifLevel',
            expander: true,
            sortable: false,
            filter: false,
            width: '40%'
        },
        {
            header: 'Value',
            field: 'value',
            sortable: false,
            filter: false,
            width: '60%'
        }
    ],
    CONFIGURE_COLUMN: [
        {
            header: '',
            field: 'checked',
            selectionMode: 'multiple',
            width: '60px',
            headerAlign: 'center',
            alignTo: 'center',
            bodyStyle: 'center',
            fieldType: 'checkbox'
        },
        {
            header: 'All Columns',
            field: 'header'
        }
    ],
    EMP_PAY_ENTITLEMENT: [
        {
            header: 'S.No.',
            field: 'slno',
            filterElement: null,
            sortable: true,
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Earnings And Deductions',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Employee Earnings And Deductions',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Earning Amount',
            field: 'earning_amt',
            filter: true,
            filterPlaceholder: 'Earning Amount',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction Amount',
            field: 'deduction_amt',
            filter: true,
            filterPlaceholder: 'Deduction Amount',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            field: 'Actions',
            fieldType: 'actions',
            width: '95px'
        }
    ],
    EMPLOLYEE_STATUS_HISTORY: [
        {
            header: 'Sl. No',
            field: 'slNo',
            width: '80px'
        },
        {
            header: 'Transaction ID',
            field: 'id',
            width: '100px'
        },
        {
            header: 'Transaction Type',
            field: 'type',
            width: '100px'
        },
        {
            header: 'Transaction Sub-Type',
            field: 'subtype',
            width: '100px'
        },
        {
            header: 'Transaction Date',
            field: 'transactionDate',
            width: '100px'
        },
        {
            header: 'WEF Date',
            field: 'wefDate',
            width: '100px'
        }
    ],
    EMP_PAYSLIP: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '50px'
        },
        {
            header: 'Components',
            field: 'name',
            width: '180px',
            fieldType: 'tooltip'
        }
    ],
    ENTITLE_CHANGE_CMP: [
        {
            header: 'Sl. No',
            field: 'slno',
            width: '50px',
            fieldType: 'tooltip'
        },
        {
            header: 'Component Name',
            field: 'name',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Component Type',
            field: 'type',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Value Type',
            field: 'value_type',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Classification Level',
            field: 'applicability',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Classification Value',
            field: 'classif_value_name',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Current Value',
            field: 'current_value',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Proposed Change',
            field: 'proposed_change',
            fieldType: 'appendLabel',
            checkType: 'FORMULA',
            appendLabel: '%',
            checkKey: 'value_type',
            width: '150px'
        },
        {
            header: '',
            fieldType: 'actions',
            settings: true,
            width: '50px'
        }
    ],
    EMPLOYEE_SPECIFIC_INFO: [
        {
            header: 'Skills',
            field: 'skills.name',
            fieldType: 'tooltip',
            sortable: true,
            width: '150px'
        },
        {
            header: 'Proficiency',
            field: 'proficiency',
            sortable: true,
            fieldType: 'tooltip',
            sortableDisabled: true,
            width: '120px'
        },
        {
            header: 'Experience in each skill',
            field: 'experience',
            sortable: true,
            sortableDisabled: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Employee_Qualificiations: [
        {
            header: 'Qualification',
            field: 'qualification',
            fieldType: 'tooltip',
            sortable: true,
            width: '150px'
        },
        {
            header: 'University',
            field: 'university',
            sortable: true,
            fieldType: 'tooltip',
            sortableDisabled: true,
            width: '120px'
        },
        {
            header: 'Certificate Number',
            field: 'certificate_number',
            sortable: true,
            sortableDisabled: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Year of Passing',
            field: 'year_passing',
            sortable: true,
            fieldType: 'tooltip',
            sortableDisabled: true,
            width: '120px'
        },
        {
            header: 'Grade',
            field: 'grade',
            sortable: true,
            sortableDisabled: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Percentage',
            field: 'percentage',
            sortable: true,
            fieldType: 'tooltip',
            sortableDisabled: true,
            width: '120px'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    ADDRESS_DETAILS: [
        {
            header: 'Address',
            field: 'line1',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'City',
            field: 'district',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Pin Code',
            field: 'pin_code',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Country',
            field: 'country_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'State',
            field: 'state_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub-District/Block/Tehsil',
            field: 'sub_district',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Village',
            field: 'village',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Panchayat',
            field: 'panchayat',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            validation: 'unique',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            width: '120px'
        }
    ],
    EMP_ADDRESS_DETAILS: [
        {
            header: 'Address',
            field: 'line1',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Location',
            field: 'district',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Pin Code',
            field: 'pin_code',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Country',
            field: 'country_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Region',
            field: 'state_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            validation: 'unique',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            width: '120px'
        }
    ],
    IA_EMPLOYEE_DETAILS: [
        {
            header: 'Employee Name & ID',
            field: 'nameAndID',
            // fieldType: 'clickable',
            // customView: true,
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Employee Name & ID',
            width: '230px'
        },
        // {
        //     header: 'MDA',
        //     field: 'mda',
        //     filter: true,
        //     filterPlaceholder: 'MDA',
        //     filterElement: 'search',
        //     filterMatchMode: 'contains',
        //     sortable: true,
        //     width: '220px',
        //     fieldType: 'tooltip'
        // },
        {
            header: 'Deputation Office',
            field: 'deputation_office',
            filter: true,
            filterPlaceholder: 'Deputation Office ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '220px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            sortable: true,
            width: '160px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            filterElement: 'date',
            filterOptions: [],
            filterPlaceholder: 'Last Update Date & Time',
            width: '200px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            width: '100px',
            body: ''
        }
    ]
};

export default TableSchema;
