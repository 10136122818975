import { serverDate } from '../util';
import React from 'react';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';

export const getFinancialPeriods = () => {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let currentDate = serverDate();
    let monthVal = currentDate.getMonth();
    let periodVals = [];
    let year = currentDate.getFullYear();

    if (monthVal === 2) {
        periodVals.push('Mar ' + year);
        --monthVal;
    }

    let month = months[monthVal];
    while (month !== 'Mar') {
        if (month === 'Dec') {
            --year;
        }
        periodVals.push(month + ' ' + year);
        --monthVal;
        if (monthVal < 0) {
            monthVal = 11;
        }
        month = months[monthVal];
    }
    return periodVals.reverse();
};

export const GenerateMonthList = (startMonth, endMonth, year) => {
    startMonth = startMonth?.charAt(0)?.toUpperCase() + startMonth?.slice(1);
    endMonth = endMonth?.charAt(0)?.toUpperCase() + endMonth?.slice(1);

    const finPeriods = [];
    const yearList = year?.split('-');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let startDate = new Date(Number(yearList[0]), months.indexOf(startMonth), 1);
    const endDate = new Date(Number(yearList[1]), months.indexOf(endMonth), 26);
    while (
        startDate.getFullYear() < endDate.getFullYear() ||
        (startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() <= endDate.getMonth())
    ) {
        let monthNum = startDate.getMonth();
        let year = startDate.getFullYear();
        finPeriods.push(months[monthNum] + ' ' + year);
        if (endDate.getMonth() === monthNum && endDate.getFullYear() === year) {
            break;
        }
        if (monthNum === 11) {
            year = year + 1;
            monthNum = -1;
        }
        startDate = new Date(year, monthNum + 1, 2);
    }
    return finPeriods;
};

export const getCurrentFinancialPeriod = () => {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let currentDate = serverDate();
    let monthVal = currentDate.getMonth();
    let year = currentDate.getFullYear();
    let month = months[monthVal];
    return month + ' ' + year;
};

export const getStartDate = (date) => {
    const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    startDate.setHours(5);
    startDate.setMinutes(30);
    const stDate = JSON.stringify(startDate);
    return stDate.replace(/"/g, '');
};
export const getEndTime = (date) => {
    const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 28, 89, 59);
    const stDate = JSON.stringify(startDate);
    return stDate.replace(/"/g, '');
};

export const roundFloat = (e) => {
    if (e.toString().split('.').length > 1 && e.toString().split('.')[1].length > 2) return parseFloat(parseFloat(e).toFixed(2));
    return e;
};

export const getFinancialYears = (fromD, toD) => {
    let fromDate = new Date(fromD);
    let toDate = new Date(toD);
    const fromYear = fromDate.getFullYear();
    let from = fromYear - 1;
    let to = fromYear;
    if (fromDate.getMonth() > 2) {
        from = fromYear;
        to = fromYear + 1;
    }
    const year = [];
    year.push(from + '-' + to);
    if (toDate) {
        while (to < toDate.getFullYear()) {
            from = to;
            to = from + 1;
            year.push(from + '-' + to);
        }
        if (toDate.getMonth() > 2 && toDate.getFullYear() > fromDate.getFullYear()) {
            year.push(to + '-' + (to + 1));
        }
    }
    return year;
};

export const getFormTypeAndRecordId = (path) => {
    let formType;
    const editExist = path.search('/edit') !== -1;
    let isDraft = path.search('/draft') !== -1;
    let isApproved = path.search('/approved') !== -1;
    let isExistApproved = path.search('/existapproved') !== -1;
    let isExistDraft = path.search('/existdraft') !== -1;
    const viewExist = path.search('/view') !== -1;
    const allExist = path.search('/all') !== -1;
    const createExist = path.search('/create') !== -1;
    const piViewExist = path.search('/piview') !== -1;
    let recordID = '';
    if (isDraft && editExist) {
        isDraft = true;
        formType = 'edit';
        recordID = path.split('/').reverse()[1];
    } else if (isExistApproved && editExist) {
        recordID = path.split('/').reverse()[1];
        isExistApproved = true;
        formType = 'edit';
    } else if (isExistApproved && viewExist) {
        recordID = path.split('/').reverse()[1];
        isExistApproved = true;
        formType = 'view';
    } else if (isExistDraft && editExist) {
        isExistDraft = true;
        formType = 'edit';
        recordID = path.split('/').reverse()[1];
    } else if (isExistDraft && viewExist) {
        isExistDraft = true;
        recordID = path.split('/').reverse()[1];
        formType = 'view';
    } else if (isApproved && editExist) {
        recordID = path.split('/').reverse()[1];
        isApproved = true;
        formType = 'edit';
    } else if (isApproved && viewExist) {
        recordID = path.split('/').reverse()[1];
        isApproved = true;
        formType = 'view';
    } else if (editExist) {
        formType = path.split('/').reverse()[1];
        recordID = path.split('/').reverse()[0];
    } else if (isDraft && viewExist) {
        recordID = path.split('/').reverse()[1];
        formType = 'view';
    } else if ((!isDraft && viewExist) || piViewExist) {
        recordID = path.split('/').reverse()[0];
        formType = 'view';
    } else if (createExist) {
        formType = 'create';
    } else if (allExist) {
        formType = 'all';
    }
    return {
        formType: formType,
        recordID: recordID,
        isDraft: isDraft,
        isApproved: isApproved,
        isExistApproved: isExistApproved,
        isExistDraft: isExistDraft
    };
};

export const TableFooterGroup = (footerButtonLabel, columns, handleClick, loading, mode, showForm, colSpan) => (
    <ColumnGroup>
        <Row>
            <Column
                footer={
                    <React.Fragment>
                        {!showForm && mode !== 'view' && (
                            <div className="comment-header" onClick={() => handleClick()}>
                                <span className="comment-color pointer" disabled={loading}>
                                    +&nbsp;{footerButtonLabel}
                                </span>
                            </div>
                        )}
                    </React.Fragment>
                }
                colSpan={colSpan ? colSpan : columns?.length}
            />
        </Row>
    </ColumnGroup>
);

export const InfoMessage = (message) => {
    return (
        <div className="display-flex justify-flex-end">
            <div className="display-flex m-b-10">
                <span className="icon-primary fs-15 info-message p_5">
                    <img className="m-r-10" src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
                    {message}
                </span>
            </div>
        </div>
    );
};
