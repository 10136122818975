import React, { useState, useEffect } from 'react';
import { Button, Tabs, Tab } from 'appkit-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import Schema from '../../../schema/AppSchema';
import { useQuery, useMutation } from '@apollo/client';
import FDataTable from '../../../components/fDataTable/FDataTable';
import TableSchema from '../../../commons/TableSchema';
import { Toast } from 'primereact/toast';
import AddNUpdateMasterFrom from './MastersForm';
import { getDate, serverDate } from '../../../util';
import './Masters.scss';
import IsAuthorized from '../../../commons/IsAuthorized';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';

const Masters = () => {
    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.COA_ITEMS.find((a) => a.routerLink === loc[1]);
    const date = serverDate();
    const [state, setState] = useState({
        financialYear: {},
        edit: false,
        showPopup: false,
        finYear: date.getFullYear() + '-' + (date.getFullYear() + 1),
        selectedRow: {}
    });
    const [growl, setGrowl] = useState();
    const columns = TableSchema.COA_MASTERS;
    const [yearList, setYearsList] = useState();

    const {
        loading,
        data: yearData,
        refetch: refetchYears
    } = useQuery(Schema.MastersAdmFinYears, {
        variables: { orderBy: { name: 'desc' } },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            yearData.admFinYears.forEach((year) => {
                /*
                 * year.master_start_date_local = getDate(year.master_start_date);
                 * year.master_end_date_local = getEndDateTime(year.master_end_date);
                 */
                year.start_date_local = getStartDateTime(year.start_date);
                year.end_date_local = getEndDateTime(year.end_date);
            });
            setYearsList(yearData);
        }
    });

    useEffect(() => {
        if (!loading && yearData) {
            let currentYear = yearData.admFinYears.filter((year) => year.name === state.finYear);
            if (currentYear.length > 0) {
                setState({ ...state, financialYear: currentYear[0], edit: true, loading: false });
            }
        }
        // eslint-disable-next-line
    }, [loading, yearData]);

    const getEndDateTime = (endDate) => {
        endDate = getDate(endDate);
        endDate = endDate.replace('05:30 AM', '11:59 PM');
        return endDate;
    };
    const getStartDateTime = (endDate) => {
        endDate = getDate(endDate);
        endDate = endDate.replace('05:30 AM', '12:00 AM');
        return endDate;
    };
    const [createRow, { loading: creating }] = useMutation(Schema.createOneAdmFinYear, {
        onCompleted: (data) => {
            if (data) {
                setState({ ...state, edit: true, financialYear: data.createOneAdmFinYear });
                growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_COA.COAStructureCreate });
            }
            refetchYears().then((response) => {
                if (response.data?.admFinYears) {
                    response.data.admFinYears.forEach((year) => {
                        /*
                         * year.master_start_date_local = getDate(year.master_start_date);
                         * year.master_end_date_local = getEndDateTime(year.master_end_date);
                         */
                        year.start_date_local = getStartDateTime(year.start_date);
                        year.end_date_local = getEndDateTime(year.end_date);
                    });
                    setYearsList(response.data);
                }
            });
        },

    });

    const [updateRow, { loading: updating }] = useMutation(Schema.updateOneAdmFinYear, {
        onCompleted: (updatedData) => {
            if (updatedData) {
                growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_COA.COAStructureUpdate });
            }
            refetchYears().then((response) => {
                if (response.data?.admFinYears) {
                    response.data.admFinYears.forEach((year) => {
                        /*
                         * year.master_start_date_local = getDate(year.master_start_date);
                         * year.master_end_date_local = getDate(year.master_end_date);
                         */
                        year.start_date_local = getStartDateTime(year.start_date);
                        year.end_date_local = getEndDateTime(year.end_date);
                    });
                    setYearsList(response.data);
                }
            });
        },

    });

    const actionTemplate = (rowData) => {
        if (window.INTERNAL_AUDIT ? true : rowData.id === state.financialYear.id) {
            return (
                <div>
                    <IsAuthorized
                        privRequired={Privileges.UPDATE}
                        officeCode={Privileges.OFFICE_CODE}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                disabled={rowData?.structures?.length > 0}
                                onClick={() => setState({ ...state, showPopup: true, selectedRow: rowData })}
                            >
                                <img
                                    title="Edit"
                                    className="pointer"
                                    src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                    alt="Edit Icon"
                                    height="20px"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                </div>
            );
        }
    };

    if (state.edit) {
        columns[columns.length - 1].body = actionTemplate;
    }

    return (
        <div className={`masters-div ${loading || creating || updating ? 'mask' : ''}`}>
            {(loading || creating || updating) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <Tabs defaultValue="structure_conf" className="h-100 a-tab-item-padding-10">
                <Tab value="structure_conf" label="Financial Years">
                    <div className="page-body">
                        <Toast ref={(el) => setGrowl(el)} />
                        <div className="page-header">
                            <span className="light-color">Financial Years</span>
                        </div>
                        {!loading && !state.edit && (
                            <div className="row m-0 p-t-20">
                                <div className="col-md-12 p-l-20 p-r-20">
                                    <div className="float-right">
                                        <IsAuthorized
                                            privRequired={Privileges.CREATE}
                                            officeCode={Privileges.OFFICE_CODE}
                                            yes={() => (
                                                <div>
                                                    <span className="p-2">
                                                        <img
                                                            src={process.env.PUBLIC_URL + '/assets/icons/outline-information.svg'}
                                                            alt="Pending task"
                                                        />
                                                        <span className="info">
                                                            (Define the period for allowing user to create COA structure for the next F.Y.)
                                                        </span>
                                                    </span>
                                                    <Button size="md" onClick={() => setState({ ...state, showPopup: true })}>
                                                        Define Period
                                                    </Button>
                                                </div>
                                            )}
                                        ></IsAuthorized>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="coa-master-page-content">
                            <div className="col-md-12 p-0">
                                {!loading && (
                                    <FDataTable
                                        rows={yearList?.admFinYears}
                                        columns={columns}
                                        noResizableColumns={true}
                                        emptyMessage={'Financial Years are yet to be created'}
                                        className="secondary-table"
                                        scrollable
                                    ></FDataTable>
                                )}
                            </div>
                        </div>
                        {state.showPopup && (
                            <AddNUpdateMasterFrom
                                finYear={state.finYear}
                                selectedRow={state.selectedRow}
                                growl={growl}
                                showModal={state.showPopup}
                                updateRow={updateRow}
                                edit={state.edit}
                                createRow={createRow}
                                handleCancel={() => setState({ ...state, showPopup: false })}
                            ></AddNUpdateMasterFrom>
                        )}
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
};

export default Masters;
