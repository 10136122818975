import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import '../../../../styles/Dialog.scss';
import TableSchema from '../../CMTableSchema';
import CommonInlineTable from '../../../../components/commonTable/CommonInlineTable';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';

export function AdvanceRecoveryModal(props) {
    let formObj = {
        rows: JSON.parse(JSON.stringify(props.advRows)) || [],
        totalAmount: JSON.parse(JSON.stringify(props?.currentDetailsObj?.adavce_recovery)) || ''
    };
    const [formState, setFormState] = useState(formObj);

    let viewMode = TableSchema.CONTCT_PAY_ADVNCE.filter((a) => a.fieldType !== 'Actions');
    let tablecolms = props.formType !== 'view' ? TableSchema.CONTCT_PAY_ADVNCE : viewMode;

    const [state] = useState({
        columns: tablecolms,
        sumOfDeductions: getSumOfDeductions()
    });

    function getSumOfDeductions() {
        let amount = 0;
        if (!isNaN(parseFloat(props.currentDetailsObj.security_depst_dedct))) {
            amount = amount + parseFloat(props.currentDetailsObj.security_depst_dedct);
        }
        if (!isNaN(parseFloat(props.currentDetailsObj.other_deduction))) {
            amount = amount + parseFloat(props.currentDetailsObj.other_deduction);
        }
        if (isNaN(amount)) amount = parseFloat(props.currentDetailsObj.amt_inc_tax);
        else amount = parseFloat(props.currentDetailsObj.amt_inc_tax) - amount;
        return amount.toFixed(2);
    }

    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total Advance" colSpan={2} />
                <Column footer={formState?.totalAmount} footerStyle={{ textAlign: 'right' }} />
                <Column />
                {props.formType !== 'view' && <Column />}
            </Row>
        </ColumnGroup>
    );

    const handleRowInputChange = (rowData, column, index, e) => {
        if (column.field === 'adv_inc_amount' && (isNaN(e) || parseFloat(e) > state.sumOfDeductions)) {
            return;
        }
        formState.rows[index.rowIndex][column.field] = e;
        setFormState({ ...formState, rows: formState.rows });
    };

    const hadleRowEdit = (rowData, column, index) => {
        formState.rows[index.rowIndex].enableEdit = true;
        setFormState({ ...formState, rows: formState.rows });
    };

    const hadleRowSave = (rowData, column, index) => {
        if (rowData.adv_inc_amount?.trim() === '') {
            props.growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.AmountMandatory });
            return;
        }
        let amount = 0;
        formState.rows[index.rowIndex].enableEdit = false;
        formState.rows.forEach((a) => {
            if (a.adv_inc_amount) amount = amount + parseFloat(a.adv_inc_amount);
        });
        if (isNaN(amount)) amount = '';
        else amount = amount.toFixed(2);
        setFormState({ ...formState, rows: formState.rows, totalAmount: amount });
    };

    const handleCancel = () => {
        setFormState(formObj);
        props.handleCancel();
    };

    const disableSave = () => {
        return formState.rows.some((a) => a.enableEdit);
    };

    return (
        <Modal visible={props.showModal} onCancel={handleCancel} className="common_css xl-modal add_user" backdropClosable={false}>
            <ModalHeader>Advance Recovery</ModalHeader>
            <ModalBody>
                <div className="Users row">
                    <CommonInlineTable
                        columns={state.columns}
                        handleRowInputChange={handleRowInputChange}
                        hadleRowEdit={hadleRowEdit}
                        hadleRowSave={hadleRowSave}
                        noPaginator={true}
                        rows={formState.rows}
                        footerGroup={footerGroup}
                        emptyMessage="Advances are yet to be created"
                        hideDel={true}
                        formType={props.formType}
                    />
                </div>
            </ModalBody>
            <ModalFooter className={props?.loading ? 'mask p-10' : 'p-10'}>
                <Button
                    size="sm"
                    disabled={disableSave()}
                    kind="primary"
                    className="m-r-10"
                    onClick={() => {
                        props.handleSave(formState);
                    }}
                >
                    OK
                </Button>
                <Button size="sm" kind="secondary" className="" onClick={() => handleCancel()}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default AdvanceRecoveryModal;
