import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Schema from '../../../../schema/AppSchema';
import { employeeClient } from '../../../../apollo';
import { DownloadFile } from '../../../../commons/Downloader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Modal, ModalHeader, ModalBody } from 'appkit-react';
import { Toast } from 'primereact/toast';
import '../../../../components/pdf/printBill.scss';
import PrintTable from '../../../../components/printTable/PrintTable';
import { getDate } from '../../../../util';
import TableSchema from '../../TableSchema';
import '../../../../styles/utils.scss';

const PersonnelBillPreview = (props) => {
    const [growl, setGrowl] = useState();
    let journeyDetails = TableSchema.JOURNEY_DETAILS.filter((a) => {
        a.width = '20%';
        return a.fieldType !== 'actions';
    });
    let tcpe = TableSchema.Transportation_Charges_of_Personnel_Effect.filter((a) => {
        a.width = '20%';
        return a.fieldType !== 'actions';
    });
    let tcpc = TableSchema.Transportation_Charges_of_Private_Conveyance.filter((a) => {
        a.width = '20%';
        return a.fieldType !== 'actions';
    });
    let stayDetails = TableSchema.Stay_Details.filter((a) => {
        a.width = '20%';
        return a.fieldType !== 'actions';
    });
    let dayAllowance = TableSchema.Day_Allowance.filter((a) => {
        a.width = '20%';
        return a.fieldType !== 'actions';
    });
    let transferCharges = TableSchema.Transfer_Chargers.filter((a) => {
        a.width = '20%';
        return a.fieldType !== 'actions';
    });
    let familyMembers = TableSchema.Family_Members.filter((a) => {
        a.width = '20%';
        return a.fieldType !== 'actions';
    });
    let deductions = TableSchema.Deductions.filter((a) => {
        a.width = '20%';
        return a.fieldType !== 'actions';
    });

    const [printPDF, { loading }] = useMutation(Schema.generate_pdf, {
        fetchPolicy: 'no-cache',
        client: employeeClient,
        onCompleted: (data) => {
            if (data?.generate_pdf) {
                DownloadFile(data.generate_pdf.id, data.generate_pdf.input_name);
                growl.show({ life: 6000, severity: 'success', summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.PersonnelBill });
            }
        },

    });

    const printPdf = () => {
        let fileName = 'Personnel_Bill_' + props?.dataObj?.ref;
        let eleId = document.getElementById('print-to-pdf');
        printPDF({
            variables: {
                html_str: new XMLSerializer().serializeToString(eleId),
                file_name: fileName + '.pdf'
            }
        });
    };

    const getEmpName = () => {
        if (props?.formState?.BILLS?.employee?.first_name && props?.formState?.BILLS?.employee?.last_name) {
            return props?.formState?.BILLS?.employee?.first_name + ' ' + props?.formState?.BILLS?.employee?.last_name;
        } else if (props?.formState?.BILLS?.employee?.first_name && !props?.formState?.BILLS?.employee?.last_name) {
            return props?.formState?.BILLS?.employee?.first_name;
        } else if (!props?.formState?.BILLS?.employee?.first_name && props?.formState?.BILLS?.employee?.last_name) {
            return props?.formState?.BILLS?.employee?.last_name;
        }
        return '';
    };

    return (
        <Modal
            visible={props.showModal}
            onCancel={props.handleCancel}
            className="master_css entittlement_change_modal"
            backdropClosable={false}
        >
            <ModalHeader>
                <span>Personnel Bill</span>
                <img
                    className="payslip-icon pointer m-l-auto m-r-10"
                    title="Print"
                    src={process.env.PUBLIC_URL + '/assets/icons/Print.svg'}
                    alt={'print-icon'}
                    onClick={printPdf}
                ></img>
            </ModalHeader>
            <ModalBody>
                <Toast ref={(el) => setGrowl(el)} />
                {loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                <div className="page-body boder-none" id="print-to-pdf">
                    <div className="page-header m-b-10">
                        <span className="light-color">
                            <b>Personnel Bill ID : {props?.dataObj?.ref}</b>
                        </span>
                    </div>
                    <div className="p-10">
                        <div className="w-100 display-flex m-b-10">
                            <div className="w-50 f-14">
                                <b>Financial Year : </b>
                                <span> {props?.formState?.BILLS?.finYear} </span>
                            </div>
                            <div className="w-50 f-14">
                                <b>Financial Period : </b>
                                <span>{props?.formState?.BILLS?.finPeriod}</span>
                            </div>
                        </div>
                        <div className="w-100 display-flex m-b-10">
                            <div className="w-50 f-14">
                                <b>Personnel Bill Type : </b>
                                <span> {props?.formState?.BILLS?.billType?.code} </span>
                            </div>
                            <div className="w-50 f-14">
                                <b>Personnel Bill SubType : </b>
                                <span>{props?.formState?.BILLS?.billSubType?.code}</span>
                            </div>
                        </div>
                        <div className="w-100 display-flex m-b-10">
                            <div className="w-50 f-14">
                                <b>Employee ID : </b>
                                <span> {props?.formState?.BILLS?.employee?.ref} </span>
                            </div>
                            <div className="w-50 f-14">
                                <b>Employee Name : </b>
                                <span>{getEmpName()}</span>
                            </div>
                        </div>
                        <div className="w-100 display-flex m-b-10">
                            <div className="w-50 f-14">
                                <b>Budget Office : </b>
                                <span>
                                    {props?.formState?.BILLS?.employee?.budget_office +
                                        ' - ' +
                                        props?.formState?.BILLS?.employee?.budget_office_name}
                                </span>
                            </div>
                            <div className="w-50 f-14">
                                <b>Deputation Office : </b>
                                <span>
                                    {props?.formState?.BILLS?.employee?.deputation_office +
                                        ' - ' +
                                        props?.formState?.BILLS?.employee?.deputation_office_name}
                                </span>
                            </div>
                        </div>
                        <div className="w-100 display-flex m-b-10">
                            {props.formState.BILLS?.billType?.config_details[0]?.include_gpf_pran_epf && (
                                <div className="w-50 f-14">
                                    <b>GPF/PRAN/EPF : </b>
                                    <span>
                                        {props?.formState?.BILLS?.employee?.special_info?.gpf_type +
                                            ' - ' +
                                            props?.formState?.BILLS?.employee?.special_info?.gpf_num}
                                    </span>
                                </div>
                            )}
                            {props.formState.BILLS?.billType?.config_details[0]?.include_superannuation_date && (
                                <div className="w-50 f-14">
                                    <b>Superannuation Date : </b>
                                    <span>{getDate(props?.formState?.BILLS?.employee?.special_info?.super_annuation_date, true)}</span>
                                </div>
                            )}
                        </div>
                        <div className="w-100 display-flex m-b-10">
                            <div className="w-100 f-14">
                                <b>HOA : </b>
                                <span> {props?.formState?.BILLS?.hoa} </span>
                            </div>
                        </div>
                        <div className="w-100 display-flex m-b-10">
                            <div className="w-100 f-14">
                                <b>Gross Bill Amount : </b>
                                <span> {props?.formState?.BILLS?.grossBillAmt} </span>
                            </div>
                        </div>

                        <div className="f-14 w-100 display-flex m-b-10 word-break-word">
                            <b>Bill Description : </b>
                            <span className="w-80"> {props?.formState?.BILLS?.billDescription}</span>
                        </div>
                        {props.formState.BILLS?.billType?.config_details[0]?.include_sanction_details && (
                            <>
                                <hr className="m-t-30 m-b-20" />
                                <div className="w-100 m-b-10">
                                    <b className="f-16">Sanction Details </b>
                                </div>
                                <div className="w-100 display-flex m-b-10">
                                    <div className="w-50 f-14 word-break-word">
                                        <b>Sanction Number : </b>
                                        <span> {props?.formState?.SANCTION_DETAILS?.number} </span>
                                    </div>
                                    <div className="w-50 f-14">
                                        <b>Sanction Date : </b>
                                        <span>{getDate(props?.formState?.SANCTION_DETAILS?.date, true)}</span>
                                    </div>
                                </div>
                                <div className="w-100 display-flex m-b-10">
                                    <div className="w-50 f-14 word-break-word">
                                        <b>Sanctioning Authority : </b>
                                        <span> {props?.formState?.SANCTION_DETAILS?.authority} </span>
                                    </div>
                                    <div className="w-50 f-14 word-break-word">
                                        <b>Sanction Amount : </b>
                                        <span>{props?.formState?.SANCTION_DETAILS?.amount}</span>
                                    </div>
                                </div>
                            </>
                        )}
                        {props.formState.BILLS?.billType?.config_details[0]?.include_journey_details && (
                            <>
                                <hr className="m-t-30 m-b-20" />
                                <div className="w-100 m-b-10">
                                    <b className="f-16">Journey Details</b>
                                </div>
                                <div className="w-100 m-b-20 f-14">
                                    <PrintTable
                                        rows={props.formState?.Journey_Details}
                                        columns={journeyDetails}
                                        footerGroup={true}
                                        headerGroup={true}
                                        table="Journey_Details"
                                    />
                                </div>
                            </>
                        )}
                        {props.formState.BILLS?.billType?.config_details[0]?.include_personnel_expenses && (
                            <>
                                <hr className="m-t-30 m-b-20" />
                                <div className="w-100 m-b-10">
                                    <b className="f-16">Transportation Charges of Personnel Effect </b>
                                </div>
                                <div className="w-100 m-b-20 f-14">
                                    <PrintTable
                                        rows={props.formState?.Transportation_Charges_of_Personnel_Effect}
                                        columns={tcpe}
                                        footerGroup={true}
                                        table="Transportation_Charges_of_Personnel_Effect"
                                    />
                                </div>
                            </>
                        )}
                        {props.formState.BILLS?.billType?.config_details[0]?.include_private_expenses && (
                            <>
                                <hr className="m-t-30 m-b-20" />
                                <div className="w-100 m-b-10">
                                    <b className="f-16">Transportation Charges of Private Conveyance </b>
                                </div>
                                <div className="w-100 m-b-20 f-14">
                                    <PrintTable
                                        rows={props.formState?.Transportation_Charges_of_Private_Conveyance}
                                        columns={tcpc}
                                        footerGroup={true}
                                        table="Transportation_Charges_of_Private_Conveyance"
                                    />
                                </div>
                            </>
                        )}
                        {props.formState.BILLS?.billType?.config_details[0]?.include_stay_details && (
                            <>
                                <hr className="m-t-30 m-b-20" />
                                <div className="w-100 m-b-10">
                                    <b className="f-16">Stay Details </b>
                                </div>
                                <div className="w-100 m-b-20 f-14">
                                    <PrintTable
                                        rows={props.formState?.Stay_Details}
                                        columns={stayDetails}
                                        footerGroup={true}
                                        table="Stay_Details"
                                    />
                                </div>
                            </>
                        )}
                        {props.formState.BILLS?.billType?.config_details[0]?.include_day_allowances && (
                            <>
                                <hr className="m-t-30 m-b-20" />
                                <div className="w-100 m-b-10">
                                    <b className="f-16">Day Allowance</b>
                                </div>
                                <div className="w-100 m-b-20 f-14">
                                    <PrintTable
                                        rows={props.formState?.Day_Allowance}
                                        columns={dayAllowance}
                                        footerGroup={true}
                                        table="Day_Allowance"
                                    />
                                </div>
                            </>
                        )}
                        {props.formState.BILLS?.billType?.config_details[0]?.include_transfer_charges && (
                            <>
                                <hr className="m-t-30 m-b-20" />
                                <div className="w-100 m-b-10">
                                    <b className="f-16">Transfer Chargers </b>
                                </div>
                                <div className="w-100 m-b-20 f-14">
                                    <PrintTable
                                        rows={props.formState?.Transfer_Chargers}
                                        columns={transferCharges}
                                        footerGroup={true}
                                        table="Transfer_Charges"
                                    />
                                </div>
                            </>
                        )}
                        {props.formState.BILLS?.billType?.config_details[0]?.include_family_members && (
                            <>
                                <hr className="m-t-30 m-b-20" />
                                <div className="w-100 m-b-10">
                                    <b className="f-16">Family Members </b>
                                </div>
                                <div className="w-100 m-b-20 f-14">
                                    <PrintTable
                                        rows={props.formState?.Family_Members}
                                        columns={familyMembers}
                                        footerGroup={true}
                                        table="Family_Members"
                                    />
                                </div>
                            </>
                        )}
                        {props.formState.BILLS?.billType?.include_additional_details &&
                            props.additionalDetailsObj?.isVisibleObj &&
                            Object.keys(props.additionalDetailsObj.isVisibleObj).some(
                                (k) => props.additionalDetailsObj.isVisibleObj[k]
                            ) && (
                                <>
                                    <hr className="m-t-30 m-b-20" />
                                    <div className="w-100 m-b-10">
                                        <b className="f-16">Additional Bill Details </b>
                                    </div>
                                    <div className="w-100 display-flex m-b-10">
                                        {props.additionalDetailsObj?.isVisibleObj?.EL_Encashment__No_of_Days && (
                                            <div className="w-50 f-14">
                                                <b>EL Encashment - No. of Days : </b>
                                                <span> {props?.formState?.Additional_Bill_Details?.encashment_days} </span>
                                            </div>
                                        )}
                                        {props.additionalDetailsObj?.isVisibleObj?.Block_From__Date && (
                                            <div className="w-50 f-14">
                                                <b>Block From - Date : </b>
                                                <span>{getDate(props?.formState?.Additional_Bill_Details?.block_from_date, true)}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-100 display-flex m-b-10">
                                        {props.additionalDetailsObj?.isVisibleObj?.Block_To__Date && (
                                            <div className="w-50 f-14">
                                                <b>Block To - Date : </b>
                                                <span> {getDate(props?.formState?.Additional_Bill_Details?.block_to_date, true)} </span>
                                            </div>
                                        )}
                                        {props.additionalDetailsObj?.isVisibleObj?.Purpose_of_Journey && (
                                            <div className="w-50 f-14 word-break-word">
                                                <b>Purpose of the Journey : </b>
                                                <span>{props?.formState?.Additional_Bill_Details?.journey_purpouse}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-100 display-flex m-b-10">
                                        {props.additionalDetailsObj?.isVisibleObj?.No_of_Leave_days && (
                                            <div className="w-50 f-14">
                                                <b>No. of Leave Days : </b>
                                                <span> {props?.formState?.Additional_Bill_Details?.no_of_leaves} </span>
                                            </div>
                                        )}
                                        {props.additionalDetailsObj?.isVisibleObj?.From_Date && (
                                            <div className="w-50 f-14">
                                                <b>From Date : </b>
                                                <span>{getDate(props?.formState?.Additional_Bill_Details?.from_date, true)}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-100 display-flex m-b-10">
                                        {props.additionalDetailsObj?.isVisibleObj?.To_Date && (
                                            <div className="w-50 f-14">
                                                <b>To Date : </b>
                                                <span> {getDate(props?.formState?.Additional_Bill_Details?.to_date, true)} </span>
                                            </div>
                                        )}
                                        {props.additionalDetailsObj?.isVisibleObj?.Headquarter && (
                                            <div className="w-50 f-14 word-break-word">
                                                <b>Headquarter : </b>
                                                <span>{props?.formState?.Additional_Bill_Details?.headquarter}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-100 display-flex m-b-10 word-break-word">
                                        {props.additionalDetailsObj?.isVisibleObj?.HeadquarterOld && (
                                            <div className="w-50 f-14">
                                                <b>Headquarter(Old) : </b>
                                                <span> {props?.formState?.Additional_Bill_Details?.headquarter_old} </span>
                                            </div>
                                        )}
                                        {props.additionalDetailsObj?.isVisibleObj?.HeadquarterNew && (
                                            <div className="w-50 f-14 word-break-word">
                                                <b>Headquarter(New) : </b>
                                                <span>{props?.formState?.Additional_Bill_Details?.headquarter_new}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-100 display-flex m-b-10 ">
                                        {props.additionalDetailsObj?.isVisibleObj?.Order_No && (
                                            <div className="w-50 f-14 word-break-word">
                                                <b>Order No. : </b>
                                                <span> {props?.formState?.Additional_Bill_Details?.order_no} </span>
                                            </div>
                                        )}
                                        {props.additionalDetailsObj?.isVisibleObj?.Other_Expenses && (
                                            <div className="w-50 f-14 word-break-word">
                                                <b>Other Expenses : </b>
                                                <span>{props?.formState?.Additional_Bill_Details?.other_expenses}</span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                        {props.formState.BILLS?.billType?.include_deductions && (
                            <>
                                <hr className="m-t-30 m-b-20" />
                                <div className="w-100 m-b-10">
                                    <b className="f-16">Deductions </b>
                                </div>
                                <div className="w-100 m-b-20 f-14">
                                    <PrintTable
                                        rows={props.formState?.Deductions}
                                        columns={deductions}
                                        footerGroup={false}
                                        table="Payment_Status"
                                    />
                                </div>
                            </>
                        )}
                        <div className="comment-header reviced-color">
                            <div>Net Payment : {props.netPay()}</div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default PersonnelBillPreview;
