/* eslint-disable max-depth */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'appkit-react';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import IsAuthorized from '../../../../../../commons/IsAuthorized';
import { MenuItemsSchema } from '../../../../../../commons/MenuItemsSchema';
import RecallButton from '../../../../../../commons/RecallButton';
import FDataTable from '../../../../../../components/fDataTable/FDataTable';
import TopCardComponent from '../../../../../../components/topComponents/TopCardComponent';
import TableSchema from '../../GMTableSchema';
import { getDate, convertTextCase, getUTCGteLteFromDate } from '../../../../../../util';
import GMQueries from '../../GMQueries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { iaClient, eventClient } from '../../../../../../apollo';
import AuditComponent from '../../../../../../components/auditLog/AuditComponent';
import ConfirmationModal from '../../../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import AppSchema from '../../../../../../schema/AppSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { ApprovedRecordApproveCheckVariable, ApprovedRecordEntityCheckVariable } from '../../../../../../commons/CommonVariables';

const AuditOpinionGuideline = () => {
    let Privileges = '';
    let loc = window.location.href.split('#');
    const projectURL = 'guidelineauditopinion';
    if (loc.length > 0) {
        MenuItemsSchema.GLOBAL_MASTERS.forEach((a) => {
            let val = '';
            if (a.subMenu)
                val = a.subMenu.find((b) => {
                    return loc[1].includes(b.routerLink);
                });
            else val = MenuItemsSchema.GLOBAL_MASTERS.find((v) => loc[1].includes(v.routerLink));
            if (val) {
                Privileges = val;
                return;
            }
        });
    }
    let history = useHistory();
    let tableColumns = JSON.parse(JSON.stringify(TableSchema.AUDIT_OBJECTIVE_GUILDELINE));

    const [state, setState] = useState({
        loading: true,
        where: { is_latest: { equals: true } },
        columns: tableColumns,
        selectedRow: {},
        selectedData: {},
        showDeleteConfirmation: false,
        selectedRec: {}
    });

    const [growl, setGrowl] = useState();
    const [recallLoader, setRecallLoader] = useState(false);
    const [selected, setSelected] = useState({});
    const [totalRecords, setTotalRecords] = useState();
    const [rows, setRows] = useState();
    const [recallObj, setRecallObj] = useState();
    const [tableLoader, setTableLoader] = useState(false);
    const [reference, setReference] = useState({});
    const [showAudit, setShowAudit] = useState(false);
    const [skip, setSkip] = useState(0);
    const [pageRows, setPageRows] = useState(10);
    const [searchFieldKey, setSearchFieldKey] = useState({});
    const [sortField, setSortField] = useState('version_date');
    const [sortOrder, setSortOrder] = useState('desc');
    const [cardCount, setCardCount] = useState('');
    const [take, setTake] = useState(10);
    const [where, setWhere] = useState({ is_latest: { equals: true } });
    const [actions, setActions] = useState('');

    const assignedTo = {
        header: 'Assigned To',
        field: 'assignedTo',
        filter: true,
        sortable: true,
        filterMatchMode: 'contains',
        filterPlaceholder: 'Search By Assigned To',
        width: '200px',
        fieldType: 'tooltip'
    };

    const setCustomizedRows = () => {
        let index = rows.findIndex((pro) => pro === state.selectedData);
        rows.splice(index, 1);
        setRows(rows);
        setState({ ...state, showConfirmModal: false, selectedData: {} });
        setRecallLoader(false);
    };
    const handleCalRecallStatus = (flag) => {
        setState({ ...state, showConfirmModal: true, commentRequired: flag });
    };
    const handleCancelModal = () => {
        setState({ ...state, showConfirmModal: false });
    };
    const handleLoader = (flag) => {
        setRecallLoader(flag);
    };
    const goBack = () => {
        setShowAudit(false);
    };

    const growlMessages = (growlMessage) => {
        growl.show({ life: 6000, severity: 'error', summary: growlMessage });
    };
    const [checkCount] = useLazyQuery(GMQueries.guidelineOpinionCount, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (resp) => {
            if (actions === 'view') {
                if (resp.guidelineOpinionCount > 0) {
                    if (state.selectedRec?.entity_id) history.push(projectURL + '/view/' + state.selectedRec['entity_id'] + '/draft');
                    else history.push(projectURL + '/view/' + state.selectedRec?.id);
                } else {
                    if (state.selectedRec?.entity_id) history.push(projectURL + '/view/' + state.selectedRec['entity_id'] + '/draft');
                    else history.push(projectURL + '/view/' + state.selectedRec['id'] + '/approved');
                }
            } else {
                if (resp.guidelineOpinionCount > 0) {
                    if (state.selectedRec?.entity_id) growlMessages(window.EDIT_OF_APPROVAL_MESSAGES.EntityMessage);
                    else growlMessages(window.EDIT_OF_APPROVAL_MESSAGES.ApprovedMessage);
                } else {
                    if (state.selectedRec?.entity_id) history.push(projectURL + '/edit/' + state.selectedRec['entity_id'] + '/draft');
                    else history.push(projectURL + '/edit/' + state.selectedRec['id'] + '/approved');
                }
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const onClickEdit = (rowData, action) => {
        setActions(action);
        setState({ ...state, selectedRec: rowData });
        if (rowData.txnId) localStorage.setItem('transaction_id', rowData.txnId);
        else localStorage.setItem('transaction_id', '');

        if (rowData.entity_id) checkCount({ variables: ApprovedRecordEntityCheckVariable(rowData) });
        else if (rowData.is_latest) checkCount({ variables: ApprovedRecordApproveCheckVariable(rowData) });
        else history.push(projectURL + '/' + action + '/' + rowData['id']);
    };

    const onClickDelete = (data) => {
        setState({ ...state, showDeleteConfirmation: true, selectedRow: data });
    };

    const onClickAudit = (rowData) => {
        setReference(rowData);
        setShowAudit(true);
    };

    const closeDeleteModal = () => {
        setState({ ...state, showDeleteConfirmation: false, selectedRow: {} });
    };

    const handleDelete = () => {
        if (state.selectedRow.entity_id) {
            deleteWorkFlowAction({
                variables: {
                    where: { id: state.selectedRow.entity_id }
                }
            });
            return;
        }
    };

    const onPageChange = (event) => {
        setTableLoader(true);
        setSkip(event.first);
        setTake(event.rows);
        setPageRows(event.rows);
        if (!selected.label || selected.label === 'Total Guideline Audit Opinion') {
            refetchCount();
        }
        fetchGuidelineAuditOpinions();
    };

    const searchRecord = (e, column, filterKeys) => {
        setTableLoader(true);
        Object.keys(filterKeys).forEach((filterKey) => {
            if (filterKeys[filterKey].contains === null) {
                delete filterKeys[filterKey];
            }
            return filterKey;
        });
        let keyData = { ...filterKeys };
        setSearchFieldKey(keyData);
        let obj;
        obj = { is_latest: { equals: true } };
        if (filterKeys && filterKeys['domain']) {
            if (filterKeys['domain'].contains) {
                filterKeys['classif_codes'] = {
                    some: { classif_level: { equals: 'Domain' }, classif_code: { contains: filterKeys['domain'].contains } }
                };
                delete filterKeys['domain'];
            } else {
                delete filterKeys['domain'];
            }
        }
        if (filterKeys && filterKeys['subject']) {
            if (filterKeys['subject'].contains) {
                filterKeys['classif_codes'] = {
                    some: { classif_level: { equals: 'Subject' }, classif_code: { contains: filterKeys['subject'].contains } }
                };
                delete filterKeys['subject'];
            } else {
                delete filterKeys['subject'];
            }
        }
        if (filterKeys && filterKeys['objective']) {
            if (filterKeys['objective'].contains) {
                filterKeys['audit_objective'] = { objective: { contains: filterKeys['objective'].contains, mode: 'insensitive' } };
                delete filterKeys['objective'];
            } else {
                delete filterKeys['objective'];
            }
        }
        if (filterKeys && filterKeys['no_of_slabs']) {
            if (filterKeys['no_of_slabs'].contains) {
                filterKeys['no_of_slabs'] = { equals: parseInt(filterKeys['no_of_slabs'].contains) };
            } else {
                delete filterKeys['no_of_slabs'];
            }
        }
        if (filterKeys['updated_at_str_local']) {
            if (filterKeys['updated_at_str_local'].contains) {
                const dateObj = getUTCGteLteFromDate(filterKeys['updated_at_str_local'].contains);
                filterKeys['version_date'] = { gte: dateObj.gte, lte: dateObj.lte };
                delete filterKeys['updated_at_str_local'];
            } else {
                delete filterKeys['updated_at_str_local'];
            }
        }
        setState({ ...state, where: { ...obj, ...filterKeys } });
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
        if (!selected.label || selected.label === 'Total Guideline Audit Opinion') {
            refetchCount();
        }
    };
    const onSort = (event) => {
        setTableLoader(true);
        let srtField = event.sortField;
        let srtOrder = 'asc';
        if (event.sortField === 'updated_at_str_local') srtField = 'version_date';
        if (sortField === srtField) srtOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(srtOrder);
        setSortField(srtField);
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
    };

    const navigateTo = (query, selectedObj, columns) => {
        if (selectedObj.label !== selected.label) {
            let tWhere = JSON.parse(JSON.stringify(where));
            setWhere(query);
            if (selectedObj.label === 'Pending For Verification/Approval') {
                setRecallObj(selectedObj.canRecall);
                columns.splice(columns.length - 1, 0, assignedTo);
            }
            columns.forEach((col) => {
                if (col['field'] === 'txnId') {
                    col['width'] = '180px';
                }
                if (col['field'] === 'status' || col['field'] === 'is_active') {
                    col['width'] = '150px';
                }
            });
            setState({ ...state, where: query, columns: columns, loading: true });
            setSelected(selectedObj);
            setSkip(0);
            setTake(10);
            setPageRows(10);
            setSortField('version_date');
            setSortOrder('desc');
            setSearchFieldKey({});
            setTableLoader(true);
            if (query?.id?.in.toString() === tWhere?.id?.in.toString()) {
                fetchGuidelineAuditOpinions({ variables: { where: query } }).then((response) => {
                    if (response) prepareData(response.data);
                });
            }
        }
    };

    const prepareData = (obj) => {
        for (let index = 0; index < obj?.auditOpinionGuidelineConfs?.length; index++) {
            let ele = obj?.auditOpinionGuidelineConfs[index];
            ele.updated_at_str_local = getDate(ele.version_date);
            ele.classif_codes.map((res) => {
                ele[res.classif_level.toLowerCase()] = res?.classif_code_obj?.name;
            });
            if (
                selected.ids?.length > 0 &&
                selected.label !== 'Total Guideline Audit Opinion' &&
                selected.status_obj &&
                selected.status_obj[ele.id]
            ) {
                let record = selected?.status_obj[ele.id]?.record;
                ele.status = selected?.status_obj[ele.id]?.status;
                ele.txnId = selected?.status_obj[ele.id]?.txnId;
                ele.assignedTo = convertTextCase(selected?.status_obj[ele.id].assignedTo, false) || '';
                prepareRecord(record, obj, ele, index);
            }
            ele.is_active = ele?.is_active ? 'Active' : 'Inactive';
            ele.objective = ele?.audit_objective?.objective;
        }
        if (selected.new_objs?.length > 0 && selected.label === 'Pending With Self') {
            for (let index = 0; index < selected?.new_objs?.length; index++) {
                const data = selected?.new_objs[index];
                data.isDraft = true;
                data.is_active = data?.is_active ? 'Active' : 'Inactive';
                data.version_no = data.versionNo;
                data.updated_at_str_local = getDate(data.version_date);
                obj.auditOpinionGuidelineConfs.push(data);
            }
        }
        let wfStatusArr = obj.auditOpinionGuidelineConfs.map((a) => a.status).filter((item, i, ar) => ar.indexOf(item) === i);
        state.columns.forEach((a) => {
            if (a.field === 'status') {
                a.filterOptions = wfStatusArr;
            }
        });
        setRows(obj.auditOpinionGuidelineConfs);
    };

    const prepareRecord = (record, resp, i, index) => {
        if (record) {
            record.updated_at_str_local = getDate(record.version_date);
            record.status = selected.status_obj[i.id].status;
            record.is_active = record.is_active ? 'Active' : 'Inactive';
            resp.auditOpinionGuidelineConfs[index] = record;
        }
        return resp;
    };

    const { refetch: refetchCount } = useQuery(GMQueries.guidelineOpinionCount, {
        client: iaClient,
        fetchPolicy: 'no-cache',
        variables: { where: state.where },
        skip: selected.label && selected.label !== 'Total Guideline Audit Opinion',
        onCompleted: (resp) => {
            setTotalRecords(resp.auditOpinionGuidelineConfCount);
            if (!cardCount || cardCount === '') {
                setCardCount(resp.auditOpinionGuidelineConfCount);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    let variables = { orderBy: { [sortField]: sortOrder }, where: state.where };
    if (!selected.label || selected.label === 'Total Guideline Audit Opinion') {
        variables.skip = skip;
        variables.take = take;
    }

    const { refetch: fetchGuidelineAuditOpinions } = useQuery(GMQueries.getGuidelineAuditOpinions, {
        client: iaClient,
        variables: variables,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            prepareData(data);
            setState({ ...state, loading: false });
            setTableLoader(false);
        },
        onError: () => {
            setState({ ...state, rows: [], loading: false });
            setTableLoader(false);
        }
    });

    const [deleteWorkFlowAction, { loading: delLoader }] = useMutation(AppSchema.deleteOneEntityRecord, {
        client: eventClient,
        onCompleted: (data) => {
            if (data) {
                let index = rows.findIndex((pro) => pro === state.selectedRow);
                rows.splice(index, 1);
                growl.show({ life: 6000, severity: 'success', summary: window.GLOBAL_MASTERS.DeleteAuditGuideline });
                setState({ ...state, loading: false, showDeleteConfirmation: false });
                setRows(rows);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const viewData = (rowData) => {
        return (
            <span className="pointer icon-primary" onClick={() => onClickEdit(rowData, 'view')}>
                <EllipsisWithTooltip placement="auto">
                    <p className="m-0 p-ellipsis">{rowData[state.columns[0].field]}</p>
                </EllipsisWithTooltip>
            </span>
        );
    };

    const actionTemplate = (rowData) => {
        const showRecallBtn = recallObj && recallObj[rowData.id];
        return (
            <>
                {(selected.label === 'Pending With Self' || rowData.is_latest) && (
                    <IsAuthorized
                        privRequired={Privileges?.UPDATE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <img
                                title="Edit"
                                className="pointer m-l-5 m-r-5"
                                src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                alt="Edit Icon"
                                height="20px"
                                onClick={() => onClickEdit(rowData, 'edit')}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {(rowData.status === 'Draft' || rowData.status === 'Created') && selected.label === 'Pending With Self' && (
                    <IsAuthorized
                        privRequired={Privileges?.DELETE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <img
                                className="pointer m-l-5 m-r-5"
                                height="20px"
                                title="Delete"
                                src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                alt="Delete Icon"
                                onClick={() => onClickDelete(rowData)}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status !== 'Draft' && (
                    <img
                        className="pointer m-l-5 m-r-5"
                        height="20px"
                        title="Audit"
                        src={process.env.PUBLIC_URL + '/assets/icons/audit_trail.svg'}
                        alt="Audit Icon"
                        onClick={() => onClickAudit(rowData)}
                    />
                )}
                {showRecallBtn && selected.label === 'Pending For Verification/Approval' && (
                    <RecallButton
                        rowData={rowData}
                        setCustomizedRows={setCustomizedRows}
                        onClickRecall={onClickRecall}
                        source_id={state.selectedData?.id}
                        handleCalRecallStatus={handleCalRecallStatus}
                        handleCancelModal={handleCancelModal}
                        handleLoader={handleLoader}
                        commentRequired={state.commentRequired}
                        showConfirmModal={state.showConfirmModal}
                        growl={growl}
                        module={'IA'}
                        fmis_function={'IA_GUIDELINE_AUDIT_OPINION'}
                    />
                )}
            </>
        );
    };

    if (state.columns && state.columns.length) {
        state.columns[0].body = viewData;
        state.columns[state.columns.length - 1].body = actionTemplate;
    }
    const onClickRecall = (rowData) => {
        setState({ ...state, selectedData: rowData });
    };

    return (
        <div className={`${'page-body pos-rel'}`}>
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <Toast ref={(el) => setGrowl(el)} />
            {!showAudit ? (
                <div>
                    <div className="page-header w-100 spaceBetween">
                        <span className="light-color">Guideline For Audit Opinion</span>
                    </div>
                    <div className="coa-card-content">
                        {!state.loading && !delLoader && !recallLoader && (
                            <TopCardComponent
                                className="thumbnail card-width flex-unset"
                                cards={state.cards}
                                selected={selected}
                                fetchParent={(query, selectedData, columns) => navigateTo(query, selectedData, columns)}
                                fmis_function="IA_GUIDELINE_AUDIT_OPINION"
                                approvedLabel="Total Guideline Audit Opinion"
                                columns={tableColumns}
                                cardCount={totalRecords}
                            />
                        )}
                        {!state.loading && (
                            <div className="row">
                                <div className="col-sm-12">
                                    <IsAuthorized
                                        privRequired={Privileges?.CREATE}
                                        officeCode={Privileges?.OFFICE_CODE}
                                        yes={() => (
                                            <Button
                                                size="md"
                                                className="float-right"
                                                onClick={() => localStorage.setItem('transaction_id', '')}
                                            >
                                                <Link className="whiteLink" to="/globalmaster/guidelineauditopinion/create">
                                                    Add Guideline
                                                </Link>
                                            </Button>
                                        )}
                                    ></IsAuthorized>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-12">
                                {!state.loading && (
                                    <div className="row p-t-10">
                                        <div className="col-md-12">
                                            <FDataTable
                                                rows={rows}
                                                columns={state.columns}
                                                onClickDelete={onClickDelete}
                                                loading={tableLoader}
                                                deletePriv={Privileges.DELETE}
                                                updatePriv={Privileges.UPDATE}
                                                officeCode={Privileges.OFFICE_CODE}
                                                count={skip}
                                                pageRows={pageRows}
                                                searchRecord={searchRecord}
                                                filterKeys={searchFieldKey}
                                                lazyLoad={
                                                    !selected.label || selected.label === 'Total Guideline Audit Opinion' ? true : false
                                                }
                                                onSort={onSort}
                                                sortField={sortField}
                                                sortOrder={sortOrder === 'asc' ? 1 : -1}
                                                onPageChange={(e) => onPageChange(e)}
                                                className="primary-table"
                                                emptyMessage={'Guideline are yet to be created'}
                                                scrollable={true}
                                                totalRecords={totalRecords}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <AuditComponent
                    refence_label="Guideline For Audit Opinion"
                    goBack={goBack}
                    transaction_Id={reference.txnId}
                    reference_value={reference.ref}
                    sourceData={reference}
                />
            )}
            {state.showDeleteConfirmation && (
                <ConfirmationModal
                    showModal={state.showDeleteConfirmation}
                    handleCancel={closeDeleteModal}
                    type={'Guideline For Audit Opinion'}
                    name={state?.selectedRow?.txnId}
                    handleDelete={handleDelete}
                    showSpinner={state.loading}
                />
            )}
        </div>
    );
};

export default AuditOpinionGuideline;
