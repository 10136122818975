/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import React, { useState } from 'react';
import { Toast } from 'primereact/toast';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { ProgressSpinner } from 'primereact/progressspinner';
import { eventClient, iaClient, adminClient } from '../../../../../apollo';
import { getFormTypeAndRecordId, InfoMessage } from '../../../../../services/commonService';
import InputComponent from '../../../../../components/InputComponent/InputComponent';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import ActionsComponents from '../../../../../components/actionsComponent/ActionsComponents';
import { MenuItemsSchema } from '../../../../../commons/MenuItemsSchema';
import { Button } from 'appkit-react';
import { GetFinYear, GetFinYearOnRef } from '../../../../../commons/commons';
import QuerySchema from '../../../../coa/codes/Queries';
import Schema from '../AUMQueries';
import AppSchema from '../../../../../schema/AppSchema';
import GMTableSchema from '../../../masters/globalMasters/GMTableSchema';
import { uniqBy, isValidCUID } from '../../../../../util';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { Sidebar } from 'primereact/sidebar';
import WorklowSidebarContent from '../../../../../components/WorklowSidebarContent/WorklowSidebarContent';
import { ApprovedRecordEntityCheckVariable } from '../../../../../commons/CommonVariables';
import IsAuthorized from '../../../../../commons/IsAuthorized';
import { useHistory } from 'react-router-dom';

const CreateIAUnits = (props) => {
	let Privileges = {};
	let prev = props.location.pathname.split('/');
	let path = prev[0] + '/' + prev[1] + '/' + prev[2];
	if (prev.length > 0 && path) {
		MenuItemsSchema['AUDIT_UNIVERSE_MANAGEMENT'].forEach((a) => {
			let val = '';
			if (a.subMenu)
				val = a.subMenu.find((b) => {
					return b.routerLink === path;
				});
			else val = MenuItemsSchema['AUDIT_UNIVERSE_MANAGEMENT'].find((v) => v.routerLink === path);
			if (val) {
				Privileges = val;
				return;
			}
		});
	}
	let tableColumns = JSON.parse(JSON.stringify(GMTableSchema.IA_UNIT));
	let history = useHistory();
	const formTypeAndId = getFormTypeAndRecordId(props.location.pathname);
	let formType = formTypeAndId['formType'];
	let recordID = formTypeAndId['recordID'];
	let isDraft = formTypeAndId['isDraft'];
	let isApproved = formTypeAndId['isApproved'];
	let id = props.match.params.id;
	const isCreate = prev.includes('create');
	let transactionID = localStorage.getItem('transaction_id');
	let reference = localStorage.getItem('Reference');
	const [growl, setGrowl] = useState();
	const [mode, setMode] = useState(formType);
	const [versionNo, setVersionNo] = useState();
	const [versionUser, setVersionUser] = useState();
	let fiVal = mode === 'create' ? GetFinYear() : GetFinYearOnRef(reference);
	const [entityId, setEntityId] = useState('');
	const [recID, setID] = useState('');
	const [currentAction, setCurrentAction] = useState();
	const [levelDropdowns, setLevelDropdowns] = useState([]);
	const [officeLevel, setOfficeLevel] = useState('');
	const [deptLevel, setDeptLevel] = useState('');
	const [dropdownIndex, setIndex] = useState(0);
	const [unitCode, setUnitCode] = useState('');
	const [unitFullCode, setUnitFullCode] = useState('');
	const [unitName, setUnitName] = useState('');
	const [aliasName, setAliasName] = useState('');
	const [selectedMDA, setSelectedMDA] = useState([]);
	const [mdaSelectedCodes, setSelectedMdaCodes] = useState([]);
	const [tableRows, setTableRows] = useState([]);
	const [selectedTableRows, setSelectedTableRows] = useState([]);
	const [addSelectedRows, setAddSelectedRows] = useState([]);
	const [removeSelectedRows, setRemoveSelectedRows] = useState([]);
	const [tableVisible, setTableVisible] = useState(false);
	const [entityOptions, setEntityOptions] = useState([]);
	const [enableOkBtn, setEnableOkBtn] = useState(false);
	const [state, setState] = useState({
		loading: false,
		showWorkflowStatus: false,
		code: '',
		auditName: '',
		columns: tableColumns,
		data: {},
		ref: '',
		formType: formType, // EOA change
		version_no: 0 // EOA change
	});
	const [approveVersionNo, setApproveVersionNo] = useState(''); // EOA change
	const [showActions, setShowActions] = useState(isApproved && formType === 'view' ? false : true); // EOA change
	const [approvedRecord, setApprovedRecord] = useState(false); // EOA change
	const [ref, setRef] = useState(''); // EOA change
	const [riskDef, setRiskDef] = useState(false); // EOA change
	const [approved, setApproved] = useState(false); // EOA change
	// EOA change
	const [checkIAD] = useLazyQuery(Schema.iauDevisionCount, {
		fetchPolicy: 'no-cache',
		client: iaClient,
		onCompleted: (resp) => {
			if (resp.iauDevisionCount > 0) setRiskDef(true);
			else setRiskDef(false);
			setState({ ...state, loading: false });
		},
		onError: () => {
			setState({ ...state, loading: false });
		}
	});

	const handleChange = (e, key) => {
		if (key === 'audit_unit_name') {
			if (typeof e.trim === 'function' && e.trim(' ') === '') {
				e = e.trim(' ');
			}
			setAliasName(e);
		} else if (key === 'mda') {
			let mdas = e.value;
			let mdaCodes = mdas.map((m) => m.code);
			setSelectedMdaCodes(mdaCodes);
			setSelectedMDA(mdas);
			setEnableOkBtn(mdas?.length > 0 ? true : false);
			setAddSelectedRows([]);
		}
	};

	const setActionsStatus = (hasActions) => {
		setState({ ...state, hasActions });
	};

	const handleLoader = (status) => {
		setState({ ...state, loading: status });
	};

	const handleCancel = () => {
		if (isApproved) props.history.push('/audituniversemang/auditunit');
		// EOA change
		else props.history.goBack();
	};

	const showGrowl = (msg, type) => {
		growl.show({ life: 6000, severity: type, summary: msg });
		setTimeout(() => {
			if (type !== 'error') handleCancel();
		}, 1000);
	};

	let [coaSegment, { loading: confLoader }] = useLazyQuery(QuerySchema.coaSegmentConf, {
		client: adminClient,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data?.coaSegmentConf) {
				setOfficeLevel(data?.coaSegmentConf?.office_level);
				setDeptLevel(data?.coaSegmentConf?.dept_level);
				let fullCode = unitFullCode.split('-');
				let variables = {
					where: { full_code: { in: fullCode }, seg_def: { path: { startsWith: `${data?.coaSegmentConf?.org_segment}#` } } }
				};
				variables.where.year_id = { equals: fiVal };
				getNextLevelData({
					variables: variables
				});
			}
		}
	});

	// get api  countries, designation appsetting, mda dept offcs, years
	let [masterData, { loading: masterLoader }] = useLazyQuery(Schema.iaMdaDeputationOffice, {
		fetchPolicy: 'no-cache',
		onCompleted: (res) => {
			if (res?.mdaAndDeputationOffice?.length > 0) {
				setEntityOptions(res?.mdaAndDeputationOffice);
				res.mdaAndDeputationOffice.map((mda) => {
					mda.displayLabel = `${mda.full_code} - ${mda.name}`;
					mda?.children?.length > 0 &&
						mda.children.map((c) => {
							delete c.__typename;
						});
					delete mda.__typename;
				});
				levelDropdowns.map((level, index) => {
					if (index === 0) {
						level.options = res?.mdaAndDeputationOffice;
					}
				});
				if (mode !== 'create' && !isDraft) {
					iauDevision();
				}
				setState({ ...state, loading: false });
			}
		}
	});
	let admVars = { where: { structure: { year_id: { equals: fiVal } } }, orderBy: { serial_no: 'asc' } };
	let { loading: LevelsLoader } = useQuery(AppSchema.admCoaStructDetails, {
		// api to get dropdown labels based on mda selected
		fetchPolicy: 'no-cache',
		variables: admVars,
		onCompleted: (res) => {
			if (res?.admCoaStructDetails?.length > 0) {
				let masterVariable = {};
				if (mode !== 'create') masterVariable = { fin_year: fiVal };
				masterData({ variables: masterVariable });
				coaSegment();
				setLevelDropdowns(res?.admCoaStructDetails[0].seg_def.levels);
				if (isDraft) {
					fetchEntityRecord();
				}
			}
		}
	});

	let [getMdaProfiles, { loading: defsLoader }] = useLazyQuery(Schema.mdaProfileDefs, {
		fetchPolicy: 'no-cache',
		client: iaClient,
		onCompleted: (response) => {
			if (response?.mdaProfileDefs) {
				mdaProfileDefs(response?.mdaProfileDefs);
			}
		}
	});

	const mdaProfileDefs = (mdaData) => {
		let aa = [];
		mdaData?.length > 0 &&
			mdaData.map((m) => {
				aa.push({
					displayLabel: `${m?.auditable_area_code} - ${m?.auditable_area}`,
					auditable_area_code: m?.auditable_area_code,
					auditable_area: m?.auditable_area,
					id: m?.id,
					mda: m?.mda,
					mda_code: m?.mda_code
				});
			});
		let filterArr =
			selectedTableRows.length > 0
				? aa.filter((a) => selectedTableRows.every((sel) => sel.auditable_area_code !== a.auditable_area_code))
				: aa;
		setTableRows(filterArr);
	};

	const handleDynChange = (ev, field, index) => {
		setIndex(index);
		let aArea = '';
		let aCode = '';
		let editLevels = [...levelDropdowns];
		editLevels.forEach((level, inx) => {
			if (inx > index) {
				level['value'] = {};
				level['options'] = [];
			}
			if (level.name === field) {
				level.value = ev;
			}
			let aName = level?.value?.name?.replace('-', '–');
			if (aArea !== '') {
				if (level?.value?.full_code) {
					aArea = aArea?.split('-');
					aArea = aArea?.filter((a, ind) => ind < aArea.length - 1);
					aArea = aArea?.join('-');
					aCode = aArea + '-' + level?.value?.full_code;
					aArea = aArea + '-' + level?.value?.full_code + '-' + aName;
				}
			} else {
				aArea = level?.value?.full_code + '-' + level?.value?.name;
			}
			setUnitName(aName);
			return level;
		});
		setUnitFullCode(aCode);
		let queryObj = {};
		if (levelDropdowns.length !== index) {
			queryObj.seg_def_id = { equals: ev.children[0].seg_def.id };
		}
		if (levelDropdowns.length === index) {
			let lastItem = editLevels[editLevels.length - 1];
			let fullCode = lastItem?.value?.full_code;
			setUnitCode(fullCode);
		}
		queryObj.parent_id = { equals: ev.id };
		if (mode !== 'create') queryObj.year_id = { equals: fiVal };
		getNextLevelData({ variables: { where: queryObj } });
		setLevelDropdowns(editLevels);
	};

	const [getNextLevelData, { loading: segLoader }] = useLazyQuery(AppSchema.admCoaSegments, {
		// api to get level dropdown options
		fetchPolicy: 'no-cache',
		onCompleted: (response) => {
			if (response?.admCoaSegments?.length > 0) {
				response.admCoaSegments.map((coa) => {
					coa.displayLabel = `${coa.full_code} - ${coa.name}`;
				});
				let levels = [...levelDropdowns];
				if (mode !== 'create') {
					levels.forEach((lev) => {
						let vals = response?.admCoaSegments.find((adm) => adm.seg_def.name === lev.name);
						lev.value = vals;
						return lev;
					});
				} else {
					let coaOptions = response.admCoaSegments;
					levels[dropdownIndex].options = coaOptions;
					levels[dropdownIndex].value = mode !== 'create' ? coaOptions[0] : {};
				}
				setLevelDropdowns(levels);
			}
		},

	});

	const [createIAUnit] = useMutation(Schema.createOneIAUDevision, {
		client: iaClient,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data) {
				let growlMsg = '';
				if (!isDraft && versionNo > 0) growlMsg = currentAction?.name + ' action taken successfully';
				else growlMsg = window.INTERNAL_AUDIT_UNITS.CreateIAU;
				showGrowl(growlMsg, 'success');
			}
		},
		onError: () => {
			handleLoader(false);
		}
	});

	const [updateIAUnit] = useMutation(Schema.updateOneIAUDevision, {
		variables: { where: { id: recID } },
		client: iaClient,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data) {
				let growlMsg = currentAction?.name
					? currentAction?.name + ' action taken successfully'
					: window.INTERNAL_AUDIT_UNITS.UpdateIAU;
				showGrowl(growlMsg, 'success');
				setVersionNo(parseInt(data.updateOneIAUDevision.version_no));
				setVersionUser(data.updateOneIAUDevision.version_user);
			}
		},
		onError: () => {
			handleLoader(false);
		}
	});

	const [iauDevision, { loading: iaLoader }] = useLazyQuery(Schema.iauDevision, {
		client: iaClient,
		variables: { where: { id: id } },
		fetchPolicy: 'no-cache',
		skip: isCreate || isDraft,
		onCompleted: (data) => {
			if (data && data?.iauDevision) {
				prepareData(data?.iauDevision);
				setVersionNo(data?.iauDevision?.version_no);
				setVersionUser(data?.iauDevision?.version_user);
				setRef(data?.iauDevision?.ref); // EOA change
				setRiskDef(data?.iauDevision?.is_latest); // EOA change
				setApprovedRecord(!isValidCUID(data?.iauDevision?.ref)); // EOA change
				coaSegment({ variables: { fin_year: fiVal } });
			} else {
				masterData({ variables: { fin_year: fiVal } });
				setState({ ...state, loading: false });
			}
		},
		onError: () => {
			setState({ ...state, loading: false });
		}
	});

	const [fetchEntityRecord, { loader: entLoader }] = useLazyQuery(AppSchema.entityRecord, {
		// get api for record data by ID in Draft mode
		client: eventClient,
		fetchPolicy: 'no-cache',
		variables: { where: { id: recordID } },
		skip: !isDraft || isApproved || mode === 'create',
		onCompleted: (data) => {
			if (data?.entityRecord?.record) {
				let record = data?.entityRecord?.record;
				isApproved = record?.is_approved; // EOA change
				setApproveVersionNo(record?.approved_version_no); // EOA change
				setRef(record?.ref); // EOA change
				// EOA change
				if (checkIsApproved()) {
					record.version_no = record?.approved_version_no;
					checkIAD({ variables: ApprovedRecordEntityCheckVariable(record) });
				}
				prepareData(record);
				setEntityId(record.entity_id);
				setVersionNo(record.version_no);
				setApproved(record?.is_approved ? true : false); // EOA change
				setVersionUser(record.version_user);
				let levels = [...levelDropdowns];
				levels.forEach((lev) => {
					let entName = lev?.name.toLowerCase();
					lev.value = record?.auditabl_area_details[entName];
					return lev;
				});
				setLevelDropdowns(levels);
				return;
			}
		},

	});

	const prepareData = (data) => {
		if (data.id) setID(data.id);
		let details = !isDraft && data.details;
		if (isDraft) {
			let mdaCodes = data?.mda?.length > 0 && data.mda.map((m) => m.code);
			setSelectedTableRows(data?.selectedTable);
			setTableRows(data?.tableRows);
			setSelectedMDA(data.mda);
			setSelectedMdaCodes(mdaCodes);
			setTableVisible(data?.tableRows.length > 0 || data?.selectedTable.length > 0 ? true : false);
		} else {
			let mda = [];
			let mdaCodes = [];
			let auditableAreas = [];
			details.map((m) => {
				let selMdaList = entityOptions?.length > 0 && entityOptions.find((ent) => ent?.full_code === m?.mda_profile?.mda_code);
				mda.push(selMdaList);
				if (mdaCodes.indexOf(m?.mda_profile?.mda_code) === -1) {
					mdaCodes.push(m?.mda_profile?.mda_code);
				}
				auditableAreas.push({
					displayLabel: `${m.mda_profile?.auditable_area_code} - ${m.mda_profile?.auditable_area}`,
					auditable_area: m.mda_profile?.auditable_area,
					auditable_area_code: m.mda_profile?.auditable_area_code,
					id: m.mda_profile_id,
					mda: m.mda_profile.mda,
					mda_code: m.mda_profile.mda_code
				});
			});
			setSelectedMDA(uniqBy(mda, 'full_code'));
			mdaProfileCall(mdaCodes);
			setSelectedTableRows(auditableAreas);
			setTableVisible(auditableAreas.length > 0 ? true : false);
		}
		setUnitCode(data?.code);
		setUnitName(data?.name);
		setUnitFullCode(data?.full_code);
		setAliasName(data?.alias);
		setState({ ...state, loading: false, ref: data?.ref, version_no: data?.version_no });
	};

	const updateAddSelcetedRows = (rows) => {
		let arr = [...selectedTableRows, ...rows.value];
		setAddSelectedRows(arr);
		setRemoveSelectedRows([]);
	};

	const updateRemoveSelcetedRows = (rows) => {
		setRemoveSelectedRows(rows.value);
		setAddSelectedRows([]);
	};

	const onClickOk = () => {
		mdaProfileCall(mdaSelectedCodes);
		setTableVisible(true);
		setEnableOkBtn(false);
	};

	const mdaProfileCall = (codes) => {
		getMdaProfiles({
			variables: {
				where: {
					mda_code: { in: codes },
					is_effective: { equals: true },
					iau_devision_detail: {
						every: { iau_devision: { status: { in: ['Rejected', 'Cancelled'] } } }
					}
				}
			}
		});
	};

	const onClickAdd = () => {
		if (addSelectedRows?.length > 0) {
			let tableFilterArr = tableRows?.filter((row) => addSelectedRows?.every((add) => add?.id !== row?.id));
			setTableRows(tableFilterArr);
			setSelectedTableRows(uniqBy(addSelectedRows, 'auditable_area_code'));
			setAddSelectedRows([]);
		}
	};

	const onClickRemove = () => {
		if (removeSelectedRows.length > 0) {
			let filterArr = selectedTableRows?.filter((sel) => removeSelectedRows?.every((rem) => rem?.id !== sel?.id));
			let arr = [...tableRows, ...removeSelectedRows];
			setRemoveSelectedRows([]);
			setSelectedTableRows(filterArr);
			setTableRows(arr);
			setAddSelectedRows([]);
		}
	};

	const setData = () => {
		let list = [];
		selectedTableRows.map((data) => {
			list.push({
				mda_profile: { connect: { id: data.id } }
			});
		});
		return list;
	};

	const setDropDownsData = () => {
		let obj = {};
		levelDropdowns?.length > 0 &&
			levelDropdowns.map((lev) => {
				obj[lev.name.toLowerCase()] = lev?.value;
			});
		return obj;
	};

	const getValue = (value, isTempSave) => {
		if (!isTempSave && (versionNo > 0 || (!versionNo && state?.data?.version_no > 0)) && !isApproved) return { set: value };
		return value;
	};

	const getRecord = (isTempSave = true) => {
		let obj = {};
		obj.code = getValue(unitCode, isTempSave);
		obj.full_code = getValue(unitFullCode, isTempSave);
		obj.name = getValue(unitName, isTempSave);
		obj.alias = getValue(aliasName, isTempSave);
		obj.auditabl_area_details = setDropDownsData(); // delete while create and update
		obj.details = { create: setData() };
		obj.mda = selectedMDA; // delete while create and update
		obj.selectedTable = selectedTableRows.length > 0 ? selectedTableRows : []; // delete while create and update
		obj.tableRows = tableRows.length > 0 ? tableRows : []; // delete while create and update
		if (versionNo || isApproved)
			// EOA change
			obj.version_no = getValue(approveVersionNo > 0 ? Number(approveVersionNo) + 1 : Number(versionNo) + 1, isTempSave);
		if (checkIsApproved()) {
			// EOA change
			obj.ref = getValue(ref, isTempSave);
			obj.approved_version_no = getValue(approveVersionNo > 0 ? approveVersionNo : versionNo, isTempSave);
		}
		if (recID) obj.id = recID;
		if (isDraft) obj.entity_id = entityId;
		return obj;
	};

	const getDisplayData = () => {
		let obj = {};
		obj.code = unitCode;
		obj.alias = aliasName;
		obj.full_code = unitFullCode;
		obj.ref = ref; // EOA change
		obj.versionNo = approveVersionNo > 0 ? approveVersionNo : versionNo; // EOA change
		return obj;
	};

	const navigateToEdit = () => {
		if (isApproved) {
			history.push('/audituniversemang/auditunit/edit/' + recordID + '/approved'); // EOA change
			setShowActions(true);
		}
		setMode('edit');
		setState({ ...state, formType: 'edit' });
	};

	const canDisable = () => {
		let code = unitFullCode?.split('-');
		if (levelDropdowns?.length === code?.length && aliasName && selectedMDA.length > 0 && selectedTableRows.length > 0) {
			return false;
		} else return true;
	};

	const minFieldsRequired = () => {
		let code = unitFullCode?.split('-');
		if (levelDropdowns?.length === code?.length) {
			return false;
		} else return true;
	};

	const deleteKeys = (data) => {
		delete data.entity_id;
		delete data.auditabl_area_details;
		delete data.mda;
		delete data.selectedTable;
		delete data.tableRows;
		delete data.is_approved; // EOA change
		delete data.app_version_no; // EOA change
		delete data.approved_version_no; // EOA change
		return data;
	};

	const onSubmit = (wfParams, action) => {
		setCurrentAction(action);
		let obj = getRecord(false);
		obj.wf_params = wfParams;
		obj = deleteKeys(obj);
		if (state?.version_no > 0 && !isApproved) {
			if (checkIsApproved()) {
				obj.version_no = { set: approveVersionNo > 0 ? Number(approveVersionNo) + 1 : Number(versionNo) + 1 };
			} else {
				delete obj.version_no;
			}
			if (approvedRecord) obj.ref = { set: ref };
			delete obj.id;
			obj.wf_params = wfParams;
			updateIAUnit({ variables: { data: obj, where: { id: isDraft ? recID : props.match.params.id } } });
		} else {
			obj.wf_params = wfParams;
			if (recID || isApproved) {
				if (checkIsApproved()) {
					if (!isDraft) delete obj.id;
					obj.version_no = approveVersionNo > 0 ? Number(approveVersionNo) + 1 : Number(versionNo) + 1;
					obj.ref = ref;
				}
				createIAUnit({ variables: { data: obj } });
			} else {
				createIAUnit({ variables: { data: obj } });
			}
		}
		setState({ ...state, loading: true });
	};
	const showWorkflow = (flag) => {
		setState({ ...state, showWorkflowStatus: flag });
	};
	const handleCommentClick = (selRow, flag) => {
		const lstToShowComment = state.listToShowComment;
		lstToShowComment[selRow.id] = flag;
		setState({ ...state, listToShowComment: lstToShowComment });
	};

	const checkIsApproved = () => {
		return isApproved || (approveVersionNo && approveVersionNo > 0) || approvedRecord;
	};

	return (
		<div
			className={`page-body pos-rel ${state.loading || LevelsLoader || masterLoader || defsLoader || iaLoader || entLoader ? 'mask' : ''
				}`}
		>
			{(state.loading || segLoader || LevelsLoader || confLoader || masterLoader || defsLoader || iaLoader || entLoader) && (
				<ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />
			)}
			<Toast ref={(el) => setGrowl(el)} />
			<>
				<div className="page-header">
					<span className="light-color">
						{(state?.data && state?.data.id) || recordID
							? `${mode[0]?.toUpperCase() + mode?.slice(1)} Of Internal Audit Unit`
							: 'Creation Of Internal Audit Unit'}
						{recordID &&
							(transactionID && transactionID !== 'undefined' ? (
								<span className="p-l-5">: {transactionID}</span>
							) : (
								<span className="p-l-5">: {ref}</span>
							))}
					</span>
					<span className="backBtn pointer">
						{recordID && versionNo !== '' && versionNo !== 0 && (
							<img
								alt=""
								className="header-icon"
								title="Workflow Stages"
								src={process.env.PUBLIC_URL + '/assets/icons/workflow.svg'}
								onClick={() => showWorkflow(true)}
							></img>
						)}
						<span
							onClick={() => {
								localStorage.setItem('transaction_id', '');
								props.history.goBack();
							}}
						>
							<span className="appkiticon icon-left-chevron-outline icon_size"></span>Back
						</span>
					</span>
				</div>
				<div
					className={`${state.hasActions || mode === 'create' || checkIsApproved()
							? 'page-content-ht-with-btns'
							: 'page-content-ht-without-btns'
						}`}
				>
					<Sidebar
						visible={state.showWorkflowStatus}
						onHide={() => showWorkflow(false)}
						position="right"
						dismissable={true}
						closeOnEscape={false}
						showCloseIcon={false}
					>
						<WorklowSidebarContent
							sourceId={recordID}
							listToShowComment={state.listToShowComment}
							showWorkflow={showWorkflow}
							handleCommentClick={handleCommentClick}
						/>
					</Sidebar>
					{checkIsApproved() &&
						state.formType === 'edit' &&
						InfoMessage(window.EDIT_OF_APPROVAL_ADVISORY_MESSAGES.InternalAuditUnit)}
					{riskDef &&
						!isApproved &&
						state.formType === 'view' &&
						InfoMessage(window.EDIT_OF_APPROVAL_MESSAGES.ApprovedMessageForView)}
					<div className="row f-15">
						{levelDropdowns?.length > 0 &&
							levelDropdowns.map((field, idx) => {
								return (
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10" key={idx}>
										<div className="row">
											<div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
												<b className="f-15">
													{field.name} {mode === 'create' ? <span className="icon-primary">*</span> : ' :'}
												</b>
											</div>
											<div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
												{mode !== 'create' ? (
													<span>{field?.value?.displayLabel}</span>
												) : (
													<Dropdown
														className="w-100"
														name={field['name']}
														disabled={field.disableMe || field?.options?.length === 0}
														value={field['value']}
														onChange={(e) => handleDynChange(e.value, field.name, idx + 1)}
														appendTo={document.self}
														options={field.options || []}
														placeholder={`Select ${field.name}`}
														optionLabel="displayLabel"
														filter={true}
														filterPlaceholder="Search"
														filterBy="displayLabel"
													/>
												)}
											</div>
										</div>
									</div>
								);
							})}
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
							<div className="row">
								<div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
									<b>Internal Audit Unit Name {mode !== 'view' ? <span className="icon-primary">*</span> : ' :'}</b>
								</div>
								<div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
									{mode === 'view' ? (
										<span>{aliasName}</span>
									) : (
										<InputComponent
											className="w-100"
											name="audit_unit_name"
											value={aliasName}
											placeholder="Enter Internal Audit Unit Name"
											inputBoxSize="sm"
											type="text"
											maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
											validations={['alphaNumeric']}
											onChange={(e) => handleChange(e, 'audit_unit_name')}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="row f-15">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
							<div className="row">
								<div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
									<b>
										{deptLevel} {mode !== 'view' ? <span className="icon-primary">*</span> : ' :'}
									</b>
								</div>
								<div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
									{mode === 'view' ? (
										selectedMDA.map((sel) => sel?.displayLabel).join(', ')
									) : (
										<MultiSelect
											filter={true}
											className="w-100"
											optionLabel="displayLabel"
											name="name"
											value={selectedMDA}
											options={entityOptions}
											onChange={(e) => handleChange(e, 'mda')}
											placeholder="Select Entity"
											responsive
											resetFilterOnHide={true}
											filterBy="displayLabel"
											filterPlaceholder="Search"
											disabled={
												isDraft
													? entityOptions.length === 0
													: levelDropdowns.length !== unitFullCode?.split('-')?.length
											}
										/>
									)}
								</div>
							</div>
						</div>
						{mode !== 'view' && (
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10 m-t-5">
								<div className="row">
									<div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
										<Button
											size="sm"
											kind="primary"
											disabled={!enableOkBtn}
											onClick={() => {
												onClickOk();
											}}
											className="m-r-5"
										>
											Ok
										</Button>
									</div>
								</div>
							</div>
						)}
					</div>
					{tableVisible && (
						<div className="row">
							{mode !== 'view' && (
								<div className="col-sm-5">
									<div className="ia-content ia-box-ht">
										<div className="table-row-selection">
											<DataTable
												value={tableRows}
												selectionMode={'multiple'}
												metaKeySelection={false}
												selection={addSelectedRows}
												onSelectionChange={(e) => updateAddSelcetedRows(e)}
												dataKey="id"
												responsiveLayout="scroll"
												scrollable={true}
												emptyMessage={
													selectedTableRows.length > 0 && tableRows.length === 0
														? 'All Auditable Areas are selected'
														: 'No records found'
												}
											>
												<Column
													field="displayLabel"
													filter={true}
													filterMatchMode="contains"
													width="100%"
													filterPlaceholder="Search by Auditable Area"
													header="Auditable Area"
												></Column>
											</DataTable>
										</div>
									</div>
								</div>
							)}
							{mode !== 'view' && (
								<div className="col-sm-2 ia-btn">
									{mode !== 'view' && (
										<div>
											<Button
												disabled={addSelectedRows?.length === 0 || tableRows?.length === 0}
												size="sm"
												kind="primary"
												className="float-left"
												onClick={() => {
													onClickAdd();
												}}
											>
												Add
											</Button>
										</div>
									)}
									{mode !== 'view' && (
										<div>
											<Button
												disabled={removeSelectedRows?.length === 0 || selectedTableRows?.length === 0}
												size="sm"
												kind="primary"
												className="float-right"
												onClick={() => {
													onClickRemove();
												}}
											>
												Remove
											</Button>
										</div>
									)}
								</div>
							)}
							<div className={`${mode === 'view' ? 'col-sm-12' : 'col-sm-5'}`}>
								<div className={`${mode === 'view' ? 'ia-content' : 'ia-content ia-box-ht'}`}>
									<div className="table-row-selection">
										<DataTable
											value={selectedTableRows}
											selectionMode={mode === 'view' ? false : 'multiple'}
											metaKeySelection={false}
											selection={removeSelectedRows}
											onSelectionChange={(e) => updateRemoveSelcetedRows(e)}
											dataKey="id"
											responsiveLayout="scroll"
											scrollable={true}
											emptyMessage="No Auditable Areas are selected"
										>
											<Column
												field="displayLabel"
												filter={true}
												filterMatchMode="contains"
												width="100%"
												filterPlaceholder="Search by Auditable Area"
												header="Auditable Area"
											></Column>
										</DataTable>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</>
			{!(riskDef && !isApproved && state.formType === 'view') && (
				<>
					{!state.loading && (
						<>
							{showActions ? (
								<ActionsComponents
									navigateToEdit={() => navigateToEdit()}
									mode={isApproved ? 'create' : state.formType} // EOA change
									setActionsStatus={setActionsStatus}
									canDisable={canDisable()}
									data={state?.data}
									onSave={(wfParams, action) => onSubmit(wfParams, action)}
									onCancel={() => handleCancel()}
									version_no={isApproved || approved || isDraft ? 0 : versionNo} // EOA change
									version_user={versionUser}
									privRequired={Privileges?.UPDATE}
									officeCode={Privileges.OFFICE_CODE}
									module="IA"
									fmis_function="IA_IAU_DIVISION"
									fmis_sub_function="NA"
									minFieldsRequired={minFieldsRequired()}
									getRecord={getRecord}
									getDisplayData={getDisplayData}
									handleLoader={handleLoader}
									screenName={'Creation Of Internal Audit Units'}
									source_id={isApproved ? '' : recID} // EOA change
									isApproved={isApproved || approved} // EOA change
								/>
							) : (
								<div className="bottom-left-btm-div">
									<IsAuthorized
										privRequired={Privileges?.UPDATE}
										officeCode={Privileges.OFFICE_CODE}
										yes={() => (
											<Button size="sm" kind="primary" className="m-r-10 m-t-10" onClick={() => navigateToEdit()}>
												Edit
											</Button>
										)}
									></IsAuthorized>
								</div>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default CreateIAUnits;
