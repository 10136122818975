/* eslint-disable max-depth */
import React, { useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import AuditCommonFields from '../../CommonHierarchyFields';
import { Dropdown } from 'primereact/dropdown';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import InputComponent from '../../../../../../components/InputComponent/InputComponent';
import FDataTable from '../../../../../../components/fDataTable/FDataTable';
import TableSchema from '../../GMTableSchema';
import { getFormTypeAndRecordId, InfoMessage } from '../../../../../../services/commonService';
import { Button } from 'appkit-react';
import * as FormValidation from '../../../../../../Validation';
import TextAreaComponent from '../../../../../../components/InputComponent/TextAreaComponent';
import AppSchema from '../../../../../../schema/AppSchema';
import GMQueries from '../../GMQueries';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { iaClient, eventClient } from '../../../../../../apollo';
import ActionsComponents from '../../../../../../components/actionsComponent/ActionsComponents';
import { MenuItemsSchema } from '../../../../../../commons/MenuItemsSchema';
import ConfirmationModal from '../../ConfirmationModal';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import WorklowSidebarContent from '../../../../../../components/WorklowSidebarContent/WorklowSidebarContent';
import IsAuthorized from '../../../../../../commons/IsAuthorized';
import { isValidCUID, serverDate } from '../../../../../../util';
import { ApprovedRecordApproveCheckVariable, ApprovedRecordEntityCheckVariable } from '../../../../../../commons/CommonVariables';

const AddGuideline = (props) => {
    let transactionID = '';
    transactionID = localStorage.getItem('transaction_id');
    const formTypeAndId = getFormTypeAndRecordId(props.location.pathname);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    let isDraft = formTypeAndId['isDraft'];
    let isApproved = formTypeAndId['isApproved'];

    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) {
        MenuItemsSchema.GLOBAL_MASTERS.forEach((a) => {
            let val = '';
            if (a.subMenu)
                val = a.subMenu.find((b) => {
                    return b.routerLink.split('/')[2] === loc[1].split('/')[2];
                });
            else val = MenuItemsSchema.GLOBAL_MASTERS.find((v) => v.routerLink === loc[1]);
            if (val) {
                Privileges = val;
                return;
            }
        });
    }

    const [state, setState] = useState({
        loading: true,
        slab: '',
        showTable: false,
        slabsForm: false,
        setDraftObjective: '',
        setDraftclassification: '',
        data: {},
        version_no: '',
        version_user: '',
        entity_id: '',
        showConfirmationModal: false,
        is_active: true,
        listToShowComment: [],
        showWorkflowStatus: false
    });

    const [currentAction, setCurrentAction] = useState('');
    const [slabsDataTable, setSlabsDataTable] = useState([]);
    const [slabsData, setSlabsData] = useState({});
    const [errors, setErrors] = useState(false);
    const [slabErrors, setSlabErrors] = useState({ slab: '', txn_fail_percent_start: '', txn_fail_percent_end: '' });
    const [mode, setMode] = useState(formType);
    const [growl, setGrowl] = useState();
    const [recID, setRecId] = useState('');
    const [entity, setEntity] = useState('');
    const [versionNo, setVersionNo] = useState();
    const [draftClassification, setDraftClassification] = useState('');
    const [disableClassif, setDisableClassif] = useState(true);
    const [objectiveDisable, setObjectiveDisable] = useState(true);
    const [hierarchyLevels, setHierarchyLevels] = useState([]);
    const [allHierarchyLevel, setAllHierarchyLevel] = useState([]);
    const [auditCommonValuesReturn, setAuditCommonValuesReturn] = useState([]);
    const [auditLevels, setAuditLevels] = useState({});
    const [auditLastValue, setAuditLastValue] = useState([]);
    const [objective, setObjective] = useState({});
    const [objectiveList, setObjectiveList] = useState([]);
    const [classification, setClassification] = useState([]);
    const [classificationList, setClassificationList] = useState([]);
    const [lastLevelDraft, setLastLevelDraft] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [approveVersionNo, setApproveVersionNo] = useState('');
    const [ref, setRef] = useState('');
    const [showActions, setShowActions] = useState(isApproved && formType === 'view' ? false : true);
    const [approvedRecord, setApprovedRecord] = useState(false);
    const [isGuidelineValid, setIsGuidelineValid] = useState(false);
    const [slabVal, setSlabVal] = useState('');

    const fields = [
        { key: 'slab', validators: 'numeric' },
        { key: 'txn_fail_percent_start', validators: 'numeric' },
        { key: 'txn_fail_percent_end', validators: 'numeric' },
        { key: 'conclusion_opinion', validators: 'alphaNumeric' },
        { key: 'description', validators: 'alphaNumeric' }
    ];

    const handleChange = (e, key) => {
        if (key === 'slab') {
            if (parseInt(e) === 0 || parseInt(e) > 99) {
                setSlabErrors({ ...slabErrors, [key]: 'Please enter upto 99 slabs only' });
            } else {
                setSlabErrors({ ...slabErrors, [key]: '' });
            }
            validateFeild(e, key);
            setState({ ...state, [key]: e });
        } else if (key === 'classification') {
            setClassification(e.value);
            setObjective([]);
            let selClassificationValues = e.value;
            let passClassiValues = [];
            selClassificationValues.map((res) => {
                passClassiValues.push({ audit_classification: { contains: res.name } });
            });
            fetchAuditObjectivesCommonMethod(
                Object.keys(auditLastValue).length !== 0 ? auditLastValue : lastLevelDraft,
                passClassiValues,
                ''
            );
            setObjectiveDisable(false);
        } else if (key === 'is_active') {
            setState({ ...state, [key]: e });
        } else {
            setObjective(e);
        }
    };

    const onSelectedData = (value, key, obj, levels) => {
        let ids = arrSelIds(obj?.auditDataConfig);
        setAuditLastValue(ids);
        setHierarchyLevels(levels);
        setAllHierarchyLevel(obj);
        setClassification([]);
        setObjective({});
        setLastLevelDraft([]);
        setObjectiveList([]);
        setAuditLevels({ ...auditLevels, [key.name]: value });
        let flag = [];
        if (allHierarchyLevel && allHierarchyLevel.auditDataConfig && allHierarchyLevel.auditDataConfig.length > 0) {
            allHierarchyLevel.auditDataConfig.map((hierarchyLevel) => {
                if (!hierarchyLevel.value) {
                    flag.push(true);
                }
            });
            setDisableClassif(flag.includes(true));
        }
    };

    const arrSelIds = (selAuditIds) => {
        return selAuditIds && selAuditIds.map((v) => (v?.value?.id ? v?.value?.id : '')).filter((aId) => aId !== '');
    };

    const go = () => {
        setState({ ...state, showConfirmationModal: true });
        let chkTable = slabsDataTable.some((res) => res.txn_fail_percent_end);
        if (chkTable) {
            setState({ ...state, showConfirmationModal: true, slabsForm: false });
        } else {
            slabsDataCreation();
            setState({ ...state, showTable: true, slabsForm: false });
        }
    };

    const onClickEdit = (rowData) => {
        setState({ ...state, slabsForm: true });
        setSlabsData(rowData);
    };

    const handleSlabsChange = (e, key) => {
        if (key === 'txn_fail_percent_end') {
            txnEndPercentage(e, key);
        }
        if (slabsData.slab_no > 1 && key === 'txn_fail_percent_start') {
            txnStartSlabGreater(e, key);
        } else {
            txnStartSlab(e, key);
        }
        validateFeild(e, key);
        setSlabsData({ ...slabsData, [key]: e });
    };

    const txnEndPercentage = (e, key) => {
        if (slabsDataTable.length === slabsData.slab_no) {
            if (parseInt(e) === 100) setSlabErrors({ ...slabErrors, [key]: '' });
            else setSlabErrors({ ...slabErrors, [key]: 'Closing Range should be end with 100' });
        } else {
            if (parseInt(e) <= parseInt(slabsData.txn_fail_percent_start))
                setSlabErrors({ ...slabErrors, [key]: 'Ending range should be more than starting value' });
            else setSlabErrors({ ...slabErrors, [key]: '' });
        }
    };

    const txnStartSlabGreater = (e, key) => {
        for (let i = 0; i < slabsDataTable.length; i++) {
            if (slabsDataTable[i].slab_no === slabsData.slab_no) {
                if (e > slabsDataTable[i - 1].txn_fail_percent_end || e < slabsDataTable[i - 1].txn_fail_percent_end)
                    setSlabErrors({ ...slabErrors, [key]: `Entered range should be equal to slab ${slabsData.slab_no - 1} ending value` });
                else setSlabErrors({ ...slabErrors, [key]: '' });
            }
        }
    };

    const txnStartSlab = (e, key) => {
        if (slabsData.slab_no === 1 && key === 'txn_fail_percent_start') {
            if (e >= 1) setSlabErrors({ ...slabErrors, [key]: 'Range should start from 0' });
            else setSlabErrors({ ...slabErrors, [key]: '' });
        }
    };

    const validateFeild = async (ev, key) => {
        let err = {};
        const field = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(field[0].validators, key, ev, field[0].maxLength, field[0].minLength);
        let prevErrors = { ...errors, ...err };
        setErrors(prevErrors);
    };

    const update = () => {
        let tempEndingPer = 0,
            changeRecordIndex;
        for (let index = 0; index < slabsDataTable.length; index++) {
            const account = slabsDataTable[index];
            if (account.slab_no === slabsData.slab_no) {
                tempEndingPer = slabsData.txn_fail_percent_end;
                slabsDataTable[index] = slabsData;
                if (index !== slabsDataTable.length - 1 && slabsDataTable[index + 1].txn_fail_percent_start < tempEndingPer) {
                    changeRecordIndex = index + 1;
                }
            }
        }
        if (changeRecordIndex) {
            slabsDataTable[changeRecordIndex].txn_fail_percent_start = '';
            slabsDataTable[changeRecordIndex].txn_fail_percent_end = '';
            setState({ ...state, slabsForm: true });
            setSlabsData(slabsDataTable[changeRecordIndex]);
        } else {
            setState({ ...state, slabsForm: false });
            setSlabsData({ ...slabsData });
        }
    };

    const cancel = () => {
        setState({ ...state, slabsForm: false });
    };

    const disableUpdate = () => {
        let chkErrors = Object.keys(errors).some((key) => {
            return errors[key];
        });
        if (chkErrors) {
            return true;
        }
        if (
            slabsData.txn_fail_percent_start !== '' &&
            !slabErrors.txn_fail_percent_start &&
            slabsData.txn_fail_percent_end !== '' &&
            !slabErrors.txn_fail_percent_end &&
            slabsData.conclusion_opinion !== '' &&
            !slabErrors.conclusion_opinion &&
            slabsData.description !== '' &&
            !slabErrors.description
        ) {
            return false;
        }
        return true;
    };

    // fetchClassications
    const { loading: enumLoader, refetch: refetchClassification } = useQuery(AppSchema.appEnums, {
        fetchPolicy: 'no-cache',
        variables: { where: { enum_type: { equals: 'AUDIT_CLASSIFICATION' } } },
        onCompleted: (data) => {
            if (data && data.appEnums.length > 0) {
                let list = [];
                data.appEnums.map((res) => {
                    list.push({ label: res.display_value, name: res.display_value, id: res.id });
                });
                if (list && list.length > 0) {
                    setClassificationList(list);
                }
                setState({ ...state, loading: false });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const fetchAuditObjectivesCommonMethod = (lastLevelValue, passClassiValues, existAuditId) => {
        fetchAuditObjectives({
            variables: {
                where: {
                    AND: [
                        { OR: passClassiValues },
                        {
                            is_effective: { equals: true },
                            audit_classif_codes: { some: { classif_codes: { in: lastLevelValue } } },
                            OR: [
                                { audit_openions: { every: { status: { notIn: ['Approved', 'Draft'] } } } },
                                { id: { equals: existAuditId ? existAuditId : '' } }
                            ]
                        }
                    ]
                }
            }
        });
    };

    const [fetchAuditObjectives, { loading: objLoader }] = useLazyQuery(GMQueries.filterAuditObjectiveByClassifs, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (data) => {
            if (data?.auditObjectiveDefs.length > 0) {
                const list = [];
                let objectiveValue = [];
                data.auditObjectiveDefs.map((obj) => {
                    if (obj.status === 'Approved') {
                        let setObj = { name: `${obj.ref} - ${obj.objective}`, label: `${obj.ref} - ${obj.objective}`, id: obj.id };
                        list.push(setObj);
                        if (isDraft && state.setDraftObjective && state?.setDraftObjective?.name === setObj?.name) {
                            objectiveValue.push(setObj);
                            setObjective(objectiveValue[0]);
                        }
                    }
                });
                setObjectiveList(list);
                setShowMessage(false);
            } else {
                setShowMessage(true);
                setObjectiveList(data.auditObjectiveDefs);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });
    const [createGuidelineAuditOpinion] = useMutation(GMQueries.createOneAuditOpinionGuideline, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (data) => {
            if (data) {
                growl.show({ life: 6000, severity: 'success', summary: window.GLOBAL_MASTERS.CreateAuditGuideline });
                setState({ ...state, loading: false });
                setTimeout(function () {
                    props.history.goBack();
                }, 2000);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [updateGuidelineAuditOpinion] = useMutation(GMQueries.updateOneAuditOpinionGuideline, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (data) => {
            if (data) {
                let growlmsg = currentAction?.name
                    ? currentAction?.name + window.GLOBAL_MASTERS.Action
                    : window.GLOBAL_MASTERS.UpdateAuditGuideline;
                growl.show({ life: 6000, severity: 'success', summary: growlmsg });
                setState({ ...state, loading: false });
                setTimeout(function () {
                    props.history.goBack();
                }, 2000);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const { loading: entLoader } = useQuery(AppSchema.entityRecord, {
        client: eventClient,
        skip: !isDraft,
        fetchPolicy: 'no-cache',
        variables: { where: { id: recordID } },
        onCompleted: (res) => {
            if (res && res.entityRecord.record) {
                let record = res.entityRecord.record;
                setState({
                    ...state,
                    version_no: record?.version_no,
                    version_user: record?.version_user,
                    entity_id: record?.entity_id,
                    setDraftObjective: record?.objective?.name,
                    setDraftclassification: record?.classification,
                    loading: false
                });
                setEntity(res.entityRecord?.record.entity_id);
                setDraftClassification(record?.classification);
                if (res.entityRecord?.record?.ref) {
                    setRef(res?.entityRecord?.record?.ref);
                    setApproveVersionNo(res?.entityRecord?.record?.approved_version_no);
                }
                if (res?.entityRecord?.record?.ref) setApprovedRecord(!isValidCUID(res?.entityRecord?.record?.ref));
                if (
                    res.entityRecord.record.ref &&
                    (isApproved || res.entityRecord.record.approved_version_no > 0 || !isValidCUID(res.entityRecord.record.ref))
                ) {
                    let resp = { ...res.entityRecord.record };
                    resp.version_no = res.entityRecord?.record?.approved_version_no;
                    checkCount({ variables: ApprovedRecordEntityCheckVariable(resp) });
                }
                prepareData(res?.entityRecord?.record);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const { loading: dataLoader } = useQuery(GMQueries.getGuidelineAuditOpinionById, {
        client: iaClient,
        variables: { where: { id: recordID } },
        fetchPolicy: 'no-cache',
        skip: isDraft || mode === 'create',
        onCompleted: (data) => {
            if (data && data.auditOpinionGuidelineConf) {
                let record = data.auditOpinionGuidelineConf;
                setRef(record?.ref);
                setApprovedRecord(!isValidCUID(record?.ref));
                checkCount({ variables: ApprovedRecordApproveCheckVariable(record) });
                prepareData(record);
                setDraftClassification(record.classification);
            } else {
                setState({ ...state, loading: false });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const prepareData = (obj) => {
        if (obj?.version_no) setVersionNo(obj?.version_no);
        if (obj.id) setRecId(obj.id);
        if (obj) {
            let guidelineDetails = isDraft ? obj.audit_opinion_guideline_details.create : obj.audit_opinion_guideline_details;
            let auditClassifs = [];
            if (isDraft) {
                auditClassifs = obj.audit_classif_codes.create;
            } else {
                obj.classif_codes.map((res) => {
                    auditClassifs.push({ audit_level_id: res.classif_level, name: res?.classif_code_obj?.name, id: res.classif_code });
                });
            }
            const lastLevelValue = auditClassifs?.length > 0 && auditClassifs?.map((res) => res?.id);
            setLastLevelDraft(lastLevelValue);
            let classifRecords = obj.classification.split(',');
            let classificationValues = [];
            refetchClassification().then((enums) => {
                let ele = enums.data.appEnums;
                classifRecords.map((res) => {
                    // eslint-disable-next-line max-nested-callbacks
                    ele.filter((cl) => {
                        if (cl.display_value === res)
                            classificationValues.push({ label: cl.display_value, name: cl.display_value, id: cl.id });
                    });
                });
                if (classificationValues && classificationValues.length > 0) {
                    setClassification(classificationValues);
                }
            });
            let passClassiValues = [];
            classifRecords.map((res) => {
                passClassiValues.push({ audit_classification: { contains: res } });
            });
            if (classifRecords && classifRecords.length > 0 && lastLevelValue) {
                fetchAuditObjectivesCommonMethod(lastLevelValue, passClassiValues, obj?.objective?.id || obj?.audit_objective?.id);
            }
            if (!isDraft && (mode === 'view' || mode === 'edit') && mode !== 'create') {
                let setObj = {
                    name: `${obj.audit_objective.ref} - ${obj.audit_objective.objective}`,
                    label: `${obj.audit_objective.ref} - ${obj.audit_objective.objective}`,
                    id: obj.audit_objective.id
                };
                setObjective(setObj);
            }
            setSlabsDataTable(guidelineDetails);
            setSlabVal(obj.no_of_slabs);
            setState({
                ...state,
                data: obj,
                slab: obj.no_of_slabs,
                setDraftObjective: obj.objective,
                is_active: obj.is_active,
                showTable: true
            });
            setAuditCommonValuesReturn(auditClassifs);
        }
    };

    const [checkCount] = useLazyQuery(GMQueries.guidelineOpinionCount, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (resp) => {
            if (resp.auditPlanDefCount > 0) setIsGuidelineValid(true);
            else setIsGuidelineValid(false);
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const navigateToEdit = () => {
        if (isApproved) setShowActions(true);
        setMode('edit');
    };

    const onClickCancel = () => {
        props.history.goBack();
    };

    const handleModalCancel = (e) => {
        setState({ ...state, showConfirmationModal: e, slab: slabVal });
    };

    const handleContinue = (e) => {
        if (e) {
            setSlabsDataTable([]);
            slabsDataCreation();
            setState({ ...state, showTable: true, showConfirmationModal: false, slabsForm: false });
        }
    };

    const slabsDataCreation = () => {
        let slabs = [];
        for (let i = 1; i <= state.slab; i++) {
            slabs.push({
                slab_no: i,
                txn_fail_percent_start: '',
                txn_fail_percent_end: '',
                conclusion_opinion: '',
                description: ''
            });
        }
        setSlabsDataTable(slabs);
    };

    const setSlabsRecords = () => {
        let obj = [];
        slabsDataTable &&
            slabsDataTable.length > 0 &&
            slabsDataTable.map((res) => {
                obj.push({
                    slab_no: parseInt(res.slab_no),
                    txn_fail_percent_start: parseInt(res.txn_fail_percent_start),
                    txn_fail_percent_end: parseInt(res.txn_fail_percent_end),
                    conclusion_opinion: res.conclusion_opinion,
                    description: res.description
                });
            });
        return obj;
    };

    const prepareAuditOptions = (value, auditId) => {
        let obj = {};
        allHierarchyLevel &&
            allHierarchyLevel.auditDataConfig &&
            allHierarchyLevel.auditDataConfig.map((res) => {
                if (res.name === value) {
                    obj = res.auditSegmentList.find((l) => auditId === l.id);
                }
            });
        return obj;
    };

    const setAuditLevelRecords = () => {
        let audits = [];
        if (Object.keys(auditLevels).length === 0 && auditLevels.constructor === Object) {
            auditCommonValuesReturn.map((val) => {
                audits.push({ audit_level_id: val.audit_level_id, name: val.name, id: val.id });
            });
        } else {
            for (const [key, value] of Object.entries(auditLevels)) {
                let options = prepareAuditOptions(key, value.id);
                audits.push({ audit_level_id: options.audit_level_id, name: options.name, id: options.id });
            }
        }
        return audits;
    };

    const getValue = (value, isTempSave) => {
        if (!isTempSave && (versionNo > 0 || (!versionNo && state.data?.version_no > 0)) && !isApproved) return { set: value };
        return value;
    };

    const getRecord = (isTempSave = true) => {
        let classifValues = classification && classification.map((res) => res.name).join(',');
        let obj = {};
        obj.no_of_slabs = getValue(parseInt(state.slab), isTempSave);
        obj.objective = objective;
        obj.classification = getValue(classifValues, isTempSave);
        obj.audit_classif_codes = { create: setAuditLevelRecords() };
        obj.audit_opinion_guideline_details = { create: setSlabsRecords() };
        obj.is_active = getValue(state.is_active, isTempSave);

        if (checkIsApproved()) obj.version_date = serverDate();
        if (state.data?.version_no || checkIsApproved())
            obj.version_no = approveVersionNo > 0 ? Number(approveVersionNo) + 1 : Number(state.data.version_no) + 1;
        if (checkIsApproved()) obj.ref = ref;
        if (entity) obj.entity_id = entity;
        if (recID && !isApproved) obj.id = recID;
        if (checkIsApproved()) {
            obj.approved_version_no =
                state.data?.approved_version_no && state.data?.approved_version_no > 0
                    ? state.data.approved_version_no
                    : state.data?.version_no;
        }
        return obj;
    };

    const getDisplayData = () => {
        let obj = {};
        obj.objective = Object.keys(objective).length > 0 ? objective.name?.split('-')[2]?.trim() : '';
        obj.no_of_slabs = state?.slab;
        obj.is_active = state.is_active;
        if (Object.keys(auditLevels).length === 0 && auditLevels.constructor === Object) {
            auditCommonValuesReturn.map((val) => {
                obj[val.audit_level_id.toLowerCase()] = val.name;
            });
        } else {
            for (const [key, value] of Object.entries(auditLevels)) {
                obj[key.toLowerCase()] = value.name;
            }
        }
        obj.ref = ref;
        obj.versionNo =
            state.data?.approved_version_no && state.data?.approved_version_no > 0
                ? state.data.approved_version_no
                : state.data?.version_no;
        return obj;
    };

    const onSubmit = (wfParams, action) => {
        setCurrentAction(action);
        let obj = getRecord(false);
        obj.wf_params = wfParams;
        delete obj.entity_id;
        delete obj.version_date;
        if (isApproved) delete obj.id;
        if (checkIsApproved()) delete obj.approved_version_no;
        else delete obj.version_no;
        delete obj.objective;
        setState({ ...state, loading: true });
        if (obj.audit_classif_codes) {
            let auditClassifCodes = [];
            obj.audit_classif_codes.create.forEach((res) => {
                auditClassifCodes.push({ classif_level: res.audit_level_id, classif_code: res.id });
            });
            obj.classif_codes = { create: auditClassifCodes };
        }
        obj.audit_objective = { connect: { id: objective.id } };
        obj.version_date = new Date();
        delete obj.audit_classif_codes;
        if ((versionNo > 0 || (!versionNo && state.data?.version_no > 0)) && !isApproved) {
            delete obj.id;
            delete obj.version_date;
            delete obj.version_no;
            if (checkIsApproved()) obj.ref = { set: ref };
            updateGuidelineAuditOpinion({ variables: { data: obj, where: { id: isDraft ? recID : recordID } } });
        } else {
            if (checkIsApproved() && !isDraft) delete obj.id;
            createGuidelineAuditOpinion({ variables: { data: obj } });
        }
    };

    const handleLoader = (flag) => {
        setState({ ...state, loading: flag });
    };
    const checkDisable = () => {
        let chkLastSlabEndValue = slabsDataTable && slabsDataTable[slabsDataTable.length - 1];
        if (
            (hierarchyLevels.length > 0 || auditCommonValuesReturn.length > 0) &&
            (auditCommonValuesReturn.length > 0 || hierarchyLevels.length === keys.length) &&
            classification.length > 0 &&
            Object.keys(objective).length !== 0 &&
            !slabErrors.slab &&
            parseInt(state.slab) === slabsDataTable.length &&
            chkLastSlabEndValue &&
            chkLastSlabEndValue.txn_fail_percent_end !== ''
        ) {
            return false;
        }
        return true;
    };

    const disabledGo = () => {
        if (isDraft && !errors['slab'] && !slabErrors.slab) {
            return false;
        }
        if (errors && !errors['slab'] && !slabErrors.slab) {
            return false;
        }
        return true;
    };

    const showWorkflow = (flag) => {
        setState({ ...state, showWorkflowStatus: flag });
    };
    const handleCommentClick = (selRow, flag) => {
        const lstToShowComment = state.listToShowComment;
        lstToShowComment[selRow.id] = flag;
        setState({ ...state, listToShowComment: lstToShowComment });
    };

    const keys = Object.keys(auditLevels);
    const minFieldsRequired = () => {
        let chkLastSlabEndValue = slabsDataTable && slabsDataTable[slabsDataTable.length - 1];
        if (
            (!isDraft || mode !== 'edit' || mode !== 'create') &&
            parseInt(state.slab) === slabsDataTable.length &&
            chkLastSlabEndValue &&
            chkLastSlabEndValue.txn_fail_percent_end !== ''
        ) {
            return false;
        }
        if (
            (parseInt(state.slab) && !chkLastSlabEndValue) ||
            (parseInt(state.slab) !== slabsDataTable.length && chkLastSlabEndValue && chkLastSlabEndValue.txn_fail_percent_end !== '')
        ) {
            return true;
        }
        if (chkLastSlabEndValue && chkLastSlabEndValue.txn_fail_percent_end === '') {
            return true;
        }
        if (hierarchyLevels.length > 0 && hierarchyLevels.length === keys.length) {
            return false;
        }
        return true;
    };

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Slab No." field="slab_no" rowSpan={2} />
                <Column header="Transaction Fail Percentage" colSpan={2} headerStyle={{ textAlign: 'center' }} />
                <Column header="Conclusion/Opinion" field="conclusion_opinion" rowSpan={2} />
                <Column header="Description" field="description" rowSpan={2} />
                {mode !== 'view' && <Column header="Actions" field="actions" rowSpan={2} />}
            </Row>

            <Row>
                <Column header="Starting %" field="txn_fail_percent_start" headerStyle={{ textAlign: 'center' }} />
                <Column header="Ending %" field="txn_fail_percent_end" headerStyle={{ textAlign: 'center' }} />
            </Row>
        </ColumnGroup>
    );

    const actionTemplate = (rowData) => {
        return (
            <>
                <button className="a-btn-background-transparent a-icon-container m-l-5 m-r-5" onClick={() => onClickEdit(rowData)}>
                    <img
                        title="Edit"
                        className="pointer"
                        src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                        alt="Edit Icon"
                        height="20px"
                    />
                </button>
            </>
        );
    };

    if (TableSchema.AUDIT_GUIDELINE.length > 0) {
        TableSchema.AUDIT_GUIDELINE[TableSchema.AUDIT_GUIDELINE.length - 1].body = actionTemplate;
    }

    const checkIsApproved = () => {
        return isApproved || (approveVersionNo && approveVersionNo > 0) || approvedRecord;
    };

    const setActionsStatus = (hasActions) => {
        setState({ ...state, hasActions });
    };

    return (
        <div className={`page-body ${state.loading && objLoader && dataLoader && entLoader && enumLoader ? 'mask pos-rel' : 'pos-rel'}`}>
            {(state.loading || objLoader || dataLoader || entLoader || enumLoader) && (
                <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />
            )}
            <Toast ref={(el) => setGrowl(el)} />
            <div className="page-header w-100 spaceBetween">
                <span className="light-color">
                    {(state.data && state.data.id) || recordID
                        ? `${mode[0]?.toUpperCase() + mode?.slice(1)} Guideline for Audit Opinion`
                        : 'Create Guideline for Audit Opinion'}
                    {recordID &&
                        (transactionID && transactionID !== 'undefined' ? (
                            <span className="p-l-5">: {transactionID}</span>
                        ) : (
                            <span className="p-l-5">: {ref}</span>
                        ))}
                </span>
                <span className="backBtn">
                    {recordID && versionNo !== '' && versionNo !== 0 && (
                        <img
                            alt=""
                            className="header-icon pointer"
                            title="Workflow Stages"
                            src={process.env.PUBLIC_URL + '/assets/icons/workflow.svg'}
                            onClick={() => showWorkflow(true)}
                        ></img>
                    )}
                    <span onClick={() => props.history.goBack()} className="pointer">
                        <span className="appkiticon icon-left-chevron-outline icon_size" />
                        Back
                    </span>
                </span>
            </div>
            {(!state.loading || !objLoader || !dataLoader || !entLoader || !enumLoader) && (
                <div
                    className={`${
                        state.hasActions || mode === 'create' || checkIsApproved()
                            ? 'page-content-ht-with-btns'
                            : 'page-content-ht-without-btns'
                    }`}
                >
                    <Sidebar
                        visible={state.showWorkflowStatus}
                        onHide={() => showWorkflow(false)}
                        position="right"
                        dismissable={true}
                        closeOnEscape={false}
                        showCloseIcon={false}
                    >
                        <WorklowSidebarContent
                            sourceId={recordID}
                            listToShowComment={state.listToShowComment}
                            showWorkflow={showWorkflow}
                            handleCommentClick={handleCommentClick}
                        />
                    </Sidebar>
                    {isGuidelineValid && mode === 'view' && InfoMessage(window.EDIT_OF_APPROVAL_MESSAGES.ApprovedMessageForView)}
                    <div className="row">
                        <AuditCommonFields
                            formType={mode}
                            audit={true}
                            risks={false}
                            onSelectedData={onSelectedData}
                            auditDataConfig={allHierarchyLevel.auditDataConfig}
                            passAuditCommonValuesReturn={auditCommonValuesReturn.length > 0 ? auditCommonValuesReturn : []}
                            isApproved={checkIsApproved()}
                        />
                        <div className="col-sm-3">
                            <b className="f-14">
                                Audit Classification(s)
                                {mode !== 'view' && !checkIsApproved() ? <span className="icon-primary p-l-5">*</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-sm-3 m-b-10">
                            {mode !== 'view' && !checkIsApproved() ? (
                                <MultiSelect
                                    filter={true}
                                    className="w-100"
                                    optionLabel="label"
                                    name="classification"
                                    value={classification}
                                    options={classificationList}
                                    onChange={(e) => handleChange(e, 'classification')}
                                    placeholder="Select Audit Classification"
                                    responsive
                                    resetFilterOnHide={true}
                                    filterPlaceholder="Search"
                                    disabled={disableClassif}
                                />
                            ) : (
                                <span className="word-break f-14">{draftClassification}</span>
                            )}
                        </div>
                        <div className="col-sm-3 m-b-10">
                            <b className="f-14">
                                Audit Objective
                                {mode !== 'view' && !checkIsApproved() ? <span className="icon-primary p-l-5">*</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-sm-3 m-b-10">
                            {mode !== 'view' && !checkIsApproved() ? (
                                <Dropdown
                                    name="objective"
                                    value={objective}
                                    onChange={(e) => handleChange(e.value, 'objective')}
                                    options={objectiveList}
                                    placeholder="Select Audit Objective"
                                    className="w-100"
                                    validations={['required']}
                                    optionLabel="name"
                                    filter={true}
                                    filterBy="name"
                                    filterPlaceholder="Search"
                                    resetFilterOnHide={true}
                                    disabled={objectiveDisable}
                                />
                            ) : (
                                <span className="f-14">{isDraft ? state?.setDraftObjective?.name : objective && objective.name}</span>
                            )}
                        </div>
                        <div className="col-sm-3 m-b-10">
                            <b className="f-14">
                                Active/Inactive
                                {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-sm-6 m-b-10">
                            {mode !== 'view' ? (
                                <>
                                    <RadioButton
                                        value={true}
                                        onChange={(e) => handleChange(e.value, 'is_active')}
                                        disabled={mode === 'view'}
                                        checked={state.is_active}
                                    />
                                    <label htmlFor="rb1" className="p-radiobutton-label p-r-20 ">
                                        Active
                                    </label>
                                    <RadioButton
                                        value={false}
                                        onChange={(e) => handleChange(e.value, 'is_active')}
                                        disabled={mode === 'view'}
                                        checked={!state.is_active}
                                    />
                                    <label htmlFor="rb2" className="p-radiobutton-label p-r-20">
                                        Inactive
                                    </label>
                                </>
                            ) : (
                                <span>{state.is_active ? 'Active' : 'Inactive'} </span>
                            )}
                        </div>
                        {showMessage && (
                            <div className="col-sm-6 display-flex justify-flex-end m-b-10">
                                <span className="icon-primary fs-15 info-message p_5">
                                    <img
                                        className="m-r-10"
                                        src={process.env.PUBLIC_URL + '/assets/icons/information.svg'}
                                        alt="info Icon"
                                    />
                                    {'Objectives are not mapped on selected Audit Hierarchy.'}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-sm-3 m-b-10">
                            <b className="f-14">
                                Enter No. of Slabs {mode !== 'view' ? <span className="icon-primary p-l-5">*</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-sm-3 m-b-10">
                            {mode === 'view' ? (
                                <span className="f-14">{state.slab}</span>
                            ) : (
                                <InputComponent
                                    className="w-100"
                                    name="slab"
                                    inputBoxSize="sm"
                                    type="text"
                                    placeholder="Enter No. of Slabs"
                                    value={`${state.slab}`}
                                    onChange={(e) => handleChange(e, 'slab')}
                                    hasError={(errors && errors.slab) || slabErrors.slab}
                                    errMsg={slabErrors.slab ? slabErrors.slab : 'Please enter valid number'}
                                    validations={['required']}
                                    minLength={1}
                                    maxLength={2}
                                />
                            )}
                        </div>
                        {mode !== 'view' && (
                            <div className="col-sm-3 m-b-10">
                                <button
                                    type="button"
                                    className="a-btn a-btn-primary a-btn-md a-keyboard-hover-only-div"
                                    onClick={() => go()}
                                    disabled={disabledGo()}
                                >
                                    Go
                                </button>
                            </div>
                        )}
                    </div>
                    {state.showTable && (
                        <div className="row m-t-10">
                            <div className="col-sm-12">
                                <FDataTable
                                    rows={slabsDataTable}
                                    columns={
                                        mode === 'view'
                                            ? TableSchema.AUDIT_GUIDELINE.filter((a) => a.field !== 'actions')
                                            : TableSchema.AUDIT_GUIDELINE
                                    }
                                    headerColumnGroup={headerGroup}
                                    className="secondary-table t-foot-padd-0"
                                    emptyMessage="No records found"
                                    scrollable={true}
                                    noPaginator={false}
                                />
                            </div>
                        </div>
                    )}

                    {state.slabsForm && (
                        <div className="form-border">
                            <div className="row">
                                <div className="col-sm-3 m-b-10">
                                    <b className="f-14">Slab No:</b>
                                </div>
                                <div className="col-sm-3 m-b-10">
                                    <span>{slabsData.slab_no}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 m-b-10">
                                    <b className="f-14">
                                        Starting %<span className="icon-primary p-l-5">*</span>
                                    </b>
                                </div>
                                <div className="col-sm-3 m-b-10">
                                    <InputComponent
                                        className="w-100"
                                        name="txn_fail_percent_start"
                                        placeholder="Enter Start Percentage"
                                        inputBoxSize="sm"
                                        type="text"
                                        value={slabsData.txn_fail_percent_start}
                                        onChange={(e) => handleSlabsChange(e, 'txn_fail_percent_start')}
                                        hasError={(errors && errors.txn_fail_percent_start) || slabErrors.txn_fail_percent_start}
                                        errMsg={
                                            slabErrors.txn_fail_percent_start
                                                ? slabErrors.txn_fail_percent_start
                                                : 'Please enter valid number'
                                        }
                                        validations={['required']}
                                        maxLength={slabsData.slab_no === 1 ? 1 : 2}
                                    />
                                </div>
                                <div className="col-sm-3 m-b-10">
                                    <b className="f-14">
                                        Conclusion/Opinion <span className="icon-primary p-l-5">*</span>
                                    </b>
                                </div>
                                <div className="col-sm-3 m-b-10">
                                    <InputComponent
                                        className="w-100"
                                        name="conclusion_opinion"
                                        placeholder="Enter Conclusion"
                                        inputBoxSize="sm"
                                        type="text"
                                        value={slabsData.conclusion_opinion}
                                        onChange={(e) => handleSlabsChange(e, 'conclusion_opinion')}
                                        hasError={errors && errors.conclusion_opinion}
                                        errMsg="Please enter valid text"
                                        validations={['required']}
                                        maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 m-b-10">
                                    <b className="f-14">
                                        Ending %<span className="icon-primary p-l-5">*</span>
                                    </b>
                                </div>
                                <div className="col-sm-3 m-b-10">
                                    <InputComponent
                                        className="w-100"
                                        name="txn_fail_percent_end"
                                        placeholder="Enter End Percentage"
                                        inputBoxSize="sm"
                                        type="text"
                                        value={slabsData.txn_fail_percent_end}
                                        onChange={(e) => handleSlabsChange(e, 'txn_fail_percent_end')}
                                        hasError={(errors && errors.txn_fail_percent_end) || slabErrors.txn_fail_percent_end}
                                        errMsg={
                                            slabErrors.txn_fail_percent_end ? slabErrors.txn_fail_percent_end : 'Please enter valid number'
                                        }
                                        validations={['required']}
                                        maxLength={slabsDataTable.length === slabsData.slab_no ? 3 : 2}
                                    />
                                </div>
                                <div className="col-sm-3 m-b-10">
                                    <b className="f-14">
                                        Description <span className="icon-primary p-l-5">*</span>
                                    </b>
                                </div>
                                <div className="col-sm-3 m-b-10">
                                    <TextAreaComponent
                                        className="w-100"
                                        name="description"
                                        placeholder="Enter Description"
                                        textBoxSize="xl"
                                        type="text"
                                        value={slabsData.description}
                                        onChange={(e) => handleSlabsChange(e, 'description')}
                                        validations={['required']}
                                        rows={3}
                                        maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                    />
                                </div>
                            </div>
                            <div>
                                <Button size="sm" kind="primary" onClick={() => update()} disabled={disableUpdate()}>
                                    Update
                                </Button>
                                <Button size="sm" kind="secondary" className="m-l-10" onClick={() => cancel()}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    )}
                    {!state.loading && !(isGuidelineValid && !isApproved && mode === 'view') && (
                        <div className="m-b-10 m-r-10">
                            {showActions ? (
                                <ActionsComponents
                                    mode={isApproved ? 'create' : mode}
                                    growl={growl}
                                    module="IA"
                                    fmis_function="IA_GUIDELINE_AUDIT_OPINION"
                                    fmis_sub_function="NA"
                                    screenName={'Guideline for Object Opinion'}
                                    source_id={isApproved ? '' : isDraft ? recID : recordID}
                                    privRequired={Privileges.UPDATE}
                                    officeCode={Privileges.OFFICE_CODE}
                                    navigateToEdit={navigateToEdit}
                                    onSave={(wfParams, actions) => onSubmit(wfParams, actions)}
                                    minFieldsRequired={minFieldsRequired()}
                                    onCancel={onClickCancel}
                                    data={state.category_data}
                                    version_no={!isApproved ? state.data?.version_no : 0}
                                    canDisable={checkDisable()}
                                    handleLoader={handleLoader}
                                    getRecord={getRecord}
                                    getDisplayData={getDisplayData}
                                    setActionsStatus={setActionsStatus}
                                    isApproved={isApproved}
                                    checkIsApproved={checkIsApproved()}
                                    version_user={state.data.version_user}
                                />
                            ) : (
                                <div className="bottom-left-btm-div">
                                    <IsAuthorized
                                        privRequired={Privileges?.UPDATE}
                                        officeCode={Privileges.OFFICE_CODE}
                                        yes={() => (
                                            <Button size="sm" kind="primary" className="m-r-10 m-t-10" onClick={() => navigateToEdit()}>
                                                Edit
                                            </Button>
                                        )}
                                    ></IsAuthorized>
                                </div>
                            )}
                        </div>
                    )}
                    {state.showConfirmationModal && (
                        <ConfirmationModal
                            row={true}
                            showModal={true}
                            handleCancel={handleModalCancel}
                            header={'Confirmation'}
                            content={'Entered slab data will be removed !'}
                            fstButton={'Yes'}
                            secondBtn={'No'}
                            handleContinue={handleContinue}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
export default AddGuideline;
