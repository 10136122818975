import React, { useState } from 'react';
import { eventClient } from '../../apollo';
import { useQuery } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody } from 'appkit-react';
import './audit.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

const AuditDetailsComponent = (props) => {
    const [activeAccordion] = useState('');
    const [isDetailLoading, setLoading] = useState(true);
    const [auditData, setAuditData] = useState();
    useQuery(Schema.fetchWorkflowAuditDetails, {
        client: eventClient,
        variables: {
            where: {
                source_id_source_version_no: { source_id: props.sourceData.id, source_version_no: props.auditInstance.source_version_no }
            }
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.audit?.details) {
                setAuditData(data.audit.details);
            }
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        }
    });
    const updateAuditFields = (audit) => {
        if (audit.fields?.length > 0) {
            audit.fields.forEach((a) => {
                a.prev = a.prev?.toString() || '';
                a.curr = a.curr?.toString() || '';
            });
        }
        return audit;
    };
    const AuditDetails = (data) => {
        let auditParent = data.data;
        let rows = [];
        let t = constructTable(auditParent.fields, auditParent.label);
        rows.push(t);
        if (auditParent.children && auditParent.children.length > 0) {
            for (let index = 0; index < auditParent.children.length; index++) {
                let audit = auditParent.children[index];
                let name = audit.label + '' + (index + 1);
                audit = updateAuditFields(audit);
                let rw = constructTable(audit.fields, name);
                rows.push(rw);
            }
        }
        return <div>{rows}</div>;
    };

    const constructTable = (fields, name) => {
        return (
            <>
                <Accordions multiOpen={true} activeItem={activeAccordion}>
                    <AccordionItem itemId={name} className="accordian-item m-b-10 code-acc">
                        <AccordionItemHeader title={name} />
                        <AccordionItemBody>
                            <div className="Users coa-card p-10">
                                <DataTable value={fields} emptyMessage={fields.length === 0 && 'No records found'}>
                                    <Column field="label" header="Property Name" />
                                    <Column field="prev" header="Old Value" />
                                    <Column field="curr" header="New Value" />
                                </DataTable>
                            </div>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordions>
            </>
        );
    };

    return (
        <div className={`audits ${isDetailLoading ? 'mask' : ''}`}>
            {isDetailLoading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            {auditData ? <AuditDetails data={auditData}></AuditDetails> : <div className="p-l-20">No data found</div>}
        </div>
    );
};

export default AuditDetailsComponent;
