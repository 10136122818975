export const BreadCrumbItems = {
    exchangecurrency: 'Exchange Currency Rate Master',
    ifsccodemaster: 'IFSC Code Master',
    commentsconfiguration: 'Function/Master Specific Comments',
    overallcomments: 'Overall Comments',
    deductionmaster: 'Deduction Master',
    remittanceconfig: 'Remittance Configuration',
    all: 'All Approved Codes',
    departmentconfig: 'Department Configuration',
    rpm: 'Remittance Purpose Mapping',
    elrm: 'Employee-Loans Mapping',
    plrm: 'Pension-Loans Mapping',
    adrm: 'Contract-Advances Mapping',
    oirm: 'Office-Imprest Mapping',
    supplycategorymaster: 'Supply Category Master',
    securitydepositdeduction: 'Security Deposit Deduction',
    projectMaster: 'Project Master',
    advancemaster: 'Advance Master',
    sanctioningMaster: 'Sanctioning Agency Master',
    implementingMaster: 'Implementing Agency Master',
    admissibleVariance: 'Admissible Variance',
    supplierregistration: 'Supplier Registration',
    configuresuppliercategory: 'Configure Supply Category',
    remittancepurpose: 'Configure Payment Mode',
    crf: 'Configure Reports & Fields',
    dashboard: 'Dashboard',
    generatechallan: 'Generate Challan',
    defineLocalities: 'Define Locality',
    panamountconfig: 'PAN Number Amount Configuration',
    standardReports: 'Standard Report',
    receiptentry: 'Receipt Entry',
    cashDashboard: 'Dashboard',
    cashReport: 'Daily Cash Report',
    collectorReport: 'Daily Collection Report',
    budgetconfig: 'Budget Office',
    deputationconfig: 'Deputation Office',
    dvDashboard: 'Dashboard',
    bankdv: 'Bank Deposit Voucher',
    updatebank: 'Update Bank Ref. No.',
    usermasters: 'Roles Creation',
    organizationLevel: 'Organization Levels',
    otherbills: 'Other Bills',
    contractbill: 'Contract Bills',
    imprestrelease: 'Imprest / Petty Cash Release',
    imprestadjustment: 'Imprest / Petty Cash Adjustment',
    manageforms: 'Manage Forms',
    imprestlimit: 'Monthly Imprest Limit',
    beneficiarydetails: 'Beneficiary Details',
    lockfacility: 'Lock Facility',
    sessionexpired: 'Session Expired',
    passwordexpired: 'Password Expired',
	passwordconfig: 'Password Configuration',
    config: 'Workflow Configuration',
    create: 'Create',
    csc: 'COA Segment Configuration',
    segments: 'COA Segments',
    structures: 'COA Structures',
    codes: 'COA Codes',
    compositions: 'COA Compositions',
    mappings: 'COA Mappings',
    reports: 'COA Reports',
    masters: 'Masters',
    cecc: 'COA Economic Classification Configuration',
    pis: 'Payment Instructions',
    piview: 'View',
    assigncheques: 'Assign Cheques',
    assigncheque: 'Assign Cheque',
    chequecash: 'Cheque / Cash Delivered',
    recallpayment: 'Recall Payment',
    bankmaster: 'Bank Account Master',
    cashmaster: 'Cash Account Master',
    chequebooks: 'Cheque Books',
    chequeregister: 'Cheque Register',
    voidcheque: 'Void Cheque',
    reversecheque: 'Reverse Cheque',
    empdetails: 'Employee Details',
    empclsdetails: 'Details',
    empstatuschange: 'Employee Status Change',
    payentitlement: 'Pay Entitlement Master',
    payentitlementchange: 'Pay Entitlement Change',
    paycommission: 'Pay Commission',
    empclsstructure: 'Structure',
    auditplanconfig: 'Audit Plan Configuration',
    auditprogram: 'Audit Program Configuration',
    riskdef: 'Risk Definition',
    controlsdef: 'Controls Definition',
    entityriskmaturity: 'Entity Risk Maturity',
    transriskandcontrols: 'Transaction Risks and Controls',
    transriskranking: 'Transaction Risk Ranking',
    auditareaandhierarchy: 'Auditable Area and Hierarchy',
    filenumber: 'File Number',
    guidelineauditopinion: 'Guideline For Audit Opinion',
    followupauditconfig: 'Follow-Up Audit Configuration',
    emppayentitlement: 'Employee Pay Entitlements',
    paycomponent: 'Pay Component',
    empgroup: 'Employee Group',
    payrollvoucher: 'Payroll Voucher',
    scheduledjobs: 'Scheduled Jobs Configuration',
    globalconfiguration: 'Global Configuration',
    approveddepartment: 'All Approved Departments',
    newchallan: 'New Challan',
    misreportconfig: 'Configure MIS Report',
    personnelbills: 'Personnel Bills',
    documentcategory: 'Document Category',
    saap: 'Strategic and Annual Audit Planning',
    aape: 'Audit Assignment Planning and Execution',
    reportsanddashboards: 'Reports and Dashboards',
    mdaprofile: 'MDA Profile',
    humanresourcesplan: 'Human Resources Plan',
    audithierarchy: 'Audit Hierarchy',
    resourceplanconfig: 'Resource Plan Configuration',
    ratecard: 'Rate Card',
    gradeallowanceconfig: 'Grade-Allowance Configuration',
    stakeholders: 'Key Stakeholders',
    transriskandcontrolspage: 'Transaction Risk and Controls',
    addevents: 'Add Events',
    calendarblocking: 'Calendar Blocking',
    riskmaturityassessment: 'Risk Maturity Assessment',
    audiplanschedule: 'Audit Plan Schedule',
    auditplanstatus: 'Audit Plan Status',
    riskassessment: 'Risk Assessment',
    rankedaudituniverse: 'Ranked Audit Universe',
    strategicadtplan: 'Strategic Audit Plan',
    scheduledaudits: 'Scheduled Audits',
    adhocaudits: 'Ad-Hoc Audits',
    consultingassignment: 'Consulting Assignments',
    administrativeactivities: 'Administratve Activities',
    annualauditplan: 'Annual Audit Plan View',
    conflictofinterest: 'Conflict Of Interest',
    auditcommencementform: 'Audit Resources and Schedule',
    summaryauditablearea: 'Summary Auditable Area',
    requestlistresponse: 'Request List Response',
    auditscope: 'Audit Scope',
    inheritrisk: 'Inherit Risk',
    controlassessment: 'Control Assessment',
    auditassignmentstatus: 'Audit Assignment Status',
    auditstatus: 'Audit Status',
    summaryauditsubject: 'Summary Audit Subject',
    auditclosureform: 'Audit Closure Form',
    auditmeetings: 'Audit Meetings',
    residualriskassessment: 'Residual Risk Assessment',
    riskassessmentconclusion: 'Risk Assessment Conclusion',
    reportactuals: 'Report Actuals',
    auditfindings: 'Audit Findings',
    audittestresults: 'Audit Test Results',
    auditassignmentplan: 'Audit Assignment Plan',
    aapauditprogram: 'Audit Program',
    summaryauditplan: 'Summary Audit Plan',
    scheduleaudits: 'Scheduled Audit',
    aapexecution: 'AAP Execution',
    inherentrisk: 'Inherent Risk',
    auditregister: 'Audit Register',
    auditunit: 'Internal Audit Units',
    auditobservations: 'Audit Observations',
    generatereport: 'Generate Report',
    audithistory: 'Audit History'
};
