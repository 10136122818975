import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../TableSchema';
import Calendar from '../../../../components/Calendar/CalendarComponent';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import ConfirmationModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../Validation';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { getDate, serverDate, uniqueId } from '../../../../util';

const TransferChargers = (props) => {
    const [rows, setRows] = useState(props.formStateObj?.Transfer_Chargers);
    useEffect(() => {
        setRows(props.formStateObj?.Transfer_Chargers);
        // eslint-disable-next-line
    }, [props.formStateObj?.Transfer_Chargers]);
    let viewMode = TableSchema.Transfer_Chargers.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.mode !== 'view' ? TableSchema.Transfer_Chargers : viewMode;
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false
    });
    let emptyObj = {
        distance: '',
        rate: '',
        home_journey_date: '',
        transfer_grant: '',
        amount: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    const fields = [
        { key: 'rate', validators: 'numbersOnly', maxLength: 6 },
        { key: 'distance', validators: 'numbersOnly', maxLength: 3 },
        { key: 'transfer_grant', validators: 'numbersOnly', maxLength: 6 }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);

        if (err[key] || ((key === 'rate' || key === 'transfer_grant' || key === 'distance') && parseInt(ev) <= 0)) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const saveForm = () => {
        currentDetailsObj.home_journey_date = getDate(currentDetailsObj.home_journey_date, true);
        currentDetailsObj.amount = parseInt(currentDetailsObj.distance) * parseInt(currentDetailsObj.rate);
        if (state.actionType === 'edit') {
            let indx = rows.findIndex((a) => a.id === editObj.id);
            rows[indx] = currentDetailsObj;
            setRows(rows);
        } else {
            currentDetailsObj.id = uniqueId(20);
            rows.push(currentDetailsObj);
            setRows(rows);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Transfer_Chargers', rows);
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                key !== 'amount' &&
                (currentDetailsObj[key] === undefined ||
                    currentDetailsObj[key] === '' ||
                    (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === ''))
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            newObj['home_journey_date'] = new Date(newObj.home_journey_date);
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = rows.filter((a) => {
            return a.id !== currentDetailsObj.id;
        });
        setRows(filteredRows);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Transfer_Chargers', filteredRows);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    const getSum = () => {
        let total = 0;
        rows.forEach((ro) => {
            total = total + parseInt(ro.amount);
        });
        return isNaN(total) ? '' : total;
    };

    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={4} />
                <Column footer={getSum()} footerStyle={{ textAlign: 'right' }} />
                {props.mode !== 'view' && <Column />}
            </Row>
        </ColumnGroup>
    );
    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Transfer Chargers are yet to be created"
                    className="secondary-table"
                    scrollable
                    mode={props?.mode}
                    footerGroup={footerGroup}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Distance(Kms){props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="distance"
                                            type="text"
                                            value={currentDetailsObj.distance}
                                            maxLength={3}
                                            onChange={(e) => handleChange(e, 'distance')}
                                            placeholder="Enter Distance(Kms)"
                                            hasError={errors && errors.distance}
                                            errMsg="Please enter valid Distance(Kms)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Rate{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="rate"
                                            type="text"
                                            value={currentDetailsObj.rate}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e, 'rate')}
                                            placeholder="Enter Rate"
                                            hasError={errors && errors.rate}
                                            errMsg="Please enter valid Rate"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Date of Home journey{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Calendar
                                            className="w-100"
                                            maxDate={serverDate()}
                                            readOnlyInput={true}
                                            placeholder="Select Date of Home journey"
                                            appendTo={document.self}
                                            value={currentDetailsObj.home_journey_date}
                                            onChange={(e) => handleChange(e.target.value, 'home_journey_date')}
                                            dateFormat="dd M yy"
                                            showIcon
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Transfer Grant{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="transfer_grant"
                                            type="text"
                                            value={currentDetailsObj.transfer_grant}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e, 'transfer_grant')}
                                            placeholder="Enter Transfer Grant"
                                            hasError={errors && errors.transfer_grant}
                                            errMsg="Please enter valid Transfer Grant"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.mode !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (rows?.length === 0 ? '' : 'Another ') + 'Transfer Chargers'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Transfer Chargers"
                    showModal={state.showDeleteModal}
                    name={'Transfer Chargers : Distance: ' + currentDetailsObj.distance}
                />
            )}
        </div>
    );
};

export default TransferChargers;
