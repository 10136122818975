import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import '../../../../styles/Dialog.scss';
import TableSchema from '../../CMTableSchema';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import CommonInlineTable from '../../../../components/commonTable/CommonInlineTable';

export function OtherDeductionModal(props) {
    let formObj = {
        rows: JSON.parse(JSON.stringify(props.deductionRows)) || [],
        totalAmount: JSON.parse(JSON.stringify(props.currentDetailsObj.other_deduction)) || ''
    };
    const [formState, setFormState] = useState(formObj);
    let viewMode = TableSchema.CONTCT_PAY_OTH_DEDUCTIONS.filter((a) => a.fieldType !== 'Actions');
    let tablecolms = props.formType !== 'view' ? TableSchema.CONTCT_PAY_OTH_DEDUCTIONS : viewMode;

    const [state] = useState({
        columns: tablecolms,
        sumOfDeductions: getSumOfDeductions()
    });
    const [editObj, setEditObj] = useState({});

    function getSumOfDeductions() {
        let amount = 0;
        if (!isNaN(parseFloat(props.currentDetailsObj.security_depst_dedct))) {
            amount = amount + parseFloat(props.currentDetailsObj.security_depst_dedct);
        }
        if (!isNaN(parseFloat(props.currentDetailsObj.adavce_recovery))) {
            amount = amount + parseFloat(props.currentDetailsObj.adavce_recovery);
        }
        if (isNaN(amount)) amount = parseFloat(props.currentDetailsObj.amt_inc_tax);
        else amount = parseFloat(props.currentDetailsObj.amt_inc_tax) - amount;
        return amount.toFixed(2);
    }

    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total Deductions" colSpan={3} />
                <Column footer={formState?.totalAmount} footerStyle={{ textAlign: 'right' }} />
                <Column />
                {props.formType !== 'view' && <Column />}
            </Row>
        </ColumnGroup>
    );

    // other deductions prepare table rows and based on type(incl or excl) return sum of rows
    const calculateDeductions = (item, incVal, excVal) => {
        let val = 0;
        if (item?.deduction?.exceptions?.length > 0 && item.deduction.exceptions?.find((a) => a.fmis_function === 'Create Contract')) {
            val = (parseFloat(excVal) / 100) * parseFloat(item.deduction.amount);
        } else {
            val = (parseFloat(incVal) / 100) * parseFloat(item.deduction.amount);
        }
        return val;
    };

    const handleRowInputChange = (rowData, column, index, e) => {
        if (
            column.field === 'pay_amount' &&
            (isNaN(e) ||
                parseInt(e) === 0 ||
                parseFloat(e) > state.sumOfDeductions ||
                parseFloat(e) > state.sumOfDeductions ||
                parseFloat(e) > calculateDeductions(rowData, props.currentDetailsObj?.amt_inc_tax, props.currentDetailsObj?.amt_exc_tax))
        ) {
            return;
        }
        formState.rows[index.rowIndex][column.field] = e;
        setFormState({ ...formState, rows: formState.rows });
    };

    const hadleRowEdit = (rowData, column, index) => {
        formState.rows[index.rowIndex].enableEdit = true;
        setEditObj(JSON.parse(JSON.stringify(rowData)));
        setFormState({ ...formState, rows: formState.rows });
    };
    const disableSave = () => {
        return formState.rows.some((a) => a.enableEdit);
    };

    const hadleRowSave = (rowData, column, index) => {
        if (rowData.pay_amount?.trim() === '') {
            props.growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.AmountMandatory });
            return;
        }
        if (rowData.remarks?.trim() === '' && parseFloat(editObj.pay_amount) !== parseFloat(rowData.pay_amount)) {
            props.growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.RemarksMandatory });
            return;
        }
        let amount = 0;
        formState.rows[index.rowIndex].enableEdit = false;
        formState.rows.forEach((a) => {
            if (a.pay_amount) amount = amount + parseFloat(a.pay_amount);
        });
        if (isNaN(amount)) amount = '';
        else amount = amount.toFixed(2);
        setEditObj({});
        setFormState({ ...formState, rows: formState.rows, totalAmount: amount });
    };
    const handleCancel = () => {
        setFormState(formObj);
        setEditObj({});
        props.handleCancel();
    };

    return (
        <Modal visible={props.showModal} onCancel={props.handleCancel} className="common_css xl-modal add_user" backdropClosable={false}>
            <ModalHeader>Other Deduction</ModalHeader>
            <ModalBody>
                <div className="Users row">
                    <CommonInlineTable
                        columns={state.columns}
                        handleRowInputChange={handleRowInputChange}
                        hadleRowEdit={hadleRowEdit}
                        hadleRowSave={hadleRowSave}
                        noPaginator={true}
                        rows={formState.rows}
                        footerGroup={footerGroup}
                        emptyMessage="Other Deductions are yet to be created"
                        formType={props.formType}
                    />
                </div>
            </ModalBody>
            <ModalFooter className={props?.loading ? 'mask p-10' : 'p-10'}>
                <Button
                    size="sm"
                    disabled={disableSave()}
                    kind="primary"
                    className="m-r-10"
                    onClick={() => {
                        props.handleSave(formState);
                    }}
                >
                    OK
                </Button>
                <Button size="sm" kind="secondary" className="" onClick={() => handleCancel()}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default OtherDeductionModal;
