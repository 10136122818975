export function DownloadFile(fileName, fileNameToDownload) {
    fetch(window.API_URL + 'sysadmin/download/' + fileName).then((response) => {
        response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileNameToDownload;
            a.click();
        });
    });
}

export function IADownloadPreview(fileName, fileNameToDownload, mimeType) {
    let downloadUrl = 'sysadmin/stream/';
    fetch(window.API_URL + downloadUrl + fileName).then((response) => {
        var fileURL = response.url;
        var a = document.createElement('a');
        a.href = fileURL;
        a.setAttribute('type', mimeType);
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    });
}

export function IADownloadFile(fileName, fileNameToDownload, mimeType) {
    let downloadUrl = 'sysadmin/download/';
    fetch(window.API_URL + downloadUrl + fileName).then((response) => {
        response.blob().then((blob) => {
            var fileURL = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = fileURL;
            a.setAttribute('type', mimeType);
            a.download = fileNameToDownload;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    });
}

export function prepareURL(fileName) {
    return window.API_URL + 'sysadmin/download/' + fileName;
}
