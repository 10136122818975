import React, { useState } from 'react';
import { eventClient, iaClient } from '../../apollo';
import { useQuery, useLazyQuery } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import FDataTable from '../fDataTable/FDataTable';
import TableSchema from '../../commons/TableSchema';
import { getDate } from '../../util';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Modal, ModalHeader, ModalBody } from 'appkit-react';
import CommonModal from '../../commons/CommonModal'

const AuditInstanceComponent = (props) => {
	const [rows, setRows] = useState([]);
	const [isInstaLoading, setLoading] = useState(true);
	const [state, setState] = useState({});

	useQuery(Schema.fetchWorkflowInstanceSteps, {
		client: eventClient,
		variables: { wf_instance_id: props.wf_instance_id },
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data.workflowInstanceSteps?.length > 0) {
				let InstanceIds = [];
				data.workflowInstanceSteps.forEach((res) => {
					switch (res.status?.toLowerCase()) {
						case 'approved':
							res.status = 'Approved';
							break;
						case 'draft':
							res.status = 'Draft';
							break;
						case 'sent for verification':
							res.status = 'Sent For Verification';
							break;
						case 'sent for revision':
							res.status = 'Sent For Revision';
							break;
						case 'sent for approval':
							res.status = 'Sent For Approval';
							break;
						case 'rejected':
							res.status = 'Rejected';
							break;
						case 'cancelled':
							res.status = 'Cancelled';
							break;
						default:
					}
					res.version_date_local = getDate(res.version_date);
					res.comments = res?.comments.trim() === '' ? null : res?.comments?.split('#');
					//res.is_audit_trail = true;
					InstanceIds.push(res.id)

				});
				setRows(data.workflowInstanceSteps);
				checkIsAuditTrail({ variables: { where: { wf_instance_step_id: { in: InstanceIds } } } });
			}
			//setLoading(false);
		},
		onError: () => {
			setLoading(false);
		}
	});

	const onClickAudit = (rowData) => {
		props.setEvent(rowData);
	};

	const onClickComments = (row) => {
		setState({ ...state, showModal: true, row: row });
	};

	const onClickAuditTrail = (rowData) => {
		if (rowData?.is_audit_trail?.record) {
			rowData.is_audit_trail.record.map(obj => {
				obj.old_value = obj.old_value?.toString();
				obj.new_value = obj.new_value?.toString();
			})
		}
		setState({ ...state, showauditTrailModal: true, auditTrailData: rowData?.is_audit_trail?.record, versionDate: rowData?.is_audit_trail?.version_date });
	}

	const [checkIsAuditTrail] = useLazyQuery(Schema.auditTrails, {
		fetchPolicy: 'no-cache',
		client: iaClient,
		onCompleted: async (data) => {
			if (data && data.auditTrails) {
				rows.map(instance => {
					instance.is_audit_trail = data.auditTrails.filter(t => t.wf_instance_step_id === instance.id)[0];
				})

			}
			setLoading(false);
		},
		onError: () => {
			setLoading(false);
			return 'Error';
		}
	});

	const CommentsModal = () => (
		<Modal
			visible={state.showModal}
			onCancel={() => setState({ ...state, showModal: false })}
			className="common_css"
			backdropClosable={false}
		>
			<ModalHeader>Comments</ModalHeader>
			<ModalBody className="confirm-modal font-14">
				<div className="comment-div">
					<ul className="list-style-disc m-b-0">
						{state.row.comments.map((comment, i) => (
							<li className="list-style-disc" key={i}>
								{comment}
							</li>
						))}
					</ul>
				</div>
			</ModalBody>
		</Modal>
	);

	return (
		<div className={`${isInstaLoading ? 'mask' : ''}`}>
			{isInstaLoading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
			<FDataTable
				rows={rows}
				columns={TableSchema.AUDIT_INSTANCE_LOG}
				className="primary-table"
				emptyMessage="No record found"
				noPaginator={true}
				onClickAudit={onClickAudit}
				onClickComments={onClickComments}
				onClickAuditTrail={onClickAuditTrail}
				scrollable={true}
			/>
			{state.showModal && <CommentsModal />}
			{state.showauditTrailModal &&
				<CommonModal
					showModal={state.showauditTrailModal}
					title={`Event at ${state.versionDate}`}
					cancelText="Cancel"
					closeModal={() => setState({ ...state, showauditTrailModal: false })}
					type="table"
					rows={state.auditTrailData}
					schema={TableSchema.AUDIT_TRAIL_LOG}
					modalClass="view-modal"
				>
				</CommonModal>
			}
		</div>
	);
};

export default AuditInstanceComponent;
