import { gql } from '@apollo/client';
export const EXPSchema = {
    personnelBillConfs: gql`
        query personnelBillConfs($skip: Int, $take: Int, $orderBy: [PersonnelBillConfOrderByInput!], $where: PersonnelBillConfWhereInput!) {
            personnelBillConfs(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                ref
                code
                is_active
                description
                include_subbill
                config_details {
                    applicable_emp_status
                    bill_value_type
                }
                sub_bill_details {
                    description
                    code
                }
                status
                version_date
            }
        }
    `,
    personnelBillConf: gql`
        query personnelBillConf($where: PersonnelBillConfWhereUniqueInput!) {
            personnelBillConf(where: $where) {
                id
                ref
                code
                description
                include_subbill
                status
                ref
                allow_recoveries
                include_subbill
                include_additional_details
                include_deductions
                is_latest
                is_active
                version_no
                version_user
                sub_bill_details {
                    id
                    code
                    description
                    is_active
                }
                additional_details {
                    id
                    fields {
                        id
                        field
                        is_visible
                        is_mandatory
                    }
                }
                recovery_details {
                    id
                    recovery_type
                    is_mandatory
                }
                deductions {
                    id
                    deduction_id
                    is_mandatory
                    deduction
                }
                config_details {
                    applicable_emp_status
                    display_prev_bills
                    bill_value_type
                    amount
                    include_sanction_details
                    include_thrirdparty_details
                    include_superannuation_date
                    include_gpf_pran_epf
                    include_journey_details
                    include_family_members
                    include_day_allowances
                    include_personnel_expenses
                    include_stay_details
                    include_transfer_charges
                    include_private_expenses
                    include_el_encashment_days
                }
                codes {
                    id
                    seg_def_name
                    level_name
                    segment_code
                    segment_full_code
                    segment_name
                }
            }
        }
    `,
    deleteOnePersonnelBillConf: gql`
        mutation deleteOnePersonnelBillConf($where: PersonnelBillConfWhereUniqueInput!) {
            deleteOnePersonnelBillConf(where: $where) {
                id
            }
        }
    `,
    createOnePersonnelBillConf: gql`
        mutation createOnePersonnelBillConf($data: PersonnelBillConfCreateInput!) {
            createOnePersonnelBillConf(data: $data) {
                id
            }
        }
    `,
    updateOnePersonnelBillConf: gql`
        mutation updateOnePersonnelBillConf($data: PersonnelBillConfUpdateInput!, $where: PersonnelBillConfWhereUniqueInput!) {
            updateOnePersonnelBillConf(data: $data, where: $where) {
                version_no
                version_user
            }
        }
    `,
    personnelBillConfCount: gql`
        query personnelBillConfCount($where: PersonnelBillConfWhereInput) {
            personnelBillConfCount(where: $where)
        }
    `,
    employeeBankAccounts: gql`
        query employeeBankAccounts($whereEmp: EmployeeBankAccountWhereInput!) {
            employeeBankAccounts(where: $whereEmp) {
                id
                emp_id
                bank_acc_id
                is_primary
                account {
                    id
                    name
                    number
                    holder_name
                    bank_id
                    bank
                }
            }
        }
    `,
    beneficiaryAccounts: gql`
        query beneficiaryAccounts($where: BeneficiaryAccountWhereInput) {
            beneficiaryAccounts(where: $where) {
                beneficiary_id
                acc_id
                is_primary
                account {
                    name
                    number
                    holder_name
                    bank_id
                    bank {
                        ifsc
                        name
                        branch_name
                    }
                }
            }
        }
    `,
    createOnePBThirdPartyPayment: gql`
        mutation createOnePBThirdPartyPayment($data: PBThirdPartyPaymentCreateInput!) {
            createOnePBThirdPartyPayment(data: $data) {
                id
            }
        }
    `,
    updateOnePBThirdPartyPayment: gql`
        mutation updateOnePBThirdPartyPayment($data: PBThirdPartyPaymentUpdateInput!, $where: PBThirdPartyPaymentWhereUniqueInput!) {
            updateOnePBThirdPartyPayment(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOnePBThirdPartyPayment: gql`
        mutation deleteOnePBThirdPartyPayment($where: PBThirdPartyPaymentWhereUniqueInput!) {
            deleteOnePBThirdPartyPayment(where: $where) {
                id
            }
        }
    `
};

export default EXPSchema;
