import React, { useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import SearchFilter from '../../../components/COASelectionTable/SearchFilter';
import { Input } from 'appkit-react';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { convertTextCase } from '../../../util';

const RemitanceSegs = (props) => {
    const [state, setState] = useState({
        remitances: props.remitanceDetails,
        editData: props.isDraft ? props?.editData?.code_details?.create : props?.editData?.code_details,
        selectedSegments: props.mode !== 'create' ? props.selectedSegments() : {},
        filterSegments: [],
        filteredSelSegs: {},
        searchText: {}
    });
    useEffect(() => {
        setState({
            ...state,
            remitances: props.remitanceDetails,
            editData: props.isDraft ? props?.editData?.code_details?.create : props?.editData?.code_details,
            selectedSegments: props.mode !== 'create' ? props.selectedSegments() : {},
            filterSegments: [],
            filteredSelSegs: {},
            searchText: {}
        });
        // eslint-disable-next-line
    }, [props.remitanceDetails.segments]);

    const handleSubFunChange = (e) => {
        if (e.checked) {
            state.selectedSegments[e.value] = true;
        } else {
            state.selectedSegments[e.value] = false;
        }
        setState({ ...state, selectedSegments: state.selectedSegments });
        sortSegmentsOnSel();
        checkIfAllSelected(state.selectedSegments);
    };

    const sortFunction = (a) => {
        return a.segments.sort((b, c) => {
            const keyA = b.id in state.selectedSegments && state.selectedSegments[b.id];
            const keyB = c.id in state.selectedSegments && state.selectedSegments[c.id];

            if (keyA && !keyB) {
                return -1;
            }
            if (keyA && keyB && parseInt(b.full_code) > parseInt(c.full_code)) {
                return 1;
            } else if (keyA && keyB && parseInt(c.full_code) > parseInt(b.full_code)) {
                return -1;
            }
            if (parseInt(b.full_code) > parseInt(c.full_code) && !keyA && !keyB) {
                return 1;
            } else if (parseInt(c.full_code) > parseInt(b.full_code) && !keyA && !keyB) {
                return -1;
            }
            return 0;
        });
    };
    const sortSegmentsOnSel = () => {
        let sortArr = sortFunction(state.remitances);
        setState({ ...state, remitances: sortArr });
        props.updateProps({ remitances: sortArr, selectedSegments: state.selectedSegments });
    };

    const handleOnToogle = (codesList, index) => {
        let id;
        let list = JSON.parse(JSON.stringify(codesList));
        let selSegs = JSON.parse(JSON.stringify(state.selectedSegments));
        for (let i = 0; i < state.remitances.segments.length; i++) {
            id = 'myDropdown' + i;
            if (document.getElementById(id)?.classList.contains('show') && index !== i) {
                document.getElementById(id) && document.getElementById(id).classList.remove('show');
            }
            if (index !== i && state.searchText[i]) {
                state.searchText[i] = '';
            }
        }
        id = 'myDropdown' + index;
        if (!document.getElementById(id)?.classList.contains('show')) {
            document.getElementById(id) && document.getElementById(id).classList.add('show');
            props.clearSearchData({ ...props.searchText });
            setState({ ...state, filterSegments: list, filteredSelSegs: selSegs, searchText: { ...state.searchText } });
        }
        state.editData &&
            state.editData.length > 0 &&
            state.editData.forEach((fun) => {
                fun.hideMe = false;
            });
    };

    const filterBySearchString = (list, e) => {
        let val1 =
            list?.length > 0 &&
            list.map((fun) => {
                fun.hideMe = false;
                if (!fun.filterLabel.toLowerCase().includes(e.toLowerCase())) {
                    fun.hideMe = true;
                }
                return fun;
            });
        let val2 =
            state.editData.length > 0 &&
            state.editData.forEach((fun, ind) => {
                fun.hideMe = false;
                let filterLabel = fun.segment_full_code + '-' + fun.segment_name;
                if (!filterLabel.toLowerCase().includes(e.toLowerCase())) {
                    let fieldKey = ind > 3 ? 'remittancesSearch' : '';
                    fun.hideMe = props.searchKey === fieldKey ? true : false;
                }
            });
        return props.mode === 'create' ? val1 : val2;
    };

    const handleFilterChange = (e, codesList, index) => {
        const list = JSON.parse(JSON.stringify(codesList));
        let val = filterBySearchString(list, e);
        props.clearSearchData({ [index]: e });
        setState({
            ...state,
            filterSegments: val,
            searchText: { [index]: e }
        });
    };
    const hadleCheckAll = (e) => {
        let obj = {};
        state.remitances.checked = e.checked;
        state.remitances.segments.forEach((a) => {
            obj[a.id] = e.checked;
        });
        state.remitances.segments.sort((b, c) => {
            if (parseInt(b.full_code) > parseInt(c.full_code)) {
                return 1;
            } else if (parseInt(c.full_code) > parseInt(b.full_code)) {
                return -1;
            }
            return 0;
        });
        let obj1 = { ...state.selectedSegments, ...obj };
        setState({ ...state, remitances: state.remitances, selectedSegments: obj1 });
        props.updateProps({ remitances: state.remitances, selectedSegments: obj1 });
    };
    const handleSearchChange = (selectedSegments) => {
        state.selectedSegments = selectedSegments;
        setState((prevState) => {
            return { ...prevState, searchText: {}, selectedSegments: state.selectedSegments };
        });
        checkIfAllSelected(selectedSegments);
        sortSegmentsOnSel();
    };
    const checkIfAllSelected = (selcodes) => {
        state.remitances.checked = false;
        let val = state.remitances.segments.every((b) => b.id in selcodes && selcodes[b.id]);
        if (val) state.remitances.checked = true;
        setState((prevState) => {
            return { ...prevState, remitances: state.remitances };
        });
    };

    const closeFilter = (index) => {
        let id = 'myDropdown' + index;
        document.getElementById(id).classList.remove('show');
        props.clearSearchData({});
        setState({ ...state, searchText: {}, filterSegments: [], filteredSelSegs: {} });
    };

    return (
        <div>
            {state.remitances?.segment && (
                <b>
                    {convertTextCase(state.remitances?.segment, false)} ({state.remitances.name})
                </b>
            )}
            <div>
                {props.mode !== 'view' && state.remitances?.segments?.length <= 0 && !props.loading && (
                    <div className="fs-12 m-t-20">
                        {props.remitanceSel?.id ? (
                            <div>
                                {'COA codes for '}
                                <b>
                                    &apos;
                                    {convertTextCase(state.remitances.name, false)}
                                    &apos;
                                </b>
                                {' are yet to be created'}
                            </div>
                        ) : (
                            <div>
                                Select coa code from
                                <b>
                                    {convertTextCase(props.remitanceDetails?.segment, false)}
                                    &nbsp;({props.remitanceDetails.level})
                                </b>
                            </div>
                        )}
                    </div>
                )}
                {state.remitances?.segments?.length > 0 && (
                    <div>
                        <div className="position-relative m-t-10">
                            <span
                                className={`map-search position-relative p-0 ${props.mode === 'view' ? 'm-b-20' : 'm-b-10'}`}
                                id="myDropdownsel"
                                onClick={() => handleOnToogle(state.remitances.segments, 0)}
                            >
                                <Input
                                    className={`w-70 ${props.mode === 'view' ? 'mask pointer-none' : ''}`}
                                    autoComplete="off"
                                    name={'fimsSearch' + 0}
                                    value={props.searchText[0] || ''}
                                    onChange={(e) => handleFilterChange(e, state.remitances.segments, 0)}
                                    placeholder={'Search By ' + convertTextCase(state.remitances.name, false)}
                                />
                                <span className="appkiticon icon-primary icon-search-outline" />
                            </span>
                            {props.mode !== 'view' && (
                                <div id={'myDropdown' + 0} className="filter-dropdown-content ">
                                    <SearchFilter
                                        index={0}
                                        closeFilter={closeFilter}
                                        name={state.remitances.name}
                                        options={state.filterSegments}
                                        selectAll={state.remitances.checked}
                                        selectedCodes={state.filteredSelSegs}
                                        handleSearchChange={(codes) => handleSearchChange(codes)}
                                    />
                                </div>
                            )}
                        </div>
                        {props.mode !== 'view' && (
                            <div className="display-flex align-center">
                                <Checkbox inputId="all" onChange={(e) => hadleCheckAll(e)} checked={state.remitances.checked}></Checkbox>
                                <label htmlFor="all" className="p-checkbox-label p-l-10">
                                    All
                                </label>
                            </div>
                        )}
                    </div>
                )}
                {props.mode === 'view' ? (
                    <div className="fs-14  m-b-15">
                        {state.editData &&
                            state.editData.length > 0 &&
                            state.editData.map((item, index) => {
                                return (
                                    index > 3 &&
                                    item.segment_def_name === convertTextCase(state.remitances.segment, false) && (
                                        <div
                                            key={index}
                                            title={item.segment_full_code + '-' + item.segment_name}
                                            className={`m-b-5 ${state.editData?.[index]?.hideMe ? 'display-none' : ''}`}
                                        >
                                            {item.segment_full_code + '-' + item.segment_name}
                                        </div>
                                    )
                                );
                            })}
                    </div>
                ) : (
                    <div className="slmapp-ht-slm p-t-10">
                        {state.remitances.segments &&
                            state.remitances.segments.map((fm, index) => (
                                <div key={`${fm.name}${fm.code}${index}`} className="p-b-10 display-flex align-center">
                                    <Checkbox
                                        inputId={fm.id}
                                        value={fm.id}
                                        onChange={(e) => handleSubFunChange(e)}
                                        checked={state.selectedSegments[fm.id]}
                                    ></Checkbox>
                                    <label htmlFor={fm.name} className="p-checkbox-label p-l-10  word-ellipses">
                                        <EllipsisWithTooltip placement="auto">{fm.filterLabel}</EllipsisWithTooltip>
                                    </label>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default RemitanceSegs;
