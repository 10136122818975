import React, { useState, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import Schema from '../../../../../schema/AppSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody } from 'appkit-react';
import EmployeeActions from './EmployeeActions';
import EmployeeClassification from './EmployeeClassification';
import EmployeeInformation from './EmployeeInformation';
import EmployeeSpecificInfo from './EmployeeSpecificInfo';
import { prepareURL } from '../../../../../commons/Downloader';
import { eventClient, employeeClient, uploadClient, iaClient } from '../../../../../apollo';
import '../../../EmployeeManagement.scss';
import { Sidebar } from 'primereact/sidebar';
import WorklowSidebarContent from '../../../../../components/WorklowSidebarContent/WorklowSidebarContent';
import { getFormTypeAndRecordId, InfoMessage } from '../../../../../services/commonService';
import EmployeeQualifications from './EmployeeQualifications';
import Address from '../../../../../components/IAAddress/Address';
import { ApprovedRecordApproveCheckVariable, ApprovedRecordEntityCheckVariable } from '../../../../../commons/CommonVariables';
import { isValidCUID } from '../../../../../util';
import { GetFinYear, GetFinYearOnRef } from '../../../../../commons/commons';

const CreateEmployee = (props) => {
	const projectURL = window.INTERNAL_AUDIT ? 'employee' : 'empdetails';
	const [growl, setGrowl] = useState();
	const [errorStatus, setErrorStatus] = useState(false);
	const [state, setState] = useState({
		activeAccordion: [
			'Employee_Classification',
			'Employee_Information',
			'Employee_Specific_Information',
			'Employee_Qualificiations',
			'Employee_Residential_Address_Details'
		],
		loading: true,
		empGroupList: [],
		showPaySlip: false,
		showWorkflowStatus: false,
		listToShowComment: []
	});

	let filesList = ['PNG', 'JPG', 'JPEG', 'TIFF', 'GIF', 'PJP', 'JFIF', 'BMP'];
	const APP_CONFIGURATION_VALUES = JSON.parse(localStorage.getItem('APP_CONFIGURATION_VALUES'));
	const nationality =
		APP_CONFIGURATION_VALUES?.filter((configData) => configData.code === 'COUNTRY')[0].value1.charAt(0) +
		APP_CONFIGURATION_VALUES?.filter((configData) => configData.code === 'COUNTRY')[0]
			.value1.slice(1)
			.toLowerCase();

	const [formState, setFormState] = useState({
		employeeID: '',
		empGroup: { id: 'INTERNAL', displayLabel: 'Internal' },
		empPhoto: '',
		EMPLOYEE_CLASSIFICATION: {
			dynamicFields: [],
			MDA: '',
			deputation_office: '',
			office_location: '',
			daily_rate: '',
			effective_from: '',
			showEffectiveDate: false
		},
		EMPLOYEE_INFORMATION: {
			emp_first_name: '',
			emp_last_name: '',
			dob: '',
			gender: '',
			emp_fathers_name: '',
			emp_mother_name: '',
			nationality: '',
			TPIN: '',
			national_id_Card: '',
			aadhar_ref_no: '',
			mobile_no: '',
			email_id: '',
			office_phone_no: '',
			isDisabled: false,
			disability: '',
			percentOfDisability: ''
		},
		EMPLOYEE_SPECIFIC_INFO: { rows: [], overAllExp: '' },
		Qualificiations: [],
		address_list: []
	});
	const formTypeAndId = getFormTypeAndRecordId(props.location.pathname);
	let formType = formTypeAndId['formType'];
	let recordID = formTypeAndId['recordID'];
	let isDraft = formTypeAndId['isDraft'];
	let isApproved = formTypeAndId['isApproved'];
	let transactionId = localStorage.getItem('transaction_id');
	const [mode, setMode] = useState(formType);
	let reference = localStorage.getItem('Reference');
	let fiVal = mode === 'create' ? GetFinYear() : GetFinYearOnRef(reference);
	const [empDetails, setEmpDetails] = useState();
	const [versionNo, setversionNo] = useState();
	const [versionUser, setversionUser] = useState();
	const [showUpdateInfo, setUpdateInfo] = useState('');
	const [entityId, setEntityId] = useState('');
	const [recID, setID] = useState('');
	const [showActions, setShowActions] = useState(isApproved && formType === 'view' ? false : true);
	const [approvedRecord, setApprovedRecord] = useState(false);
	const [approveVersionNo, setApproveVersionNo] = useState(0);
	const [employeeCount, setEmployeeCount] = useState(0);

	useEffect(() => {
		if (fiVal) getIAEmpSysadminData();
	}, [fiVal]);

	let [getIAEmpSysadminData, { data: sysAdminData }] = useLazyQuery(Schema.iaEmpSysadminData, {
		// get api for deputation offices & countries
		variables: { fin_year: fiVal },
		fetchPolicy: 'no-cache',
		onCompleted: (response) => {
			if (response) {
				getClassifDetails();
				return;
			}
			setState({ ...state, loading: false });
		},
		onError: () => {
			setState({ ...state, loading: false });
		}
	});

	let { data: skillsList, loading: skillsLoader } = useQuery(Schema.auditHierarchyCodes, {
		// get api for skills list
		variables: { where: { audit_level_id: { equals: 'Subject' } }, transations: { none: { is_active: { equals: false } } } },
		fetchPolicy: 'no-cache',
		client: iaClient,
		onError: () => {
			setState({ ...state, loading: false });
		}
	});

	const prepareStructureData = (level, arr) => {
		// prepare employee structure dynamic keys in classifictaion accordion
		if (level?.id) {
			let key = level?.name.replace(/ /g, '_');
			arr.push({ name: level?.name, id: level?.id, key: key, fieldOptions: [], value: {}, isDisabled: true });
		}
		if (level?.child_level) {
			prepareStructureData(level?.child_level, arr);
		}
		return arr;
	};

	const [getClassifDetails, { data: classificationsData }] = useLazyQuery(Schema.employeeClassifLevelsAndCode, {
		// get api for employee groups and classification structure
		fetchPolicy: 'no-cache',
		client: employeeClient,
		onCompleted: (response) => {
			if (response?.employeeClassifLevels) {
				let dynamicFields = prepareStructureData(response.employeeClassifLevels[0], []);
				if (!recordID) {
					setState({ ...state, loading: false });
				}
				setFormState({ ...formState, EMPLOYEE_CLASSIFICATION: { dynamicFields: dynamicFields } });
				if (response?.employeeClassifCodes?.length > 0) setClassifOptions(response?.employeeClassifCodes, dynamicFields);

				if (recordID && isDraft) {
					getEntityRecord();
				} else if (recordID) {
					getEmployeeByID();
				}
				return;
			}
			setState({ ...state, loading: false });
		},
		onError: () => {
			setState({ ...state, loading: false });
		}
	});

	const [checkEmployeeCount] = useLazyQuery(Schema.employeeCount, {
		fetchPolicy: 'no-cache',
		client: employeeClient,
		onCompleted: (resp) => {
			setEmployeeCount(resp.employeeCount);
			setState({ ...state, loading: false });
		},
		onError: () => {
			setState({ ...state, loading: false });
		}
	});

	const setClassifOptions = (ev, dynF) => {
		// prepare dynamic fields with group selected and hit pay entitlement
		if (ev?.length > 0) {
			dynF.forEach((dyn, index) => {
				dyn['value'] = {};
				if (index === 0) {
					dyn['fieldOptions'] = ev || [];
					dyn['isDisabled'] = false;
				} else {
					dyn['fieldOptions'] = [];
					dyn['isDisabled'] = true;
				}
			});
		}
	};

	function getParent(root, id, parent) {
		var node;

		root.some(function (n) {
			if (n.id === id) {
				return (node = parent);
			}
			if (n.child_codes) {
				return (node = getParent(n.child_codes, id, n));
			}
			return n;
		});
		return node || null;
	}

	const prepareClassifDetails = (list) => {
		let arr = isDraft ? list.create : list;
		if (formState.EMPLOYEE_CLASSIFICATION.dynamicFields?.length > 0) {
			formState.EMPLOYEE_CLASSIFICATION.dynamicFields.forEach((dyn, index) => {
				let val = arr.find((li) => li.classif_id === dyn.id);
				if (index === 0) {
					dyn['fieldOptions'] = classificationsData?.employeeClassifCodes;
					dyn['value'] = dyn['fieldOptions']?.find((va) => va.id === val?.classif_code) || {};
					dyn['isDisabled'] = false;
				} else {
					let options = getParent(
						classificationsData?.employeeClassifCodes,
						val?.classif_code,
						classificationsData?.employeeClassifCodes
					);
					dyn['fieldOptions'] = options?.child_codes || [];
					dyn['value'] = dyn['fieldOptions'].find((va) => va.id === val?.classif_code) || {};
					dyn['isDisabled'] = false;
				}
			});
		}
		return formState.EMPLOYEE_CLASSIFICATION.dynamicFields;
	};

	const getQualificationsData = (rows) => {
		let list = [];
		list = rows.map((qual) => {
			let accObj = {
				qualification: qual.q1ualification,
				university: qual.university,
				certificate_number: qual.cert_number,
				year_passing: qual.year_of_passing,
				grade: qual.grade,
				percentage: qual.percentage,
				id: qual.id
			};
			return accObj;
		});
		return list;
	};
	const getSkillsData = (rows) => {
		let list = [];
		list =
			rows?.length > 0 &&
			rows.map((sk) => {
				let skillObj = skillsList?.auditHierarchyCodes?.find((skillOb) => skillOb.id === sk.skill_desc) || '';
				let accObj = {
					id: sk.id,
					experience: sk.total_experience,
					proficiency: sk.proficiency,
					skills: skillObj
				};
				return accObj;
			});
		return list;
	};

	const getAddresses = (list) => {
		let rows = [];
		rows = list.map((address) => {
			let obj = isDraft ? { ...address, ...address.address?.create } : { ...address, ...address.address };
			let contry = sysAdminData?.countries?.find((a) => a.name === obj.country);
			let states = contry?.states.find((a) => a.id === obj.state);
			let districts = states?.locations?.find((a) => a.id === obj.district);
			obj['country_name'] = contry?.name;
			obj['state_name'] = states?.name || obj.state;
			obj['district_name'] = districts?.name || obj.district;
			obj['tier'] = sysAdminData?.tiers?.find((a) => a.id === obj.tier_id) || '';
			obj['recordID'] = address?.id
			return obj;
		});
		return rows;
	};

	const prepareData = (response) => {
		// to set data to page in edit or view mode
		if (response.id) setID(response.id);
		setversionNo(response?.version_no);
		setversionUser(response?.version_user);
		setEmpDetails(response);
		response.first_name = response?.first_name?.trim();

		let genInfo = isDraft ? response.general_info?.create : response.general_info;
		let ofcLoc = sysAdminData?.countries.find((co) => co.id === genInfo?.office_location);
		let mObj = sysAdminData.departmentWithDeputationOffices.find((co) => co?.department?.segment_full_code === response.mda);
		let mdaObj = '';
		let deptObj = '';
		if (mObj) {
			mdaObj = {
				id: mObj?.department?.id,
				name: mObj?.department?.segment_name,
				fullCode: mObj?.department?.segment_full_code,
				displayLabel: mObj?.department?.segment_full_code + ' - ' + mObj?.department?.segment_name,
				deputaionOfcsList: mObj.deputation_office
			};
			let deptOfc = mdaObj.deputaionOfcsList.find((de) => de.segment_full_code === response.deputation_office) || '';
			if (deptOfc) {
				deptObj = {
					id: deptOfc.id,
					name: deptOfc.segment_name,
					fullCode: deptOfc.segment_full_code,
					displayLabel: deptOfc.segment_full_code + ' - ' + deptOfc.segment_name
				};
			}
		}
		let specInfo = isDraft ? response.special_info?.create?.skills?.create : response.special_info?.skills;
		let specialInfo = isDraft ? response.special_info?.create : response.special_info;
		let empQualifications = isDraft ? response.qualifications?.create : response?.qualifications;
		let addressList = isDraft ? response?.addresses?.create : response?.addresses;
		// let paysList = isDraft ? response?.pay_components?.create : response?.pay_components;

		let dataObj = {
			employeeID: response.ref,
			empGroup: { id: 'INTERNAL', displayLabel: 'Internal' },
			empPhoto: response.img_url,
			EMPLOYEE_CLASSIFICATION: {
				deputation_office: deptObj,
				dynamicFields: prepareClassifDetails(response.classif_details),
				MDA: mdaObj,
				office_location: ofcLoc,
				daily_rate: genInfo?.daily_rate,
				effective_from: response?.effective_from,
				showEffectiveDate:
					response.effective_from === '' || response.effective_from === null || response.effective_from === undefined
						? false
						: true
			},
			EMPLOYEE_INFORMATION: {
				emp_first_name: response.first_name,
				emp_last_name: response.last_name,
				dob: genInfo?.dob ? new Date(genInfo?.dob) : '',
				gender: response.gender,
				emp_fathers_name: genInfo?.father_name,
				emp_mother_name: genInfo?.mother_name,
				nationality: genInfo?.nationality ? genInfo?.nationality : nationality,
				TPIN: genInfo?.pan_num,
				national_id_Card: genInfo?.aadhar,
				aadhar_ref_no: genInfo?.first_name,
				mobile_no: genInfo?.mobile,
				email_id: response.email,
				office_phone_no: genInfo?.other_mobile,
				isDisabled: genInfo?.is_disabled,
				disability: genInfo?.disability_type,
				percentOfDisability: genInfo?.disability_percent?.toString()
			},
			EMPLOYEE_SPECIFIC_INFO: { rows: getSkillsData(specInfo), overAllExp: specialInfo?.overall_experience }
		};
		if (empQualifications?.length > 0) {
			dataObj.Qualificiations = getQualificationsData(empQualifications);
		}
		if (addressList?.length > 0) {
			dataObj.address_list = getAddresses(addressList);
		}

		setFormState({ ...formState, ...dataObj });
		setState({ ...state, loading: false });
	};

	const [getEmployeeByID] = useLazyQuery(Schema.employee, {
		// get api for emplolyee data by ID
		fetchPolicy: 'no-cache',
		client: employeeClient,
		variables: { where: { id: recordID } },
		onCompleted: (response) => {
			if (response?.employee) {
				checkEmployeeCount({ variables: ApprovedRecordApproveCheckVariable(response.employee) });
				setApprovedRecord(!isValidCUID(response?.employee?.ref));
				prepareData(response.employee);
				return;
			}
			setState({ ...state, loading: false });
		},
		onError: () => {
			setState({ ...state, loading: false });
		}
	});
	const [getEntityRecord] = useLazyQuery(Schema.entityRecord, {
		// get api for emplolyee data by ID in Draft mode
		client: eventClient,
		fetchPolicy: 'no-cache',
		variables: { where: { id: recordID } },
		onCompleted: (data) => {
			if (data?.entityRecord?.record) {
				setApproveVersionNo(data?.entityRecord?.record?.approved_version_no);
				setEntityId(data?.entityRecord?.record?.entity_id);
				prepareData(data.entityRecord.record);
				if (isApproved || data?.entityRecord?.record?.approved_version_no > 0 || approvedRecord) {
					data.entityRecord.record.version_no = data.entityRecord?.record?.approved_version_no;
					checkEmployeeCount({ variables: ApprovedRecordEntityCheckVariable(data?.entityRecord?.record) });
				}
				return;
			}
			setState({ ...state, loading: false });
		},
		onError: () => {
			setState({ ...state, loading: false });
		}
	});

	const handleAccordionChange = (e) => {
		// accordion close or open
		if (mode === 'view') {
			setState({
				...state,
				activeAccordion: [
					'Employee_Classification',
					'Employee_Information',
					'Employee_Specific_Information',
					'Employee_Qualificiations',
					'Employee_Residential_Address_Details'
				]
			});
		} else {
			let val = state.activeAccordion.find((a) => a === e);
			if (val)
				state.activeAccordion = state.activeAccordion.filter((a) => {
					return a !== e;
				});
			else state.activeAccordion.push(e);
			setState({ ...state, activeAccordion: state.activeAccordion });
		}
	};

	const handleLoader = (status) => {
		if (status) {
			setState({ ...state, activeAccordion: [], loading: true });
			return;
		}
		setState({ ...state, loading: status });
	};

	const handleCancel = () => {
		setState({ ...state, loading: false });
		props.history.goBack();
	};

	const showGrowl = (msg, type) => {
		growl.show({ life: 6000, severity: type, summary: msg });
		setTimeout(() => {
			if (type !== 'error') handleCancel();
		}, 1000);
	};

	const navigateToEdit = () => {
		if (isApproved) setShowActions(true);
		setMode('edit');
		setState({ ...state, activeAccordion: [] });
	};

	const checkValidations = () => { };

	const saveFormsData = (type, data) => {
		let obj = JSON.parse(JSON.stringify(formState));
		obj[type] = data;

		let val = checkValidations(type, data);
		setUpdateInfo(val);
		setFormState({
			...formState,
			[type]: data
		});
	};

	const [fileUploader, setFileUploader] = useState('');

	const [uploadImage] = useMutation(Schema.singleUpload, {
		client: uploadClient,
		onCompleted: (response) => {
			if (response?.singleUpload?.id) {
				setState({ ...state, loading: false });
				setFormState({ ...formState, empPhoto: prepareURL(response.singleUpload.id) });
			}
		}
	});
	const handleUploadImage = () => {
		fileUploader.click();
	};

	const handleImageUpload = (ev) => {
		ev.stopPropagation();
		ev.preventDefault();
		const file = ev.target.files[0];
		let fileNameArr = file?.name?.split('.');
		let fileExt = fileNameArr[fileNameArr?.length - 1].toUpperCase();

		let arr = filesList.filter((item) => item === fileExt);

		if (arr.length > 0) {
			uploadImage({ variables: { file: file } });
			setState({ ...state, loading: true });
		} else {
			let msg = window.COMMONS.FileNotSupported;
			growl.show({
				life: 6000,
				severity: 'error',
				summary: msg
			});
		}
	};

	/*
	 * const navigateToPaySlips = () => {
	 *     setState({ ...state, showPaySlip: true });
	 * };
	 */

	const showWorkflow = (flag) => {
		setState({ ...state, showWorkflowStatus: flag });
	};
	const handleCommentClick = (selRow, flag) => {
		const lstToShowComment = state.listToShowComment;
		lstToShowComment[selRow.id] = flag;
		setState({ ...state, listToShowComment: lstToShowComment });
	};

	const setActionsStatus = (hasActions) => {
		setState({ ...state, hasActions });
	};

	const checkIsApproved = () => {
		return isApproved || approveVersionNo > 0 || approvedRecord;
	};

	return (
		<div className={`page-body pos-rel employee-details ${state.loading || skillsLoader ? 'mask' : ''}`}>
			{(state.loading || skillsLoader) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
			<Toast ref={(el) => setGrowl(el)} />
			{!state.showPaySlip ? (
				<>
					<div className="page-header">
						{!state.loading && !skillsLoader && (
							<span className="light-color">
								{recordID
									? transactionId && transactionId !== 'undefined'
										? `Transaction Number : ${transactionId}`
										: `${empDetails?.first_name} ${empDetails?.last_name} - ${empDetails?.ref}`
									: 'Register an Employee'}
							</span>
						)}

						{!state.loading && !skillsLoader && (
							<span className="backBtn pointer">
								{/* {recordID && empDetails?.is_latest && (
                                    <img
                                        className="header-icon"
                                        title="Pay Slips"
                                        src={process.env.PUBLIC_URL + '/assets/icons/Payment_Details.svg'}
                                        onClick={() => navigateToPaySlips()}
                                        alt={'payment-icon'}
                                    ></img>
                                )} */}
								{recordID && versionNo !== 0 && (
									<img
										alt=""
										className="header-icon"
										title="Workflow Stages"
										src={process.env.PUBLIC_URL + '/assets/icons/workflow.svg'}
										onClick={() => showWorkflow(true)}
									></img>
								)}
								<span
									onClick={() => {
										localStorage.setItem('transaction_id', '');
										props.history.goBack();
									}}
								>
									<span className="appkiticon icon-left-chevron-outline icon_size"></span>Back
								</span>
							</span>
						)}
					</div>
					<div
						className={`${state.hasActions || mode === 'create' || checkIsApproved()
								? 'page-content-ht-with-btns'
								: 'page-content-ht-without-btns'
							}`}
					>
						<Sidebar
							visible={state.showWorkflowStatus}
							onHide={() => showWorkflow(false)}
							position="right"
							dismissable={true}
							closeOnEscape={false}
							showCloseIcon={false}
						>
							<WorklowSidebarContent
								sourceId={recordID}
								listToShowComment={state.listToShowComment}
								showWorkflow={showWorkflow}
								handleCommentClick={handleCommentClick}
							/>
						</Sidebar>
						{checkIsApproved() &&
							mode === 'edit' &&
							InfoMessage(window.EDIT_OF_APPROVAL_ADVISORY_MESSAGES.EmployeeRegistration)}
						{employeeCount > 0 && mode === 'view' && InfoMessage(window.EDIT_OF_APPROVAL_MESSAGES.ApprovedMessageForView)}
						{state.hasActions && showUpdateInfo && (
							<div className="display-flex m-b-20">
								<img src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
								<span className="m-l-5 icon-primary font-15 info-message p_5"> {showUpdateInfo}</span>
							</div>
						)}
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 m-b-10">
								{recordID !== '' && empDetails?.status === 'Approved' && (
									<div className="row col-12 m-b-10">
										<b className="w-30 fs-14">
											Employee ID{mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
										</b>
										{formState.employeeID}
									</div>
								)}
								<div className="row col-12 m-b-40">
									<b className="w-30 fs-14">Employee Group</b>
									{mode !== 'view' && !recordID && formState.empGroup?.displayLabel}
									{(mode === 'view' || recordID) && formState.empGroup?.displayLabel}
								</div>
							</div>
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 m-b-10">
								<div className="pic-upload">
									{formState.empPhoto && <img className="pic-img" src={formState.empPhoto} alt="Employee" />}
									<input
										type="file"
										id="file"
										ref={(file) => {
											setFileUploader(file);
										}}
										accept="image/*"
										hidden
										onChange={(ev) => handleImageUpload(ev)}
										onClick={(ev) => (ev.target.value = null)}
									/>
									{mode !== 'view' && (
										<div className={`pos-abs ${formState.empPhoto ? 'visible-hover' : ''}`}>
											<span
												className="appkiticon a-font-32 icon-primary icon-camera-fill pointer"
												onClick={() => handleUploadImage()}
											/>
											<div className="m-t-10 fs-12 pointer" onClick={() => handleUploadImage()}>
												Upload Photo
											</div>
										</div>
									)}
								</div>
							</div>
						</div>

						{formState.empGroup && (!state.loading || !skillsLoader) && (
							<div>
								<Accordions multiOpen={true} activeItem={state.activeAccordion} onChange={(e) => handleAccordionChange(e)}>
									<AccordionItem itemId="Employee_Classification" className="accordian-item m-b-10 code-acc">
										<AccordionItemHeader title={'Employee Classification'} />
										<AccordionItemBody>
											<EmployeeClassification
												sysAdminData={sysAdminData}
												saveFormData={saveFormsData}
												formStateObj={formState}
												growl={growl}
												formType={mode}
												handleLoader={() => setState({ ...state, loading: true })}
												updateErrorStatus={(status) => setErrorStatus(status)}
												checkIsApproved={checkIsApproved()}
											/>
										</AccordionItemBody>
									</AccordionItem>
									<AccordionItem itemId="Employee_Information" className="accordian-item m-b-10 code-acc">
										<AccordionItemHeader title={'Employee Information'} />
										<AccordionItemBody>
											<EmployeeInformation
												empDetails={empDetails}
												saveFormData={saveFormsData}
												formStateObj={formState}
												formType={mode}
												nationList={sysAdminData}
												INTERNAL_EMAIL_DOMAINS={
													APP_CONFIGURATION_VALUES &&
													APP_CONFIGURATION_VALUES.filter((obj) => {
														return obj.code === 'INTERNAL_EMAIL_DOMAINS';
													})
												}
												updateErrorStatus={(status) => setErrorStatus(status)}
											/>
										</AccordionItemBody>
									</AccordionItem>
									<AccordionItem itemId="Employee_Specific_Information" className="accordian-item m-b-10 code-acc">
										<AccordionItemHeader title={'Employee Specific Information'} />
										<AccordionItemBody>
											<EmployeeSpecificInfo
												saveFormData={saveFormsData}
												skillsList={skillsList?.auditHierarchyCodes}
												formType={mode}
												formStateObj={formState}
												updateErrorStatus={(status) => setErrorStatus(status)}
											/>
										</AccordionItemBody>
									</AccordionItem>
									<AccordionItem itemId="Employee_Qualificiations" className="accordian-item m-b-10 code-acc">
										<AccordionItemHeader title={'Qualifications'} />
										<AccordionItemBody>
											<EmployeeQualifications
												saveFormData={saveFormsData}
												formType={mode}
												formStateObj={formState}
												updateErrorStatus={(status) => setErrorStatus(status)}
											/>
										</AccordionItemBody>
									</AccordionItem>
									<AccordionItem itemId="Employee_Residential_Address_Details" className="accordian-item m-b-10 code-acc">
										<AccordionItemHeader title={'Employee Residential Address Details'} />
										<AccordionItemBody>
											<Address
												countries={sysAdminData?.countries}
												country={
													APP_CONFIGURATION_VALUES?.filter((configData) => configData.code === 'COUNTRY')[0]
														.value1
												}
												addressData={formState.address_list}
												formType={mode}
												updateData={saveFormsData}
												tiers={sysAdminData?.tiers}
											/>
										</AccordionItemBody>
									</AccordionItem>
								</Accordions>
							</div>
						)}
					</div>

					<EmployeeActions
						formState={formState}
						handleLoader={handleLoader}
						handleCancel={handleCancel}
						showGrowl={showGrowl}
						formType={mode}
						setActionsStatus={setActionsStatus}
						recordID={recordID}
						navigateToEdit={navigateToEdit}
						recID={recID}
						entity_id={entityId}
						version_no={versionNo}
						version_user={versionUser}
						errorStatus={errorStatus}
						showUpdateInfo={showUpdateInfo}
						location={props.location}
						isDraft={isDraft}
						showActions={showActions}
						isApproved={isApproved}
						approveVersionNo={approveVersionNo}
						employeeRef={empDetails?.ref}
						checkIsApproved={checkIsApproved()}
						employeeCount={employeeCount}
						projectURL={projectURL}
					/>
				</>
			) : (
				''
			)}
		</div>
	);
};

export default CreateEmployee;
