import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/IsAuthorized';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';

const CalendarBlocking = lazy(() => import('../pages/internalAudit/masters/CalendarBlocking/CalendarBlocking'));
const HRPlan = lazy(() => import('../pages/internalAudit/masters/CalendarBlocking/AuditorHrPlan/AddHrPlan'));

const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const calendarBlockingItems = MenuItemsSchema.CALENDAR_BLOCKING;
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const globalMastersBreadCrmb = (props) => {
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/globalmaster/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    return obj;
};
const CalendarBlokingRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={globalMastersBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={CalendarBlocking}
                    path={`${props?.path}/calendarblocking`}
                    name="Calendar Blocking"
                    menuItems={calendarBlockingItems}
                />
                <AuthGaurd
                    {...props}
                    component={HRPlan}
                    exact
                    path={`${props?.path}/auditorhrplan`}
                    name="Auditor HR Plan"
                    menuItems={calendarBlockingItems}
                />
            </Switch>
        </div>
    );
};
export default CalendarBlokingRouting;
