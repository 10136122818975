import React, { useState } from 'react';
import { eventClient } from '../../apollo';
import { useQuery } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import FDataTable from '../fDataTable/FDataTable';
import TableSchema from '../../commons/TableSchema';
import { getDate } from '../../util';
import AuditInstanceComponent from './AuditInstanceComponent';
import AuditDetailsComponent from './AuditDetailsComponent';
import { ProgressSpinner } from 'primereact/progressspinner';

const AuditComponent = (props) => {
	const [isloading, setLoading] = useState(true);
	const [rows, setRows] = useState([]);
	const [instance, setInstance] = useState({});
	const [eventData, setEventData] = useState();

	useQuery(Schema.fetchWorkflowInstances, {
		client: eventClient,
		variables: { source_ref: props.reference_value },
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			setLoading(false);
			if (data.workflowInstances?.length > 0) {
				data.workflowInstances.forEach((res, index) => {
					res.version_date_local = getDate(res.version_date);
					if (index === 0) res.version_no = 'Original';
					else res.version_no = 'Amendment' + ' ' + index;
				});
				setRows(data.workflowInstances);
			}
		},
		onError: () => {
			setLoading(false);
		}
	});

	const onClickAudit = (rowData) => {
		setInstance(rowData);
	};

	const setEvent = (eventdata) => {
		setEventData(eventdata);
	};

	return (
		<div className={`${!props.tabs && 'page-body'}  ${isloading ? 'mask' : ''}`}>
			{isloading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
			<div className="page-header">
				<span className="light-color f-s-l">
					Audit Log for {props.refence_label} : {props.transaction_Id ? props.transaction_Id : props.reference_value}
					{instance.id && ' / ' + instance.version_no}
					{eventData?.id && ' / Event at : ' + getDate(eventData.version_date)}
				</span>
				{!instance.id && (
					<span className="backBtn pointer" onClick={() => props.goBack()}>
						<span className="appkiticon icon-left-chevron-outline icon_size" />
						Back
					</span>
				)}
				{instance?.id && !eventData?.id && (
					<span className="backBtn pointer" onClick={() => setInstance({})}>
						<span className="appkiticon icon-left-chevron-outline icon_size" />
						Back
					</span>
				)}
				{eventData?.id && (
					<span className="backBtn pointer" onClick={() => setEventData({})}>
						<span className="appkiticon icon-left-chevron-outline icon_size" />
						Back
					</span>
				)}
			</div>
			<div className={props.tabs ? 'page-content-ht-with-tab-btns' : 'coa-card-content'}>
				{!instance.id && (
					<FDataTable
						rows={rows}
						columns={TableSchema.AUDIT_LOG}
						className="primary-table"
						emptyMessage="No record found"
						noPaginator={true}
						onClickAudit={onClickAudit}
						scrollable={true}
					/>
				)}
				{instance?.id && !eventData?.id && (
					<AuditInstanceComponent wf_instance_id={instance.id} sourceData={props.sourceData} setEvent={setEvent} />
				)}
				{eventData?.id && <AuditDetailsComponent auditInstance={eventData} sourceData={props.sourceData} />}
			</div>
		</div>
	);
};

export default AuditComponent;
