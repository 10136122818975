import React, { useState } from 'react';
import Schema from '../../../schema/AppSchema';
import TopButtonComponent from '../../../components/topComponents/TopButtonComponent';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useQuery, useMutation } from '@apollo/client';
import FDataTable from '../../../components/fDataTable/FDataTable';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import { Toast } from 'primereact/toast';
import { revenueClient, eventClient } from '../../../apollo';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';
import AuditComponent from '../../../components/auditLog/AuditComponent';
import { useHistory, Link } from 'react-router-dom';
import TopCardComponent from '../../../components/topComponents/TopCardComponent';
import TopCardComponentItems from '../../../components/topComponents/TopCardComponentItems';
import IsAuthorized from '../../../commons/IsAuthorized';
import { getDate, getMonth, getDateVal, convertTextCase } from '../../../util';
import CoMasTableSchema from '../CoMasTableSchema';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import RecallButton from '../../../commons/RecallButton';

const COASegLevMapping = (props) => {
    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.REVENUE_MNGMNT.find((a) => a.routerLink === loc[1]);
    let tableColumns = JSON.parse(JSON.stringify(CoMasTableSchema.REMITANCE_PURPOSE_MAPPING));
    const assignedTo = {
        header: 'Assigned To',
        field: 'assignedTo',
        filter: true,
        sortable: true,
        filterMatchMode: 'contains',
        filterPlaceholder: 'Search By Assigned To',
        width: '100px',
        fieldType: 'tooltip'
    };

    const [state, setState] = useState({
        loading: true,
        customizedRows: [],
        selectedData: {},
        showModal: false,
        cards: TopCardComponentItems.CONFIG_RPM,
        columns: tableColumns,
        commentRequired: false,
        showConfirmModal: false,
        recallSelectedData: {}
    });
    let history = useHistory();
    let variable = { is_latest: { equals: true } };
    const [variables, setVariables] = useState(variable);
    const [recallObj, setRecallObj] = useState();
    const [growl, setGrowl] = useState();
    const [selected, setSelected] = useState({});
    const [reference, setReference] = useState({});
    const [showAudit, setShowAudit] = useState(false);
    const [recallLoader, setRecallLoader] = useState(false);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [pageRows, setPageRows] = useState(10);
    const [searchFieldKey, setSearchFieldKey] = useState({});
    const [sortField, setSortField] = useState('version_date');
    const [sortOrder, setSortOrder] = useState('asc');
    const [where, setWhere] = useState(
        props?.whereQry ? { ...props.whereQry, ...{ is_latest: { equals: true } } } : { is_latest: { equals: true } }
    );
    const [tableLoader, setTableLoader] = useState(false);

    const { refetch: refetchRowsCount } = useQuery(Schema.remitCodeDraftCount, {
        fetchPolicy: 'no-cache',
        client: revenueClient,
        variables: { where: variables },
        onCompleted: (response) => {
            if (response) setTotalRecords(response.remitCodeDraftCount);
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    let variableData = { where: variables, orderBy: { [sortField]: sortOrder } };
    if (!selected.label || selected.label === 'Total Remittance Purpose Mappings') {
        variableData.skip = skip;
        variableData.take = take;
    }
    const { refetch: refetchRows, error } = useQuery(Schema.remitCodeDrafts, {
        fetchPolicy: 'no-cache',
        client: revenueClient,
        variables: variableData,
        onCompleted: (response) => {
            if (response) prepareRowsCols(response);
        },
        onError: () => {
            setState({ ...state, loading: false });
            setTableLoader(false);
        }
    });
    const prepareRowsCols = (resp) => {
        for (let index = 0; index < resp.remitCodeDrafts.length; index++) {
            let i = resp.remitCodeDrafts[index];
            if (
                selected.ids?.length > 0 &&
                selected.label !== 'Total Remittance Purpose Mappings' &&
                selected.status_obj &&
                selected.status_obj[i.id]
            ) {
                let record = selected?.status_obj[i.id]?.record;
                i.status = selected.status_obj[i.id].status;
                i.txnId = selected.status_obj[i.id].txnId;
                i.assignedTo = convertTextCase(selected.status_obj[i.id].assignedTo, false) || '';
                prepareRecord(record, resp, i, index);
            }
            i.version_date_local = getDate(i.version_date);
        }
        if (selected.new_objs?.length > 0 && selected.label === 'Pending With Self') {
            for (let index = 0; index < selected.new_objs.length; index++) {
                const element = selected.new_objs[index];
                element.version_date_local = getDate(element.version_date);
                resp.remitCodeDrafts.push(element);
            }
        }
        let arr = resp.remitCodeDrafts.map((a) => a.status).filter((item, i, ar) => ar.indexOf(item) === i);
        state.columns.forEach((a) => {
            if (a.field === 'status') {
                a.filterOptions = arr;
            }
        });
        setState({
            ...state,
            customizedRows: resp.remitCodeDrafts,
            columns: state.columns,
            loading: false,
            showModal: false
        });
        setTableLoader(false);
    };

    const prepareRecord = (record, resp, i, index) => {
        if (record) {
            record.version_date_local = getDate(record.version_date);
            record.status = selected.status_obj[i.id].status;
            resp.remitCodeDrafts[index] = record;
        }
        return resp;
    };

    const onClickEdit = (rowData) => {
        localStorage.setItem('transaction_id', rowData.txnId);
        if (rowData.entity_id) {
            history.push('/rm/rpm/edit/' + rowData['entity_id'] + '/draft');
            return;
        }
        history.push(`/rm/rpm/edit/${rowData.id}`);
    };

    const handleCancel = () => {
        setState({ ...state, showModal: false, selectedData: {} });
    };
    const handleDelete = () => {
        let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
        setState({ ...state, showModal: false });
        if (state.selectedData.entity_id) {
            deleteWorkFlowAction({ variables: { where: { id: state.selectedData.entity_id } } });
            return;
        }
        deletetePaymentInstruction({ variables: { where: { id: state.customizedRows[index].id } } });
        setTimeout(() => {
            refetchRows().then((res) => {
                if (res?.data) {
                    prepareRowsCols(res.data);
                }
            });
        }, 1000);
    };

    const [deletetePaymentInstruction, { loading: dLoader }] = useMutation(Schema.deleteOneRemitCodeDraft, {
        client: revenueClient,
        onCompleted: (data) => {
            if (data) {
                let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
                state.customizedRows.splice(index, 1);
                setState({ ...state, loading: false, selectedData: {}, customizedRows: state.customizedRows });
                growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.SYSTEM_ADMINISTRATION_MASTERS.DeleteRemittancePurposeMapping
                });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [deleteWorkFlowAction, { loading: delLoader }] = useMutation(Schema.deleteOneEntityRecord, {
        client: eventClient,
        onCompleted: (data) => {
            if (data) {
                let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
                state.customizedRows.splice(index, 1);
                setState({ ...state, loading: false, selectedData: {}, customizedRows: state.customizedRows });
                growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.SYSTEM_ADMINISTRATION_MASTERS.DeleteRemittancePurposeMapping
                });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const onClickDelete = (rowData) => {
        setState({ ...state, selectedData: rowData, showModal: true });
    };

    const onClickAudit = (rowData) => {
        setReference(rowData);
        setShowAudit(true);
    };

    const goBack = () => {
        setShowAudit(false);
    };

    const navigateTo = (query, selectedCard, columns) => {
        let tWhere = JSON.parse(JSON.stringify(where));
        setWhere(query);
        if (selectedCard.label === 'Pending For Verification/Approval') {
            setRecallObj(selectedCard.canRecall);
            columns.splice(columns.length - 1, 0, assignedTo);
        }
        setState({ ...state, columns: columns, loading: true });
        setSelected(selectedCard);
        setSkip(0);
        setTake(10);
        setPageRows(10);
        setSearchFieldKey({});
        setSortField('version_date');
        setSortOrder('asc');
        setVariables(query);
        setTableLoader(true);
        if (query?.id?.in.toString() === tWhere?.id?.in.toString()) {
            refetchRows({
                variables: { where: query }
            }).then((response) => {
                if (response) prepareRowsCols(response.data);
            });
        }

    };

    const actionTemplate = (rowData) => {
        const showRecallBtn = recallObj && recallObj[rowData.id];
        return (
            <>
                {selected.label === 'Pending With Self' && (
                    <IsAuthorized
                        privRequired={Privileges?.UPDATE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <img
                                className="pointer m-l-5 m-r-5"
                                height="20px"
                                title="Edit"
                                src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                alt="Edit Icon"
                                onClick={() => onClickEdit(rowData)}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status === 'Draft' && selected.label === 'Pending With Self' && (
                    <IsAuthorized
                        privRequired={Privileges?.DELETE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <img
                                className="pointer m-l-5 m-r-5"
                                height="20px"
                                title="Delete"
                                src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                alt="Delete Icon"
                                onClick={() => onClickDelete(rowData)}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status !== 'Draft' && (
                    <img
                        className="pointer m-l-5 m-r-5"
                        height="20px"
                        title="Audit"
                        src={process.env.PUBLIC_URL + '/assets/icons/audit_trail.svg'}
                        alt="Audit Icon"
                        onClick={() => onClickAudit(rowData)}
                    />
                )}
                {showRecallBtn && selected.label === 'Pending For Verification/Approval' && (
                    <RecallButton
                        rowData={rowData}
                        setCustomizedRows={setCustomizedRows}
                        onClickRecall={onClickRecall}
                        source_id={state.recallSelectedData.id}
                        handleCalRecallStatus={handleCalRecallStatus}
                        handleCancelModal={handleCancelModal}
                        handleLoader={handleLoader}
                        commentRequired={state.commentRequired}
                        showConfirmModal={state.showConfirmModal}
                        growl={growl}
                        module={'Revenue Management'}
                        fmis_function={'RVN_REMITTANCE_PURPOSE_CONFIG'}
                    />
                )}
            </>
        );
    };

    const onClickRecall = (rowData) => {
        setState({ ...state, recallSelectedData: rowData });
    };
    const setCustomizedRows = () => {
        let index = state.customizedRows.findIndex((pro) => pro === state.recallSelectedData);
        state.customizedRows.splice(index, 1);
        setState({ ...state, recallSelectedData: {}, showConfirmModal: false, customizedRows: state.customizedRows });
        setRecallLoader(false);
    };
    const handleCalRecallStatus = (flag) => {
        setState({ ...state, showConfirmModal: true, commentRequired: flag });
    };
    const handleCancelModal = () => {
        setState({ ...state, showConfirmModal: false });
    };
    const handleLoader = (flag) => {
        setRecallLoader(flag);
    };

    const onClickView = (rowData) => {
        localStorage.setItem('transaction_id', rowData['txnId']);
        if (rowData.entity_id) {
            history.push('/rm/rpm/view/' + rowData['entity_id'] + '/draft');
            return;
        } else {
            history.push('/rm/rpm/view/' + rowData.id);
        }
    };

    const viewData = (rowData) => {
        return (
            <span className="pointer icon-primary" onClick={() => onClickView(rowData)}>
                <EllipsisWithTooltip placement="auto">{rowData[state.columns[0].field]}</EllipsisWithTooltip>
            </span>
        );
    };

    if (state.columns && state.columns.length) {
        state.columns[0].body = viewData;
        state.columns[state.columns.length - 1].body = actionTemplate;
    }

    const addRemittancePurpose = () => {
        localStorage.setItem('transaction_id', '');
        props.history.push('rpm/create');
    };

    const onPageChange = (event) => {
        setTableLoader(true);
        setSkip(event.first);
        setTake(event.rows);
        setPageRows(event.rows);
        if (!selected.label || selected.label === 'Total Remittance Purpose Mappings') {
            refetchRowsCount();
        }
        refetchRows();
    };

    const searchRecord = (e, column, filterKeys) => {
        setTableLoader(true);
        Object.keys(filterKeys).forEach((filterKey) => {
            if (filterKeys[filterKey].contains === null) {
                delete filterKeys[filterKey];
            }
            return filterKey;
        });
        let keyData = { ...filterKeys };
        setSearchFieldKey(keyData);
        if (filterKeys['version_date_local']) {
            if (filterKeys['version_date_local'].contains) {
                const time = new Date(filterKeys['version_date_local'].contains);
                const date = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T00:00:00.000Z';
                const date2 = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T23:59:59.000Z';
                filterKeys['version_date'] = {
                    gte: date,
                    lte: date2
                };
                delete filterKeys['version_date_local'];
            } else {
                delete filterKeys['version_date_local'];
            }
        }
        setVariables({ ...variable, ...filterKeys });
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
        refetchRowsCount();
        refetchRows();
    };

    const onSort = (event) => {
        setTableLoader(true);
        let srtField = event.sortField;
        if (event.sortField === 'version_date_local') {
            srtField = 'version_date';
        }
        let srtOrder = 'asc';
        if (sortField === srtField) {
            srtOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        }
        setSortOrder(srtOrder);
        setSortField(srtField);
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
    };

    if (error) return `Error! ${error.message}`;

    return (
        <div>
            <Toast ref={(el) => setGrowl(el)} />
            {(state.loading || recallLoader) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            {!showAudit ? (
                <div className={`page-body ${state.loading || dLoader || delLoader || recallLoader ? 'mask' : ''}`}>
                    <div className="page-header">
                        <span className="light-color">Remittance Purpose Mapping</span>
                    </div>
                    <div className="coa-card-content">
                        {!dLoader && !delLoader && !state.loading && !recallLoader && (
                            <TopCardComponent
                                className="thumbnail card-width flex-unset"
                                fetchParent={(query, selectedData, columns) => navigateTo(query, selectedData, columns)}
                                cards={state.cards}
                                selected={selected}
                                fmis_function="RVN_REMITTANCE_PURPOSE_CONFIG"
                                fmis_sub_function="NA"
                                approvedLabel="Total Remittance Purpose Mappings"
                                columns={tableColumns}
                                cardCount={totalRecords}
                            />
                        )}
                        <div>
                            {state.columns?.length <= 0 && !state.loading && !recallLoader && (
                                <div className="m-t-10 display-flex">
                                    <img src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
                                    <span className="m-l-10 icon-primary font-15 info-message p_5">
                                        {error?.graphQLErrors[0]?.message
                                            ? error?.graphQLErrors[0]?.message
                                            : 'Remittance Purpose Mapping are yet to be created.'}
                                    </span>
                                </div>
                            )}
                            {state.showModal && (
                                <DeleteConfirmModal
                                    showModal={state.showModal}
                                    handleCancel={handleCancel}
                                    type={'Remittance Purpose Mapping'}
                                    handleDelete={handleDelete}
                                    showSpinner={true}
                                    name={state?.selectedData?.txnId}
                                />
                            )}
                            <div className="m-t-10">
                                <div className="row flex-end m-r-0 m-b-10">
                                    <span className="liA self-align">
                                        <IsAuthorized
                                            privRequired={Privileges.CREATE}
                                            officeCode={Privileges.OFFICE_CODE}
                                            yes={() => (
                                                <Link className="primary-color p-r-10 fs-14" to="rpm/all">
                                                    View all Approved Codes
                                                </Link>
                                            )}
                                        ></IsAuthorized>
                                    </span>
                                    <TopButtonComponent
                                        topGridProps={{
                                            className: 'p-b-0',
                                            buttonLabel: 'Add New Remittance Purpose',
                                            officeCode: Privileges.OFFICE_CODE,
                                            createPriv: Privileges.CREATE
                                        }}
                                        showPopUp={() => addRemittancePurpose()}
                                    />
                                </div>
                            </div>
                            {!state.loading && state.columns.length > 0 && (
                                <FDataTable
                                    rows={state.customizedRows}
                                    columns={state.columns}
                                    emptyMessage="Remittance Purpose Mapping are yet to be created"
                                    loading={tableLoader}
                                    updatePriv={Privileges.UPDATE}
                                    deletePriv={Privileges.DELETE}
                                    officeCode={Privileges.OFFICE_CODE}
                                    className="primary-table"
                                    count={skip}
                                    totalRecords={totalRecords}
                                    onPageChange={onPageChange}
                                    pageRows={pageRows}
                                    searchRecord={searchRecord}
                                    filterKeys={searchFieldKey}
                                    lazyLoad={!selected.label || selected.label === 'Total Remittance Purpose Mappings' ? true : false}
                                    sortField={sortField}
                                    sortOrder={sortOrder === 'asc' ? 1 : -1}
                                    onSort={onSort}
                                    scrollable
                                />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <AuditComponent
                    refence_label="Remittance Purpose Mapping"
                    goBack={goBack}
                    reference_value={reference?.ref || reference?.id}
                    sourceData={reference}
                    transaction_Id={reference.txnId}
                />
            )}
        </div>
    );
};

export default COASegLevMapping;
