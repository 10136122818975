import React, { useState, useEffect } from 'react';
import { TableSchema } from '../commons/TableSchema';
import SecondaryDataTable from '../components/fDataTable/SecondaryDataTable';
import TextAreaComponent from '../components/InputComponent/TextAreaComponent';
import { useQuery, useMutation } from '@apollo/client';
import Schema from '../schema/AppSchema';
import { adminClient, uploadClient } from '../apollo';
import { Dropdown } from 'primereact/dropdown';
import { uniqueId, getDate } from '../util';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'appkit-react';
import ConfirmationModal from '../components/DeleteConfirmModal/DeleteConfirmModal';
import InputComponent from '../components/InputComponent/InputComponent';
import { DownloadFile } from '../commons/Downloader';
import * as FormValidation from '../Validation';

const UploadDocuments = (props) => {
    const [rows, setRows] = useState(props.document_details);
    const [fileUploader, setFileUploader] = useState('');
    const [docCats, setDocmentCats] = useState([]);
    const [invalidVisible, setInValid] = useState(true);
    let tablecolms = TableSchema.DOC_UPLOAD;
    const [state, setState] = useState({
        showForm: false,
        actionType: '',
        showDeleteModal: false
    });
    const [docState, setDocState] = useState({
        validDocs: props.document_details?.filter((val) => val.is_rejected === false),
        invalidDocs: props.document_details?.filter((val) => val.is_rejected === true)
    });
    let emptyObj = {
        category: '',
        description: '',
        attachments: [],
        upload_type: '',
        fileName: undefined
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let valid = props?.document_details.filter((val) => val.is_rejected === false);
        let invalid = props?.document_details.filter((val) => val.is_rejected === true);
        setDocState({ ...docState, validDocs: valid, invalidDocs: invalid });
        setRows(props?.document_details);
    }, [props?.document_details]);

    const actionTemplate = (rowData) => {
        return (
            <>
                <div>
                    {(!props.version_no || props.version_no === 0 || (props.isApproved ? true : rowData?.is_local)) &&
                        props?.mode !== 'view' &&
                        (props.isApproved ? props.checkApproveEdit : true) && (
                            <>
                                <img
                                    title="Edit"
                                    className="pointer m-l-5 m-r-5"
                                    height="20px"
                                    src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                    alt="Edit Icon"
                                    onClick={() => handleActions(rowData, 'edit')}
                                />
                                {!rowData?.is_mandatory && !props.isApproved && (
                                    <button
                                        className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                        onClick={() => handleActions(rowData, 'delete')}
                                    >
                                        <img
                                            className="pointer m-l-5 m-r-5"
                                            height="20px"
                                            title="Delete"
                                            src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                            alt="Delete Icon"
                                        />
                                    </button>
                                )}
                            </>
                        )}
                    {props.version_no > 0 && (
                        <>
                            <img
                                className="pointer m-l-5 m-r-5"
                                height="20px"
                                title="Info"
                                src={process.env.PUBLIC_URL + '/assets/icons/leftMenuIcons/Gen_Info.svg'}
                                alt="Info Icon"
                                onClick={() => handleActions(rowData, 'info')}
                            />
                            {!rowData?.is_rejected && props?.mode !== 'view' && !rowData?.is_local && (
                                <img
                                    className="pointer m-l-5 m-r-5"
                                    height="18px"
                                    title="Invalid"
                                    src={process.env.PUBLIC_URL + '/assets/icons/Invalidate_Document.svg'}
                                    alt="Invalid Icon"
                                    onClick={() => handleActions(rowData, 'invalid')}
                                />
                            )}
                        </>
                    )}
                </div>
            </>
        );
    };
    tablecolms[tablecolms.length - 1].body = actionTemplate;

    const setFileExtensions = (e) => {
        let selVals = e?.split(',');
        let exts = [];
        if (selVals?.length > 0 && docsData?.appEnums?.length > 0) {
            docsData.appEnums.forEach((extType) => {
                if (selVals.find((se) => se === extType.display_value)) {
                    exts.push('.' + extType.enum_value);
                }
            });
        }
        return exts?.toString();
    };

    const fields = [{ key: 'description' }];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev);

        if (err[key]) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        if (key === 'category') {
            let fileExtensions = setFileExtensions(ev?.value?.file_type);
            setCurrentDetailsObj({
                ...currentDetailsObj,
                [key]: ev?.value,
                name_ref: ev?.value?.name_ref,
                category_id: ev?.value?.id,
                file_type: ev?.value?.file_type,
                fileType: fileExtensions,
                upload_type: ev?.value?.upload_type,
                file_size: ev?.value?.file_size,
                description: '',
                fileName: ''
            });
        } else {
            if (!ev.trim()) {
                ev = '';
            }
            setCurrentDetailsObj({
                ...currentDetailsObj,
                [key]: ev
            });
        }
        validateFeild(ev, key);
    };

    let { data: docsData } = useQuery(Schema.documentCategories, {
        client: adminClient,
        fetchPolicy: 'no-cache',
        variables: {
            where: {
                is_effective: { equals: true },
                mappings: props.fmis_filter ? { some: { fmis_function_code: { equals: props.fmis_function } } } : undefined
            }
        },
        onCompleted: (data) => {
            if (data.documentCategories) {
                data.documentCategories.forEach((val) => {
                    let ref = val?.ref ? val?.ref + ' - ' : '';
                    val['name_ref'] = ref + val['name'];
                });
            }
            setDocmentCats(data.documentCategories);
        }
    });

    const saveToDB = () => {
        if (state.actionType === 'edit') {
            props.loading(true);
            setState({ ...state, fromEdit: true });
            updateDocument({
                variables: {
                    data: {
                        is_rejected: { set: false },
                        name: { set: currentDetailsObj?.fileName ? currentDetailsObj?.fileName : '' },
                        file_id: {
                            set: currentDetailsObj?.attachments[0]?.file_id ? currentDetailsObj?.attachments[0]?.file_id : editObj?.file_id
                        },
                        document: { update: { description: { set: currentDetailsObj.description } } }
                    },
                    where: {
                        id: editObj.attachment_id
                    }
                }
            });
        } else {
            props.loading(true);
            createDocument({
                variables: {
                    data: {
                        name: currentDetailsObj?.fileName ? currentDetailsObj?.fileName : '',
                        file_id: currentDetailsObj?.attachments[0]?.file_id ? currentDetailsObj?.attachments[0]?.file_id : editObj?.file_id,
                        document: {
                            create: {
                                source_id: props?.source_id,
                                description: currentDetailsObj.description,
                                category: { connect: { id: currentDetailsObj.category_id } }
                            }
                        }
                    }
                }
            });
        }
    };

    const saveToTB = () => {
        if (state.actionType === 'edit') {
            currentDetailsObj['is_rejected'] = false;
            let indx = docState.validDocs.findIndex((a) => a.unique_Id === editObj['unique_Id']);
            docState.validDocs[indx] = currentDetailsObj;
            setRows([...docState.validDocs, ...docState.invalidDocs]);
        } else {
            currentDetailsObj['is_rejected'] = false;
            currentDetailsObj['unique_Id'] = uniqueId(5);
            currentDetailsObj['is_local'] = true;
            docState.validDocs.push(currentDetailsObj);
            setRows([...docState.validDocs, ...docState.invalidDocs]);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveStateData([...docState.validDocs, ...docState.invalidDocs]);
    };

    const handleViewActions = (rowData, col, sAction) => {
        if (sAction === 'view_click') {
            download(rowData);
        }
    };

    const download = (stateVal) => {
        let val = stateVal?.attachments[0]?.file_id;
        let fileName = stateVal?.fileName;
        DownloadFile(val, fileName);
    };

    const handleActions = (rowData, sAction) => {
        let newObj = JSON.parse(JSON.stringify(rowData));
        setEditObj(newObj);
        if (sAction === 'edit') {
            let fileExtensions = setFileExtensions(newObj?.file_type);
            newObj['fileType'] = fileExtensions ? fileExtensions : newObj['fileType'];
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            newObj['attachments'] = [];
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
        if (sAction === 'info') {
            setState({ ...state, showInfoModal: true });
        }
        if (sAction === 'invalid') {
            setCurrentDetailsObj(newObj);
            setState({ ...state, showInvalidModal: true });
        }
    };

    const handleInvalid = () => {
        setState({ ...state, showInvalidModal: false, fromInvalid: true });
        props.loading(true);
        updateDocument({
            variables: {
                data: { is_rejected: { set: true } },
                where: {
                    id: editObj.attachment_id
                }
            }
        });
    };

    const [updateDocument] = useMutation(Schema.updateOneDocumentAttachment, {
        fetchPolicy: 'no-cache',
        client: adminClient,
        onCompleted: (data) => {
            if (data.updateOneDocumentAttachment) {
                let rec = data?.updateOneDocumentAttachment;
                currentDetailsObj['is_rejected'] = rec?.is_rejected;
                currentDetailsObj['version_user'] = rec?.version_user;
                currentDetailsObj['attachment_id'] = rec?.document?.attachments[0]?.id;
                currentDetailsObj['file_id'] = rec?.document?.attachments[0]?.file_id;
                currentDetailsObj['local_date'] = getDate(rec?.document?.attachments[0]?.version_date);
                currentDetailsObj['document_ref'] = rec?.document?.ref;
                currentDetailsObj['attachments'] = rec?.document?.attachments;
                let indx = docState.validDocs.findIndex((a) => a['unique_Id'] === editObj['unique_Id']);
                if (rec.is_rejected && state.fromInvalid) {
                    docState.validDocs.splice(indx, 1);
                    docState.invalidDocs.push(currentDetailsObj);
                } else if (state.fromEdit) {
                    docState.validDocs[indx] = currentDetailsObj;
                }
                setDocState({ ...docState, validDocs: docState.validDocs, invalidDocs: docState.invalidDocs });
                setRows([...docState.validDocs, ...docState.invalidDocs]);
                setState({ ...state, showForm: false, actionType: '', showDeleteModal: false });
                setCurrentDetailsObj(emptyObj);
                setEditObj({});
                props.saveStateData([...docState.validDocs, ...docState.invalidDocs]);
                setState({
                    ...state,
                    showForm: false,
                    actionType: '',
                    showDeleteModal: false,
                    fromEdit: false,
                    fromDelete: false,
                    fromInvalid: false
                });

                props.loading(false);
            }
        },
        onError: () => {
            props.loading(false);
        }
    });

    const [createDocument] = useMutation(Schema.createOneDocumentAttachment, {
        fetchPolicy: 'no-cache',
        client: adminClient,
        onCompleted: (data) => {
            let rec = data.createOneDocumentAttachment;
            currentDetailsObj['is_rejected'] = false;
            currentDetailsObj['unique_Id'] = uniqueId(5);
            currentDetailsObj['is_local'] = true;
            currentDetailsObj['id'] = rec.id;
            currentDetailsObj['document_id'] = rec?.document?.id;
            currentDetailsObj['attachment_id'] = rec?.document?.attachments[0]?.id;
            currentDetailsObj['file_id'] = rec?.document?.attachments[0]?.file_id;
            currentDetailsObj['attachments'] = rec?.document?.attachments;
            currentDetailsObj['local_date'] = getDate(rec?.document?.attachments[0]?.version_date);
            currentDetailsObj['version_user'] = rec?.document?.attachments[0]?.version_user;
            currentDetailsObj['document_ref'] = rec?.document?.ref;
            docState.validDocs.push(currentDetailsObj);
            setDocState({ ...docState, validDocs: docState.validDocs, invalidDocs: docState.invalidDocs });
            setRows([...docState.validDocs, ...docState.invalidDocs]);
            setState({ ...state, showForm: false, actionType: '' });
            setCurrentDetailsObj(emptyObj);
            setEditObj({});
            props.saveStateData([...docState.validDocs, ...docState.invalidDocs]);
            props.loading(false);
        },
        onError: () => {
            props.loading(false);
        }
    });

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    const uploadDoc = () => {
        fileUploader.click();
    };

    const onBasicUploadAuto = (value) => {
        value.stopPropagation();
        value.preventDefault();
        const file = value.target.files[0];
        if (file !== undefined) {
            let fileSize = file?.size / (1024 * 1024);
            let fileNameArr = file?.name?.split('.');
            let fileExt = '.' + fileNameArr[fileNameArr?.length - 1].toUpperCase();
            if (fileSize > currentDetailsObj?.file_size) {
                let msg = +currentDetailsObj?.file_size
                    ? window.COMMONS.File + currentDetailsObj?.file_size + 'MB)'
                    : window.COMMONS.FileSize;
                props.growl.show({
                    life: 6000,
                    severity: 'error',
                    summary: msg
                });
            } else if (!currentDetailsObj?.fileType.split(',').includes(fileExt)) {
                let msg = window.COMMONS.FileNotSupported;
                props.growl.show({
                    life: 6000,
                    severity: 'error',
                    summary: msg
                });
            } else {
                // eslint-disable-next-line max-depth
                if (file) {
                    props.loading(true);
                    uploadFile({
                        variables: {
                            file: file
                        }
                    });
                }
            }
        }
    };

    const [uploadFile] = useMutation(Schema.singleUpload, {
        client: uploadClient,
        onCompleted: (data) => {
            if (data) {
                let val = data.singleUpload;
                let attachments = [
                    {
                        file_id: val.id,
                        id: uniqueId(25),
                        name: val.input_name
                    }
                ];
                setCurrentDetailsObj({ ...currentDetailsObj, fileName: data.singleUpload.input_name, attachments: attachments });
                props.loading(false);
            }
        },
        onError: () => {
            props.loading(false);
        }
    });

    const [deleteDocument] = useMutation(Schema.deleteOneDocument, {
        client: adminClient,
        onCompleted: (data) => {
            if (data) {
                let indx = docState.validDocs.findIndex((a) => {
                    return a.unique_Id === currentDetailsObj.unique_Id;
                });
                docState.validDocs.splice(indx, 1);
                setDocState({ ...docState, validDocs: docState.validDocs, invalidDocs: docState.invalidDocs });
                setRows([...docState.validDocs, ...docState.invalidDocs]);
                setCurrentDetailsObj(emptyObj);
                setEditObj({});
                props.saveStateData([...docState.validDocs, ...docState.invalidDocs]);
                setState({ ...state, showDeleteModal: false });
            }
            props.loading(false);
        },
        onError: () => {
            props.loading(false);
        }
    });

    const handleDelete = () => {
        if (editObj?.document_id) {
            props.loading(true);
            deleteDocument({ variables: { where: { id: editObj.document_id } } });
        } else {
            let indx = docState.validDocs.findIndex((a) => {
                return a.unique_Id === currentDetailsObj.unique_Id;
            });
            docState.validDocs.splice(indx, 1);
            setDocState({ ...docState, validDocs: docState.validDocs, invalidDocs: docState.invalidDocs });
            setRows([...docState.validDocs, ...docState.invalidDocs]);
            setCurrentDetailsObj(emptyObj);
            setEditObj({});
            props.saveStateData([...docState.validDocs, ...docState.invalidDocs]);
            setState({ ...state, showDeleteModal: false });
        }
    };

    const prepareDocValues = () => {
        let list = [];
        if (docState?.validDocs?.length > 0) {
            docCats.forEach((obj) => {
                let val = docState.validDocs.filter((key) => {
                    return key.name_ref === obj.name_ref && (obj.upload_type === 'SINGLE' || obj.upload_type === 'Single');
                })[0];
                if (!val) list.push(obj);
            });
        } else {
            list = docCats;
        }
        return list;
    };

    const isDisabled = () => {
        return !currentDetailsObj.category_id || !currentDetailsObj.description || !currentDetailsObj.fileName;
    };

    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows.filter((val) => val.is_rejected === false)}
                    columns={tablecolms}
                    noPaginator={true}
                    emptyMessage="Documents are yet to be added"
                    className="secondary-table"
                    scrollable
                    formType="view"
                    mode={props?.mode}
                    handleActionsInParent={handleViewActions}
                />
                {!state.showForm && props.mode !== 'view' && (props.isApproved ? props.checkApproveEdit : true) && (
                    <div className="comment-header">
                        <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                            +&nbsp;{' Add Document '}
                        </span>
                    </div>
                )}
                {state.showForm && (
                    <div>
                        <div className="row p-t-10">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Document Category
                                            {props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        {state.actionType === 'edit' ? (
                                            <InputComponent value={currentDetailsObj.name_ref} floatdecimal="true" disabled={true} />
                                        ) : (
                                            <Dropdown
                                                resetFilterOnHide={true}
                                                className="w-100"
                                                name="Document Category"
                                                value={currentDetailsObj.category}
                                                useGrouping={false}
                                                onChange={(e) => handleChange(e, 'category')}
                                                appendTo={document.self}
                                                options={prepareDocValues()}
                                                placeholder="Document Category"
                                                optionLabel="name_ref"
                                                filter={true}
                                                filterPlaceholder="Search"
                                                filterBy="name_ref"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Document Description
                                            {props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        {props.mode !== 'view' && (
                                            <>
                                                <TextAreaComponent
                                                    textBoxSize="lg"
                                                    name="description"
                                                    type="text"
                                                    value={currentDetailsObj.description}
                                                    maxLength={
                                                        window.INTERNAL_AUDIT ? window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT : '50'
                                                    }
                                                    onChange={(e) => handleChange(e, 'description')}
                                                    placeholder="Enter description"
                                                />
                                                {errors && errors.description && (
                                                    <p className="a-form-error">Please enter valid Document Description</p>
                                                )}
                                            </>
                                        )}
                                        {props.mode === 'view' && (currentDetailsObj?.description ? currentDetailsObj?.description : '–')}
                                    </div>
                                </div>
                            </div>
                            {currentDetailsObj?.file_type && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                            <b className="fs-14">File Type : </b>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                            <span className="word-break">{currentDetailsObj?.file_type}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {currentDetailsObj?.file_size && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                            <b className="fs-14">File Size :</b>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                            {currentDetailsObj?.file_size} MB
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Attachment? {props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        {currentDetailsObj.fileName && currentDetailsObj.fileName !== '--' ? (
                                            <div>
                                                <p className="display-flex">
                                                    <u onClick={() => download(currentDetailsObj)} className="primary-color pointer">
                                                        {currentDetailsObj.fileName}
                                                    </u>
                                                    <i
                                                        className="pi pi-trash float-right pointer m-t-5 m-l-5"
                                                        onClick={() =>
                                                            setCurrentDetailsObj({
                                                                ...currentDetailsObj,
                                                                fileName: undefined,
                                                                attachments: []
                                                            })
                                                        }
                                                    ></i>
                                                </p>
                                            </div>
                                        ) : (
                                            <>
                                                <Button
                                                    size="sm"
                                                    kind="primary"
                                                    disabled={!currentDetailsObj.category_id}
                                                    className="m-r-10"
                                                    onClick={() => uploadDoc()}
                                                >
                                                    Upload
                                                </Button>
                                                <input
                                                    type="file"
                                                    id="file"
                                                    accept={currentDetailsObj.fileType}
                                                    ref={(file) => {
                                                        setFileUploader(file);
                                                    }}
                                                    hidden
                                                    onChange={(ev) => onBasicUploadAuto(ev)}
                                                    onClick={(ev) => (ev.target.value = null)}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 p-t-10">
                            <Button
                                size="sm"
                                disabled={isDisabled()}
                                kind="primary"
                                className="m-r-10"
                                onClick={() => (props.version_no > 0 ? saveToDB() : saveToTB())}
                            >
                                {state.actionType === 'edit' ? 'Update' : 'Add'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
                {docState?.invalidDocs?.length > 0 && (
                    <>
                        <div className="fs-14 m-t-10 m-b-10">
                            <b className="fs-14">Invalid Documents</b>
                            <img
                                className="pointer m-l-5 m-r-5"
                                height="30px"
                                title="Invalid Documents"
                                src={
                                    invalidVisible
                                        ? process.env.PUBLIC_URL + '/assets/icons/Collapse.svg'
                                        : process.env.PUBLIC_URL + '/assets/icons/PlusRound.png'
                                }
                                alt="Invalid"
                                onClick={() => {
                                    invalidVisible ? setInValid(false) : setInValid(true);
                                }}
                            />
                        </div>
                        {invalidVisible ? (
                            <SecondaryDataTable
                                formType="view"
                                handleActionsInParent={handleViewActions}
                                rows={rows.filter((val) => val.is_rejected === true)}
                                columns={tablecolms}
                                noPaginator={true}
                                className="secondary-table"
                                scrollable
                                mode={props?.mode}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </div>
            {state.showInfoModal && (
                <Modal
                    visible={state.showInfoModal}
                    backdropClosable={false}
                    onCancel={() => setState({ ...state, showInfoModal: false })}
                    className="common_css w-600"
                >
                    <ModalHeader>Additional Information</ModalHeader>
                    <ModalBody>
                        <SecondaryDataTable
                            rows={[editObj]}
                            columns={TableSchema.DOC_UPLOAD_INFO}
                            noPaginator={true}
                            className="secondary-table "
                            scrollable
                        />
                    </ModalBody>
                </Modal>
            )}
            {state.showInvalidModal && (
                <Modal
                    visible={state.showInvalidModal}
                    backdropClosable={false}
                    onCancel={() => setState({ ...state, showInvalidModal: false })}
                    className={state.loading ? 'common_css-mask common_css add_user' : 'common_css add_user'}
                >
                    <ModalHeader>Invalid</ModalHeader>
                    <ModalBody className="p-0">
                        <div className="flex align-items-center">
                            <div className="f-14">Are you sure of moving this file to Invalid Documents ? </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="m-0">
                        <Button
                            className="m-r-10"
                            size="sm"
                            kind="primary"
                            onClick={() => {
                                handleInvalid();
                            }}
                        >
                            Yes
                        </Button>
                        <Button size="sm" kind="secondary" onClick={() => setState({ ...state, showInvalidModal: false })}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDelete}
                    handleCancel={() => setState({ ...state, showDeleteModal: false })}
                    type="Document Details"
                    showModal={state.showDeleteModal}
                    name="this record"
                />
            )}
        </div>
    );
};

export default UploadDocuments;
