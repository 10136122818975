import React, { useState } from 'react';
import { Button } from 'appkit-react';
import { Dropdown } from 'primereact/dropdown';
import IsAuthorized from '../../commons/IsAuthorized';

const TopDropdownComponent = (props) => {
    const [state, setState] = useState({
        selectedYear: props.topGridProps.selectedYear,
        yearObj: props.topGridProps.yearObj
    });

    const onClickAddNewRate = () => {
        props.showPopUp();
    };
    const handleChange = (e) => {
        setState({ ...state, selectedYear: e });
        props.topGridProps.setSelectedYear(e);
    };

    return (
        <div className="m-b-10">
            <div className="row m-0 hz-ver-center">
                <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 p-l-0">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-6 col-lg-4 col-xl-4 p-t-5">
                            <b>Financial Year</b>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6 col-lg-4 col-xl-4">
                            <Dropdown
                                name="selectedYear"
                                value={state.selectedYear}
                                onChange={(e) => handleChange(e.value)}
                                options={state.yearObj?.admFinYears}
                                className="w-100"
                                validations={['required']}
                                optionValue="id"
                                optionLabel="name"
                                filter={true}
                                filterPlaceholder="Search"
                                filterBy="name"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 p-r-0">
                    <IsAuthorized
                        privRequired={props.topGridProps.createPriv}
                        officeCode={props.topGridProps.officeCode}
                        yes={() => (
                            <Button
                                size="md"
                                className={props.topGridProps.disableAdd ? 'disabled float-right' : 'float-right'}
                                onClick={() => onClickAddNewRate()}
                            >
                                {props.topGridProps.buttonLabel}
                            </Button>
                        )}
                    ></IsAuthorized>
                </div>
            </div>
        </div>
    );
};

export default TopDropdownComponent;
