import React, { useState } from 'react';
import Schema from '../../../schema/AppSchema';
import { useMutation } from '@apollo/client';
import { contractClient } from '../../../apollo';
import ActionsComponents from '../../../components/actionsComponent/ActionsComponents';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';
import { getUTCTime } from '../../../util';
import { useHistory } from 'react-router-dom';

const createOneQuery = Schema.CreateContract;
const updateOneQuery = Schema.updateOneCntrContract;

const ContractActions = (props) => {
    const [state, setState] = useState({ buttonStatus: '' });
    const [versionNo, setVersionNo] = useState(props.formState?.version_no);
    const [versionUser, setVersionUser] = useState(props.formState?.version_user);
    const [currentAction, setCurrentAction] = useState('');
    let history = useHistory();
    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.CNTRCT_CREATION.find((a) => loc[1].includes(a.routerLink));
    const [showWfBtns, setWFBtns] = useState(true);

    const [createContract] = useMutation(createOneQuery, {
        client: contractClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let growlMsg =
                    currentAction?.name && currentAction?.name === 'Submit'
                        ? 'Contract submitted successfully'
                        : 'Contract created successfully';
                props.showGrowl(growlMsg, 'success');
                if (currentAction?.name && currentAction?.name === 'Submit') {
                    setWFBtns(currentAction?.name && currentAction?.name === 'Submit' ? false : true);
                }
                setTimeout(() => {
                    history.push('/cc/contracts');
                }, 2000);
            }
        },
        onError: () => {
            props.handleLoader(false);
        }
    });

    const [updateContract] = useMutation(updateOneQuery, {
        client: contractClient,
        variables: { where: { id: props.recID } },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let growlMsg = currentAction?.name ? currentAction?.name + ' action taken successfully' : 'Contract updated successfully';
                setVersionNo(parseInt(data.updateOneCntrContract.version_no));
                setVersionUser(data.updateOneCntrContract.version_user);
                setWFBtns(true);
                props.showGrowl(growlMsg, 'success');
            }
        },
        onError: () => {
            props.handleLoader(false);
        }
    });

    const prepareBankDetails = () => {
        let list = [];
        if (props?.formState?.Performance_Bank_Guarantee?.rows?.length > 0) {
            props.formState.Performance_Bank_Guarantee.rows.forEach((a) => {
                let bankObj = {
                    bank_id: a.bankName.id,
                    amount: parseFloat(parseFloat(a.amountBGaurnte).toFixed(2)),
                    ref: a.bGauNo.toString(),
                    expiry: new Date(a.expiryDate)
                };
                if (a.id) bankObj.id = a.id;
                list.push(bankObj);
            });
        }
        return list;
    };

    const prepareAdvances = (advances) => {
        let list = [];
        if (advances?.rows?.length > 0) {
            advances.rows.forEach((a) => {
                list.push({
                    advance: { connect: { id: a.adv_type.id } },
                    rate: parseFloat(parseFloat(a.adv_rate).toFixed(2)),
                    amount: parseFloat(parseFloat(a.adv_amount).toFixed(2)),
                    hoa: a.hoa,
                    recovery_method: a.adv_rec_mthd
                });
            });
        }
        return list;
    };

    const prepareDeductions = (deductions) => {
        let list = [];
        if (deductions?.rows?.length > 0) {
            deductions.rows.forEach((a) => {
                list.push({
                    deduction_id: a.deduction.id,
                    amount: parseFloat(parseFloat(a.amount).toFixed(2)),
                    rate: a.deduction.type === 'Percentage' ? parseFloat(parseFloat(a.deduction.amount).toFixed(2)) : -1
                });
            });
        }
        return list;
    };

    const prepareAdRecovery = (rows) => {
        let list = [];
        if (rows?.length > 0) {
            rows.forEach((a) => {
                list.push({
                    advance: { connect: { id: a.id } },
                    amount: parseFloat(parseFloat(a.adv_inc_amount).toFixed(2)),
                    is_edited: a.is_edited
                });
            });
        }
        return list;
    };

    const prepareDeductionDetails = (item) => {
        let list = [];
        if (item?.length > 0) {
            item.forEach((a) => {
                list.push({
                    deduction_id: a.deduction?.id,
                    amount: parseFloat(parseFloat(a.pay_amount).toFixed(2)),
                    remarks: a.remarks,
                    is_edited: a.is_edited
                });
            });
        }
        return list;
    };

    const preparePaySchedules = (payments) => {
        let list = [];
        if (payments?.rows?.length > 0) {
            payments.rows.forEach((a) => {
                let obj = {
                    hoa: a.hoa,
                    mile_stone: a.advance_id,
                    due_date: a.due_date,
                    amount_incl_tax: parseFloat(parseFloat(a.amt_inc_tax).toFixed(2)),
                    amount_excl_tax: parseFloat(parseFloat(a.amt_exc_tax).toFixed(2)),
                    adv_recov_amount: -1,
                    sec_dep_amount: -1,
                    other_deduction_amount: -1,
                    out_standing_amount: parseFloat(parseFloat(a.amt_inc_tax).toFixed(2)),
                    excl_out_standing_amount: parseFloat(parseFloat(a.amt_inc_tax).toFixed(2)),
                    net_pay_amount: parseFloat(parseFloat(a.net_pay_amt).toFixed(2)),
                    sequence: a.slno
                };
                if (a.type !== 'advance') {
                    obj.mile_stone = a.pay_milestone?.trim();
                    obj.adv_recov_amount = parseFloat(parseFloat(a.adavce_recovery).toFixed(2));
                    obj.sec_dep_amount = parseFloat(parseFloat(a.security_depst_dedct).toFixed(2));
                    obj.other_deduction_amount = parseFloat(parseFloat(a.other_deduction).toFixed(2));
                    obj.advance_recoveries = { create: prepareAdRecovery(a.advance_recoveries) };
                    obj.deduction_details = { create: prepareDeductionDetails(a.deduction_details) };
                }
                list.push(obj);
            });
        }
        return list;
    };

    const prepareFinSanc = (item) => {
        let list = [];
        if (item?.finRows?.length > 0) {
            item.finRows.forEach((a) => {
                list.push({
                    ref: a.fin_sanc_ref?.trim(),
                    amount: parseFloat(parseFloat(a.fin_sanc_inr).toFixed(2)),
                    year_id: a.year
                });
            });
        }
        return list;
    };

    const prepareSupplies = () => {
        let list = [];
        let isOnTotalVal = props.formState.Contract_Details.contracType !== 'supplyCat';
        if (props.formState.Contract_Details.rows.length > 0) {
            props.formState.Contract_Details.rows.forEach((a) => {
                let obj = {
                    supply_category: { connect: { id: a.sup_cat.id } },
                    hoa: a.hoa,
                    amount_incl_tax: parseFloat(parseFloat(a.cntrct_val_inc_tax).toFixed(2)),
                    amount_excl_tax: parseFloat(parseFloat(a.cntrct_val_exc_tax).toFixed(2)),
                    variance: parseFloat(parseFloat(a.admissable_var).toFixed(2)),
                    fin_sanctions: { create: prepareFinSanc(a.cntrct_fin_sanc) }
                };
                if (!isOnTotalVal && a.moreDetailsObj) {
                    obj['more_data'] = getMoreData(a.moreDetailsObj);
                }
                if (a.id) obj.id = a.id;
                list.push(obj);
            });
        }
        return list;
    };

    const getMoreData = (moreObj) => {
        let obj = {
            create: {}
        };
        if (moreObj?.Advance?.rows?.length > 0) {
            obj['create']['advances'] = { create: prepareAdvances(moreObj?.Advance) };
        }
        if (moreObj?.Other_Deductions?.rows?.length > 0) {
            obj['create']['deductions'] = { create: prepareDeductions(moreObj?.Other_Deductions) };
        }
        if (moreObj?.Payment_Schedule?.rows?.length > 0) {
            obj['create']['pay_schedules'] = { create: preparePaySchedules(moreObj?.Payment_Schedule) };
        }
        if (moreObj?.Security_Deposit_Deduction?.scc_depo_deduc_rate?.id) {
            obj['create']['sec_deposit'] = { connect: { id: moreObj.Security_Deposit_Deduction.scc_depo_deduc_rate.id } };
        }
        if (moreObj?.Security_Deposit_Deduction?.scc_depo_refund_time?.trim()) {
            obj['create']['refund_in_months'] = parseFloat(moreObj.Security_Deposit_Deduction.scc_depo_refund_time);
        }
        return obj;
    };

    const checkValidations = () => {
        if (props.showUpdateInfo) {
            return props.showUpdateInfo;
        }
    };

    const displaySupplies = () => {
        let list = [];
        if (props.formState?.Contract_Details?.rows?.length > 0) {
            props.formState.Contract_Details.rows.forEach((con) => {
                let obj = {};
                obj.amount_incl_tax = parseFloat(con.cntrct_val_inc_tax);
                obj['supply_category'] = con.sup_cat;
                list.push(obj);
            });
        }
        return list;
    };

    const getDisplayData = () => {
        return {
            name: props.formState?.contractName?.trim(),
            supplies: displaySupplies(),
            supplier: {
                name: props.formState?.supplier?.name
            }
        };
    };

    const getRecord = (isTempSave = true, status) => {
        let isValid = checkValidations();
        if (isValid) {
            props.showGrowl(isValid, 'error');
            return;
        }

        state.buttonStatus = status;
        setState({ ...state, buttonStatus: state.buttonStatus });
        props.handleLoader(true);
        let isOnTotalVal = props.formState.Contract_Details.contracType !== 'supplyCat';
        let queryObj = {
            year_id: getValue(props.formState?.year_id, isTempSave),
            description: getValue(props.formState?.description?.trim(), isTempSave),
            name: getValue(props.formState?.contractName?.trim(), isTempSave),
            supplier_contact: {
                connect: {
                    id: props.formState?.supplierContact.id
                }
            },
            supplier: {
                connect: {
                    id: props.formState?.supplier?.id
                }
            },
            sanct_agency_id: getValue(props?.formState?.General_Contract_Information?.sanct_agency_id, isTempSave),
            impl_agency_id: getValue(props?.formState?.General_Contract_Information?.impl_agency_id, isTempSave),
            ccy: getValue(props?.formState?.General_Contract_Information?.ccy, isTempSave),
            signing_date: getValue(getUTCTime(props?.formState?.General_Contract_Information?.signing_date), isTempSave),
            closing_date: getValue(getUTCTime(props?.formState?.General_Contract_Information?.closing_date), isTempSave),
            govt_ref: getValue(props?.formState?.General_Contract_Information?.govt_ref, isTempSave),
            adm_ref: getValue(props?.formState?.General_Contract_Information?.adm_ref?.trim(), isTempSave),
            tech_ref: getValue(props?.formState?.General_Contract_Information?.tech_ref?.trim(), isTempSave),
            proc_ref: getValue(props?.formState?.General_Contract_Information?.proc_ref?.trim(), isTempSave),
            on_total_val: getValue(isOnTotalVal, isTempSave),
            pay_term: getValue('30 days', isTempSave),
            pay_method: getValue(props.formState.Payment_Terms.paymentMode, isTempSave)
        };
        if (props.formState.Contract_Details.rows.length > 0) {
            queryObj['supplies'] = {
                create: prepareSupplies()
            };
        }
        if (props.formState?.General_Contract_Information?.project) {
            queryObj['project'] = {
                connect: {
                    id: props.formState?.General_Contract_Information?.project
                }
            };
        }
        if (props?.formState?.Performance_Bank_Guarantee?.rows?.length > 0) {
            queryObj['bank_guarantees'] = {
                create: prepareBankDetails()
            };
        }
        if (isOnTotalVal && props?.formState?.Contract_Details?.moreDetailsObj) {
            queryObj['more_data'] = getMoreData(props.formState.Contract_Details.moreDetailsObj);
        }
        if (state.category_data?.version_no) queryObj.version_no = state.category_data.version_no;
        if (props.entity_id) queryObj.entity_id = props.entity_id;
        if (props.recID) queryObj.id = props.recID;
        if (isTempSave) queryObj['documents'] = props.docList;
        return queryObj;
    };

    const getValue = (value, isTempSave) => {
        if (!isTempSave && (versionNo > 0 || (!versionNo && props?.formState?.version_no > 0))) return { set: value };
        return value;
    };

    const growlVal = (records) => {
        return (
            <>
                <div>Please upload mandatory documents</div>
                {records.map((btn, index) => {
                    return (
                        <div key={index}>
                            {index + 1}. {btn.name_ref}
                        </div>
                    );
                })}
            </>
        );
    };

    const checkMandatory = () => {
        let records = props.docList
            .filter((val) => val.is_mandatory && !val?.fileName)
            .map((r) => {
                return { name: r.name, ref: r.ref, name_ref: r.name_ref };
            });
        if (records && records.length > 0) props.showGrowl(growlVal(records), 'error');
        return records && records.length > 0 ? true : false;
    };

    const prepareDocAtts = (attachments) => {
        return attachments?.map((val) => {
            return {
                file_id: val.file_id,
                name: val.name
            };
        });
    };

    const prepareDocuments = () => {
        return props.docList.map((val) => {
            return {
                description: val.description,
                source_id: '',
                category: { connect: { id: val.category_id } },
                attachments: { create: val?.attachments ? prepareDocAtts(val?.attachments) : [] }
            };
        });
    };

    const handleSubmitORSave = (status, wfParams, action) => {
        if (checkMandatory()) return;
        setCurrentAction(action);
        let dataObj = getRecord(false, status);
        dataObj.wf_params = wfParams;
        delete dataObj.entity_id;
        delete dataObj.version_no;
        if (versionNo > 0 || (!versionNo && props.formState?.version_no > 0)) {
            delete dataObj.id;
            setWFBtns(false);
            updateContract({
                variables: {
                    data: dataObj
                }
            });
        } else {
            dataObj.documents = { data: prepareDocuments() };
            createContract({
                variables: {
                    data: dataObj
                }
            });
        }
    };

    let mandatroryFields = {
        contractName: '',
        supplier: '',
        supplierContact: '',
        description: ''
    };

    let minFields = {
        supplier: ''
    };

    const disableButton = () => {
        if (!props.formState) return true;
        for (let key in mandatroryFields) {
            if (
                props.formState[key] === undefined ||
                props.formState[key] === '' ||
                (props.formState[key] && typeof props.formState[key] === 'string' && props.formState[key].trim() === '')
            ) {
                return true;
            }
        }
        if (props.inActiveAccs?.length > 0) return true;

        let gen = props?.formState?.General_Contract_Information;
        if (
            !gen.sanct_agency_id ||
            !gen.impl_agency_id ||
            !gen.ccy ||
            !gen.signing_date ||
            !gen.closing_date ||
            !props?.formState?.Payment_Terms.paymentMode
        ) {
            return true;
        }
        const contractDetails = props?.formState?.Contract_Details?.rows;
        let flag = false;
        contractDetails &&
            contractDetails.map((cDetail) => {
                if (cDetail.hoa?.split('-').length < 4) {
                    flag = true;
                }
                return cDetail;
            });
        return flag;
    };

    const minFieldsRequired = () => {
        if (!props.formState) return true;
        if (props.inActiveAccs?.length > 0) return true;
        for (let key in minFields) {
            if (
                props.formState[key] === undefined ||
                props.formState[key] === '' ||
                (props.formState[key] && typeof props.formState[key] === 'string' && props.formState[key].trim() === '')
            ) {
                return true;
            }
        }

        return false;
    };

    return (
        <div>
            {showWfBtns && (
                <ActionsComponents
                    navigateToEdit={() => props.navigateToEdit()}
                    mode={props.formType}
                    setActionsStatus={props.setActionsStatus}
                    screen="createContract"
                    showGrowl={props.showGrowl}
                    canDisable={disableButton()}
                    minFieldsRequired={minFieldsRequired()}
                    onSave={(wfParams, action) => handleSubmitORSave('Approved', wfParams, action)}
                    onCancel={() => props.handleCancel()}
                    version_no={versionNo || props.formState?.version_no}
                    version_user={versionUser || props.formState?.version_user}
                    privRequired={Privileges?.UPDATE}
                    officeCode={props?.formState?.General_Contract_Information?.sanct_agency_id}
                    module="Contract Management"
                    fmis_function="CNTR_CONTRACT_CREATION"
                    fmis_sub_function="NA"
                    getRecord={getRecord}
                    getDisplayData={getDisplayData}
                    handleLoader={props.handleLoader}
                    screenName={'Contract'}
                    source_id={props.recID}
                />
            )}
        </div>
    );
};

export default ContractActions;
