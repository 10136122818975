import React from 'react';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'primereact/carousel';
import store from '../../index';
import { RoutingSchema } from './RoutingSchema';

const CarouselTemplate = (props) => {
    let history = useHistory();
    let dashboardType = history.location.pathname.split('/')[1];
    const setCards = (cards) => {
        let userPrivileges = store.getState()?.appReducer?.auth?.privileges;
        let subDashboardCards = [];
        cards &&
            cards.length > 0 &&
            cards.forEach((ele) => {
                if (
                    ele.type === RoutingSchema[dashboardType].name &&
                    (userPrivileges[ele.priv + '_C'] ||
                        userPrivileges[ele.priv + '_R'] ||
                        userPrivileges[ele.priv + '_D'] ||
                        userPrivileges[ele.priv + '_U'])
                ) {
                    subDashboardCards.push(ele);
                }
            });

        return subDashboardCards;
    };

    const cardsTemplate = (product) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className={`topcards${'-'}${product.icon}`}>
                        <div className="row m-0 height-top">
                            <div className="col-md-12 p-10 flex justify-content-center flex-d-c">
                                <b className="title fs-16">{product.name}</b>
                                <p className="m-0 label">
                                    My Pending Activities
                                    <span className="p-l-10 count">{product.count}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className={props.gridStyle === '1' ? 'row m-0 contract-grid w-100' : 'col-md-12 p-0'}>
            <div className="col-md-12 p-0">
                <img src={props.src} alt={props.alt} className={props.className} />
                <span className="contract-title">{props.title}</span>
            </div>
            <div className="col-md-12 p-0" id="show-curosel">
                <Carousel
                    value={setCards(props.subDashTopCards)}
                    numVisible={props.numVisible}
                    numScroll={props.numScroll}
                    responsiveOptions={props.responsiveOptions}
                    itemTemplate={cardsTemplate}
                />
            </div>
        </div>
    );
};

export default CarouselTemplate;
