import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import './FDataTable.scss';
import { Checkbox } from 'primereact/checkbox';
import Calendar from '../Calendar/CalendarComponent';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import FilterConfig from './TableFilterConfig';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { Tooltip } from 'primereact/tooltip';
import { convertTextCase, getDate, getDateOnly, serverDate } from '../../util';
import IsAuthorized from '../../commons/IsAuthorized';
import InputComponent from '../InputComponent/InputComponent';

const SecondaryDataTable = (props) => {
    const [selectedColumns, setColumns] = useState([...props.columns]);
    const [rows, setRows] = useState(props.rows);
    const [rowsCount, setRowsCount] = useState(10);
    const [first, setFirst] = useState(0);
    const [state, setState] = useState({
        disableActions: props.disableActions,
        searchRec: '',
        selectedRows: props.selectedRows,
        pageLength: props?.paginatorLength
    });
    useEffect(() => {
        setRows(props.rows);
        let tempState = {};
        selectedColumns.forEach((column) => {
            if (column.filterElement === 'dropdown') {
                tempState['selected' + column.field] = null;
            }
        });
        setColumns([...props.columns]);
        setState({ ...state, ...tempState, selectedRows: props.selectedRows });
        // eslint-disable-next-line
    }, [props]);

    let dt = useRef(null);

    const onClickSwitch = (e, column, rowData, index) => {
        if (column?.validation === 'unique' && rows.some((a) => a[column.field] && a.id !== rowData.id)) {
            props.handleActionsInParent(rowData, column, 'switch');
            return;
        }
        props.handleActionsInParent(rowData, column, 'switch', index);
    };
    const onClickAction = (rowData, column, index, action) => {
        rowData['index'] = index;
        props.handleActionsInParent(rowData, column, action);
    };
    const prepareActionTemplate = (rowData, column, index) => {
        return (
            <div>
                {column.view && (
                    <img
                        className="pointer m-r-15"
                        title="View"
                        src={process.env.PUBLIC_URL + '/assets/icons/eye.svg'}
                        alt="view Icon"
                        onClick={() => onClickAction(rowData, column, index, 'view')}
                    />
                )}
                {column.edit && props.formType !== 'view' && !rowData['hideEdit'] && (
                    <button
                        className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                        onClick={() => onClickAction(rowData, column, index, 'edit')}
                    >
                        <img
                            className="pointer"
                            title="Edit"
                            src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                            alt="Edit Icon"
                            height="20px"
                        />
                    </button>
                )}
                {column.editByPriv && (
                    <IsAuthorized
                        privRequired={props.Privileges?.UPDATE}
                        officeCode={props.Privileges?.officeCode}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                onClick={() => onClickAction(rowData, column, index, 'edit')}
                            >
                                <img
                                    className="pointer"
                                    title="Edit"
                                    src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                    alt="Edit Icon"
                                    height="20px"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {column.more && (
                    <button
                        className="a-btn-background-transparent a-icon-container m-l-5"
                        onClick={() => onClickAction(rowData, column, index, 'more')}
                    >
                        <span className="a-font-18 appkiticon icon-primary icon-horizontal-more-fill pointer" title="Details" />
                    </button>
                )}
                {column.settings && props.formType !== 'view' && (
                    <button
                        className="a-btn-background-transparent a-icon-container m-l-5"
                        onClick={() => onClickAction(rowData, column, index, 'settings')}
                    >
                        <img
                            className="pointer"
                            title="Edit"
                            src={process.env.PUBLIC_URL + '/assets/icons/settings.svg'}
                            alt="Edit Icon"
                            height="20px"
                        />
                    </button>
                )}
                {column.delete && props.formType !== 'view' && !rowData['hideDelete'] && (
                    <button
                        className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                        onClick={() => onClickAction(rowData, column, index, 'delete')}
                        disabled={rowData.is_primary}
                    >
                        <img
                            className="pointer"
                            height="20px"
                            title="Delete"
                            src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                            alt="Delete Icon"
                        />
                    </button>
                )}
                {column.deleteByPriv && (
                    <IsAuthorized
                        privRequired={props.Privileges?.DELETE}
                        officeCode={props.Privileges?.officeCode}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                onClick={() => onClickAction(rowData, column, index, 'delete')}
                                disabled={rowData.is_primary}
                            >
                                <img
                                    className="pointer"
                                    height="20px"
                                    title="Delete"
                                    src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                    alt="Delete Icon"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {rowData.edit && props.formType !== 'view' && (
                    <button
                        className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                        onClick={() => onClickAction(rowData, column, index, 'edit')}
                    >
                        <img
                            title="Edit"
                            className="pointer"
                            src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                            alt="Edit Icon"
                            height="20px"
                        />
                    </button>
                )}
                {rowData.delete && (
                    <button
                        className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                        onClick={() => onClickAction(rowData, column, index, 'delete')}
                    >
                        <img
                            className="pointer"
                            height="20px"
                            title="Delete"
                            src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                            alt="Delete Icon"
                        />
                    </button>
                )}
                {rowData.moreDetails && (
                    <button
                        className="a-btn-background-transparent a-icon-container m-l-5"
                        onClick={() => onClickAction(rowData, column, index, 'moreDetails')}
                    >
                        <span className="a-font-18 appkiticon icon-primary icon-horizontal-more-fill" title="Details" />
                    </button>
                )}
            </div>
        );
    };
    const getValue = (rowData, col) => {
        let values = col.field.split('.');
        let val = rowData;
        values.forEach((a) => {
            val = val && val[a];
        });
        return val;
    };
    const onColumnToggle = (event) => {
        let selCol = event.value;
        let orderedSelectedColumns = props.columns.filter((col) => selCol.includes(col));
        setColumns(orderedSelectedColumns);
    };
    const updateSelection = (e) => {
        setState({ ...state, selectedRows: e.value });
        // sending slected rows as input
        props.updateInparent(e.value);
    };
    const setTooltip = (rowData, column) => {
        let toolTip = '';
        if (column.field === 'is_primary') {
            toolTip = rowData[column.field] ? 'No' : 'Yes';
        } else {
            toolTip = rowData[column.field] ? 'Deactivate' : 'Activate';
        }
        return toolTip;
    };

    const onClickLabel = (row) => {
        if (props.handleRowItemClick) props.handleRowItemClick(row);
    };

    const onCheckPopup = (row) => {
        if (props.handleOpenPopup) props.handleOpenPopup(row);
    };
    const onPageWithoutLazyLoad = (event) => {
        setFirst(event.first);
        setRowsCount(event.rows);
        setState({ ...state, pageLength: event.rows });
    };
    const handleInputChange = (e, index, column) => {
        if (/^0/.test(e)) {
            e = e.replace(/^0/, '');
        }
        let editRows = rows;
        editRows[index.rowIndex][column.field] = e;
        let updateRows = [...editRows];
        setRows(updateRows);
        props.handleInputChange(e, index, column);
    };

    const abbreviationRec = (column, fieldValue, rowData) => {
        if (column.fieldType === 'abbreviation') {
            let abbrValRet = '';
            let val = rowData[column.field];
            let abbreviationTxt = val;
            if (Array.isArray(val)) {
                val = val.toString();
                abbreviationTxt = val?.split(',').join(', ');
                fieldValue =
                    val?.split(',')?.length > 1
                        ? val
                              .split(',')
                              .map((b) => (b = b.charAt(0).toUpperCase()))
                              .join(', ')
                        : val;
            } else {
                fieldValue = val ? val.charAt(0).toUpperCase() : val;
            }
            /* prettier-ignore */
            if (val?.split(',')?.length > 1) {
                abbrValRet = (
                    <div className="p-d-flex p-ai-center">
                        <Tooltip
                            target=".show-tooltip"
                            className="f-12 over-tooltip"
                            position="bottom"
                            appendTo={document.self}
                            autoZIndex={true}
                        />
                        <p className="m-0 p-ellipsis show-tooltip" data-pr-tooltip={`${abbreviationTxt}`}>
                            {fieldValue}
                        </p>
                    </div>
                );
            } else {
                abbrValRet = <p className="m-b-0">{fieldValue}</p>;
            }
            return abbrValRet;
        }
    };

    const prepareBody = (rowData, column, index) => {
        let colVal = rowData[column.field];
        let body = undefined;
        let fieldSplit = column.field?.split('.');
        let fieldValue = '';
        if (fieldSplit?.length === 2) {
            fieldValue = rowData?.[fieldSplit?.[0]]?.[fieldSplit?.[1]];
        } else if (fieldSplit?.length === 1) {
            fieldValue = rowData?.[fieldSplit?.[0]];
        } else if (fieldSplit?.length === 3) {
            fieldValue = rowData?.[fieldSplit?.[0]]?.[fieldSplit?.[1]]?.[fieldSplit?.[2]];
        }
        switch (column.fieldType) {
            case 'tooltip':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{fieldValue}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'dateConversion':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{column.dateOnly ? getDateOnly(fieldValue) : getDate(fieldValue)}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'abbreviation':
                body = abbreviationRec(column, fieldValue, rowData);
                break;
            case 'viewDetails':
                body = (
                    <div className="display-flex">
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-r-5 p-ellipsis">{fieldValue}</p>
                        </EllipsisWithTooltip>
                        {column?.displayLabel ? (
                            <button
                                className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                onClick={() => onClickAction(rowData, column, index, 'view_info')}
                            >
                                {column?.displayLabel}
                            </button>
                        ) : (
                            <>
                                {fieldValue && fieldValue !== '–' && (
                                    <button
                                        className="a-btn-background-transparent a-icon-container m-l-auto m-r-5"
                                        onClick={() => onClickAction(rowData, column, index, 'info')}
                                    >
                                        <img
                                            title="Info"
                                            className="pointer"
                                            src={process.env.PUBLIC_URL + '/assets/icons/info.svg'}
                                            alt="Info Icon"
                                            height="20px"
                                        />
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                );
                break;
            case 'addInfo':
                body = (
                    <div className="display-flex">
                        {column.showVal ? (
                            <EllipsisWithTooltip placement="auto">
                                <p className="m-0 p-r-5 p-ellipsis">{fieldValue}</p>
                            </EllipsisWithTooltip>
                        ) : (
                            <>
                                {fieldValue ? (
                                    <p className="m-0 p-r-5 p-ellipsis">{props.formType !== 'view' ? 'Added' : 'View ' + column.header}</p>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                        {fieldValue ? (
                            <button
                                className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                onClick={() => onClickAction(rowData, column, index, 'add_info')}
                            >
                                <img
                                    className="pointer"
                                    title={props.formType !== 'view' && column.edit ? 'Edit' : 'Info'}
                                    src={
                                        props.formType !== 'view' && column.edit
                                            ? process.env.PUBLIC_URL + '/assets/icons/edit.svg'
                                            : process.env.PUBLIC_URL + '/assets/icons/info.svg'
                                    }
                                    alt={props.formType !== 'view' && column.edit ? 'Edit Icon' : 'Info Icon'}
                                    height="20px"
                                />
                            </button>
                        ) : (
                            <>
                                {props.formType !== 'view' && column.edit && (
                                    <button
                                        className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                        onClick={() => onClickAction(rowData, column, index, 'add_info')}
                                    >
                                        + Add
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                );
                break;
            case 'addPlusInfo':
                body = (
                    <div className="display-flex">
                        {fieldValue && (
                            <>
                                <EllipsisWithTooltip placement="auto">
                                    <p className="m-0 p-r-5 p-ellipsis">{fieldValue}</p>
                                </EllipsisWithTooltip>
                                <button
                                    className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                    onClick={() => onClickAction(rowData, column, index, 'add_info')}
                                >
                                    <img
                                        className="pointer"
                                        title="Info"
                                        src={process.env.PUBLIC_URL + '/assets/icons/info.svg'}
                                        alt="Info Icon"
                                        height="20px"
                                    />
                                </button>
                            </>
                        )}
                        <>
                            {props.formType !== 'view' && (
                                <button
                                    className="a-btn-background-transparent icon-primary a-icon-container m-r-5"
                                    onClick={() => onClickAction(rowData, column, index, 'add_create')}
                                >
                                    <span className="f-16 plus_finding">+</span>
                                </button>
                            )}
                        </>
                    </div>
                );
                break;

            case 'addMultipleInfo':
                body = (
                    <>
                        {fieldValue?.map((fm, i) => (
                            <div key={fm.name} className="w-100 m-b-5 display-flex">
                                <p className=" m-b-5"> Added </p>
                                <button
                                    className="a-btn-background-transparent icon-primary a-icon-container m-l-10 m-r-5"
                                    onClick={() => onClickAction(rowData, column, i, 'add_info')}
                                >
                                    <img
                                        className="pointer"
                                        title="Info"
                                        src={process.env.PUBLIC_URL + '/assets/icons/info.svg'}
                                        alt="Info Icon"
                                        height="20px"
                                    />
                                </button>
                                {props.formType !== 'view' && !column.addnewhide && !column.hideEdit && (
                                    <button
                                        className="a-btn-background-transparent icon-primary a-icon-container m-l-10 m-r-5"
                                        onClick={() => onClickAction(rowData, column, i, 'add_edit')}
                                    >
                                        <img
                                            className="pointer"
                                            title="Edit"
                                            src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                            alt="Edit Icon"
                                            height="20px"
                                        />
                                    </button>
                                )}
                                {props.formType !== 'view' && fieldValue?.length - 1 === i && !column.addnewhide && (
                                    <button
                                        className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                        onClick={() => onClickAction(rowData, column, index, 'add_create')}
                                    >
                                        + Add
                                    </button>
                                )}
                            </div>
                        ))}
                        {props.formType !== 'view' && fieldValue?.length === 0 && !column.addnewhide && (
                            <button
                                className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                onClick={() => onClickAction(rowData, column, index, 'add_create')}
                            >
                                + Add
                            </button>
                        )}
                    </>
                );
                break;
            case 'editInfo':
                body = (
                    <div className="display-flex">
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-r-5 p-ellipsis">{fieldValue}</p>
                        </EllipsisWithTooltip>
                        {fieldValue && fieldValue !== '' ? (
                            <button
                                className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                onClick={() => onClickAction(rowData, column, index, 'edit_info')}
                            >
                                <img
                                    className="pointer"
                                    title={props.formType !== 'view' && column.edit ? 'Edit' : 'Info'}
                                    src={
                                        props.formType !== 'view' && column.edit
                                            ? process.env.PUBLIC_URL + '/assets/icons/edit.svg'
                                            : process.env.PUBLIC_URL + '/assets/icons/info.svg'
                                    }
                                    alt={props.formType !== 'view' && column.edit ? 'Edit Icon' : 'Info Icon'}
                                    height="20px"
                                />
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                );
                break;
            case 'checkbox':
                body = (
                    <div>
                        <Checkbox
                            checked={rowData[column.field]}
                            onChange={(e) => onClickCheckBox(e, column, rowData, index)}
                            disabled={
                                rows[index.rowIndex].checkboxDisable && (rows[index.rowIndex].isView || column.field !== 'is_visible')
                            }
                        ></Checkbox>
                    </div>
                );
                break;
            case 'checkboxLabel':
                body = (
                    <>
                        {props.formType !== 'view' ? (
                            <Checkbox
                                checked={rowData[column.field]}
                                disabled={rowData[column.disableKey]}
                                onChange={(e) => onClickCheckBox(e, column, rowData, index)}
                            ></Checkbox>
                        ) : (
                            <EllipsisWithTooltip placement="auto">
                                <p className="m-0 p-ellipsis">{rowData[column.field] === true ? 'Yes' : 'No'}</p>
                            </EllipsisWithTooltip>
                        )}
                    </>
                );
                break;
            case 'Boolean':
                body = (
                    <div className="display-flex">
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-ellipsis">{rowData[column.field] ? 'Yes' : 'No'}</p>
                        </EllipsisWithTooltip>
                    </div>
                );
                break;
            case 'Sl-No':
                body = <div className={`${column.textCenter ? 'text-center' : 'display-flex'}`}>{index?.rowIndex + 1}</div>;
                break;
            case 'button':
                if (column.buttonType === 'label') {
                    body = (
                        <div className="display-flex">
                            <u className="pointer table-col-link" onClick={() => onClickLabel(rowData)}>
                                {column.buttonLabel}
                            </u>
                        </div>
                    );
                } else if (column.buttonLabel) {
                    body = (
                        <span size="sm" kind="primary" className="pointer table-col-link">
                            {column.buttonLabel}
                        </span>
                    );
                } else if (column.buttonType === 'popupcheck') {
                    body = (
                        <div className="display-flex">
                            {rowData[column.field] !== 0 ? (
                                <u className="pointer table-col-link" onClick={() => onCheckPopup(rowData)}>
                                    {rowData[column.field]}
                                </u>
                            ) : (
                                <span>{rowData[column.field]}</span>
                            )}
                        </div>
                    );
                } else {
                    body = (
                        <span size="sm" kind="primary" className="pointer table-col-link" onClick={() => onClickLabel(rowData)}>
                            {rowData[column.field]}
                        </span>
                    );
                }
                break;
            case 'radio':
                body = (
                    <div>
                        <RadioButton
                            inputId="rb2"
                            value={rowData[column.field]}
                            name="functions"
                            checked={rowData[column.field]}
                            onChange={(e) => onClickRadio(e, column, rowData, index)}
                        />
                    </div>
                );
                break;
            case 'switch':
                body = (
                    <div>
                        {props.formType !== 'view' ? (
                            <InputSwitch
                                checked={rowData[column.field]}
                                onChange={(e) => onClickSwitch(e, column, rowData, index)}
                                title={setTooltip(rowData, column)}
                                className={`m-0 ${column.className ? column.className : ''}`}
                                disabled={props.disableActions(rowData, column)}
                            />
                        ) : rowData[column.field] === true ? (
                            'Yes'
                        ) : (
                            'No'
                        )}
                    </div>
                );
                break;
            case 'actions':
                body = prepareActionTemplate(rowData, column, index.rowIndex);
                break;

            case 'workflowStatus':
                body =
                    rowData[column.field]?.toLowerCase() === 'approved' ? (
                        <span className="Approved">
                            <EllipsisWithTooltip placement="auto">{convertTextCase(rowData[column.field], false)}</EllipsisWithTooltip>
                        </span>
                    ) : rowData[column.field]?.toLowerCase() === 'draft' ? (
                        <span className="Draft">
                            <EllipsisWithTooltip placement="auto">{convertTextCase(rowData[column.field], false)}</EllipsisWithTooltip>
                        </span>
                    ) : rowData[column.field]?.toLowerCase() === 'sent for approval' ? (
                        <span className="SentForApproval">
                            <EllipsisWithTooltip placement="auto">{rowData[column.field]}</EllipsisWithTooltip>
                        </span>
                    ) : rowData[column.field]?.toLowerCase() === 'sent for revision' ? (
                        <span className="SentForRevision">
                            <EllipsisWithTooltip placement="auto">{rowData[column.field]}</EllipsisWithTooltip>
                        </span>
                    ) : rowData[column.field]?.toLowerCase() === 'sent for verification' ? (
                        <span className="SentForVerification">
                            <EllipsisWithTooltip placement="auto">{rowData[column.field]}</EllipsisWithTooltip>
                        </span>
                    ) : rowData[column.field]?.toLowerCase() === 'rejected' || rowData[column.field]?.toLowerCase() === 'cancelled' ? (
                        <span className="Rejected">
                            <EllipsisWithTooltip placement="auto">{convertTextCase(rowData[column.field], false)}</EllipsisWithTooltip>
                        </span>
                    ) : (
                        <EllipsisWithTooltip placement="auto">{rowData[column.field]}</EllipsisWithTooltip>
                    );
                break;
            case 'view_clickable':
                if (column.field.indexOf('.') !== -1) {
                    colVal = (
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-ellipsis">{getValue(rowData, column)}</p>
                        </EllipsisWithTooltip>
                    );
                }
                body = (
                    <div>
                        {props.formType === 'view' && colVal !== 'NA' ? (
                            <div className="word-ellipses">
                                <span className="column-label" onClick={() => onClickAction(rowData, column, index.rowIndex, 'view_click')}>
                                    <EllipsisWithTooltip placement="auto"> {colVal}</EllipsisWithTooltip>
                                </span>
                            </div>
                        ) : (
                            <EllipsisWithTooltip placement="auto">
                                <p className="m-0 p-ellipsis"> {colVal}</p>
                            </EllipsisWithTooltip>
                        )}
                    </div>
                );
                break;
            case 'ref_click':
                body = (
                    <div className="word-ellipses">
                        <span className="column-label" onClick={() => onClickAction(rowData, column, index.rowIndex, 'view_click')}>
                            <EllipsisWithTooltip placement="auto"> {colVal}</EllipsisWithTooltip>
                        </span>
                    </div>
                );
                break;
            case 'islatest':
                if (column.field.indexOf('.') !== -1) {
                    colVal = getValue(rowData, column);
                }
                body = rowData.islatest ? (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{colVal}</p>
                    </EllipsisWithTooltip>
                ) : (
                    <EllipsisWithTooltip placement="auto">
                        <mark className="m-0 p-ellipsis">{colVal}</mark>
                    </EllipsisWithTooltip>
                );
                break;
            case 'date':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{rowData[column.field]}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'dateString':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{rowData[column?.field]?.toString()}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'appendLabel':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">
                            {rowData[column.field]}
                            {column.checkType !== rowData[column.checkKey] && column.appendLabel}
                        </p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'appendClick':
                body = (
                    <div className="display-flex">
                        <p className="m-0 p-ellipsis">
                            {rowData[column.field]} &nbsp;&nbsp;&nbsp;
                            <button
                                className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                onClick={() => onClickAction(rowData, column, index.rowIndex, 'append_info')}
                            >
                                {column.appendClickLabel && props.formType !== 'view' ? column.appendClickLabel : ''}
                            </button>
                        </p>
                    </div>
                );
                break;
            case 'inputComponent':
                body = (
                    <div>
                        {props.mode === 'view' ? (
                            rows[index.rowIndex][column.field]
                        ) : (
                            <>
                                {column?.inputMode === 'number' ? (
                                    <InputComponent
                                        placeholder={column.inputPlaceholder}
                                        name={column?.field}
                                        value={rows[index?.rowIndex][column?.field]}
                                        onChange={(e) => handleInputChange(e, index, column)}
                                        className="w-100"
                                        inputBoxSize="sm"
                                        numbers="true"
                                        type={column.inputType}
                                        maxLength={column.inputMaxlength || 50}
                                    />
                                ) : (
                                    <InputComponent
                                        name={column?.ddName}
                                        value={rows[index?.rowIndex][column?.field]}
                                        onChange={(e) => props.handleInputChange(e, index, column)}
                                        placeholder={column?.ddPlaceholder}
                                        className="w-100"
                                        inputBoxSize="sm"
                                        disabled={props.inputDisabled}
                                        type={column.inputType}
                                        maxLength={column.inputMaxlength || 50}
                                        hasError={
                                            props?.errors && props?.errors[index?.rowIndex] && props?.errors[index?.rowIndex][column?.field]
                                        }
                                        errMsg={props?.errMsg}
                                    />
                                )}
                            </>
                        )}
                    </div>
                );
                break;
            default:
                break;
        }
        return body;
    };

    const setOptions = (options) => {
        let obj = {};
        let optionsArr = [];
        if (options?.length > 0 && !FilterConfig[options]) {
            options.forEach((ele) => {
                obj = { label: ele, value: ele, id: ele };
                optionsArr.push(obj);
            });
        }
        return FilterConfig[options] ? FilterConfig[options] : optionsArr;
    };

    const onDropDownChange = (event, column) => {
        dt.current.filter(event.value, column.field, 'equals');
        setState({ ...state, ['selected' + column.field]: event.value });
    };

    const onSelectDate = (event, column) => {
        let dateVal = event.value ? getDate(event.value) : '';
        let val = dateVal.split(',');
        if (val.length >= 2) {
            dateVal = val[0] + ',' + val[1];
        }
        dt.current.filter(dateVal, column.field, 'contains');
        setState({ ...state, ['date' + column.field]: event.value });
    };

    const setFilterElement = (column) => {
        let filterElement = undefined;
        if (column.filterElement === 'dropdown') {
            filterElement = (
                <Dropdown
                    className="w-100"
                    placeholder={column.filterPlaceholder}
                    appendTo={document.self}
                    value={state['selected' + column.field]}
                    filter={true}
                    resetFilterOnHide={true}
                    filterPlaceholder="Search"
                    filterBy="label"
                    options={
                        setOptions(column.filterOptions).length > 0
                            ? setOptions(column.filterOptions)
                            : [{ label: 'No results found', value: 'No results found', disabled: true }]
                    }
                    onChange={(e) => onDropDownChange(e, column)}
                    showClear
                />
            );
        } else if (column.filterElement === 'date') {
            filterElement = (
                <Calendar
                    overlayVisible
                    maxDate={serverDate()}
                    appendTo={document.self}
                    value={state['date' + column.field]}
                    showButtonBar={true}
                    placeholder={column.filterPlaceholder}
                    dateFormat="M dd, yy"
                    showIcon
                    onChange={(e) => onSelectDate(e, column)}
                />
            );
        }
        return filterElement;
    };

    const onClickCheckBox = (e, column, rowData, index) => {
        props.handleChangeInParent('checkbox', e, rowData, index.rowIndex, column);
    };
    const onClickRadio = (e, column, rowData, index) => {
        props.handleChangeInParent('radio', e, rowData, index);
    };
    const renderColumn = (column, ind) => {
        return (
            <Column
                key={ind}
                field={column?.field}
                header={column?.header}
                filter={column?.filter}
                frozen={column.frozen}
                selectionMode={column?.selectionMode}
                bodyStyle={{ textAlign: column?.bodyStyle }}
                filterMatchMode={column.filterMatchMode}
                sortable={column?.sortable}
                sortableDisabled={column?.sortableDisabled}
                filterPlaceholder={column?.filterPlaceholder}
                className={column?.className ? column.className : null}
                body={column.fieldType ? (e, index) => prepareBody(e, column, index) : column?.body}
                filterElement={column?.filterElement !== null ? setFilterElement(column) : column?.filterElement}
                style={{ width: column?.width, textAlign: column?.headerAlign, paddingLeft: column?.paddingLeft }}
            />
        );
    };
    const footerTop = (
        <div className="comment-header">
            <div className="col-md-7">Total</div>
            <div className="col-md-5 p-l-25">{props.totalAmount}</div>
        </div>
    );
    return (
        <div className={`${props.loading ? 'mask' : ''}`}>
            {props?.columnSelection && (
                <div className="col-md-12 p-t-10">
                    <div className="fTableRight">
                        <MultiSelect
                            value={selectedColumns}
                            options={props.columns}
                            optionLabel="field"
                            onChange={onColumnToggle}
                            className="fMultiSel"
                        />
                    </div>
                </div>
            )}
            <div className={props?.className}>
                <DataTable
                    ref={dt}
                    dataKey={props.dataKey || 'id'}
                    rows={!props.noPaginator ? (state.pageLength ? state.pageLength : rowsCount) : null}
                    headerColumnGroup={props.headerColumnGroup}
                    footerColumnGroup={props.footerGroup}
                    onSelectionChange={(e) => updateSelection(e)}
                    scrollable={props.scrollable}
                    scrollHeight={props.scrollHeight}
                    resizableColumns={true}
                    columnResizeMode="fit"
                    reorderableColumns={true}
                    value={rows}
                    emptyMessage={props?.emptyMessage}
                    selection={state.selectedRows ? state.selectedRows : props.selectedRows}
                    selectionMode={props?.selectionMode ? props.selectionMode : null}
                    paginator={props.noPaginator ? false : props.rows && props.rows.length > 0 ? true : false}
                    paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords} Records"
                    rowGroupFooterTemplate={() => 'Records per page'}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    onPage={onPageWithoutLazyLoad}
                    first={first}
                    sortField={props.customSort ? props.sortField : null}
                    sortOrder={props.customSort ? props.sortOrder : null}
                    rowGroupMode={props.rowGroupMode ? props.rowGroupMode : null}
                    groupField={props.groupField ? props.groupField : null}
                >
                    {selectedColumns && selectedColumns.length > 0 && selectedColumns.map((column, ind) => renderColumn(column, ind))}
                </DataTable>
                {props.totalAmount && footerTop}
            </div>
        </div>
    );
};
export default SecondaryDataTable;
