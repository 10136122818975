import './Notifications.scss';
import React, { useState, useRef } from 'react';
import { Tabs, Tab, Button } from 'appkit-react';
import { Link, useHistory } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useLazyQuery, useQuery } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import { eventClient, iaClient } from '../../apollo';
import { getDate, getMonth, getDateVal } from '../../util';
import { takeActionNavigationForFmis, takeActionNavigationForAms, auditTypeName, getFmisFunctions } from '../../commons/commons';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Calendar from '../../components/Calendar/CalendarComponent';
import AAPEQueries from '../../pages/internalAudit/auditAssignmentPlanningAndExecution/AAPEQueries';

const ViewAllNotifications = () => {
    let history = useHistory();
    const [state, setState] = useState({
        loading: true,
        first: 0,
        rows: 10,
        page: 0,
        searchKey: '',
        notificationsData: [],
        showTakeAction: false,
        notificationsBeforeID: '',
        notificationsAfterID: '',
        pageType: '',
        selectedTab: 'active_notifications'
    });
    let dt = useRef(null);
    let userInfo = JSON.parse(localStorage.getItem('USER_INF'));
    let variable1 = { assigned_to: { equals: userInfo.user_name }, is_completed: { equals: 0 } };
    let variable2 = { type: { notIn: ['Workflow Task'] } };
    const fmisFunctions = getFmisFunctions();
    const [globalFilter, setGlobalFilter] = useState({ searchVal: '', dateVal: '' });
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [pageRows, setPageRows] = useState(10);
    const [searchFieldKey, setSearchFieldKey] = useState({});
    const [variables1, setVariable1] = useState(variable1);
    const [variables2, setVariable2] = useState(variable2);
    const [selectdNotif, setSelectdNotif] = useState({});
    const [tableLoader, setTableLoader] = useState(false);
    const [sortField1] = useState('created_at');
    const [sortField2] = useState('version_date');
    const [sortOrder] = useState('desc');

    useQuery(Schema.pending_with_self_task_total_count, {
        fetchPolicy: 'no-cache',
        client: eventClient,
        skip: state.selectedTab === 'active_notifications' ? false : true,
        variables: { where: variables1 },
        onCompleted: (resp) => {
            if (resp) setTotalRecords(resp.pending_with_self_task_total_count);
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    useQuery(Schema.total_notifications, {
        fetchPolicy: 'no-cache',
        client: eventClient,
        skip: state.selectedTab === 'notifications' ? false : true,
        variables: { where: variables2 },
        onCompleted: (resp) => {
            if (resp) setTotalRecords(resp.total_notifications.totalCount);
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const { refetch: activeNotications } = useQuery(Schema.dashboard_pending_tasks, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        skip: state.selectedTab === 'active_notifications' ? false : true,
        variables: { orderBy: { [sortField1]: sortOrder }, skip: skip, take: take, where: variables1 },
        onCompleted: (data) => {
            if (data && data.dashboard_pending_tasks) {
                prepareDate(data.dashboard_pending_tasks);
                prepareData(data);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
            setTableLoader(false);
        }
    });

    const { refetch: notifications } = useQuery(Schema.notifications, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        skip: state.selectedTab === 'notifications' ? false : true,
        variables: { orderBy: { [sortField2]: sortOrder }, skip: skip, take: take, where: variables2 },
        onCompleted: (data) => {
            if (data && data.notifications) {
                prepareDate(data.notifications);
                setState({ ...state, loading: false, notificationsData: data.notifications });
                setTableLoader(false);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
            setTableLoader(false);
        }
    });

    const prepareDate = (data) => {
        data.forEach((ele) => {
            ele.notif_date = getDate(ele.version_date);
        });
        return data;
    };

    const prepareData = (data) => {
        data.dashboard_pending_tasks.forEach((notif) => {
            let [module, moduleStatus] = notif.curr_phase_type.split(notif.id);
            notif.module = module;
            notif.moduleStatus = moduleStatus;
            return notif;
        });
        setState({ ...state, loading: false, notificationsData: data.dashboard_pending_tasks });
        setTableLoader(false);
    };

    const handleTabChange = (evt, tabValue) => {
        state.selectedTab = tabValue;
        setState({ ...state, selectedTab: state.selectedTab, loading: true });
        if (state.selectedTab === 'active_notifications') {
            activeNotications();
        } else {
            notifications();
        }
        setGlobalFilter({ searchVal: '', dateVal: '' });
        setSearchFieldKey({});
        setVariable1(variable1);
        setVariable2(variable2);
        setSkip(0);
        setTake(10);
        setPageRows(10);
    };

    const [getTaskNotifData] = useLazyQuery(Schema.annualAuditPlanInfo, {
        client: iaClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.annualAuditPlanInfo) {
                let val = data.annualAuditPlanInfo?.[0];
                localStorage.setItem('audit_assignment_type', val?.audit_assignment_type ? val?.audit_assignment_type : '');
                localStorage.setItem('audit_assignment_id', val?.audit_assignment_id ? val?.audit_assignment_id : '');
                localStorage.setItem('audit_assignment_name', val?.audit_assignment_type ? auditTypeName(val?.audit_assignment_type) : '');
                localStorage.setItem('aap_detail_id', val?.aap_detail_id ? val?.aap_detail_id : '');
                localStorage.setItem('aap_item_id', val?.aap_item_id ? val?.aap_item_id : '');
                localStorage.setItem('office_code', val?.iau_code ? val?.iau_code : '');
                let csVariables = '';
                if (val?.audit_assignment_id === '') {
                    csVariables = { aap_item_id: val?.aap_item_id };
                } else if (val?.aap_item_id === '') {
                    csVariables = { assignment_id: val?.audit_assignment_id };
                } else {
                    csVariables = { aap_item_id: val?.aap_item_id, assignment_id: val?.audit_assignment_id };
                }
                conclusionStatus({ variables: csVariables });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [conclusionStatus] = useLazyQuery(AAPEQueries.getConclusionStatus, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (resp) => {
            if (resp?.conclusionStatus) {
                localStorage.setItem('AAPE_CONCLUSION_STATUS', JSON.stringify(resp.conclusionStatus));
                localStorage.setItem('transaction_id', selectdNotif.id);
                takeActionNavigationForAms(history, selectdNotif);
                window.location.reload();
                setState({ ...state, loading: false });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const handleTakeAction = (e, task) => {
        setSelectdNotif(task);
        if (!window.INTERNAL_AUDIT) {
            takeActionNavigationForFmis(history, task);
        } else {
            let flag = false;
            fmisFunctions.map((ele) => {
                if (ele.code === task.fmis_function) flag = true;
            });
            if (flag) {
                getTaskNotifData({ variables: { where: { source_id: task.source_id, fmis_function: task.fmis_function } } });
            } else {
                takeActionNavigationForAms(history, task);
                localStorage.setItem('transaction_id', task.id);
            }
        }
    };

    const onPageChange = (event) => {
        setTableLoader(true);
        setSkip(event.first);
        setTake(event.rows);
        setPageRows(event.rows);
        if (state.selectedTab === 'active_notifications') {
            activeNotications();
        } else {
            notifications();
        }
    };

    const onFilter = (event) => {
        setTableLoader(true);
        let filterKeys = {};
        let keyData = { ...filterKeys };
        if (event?.filters?.globalFilter?.value?.searchVal) {
            filterKeys[state.selectedTab === 'active_notifications' ? 'id' : 'message'] = {
                contains: event.filters.globalFilter.value.searchVal.trim(),
                mode: 'insensitive'
            };
        }
        if (event?.filters?.globalFilter?.value?.dateVal) {
            const time = new Date(event.filters.globalFilter.value.dateVal);
            const date = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T00:00:00.000Z';
            const date2 = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T23:59:59.000Z';
            filterKeys['version_date'] = { gte: date, lte: date2 };
        }
        setSearchFieldKey(keyData);
        setSkip(0);
        setTake(10);
        setPageRows(10);
        /*
         * if (Object.keys(globalFilter).length === 1 && Object.keys(filterKeys).length === 0) {
         *     setTableLoader(false);
         * }
         */
        if (state.selectedTab === 'active_notifications') {
            setVariable1({ ...variable1, ...filterKeys });
            activeNotications();
        } else {
            setVariable2({ ...variable2, ...filterKeys });
            notifications();
        }
    };

    const titleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="notif_name notif-card">
                    {state.selectedTab === 'active_notifications' ? (
                        <div>
                            <span>{rowData.module}</span>
                            <span className="column-label" onClick={(e) => handleTakeAction(e, rowData)}>
                                {rowData.id}
                            </span>
                            <span className="p-l-5">{rowData.moduleStatus}</span>
                        </div>
                    ) : (
                        <div>{rowData.message}</div>
                    )}
                </div>
            </React.Fragment>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="notif-card">
                    <span>{rowData.notif_date}</span>
                </div>
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="notif-card word-space-align">
                    <Button size="md" kind="" className="outline-btn overlay_btn pointer" onClick={(e) => handleTakeAction(e, rowData)}>
                        Take Action
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left searchIconAlign w-70 p-r-10">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    autoComplete="off"
                    value={globalFilter.searchVal}
                    className="w-100 fs-15 overrideInput"
                    onChange={(e) => setGlobalFilter({ ...globalFilter, dateVal: '', searchVal: e.target.value })}
                    placeholder={state.selectedTab === 'active_notifications' ? 'Search by Transaction ID' : 'Search by Title'}
                />
            </span>
            <span className="p-input-icon-left overrideCalender w-30">
                <Calendar
                    showIcon
                    overlayVisible
                    showButtonBar={true}
                    dateFormat="M dd, yy"
                    className="w-100 fs-15"
                    appendTo={document.self}
                    placeholder="Search by Date"
                    value={globalFilter.dateVal}
                    onChange={(e) => setGlobalFilter({ ...globalFilter, searchVal: '', dateVal: e.target.value })}
                />
            </span>
        </div>
    );

    return (
        <div className="p-t-5 p-l-15 p-r-15 h-100 overflow-auto">
            <div className={`h-100 ${state.loading || tableLoader ? 'mask' : ''}`}>
                {(state.loading || tableLoader) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                <div className="coa-ht p-b-15">
                    <div className="page-header">
                        <span className="light-color">Task Notifications</span>
                        <span className="backBtn pointer">
                            <Link className="whiteLink" to="/dashboard">
                                <span className="appkiticon icon-left-chevron-outline icon_size" />
                                Back
                            </Link>
                        </span>
                    </div>
                    <div className="masters-div p-l-20 p-r-20 p-t-10">
                        <Tabs
                            defaultValue={state.selectedTab === 'active_notifications' ? 'active_notifications' : 'notifications'}
                            onChange={handleTabChange}
                        >
                            <Tab value="active_notifications" label="Active Notifications" />
                            <Tab value="notifications" label="Notifications" />
                        </Tabs>
                    </div>
                    {!state.loading && (
                        <div className="p-l-15 p-r-15">
                            <div className="row m-0">
                                <div className="col-md-12 p-0 notification-table">
                                    <DataTable
                                        ref={dt}
                                        rows={pageRows}
                                        header={header}
                                        onFilter={onFilter}
                                        filters={searchFieldKey}
                                        paginator={state.loading ? false : true}
                                        value={state.notificationsData}
                                        globalFilter={globalFilter}
                                        emptyMessage="Notifications are yet to be created."
                                        paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="{first} to {last} of {totalRecords} Records"
                                        rowGroupFooterTemplate={() => 'Records per page'}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        totalRecords={totalRecords}
                                        first={skip}
                                        sortField={state.selectedTab === 'active_notifications' ? sortField1 : sortField2}
                                        sortOrder={sortOrder === 'asc' ? 1 : -1}
                                        onPage={(e) => onPageChange(e)}
                                        lazy={true}
                                    >
                                        {!state.loading && (
                                            <Column
                                                field={`${state.selectedTab === 'active_notifications' ? 'curr_phase_type' : 'message'}`}
                                                body={titleBodyTemplate}
                                                className="w-60 p-l-10"
                                            />
                                        )}
                                        {!state.loading && (
                                            <Column field="notif_date" body={dateBodyTemplate} className="w-20 notif_date" />
                                        )}
                                        {!state.loading && state.selectedTab === 'active_notifications' && (
                                            <Column body={actionBodyTemplate} className="w-20 p-r-10" />
                                        )}
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default ViewAllNotifications;
