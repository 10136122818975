import React, { useState } from 'react';
import { Input, Button } from 'appkit-react';
import ReCAPTCHA from 'react-google-recaptcha';

function ForgotPassword(props) {
    let captcha = React.createRef();
    const [capState, setCapState] = useState(false);
    const handleCaptchaChange = () => {
        setCapState({ capState: false });
    };

    return (
        <div className="row m-0">
            <div className="col-md-12 text-center m-t-120">
                <h4>Recover your password</h4>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-30">
                <Input
                    inputBoxSize="lg"
                    key="username"
                    placeholder="User ID/Email ID"
                    type="text"
                    autoComplete="off"
                    value={props.properties.email}
                    onChange={(e) => props.handleChange(e, 'username')}
                />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-30">
                <div className="row text-center m-l-0">
                    {props.properties.username && (
                        <ReCAPTCHA size="normal" ref={captcha} sitekey={window._env_.SITE_KEY} onChange={handleCaptchaChange} />
                    )}
                </div>
                {!capState && props.properties.username && <p className="captcha-label m-t-20">Please select reCAPTCHA</p>}
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 text-center">
                <Button size="lg" className=" m-t-30" onClick={props.sendResetLink} disabled={!capState || !props.properties.username}>
                    Send Reset Link
                </Button>
            </div>
            <div
                className="pointer col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 p-20 m-t-10 primary-color text-center"
                onClick={props.backToLogin}
            >
                <span className="go-back-label">Go back to login</span>
            </div>
        </div>
    );
}

export default ForgotPassword;
