import React, { useState } from 'react';
import TableSchema from '../../CMTableSchema';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import FormSchema from '../formSchema';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'appkit-react';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import DeleteConfirmModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import TextAreaComponent from '../../../../components/InputComponent/TextAreaComponent';
import RemarksModal from './RemarksModal';
import { serverDate } from '../../../../util';

const OtherDeductionForm = (props) => {
    let deducOpts = props.deductions;
    let viewMode = TableSchema.CONTCT_OTHER_DEDUCTIONS.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.formType !== 'view' ? TableSchema.CONTCT_OTHER_DEDUCTIONS : viewMode;
    let currency = localStorage.getItem('CURRENCY') ? localStorage.getItem('CURRENCY') : 'INR';

    const [formState, setFormState] = useState({
        rows: props.Other_Deductions?.rows,
        totalAmount: props?.Other_Deductions?.totalAmount || ''
    });
    const [state, setState] = useState({
        columns: tablecolms,
        deductionOptions: getdeductionOptions(),
        currentDate: serverDate(),
        showRemarksModal: false
    });

    function getdeductionOptions() {
        let prepData = JSON.parse(JSON.stringify(deducOpts));
        prepData.forEach((a) => {
            a.amount = a.value;
            delete a.value;
        });
        let list = JSON.parse(JSON.stringify(prepData));
        list = list.filter((a) => {
            return !formState.rows.find((b) => b?.deduction.ref === a.ref);
        });
        return list;
    }

    let emptyObj = {
        slno: '',
        deduction: '',
        dedType: '',
        amount: '',
        remarks: '',
        islatest: true
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});

    const addNewRow = () => {
        state.deductionOptions = getdeductionOptions();
        currentDetailsObj['slno'] = formState.rows.length + 1;
        setCurrentDetailsObj(currentDetailsObj);
        setState({ ...state, showForm: true, deductionOptions: state.deductionOptions });
    };

    function typeOfDeduction(data) {
        let type = 'cntrct_val_inc_tax';
        if (data.exceptions?.find((a) => a.fmis_function === 'Create Contract')) {
            type = 'cntrct_val_exc_tax';
        }
        return type;
    }

    const handleChange = (ev, key) => {
        let pattern = new RegExp(/^\d*(\.\d{0,2})?$/g);
        if (key === 'amount' && (isNaN(ev) || ev.indexOf(' ') >= 0 || parseInt(ev) === 0 || !pattern.test(ev))) {
            return;
        }
        let amt;
        if (key === 'deduction') {
            let type = '';
            currentDetailsObj.remarks = '';
            switch (ev.type) {
                case 'Percentage':
                    type = typeOfDeduction(ev);
                    amt = (parseFloat(ev.amount) / 100) * parseFloat(props.selectedRow[type]);
                    amt = isNaN(amt) ? '' : amt.toFixed(2);
                    currentDetailsObj.amount = amt.toString();
                    break;
                case 'Fixed':
                    currentDetailsObj.amount = ev?.amount?.toString();
                    break;
                case 'Manual':
                    currentDetailsObj.amount = '';
                    break;
                default:
                    break;
            }
        }
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            if (!newObj.islatest) {
                let prepData = JSON.parse(JSON.stringify(deducOpts));
                prepData.forEach((a) => {
                    a.amount = a.value;
                    delete a.value;
                });
                let deduction = prepData.find((a) => a.ref === newObj.deduction.ref);
                newObj.deduction = deduction;
                newObj.dedType = deduction?.type;
                newObj.amount = deduction?.amount;
                newObj.islatest = true;
            }
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);

            setState({ ...state, showForm: true, actionType: sAction, deductionOptions: [...getdeductionOptions(), newObj.deduction] });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const saveCntrctDetails = () => {
        let amount = 0;
        formState.rows.forEach((a) => {
            if (state.actionType !== 'edit' || (state.actionType === 'edit' && a.deduction.ref !== editObj.deduction.ref))
                amount = amount + parseFloat(a.amount);
        });
        amount = amount + parseFloat(currentDetailsObj.amount);
        let type = typeOfDeduction(currentDetailsObj.deduction);
        if (props.selectedRow[type] < amount) {
            props.growl.show({
                life: 6000,
                severity: 'error',
                summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.TotalDeductions
            });
            return;
        }
        formState.totalAmount = amount;
        let typeval =
            currentDetailsObj?.deduction?.type +
            (currentDetailsObj.deduction.type === 'Percentage' ? '(' + currentDetailsObj.deduction.amount + ')' : '');
        currentDetailsObj.dedType = typeval;
        if (state.actionType === 'edit') {
            let indx = formState.rows.findIndex((a) => a.deduction.ref === editObj.deduction.ref);
            formState.rows[indx] = currentDetailsObj;
            setFormState({ ...formState });
        } else {
            formState.rows.push(currentDetailsObj);
            setFormState({ ...formState });
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Other_Deductions', formState);
    };
    const handleDeleteRow = () => {
        formState.totalAmount = parseFloat(formState.totalAmount) - parseFloat(currentDetailsObj.amount);
        formState.rows = formState.rows.filter((a) => {
            return a.deduction.id !== currentDetailsObj.deduction.id;
        });
        setFormState({ ...formState, rows: formState.rows, totalAmount: formState.totalAmount });
        props.saveFormData('Other_Deductions', formState);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        handleCancel('showDeleteModal');
    };

    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={3} />
                <Column footer={formState.totalAmount > 0 && formState.totalAmount} colSpan={1} footerStyle={{ textAlign: 'right' }} />
                {props.formType !== 'view' && <Column />}
            </Row>
        </ColumnGroup>
    );

    return (
        <div className={'coa-card p-10'}>
            <SecondaryDataTable
                rows={formState?.rows}
                columns={state.columns}
                emptyMessage="Other Deductions are yet to be created"
                className="secondary-table inner-table-overflow"
                noPaginator={true}
                handleActionsInParent={handleActions}
                footerGroup={footerGroup}
            />
            {props.formType !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={`comment-color pointer  ${state.showForm ? 'disabled' : ''}`}>
                        + Add New
                    </span>
                </div>
            )}
            <div>
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_OTH_DEDUCTION.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_OTH_DEDUCTION.name}
                                        {FormSchema.FLD_CD_OTH_DEDUCTION.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <Dropdown
                                        name="deduction"
                                        value={currentDetailsObj.deduction}
                                        onChange={(e) => handleChange(e.value, 'deduction')}
                                        appendTo={document.body}
                                        options={state.deductionOptions}
                                        placeholder="Select Deduction"
                                        className="w-60"
                                        optionLabel="name"
                                    />
                                </div>
                            )}
                            {FormSchema.FLD_CD_OTH_DED_RATE.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_OTH_DED_RATE.name}
                                        {FormSchema.FLD_CD_OTH_DED_RATE.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <div className={'input-div'}>
                                        <span className="opacity-5 ellipses h1-name">
                                            {currentDetailsObj?.deduction?.type
                                                ? currentDetailsObj?.deduction?.type +
                                                  (currentDetailsObj.deduction.type === 'Percentage'
                                                      ? '(' + currentDetailsObj.deduction.amount + ')'
                                                      : '')
                                                : '–'}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_OTH_DED_AMOUNT.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_OTH_DED_AMOUNT.name}
                                        {FormSchema.FLD_CD_OTH_DED_AMOUNT.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    {currentDetailsObj?.deduction?.type === 'Manual' ? (
                                        <InputComponent
                                            className="w-60"
                                            inputBoxSize="sm"
                                            name="amount"
                                            type="text"
                                            value={currentDetailsObj.amount}
                                            maxLength={15}
                                            onChange={(e) => handleChange(e, 'amount')}
                                            placeholder={'Amount (' + currency + ')'}
                                            disabled={!currentDetailsObj.deduction}
                                        />
                                    ) : (
                                        <div className={'input-div'}>
                                            <span className="opacity-5 ellipses h1-name">{currentDetailsObj.amount}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                            {FormSchema.FLD_CD_OTH_DED_REMARKS.is_visible !== -1 && currentDetailsObj.remarks && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_OTH_DED_REMARKS.name}
                                        {FormSchema.FLD_CD_OTH_DED_REMARKS.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <TextAreaComponent
                                        className="w-60"
                                        textBoxSize="lg"
                                        name="remarks"
                                        type="text"
                                        value={currentDetailsObj.remarks}
                                        placeholder="Remarks"
                                        readOnly={true}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="m-t-10">
                            <Button
                                size="sm"
                                kind="primary"
                                className="m-r-10"
                                onClick={() => saveCntrctDetails()}
                                disabled={!currentDetailsObj.deduction}
                            >
                                {state.actionType === 'edit' ? 'Update Deduction' : 'Add Deduction'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
                {state.showDeleteModal && (
                    <DeleteConfirmModal
                        handleDelete={handleDeleteRow}
                        handleCancel={() => handleCancel('showDeleteModal')}
                        type="Other Deductions"
                        showModal={state.showDeleteModal}
                        name={'Deduction : ' + currentDetailsObj.deduction.name}
                    />
                )}
                {state.showRemarksModal && (
                    <RemarksModal
                        currentDetailsObj={currentDetailsObj}
                        handleCancel={() => handleCancel('showRemarksModal')}
                        type="Remarks"
                        showModal={state.showRemarksModal}
                        handleSave={(remarks, amount) => {
                            setCurrentDetailsObj({ ...currentDetailsObj, remarks: remarks, amount: amount });
                            handleCancel('showRemarksModal');
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default OtherDeductionForm;
