import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/IsAuthorized';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import CreateEmployee from '../pages/employeeManagement/EmployeeMgmt/EmployeeDetails/IA/CreateEmployee';
const EmployeeDetails = lazy(() => import('../pages/employeeManagement/EmployeeMgmt/EmployeeDetails/EmployeeDetails'));
const CreateEmpDetails = lazy(() => import('../pages/employeeManagement/EmployeeMgmt/EmployeeDetails/FMIS/CreateEmpDetails'));
const PaySlips = lazy(() => import('../pages/employeeManagement/EmployeeMgmt/EmployeeDetails/FMIS/PaySlips'));
const EntitlementChange = lazy(() => import('../pages/employeeManagement/EmployeeMgmt/EntitlementChange/EntitlementChange'));
const AddEntitlementChange = lazy(() => import('../pages/employeeManagement/EmployeeMgmt/EntitlementChange/AddEntitlementChange'));
const PayrollVoucher = lazy(() => import('../pages/expenditureManagement/bills-management/PayrollVoucher/PayrollVoucher'));
const AddPayrollVoucher = lazy(() => import('../pages/expenditureManagement/bills-management/PayrollVoucher/AddPayrollVoucher'));
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const employeeManagementItems = MenuItemsSchema.EMPLOYEE_MANAGEMENT;
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const employeeManagmentBreadCrmb = (props) => {
    let employeeMasterValues = [{ name: 'Employee Management', redirectTo: '/employeemanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/empfunction/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    employeeMasterValues = [...employeeMasterValues, ...obj];
    return employeeMasterValues;
};
const EmployeeMangementRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={employeeManagmentBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={PayrollVoucher}
                    exact
                    path={`${props?.path}/payrollvoucher`}
                    name="Payroll Voucher"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayrollVoucher}
                    exact
                    path={`${props?.path}/payrollvoucher/create`}
                    name="Create Payroll Voucher"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayrollVoucher}
                    exact
                    path={`${props?.path}/payrollvoucher/edit/:id`}
                    name="Edit Payroll Voucher"
                    menuItems={AddPayrollVoucher}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayrollVoucher}
                    exact
                    path={`${props?.path}/payrollvoucher/view/:id/draft`}
                    name="Edit Payroll Voucher"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayrollVoucher}
                    exact
                    path={`${props?.path}/payrollvoucher/view/:id`}
                    name="View Payroll Voucher"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={EmployeeDetails}
                    exact
                    path={`${props?.path}/empdetails`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmpDetails}
                    exact
                    path={`${props?.path}/empdetails/create`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmpDetails}
                    exact
                    path={`${props?.path}/empdetails/view/:id`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmpDetails}
                    exact
                    path={`${props?.path}/empdetails/edit/:id`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmpDetails}
                    exact
                    path={`${props?.path}/empdetails/view/:id/draft`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmpDetails}
                    exact
                    path={`${props?.path}/empdetails/edit/:id/draft`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={PaySlips}
                    exact
                    path={`${props?.path}/empdetails/payslips/:id`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={EntitlementChange}
                    exact
                    path={`${props?.path}/payentitlementchange`}
                    name="Pay Entitlement Change"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEntitlementChange}
                    exact
                    path={`${props?.path}/payentitlementchange/create`}
                    name="Add Pay Entitlement Change"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEntitlementChange}
                    exact
                    path={`${props?.path}/payentitlementchange/edit/:id`}
                    name="Add Pay Entitlement Change"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEntitlementChange}
                    exact
                    path={`${props?.path}/payentitlementchange/edit/:id/draft`}
                    name="Add Pay Entitlement Change"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEntitlementChange}
                    exact
                    path={`${props?.path}/payentitlementchange/view/:id`}
                    name="Add Pay Entitlement Change"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEntitlementChange}
                    exact
                    path={`${props?.path}/payentitlementchange/view/:id/draft`}
                    name="Add Pay Entitlement Change"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={EmployeeDetails}
                    exact
                    path={`${props?.path}/employee`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmployee}
                    exact
                    path={`${props?.path}/employee/create`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmployee}
                    exact
                    path={`${props?.path}/employee/view/:id`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmployee}
                    exact
                    path={`${props?.path}/employee/view/:id/approved`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmployee}
                    exact
                    path={`${props?.path}/employee/edit/:id`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmployee}
                    exact
                    path={`${props?.path}/employee/edit/:id/approved`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmployee}
                    exact
                    path={`${props?.path}/employee/view/:id/draft`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmployee}
                    exact
                    path={`${props?.path}/employee/edit/:id/draft`}
                    name="Employee Details"
                    menuItems={employeeManagementItems}
                />
            </Switch>
        </div>
    );
};
export default EmployeeMangementRouting;
