import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/IsAuthorized';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import CreateContract from '../pages/contractManagement/ContractCreation/CreateContract';
const SupplierRegistration = lazy(() => import('../pages/contractManagement/supplierManagement/supplierRegistration/SupplierRegistration'));
const AddSupplyRegistration = lazy(() =>
    import('../pages/contractManagement/supplierManagement/supplierRegistration/AddSupplierRegistration')
);
const SupplierCategory = lazy(() =>
    import('../pages/contractManagement/supplierManagement/configureSupplierCategory/ConfigureSupplierCategoryHOC')
);
const AddSupplierCategory = lazy(() =>
    import('../pages/contractManagement/supplierManagement/configureSupplierCategory/AddSupplierCategory')
);
const CreateContractBills = lazy(() => import('../pages/expenditureManagement/bills-management/contract-bills/CreateContractBill'));
const CreateOtherBill = lazy(() => import('../pages/expenditureManagement/bills-management/other-bills/CreateOtherBill'));

const setBreadCrumbItems = (props, path) => {
    let AllCoaBreadcrumbValues = [{ name: 'Supplier Management', redirectTo: '/contractmanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: path + '/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '', key: subPath[2] }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};
const supplierManagementItems = MenuItemsSchema.SUPL_MNGMNT;
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};

const SupplierMangRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={setBreadCrumbItems(props, props?.path)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={SupplierRegistration}
                    exact
                    path={`${props?.path}/supplierregistration`}
                    name="Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplyRegistration}
                    exact
                    path={`${props?.path}/supplierregistration/create`}
                    name="Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplyRegistration}
                    exact
                    path={`${props?.path}/supplierregistration/edit/:id`}
                    name="Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplyRegistration}
                    exact
                    path={`${props?.path}/supplierregistration/view/:id`}
                    name="Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplyRegistration}
                    exact
                    path={`${props?.path}/supplierregistration/view/:id/approved`}
                    name="Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplyRegistration}
                    exact
                    path={`${props?.path}/supplierregistration/edit/:id/draft`}
                    name="Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplyRegistration}
                    exact
                    path={`${props?.path}/supplierregistration/view/:id/existdraft`}
                    name="Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplyRegistration}
                    exact
                    path={`${props?.path}/supplierregistration/edit/:id/approved`}
                    name="Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplyRegistration}
                    exact
                    path={`${props?.path}/supplierregistration/view/:id/existapproved`}
                    name="Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplyRegistration}
                    exact
                    path={`${props?.path}/supplierregistration/view/:id/draft`}
                    name="Add Supplier Registration"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={SupplierCategory}
                    exact
                    path={`${props?.path}/configuresuppliercategory`}
                    name="Supplier Category"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplierCategory}
                    exact
                    path={`${props?.path}/configuresuppliercategory/create`}
                    name="Supplier Category"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplierCategory}
                    exact
                    path={`${props?.path}/configuresuppliercategory/edit/:id`}
                    name="Supplier Category"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplierCategory}
                    exact
                    path={`${props?.path}/configuresuppliercategory/view/:id`}
                    name="Supplier Category"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplierCategory}
                    exact
                    path={`${props?.path}/configuresuppliercategory/:id/draft`}
                    name="Supplier Category"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplierCategory}
                    exact
                    path={`${props?.path}/configuresuppliercategory/view/:id/draft`}
                    name="Supplier Category"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSupplierCategory}
                    exact
                    path={`${props?.path}/configuresuppliercategory/edit/:id/draft`}
                    name="Supplier Category"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateContract}
                    exact
                    path={`${props?.path}/supplierregistration/contracts/:id`}
                    menuItems={supplierManagementItems}
                />

                <AuthGaurd
                    {...props}
                    component={CreateOtherBill}
                    exact
                    path={`${props?.path}/supplierregistration/otherbills/view/:id`}
                    name="View Other Bill"
                    menuItems={supplierManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateContractBills}
                    exact
                    path={`${props?.path}/supplierregistration/contractbills/view/:id`}
                    name="View Contract Bill"
                    menuItems={supplierManagementItems}
                />
            </Switch>
        </div>
    );
};
export default SupplierMangRouting;
