import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../../TableSchema';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../../components/fDataTable/SecondaryDataTable';
import ConfirmationModal from '../../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../../Validation';
import { uniqueId, serverDate } from '../../../../../util';
import _ from 'lodash';
import TextAreaComponent from '../../../../../components/InputComponent/TextAreaComponent';

const EmployeeQualifications = (props) => {
    const [rows, setRows] = useState(props.formStateObj?.Qualificiations);
    let viewMode = TableSchema.Employee_Qualificiations.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.formType !== 'view' ? TableSchema.Employee_Qualificiations : viewMode;
    let currYear = serverDate().getFullYear();
    let fromYear = parseInt(currYear) - 65;

    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false,
        qualificationList: ['Below SSLC', 'SSLC', 'HSC', 'Graduate', 'Post Graduate', 'Masters', 'Professional Course'],
        yearsList: _.range([fromYear], currYear + 1)
    });

    let emptyObj = {
        qualification: '',
        university: '',
        certificate_number: '',
        year_passing: '',
        grade: '',
        percentage: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setRows(props.formStateObj?.Qualificiations);
        // eslint-disable-next-line
    }, [props.formStateObj?.Qualificiations]);

    const fields = [
        { key: 'certificate_number', validators: 'alphaNumeric', maxLength: 30 },
        { key: 'percentage', validators: 'percentage' },
        { key: 'grade', validators: 'grade' }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);

        if (
            err[key] ||
            ((key === 'certificate_number' || key === 'percentage') && parseInt(ev) <= 0) ||
            (typeof ev === 'string' && ev.trim() === '')
        ) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const saveForm = () => {
        if (state.actionType === 'edit') {
            let indx = rows.findIndex((a) => a.id === editObj.id);
            rows[indx] = currentDetailsObj;
            setRows(rows);
        } else {
            currentDetailsObj.id = uniqueId(20);
            rows.push(currentDetailsObj);
            setRows(rows);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Qualificiations', rows);
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                currentDetailsObj[key] === undefined ||
                currentDetailsObj[key] === '' ||
                (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === '')
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = rows.filter((a) => {
            return a.id !== currentDetailsObj.id;
        });
        setRows(filteredRows);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Qualificiations', filteredRows);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Qualifications are yet to be created"
                    className="secondary-table"
                    scrollable
                    mode={props?.formType}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Qualification{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="qualification"
                                            value={currentDetailsObj.qualification}
                                            onChange={(e) => handleChange(e.value, 'qualification')}
                                            appendTo={document.self}
                                            options={state.qualificationList}
                                            placeholder="Select Qualification"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            University
                                            {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        {window.INTERNAL_AUDIT ? (
                                            <TextAreaComponent
                                                textBoxSize="lg"
                                                name="university"
                                                value={currentDetailsObj.university}
                                                maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                                rows="3"
                                                onChange={(e) => handleChange(e, 'university')}
                                                placeholder="Enter University"
                                                validations={['alphaNumeric']}
                                                hasError={errors && errors.university}
                                                errMsg="Please enter valid University"
                                            />
                                        ) : (
                                            <InputComponent
                                                inputBoxSize="sm"
                                                name="university"
                                                type="text"
                                                value={currentDetailsObj.university}
                                                maxLength={window.INTERNAL_AUDIT ? window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT : '30'}
                                                alphanumeric={true}
                                                onChange={(e) => handleChange(e, 'university')}
                                                placeholder="Enter University"
                                                hasError={errors && errors.university}
                                                errMsg="Please enter valid University"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Certificate Number
                                            {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="certificate_number"
                                            type="text"
                                            value={currentDetailsObj.certificate_number}
                                            maxLength={30}
                                            onChange={(e) => handleChange(e, 'certificate_number')}
                                            placeholder="Enter Certificate Number"
                                            hasError={errors && errors.certificate_number}
                                            errMsg="Please enter valid Certificate Number"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Year of Passing
                                            {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="year_passing"
                                            value={currentDetailsObj.year_passing}
                                            onChange={(e) => handleChange(e.value, 'year_passing')}
                                            appendTo={document.self}
                                            options={state.yearsList}
                                            placeholder="Select Year of Passing"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Grade
                                            {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="grade"
                                            type="text"
                                            value={currentDetailsObj.grade}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e, 'grade')}
                                            placeholder="Enter Grade"
                                            hasError={errors && errors.grade}
                                            errMsg="Please enter valid Grade"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Percentage
                                            {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="percentage"
                                            type="text"
                                            value={currentDetailsObj.percentage}
                                            maxLength={3}
                                            onChange={(e) => handleChange(e, 'percentage')}
                                            placeholder="Enter Percentage"
                                            hasError={errors && errors.percentage}
                                            errMsg="Please enter valid Percentage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.formType !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (rows?.length === 0 ? '' : 'Another ') + 'Qualifications'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Qualifications"
                    showModal={state.showDeleteModal}
                    name={'Qualifications : ' + currentDetailsObj.qualification}
                />
            )}
        </div>
    );
};

export default EmployeeQualifications;
