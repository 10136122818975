export default function (state = { spinner: false, growl: '', taskNotificationCount: 0 }, action = { type: '' }) {
    switch (action.type) {
        case 'MENU_CLICK':
            return { ...state, activeMenu: action.activeMenu, breadcrumb: action.breadcrumb };
        case 'SPINNER':
            return { ...state, spinner: action.spinner };
        case 'GROWL':
            return { ...state, growl: action.growl };
        case 'AUTH':
            return { ...state, auth: action.auth };
        default:
            return state;
    }
}
