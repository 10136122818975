import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import '../../../styles/Dialog.scss';

export function ContractTypeModal(props) {
    return (
        <Modal visible={props.showModal} onCancel={() => props.handleCancel()} className="common_css add_user" backdropClosable={false}>
            <ModalHeader>{props.headerText}</ModalHeader>
            <ModalBody>
                <div>{props.bodyText}</div>
            </ModalBody>
            <ModalFooter className="p-10">
                <Button size="sm" kind="primary" className="m-r-10" onClick={() => props.handleYes()}>
                    Yes
                </Button>
                <Button size="sm" kind="secondary" className="" onClick={() => props.handleCancel()}>
                    No
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ContractTypeModal;
