import React, { useState } from 'react';
import TableSchema from '../../CMTableSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import FormSchema from '../formSchema';
import { Button } from 'appkit-react';
import Calendar from '../../../../components/Calendar/CalendarComponent';
import DeleteConfirmModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import { Toast } from 'primereact/toast';
import AdvanceRecoveryModal from './AdvanceRecoveryModal';
import OtherDeductionModal from './OtherDeductionModal';
import { getDate, serverDate } from '../../../../util';
import HOADetailsModal from '../../../../components/HOADetails/HOADetailsModal';
import Schema from '../../../../schema/AppSchema';
import { Dropdown } from 'primereact/dropdown';
import * as FormValidation from '../../../../Validation';

const PaymentScheduleForm = (props) => {
    let GenFormDetails = props.finalData?.General_Contract_Information;
    let CNTRCT_DETAILS = props.finalData.Contract_Details;
    let tableSchema = TableSchema.CONTCT_PAY_SCHEDULE;

    let viewMode = tableSchema.filter((a) => a.fieldType !== 'actions');
    if (props.selectedRow?.sup_cat?.id) {
        tableSchema = tableSchema.filter((a) => a.field !== 'hoa');
    }
    let tablecolms = props.formType !== 'view' ? tableSchema : viewMode;
    let currYear = serverDate().getFullYear();
    let signingDateRange = currYear + ':' + (parseInt(currYear) + 20).toString();
    const [state, setState] = useState({
        columns: tablecolms,
        advRows: [],
        showAdvModal: false,
        showDeducModal: false,
        showHoaModal: false,
        deductionRows: [],
        hoaOptions: CNTRCT_DETAILS.rows.map((a) => a.hoa)
    });

    const [finYearAmount, setFinYearAmount] = useState();
    const [formState, setFormState] = useState({
        rows: props.moreDetailsObj?.Payment_Schedule?.rows || []
    });
    const [errors, setErrors] = useState(false);

    const [growl, setGrowl] = useState();
    let emptyObj = {
        slno: '',
        pay_milestone: '',
        due_date: '',
        amt_exc_tax: '',
        amt_inc_tax: '',
        adavce_recovery: '',
        security_depst_dedct: '',
        other_deduction: '',
        net_pay_amt: '',
        advance_recoveries: [],
        deduction_details: [],
        hoa: '',
        delete: false
    };
    let currency = localStorage.getItem('CURRENCY') ? localStorage.getItem('CURRENCY') : 'INR';

    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});

    // preapare Advance Recovories for selected row and return sum of all recovories
    function getRecoveryTotal(value) {
        let amount = 0;
        let advRows = [];
        if (props.moreDetailsObj?.Advance?.rows?.length > 0) {
            props.moreDetailsObj.Advance.rows.forEach((a, index) => {
                let val = (parseFloat(value) / 100) * parseFloat(a.adv_rate);
                if (!isNaN(val)) amount = amount + val;
                advRows.push({
                    slno: index + 1,
                    adv_type: a.adv_type.name,
                    edit: a.adv_rec_mthd === 'Manual' ? true : false,
                    enableEdit: false,
                    adv_inc_amount: !isNaN(val) ? val.toFixed(2) : '',
                    adv_rec_mthd: a.adv_rec_mthd,
                    is_edited: a.adv_rec_mthd === 'Manual' ? true : false,
                    id: a.adv_type.id
                });
            });
            setState((prevState) => {
                return {
                    ...prevState,
                    advRows: advRows
                };
            });
        }
        if (isNaN(amount) || (!isNaN(amount) && parseFloat(amount) <= 0)) amount = '';
        else amount = amount.toFixed(2);
        return amount;
    }
    // calculate security deposit deduction rate on incl of taxes and return value
    function getSecurityRate(value) {
        let val = (parseFloat(value) / 100) * parseFloat(props?.moreDetailsObj?.Security_Deposit_Deduction?.scc_depo_deduc_rate?.rate);
        if (isNaN(val)) val = '';
        else val = val.toFixed(2);
        return val;
    }

    // other deductions prepare table rows and based on type(incl or excl) return sum of rows
    const calculateDeductions = (item, incVal, excVal) => {
        let val = 0;
        if (item?.deduction?.exceptions?.length > 0 && item.deduction.exceptions?.find((a) => a.fmis_function === 'Create Contract')) {
            val = (parseFloat(excVal) / 100) * parseFloat(item.deduction.amount);
        } else {
            val = (parseFloat(incVal) / 100) * parseFloat(item.deduction.amount);
        }
        return val;
    };

    function getOtherDeductionRate(incVal, excVal) {
        let amount = 0;
        let deductionRows = [];
        if (props.moreDetailsObj?.Other_Deductions?.rows?.length > 0 && incVal && excVal) {
            props.moreDetailsObj.Other_Deductions.rows.forEach((a, index) => {
                let val = a.deduction?.type !== 'Percentage' ? parseFloat(a.amount) : calculateDeductions(a, incVal, excVal);
                deductionRows.push({
                    slno: index + 1,
                    deduction: a.deduction,
                    dedType: a.dedType,
                    pay_amount: !isNaN(val) ? val.toFixed(2) : '',
                    remarks: '',
                    edit: a.deduction?.is_editable,
                    enableEdit: false,
                    is_edited: a.deduction?.is_editable
                });
                if (!isNaN(val)) amount = amount + val;
            });
            setState((prevState) => {
                return {
                    ...prevState,
                    deductionRows: deductionRows
                };
            });
        }

        if (isNaN(amount) || (!isNaN(amount) && parseFloat(amount) <= 0)) amount = '';
        else amount = amount.toFixed(2);
        return amount;
    }

    // get name of next deliverable name currentDetailsObj.type !== 'advance'
    const getMileStoneName = () => {
        if (formState.rows.length > 0) {
            let payVal = formState.rows.find((a) => {
                return a.pay_milestone.includes('DELIVERABLE');
            });

            if (!payVal) {
                return 'DELIVERABLE 1';
            } else {
                let delv = formState.rows
                    .filter((a) => {
                        let obj;
                        if (a.pay_milestone.includes('DELIVERABLE')) obj = a;
                        return obj;
                    })
                    ?.map((b) => b.pay_milestone);
                let nos = [];
                let indx = 0;
                delv?.length > 0 &&
                    delv.forEach((de) => {
                        let dels = de.split(' ');
                        if (!isNaN(parseFloat(dels[dels.length - 1]))) nos.push(parseFloat(dels[dels.length - 1]));
                    });
                indx = nos.length > 0 ? Math.max(...nos) : indx;
                return 'DELIVERABLE ' + (indx + 1);
            }
        } else {
            return 'DELIVERABLE 1';
        }
    };

    // when click on add new for pay_schedules table
    const addNewRow = () => {
        currentDetailsObj['slno'] = formState.rows.length + 1;
        currentDetailsObj['pay_milestone'] = getMileStoneName();
        currentDetailsObj['delete'] = true;
        currentDetailsObj['due_date'] = new Date(GenFormDetails?.signing_date);
        setFinYearAmount(getFinAmountByDueDate(currentDetailsObj['due_date'], ''));
        setCurrentDetailsObj(currentDetailsObj);
        setState({ ...state, showForm: true });
    };
    // calcuate and return incl amount remaining from rows
    const getSumOfPrvsTax = () => {
        let amt = 0;
        let dueYear = prepareYear(new Date(currentDetailsObj.due_date));
        if (formState?.rows?.length > 0) {
            formState.rows.forEach((a) => {
                let currentYear = prepareYear(new Date(a.due_date));
                if (
                    a.type !== 'advance' &&
                    dueYear === currentYear &&
                    (state.actionType !== 'edit' || (state.actionType === 'edit' && a.pay_milestone !== editObj.pay_milestone)) &&
                    (CNTRCT_DETAILS.contracType === 'supplyCat' ||
                        (CNTRCT_DETAILS.contracType !== 'supplyCat' && a.hoa === currentDetailsObj.hoa))
                )
                    amt = amt + parseFloat(a.amt_inc_tax);
            });
        }
        return amt;
    };
    // fin year calculator
    const prepareYear = (date) => {
        let month = new Date(date).getMonth();
        let year = new Date(date).getFullYear();
        if (month > 2) {
            year = year + '-' + (year + 1);
        } else {
            year = year - 1 + '-' + year;
        }
        return year;
    };

    // calculate and return total financial sanc amount based on due date for selected row
    function getFinAmountByDueDate(date, hoa) {
        let amount = 0;
        let month = new Date(date).getMonth();
        let year = new Date(date).getFullYear();
        if (month > 2) {
            year = year + '-' + (year + 1);
        } else {
            year = year - 1 + '-' + year;
        }
        if (CNTRCT_DETAILS.contracType === 'supplyCat') {
            props.selectedRow.cntrct_fin_sanc.finRows.forEach((b) => {
                if (b.year === year) {
                    amount = amount + parseFloat(b.fin_sanc_inr);
                }
            });
        } else {
            CNTRCT_DETAILS.rows.forEach((a) => {
                if (hoa === a.hoa) {
                    a.cntrct_fin_sanc.finRows.forEach((b) => {
                        if (b.year === year) {
                            amount = amount + parseFloat(b.fin_sanc_inr);
                        }
                    });
                }
            });
        }
        return amount.toFixed(2);
    }
    // calculate net payment amount -->( incl amount - adv rec - security deposit - other deductions)
    const getNetPay = (data) => {
        let netAmt = parseFloat(data);
        if (!isNaN(parseFloat(currentDetailsObj.security_depst_dedct))) {
            netAmt = netAmt - parseFloat(currentDetailsObj.security_depst_dedct);
        }
        if (!isNaN(parseFloat(currentDetailsObj.adavce_recovery))) {
            netAmt = netAmt - parseFloat(currentDetailsObj.adavce_recovery);
        }
        if (!isNaN(parseFloat(currentDetailsObj.other_deduction))) {
            netAmt = netAmt - parseFloat(currentDetailsObj.other_deduction);
        }
        if (isNaN(netAmt)) netAmt = '';
        else netAmt = netAmt.toFixed(2);
        return netAmt;
    };

    const resetCurrObj = () => {
        currentDetailsObj.amt_exc_tax = '';
        currentDetailsObj.amt_inc_tax = '';
        currentDetailsObj['adavce_recovery'] = '';
        currentDetailsObj['security_depst_dedct'] = '';
        currentDetailsObj['other_deduction'] = '';
        currentDetailsObj['net_pay_amt'] = '';
    };

    // on input chnage
    const handleChange = (ev, key) => {
        let err = errors === false ? {} : errors;
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });

        if (key === 'amt_exc_tax' && (parseFloat(ev) > parseFloat(currentDetailsObj.amt_inc_tax) || isNaN(ev) || ev.indexOf(' ') >= 0)) {
            err['amt_exc_tax'] = true;
            let preverrors = { ...errors, ...err };
            setErrors(preverrors);
            return;
        }
        if (key === 'due_date') {
            let val = getFinAmountByDueDate(ev, currentDetailsObj.hoa);
            setFinYearAmount(val);
            resetCurrObj();
        }
        if (key === 'hoa' && CNTRCT_DETAILS.contracType !== 'supplyCat') {
            resetCurrObj();
            let amount = getFinAmountByDueDate(currentDetailsObj.due_date, ev);
            setFinYearAmount(amount);
        }
        if (key === 'amt_inc_tax') {
            currentDetailsObj.amt_exc_tax = '';
            let prvsInclTaxes = getSumOfPrvsTax();
            // based on duedate incl amount should be less than total fin year sanc amount
            if (prvsInclTaxes + parseFloat(ev) > parseFloat(finYearAmount) || isNaN(ev) || ev.indexOf(' ') >= 0) {
                err['amt_inc_tax'] = true;
                let preverrors = { ...errors, ...err };
                setErrors(preverrors);
                return;
            }
            if (prvsInclTaxes + parseFloat(ev) > parseFloat(props.selectedRow.cntrct_val_inc_tax) || isNaN(ev) || ev.indexOf(' ') >= 0) {
                err['amt_inc_tax'] = true;
                let preverrors = { ...errors, ...err };
                setErrors(preverrors);
                return;
            }
            currentDetailsObj['adavce_recovery'] = getRecoveryTotal(ev);
            currentDetailsObj['security_depst_dedct'] = getSecurityRate(ev);

            currentDetailsObj['other_deduction'] = getOtherDeductionRate(ev, currentDetailsObj.amt_exc_tax);
            currentDetailsObj['net_pay_amt'] = getNetPay(ev);
            if (ev === '') {
                currentDetailsObj.amt_exc_tax = '';
            }
        }
        if (key === 'amt_exc_tax') {
            currentDetailsObj['other_deduction'] = getOtherDeductionRate(currentDetailsObj.amt_inc_tax, ev);
            currentDetailsObj['net_pay_amt'] = getNetPay(currentDetailsObj.amt_inc_tax);
        }
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
        if (key === 'pay_milestone' || key === 'amt_exc_tax' || key === 'amt_inc_tax') {
            validateFeild(ev, key);
        }
    };
    const checkErrors = () => {
        const result = [];
        for (let i in errors) {
            if (errors[i] === true) {
                result.push(i);
            }
        }
        return result.length > 0;
    };

    const validateFeild = (ev, key) => {
        let err = {};
        err = FormValidation.validateFileld('required', key, ev);
        let preverrors = { ...errors, ...err };
        setErrors(preverrors);
    };

    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            newObj['due_date'] = new Date(newObj['due_date']);
            setFinYearAmount(getFinAmountByDueDate(newObj['due_date'], newObj.hoa));
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setState({
                ...state,
                showForm: true,
                actionType: sAction,
                advRows: newObj.advance_recoveries,
                deductionRows: newObj.deduction_details
            });
            setErrors({});
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
        if (sAction === 'view_click' && column.field === 'adavce_recovery') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            state.advRows = newObj['advance_recoveries'];
            setState({ ...state, advRows: state.advRows, showAdvModal: true });
        }
        if (sAction === 'view_click' && column.field === 'other_deduction') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            state.deductionRows = newObj['deduction_details'];
            setState({ ...state, showDeducModal: true, deductionRows: state.deductionRows });
        }
    };

    const advAmount = () => {
        let advamount = 0;
        let totalincl = parseFloat(currentDetailsObj.amt_inc_tax);
        let advcoverd = parseFloat(currentDetailsObj.adavce_recovery);
        formState.rows.forEach((a) => {
            if (state.actionType !== 'edit' || (state.actionType === 'edit' && a.pay_milestone !== editObj.pay_milestone)) {
                if (a.type && a.type === 'advance') {
                    advamount = a.amt_inc_tax ? advamount + parseFloat(a.amt_inc_tax) : advamount;
                } else {
                    totalincl = totalincl + parseFloat(a.amt_inc_tax);
                    advcoverd = advcoverd + parseFloat(a.adavce_recovery);
                }
            }
        });
        return totalincl === parseFloat(props.selectedRow.cntrct_val_inc_tax) && advcoverd !== advamount;
    };

    const checkIfNameExists = () => {
        if (state.actionType !== 'edit') {
            return formState.rows.find((a) => a.pay_milestone?.trim() === currentDetailsObj.pay_milestone?.trim());
        } else {
            return formState.rows.find(
                (a) =>
                    a.pay_milestone?.trim() !== editObj.pay_milestone?.trim() &&
                    a.pay_milestone?.trim() === currentDetailsObj.pay_milestone?.trim()
            );
        }
    };

    const getHOABasedAmount = () => {
        let amt = 0;
        let dueYear = new Date(currentDetailsObj.due_date).getFullYear().toString();
        if (formState?.rows?.length > 0) {
            formState.rows.forEach((a) => {
                let currentYear = new Date(a.due_date).getFullYear().toString();
                if (
                    a.type !== 'advance' &&
                    a.hoa === currentDetailsObj.hoa &&
                    dueYear === currentYear &&
                    (state.actionType !== 'edit' || (state.actionType === 'edit' && a.pay_milestone !== editObj.pay_milestone))
                )
                    amt = amt + parseFloat(a.amt_inc_tax);
            });
        }
        return amt;
    };

    const saveCntrctDetails = () => {
        if (checkIfNameExists()) {
            growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.PaymentMilestone });
            return;
        }
        if (props.finalData?.Contract_Details?.contracType === 'totalCntrct') {
            let currSupCat = props.finalData.Contract_Details.rows.find((a) => a.hoa === currentDetailsObj.hoa);
            if (currSupCat && parseFloat(currSupCat.cntrct_val_inc_tax) < getHOABasedAmount() + parseFloat(currentDetailsObj.amt_inc_tax)) {
                growl.show({
                    life: 6000,
                    severity: 'error',
                    summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.AmountInclTaxes
                });
                return;
            }
        }

        if (parseFloat(currentDetailsObj.amt_inc_tax) < parseFloat(currentDetailsObj.amt_exc_tax)) {
            growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.AmountExclTaxes });
            return;
        }
        if (currentDetailsObj.net_pay_amt < 0) {
            growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.TotalNetPayment });
            return;
        }
        let amount = parseFloat(currentDetailsObj.net_pay_amt);
        formState.rows.forEach((a) => {
            if (state.actionType !== 'edit' || (state.actionType === 'edit' && a.pay_milestone !== editObj.pay_milestone)) {
                amount = amount + parseFloat(a.net_pay_amt);
            }
        });
        let admVar = props.finalData?.Contract_Details?.contracType === 'totalCntrct' ? props.admis_var : props.selectedRow.admissable_var;

        let admisVarAmount = (parseFloat(admVar) / 100) * parseFloat(props.selectedRow.cntrct_val_inc_tax);
        if (parseFloat(props.selectedRow.cntrct_val_inc_tax) + admisVarAmount < amount) {
            growl.show({
                life: 6000,
                severity: 'error',
                summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.TotalNetPaymentTaxes
            });
            return;
        }

        if (props?.moreDetailsObj?.Advance?.rows?.length > 0 && advAmount()) {
            growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.AdvanceRecoveries });
            return;
        }
        currentDetailsObj['due_date'] = getDate(currentDetailsObj['due_date']);
        if (currentDetailsObj.type !== 'advance') {
            currentDetailsObj['advance_recoveries'] = state.advRows;
            currentDetailsObj['deduction_details'] = state.deductionRows;
        }

        if (state.actionType === 'edit') {
            let indx = formState.rows.findIndex((a) => a.pay_milestone === editObj.pay_milestone);
            formState.rows[indx] = currentDetailsObj;
            setFormState({ ...formState });
        } else {
            formState.rows.push(currentDetailsObj);
            setFormState({ ...formState });
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Payment_Schedule', formState);
    };

    const handleDeleteRow = () => {
        formState.rows = formState.rows.filter((a) => {
            return a.pay_milestone !== currentDetailsObj.pay_milestone;
        });
        setFormState({ ...formState, rows: formState.rows });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Payment_Schedule', formState);
        handleCancel('showDeleteModal');
    };

    const saveHoa = (arr, hoa) => {
        setCurrentDetailsObj({ ...currentDetailsObj, hoa: hoa });
        handleCancel('showHoaModal');
    };

    return (
        <div className={`coa-card p-10 ${state.loading ? 'mask' : ''}`}>
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <Toast ref={(el) => setGrowl(el)} />
            <SecondaryDataTable
                rows={formState?.rows}
                columns={state.columns}
                emptyMessage="Payment Schedules are yet to be created"
                noPaginator={true}
                handleActionsInParent={handleActions}
                formType={props.formType}
                className="secondary-table inner-table-overflow"
                scrollable
            />
            {props.formType !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={`comment-color pointer  ${state.showForm ? 'disabled' : ''}`}>
                        + Add New
                    </span>
                </div>
            )}
            {state.showForm && (
                <div className="m-t-10 m-l-5 m-r-5">
                    {!props.selectedRow?.sup_cat?.id && currentDetailsObj.type !== 'advance' && (
                        <div className="row m-b-15">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                <b className="w-40 fs-14">
                                    HOA {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                </b>
                                <Dropdown
                                    name="hoa"
                                    value={currentDetailsObj.hoa}
                                    onChange={(e) => handleChange(e.value, 'hoa')}
                                    appendTo={document.body}
                                    options={state.hoaOptions}
                                    placeholder="HOA"
                                    className="w-60"
                                />
                            </div>
                        </div>
                    )}
                    <div className="row m-b-15">
                        {FormSchema.FLD_CD_PAY_MILESTONE.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                <b className="w-40 fs-14">
                                    {FormSchema.FLD_CD_PAY_MILESTONE.name}
                                    {FormSchema.FLD_CD_PAY_MILESTONE.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                </b>
                                <InputComponent
                                    className="w-60"
                                    inputBoxSize="sm"
                                    name="pay_milestone"
                                    type="text"
                                    value={currentDetailsObj.pay_milestone}
                                    onChange={(e) => handleChange(e.toUpperCase(), 'pay_milestone')}
                                    placeholder="Payment Milestone"
                                    readOnly={currentDetailsObj.type === 'advance'}
                                    hasError={errors && errors.pay_milestone}
                                    errMsg="Payment Milestone is mandatory"
                                />
                            </div>
                        )}
                        {FormSchema.FLD_CD_PAY_DUE_DATE.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                <b className="w-40 fs-14">
                                    {FormSchema.FLD_CD_PAY_DUE_DATE.name}
                                    {FormSchema.FLD_CD_PAY_DUE_DATE.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                </b>
                                <div className="w-60">
                                    <Calendar
                                        placeholder="Due Date"
                                        value={currentDetailsObj.due_date}
                                        name="due_date"
                                        appendTo={document.body}
                                        onChange={(e) => handleChange(e.target.value, 'due_date')}
                                        dateFormat="dd M yy"
                                        showIcon
                                        readOnlyInput={true}
                                        maxDate={window?._VALIDATIONS_?.CONTRACT_DUE_DATE ? GenFormDetails?.closing_date : ''}
                                        minDate={window?._VALIDATIONS_?.CONTRACT_DUE_DATE ? GenFormDetails?.signing_date : ''}
                                        yearRange={signingDateRange}
                                        yearNavigator={true}
                                        monthNavigator={true}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {currentDetailsObj.type !== 'advance' && (
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_PAY_AMT_INC_TAX.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_PAY_AMT_INC_TAX.name}
                                        {FormSchema.FLD_CD_PAY_AMT_INC_TAX.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="amt_inc_tax"
                                        type="text"
                                        value={currentDetailsObj.amt_inc_tax}
                                        maxLength={15}
                                        onChange={(e) => handleChange(e, 'amt_inc_tax')}
                                        placeholder={'Amount Incl of Taxes ( ' + currency + ')'}
                                        disabled={
                                            !props.selectedRow?.sup_cat?.id &&
                                            currentDetailsObj.type !== 'advance' &&
                                            !currentDetailsObj.hoa
                                        }
                                        hasError={errors && errors.amt_inc_tax}
                                        errMsg={
                                            getSumOfPrvsTax() + parseFloat(currentDetailsObj.amt_inc_tax) > parseFloat(finYearAmount)
                                                ? 'Amount Inclusive of Taxes could not be greater than Total Contract value of Financial Year'
                                                : getSumOfPrvsTax() + parseFloat(currentDetailsObj.amt_inc_tax) >
                                                  parseFloat(props.selectedRow.cntrct_val_inc_tax)
                                                ? 'Amount Inclusive of Taxes could not be greater than Contract Value Incl of Tax'
                                                : 'Amount Incl of Taxe is mandatory'
                                        }
                                    />
                                </div>
                            )}
                            {FormSchema.FLD_CD_PAY_AMT_EXCL_TAX.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_PAY_AMT_EXCL_TAX.name}
                                        {FormSchema.FLD_CD_PAY_AMT_EXCL_TAX.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="amt_exc_tax"
                                        type="text"
                                        value={currentDetailsObj.amt_exc_tax}
                                        maxLength={15}
                                        onChange={(e) => handleChange(e, 'amt_exc_tax')}
                                        placeholder={'Amount Excl of Taxes ( ' + currency + ')'}
                                        disabled={!currentDetailsObj.amt_inc_tax}
                                        hasError={errors && errors.amt_exc_tax}
                                        errMsg="Amount Excl of Taxes could not be greater than Amount Incl of Taxes"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {currentDetailsObj.type !== 'advance' && (
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_PAY_ADV_RECOV.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_PAY_ADV_RECOV.name}
                                        {FormSchema.FLD_CD_PAY_ADV_RECOV.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <p className={'primary-color pointer'} onClick={() => setState({ ...state, showAdvModal: true })}>
                                        {currentDetailsObj.adavce_recovery ? currentDetailsObj.adavce_recovery : '-'}
                                    </p>
                                </div>
                            )}
                            {FormSchema.FLD_CD_PAY_SDD.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_PAY_SDD.name}
                                        {FormSchema.FLD_CD_PAY_SDD.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                    <div className={'input-div'}>
                                        <span className="opacity-5 ellipses h1-name">
                                            {currentDetailsObj.security_depst_dedct ? currentDetailsObj.security_depst_dedct : '--'}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {currentDetailsObj.type !== 'advance' && (
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_PAY_OD.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_PAY_OD.name}
                                        {FormSchema.FLD_CD_PAY_OD.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                    <p className={'primary-color pointer'} onClick={() => setState({ ...state, showDeducModal: true })}>
                                        {currentDetailsObj.other_deduction ? currentDetailsObj.other_deduction : '-'}
                                    </p>
                                </div>
                            )}
                            {FormSchema.FLD_CD_PAY_NET_PAY_AMT.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_PAY_NET_PAY_AMT.name}
                                        {FormSchema.FLD_CD_PAY_NET_PAY_AMT.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <div className={'input-div'}>
                                        <span className="opacity-5 ellipses h1-name">
                                            {currentDetailsObj.net_pay_amt ? currentDetailsObj.net_pay_amt : '--'}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="m-t-10">
                        <Button
                            size="sm"
                            kind="primary"
                            className="m-r-10"
                            onClick={() => saveCntrctDetails()}
                            disabled={
                                !currentDetailsObj.amt_inc_tax ||
                                !currentDetailsObj.amt_exc_tax ||
                                !currentDetailsObj.pay_milestone ||
                                (!props.selectedRow?.sup_cat?.id && !currentDetailsObj.hoa) ||
                                (currentDetailsObj.amt_inc_tax && parseInt(currentDetailsObj.amt_inc_tax) === 0) ||
                                (currentDetailsObj.amt_exc_tax && parseInt(currentDetailsObj.amt_exc_tax) === 0) ||
                                checkErrors() ||
                                false
                            }
                        >
                            {state.actionType === 'edit' ? 'Update Payment Schedule' : 'Add Payment Schedule'}
                        </Button>
                        <Button
                            size="sm"
                            kind="secondary"
                            onClick={() => {
                                setState({ ...state, showForm: false, actionType: '' });
                                setCurrentDetailsObj(emptyObj);
                                setEditObj({});
                                setErrors({});
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            )}
            {state.showHoaModal && (
                <HOADetailsModal
                    showModal={state.showHoaModal}
                    handleHOACancel={() => handleCancel('showHoaModal')}
                    updateHOAInParent={saveHoa}
                    selectedHOA={currentDetailsObj.hoa}
                    hoaDetails={{
                        fetchCompositionQry: Schema.getMeMappingId,
                        variables: { functionName: 'Supply Category', subFunctionName: '' },
                        emptyMessage: 'HOAs are yet to be created',
                        responseObj: 'GetMeFunctionWiseSegments'
                    }}
                />
            )}
            {state.showDeleteModal && (
                <DeleteConfirmModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Payment Schedule"
                    showModal={state.showDeleteModal}
                    name={'Payment Milestone : ' + currentDetailsObj.pay_milestone}
                />
            )}
            {state.showAdvModal && (
                <AdvanceRecoveryModal
                    handleCancel={() => handleCancel('showAdvModal')}
                    type="Advance Recovery"
                    showModal={state.showAdvModal}
                    advRows={state.advRows}
                    currentDetailsObj={currentDetailsObj}
                    formType={props.formType}
                    growl={growl}
                    handleSave={(data) => {
                        state.advRows = [...data.rows];
                        setState({ ...state, advRows: state.advRows });
                        currentDetailsObj.adavce_recovery = data.totalAmount;
                        currentDetailsObj['net_pay_amt'] = getNetPay(currentDetailsObj.amt_inc_tax);
                        handleCancel('showAdvModal');
                    }}
                />
            )}
            {state.showDeducModal && (
                <OtherDeductionModal
                    handleCancel={() => handleCancel('showDeducModal')}
                    type="Other Deduction"
                    showModal={state.showDeducModal}
                    deductionRows={state.deductionRows}
                    currentDetailsObj={currentDetailsObj}
                    formType={props.formType}
                    growl={growl}
                    handleSave={(data) => {
                        state.deductionRows = [...data.rows];
                        setState({ ...state, deductionRows: state.deductionRows });
                        currentDetailsObj.other_deduction = data.totalAmount;
                        currentDetailsObj['net_pay_amt'] = getNetPay(currentDetailsObj.amt_inc_tax);
                        handleCancel('showDeducModal');
                    }}
                />
            )}
        </div>
    );
};

export default PaymentScheduleForm;
