import React, { useState } from 'react';
import { Toast } from 'primereact/toast';
import { useQuery, useLazyQuery } from '@apollo/client';
import Schema from '../../../../schema/AppSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody } from 'appkit-react';
import { employeeClient, expenditureClient, eventClient } from '../../../../apollo';
import { Sidebar } from 'primereact/sidebar';
import WorklowSidebarContent from '../../../../components/WorklowSidebarContent/WorklowSidebarContent';
import { getFormTypeAndRecordId } from '../../../../services/commonService';
import PersonnelBillActions from './PersonnelBillActions';
import Bills from './Bills';
import SanctionDetails from './SanctionDetails';
import JourneyDetails from './JourneyDetails';
import AdditionalBillDetails from './AdditionalBillDetails';
import DayAllowances from './DayAllowances';
import FamilyMembers from './FamilyMembers';
import StayDetails from './StayDetails';
import TCPE from './TCPE';
import TCPC from './TCPC';
import TransferChargers from './TransferChargers';
import Deductions from './Deductions';
import '../../expenditure.scss';
import _ from 'lodash';
import { getDate } from '../../../../util';
import PersonnelBillPreview from './PersonnelBillPreview';
import PreviousBills from './PreviousBills';
import { DownloadFile } from '../../../../commons/Downloader';
import ThirdParty from './ThirdParty';

const CreatePersonnelBills = (props) => {
    const [growl, setGrowl] = useState();
    const [errorStatus, setErrorStatus] = useState(false);
    const formTypeAndId = getFormTypeAndRecordId(props.location.pathname);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    let isDraft = formTypeAndId['isDraft'];
    let transactionId = localStorage.getItem('workflow_id');
    const [mode, setMode] = useState(formType);
    const [versionNo, setversionNo] = useState();
    const [versionUser, setversionUser] = useState();
    const [billDetails, setBillDetails] = useState();
    const [entityId, setEntityId] = useState('');
    const [recID, setID] = useState('');
    const [additionalDetailsObj, setAdditionalDetailsObj] = useState({});
    const [thirdParties, setThirdParties] = useState([]);

    const [state, setState] = useState({
        loading: false,
        showWorkflowStatus: false,
        showPreviousBills: false,
        showThirdParty: false,
        showPreview: false,
        listToShowComment: [],
        finPeriodsList: [],
        finYearsList: [],
        deductions: [],
        showHOA: false,
        activeAccordion: [
            'Sanction_Details',
            'Journey_Details',
            'Additional_Bill_Details',
            'Day_Allowance',
            'Family_Members',
            'Stay_Details',
            'Transportation_Charges_of_Personnel_Effect',
            'Transportation_Charges_of_Private_Conveyance',
            'Transfer_Chargers',
            'Recoveries',
            'Deductions'
        ],
        hoaCodes: []
    });

    let emptyObj = {
        SANCTION_DETAILS: {
            number: '',
            date: '',
            authority: '',
            amount: ''
        },
        Journey_Details: [],
        Additional_Bill_Details: {
            block_from_date: '',
            block_to_date: '',
            no_of_leaves: '',
            journey_purpouse: '',
            from_date: '',
            to_date: '',
            encashment_days: '',
            headquarter: '',
            headquarter_old: '',
            headquarter_new: '',
            order_no: '',
            other_expenses: ''
        },
        Day_Allowance: [],
        Family_Members: [],
        Stay_Details: [],
        Transportation_Charges_of_Personnel_Effect: [],
        Transportation_Charges_of_Private_Conveyance: [],
        Transfer_Chargers: [],
        Recoveries: [],
        Deductions: []
    };
    const [formState, setFormState] = useState({
        BILLS: {
            finYear: '',
            finPeriod: '',
            billSubType: '',
            billType: '',
            employee: '',
            hoa: '',
            billDescription: '',
            grossBillAmt: ''
        },
        ...emptyObj
    });

    let { loading: employeeLoader, data: employeesData } = useQuery(Schema.employeesList, {
        fetchPolicy: 'no-cache',
        variables: { where: { is_latest: { equals: true } } },
        client: employeeClient,
        onCompleted: (res) => {
            if (res) {
                getMastersData();
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    let { loading: billsLoader, data: personnelBillTypes } = useQuery(Schema.personnelBillConfs, {
        fetchPolicy: 'no-cache',
        variables: { where: { is_latest: { equals: true } }, orderBy: { code: 'asc' } },
        client: expenditureClient,
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [getMastersData, { data: currentPeriod, loading: cPLoader }] = useLazyQuery(Schema.getCurrentPeriod, {
        fetchPolicy: 'no-cache',
        client: employeeClient,
        onCompleted: (res) => {
            if (res) {
                getMasterDetails();
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [getMasterDetails, { loading: masterLoader }] = useLazyQuery(Schema.getMasterDataForPersonnelBills, {
        variables: {
            where: { structures: { some: { id: { not: { equals: '' } } } } },
            orderBy: { name: 'desc' }
        },
        onCompleted: (res) => {
            if (res) {
                if (formType === 'create') {
                    let formObj = JSON.parse(JSON.stringify(formState));
                    formObj['BILLS']['finYear'] = res.current_fin_year.id;
                    formObj['BILLS']['finPeriod'] = currentPeriod?.getCurrentPeriod?.name.replace('-', ' ');
                    setFormState(formObj);
                }
                setState({
                    ...state,
                    finYearsList: res.admFinYears,
                    finPeriodsList: res.fin_period_list,
                    deductions: res.deductions
                });
                if (recordID && isDraft) {
                    getEntityRecord();
                } else if (recordID) {
                    getPersonnelBill();
                }
            }
        },
        fetchPolicy: 'no-cache'
    });

    const minusDateTime = (date) => {
        var d = new Date(date);
        d.setHours(d.getHours() - 5);
        d.setMinutes(d.getMinutes() - 30);
        return d;
    };

    const setJourneyDetails = (dataRows) => {
        let list = [];
        list = dataRows.map((obj) => {
            let newObj = obj;
            newObj.arrival_date = newObj.arrival_date ? getDate(minusDateTime(newObj.arrival_date)) : '';
            newObj.departure_date = newObj.departure_date ? getDate(minusDateTime(newObj.departure_date)) : '';
            delete newObj.__typename;
            return newObj;
        });
        return list;
    };
    const setDayAllowances = (dataRows) => {
        let list = [];
        list = dataRows.map((obj) => {
            let newObj = obj;
            delete newObj.__typename;
            return newObj;
        });
        return list;
    };

    const setFamilyMembers = (dataRows) => {
        let list = [];
        list = dataRows.map((obj) => {
            let newObj = obj;
            delete newObj.__typename;
            return newObj;
        });
        return list;
    };

    const setStayDetails = (dataRows) => {
        let list = [];
        list = dataRows.map((obj) => {
            let newObj = obj;
            newObj.from_date = obj.from_date ? getDate(obj.from_date, true) : '';
            newObj.to_date = obj.to_date ? getDate(obj.to_date, true) : '';
            delete newObj.__typename;
            return newObj;
        });
        return list;
    };

    const setTCPE = (dataRows) => {
        let list = [];
        list = dataRows.map((obj) => {
            let newObj = obj;
            newObj.date = newObj.date ? getDate(newObj.date, true) : '';
            newObj.amount = parseInt(obj.weight) * parseInt(obj.rate);
            delete newObj.__typename;
            return newObj;
        });
        return list;
    };

    const setTCPC = (dataRows) => {
        let list = [];
        list = dataRows.map((obj) => {
            let newObj = obj;
            newObj.amount = parseInt(obj.weight) * parseInt(obj.rate) * parseInt(obj.distance) + parseInt(obj.packing_charge);
            delete newObj.__typename;
            return newObj;
        });
        return list;
    };
    const setTransferCharges = (dataRows) => {
        let list = [];
        list = dataRows.map((obj) => {
            let newObj = obj;
            newObj.home_journey_date = newObj.home_journey_date ? getDate(newObj.home_journey_date, true) : '';
            newObj.amount = parseInt(obj.distance) * parseInt(obj.rate);
            delete newObj.__typename;
            return newObj;
        });
        return list;
    };
    const setDeductions = (dataRows) => {
        let list = [];
        list = dataRows.map((obj) => {
            let newObj = obj;
            let dedObj = state.deductions.find((de) => de.id === obj.deduction_id);
            if (dedObj) dedObj.dedValue = dedObj?.value;
            if (dedObj?.value) delete dedObj.value;
            newObj.deduction = dedObj;
            newObj.dedValue = dedObj?.dedValue;
            delete newObj.__typename;
            return newObj;
        });
        return list;
    };

    const getBillType = (bID, subID) => {
        let bType = personnelBillTypes?.personnelBillConfs?.find((a) => a.id === bID) || '';
        let sType = bType?.sub_bill_details?.find((a) => a.id === subID) || '';
        return {
            billType: bType,
            subbillType: sType
        };
    };

    const prepareData = (response) => {
        if (response.id) setID(response.id);
        setBillDetails(response);
        setversionNo(response?.version_no);
        setversionUser(response?.version_user);
        let additionalDetails = isDraft ? response.additional_details?.create : response.additional_details;
        let conveyanceExpenses = isDraft ? response.conveyance_expenses?.create : response.conveyance_expenses;
        let dayAllowances = isDraft ? response.day_allowances?.create : response.day_allowances;
        let deductions = isDraft ? response.deductions?.create : response.deductions;
        let familyMembers = isDraft ? response.family_members?.create : response.family_members;
        let journeyDetails = isDraft ? response.journey_details?.create : response.journey_details;
        let sanctionDetails = isDraft ? response.sanction_details?.create : response.sanction_details;
        let stayDetails = isDraft ? response.stay_details?.create : response.stay_details;
        let transferCharges = isDraft ? response.transfer_charges?.create : response.transfer_charges;
        let personnelExpenses = isDraft ? response.personnel_expenses?.create : response.personnel_expenses;
        let thirdPartyList = isDraft ? response.third_party_payment?.create || [] : response.third_party_payment;
        let billObj = {};
        if (isDraft) billObj = getBillType(response.bill_type?.connect?.id, response.bill_sub_type?.connect?.id);
        else billObj = getBillType(response.bill_type?.id, response.bill_sub_type?.id);
        getHoaCodes({
            variables: {
                where: { bill_conf_id: { equals: billObj?.billType.id } },
                orderBy: { name: 'desc' }
            }
        });
        if (sanctionDetails?.__typename) delete sanctionDetails.__typename;
        if (additionalDetails?.__typename) delete additionalDetails.__typename;
        let empData = employeesData?.employees.find((a) => a.id === response.emp_id);
        let dataObj = {
            BILLS: {
                finYear: response.fin_year,
                finPeriod: response.fin_period,
                billType: billObj?.billType,
                billSubType: billObj?.subbillType,
                employee: empData ? empData : response.employee,
                hoa: response.hoa,
                billDescription: response.description,
                grossBillAmt: response.gross_bill_amount
            },
            SANCTION_DETAILS: {
                ...sanctionDetails,
                date: sanctionDetails?.date ? new Date(sanctionDetails.date) : ''
            },
            Journey_Details: journeyDetails?.length > 0 ? setJourneyDetails(journeyDetails) : [],
            Additional_Bill_Details: {
                ...additionalDetails,
                block_from_date: additionalDetails?.block_from_date ? new Date(additionalDetails.block_from_date) : '',
                block_to_date: additionalDetails?.block_to_date ? new Date(additionalDetails.block_to_date) : '',
                from_date: additionalDetails?.from_date ? new Date(additionalDetails.from_date) : '',
                to_date: additionalDetails?.to_date ? new Date(additionalDetails.to_date) : ''
            },
            Day_Allowance: dayAllowances?.length > 0 ? setDayAllowances(dayAllowances) : [],
            Family_Members: familyMembers?.length > 0 ? setFamilyMembers(familyMembers) : [],
            Stay_Details: stayDetails?.length > 0 ? setStayDetails(stayDetails) : [],
            Transportation_Charges_of_Personnel_Effect: personnelExpenses?.length > 0 ? setTCPE(personnelExpenses) : [],
            Transportation_Charges_of_Private_Conveyance: conveyanceExpenses?.length > 0 ? setTCPC(conveyanceExpenses) : [],
            Transfer_Chargers: transferCharges?.length > 0 ? setTransferCharges(transferCharges) : [],
            Deductions: deductions?.length > 0 ? setDeductions(deductions) : []
        };
        setThirdParties(thirdPartyList);
        setFormState({ ...formState, ...dataObj });
    };

    const [getPersonnelBill, { loading: personnelLoader }] = useLazyQuery(Schema.personnelBill, {
        fetchPolicy: 'no-cache',
        client: expenditureClient,
        variables: { where: { id: recordID } },
        onCompleted: (response) => {
            if (response?.personnelBill) {
                prepareData(response.personnelBill);
                return;
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });
    const [getEntityRecord, { loading: entityLoader }] = useLazyQuery(Schema.entityRecord, {
        // get api for record data by ID in Draft mode
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: { where: { id: recordID } },
        onCompleted: (data) => {
            if (data?.entityRecord?.record) {
                setEntityId(data?.entityRecord?.record?.entity_id);
                prepareData(data.entityRecord.record);
                return;
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const groupBy = (xs) => {
        let arr1 = [];
        let list = [];
        xs.forEach((m) => {
            m.filterLabel = m.segment_full_code + '-' + m.segment_name;
            m.code = m.segment_code;
            m.full_code = m.segment_full_code;
            m.name = m.seg_def_name;
            arr1.push(m.seg_def_name);
        });
        let group = _.groupBy(xs, 'seg_def_name');
        arr1 = _.uniq(arr1, false, function (p) {
            return p.seg_def_name;
        });
        arr1.forEach((obj) => {
            if (obj && group[obj]) {
                let key = { name: obj, segments: group[obj] };
                list.push(key);
            }
        });
        return list;
    };

    const [getHoaCodes, { loading: hoaLoader }] = useLazyQuery(Schema.personnelBillConfCodes, {
        client: expenditureClient,
        onCompleted: (res) => {
            if (res) {
                let list = [];
                list = groupBy(res.personnelBillConfCodes);
                setState({ ...state, hoaCodes: list });
            }
        },
        fetchPolicy: 'no-cache'
    });

    const showWorkflow = (flag) => {
        setState({ ...state, showWorkflowStatus: flag });
    };
    const handleCommentClick = (selRow, flag) => {
        const lstToShowComment = state.listToShowComment;
        lstToShowComment[selRow.id] = flag;
        setState({ ...state, listToShowComment: lstToShowComment });
    };

    const setActionsStatus = (hasActions) => {
        setState({ ...state, hasActions });
    };

    const handleAccordionChange = (e) => {
        // accordion close or open
        if (mode === 'view') {
            setState({
                ...state,
                activeAccordion: [
                    'Sanction_Details',
                    'Journey_Details',
                    'Additional_Bill_Details',
                    'Day_Allowance',
                    'Family_Members',
                    'Stay_Details',
                    'Transportation_Charges_of_Personnel_Effect',
                    'Transportation_Charges_of_Private_Conveyance',
                    'Transfer_Chargers',
                    'Recoveries',
                    'Deductions'
                ]
            });
        } else {
            let val = state.activeAccordion.find((a) => a === e);
            if (val)
                state.activeAccordion = state.activeAccordion.filter((a) => {
                    return a !== e;
                });
            else state.activeAccordion.push(e);
            setState({ ...state, activeAccordion: state.activeAccordion });
        }
    };

    const getadditionalDetails = (data) => {
        let isVisibleObj = {};
        let isMandatory = {};
        if (data?.billType?.additional_details?.length > 0) {
            let fields = data?.billType?.additional_details[0].fields;
            fields.forEach((a) => {
                let code = a.field.replace(/[^a-zA-Z ]/g, '');
                a.code = code.replace(/[^A-Z0-9]/gi, '_');
                isVisibleObj[a.code] = a.is_visible;
                isMandatory[a.code] = a.is_mandatory;
            });
        }
        return {
            isVisibleObj: isVisibleObj,
            isMandatory: isMandatory
        };
    };

    const prepareDeductions = (type, data) => {
        let list = [];
        data.billType.deductions &&
            data.billType.deductions.forEach((ded) => {
                if (ded.is_mandatory) {
                    let isExists = formState.Deductions.find((de) => de.deduction?.id === ded.deduction_id);
                    let obj = {
                        deduction: { id: ded.deduction_id, name: ded.deduction?.name },
                        dedValue: ded.deduction?.value,
                        amount: isExists ? isExists.amount : '',
                        remarks: isExists ? isExists.remarks : ''
                    };
                    list.push(obj);
                }
            });

        if (formState.Deductions?.length > 0 && list.length > 0) {
            formState.Deductions.forEach((de) => {
                let findEle = list.find((a) => de?.deduction?.id === a?.deduction?.id);
                if (!findEle) {
                    list.push(findEle);
                }
            });
        } else {
            formState.Deductions = list;
        }
        list = list.filter((a) => a?.deduction?.id);
        let formObj = {
            Deductions: list,
            [type]: data
        };
        if (formState.BILLS?.billType?.id !== data?.billType?.id) {
            setFormState({
                ...emptyObj,
                ...formObj
            });
            setThirdParties([]);
        } else {
            setFormState({
                ...formState,
                ...formObj
            });
        }
        if (formState.BILLS?.employee?.id !== data?.employee?.id) {
            setThirdParties([]);
        }
        let additionalDetails = getadditionalDetails(data);
        setAdditionalDetailsObj(additionalDetails);
    };

    const saveFormsData = (type, data) => {
        if (type === 'BILLS') {
            prepareDeductions(type, data);
            return;
        }

        setFormState({
            ...formState,
            [type]: data
        });
    };

    const getNetPayAmount = () => {
        let netpay = 0;
        let recoveries = 0;
        let deductions = 0;
        let claims = 0;
        let allowances = 0;
        let haltingCharges = 0;
        let transferGrant = 0;
        let tcpe = 0;
        let tcpc = 0;
        let otherExpenses = 0;
        let grossBill = 0;
        if (formState.Journey_Details?.length > 0)
            claims = formState.Journey_Details.map((item) => parseInt(item.claim_allowed)).reduce((prev, next) => prev + next);
        if (formState.Day_Allowance?.length > 0)
            allowances = formState.Day_Allowance.map((item) => parseInt(item.amount)).reduce((prev, next) => prev + next);
        if (formState.Stay_Details?.length > 0)
            haltingCharges = formState.Stay_Details.map((item) => parseInt(item.halting_charge)).reduce((prev, next) => prev + next);
        if (formState.Transfer_Chargers?.length > 0)
            transferGrant = formState.Transfer_Chargers.map((item) => parseInt(item.transfer_grant)).reduce((prev, next) => prev + next);
        if (formState.Transportation_Charges_of_Private_Conveyance?.length > 0)
            tcpc = formState.Transportation_Charges_of_Private_Conveyance.map((item) => parseInt(item.amount)).reduce(
                (prev, next) => prev + next
            );
        if (formState.Transportation_Charges_of_Personnel_Effect?.length > 0)
            tcpe = formState.Transportation_Charges_of_Personnel_Effect.map((item) => parseInt(item.amount)).reduce(
                (prev, next) => prev + next
            );
        if (formState.Deductions?.length > 0)
            deductions = formState.Deductions.map((item) => (item.amount ? parseInt(item.amount) : 0)).reduce((prev, next) => prev + next);
        if (formState.Additional_Bill_Details?.other_expenses) {
            let val = parseInt(formState.Additional_Bill_Details.other_expenses);
            otherExpenses = isNaN(val) ? 0 : val;
        }
        grossBill = claims + allowances + haltingCharges + transferGrant + tcpc + tcpe + otherExpenses;
        let dedAm = isNaN(deductions) ? 0 : deductions;
        netpay = grossBill - dedAm - recoveries;
        return isNaN(netpay) ? 0 : netpay;
    };
    const handleLoader = (status) => {
        if (status) {
            setState({ ...state, activeAccordion: [], loading: true });
            return;
        }
        setState({ ...state, loading: status });
    };

    const handleCancel = () => {
        setState({ ...state, loading: false });
        props.history.goBack();
    };

    const showGrowl = (msg, type) => {
        growl.show({ life: 6000, severity: type, summary: msg });
        setTimeout(() => {
            if (type !== 'error') handleCancel();
        }, 1000);
    };

    const navigateToEdit = () => {
        let val = employeesData?.employees.find((a) => a.id === formState.BILLS?.employee?.id);
        if (!val) {
            formState.BILLS.employee = '';
            let data = formState.BILLS;
            setFormState({ ...formState, BILLS: data });
        }
        setMode('edit');
        setState({
            ...state,
            activeAccordion: []
        });
    };

    const navigateToPreviousBills = () => {
        setState({ ...state, showPreviousBills: true });
    };
    const pdfPreview = () => {
        setState({ ...state, showPreview: true });
    };
    const [handleDownload] = useLazyQuery(Schema.GeneratePersonnelBillPDF, {
        variables: {
            personnel_bill_id: recordID
        },
        client: expenditureClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.GeneratePersonnelBillPDF) {
                DownloadFile(data.GeneratePersonnelBillPDF.id, billDetails?.ref + '.pdf');
                growl.show({ life: 6000, severity: 'success', summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.PersonnelBill });
            }
        },
        onError: (err) => {
            let message = err.message.split(':');
            growl.show({ life: 6000, severity: 'error', summary: message.length > 0 ? message[1] : message[0] });
        }
    });
    const navigateToThirdParty = () => {
        setState({ ...state, showThirdParty: true });
    };

    return (
        <div
            className={`page-body pos-rel ${
                masterLoader || hoaLoader || employeeLoader || billsLoader || personnelLoader || entityLoader || cPLoader || state.loading
                    ? 'mask'
                    : ''
            }`}
        >
            {(masterLoader ||
                hoaLoader ||
                employeeLoader ||
                billsLoader ||
                personnelLoader ||
                entityLoader ||
                cPLoader ||
                state.loading) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <Toast ref={(el) => setGrowl(el)} />
            {!state.showPreviousBills && !state.showThirdParty ? (
                <>
                    <div className="page-header">
                        {!masterLoader && !employeeLoader && !billsLoader && !personnelLoader && !entityLoader && !cPLoader && (
                            <span className="light-color">
                                {recordID
                                    ? transactionId && transactionId !== 'undefined'
                                        ? `Transaction Number : ${transactionId}`
                                        : `Personnel Bill - ${billDetails?.ref}`
                                    : 'Personnel Bills'}
                            </span>
                        )}

                        {!masterLoader && !employeeLoader && !billsLoader && !personnelLoader && !entityLoader && !cPLoader && (
                            <span className="backBtn pointer">
                                {formState.BILLS?.billType?.config_details?.length &&
                                    formState.BILLS?.billType?.config_details[0]?.include_thrirdparty_details &&
                                    formState?.BILLS?.employee?.id && (
                                        <img
                                            title="Third Party Payment"
                                            alt="Third Party Payment"
                                            className="header-icon1 p-r-20"
                                            src={process.env.PUBLIC_URL + '/assets/icons/ThirdParty.svg'}
                                            onClick={() => navigateToThirdParty()}
                                        />
                                    )}
                                {recordID && (
                                    <img
                                        className="header-icon1 p-r-10"
                                        title="Previous Bills"
                                        src={process.env.PUBLIC_URL + '/assets/icons/Previous_Bills.svg'}
                                        onClick={() => navigateToPreviousBills()}
                                        alt={'payment-icon'}
                                    ></img>
                                )}
                                {recordID && billDetails?.is_latest && (
                                    <img
                                        title="Print"
                                        alt="Print"
                                        className="header-icon"
                                        src={process.env.PUBLIC_URL + '/assets/icons/Print.svg'}
                                        onClick={pdfPreview}
                                    />
                                )}
                                {recordID && billDetails?.is_latest && (
                                    <img
                                        title="Download"
                                        alt="Download"
                                        className="header-icon"
                                        src={process.env.PUBLIC_URL + '/assets/icons/Download_1.svg'}
                                        onClick={() => {
                                            handleDownload();
                                        }}
                                    ></img>
                                )}
                                {recordID && versionNo !== 0 && (
                                    <img
                                        alt=""
                                        className="header-icon"
                                        title="Workflow Stages"
                                        src={process.env.PUBLIC_URL + '/assets/icons/workflow.svg'}
                                        onClick={() => showWorkflow(true)}
                                    ></img>
                                )}
                                <span
                                    onClick={() => {
                                        localStorage.setItem('workflow_id', '');
                                        props.history.goBack();
                                    }}
                                >
                                    <span className="appkiticon icon-left-chevron-outline icon_size"></span>Back
                                </span>
                            </span>
                        )}
                    </div>
                    <div
                        className={`${
                            state.hasActions || mode === 'create' ? 'page-content-ht-with-btns' : 'page-content-ht-without-btns'
                        }`}
                    >
                        <Sidebar
                            visible={state.showWorkflowStatus}
                            onHide={() => showWorkflow(false)}
                            position="right"
                            dismissable={true}
                            closeOnEscape={false}
                            showCloseIcon={false}
                        >
                            <WorklowSidebarContent
                                sourceId={recordID}
                                listToShowComment={state.listToShowComment}
                                showWorkflow={showWorkflow}
                                handleCommentClick={handleCommentClick}
                            />
                        </Sidebar>
                        {!masterLoader &&
                            !employeeLoader &&
                            !billsLoader &&
                            !personnelLoader &&
                            !entityLoader &&
                            !cPLoader &&
                            formState.BILLS.finYear && (
                                <Bills
                                    mode={mode}
                                    recordID={recordID}
                                    finYearsList={state.finYearsList}
                                    finPeriodsList={state.finPeriodsList}
                                    billTypeList={personnelBillTypes?.personnelBillConfs}
                                    employeeList={employeesData.employees}
                                    getHoaCodes={getHoaCodes}
                                    hoaCodes={state.hoaCodes}
                                    formStateObj={formState}
                                    saveFormData={saveFormsData}
                                    updateErrorStatus={(status) => setErrorStatus(status)}
                                />
                            )}
                        {formState.BILLS?.billType?.config_details?.length > 0 && (
                            <Accordions multiOpen={true} activeItem={state.activeAccordion} onChange={(e) => handleAccordionChange(e)}>
                                <AccordionItem
                                    itemId="Sanction_Details"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.config_details[0]?.include_sanction_details ? '' : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Sanction Details'} />
                                    <AccordionItemBody>
                                        <SanctionDetails
                                            mode={mode}
                                            recordID={recordID}
                                            saveFormData={saveFormsData}
                                            formStateObj={formState}
                                            updateErrorStatus={(status) => setErrorStatus(status)}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem
                                    itemId="Journey_Details"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.config_details[0]?.include_journey_details ? '' : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Journey Details'} />
                                    <AccordionItemBody>
                                        <JourneyDetails
                                            mode={mode}
                                            recordID={recordID}
                                            saveFormData={saveFormsData}
                                            formStateObj={formState}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem
                                    itemId="Transportation_Charges_of_Personnel_Effect"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.config_details[0]?.include_personnel_expenses ? '' : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Transportation Charges of Personnel Effect'} />
                                    <AccordionItemBody>
                                        <TCPE mode={mode} recordID={recordID} saveFormData={saveFormsData} formStateObj={formState} />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem
                                    itemId="Transportation_Charges_of_Private_Conveyance"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.config_details[0]?.include_private_expenses ? '' : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Transportation Charges of Private Conveyance'} />
                                    <AccordionItemBody>
                                        <TCPC mode={mode} recordID={recordID} saveFormData={saveFormsData} formStateObj={formState} />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem
                                    itemId="Stay_Details"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.config_details[0]?.include_stay_details ? '' : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Stay Details'} />
                                    <AccordionItemBody>
                                        <StayDetails
                                            mode={mode}
                                            recordID={recordID}
                                            saveFormData={saveFormsData}
                                            formStateObj={formState}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem
                                    itemId="Day_Allowance"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.config_details[0]?.include_day_allowances ? '' : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Day Allowance'} />
                                    <AccordionItemBody>
                                        <DayAllowances
                                            mode={mode}
                                            recordID={recordID}
                                            saveFormData={saveFormsData}
                                            formStateObj={formState}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem
                                    itemId="Transfer_Chargers"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.config_details[0]?.include_transfer_charges ? '' : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Transfer Chargers'} />
                                    <AccordionItemBody>
                                        <TransferChargers
                                            mode={mode}
                                            recordID={recordID}
                                            saveFormData={saveFormsData}
                                            formStateObj={formState}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>

                                <AccordionItem
                                    itemId="Family_Members"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.config_details[0]?.include_family_members ? '' : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Family Members'} />
                                    <AccordionItemBody>
                                        <FamilyMembers
                                            mode={mode}
                                            recordID={recordID}
                                            saveFormData={saveFormsData}
                                            formStateObj={formState}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem
                                    itemId="Additional_Bill_Details"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.include_additional_details &&
                                        additionalDetailsObj?.isVisibleObj &&
                                        Object.keys(additionalDetailsObj.isVisibleObj).some((k) => additionalDetailsObj.isVisibleObj[k])
                                            ? ''
                                            : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Additional Bill Details'} />
                                    <AccordionItemBody>
                                        <AdditionalBillDetails
                                            mode={mode}
                                            recordID={recordID}
                                            saveFormData={saveFormsData}
                                            formStateObj={formState}
                                            updateErrorStatus={(status) => setErrorStatus(status)}
                                            additionalDetailsObj={additionalDetailsObj}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                                {/* <AccordionItem itemId="Recoveries" className="accordian-item m-b-10 code-acc">
                                <AccordionItemHeader title={'Recoveries'} />
                                <AccordionItemBody>
                                    <Recoveries mode={mode} recordID={recordID} saveFormData={saveFormsData} formStateObj={formState} />
                                </AccordionItemBody>
                            </AccordionItem> */}
                                <AccordionItem
                                    itemId="Deductions"
                                    className={`accordian-item m-b-10 code-acc ${
                                        formState.BILLS?.billType?.include_deductions ? '' : 'display-none'
                                    }`}
                                >
                                    <AccordionItemHeader title={'Deductions'} />
                                    <AccordionItemBody>
                                        <Deductions
                                            mode={mode}
                                            recordID={recordID}
                                            saveFormData={saveFormsData}
                                            formStateObj={formState}
                                            deductions={state.deductions}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                            </Accordions>
                        )}
                        {formState.BILLS?.billType?.config_details?.length ? (
                            <div className="comment-header reviced-color">
                                <div>Net Payment : {getNetPayAmount()}</div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    <PersonnelBillActions
                        formState={formState}
                        handleLoader={handleLoader}
                        handleCancel={handleCancel}
                        showGrowl={showGrowl}
                        formType={mode}
                        setActionsStatus={setActionsStatus}
                        recordID={recordID}
                        navigateToEdit={navigateToEdit}
                        recID={recID}
                        entity_id={entityId}
                        version_no={versionNo}
                        version_user={versionUser}
                        errorStatus={errorStatus}
                        netPay={() => getNetPayAmount()}
                        location={props.location}
                        additionalDetailsObj={additionalDetailsObj}
                        thirdParties={thirdParties}
                        isDraft={isDraft}
                    />
                </>
            ) : (
                <>
                    {state.showPreviousBills ? (
                        <PreviousBills
                            {...props}
                            formState={formState}
                            handleBack={() => setState({ ...state, showPreviousBills: false })}
                            recordID={recID}
                        />
                    ) : (
                        ''
                    )}
                    {state.showThirdParty ? (
                        <ThirdParty
                            {...props}
                            formState={formState}
                            handleBack={() => setState({ ...state, showThirdParty: false })}
                            setThirdPartyPayments={(data) => setThirdParties(data)}
                            recordID={recID}
                            formType={mode}
                            netPay={() => (isNaN(parseFloat(getNetPayAmount())) ? 0 : parseFloat(getNetPayAmount()))}
                            growl={growl}
                            isDraft={isDraft}
                            thirdParties={thirdParties?.length > 0 ? thirdParties.filter((a) => a.beneficiary_id) : []}
                        />
                    ) : (
                        ''
                    )}
                </>
            )}
            {state.showPreview && (
                <PersonnelBillPreview
                    handleCancel={() => setState({ ...state, showPreview: false })}
                    showModal={state.showPreview}
                    dataObj={billDetails}
                    {...props}
                    formState={formState}
                    additionalDetailsObj={additionalDetailsObj}
                    netPay={() => getNetPayAmount()}
                />
            )}
        </div>
    );
};

export default CreatePersonnelBills;
