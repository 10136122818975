import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../TableSchema';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import ConfirmationModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../Validation';

const Deductions = (props) => {
    const [rows, setRows] = useState(props.formStateObj?.Deductions);
    useEffect(() => {
        setRows(props.formStateObj?.Deductions);
        // eslint-disable-next-line
    }, [props.formStateObj?.Deductions]);
    let viewMode = TableSchema.Deductions.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.mode !== 'view' ? TableSchema.Deductions : viewMode;
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false,
        deductions: []
    });
    let emptyObj = {
        deduction: '',
        dedValue: '',
        amount: '',
        remarks: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    const fields = [{ key: 'amount', validators: 'numbersOnly', maxLength: 15 }];

    function getdeductionOptions() {
        let prepData = JSON.parse(JSON.stringify(props.deductions));
        prepData.forEach((a) => {
            a.dedValue = a.value;
            delete a.value;
        });
        prepData = prepData.filter((a) => {
            return !rows.find((b) => b?.deduction?.id === a.id);
        });
        return prepData;
    }

    const validateFeild = (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);
        if (err[key] || (key === 'amount' && parseInt(ev) <= 0)) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        if (key === 'deduction') {
            currentDetailsObj.dedValue = ev.dedValue;
        }
        validateFeild(ev, key);
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const saveForm = () => {
        if (state.actionType === 'edit') {
            let indx = rows.findIndex((a) => a.deduction?.id === editObj.deduction?.id);
            rows[indx] = currentDetailsObj;
            setRows(rows);
        } else {
            rows.push(currentDetailsObj);
            setRows(rows);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Deductions', rows);
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                key !== 'dedValue' &&
                (currentDetailsObj[key] === undefined ||
                    currentDetailsObj[key] === '' ||
                    (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === ''))
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({
                ...state,
                showForm: true,
                actionType: sAction,
                deductions: [...getdeductionOptions(), newObj.deduction]
            });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = rows.filter((a) => {
            return a.deduction?.id !== currentDetailsObj.deduction?.id;
        });
        setRows(filteredRows);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Deductions', filteredRows);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true, deductions: getdeductionOptions() });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Deductions are yet to be created"
                    className="secondary-table"
                    scrollable
                    mode={props?.mode}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Deduction{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="deduction"
                                            value={currentDetailsObj.deduction}
                                            onChange={(e) => handleChange(e.value, 'deduction')}
                                            appendTo={document.self}
                                            options={state.deductions}
                                            optionLabel="name"
                                            placeholder="Select Deduction"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            {currentDetailsObj.deduction && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                            <b className="fs-14">
                                                Value
                                                {props.mode !== 'view' && props.mode !== 'edit' ? (
                                                    <span className="icon-primary"> *</span>
                                                ) : (
                                                    ' :'
                                                )}
                                            </b>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 fs-14 m-t-5 m-b-5">
                                            {currentDetailsObj.dedValue}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Amount{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="amount"
                                            type="text"
                                            value={currentDetailsObj.amount}
                                            maxLength={30}
                                            onChange={(e) => handleChange(e, 'amount')}
                                            placeholder="Enter Amount"
                                            hasError={errors && errors.amount}
                                            errMsg="Please enter valid Amount"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Remarks{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="remarks"
                                            type="text"
                                            value={currentDetailsObj.remarks}
                                            maxLength={30}
                                            onChange={(e) => handleChange(e, 'remarks')}
                                            placeholder="Enter Remarks"
                                            hasError={errors && errors.remarks}
                                            errMsg="Please enter valid Remarks"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.mode !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (rows?.length === 0 ? '' : 'Another ') + 'Deductions'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Deductions"
                    showModal={state.showDeleteModal}
                    name={'Deductions : Deduction: ' + currentDetailsObj.deduction?.name}
                />
            )}
        </div>
    );
};

export default Deductions;
