import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import '../../../styles/Dialog.scss';
import TableSchema from '../CMTableSchema';
import FDataTable from '../../../components/fDataTable/FDataTable';

export function ContactModal(props) {
    const [state] = useState({
        columns: TableSchema.CONTACT_MODAL,
        rows: props.rows
    });
    const [formState, setFormState] = useState({
        selectedRows: props.selectedRows
    });

    const hadleRowData = (data) => {
        setFormState({ ...formState, selectedRows: data });
    };

    return (
        <Modal visible={props.showModal} onCancel={props.closeModal} className="common_css xl-modal add_user" backdropClosable={false}>
            <ModalHeader>Select Contact</ModalHeader>
            <ModalBody>
                <div className="row m-0">
                    <div className="col-md-12 p-0">
                        <FDataTable
                            rows={state?.rows}
                            columns={state.columns}
                            emptyMessage="Contacts are yet to be created"
                            noPaginator={true}
                            updateInparent={hadleRowData}
                            selectedRows={formState.selectedRows}
                            selectionMode="radiobutton"
                            className="secondary-table"
                            scrollable={true}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className={props?.loading ? 'mask p-10' : 'p-10'}>
                <Button
                    size="sm"
                    kind="primary"
                    className="m-r-10"
                    onClick={() => {
                        props.saveFormData('supplierContact', formState.selectedRows);
                    }}
                    disabled={!formState.selectedRows}
                >
                    OK
                </Button>
                <Button size="sm" kind="secondary" className="" onClick={() => props.closeModal()}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ContactModal;
