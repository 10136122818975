import React, { useRef } from 'react';
import { Button } from 'appkit-react';
import IsAuthorized from '../../../commons/IsAuthorized';

const TopGridComponent = (props) => {
    const inputFile = useRef(null);
    const uploadBulkUsers = () => {
        inputFile.current.click();
    };
    const onChange = (e) => {
        props.onUploadClick(e);
    };

    const onClickRegisterUser = () => {
        props.onClickAdd();
    };

    return (
        <IsAuthorized
            privRequired={props.createPriv}
            officeCode={props.officeCode}
            yes={() => (
                <div className="row m-0 p-b-10">
                    <div className="col-md-12 p-0 text-right">
                        <img
                            src={process.env.PUBLIC_URL + '/assets/icons/download.svg'}
                            alt="download"
                            className="m-r-15 pointer upload-btn enaabled"
                            onClick={props.onClickDownload}
                            title="Download Users"
                        />
                        <img
                            src={process.env.PUBLIC_URL + '/assets/icons/upload.svg'}
                            alt="upload"
                            className="m-r-15 upload-btn pointer enabled"
                            onClick={uploadBulkUsers}
                            title="Import Users"
                        />
                        <input type="file" ref={inputFile} accept=".xlsx" onChange={onChange} className="display-none" />
                        <Button size="md" onClick={() => onClickRegisterUser()}>
                            Register New User
                        </Button>
                    </div>
                </div>
            )}
        ></IsAuthorized>
    );
};

export default TopGridComponent;
