import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'appkit-react';
import FDataTable from '../components/fDataTable/FDataTable';
import { ProgressSpinner } from 'primereact/progressspinner';
import TextAreaComponent from '../components/InputComponent/TextAreaComponent';
import '../pages/paymentManagement/PaymentManagement.scss';
import { Dropdown } from 'primereact/dropdown';

const CommonModal = (props) => {
    return (
        <div className={`${props.loading ? 'mask' : ''}`}>
            <Modal
                visible={props.showModal}
                maxwidth="md"
                onCancel={() => props.closeModal()}
                className={`common_css add_user ${props.modalClass}`}
                backdropClosable={false}
            >
                <ModalHeader>
                    {props.title ? (
                        <span className="light-color">{props.title}</span>
                    ) : (
                        <span className="light-color">
                            {props.label} <span className="p-l-5 light-color">{props.value ? `:${props.value}` : ''}</span>
                        </span>
                    )}
                </ModalHeader>
                <ModalBody className="m-body fs-15">
                    {props.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                    {props.type === 'table' && (
                        <>
                            {props.auditorName && (
                                <div className="row m-b-10">
                                    <label className="p-l-20">
                                        Auditor Name : <span>{props.auditorName}</span>
                                    </label>
                                </div>
                            )}
                            <div className="row m-0">
                                <div className="col-md-12 p-0">
                                    <FDataTable
                                        selectionMode={props.selectionMode}
                                        className={props.tableClassName ? props.tableClassName : 'primary-table'}
                                        updatePriv={props?.updatePriv}
                                        officeCode={props?.officeCode}
                                        rows={props?.rows}
                                        noPaginator={
                                            props?.noPaginator ? props?.noPaginator : props?.rows && props?.rows?.length > 5 ? false : true
                                        }
                                        columns={props.schema}
                                        onClickDetails={props?.onClickDetails}
                                        onClickDelete={props?.onClickDelete}
                                        loading={props.loading}
                                        isUpdateInParent={props?.isUpdateInParent}
                                        updateInparent={props?.updateSelctedRows}
                                        selectedRows={props?.selectedRows}
                                        emptyMessage="No Records Found"
                                        footerGroup={props?.footerGroup}
                                        scrollable={props?.scrollable ? props?.scrollable : false}
                                        customSort={props.customSort}
                                        rowGroupMode={props.rowGroup ? props.rowGroupMode : null}
                                        groupField={props.groupField}
                                        sortMode={props.sortMode}
                                        sortField={props.sortField}
                                        sortOrder={props.sortOrder}
                                    />
                                    <span className="fs-15">{props?.footerBottom && props?.footerTemplate()}</span>
                                </div>
                            </div>
                        </>
                    )}
                    {props.type === 'text' && (
                        <div>
                            {props.text}
                            {!props?.hideEdit && (
                                <img
                                    title="Edit"
                                    className="pointer m-l-5 m-r-5"
                                    src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                    alt="Edit Icon"
                                    height="20px"
                                    onClick={() => props.onClickEdit()}
                                />
                            )}
                        </div>
                    )}
                    {(props.type === 'Delete' || props.type === 'Text') && <div>{props.text}</div>}
                    {props.type === 'box' && (
                        <div className="w-100 row m-0">
                            {props.boxArray &&
                                props.boxArray.length > 0 &&
                                props.boxArray.map((box) => (
                                    <div key={box} className="w-50">
                                        <div className="rectangle">{box}</div>
                                    </div>
                                ))}
                        </div>
                    )}
                    {props.type === 'textarea' && (
                        <TextAreaComponent
                            textBoxSize="xl"
                            rows="3"
                            name={props.name}
                            type="text"
                            autoresize="true"
                            maxLength={window.INTERNAL_AUDIT ? window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT : '500'}
                            autoComplete="off"
                            placeholder={props.placeholder ? props.placeholder : 'Enter Remarks'}
                            value={props.inputValue}
                            onChange={(e) => props.onChange(e, props.name)}
                        />
                    )}
                    {props.type === 'reason' && (
                        <div className="w-100 row m-0">
                            <div className="w-30">
                                <label>
                                    <b>Reason</b>
                                    <span className="icon-primary">*</span>
                                </label>
                            </div>
                            <div className="w-70">
                                <TextAreaComponent
                                    textBoxSize="xl"
                                    rows="3"
                                    name={props.name}
                                    type="text"
                                    autoresize="true"
                                    autoComplete="off"
                                    maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                    placeholder="Enter Reason"
                                    value={props.inputValue}
                                    onChange={(e) => props.onChange(e, props.name)}
                                />
                            </div>
                        </div>
                    )}
                    {props.type === 'dropdown' && (
                        <div className="row">
                            <div className="col-md-4 m-t-10">
                                <b>
                                    {props.header}
                                    {props.isMandatory && <span className="icon-primary p-l-5">*</span>}
                                </b>
                            </div>
                            <div className="col-md-6">
                                <Dropdown
                                    name="tier"
                                    value={props.ddValue}
                                    onChange={(e) => props.handleChange(e.value, props.ddName)}
                                    options={props.ddOptions}
                                    optionLabel="type"
                                    placeholder={props.placeholder}
                                    className="w-100"
                                />
                            </div>
                        </div>
                    )}
                    {props.type === 'viewDetails' && (
                        <div className="row f-14">
                            <div className="col-md-6 m-b-10">
                                <b>{props.data.displayName ? props.data.displayName : ''}</b>
                            </div>
                            <div className="col-md-6 m-b-10">
                                <div>{props.data?.name ? props.data?.name : '–'}</div>
                            </div>
                            <div className="col-md-6">
                                <b>{props.data.displayDescription ? props.data.displayDescription : ''}</b>
                            </div>
                            <div className="col-md-6">
                                <div>{props.data?.description ? props.data?.description : '–'}</div>
                            </div>
                        </div>
                    )}
                    {props.modalBody && props.modalBody(props)}
                </ModalBody>
                <ModalFooter className="p-10">
                    {props.save && (
                        <Button size="sm" kind="primary" className="m-r-10" disabled={props.disabled} onClick={() => props.submit()}>
                            {props.saveText ? props.saveText : 'OK'}
                        </Button>
                    )}
                    <Button size="sm" kind="secondary" onClick={() => props.closeModal()} disabled={props.Canceldisabled}>
                        {props.cancelText ? props.cancelText : 'Close'}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
export default CommonModal;
