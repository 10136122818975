const pendingVA = process.env.PUBLIC_URL + '/assets/icons/PendingVA.svg';
const ActiceContracts = process.env.PUBLIC_URL + '/assets/icons/ActiceContracts.svg';
const ActiceContractsSel = process.env.PUBLIC_URL + '/assets/icons/ActiceContractsSel.svg';
const PendingVASel = process.env.PUBLIC_URL + '/assets/icons/PendingVASel.svg';
const TotalContractsSel = process.env.PUBLIC_URL + '/assets/icons/TotalContractsSel.svg';
const PendingWS = process.env.PUBLIC_URL + '/assets/icons/PendingWS.svg';
const PendingWSSel = process.env.PUBLIC_URL + '/assets/icons/PendingWSSel.svg';

const TopCardComponentItems = {
    CONTRACTS: [
        {
            count: 10,
            label: 'Total Contracts',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Contracts',
            selected: true
        },
        {
            count: 11,
            label: 'Active Contracts',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Active Contracts'
        },
        {
            count: 11,
            label: 'Pending With Self',
            defaultSrc: PendingWS,
            highlightSrc: PendingWSSel,
            hoverText: 'Pending With Self'
        },
        {
            count: 12,
            label: 'Pending or Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],

    USERS: [
        {
            count: 10,
            label: 'Approved Users',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Contracts',
            selected: true
        },
        {
            count: 11,
            label: 'Pending With Self',
            defaultSrc: PendingWS,
            highlightSrc: PendingWSSel,
            hoverText: 'Pending With Self'
        },
        {
            count: 12,
            label: 'Pending or Verfication/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verfication/Approval'
        }
    ],
    CONFIG_SUPPLY_CAT: [
        {
            count: 10,
            label: 'Total Supplier Categories',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Supplier Categories',
            selected: true
        },
        {
            count: 0,
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            count: 0,
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    SUPPLIER_REGIS: [
        {
            count: 10,
            label: 'Total Suppliers',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Supplier Categories',
            selected: true
        },
        {
            count: 11,
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            count: 12,
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    PYMNT_INST: [
        {
            countField: 'totalCount',
            label: 'Total PIs',
            default_src: pendingVA,
            highlight_src: TotalContractsSel,
            hoverText: 'Total PIs'
        },
        {
            countField: 'pendingCount',
            label: 'Total PIs (Pending with self)',
            default_src: ActiceContracts,
            highlight_src: ActiceContractsSel,
            hoverText: 'Total PIs (Pending with self)'
        },
        {
            countField: 'approvalCount',
            label: 'Total PIs (Pending for Verification/Approval)',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Total PIs (Pending for Verification/Approval)'
        }
    ],
    BILLS: [
        {
            countField: 'total_bills',
            label: 'Total Bills',
            default_src: 'pendingVA',
            highlight_src: 'TotalContractsSel',
            hoverText: 'Total Bills',
            selected: true
        },
        {
            countField: 'total_bills_pending_with_self',
            label: 'Total Bills (Pending with self)',
            default_src: 'ActiceContracts',
            highlight_src: 'ActiceContractsSel',
            hoverText: 'Total Bills (Pending with self)'
        }
    ],

    EXP_BILLS: [
        {
            countField: 'totalCount',
            label: 'Total Contract Bills',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Contract Bills',
            selected: true
        },
        {
            countField: 'pendingCount',
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            countField: 'approvalCount',
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    EXP_OTHER_BILLS: [
        {
            countField: 'totalCount',
            label: 'Total Approved',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Contract Bills',
            selected: true
        },
        {
            countField: 'pendingCount',
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            countField: 'approvalCount',
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    EXP_PERSONNEL_BILLS: [
        {
            countField: 'totalCount',
            label: 'Total Approved',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Personnel Bills',
            selected: true
        },
        {
            countField: 'pendingCount',
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            countField: 'approvalCount',
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    BENEFICIARY_DETAILS: [
        {
            countField: 'totalCount',
            label: 'Total Approved',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Benificiares',
            selected: true
        },
        {
            countField: 'pendingCount',
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            countField: 'approvalCount',
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verfication/Approval'
        }
    ],
    MASTERS_CARDS: [
        {
            count: 14,
            label: 'Approved Roles',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Supplier Categories',
            selected: true
        },
        {
            count: 13,
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            count: 14,
            label: 'Pending for Verfication/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    ECONOMIC_CLASSIFY: [
        {
            count: 14,
            label: 'Approved COA Economic Classification Configuration',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Supplier Categories',
            selected: true
        },
        {
            count: 13,
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            count: 14,
            label: 'Pending for Verfication/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    CONFIG_RPM: [
        {
            countField: 'totalCount',
            label: 'Total Remittance Purpose Mappings',
            default_src: pendingVA,
            highlight_src: TotalContractsSel,
            hoverText: 'Total Remittance Purpose Mappings'
        },
        {
            countField: 'pendingCount',
            label: 'Pending With Self',
            default_src: ActiceContracts,
            highlight_src: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            countField: 'approvalCount',
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    CONFIGURE_REPORT_AND_FIELDS: [
        {
            countField: 'totalCount',
            label: 'Total Configure Reports and Fields',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Configure Reports and Fieds',
            selected: true
        },
        {
            countField: 'pendingCount',
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            countField: 'approvalCount',
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    PERSONNEL_BILLS: [
        {
            countField: 'totalCount',
            label: 'Total Approved Personnel Bills',
            defaultSrc: pendingVA,
            highlightSrc: TotalContractsSel,
            hoverText: 'Total Contract Bills',
            selected: true
        },
        {
            countField: 'pendingCount',
            label: 'Pending With Self',
            defaultSrc: ActiceContracts,
            highlightSrc: ActiceContractsSel,
            hoverText: 'Pending With Self'
        },
        {
            countField: 'approvalCount',
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    CONTROL_DEFINITION: [
        {
            countField: 'totalCount',
            label: 'Total Controls',
            default_src: pendingVA,
            highlight_src: TotalContractsSel,
            hoverText: 'Total Controls'
        },
        {
            countField: 'pendingCount',
            label: 'Pending with self',
            default_src: ActiceContracts,
            highlight_src: ActiceContractsSel,
            hoverText: 'Pending with self'
        },
        {
            countField: 'approvalCount',
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ],
    Objective_Procedure_Mapping: [
        {
            countField: 'totalCount',
            label: 'Total Audit Objectives',
            default_src: pendingVA,
            highlight_src: TotalContractsSel,
            hoverText: 'Total Audit Objectives'
        },
        {
            countField: 'pendingCount',
            label: 'Pending with self',
            default_src: ActiceContracts,
            highlight_src: ActiceContractsSel,
            hoverText: 'Pending with self'
        },
        {
            countField: 'approvalCount',
            label: 'Pending for Verification/Approval',
            defaultSrc: pendingVA,
            highlightSrc: PendingVASel,
            hoverText: 'Pending for Verification/Approval'
        }
    ]
};
export default TopCardComponentItems;
