import React, { useState } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import Schema from '../../../schema/AppSchema';
import { RadioButton } from 'primereact/radiobutton';
import '../../contractManagementMasters/cmm.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import RemitanceSegs from './RemitanceSegs';
import { Toast } from 'primereact/toast';
import { Input } from 'appkit-react';
import { revenueClient, eventClient } from '../../../apollo';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';
import ActionsComponents from '../../../components/actionsComponent/ActionsComponents';
import { useHistory } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import WorklowSidebarContent from '../../../components/WorklowSidebarContent/WorklowSidebarContent';
import { getFormTypeAndRecordId } from '../../../services/commonService';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { convertTextCase } from '../../../util';

const CreateCoaSLMap = (props) => {
    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.REVENUE_MNGMNT.find((a) => loc[1].includes(a.routerLink));
    let transactionID = '';
    let history = useHistory();
    transactionID = localStorage.getItem('transaction_id');
    const formTypeAndId = getFormTypeAndRecordId(props.location.pathname);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    let isDraft = formTypeAndId['isDraft'];

    const [state, setState] = useState({
        loading: recordID === '' || isDraft ? true : false,
        searchText: {},
        segments: [],
        remitanceDetails: {},
        selectedSegments: {},
        allSelcted: false,
        remitanceSearch: {},
        remitanceSel: {},
        rSearch: '',
        rpmNo: '',
        showWorkflowStatus: false,
        listToShowComment: [],
        editData: ''
    });
    const [segQueryDat, setSegQueryData] = useState();
    const [growl, setGrowl] = useState();
    const [mode, setMode] = useState(formType);
    const [entityId, setEntityId] = useState('');
    const [recID, setID] = useState('');
    const [currentAction, setCurrentAction] = useState('');

    const { refetch: getRemitSegments } = useQuery(Schema.remitCodeDrafts, {
        fetchPolicy: 'no-cache',
        client: revenueClient,
        skip: recordID === '' || isDraft,
        variables: { where: { id: { equals: recordID } } },
        onCompleted: (resp) => {
            if (resp) {
                prepareData(resp);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    useQuery(Schema.entityRecord, {
        client: eventClient,
        skip: !isDraft,
        fetchPolicy: 'no-cache',
        variables: { where: { id: recordID } },
        onCompleted: (data) => {
            if (data?.entityRecord?.record) {
                setEntityId(data?.entityRecord?.record?.entity_id);
                prepareData(data.entityRecord.record);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const prepareData = (resp) => {
        let editData = isDraft ? resp : resp.remitCodeDrafts[0];
        if (editData.id) setID(editData.id);
        setState({
            ...state,
            editData: editData,
            rpmNo: resp?.remitCodeDrafts?.[0]?.status === 'Approved' && resp?.remitCodeDrafts?.[0]?.ref
        });
    };

    useQuery(Schema.rvnRemitSegDef, {
        client: revenueClient,
        variables: { where: { id: 'REM_SEGMENT_DEF' } },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.rvnRemitSegDef) {
                let arr = [];
                data.rvnRemitSegDef.seg_details.forEach((a) => {
                    arr.push({
                        segment_def_name: a.seg_def_name,
                        level_name: a.level_name
                    });
                });
                data.rvnRemitSegDef.seg_details = arr;
                setSegQueryData(data?.rvnRemitSegDef);
                getsegmentWiseData();
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [getsegmentWiseData, { error, loading: segmentsLoader }] = useLazyQuery(Schema.GetSegmentsForGivenLevels, {
        variables: { where: { list: segQueryDat?.seg_details }, function_code: 'RVN_REMITTANCE_PURPOSE_CONFIG', office_code: 'GLOBAL' },
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            if (res && res.GetSegmentsForGivenLevels?.compositionWiseSegments) {
                let data = {};
                let val = res.GetSegmentsForGivenLevels.compositionWiseSegments.find((a) => a.name === segQueryDat?.remit_seg_def_name);
                let list = res.GetSegmentsForGivenLevels.compositionWiseSegments.filter((a) => {
                    return a.name !== segQueryDat?.remit_seg_def_name;
                });
                data = {
                    sub_remit_level_name: segQueryDat.sub_remit_level_name,
                    remit_level_name: segQueryDat.remit_level_name,
                    remit_seg_def_name: segQueryDat.remit_seg_def_name,
                    remit_segments: [],
                    details: []
                };
                if (val) {
                    data.remit_segments = val.segments;
                    data.details = list;
                }

                data.remit_segments.forEach((b) => (b.filterLabel = b.full_code + '-' + b.name));
                let remitanceSeg = {
                    name: data.sub_remit_level_name,
                    level: data.remit_level_name,
                    segment: data.remit_seg_def_name,
                    remitances: data.remit_segments,
                    segments: [],
                    checked: false
                };
                data.details.forEach((a) => {
                    a.segments.map((b) => (b.filterLabel = b.full_code + '-' + b.name));
                });
                let segList = data.details;
                let remitData = isDraft ? state.editData?.code_details?.create : state.editData?.code_details;
                let remitID = isDraft
                    ? state.editData?.code_details?.create?.[3]?.segment_def_id
                    : state.editData?.code_details?.[3]?.segment_def_id;
                let remitselObj = isDraft ? state.editData?.code_details?.create?.[3] : state.editData?.code_details?.[3];
                segList.forEach((ele, ind) => {
                    ele.segments.forEach((obj) => {
                        if (obj.id === remitData?.[ind].segment_def_id) {
                            obj.checked = true;
                        }
                    });
                });
                remitanceSeg.remitances.forEach((obj) => {
                    obj.checked = false;
                    if (obj.id === remitID) {
                        obj.checked = true;
                        remitanceSeg['selectedAll'] = true;
                    }
                });
                if (mode !== 'create') fetchSubRemitanceCodes({ variables: { parentID: remitID } });
                setState((prevState) => {
                    return { ...prevState, segments: segList, remitanceDetails: remitanceSeg, remitanceSel: remitselObj, loading: false };
                });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [fetchSubRemitanceCodes] = useLazyQuery(Schema.GetRvnSubRemitCodes, {
        client: revenueClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.GetRvnSubRemitCodes && data.GetRvnSubRemitCodes.segments) {
                data.GetRvnSubRemitCodes.segments.forEach((b) => (b.filterLabel = b.full_code + '-' + b.name));
                state.remitanceDetails.segments = data.GetRvnSubRemitCodes.segments;
                let remitData = isDraft ? state.editData?.code_details?.create : state.editData?.code_details;
                if (mode !== 'create' && state.remitanceDetails.segments.length === remitData.length - 4) {
                    state.remitanceDetails.checked = true;
                }
                setState((prevState) => {
                    return {
                        ...prevState,
                        remitanceDetails: state.remitanceDetails,
                        loading: false,
                        selectedSegments: mode === 'view' && handleCheck()
                    };
                });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const handleSubFunChange = (e, name) => {
        state.segments.forEach((el) => {
            // eslint-disable-next-line
            return (
                el.name === name &&
                el.segments.filter((temp) => {
                    temp.checked = false;
                    if (e.value === temp.id) {
                        temp.checked = true;
                        el.checked = true;
                    }
                    return temp;
                })
            );
        });
        let allSelected = state.remitanceDetails.selectedAll && state.segments.every((item) => item.checked);
        setState({ ...state, segments: state.segments, allSelected: allSelected });
    };
    const handleRemitanceChange = (e) => {
        state.remitanceDetails['selectedAll'] = false;
        // eslint-disable-next-line
        state.remitanceDetails.remitances.filter((temp) => {
            temp.checked = false;
            if (e.value === temp.id) {
                temp.checked = true;
                state.remitanceDetails['selectedAll'] = true;
                state.remitanceSel = temp;
            }
        });
        state.remitanceDetails.segments = [];
        state.remitanceDetails.checked = false;
        let allSelected = state.remitanceDetails.selectedAll && state.segments.every((item) => item.checked);
        setState({ ...state, segments: state.segments, allSelected: allSelected, remitanceSel: state.remitanceSel, loading: true });
        fetchSubRemitanceCodes({ variables: { parentID: state.remitanceSel.id } });
    };

    const updateProps = (obj) => {
        setState({ ...state, selectedSegments: obj.selectedSegments });
    };
    const handleLoader = (status) => {
        setState({ ...state, loading: status });
    };

    const handleInputChange = (e, index) => {
        if (state.segments.length > 0) {
            state.segments.forEach((fun, i) => {
                if (fun?.segments?.length > 0) {
                    fun.segments.map((codes) => {
                        codes.hideMe = false;
                        if (index === i && !codes.filterLabel.toLowerCase().includes(e.toLowerCase())) {
                            codes.hideMe = true;
                        }
                        return codes;
                    });
                }
            });
            state.remitanceDetails.remitances.map((a) => (a.hideMe = false));
        }
        searchFilter(index, e, 'hoaSearch');
        setState({
            ...state,
            editData: state.editData,
            segments: state.segments,
            remitanceSearch: { [index]: e },
            rSearch: '',
            searchText: {},
            remitanceDetails: state.remitanceDetails
        });
    };

    const handleRSearchChange = (e) => {
        if (state.remitanceDetails.remitances.length > 0) {
            state.remitanceDetails.remitances.map((fun) => {
                fun.hideMe = false;
                if (!fun.filterLabel.toLowerCase().includes(e.toLowerCase())) {
                    fun.hideMe = true;
                }
                return fun;
            });
            state.segments.forEach((fun) => {
                fun.segments.forEach((codes) => {
                    codes.hideMe = false;
                });
            });
        }
        searchFilter(3, e, 'remitSearch');
        setState({
            ...state,
            editData: state.editData,
            remitanceDetails: state.remitanceDetails,
            rSearch: e,
            remitanceSearch: {},
            searchText: {},
            segments: state.segments
        });
    };

    const searchFilter = (index, e, type) => {
        let foundArr = isDraft ? state.editData?.code_details?.create : state.editData?.code_details;
        if (foundArr?.length > 0) {
            foundArr.forEach((fun, ind) => {
                fun.hideMe = false;
                let filterLabel = fun.segment_full_code + '-' + fun.segment_name;
                let found =
                    type === 'hoaSearch'
                        ? index === ind && !filterLabel.toLowerCase().includes(e.toLowerCase())
                        : !filterLabel.toLowerCase().includes(e.toLowerCase());
                let fieldKey = ind < 3 ? 'hoaSearch' : ind > 3 ? 'remittancesSearch' : 'remitSearch';
                if (found) {
                    fun.hideMe = type === fieldKey ? true : false;
                }
            });
        }
        return foundArr;
    };

    const [createOneRemitCodeDraft] = useMutation(Schema.createOneRemitCodeDraft, {
        fetchPolicy: 'no-cache',
        client: revenueClient,
        onCompleted: (data) => {
            if (data) {
                growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.SYSTEM_ADMINISTRATION_MASTERS.CreateRemittancePurposeMapping
                });
                setTimeout(() => {
                    history.push('/rm/rpm');
                }, 1000);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [updateOneRemitCodeDraft] = useMutation(Schema.updateOneRemitCodeDraft, {
        client: revenueClient,
        onCompleted: () => {
            setMode('view');
            let growlMsg = currentAction?.name
                ? currentAction?.name + window.SYSTEM_ADMINISTRATION_MASTERS.Action
                : window.SYSTEM_ADMINISTRATION_MASTERS.UpdateRemittancePurposeMapping;
            growl.show({ life: 6000, severity: 'success', summary: growlMsg });
            getRemitSegments().then((resp) => {
                if (resp) prepareData(resp.data);
            });
            setTimeout(() => {
                history.push('/rm/rpm');
            }, 1000);
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const navigateToEdit = () => {
        setMode('edit');
        setState({ ...state, rSearch: '', remitanceSearch: {}, searchText: {} });
        let foundArr = isDraft ? state.editData?.code_details?.create : state.editData?.code_details;
        if (foundArr?.length > 0) {
            foundArr.forEach((fun) => {
                fun.hideMe = false;
            });
        }
        state.remitanceDetails.remitances.map((a) => (a.hideMe = false));
        state.segments.forEach((fun) => {
            fun.segments.forEach((codes) => {
                codes.hideMe = false;
            });
        });
    };

    const handleCheck = () => {
        let checkObj = {};
        let arr = isDraft ? state.editData?.code_details?.create : state.editData?.code_details;
        arr &&
            arr.length > 0 &&
            arr.forEach((ele, ind) => {
                state.remitanceDetails &&
                    state.remitanceDetails?.segments?.length > 0 &&
                    state.remitanceDetails.segments.forEach((obj) => {
                        if (ind > 3 && ele.segment_def_id === obj.id) {
                            checkObj[ele.segment_def_id] = true;
                        }
                    });
            });
        return checkObj;
    };

    const handleCancel = () => {
        props.history.goBack();
    };

    const handleSave = (wfParams, action) => {
        setCurrentAction(action);
        setState({ ...state, loading: true });
        let dataObj = getRecord();
        dataObj.wf_params = wfParams;
        delete dataObj.entity_id;
        delete dataObj.version_no;
        if (state.editData?.version_no && state.editData.version_no > 0) {
            delete dataObj.id;
            updateOneRemitCodeDraft({ variables: { data: dataObj, where: { id: isDraft ? recID : recordID } } });
        } else {
            createOneRemitCodeDraft({ variables: { data: dataObj } });
        }
    };

    const getDisplayData = () => {
        return {
            version_user: state.editData.version_user
        };
    };

    const getRecord = () => {
        let dataObj = {};
        let segs = [];
        let subRemitSegs = [];
        let remitSegs = [];
        state.segments.forEach((a) => {
            let isExists = a.segments.find((b) => b.checked);
            if (isExists)
                segs.push({
                    segment_def_id: isExists.id,
                    level_name: a.level_name,
                    segment_def_name: a.name,
                    segment_name: isExists.name,
                    segment_full_code: isExists.full_code
                });
        });
        let filArr = state.remitanceDetails.remitances.filter((b) => b.checked);
        subRemitSegs.push({
            segment_def_id: filArr[0].id,
            level_name: state.remitanceDetails.level,
            segment_name: filArr[0].name,
            segment_full_code: filArr[0].full_code,
            segment_def_name: convertTextCase(state.remitanceDetails.segment, false)
        });
        for (let key in state.selectedSegments) {
            let isExists = state.remitanceDetails.segments.find((b) => b.id === key);
            if (isExists && state.selectedSegments[key]) {
                remitSegs.push({
                    segment_def_id: isExists.id,
                    level_name: state.remitanceDetails.name,
                    segment_name: isExists.name,
                    segment_full_code: isExists.full_code,
                    segment_def_name: convertTextCase(state.remitanceDetails.segment, false)
                });
            }
        }
        let arr = [...segs, ...subRemitSegs, ...remitSegs];
        dataObj = { code_details: { create: arr } };
        if (state.editData?.version_no) dataObj.version_no = state.editData.version_no;
        if (entityId) dataObj.entity_id = entityId;
        if (recID) dataObj.id = recID;
        return dataObj;
    };
    const disableButton = () => {
        let disable = false;
        if (
            !state.allSelected ||
            Object.keys(state.selectedSegments).length <= 0 ||
            !Object.keys(state.selectedSegments).some((k) => state.selectedSegments[k])
        )
            disable = true;
        return disable;
    };

    const prepareLabels = (ind, type) => {
        if (type === 'HOA1') {
            return (
                (isDraft
                    ? state.editData?.code_details?.create?.[ind]?.segment_full_code
                    : state.editData?.code_details?.[ind]?.segment_full_code) +
                '-' +
                (isDraft ? state.editData?.code_details?.create?.[ind]?.segment_name : state.editData?.code_details?.[ind]?.segment_name)
            );
        } else {
            return (
                (isDraft
                    ? state.editData?.code_details?.create?.[3]?.segment_full_code
                    : state.editData?.code_details?.[3]?.segment_full_code) +
                '-' +
                (isDraft ? state.editData?.code_details?.create?.[3]?.segment_name : state.editData?.code_details?.[3]?.segment_name)
            );
        }
    };

    const showWorkflow = (flag) => {
        setState({ ...state, showWorkflowStatus: flag });
    };

    const handleCommentClick = (selRow, flag) => {
        const lstToShowComment = state.listToShowComment;
        lstToShowComment[selRow.id] = flag;
        setState({ ...state, listToShowComment: lstToShowComment });
    };

    const clearSearchData = (value) => {
        setState({ ...state, searchText: value, rSearch: '', remitanceSearch: {} });
    };

    if (error) return `Error! ${error.message}`;

    const setActionsStatus = (hasActions) => {
        setState({ ...state, hasActions });
    };

    return (
        <div className={`page-body ${state.loading || segmentsLoader ? 'mask pos-rel' : 'pos-rel'}`}>
            {(state.loading || segmentsLoader) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <Toast ref={(el) => setGrowl(el)} />
            <div className="page-header">
                <span className="light-color">
                    {!state.loading && !segmentsLoader && (
                        <span className="light-color">
                            Remittance Purpose Mapping
                            {recordID &&
                                (transactionID && transactionID !== 'undefined' ? (
                                    <span className="p-l-5">: {transactionID}</span>
                                ) : (
                                    <span className="p-l-5">: {state.rpmNo}</span>
                                ))}
                        </span>
                    )}
                </span>

                <span className="backBtn pointer">
                    {recordID && state.editData?.version_no !== 0 && (
                        <img
                            alt=""
                            className="header-icon"
                            title="Workflow Stages"
                            src={process.env.PUBLIC_URL + '/assets/icons/workflow.svg'}
                            onClick={() => showWorkflow(true)}
                        ></img>
                    )}
                    <span
                        onClick={() => {
                            props.history.goBack();
                            localStorage.setItem('transaction_id', '');
                        }}
                    >
                        <span className="appkiticon icon-left-chevron-outline icon_size" />
                        Back
                    </span>
                </span>
            </div>

            <div className={`${state.hasActions || mode === 'create' ? 'page-content-ht-with-btns' : 'page-content-ht-without-btns'}`}>
                <div className="componentWrapper m-t-20">
                    <Sidebar
                        visible={state.showWorkflowStatus}
                        onHide={() => showWorkflow(false)}
                        position="right"
                        dismissable={true}
                        closeOnEscape={false}
                        showCloseIcon={false}
                    >
                        <WorklowSidebarContent
                            listToShowComment={state.listToShowComment}
                            showWorkflow={showWorkflow}
                            handleCommentClick={handleCommentClick}
                            sourceId={recordID}
                        />
                    </Sidebar>
                    <div className="header">HOA Selection</div>
                    <div className="page-header m-10">
                        <span className="head light-color">Remittance Purpose Mapping</span>
                    </div>
                    {/* {state?.segments?.length > 0 && ( */}
                    <div className="row m-l-10 m-t-15">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-l-0 p-r-0">
                            <div className="m-r-0">
                                <div className="row w-100 flex-row flex-nowrap radio-spacing">
                                    {state.segments.map((seg, ind) => (
                                        <div key={seg.name} className="col-xs-12 col-sm-8 col-md-8 col-lg-3 col-xl-3 m-b-15">
                                            <b>{convertTextCase(seg.name, false)}</b>
                                            <span className="map-search">
                                                <Input
                                                    className={`w-70 ${mode === 'view' ? 'mask pointer-none' : ''}`}
                                                    autoComplete="off"
                                                    name={'remitanceSearch' + ind}
                                                    value={state.remitanceSearch[ind] || ''}
                                                    onChange={(e) => handleInputChange(e, ind)}
                                                    placeholder={'Search By ' + seg.name}
                                                />
                                                <span className="appkiticon icon-primary icon-search-outline" />
                                            </span>
                                            {seg.segments.length <= 0 && (
                                                <div className="fs-12 m-t-20">
                                                    {'COA codes for '}
                                                    <b>
                                                        &apos;
                                                        {convertTextCase(seg.name, false)}
                                                        &apos;
                                                    </b>
                                                    {' are yet to be created'}
                                                </div>
                                            )}
                                            {mode === 'view' ? (
                                                <div className="p-t-10 word-ellipses fs-14  m-b-15">
                                                    <span
                                                        className={`${
                                                            (
                                                                isDraft
                                                                    ? state.editData?.code_details?.create?.[ind]?.hideMe
                                                                    : state.editData?.code_details?.[ind]?.hideMe
                                                            )
                                                                ? 'display-none'
                                                                : ''
                                                        }`}
                                                        title={prepareLabels(ind, 'HOA1')}
                                                    >
                                                        {prepareLabels(ind, 'HOA1')}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="slmapp-ht-slm p-t-10">
                                                    {seg.segments.map((fm, index) => (
                                                        <div
                                                            key={`${fm.name}${fm.code}${index}`}
                                                            className={`p-b-10 display-flex  ${fm.hideMe ? 'display-none' : ''}`}
                                                        >
                                                            <RadioButton
                                                                inputId={fm.id}
                                                                value={fm.id}
                                                                onChange={(e) => handleSubFunChange(e, seg.name)}
                                                                checked={fm.checked}
                                                            />
                                                            <label htmlFor={fm.id} className="p-radiobutton-label word-ellipses m-neg-4">
                                                                <EllipsisWithTooltip placement="auto">{fm.filterLabel}</EllipsisWithTooltip>
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    {state.remitanceDetails?.segment && (
                                        <div className="col-xs-12 col-sm-8 col-md-8 col-lg-4 col-xl-4">
                                            <b>
                                                {convertTextCase(state.remitanceDetails.segment, false)}
                                                &nbsp;({state.remitanceDetails?.level})
                                            </b>
                                            <span className="map-search">
                                                <Input
                                                    className={`w-70 ${mode === 'view' ? 'mask pointer-none' : ''}`}
                                                    autoComplete="off"
                                                    name={'rSearch'}
                                                    value={state.rSearch || ''}
                                                    onChange={(e) => handleRSearchChange(e)}
                                                    placeholder={'Search By ' + convertTextCase(state.remitanceDetails.segment, false)}
                                                />
                                                <span className="appkiticon icon-primary icon-search-outline" />
                                            </span>
                                            {state.remitanceDetails?.remitances?.length <= 0 && (
                                                <div className="fs-12 m-t-20">
                                                    {'COA codes for '}
                                                    <b>
                                                        &apos;
                                                        {convertTextCase(state.remitanceDetails.segment, false)}
                                                        &apos;
                                                    </b>
                                                    {' are yet to be created'}
                                                </div>
                                            )}
                                            {mode === 'view' ? (
                                                <div className="p-t-10 word-ellipses fs-14 m-b-15">
                                                    <span
                                                        className={`${
                                                            (
                                                                isDraft
                                                                    ? state.editData?.code_details?.create?.[3]?.hideMe
                                                                    : state.editData?.code_details?.[3]?.hideMe
                                                            )
                                                                ? 'display-none'
                                                                : ''
                                                        }`}
                                                        title={prepareLabels(3, 'HOA2')}
                                                    >
                                                        {prepareLabels(3, 'HOA2')}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="slmapp-ht-slm p-t-10">
                                                    {state.remitanceDetails.remitances.map((fm, index) => (
                                                        <div
                                                            key={`${fm.name}${fm.code}${index}`}
                                                            className={`p-b-10 display-flex ${fm.hideMe ? 'display-none' : ''}`}
                                                        >
                                                            <RadioButton
                                                                inputId={fm.id}
                                                                value={fm.id}
                                                                onChange={(e) => handleRemitanceChange(e)}
                                                                checked={fm.checked}
                                                            />
                                                            <label htmlFor={fm.id} className="p-radiobutton-label word-ellipses m-neg-4">
                                                                <EllipsisWithTooltip placement="auto">{fm.filterLabel}</EllipsisWithTooltip>
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-3 col-xl-3">
                                        <RemitanceSegs
                                            loading={state.loading}
                                            remitanceDetails={state.remitanceDetails}
                                            updateProps={updateProps}
                                            remitanceSel={state.remitanceSel}
                                            editData={state.editData}
                                            clearSearchData={clearSearchData}
                                            searchText={state.searchText}
                                            selectedSegments={handleCheck}
                                            searchKey="remittancesSearch"
                                            mode={mode}
                                            isDraft={isDraft}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* )} */}
                    {!state.loading && !segmentsLoader && state?.segments?.length <= 0 && !state.remitanceDetails?.segment && (
                        <div className="m-t-10 m-l-20 display-flex m-b-20">
                            <img src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
                            <span className="m-l-10 icon-primary font-15 info-message p_5">
                                {error?.graphQLErrors[0]?.message
                                    ? error?.graphQLErrors[0]?.message
                                    : 'Remittance Purpose Mappings are yet to created.'}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            {!state.loading && !segmentsLoader && state?.segments?.length > 0 && (
                <ActionsComponents
                    mode={mode}
                    growl={growl}
                    setActionsStatus={setActionsStatus}
                    module="Revenue Management"
                    fmis_function="RVN_REMITTANCE_PURPOSE_CONFIG"
                    fmis_sub_function="NA"
                    screenName={'Remittance Purpose Mapping'}
                    source_id={isDraft ? recID : recordID}
                    privRequired={Privileges.UPDATE}
                    officeCode={Privileges.OFFICE_CODE}
                    navigateToEdit={navigateToEdit}
                    onCancel={handleCancel}
                    onSave={(wfParams, actions) => handleSave(wfParams, actions)}
                    minFieldsRequired={mode === 'create' && disableButton()}
                    version_no={state.editData?.version_no}
                    version_user={state.editData?.version_user}
                    canDisable={mode === 'create' && disableButton()}
                    getRecord={getRecord}
                    getDisplayData={getDisplayData}
                    handleLoader={handleLoader}
                />
            )}
        </div>
    );
};

export default CreateCoaSLMap;
