import React, { useState, useRef, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import './CustomTreeSelect.scss';

const handleOnToogle = () => {
    document.getElementById('myDropdown').classList.toggle('show');
    document.getElementById('myDropdownsel').classList.toggle('opened');
};

const CustomTreeSelect = (props) => {
    const [state, setState] = useState({
        selectedValues: [],
        searchKey: ''
    });
    const dropdownListRef = useRef();
    const dropdownRef = useRef();

    function useOutsideAlerter(ref, ref1) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && !ref1.current.contains(event.target)) {
                    document.getElementById('myDropdown').classList.remove('show');
                    document.getElementById('myDropdownsel').classList.remove('opened');
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref, ref1]);
    }

    const handleChange = (e, parentLabel) => {
        let value = e.value;
        if (parentLabel) value = parentLabel + ' - ' + value;
        let selectedValues = [...state.selectedValues];
        if (e.checked) selectedValues.push(value);
        else selectedValues.splice(selectedValues.indexOf(value), 1);
        setState({
            ...state,
            searchKey: '',
            selectedValues: selectedValues
        });
        props.updateParentState(selectedValues);
    };

    const removeFMISFunction = (functionID) => {
        const items = state.selectedValues.filter((item) => item !== functionID);
        setState({ ...state, selectedValues: items });
    };

    const selectedCount = () => {
        if (state.selectedValues.length > 0) {
            return (
                <span className="self-align p-l-10">
                    {state.selectedValues.length + (state.selectedValues.length === 1 ? ' item selected' : ' items selected')}
                    <span id="toogleIcon" className="appkiticon m-r-10 m-l-auto arrow-icon icon-down-chevron-fill"></span>
                </span>
            );
        }
        return (
            <span className="self-align p-l-10">
                -Select-
                <span id="toogleIcon" className="appkiticon m-r-10 m-l-auto arrow-icon icon-down-chevron-fill"></span>
            </span>
        );
    };

    const searchSubFunction = (subFunctions) => {
        return subFunctions.some((sub) => sub.label.toLowerCase().indexOf(state.searchKey.toLowerCase()) !== -1);
    };

    const customList = (list, parentLabel) => {
        return (
            <ul>
                {list?.length > 0 &&
                    list.map(
                        (seg, ind) =>
                            (state.searchKey === '' ||
                                (!seg?.subFunctions && seg.label.toLowerCase().indexOf(state.searchKey.toLowerCase()) !== -1) ||
                                (state.searchKey !== '' && seg?.subFunctions && searchSubFunction(seg?.subFunctions))) && (
                                <div key={ind} className="cus-item-wraper p-t-10">
                                    <li>
                                        {(!seg.subFunctions || seg?.subFunctions?.length <= 0) && (
                                            <Checkbox
                                                className="m-r-10"
                                                inputId={seg.label}
                                                value={seg.label}
                                                onChange={(e) => handleChange(e, parentLabel)}
                                                checked={
                                                    parentLabel
                                                        ? state.selectedValues.includes(parentLabel + ' - ' + seg.label)
                                                        : state.selectedValues.includes(seg.label)
                                                }
                                            ></Checkbox>
                                        )}
                                        <span
                                            htmlFor={seg.label}
                                            className={`${
                                                seg?.subFunctions?.length > 0 ? (state[seg.label + 'toggle'] ? 'caret-down' : 'caret') : ''
                                            } `}
                                            onClick={() => setState({ ...state, [seg.label + 'toggle']: !state[seg.label + 'toggle'] })}
                                        >
                                            {seg.label}
                                        </span>
                                        <ul className={state[seg.label + 'toggle'] ? 'show' : 'display-none'}>
                                            <li> {customList(seg.subFunctions, seg.label)}</li>
                                        </ul>
                                    </li>
                                </div>
                            )
                    )}
            </ul>
        );
    };

    useOutsideAlerter(dropdownListRef, dropdownRef);
    return (
        <div>
            <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-6 col-lg-3 col-xl-3">
                    <b>
                        FMIS Function <span className="icon-primary p-l-5">*</span>
                    </b>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-6 col-lg-2 col-xl-3">
                    <div>
                        <div
                            id="myDropdownsel"
                            ref={dropdownRef}
                            className={`customdropdown w-100 ${props.options.length === 0 ? 'opacity-5' : 'cur-point'}`}
                            onClick={() => props.options.length > 0 && handleOnToogle()}
                        >
                            {selectedCount()}
                        </div>
                        <div id="myDropdown" ref={dropdownListRef} className="dropdown-content">
                            <div className="search-bar">
                                <input
                                    className="w-90"
                                    name="codesearch"
                                    placeholder="Search.."
                                    value={state.searchKey}
                                    onChange={(e) => setState({ ...state, searchKey: e.target.value })}
                                />
                                <span className="appkiticon search-icon icon-search-outline" />
                            </div>
                            {customList(props.options)}
                        </div>
                    </div>
                </div>
            </div>
            {state.selectedValues && state.selectedValues.length > 0 && (
                <div className="row">
                    <div className="m-t-10 col-md-7 col-sm-12 col-xs-12 col-lg-5 col-xl-6">
                        <div className="fmis-functions-div p-10">
                            {state.selectedValues.map((selectedFunction, ind) => {
                                if (selectedFunction === '') return null;
                                return (
                                    <span key={ind} className="selected-fmis-function m-10">
                                        {selectedFunction}
                                        <span
                                            className="icon-primary appkiticon icon-circle-delete-fill m-l-5 pointer"
                                            onClick={() => !state.viewData && removeFMISFunction(selectedFunction)}
                                        ></span>
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomTreeSelect;
