/* eslint-disable max-depth */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel } from 'appkit-react';
import CarouselTemplate from './CarouselTemplate';
import { setSubDashboardConfigList } from '../../commons/commons';
import { useQuery } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import { eventClient } from '../../apollo';
import { useDispatch } from 'react-redux';
import { loadSpinner } from '../../app-redux/app-action';
import './SubDashboard.scss';
import { RoutingSchema } from './RoutingSchema';
import store from '../../index';
import { ConfigConstants } from '../../constants/configurationConstant';

export default function SystemAdministration() {
    let history = useHistory();
    let dashboardType = history.location.pathname.split('/')[1];
    const [loading, setLoading] = useState(true);
    let dispatch = useDispatch();
    const sysAdminConfigData = JSON.parse(localStorage.getItem('APP_CONFIGURATION_VALUES'))?.filter(
        (configValues) => configValues.code.split('_')[0] === 'SUB'
    );
    const responsiveOptions = [
        {
            breakpoint: '2560px',
            numVisible: 5,
            numScroll: 3
        },
        {
            breakpoint: '1920px',
            numVisible: 5,
            numScroll: 3
        },
        {
            breakpoint: '1440px',
            numVisible: 4,
            numScroll: 2
        },
        {
            breakpoint: '1280px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 2
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const [subDashTopCards, setSubDashTopCards] = useState([
        {
            key: 'CONTRACT_MANAGEMENT',
            icon: 1,
            countType: 'CNTR_SUPPLIER_CTGRY_MASTER',
            type: 'Supplier Management',
            name: 'Total Configure Supplier Categories',
            count: 0,
            priv: 'CNTR_SUPPLIER_CTGRY_MASTER'
        },
        {
            key: 'CONTRACT_MANAGEMENT',
            icon: 2,
            countType: 'CNTR_SUPPLIER_REGISTRATION',
            type: 'Supplier Management',
            name: 'Total Suppliers',
            count: 0,
            priv: 'CNTR_SUPPLIER_REGISTRATION'
        },
        {
            key: 'CONTRACT_MANAGEMENT',
            icon: 1,
            countType: 'CNTR_CONTRACT_CREATION',
            type: 'Contract Management',
            name: 'Total Contracts',
            count: 0,
            priv: 'CNTR_CONTRACT_CREATION'
        },
        {
            key: 'CONTRACT_MANAGEMENT',
            icon: 2,
            countType: 'SYS_SUPPLY_CTGRY_MASTER',
            type: 'Contract Management',
            name: 'Total Supplier Category masters',
            count: 0,
            priv: 'SYS_SUPPLY_CTGRY_MASTER'
        },
        {
            key: 'PAYMENT_MANAGEMENT',
            icon: 1,
            countType: 'PAYMENT_INSTRUCTION',
            type: 'Payment Management',
            name: 'Payment Instructions',
            count: 0,
            priv: 'PAYMENT_INSTRUCTION'
        },
        {
            key: 'EMPLOYEE_MANAGEMENT',
            icon: 1,
            countType: 'EMP_PAYROLL_VOUCHER',
            type: 'Expenditure Management',
            name: 'Payroll Vouchers',
            count: 0,
            priv: 'EMP_PAYROLL_VOUCHER'
        },
        {
            key: 'EXPENDITURE_MANAGEMENT',
            icon: 2,
            countType: 'EXP_PERSONNEL_BILL',
            type: 'Expenditure Management',
            name: 'Personnel Bills',
            count: 0,
            priv: 'EXP_PERSONNEL_BILL'
        },
        {
            key: 'EXPENDITURE_MANAGEMENT',
            icon: 1,
            countType: 'EXP_CONTRACT_ADVANCE_BILL,EXP_CONTRACT_RUNNING_BILL,EXP_CONTRACT_FINAL_BILL,EXP_CONTRACT_SEC_DEPOSIT_REFUND',
            type: 'Expenditure Management',
            name: 'Contract Bills',
            count: 0,
            priv: 'EXP_CONTRACT_BILL_CREATION'
        },
        {
            key: 'EXPENDITURE_MANAGEMENT',
            icon: 2,
            countType: 'EXP_OTHER_BILL_CREATION',
            type: 'Expenditure Management',
            name: 'Other Bills',
            count: 0,
            priv: 'EXP_OTHER_BILL_CREATION'
        },
        {
            key: 'EXPENDITURE_MANAGEMENT',
            icon: 1,
            countType: 'EXP_IMPREST_RELEASE_CREATION',
            type: 'Expenditure Management',
            name: 'Imprest / Petty Cash Release',
            count: 0,
            priv: 'EXP_IMPREST_RELEASE_CREATION'
        },
        {
            key: 'EXPENDITURE_MANAGEMENT',
            icon: 2,
            countType: 'EXP_IMPREST_ADJ_CREATION',
            type: 'Expenditure Management',
            name: 'Imprest / Petty Cash Adjustment ',
            count: 0,
            priv: 'EXP_IMPREST_ADJ_CREATION'
        },
        {
            key: 'EXPENDITURE_MANAGEMENT',
            icon: 1,
            countType: 'EXP_PERSONNEL_BILL_MASTER',
            type: 'Expenditure Management',
            name: 'Personnel Bills Master',
            count: 0,
            priv: 'EXP_PERSONNEL_BILL_MASTER'
        },
        {
            key: 'EXPENDITURE_MANAGEMENT',
            icon: 2,
            countType: 'EXP_CONTRACT_BILL_MASTER',
            type: 'Expenditure Management',
            name: 'Contract Bills Master',
            count: 0,
            priv: 'EXP_CONTRACT_BILL_MASTER'
        },
        {
            key: 'EXPENDITURE_MANAGEMENT',
            icon: 1,
            countType: 'EXP_OTHER_BILL_MASTER',
            type: 'Expenditure Management',
            name: 'Other Bills Master ',
            count: 0,
            priv: 'EXP_OTHER_BILL_MASTER'
        },
        {
            key: 'COA',
            icon: 1,
            countType: 'COA_ECONOMIC_CODES_MASTER',
            type: 'System Administration',
            name: 'Total COA Economic Classification Configuration',
            count: 0,
            priv: 'COA_ECONOMIC_CODES_MASTER'
        },
        {
            key: 'SYSTEM_ADMINISTRATION',
            icon: 2,
            countType: 'SYS_ROLE_MASTER',
            type: 'System Administration',
            name: 'Total Roles',
            count: 0,
            priv: 'SYS_ROLE_MASTER'
        },
        {
            key: 'SYSTEM_ADMINISTRATION',
            icon: 1,
            countType: 'SYS_USER_MASTER',
            type: 'System Administration',
            name: 'Total Users',
            count: 0,
            priv: 'SYS_USER_MASTER'
        },
        {
            key: 'SYSTEM_ADMINISTRATION',
            icon: 2,
            countType: 'SYS_ADVANCE_MASTER',
            type: 'System Administration',
            name: 'Total Advance Masters',
            count: 0,
            priv: 'SYS_ADVANCE_MASTER'
        },
        {
            key: 'SYSTEM_ADMINISTRATION',
            icon: 1,
            countType: 'SYS_BENEFICIARY_DETAILS',
            type: 'System Administration',
            name: 'Total Beneficiary Details',
            count: 0,
            priv: 'SYS_BENEFICIARY_DETAILS'
        },
        {
            key: 'SYSTEM_ADMINISTRATION',
            icon: 2,
            countType: 'SYS_DEDUCTION_MASTER',
            type: 'System Administration',
            name: 'Total Deduction Masters',
            count: 0,
            priv: 'SYS_DEDUCTION_MASTER'
        },
        {
            key: 'SYSTEM_ADMINISTRATION',
            icon: 1,
            countType: 'SYS_DOC_CATEGORY',
            type: 'System Administration',
            name: 'Total Document Categories',
            count: 0,
            priv: 'SYS_DOC_CATEGORY'
        },
        {
            key: 'EMPLOYEE_MANAGEMENT',
            icon: 1,
            countType: 'EMP_CREATION',
            type: 'Employee Management',
            name: 'Total Employees',
            count: 0,
            priv: 'EMP_CREATION'
        },
        {
            key: 'EMPLOYEE_MANAGEMENT',
            icon: 2,
            countType: 'EMP_PAY_ENTITLEMENT_CHANGE',
            type: 'Employee Management',
            name: 'Total Pay Entitlement Changes',
            count: 0,
            priv: 'EMP_PAY_ENTITLEMENT_CHANGE'
        },
        {
            key: 'EMPLOYEE_MANAGEMENT',
            icon: 1,
            countType: 'EMP_PAY_COMMISSION_MASTER',
            type: 'Employee Management',
            name: 'Total Pay Commissions',
            count: 0,
            priv: 'EMP_PAY_COMMISSION_MASTER'
        },
        {
            key: 'EMPLOYEE_MANAGEMENT',
            icon: 2,
            countType: 'EMP_PAY_ENTITLEMENT_MASTER',
            type: 'Employee Management',
            name: 'Total Pay Entitlement Master',
            count: 0,
            priv: 'EMP_PAY_ENTITLEMENT_MASTER'
        },
        {
            key: 'EMPLOYEE_MANAGEMENT',
            icon: 1,
            countType: 'EMP_PAY_COMPONENT_MASTER',
            type: 'Employee Management',
            name: 'Total Pay Components',
            count: 0,
            priv: 'EMP_PAY_COMPONENT_MASTER'
        },
        {
            key: 'EMPLOYEE_MANAGEMENT',
            icon: 2,
            countType: 'EMP_GROUP_MASTER',
            type: 'Employee Management',
            name: 'Total Employee Groups',
            count: 0,
            priv: 'EMP_GROUP_MASTER'
        },
        {
            key: 'REVENUE_MANAGEMENT',
            icon: 1,
            countType: 'RVN_REMITTANCE_PURPOSE_CONFIG',
            type: 'Revenue Management',
            name: 'Total Remittance Purpose Mappings',
            count: 0,
            priv: 'RVN_REMITTANCE_PURPOSE_CONFIG'
        },
        {
            key: 'REVENUE_MANAGEMENT',
            icon: 2,
            countType: 'RVN_REPORT_CONFIG',
            type: 'Revenue Management',
            name: 'Total Configure Reports and Fields',
            count: 0,
            priv: 'RVN_REPORT_CONFIG'
        },
        {
            key: 'GLOBAL_MASTERS',
            icon: 1,
            countType: 'IA_RISK_DEFINITION',
            type: 'Masters',
            name: 'Total Risk Definitions',
            count: 0,
            priv: 'IA_RISK_DEFINITION'
        },
        {
            key: 'GLOBAL_MASTERS',
            icon: 2,
            countType: 'IA_CONTROLS_DEFINITION',
            type: 'Masters',
            name: 'Total Control Definitions',
            count: 0,
            priv: 'IA_CONTROLS_DEFINITION'
        },
        {
            key: 'GLOBAL_MASTERS',
            icon: 1,
            countType: 'IA_AUDIT_OBJECTIVE_DEF',
            type: 'Masters',
            name: 'Total Audit Objectives',
            count: 0,
            priv: 'IA_AUDIT_OBJECTIVE_DEF'
        },
        {
            key: 'GLOBAL_MASTERS',
            icon: 2,
            countType: 'IA_AUDIT_PROCEDURE_DEF',
            type: 'Masters',
            name: 'Total Controls and Procedure Mappings',
            count: 0,
            priv: 'IA_AUDIT_PROCEDURE_DEF'
        },
        {
            key: 'GLOBAL_MASTERS',
            icon: 1,
            countType: 'IA_OBJECTIVE_PROCEDURE_DEF_MAPPING',
            type: 'Masters',
            name: 'Total Controls and Objectives Mappings',
            count: 0,
            priv: 'IA_OBJECTIVE_PROCEDURE_DEF_MAPPING'
        },
        {
            key: 'AUDIT_UNIVERSE_MANAGEMENT',
            icon: 2,
            countType: 'IA_GUIDELINE_AUDIT_OPINION',
            type: 'Masters',
            name: 'Total Guideline For Opinion',
            count: 0,
            priv: 'IA_GUIDELINE_AUDIT_OPINION'
        },
        {
            key: 'AUDIT_UNIVERSE_MANAGEMENT',
            icon: 1,
            countType: 'IA_KEY_STAKE_HOLDER',
            type: 'Masters',
            name: 'Total Key Stakeholders',
            count: 0,
            priv: 'IA_KEY_STAKE_HOLDER'
        },
        {
            key: 'AUDIT_UNIVERSE_MANAGEMENT',
            icon: 2,
            countType: 'IA_MDA_PROFILE',
            type: 'Masters',
            name: 'Total MDA Profiles',
            count: 0,
            priv: 'IA_MDA_PROFILE'
        },
        {
            key: 'RESOURCE_MANAGEMENT',
            icon: 1,
            countType: 'IA_LOCATION_ALLOWANCE_MAPPING',
            type: 'Masters',
            name: 'Total Grade-Allowance Configurations',
            count: 0,
            priv: 'IA_LOCATION_ALLOWANCE_MAPPING'
        },
        {
            key: 'RESOURCE_MANAGEMENT',
            icon: 2,
            countType: 'IA_RATE_CARD',
            type: 'Masters',
            name: 'Total Rate Cards',
            count: 0,
            priv: 'IA_RATE_CARD'
        },
        {
            key: 'STRATEGIC_AUDIT_PLAN',
            icon: 1,
            countType: 'IA_RISK_MATURITY_ASSESSMENT',
            type: 'Strategic and Annual Audit Planning',
            name: 'Total Risk Maturity Assessments',
            count: 0,
            priv: 'IA_RISK_MATURITY_ASSESSMENT'
        },
        {
            key: 'STRATEGIC_AUDIT_PLAN',
            icon: 2,
            countType: 'IA_AUDIT_PLAN_SCHEDULE',
            type: 'Strategic and Annual Audit Planning',
            name: 'Total Audit Plan Schedules',
            count: 0,
            priv: 'IA_AUDIT_PLAN_SCHEDULE'
        },
        {
            key: 'STRATEGIC_AUDIT_PLAN',
            icon: 1,
            countType: 'IA_RISK_ASSESSMENT',
            type: 'Strategic and Annual Audit Planning',
            name: 'Total Risk Assessments',
            count: 0,
            priv: 'IA_RISK_ASSESSMENT'
        },
        {
            key: 'STRATEGIC_AUDIT_PLAN',
            icon: 2,
            countType: 'IA_STRATEGIC_AUDIT_PLAN',
            type: 'Strategic and Annual Audit Planning',
            name: 'Total Strategic Audit Plans',
            count: 0,
            priv: 'IA_STRATEGIC_AUDIT_PLAN'
        },
        {
            key: 'STRATEGIC_AUDIT_PLAN',
            icon: 1,
            countType: 'IA_ANNUAL_AUDIT_PLAN',
            type: 'Strategic and Annual Audit Planning',
            name: 'Total Annual Audit Plans',
            count: 0,
            priv: 'IA_ANNUAL_AUDIT_PLAN'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_SCHEDULE_AAP_SUBASSIGNMENT',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Scheduled Audits',
            count: 0,
            priv: 'IA_SCHEDULE_AAP_SUBASSIGNMENT'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_FOLLOW_UP_AAP_SUBASSIGNMENT',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Follow-Up Audits',
            count: 0,
            priv: 'IA_FOLLOW_UP_AAP_SUBASSIGNMENT'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_AAP_ADMIN_ACTIVITY_EXEC',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Administrative Activities',
            count: 0,
            priv: 'IA_AAP_ADMIN_ACTIVITY_EXEC'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_AUDIT_REGISTER',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Registers',
            count: 0,
            priv: 'IA_AUDIT_REGISTER'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_COI_DECLARATION',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Conflict of Interest Declarations',
            count: 0,
            priv: 'IA_COI_DECLARATION'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_AUDIT_COMMENCEMENT_FORM',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Resources and Schedules',
            count: 0,
            priv: 'IA_AUDIT_COMMENCEMENT_FORM'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_REQUEST_LIST_RESPONSE',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Request List Responses',
            count: 0,
            priv: 'IA_REQUEST_LIST_RESPONSE'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_SUMMARY_AUDITABLE_AREA',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Summary Auditable Areas',
            count: 0,
            priv: 'IA_SUMMARY_AUDITABLE_AREA'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_SUMMARY_AUDIT_SUBJECT',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Summary Audit Subjects',
            count: 0,
            priv: 'IA_SUMMARY_AUDIT_SUBJECT'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_AUDIT_SCOPE',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Scopes',
            count: 0,
            priv: 'IA_AUDIT_SCOPE'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_INHERENT_RISK',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Inherent Risks',
            count: 0,
            priv: 'IA_INHERENT_RISK'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_CONTROL_ASSESSMENT',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Control Assessments',
            count: 0,
            priv: 'IA_CONTROL_ASSESSMENT'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_RESIDUAL_RISK_ASSESSMENT',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Residual Risk Assessments',
            count: 0,
            priv: 'IA_RESIDUAL_RISK_ASSESSMENT'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_RISK_ASSESSMENT_CONCLUSION',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Risk Assessment Conclusions',
            count: 0,
            priv: 'IA_RISK_ASSESSMENT_CONCLUSION'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_AUDIT_ASSIGNMENT_PLAN',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Assignment Plans',
            count: 0,
            priv: 'IA_AUDIT_ASSIGNMENT_PLAN'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_AUDIT_PROGRAM',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Programs',
            count: 0,
            priv: 'IA_AUDIT_PROGRAM'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_AUDIT_TEST_WORKSHEET',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Test Worksheets',
            count: 0,
            priv: 'IA_AUDIT_TEST_WORKSHEET'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_AUDIT_TEST_RESULT',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Test Results',
            count: 0,
            priv: 'IA_AUDIT_TEST_RESULT'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_AUDIT_FINDING',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Findings',
            count: 0,
            priv: 'IA_AUDIT_FINDING'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_AUDIT_MEETING',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Meetings',
            count: 0,
            priv: 'IA_AUDIT_MEETING'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_REPORT_ACTUAL',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Report Actuals',
            count: 0,
            priv: 'IA_REPORT_ACTUAL'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 1,
            countType: 'IA_AUDIT_CLOSURE_FORM',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Audit Closure Forms',
            count: 0,
            priv: 'IA_AUDIT_CLOSURE_FORM'
        },
        {
            key: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
            icon: 2,
            countType: 'IA_REPORT',
            type: 'Audit Assignment Planning and Execution',
            name: 'Total Reports',
            count: 0,
            priv: 'IA_REPORT'
        }
    ]);

    const getRouter = (item) => {
        let userPrivileges = store.getState()?.appReducer?.auth?.privileges;
        if (item.menuItems && item.menuItems.length > 0) {
            for (let i = 0; i < item.menuItems.length; i++) {
                const element = item.menuItems[i];
                if (element.routerLink && userPrivileges[element.VIEW]) {
                    return element.routerLink;
                } else if (element.subMenu) {
                    for (let j = 0; j < element?.subMenu?.length; j++) {
                        const subele = element.subMenu[j];
                        if (subele.routerLink && userPrivileges[subele.VIEW]) {
                            return subele.routerLink;
                        }
                    }
                }
            }
        }
        return item.routerLink;
    };

    useQuery(Schema.dashboard_stats, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.dashboard_stats) {
                if (subDashTopCards && subDashTopCards.length > 0) {
                    subDashTopCards.forEach((ele) => {
                        for (const [key, value] of Object.entries(data.dashboard_stats)) {
                            let countTypes = ele.countType.split(',');
                            countTypes.forEach((countType) => {
                                if (value[countType] && ele.key === key) {
                                    ele.count += value[countType];
                                }
                            });
                        }
                    });
                }
                setSubDashTopCards(subDashTopCards);
                setLoading(false);
            }
        },
        onError: () => {
            setLoading(false);
        }
    });
    if (loading) dispatch(loadSpinner(false));
    return (
        <div>
            <div className="row bg-wight m-0">
                <div className="cust-container">
                    <div className="col-md-12 p-t-15 p-b-15">
                        <span className="appkiticon icon-primary icon-home-fill pointer" onClick={() => history.push('/dashboard')} />
                        &nbsp; / &nbsp;<span>{RoutingSchema[dashboardType].name}</span>
                    </div>
                </div>
            </div>

            <div className="row title-grid-bg m-0">
                <div className="cust-container image-grid">
                    {RoutingSchema[dashboardType].name === 'System Administration' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/sysAdminUser.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="c-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle="1"
                            dashboardType={dashboardType}
                        ></CarouselTemplate>
                    )}
                    {RoutingSchema[dashboardType].name === 'Revenue Management' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/dashboardRevenueIcon.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="c-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle=" "
                        ></CarouselTemplate>
                    )}
                    {RoutingSchema[dashboardType].name === 'General Ledger' && (
                        <div className="col-md-12">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/icons/dashboardRevenueIcon.svg'}
                                alt={RoutingSchema[dashboardType].name}
                                className="c-logo"
                            />
                            <span className="contract-title">{RoutingSchema[dashboardType].name}</span>
                        </div>
                    )}
                    {RoutingSchema[dashboardType].name === 'Payment Management' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/paymentManageIconSel.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="e-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle=" "
                        ></CarouselTemplate>
                    )}
                    {RoutingSchema[dashboardType].name === 'Expenditure Management' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/billsManageWhite.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="c-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle="1"
                        ></CarouselTemplate>
                    )}
                    {RoutingSchema[dashboardType].name === 'Supplier Management' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/contractManagelight.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="e-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle="1"
                        ></CarouselTemplate>
                    )}
                    {RoutingSchema[dashboardType].name === 'Employee Management' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/EmpLight.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="c-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle="1"
                        ></CarouselTemplate>
                    )}
                    {RoutingSchema[dashboardType].name === 'Masters' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/contractManagelight.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="e-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle="1"
                        ></CarouselTemplate>
                    )}
                    {RoutingSchema[dashboardType].name === 'Strategic and Annual Audit Planning' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/EmpLight.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="c-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle="1"
                        ></CarouselTemplate>
                    )}
                    {RoutingSchema[dashboardType].name === 'Audit Assignment Planning and Execution' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/EmpLight.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="c-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle="1"
                        ></CarouselTemplate>
                    )}
                    {RoutingSchema[dashboardType].name === 'Reports and Dashboards' && (
                        <CarouselTemplate
                            src={process.env.PUBLIC_URL + '/assets/icons/EmpLight.svg'}
                            alt={RoutingSchema[dashboardType].name}
                            className="c-logo"
                            title={RoutingSchema[dashboardType].name}
                            subDashTopCards={subDashTopCards}
                            responsiveOptions={responsiveOptions}
                            numVisible={3}
                            numScroll={3}
                            gridStyle="1"
                        ></CarouselTemplate>
                    )}
                </div>
            </div>
            <div className="cust-container">
                {RoutingSchema[dashboardType] !== '' && RoutingSchema[dashboardType]?.panels?.length > 0 && (
                    <div className="row">
                        {RoutingSchema[dashboardType]?.panels.map((item, ind) => {
                            if (setSubDashboardConfigList(item)) {
                                const configData = ConfigConstants['SUB_MODULES'][dashboardType]?.filter(
                                    (configCon) => configCon.type === item.type
                                );
                                const confData = sysAdminConfigData?.filter(
                                    (confgData) => configData && confgData.code === configData[0]?.code
                                );
                                return (
                                    <Panel
                                        key={ind}
                                        className="user-panel m-t-20 p-l-20 p-r-20 p-t-30 p-b-30 pointer"
                                        onClick={() => history.push(getRouter(item))}
                                    >
                                        <div className={`coa-img-div${item.noborder ? ' border_none' : ''}`}>
                                            <img
                                                className={`coa-img${item.noborder ? ' m-t-0 w-100' : ''}`}
                                                src={item.icon}
                                                alt={confData && confData[0]?.value1}
                                            />
                                        </div>
                                        <p className="p-t-10 m-b-5 font-16">
                                            <b>{confData && confData[0]?.value1}</b>
                                        </p>
                                        <p className="m-0 font-12">{confData && confData[0]?.value2}</p>
                                    </Panel>
                                );
                            } else {
                                return false;
                            }
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
