import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../TableSchema';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import ConfirmationModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../Validation';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { uniqueId } from '../../../../util';

const TCPC = (props) => {
    const [rows, setRows] = useState(props.formStateObj?.Transportation_Charges_of_Private_Conveyance);
    useEffect(() => {
        setRows(props.formStateObj?.Transportation_Charges_of_Private_Conveyance);
        // eslint-disable-next-line
    }, [props.formStateObj?.Transportation_Charges_of_Private_Conveyance]);
    let viewMode = TableSchema.Transportation_Charges_of_Private_Conveyance.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.mode !== 'view' ? TableSchema.Transportation_Charges_of_Private_Conveyance : viewMode;
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false,
        modes: ['Rail', 'Road', 'Airways', 'Waterways']
    });
    let emptyObj = {
        mode: '',
        station: '',
        weight: '',
        rate: '',
        distance: '',
        packing_charge: '',
        amount: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    const fields = [
        { key: 'rate', validators: 'numbersOnly', maxLength: 6 },
        { key: 'weight', validators: 'numbersOnly', maxLength: 3 },
        { key: 'distance', validators: 'numbersOnly', maxLength: 3 },
        { key: 'packing_charge', validators: 'numbersOnly', maxLength: 6 }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);

        if (
            err[key] ||
            ((key === 'rate' || key === 'weight' || key === 'distance' || key === 'packing_charge') && parseInt(ev) <= 0) ||
            (typeof ev === 'string' && ev.trim() === '')
        ) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const saveForm = () => {
        currentDetailsObj.station = currentDetailsObj.station?.trim();
        currentDetailsObj.amount =
            parseInt(currentDetailsObj.weight) * parseInt(currentDetailsObj.rate) * parseInt(currentDetailsObj.distance) +
            parseInt(currentDetailsObj.packing_charge);

        if (state.actionType === 'edit') {
            let indx = rows.findIndex((a) => a.id === editObj.id);
            rows[indx] = currentDetailsObj;
            setRows(rows);
        } else {
            currentDetailsObj.id = uniqueId(20);
            rows.push(currentDetailsObj);
            setRows(rows);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Transportation_Charges_of_Private_Conveyance', rows);
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                key !== 'amount' &&
                (currentDetailsObj[key] === undefined ||
                    currentDetailsObj[key] === '' ||
                    (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === ''))
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = rows.filter((a) => {
            return a.id !== currentDetailsObj.id;
        });
        setRows(filteredRows);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Transportation_Charges_of_Private_Conveyance', filteredRows);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    const getSum = () => {
        let total = 0;
        rows.forEach((ro) => {
            total = total + parseInt(ro.amount);
        });
        return isNaN(total) ? '' : total;
    };

    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={6} />
                <Column footer={getSum()} footerStyle={{ textAlign: 'right' }} />
                {props.mode !== 'view' && <Column />}
            </Row>
        </ColumnGroup>
    );
    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Transportation Charges of Private Conveyance are yet to be created"
                    className="secondary-table"
                    scrollable
                    mode={props?.mode}
                    footerGroup={footerGroup}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Mode{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="mode"
                                            value={currentDetailsObj.mode}
                                            onChange={(e) => handleChange(e.value, 'mode')}
                                            appendTo={document.self}
                                            options={state.modes}
                                            placeholder="Select Mode"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Station{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="station"
                                            type="text"
                                            value={currentDetailsObj.station}
                                            maxLength={25}
                                            alphanumeric='true'
                                            onChange={(e) => handleChange(e, 'station')}
                                            placeholder="Enter Station"
                                            hasError={errors && errors.station}
                                            errMsg="Please enter valid Station"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Weight{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="weight"
                                            type="text"
                                            value={currentDetailsObj.weight}
                                            maxLength={3}
                                            onChange={(e) => handleChange(e, 'weight')}
                                            placeholder="Enter Weight"
                                            hasError={errors && errors.weight}
                                            errMsg="Please enter valid Weight"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Rate{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="rate"
                                            type="text"
                                            value={currentDetailsObj.rate}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e, 'rate')}
                                            placeholder="Enter Rate"
                                            hasError={errors && errors.rate}
                                            errMsg="Please enter valid Rate"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Distance(Kms){props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="distance"
                                            type="text"
                                            value={currentDetailsObj.distance}
                                            maxLength={3}
                                            onChange={(e) => handleChange(e, 'distance')}
                                            placeholder="Enter Distance in Kms"
                                            hasError={errors && errors.distance}
                                            errMsg="Please enter valid Distance in Kms"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Packaging Charge{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="packing_charge"
                                            type="text"
                                            value={currentDetailsObj.packing_charge}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e, 'packing_charge')}
                                            placeholder="Enter Packaging Charge"
                                            hasError={errors && errors.packing_charge}
                                            errMsg="Please enter valid Packaging Charge"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.mode !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (rows?.length === 0 ? '' : 'Another ') + 'Transportation Charges of Private Conveyance'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Transportation Charges of Private Conveyance"
                    showModal={state.showDeleteModal}
                    name={
                        'Transportation Charges of Private Conveyance : Mode: ' +
                        currentDetailsObj.mode +
                        ' - Station: ' +
                        currentDetailsObj.station
                    }
                />
            )}
        </div>
    );
};

export default TCPC;
