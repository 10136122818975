import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import '../../../../styles/Dialog.scss';
import TableSchema from '../../CMTableSchema';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import FormSchema from '../formSchema';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import * as FormValidation from '../../../../Validation';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';

export function FinancialSanctionModal(props) {
    const fromDate = props.propsObj?.signing_date;
    const toDate = props.propsObj?.closing_date;
    let viewMode = TableSchema.CONTCT_FINANC_SANC.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.formType !== 'view' ? TableSchema.CONTCT_FINANC_SANC : viewMode;
    let finArr = JSON.parse(JSON.stringify(props.currentObj.finRows));
    let emptyFormObj = {
        finRows: finArr || [],
        totalAmount: props.currentObj.totalAmount || ''
    };
    const [errors, setErrors] = useState(false);
    let currency = localStorage.getItem('CURRENCY') ? localStorage.getItem('CURRENCY') : 'INR';

    let years = getFinancialYears();
    const [formState, setFormState] = useState(emptyFormObj);
    const [state, setState] = useState({
        columns: tablecolms,
        yearsList: getFilteredYears(),
        showForm: false
    });
    let emptyObj = {
        slno: '',
        year: '',
        fin_sanc_ref: '',
        fin_sanc_inr: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    function getFilteredYears() {
        return years;
    }
    function getFinancialYears() {
        const fromYear = fromDate.getFullYear();
        let from = fromYear - 1;
        let to = fromYear;
        if (fromDate.getMonth() > 2) {
            from = fromYear;
            to = fromYear + 1;
        }
        const year = [];
        year.push(from + '-' + to);
        if (toDate) {
            while (to < toDate.getFullYear()) {
                from = to;
                to = from + 1;
                year.push(from + '-' + to);
            }
            if (toDate.getMonth() > 2 && toDate.getFullYear() > fromDate.getFullYear()) {
                year.push(to + '-' + (to + 1));
            }
        }
        return year;
    }
    const handleChange = (ev, key) => {
        if (key === 'fin_sanc_inr' && (isNaN(ev) || ev.indexOf('.') >= 0 || parseInt(ev) === 0)) {
            return;
        }
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
        if (key === 'fin_sanc_inr') {
            validateFeild(ev, key);
        }
    };

    const validateFeild = (ev, key) => {
        let err = {};
        err = FormValidation.validateFileld('required', key, ev);
        let preverrors = { ...errors, ...err };
        setErrors(preverrors);
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            setState({ ...state, showForm: true, actionType: sAction, yearsList: getFilteredYears() });
            setErrors({});
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction });
        }
    };
    const disableSave = () => {
        for (let key in currentDetailsObj) {
            if (
                (key !== 'fin_sanc_ref' && (currentDetailsObj[key] === undefined || currentDetailsObj[key] === '')) ||
                (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === '')
            ) {
                return true;
            }
        }
        return false;
    };
    const checkRefs = () => {
        let isExists = false;
        if (formState.finRows?.length > 0) {
            formState.finRows.forEach((a) => {
                if (
                    (state.actionType !== 'edit' &&
                        a.fin_sanc_ref === currentDetailsObj.fin_sanc_ref &&
                        a.year === currentDetailsObj.year) ||
                    (state.actionType === 'edit' &&
                        a.slno !== currentDetailsObj.slno &&
                        a.fin_sanc_ref === currentDetailsObj.fin_sanc_ref &&
                        a.year === currentDetailsObj.year)
                ) {
                    isExists = true;
                }
                if (isExists) return;
            });
        }
        return isExists;
    };
    const saveFinSanc = () => {
        if (checkRefs()) {
            props.growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.FinancilaYear });
            return;
        }
        let amount = 0;
        formState.finRows.forEach((a) => {
            if (state.actionType !== 'edit' || (state.actionType === 'edit' && a.slno !== currentDetailsObj.slno))
                amount = amount + parseFloat(a.fin_sanc_inr);
        });
        amount = amount + parseFloat(currentDetailsObj.fin_sanc_inr);
        formState.totalAmount = amount;
        if (state.actionType === 'edit') {
            let indx = formState.finRows.findIndex((a) => a.slno === currentDetailsObj.slno);
            formState.finRows[indx] = currentDetailsObj;
            setFormState({ ...formState });
        } else {
            formState.finRows.push(currentDetailsObj);
            setFormState({ ...formState });
        }

        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
    };
    const addNewRow = () => {
        state.yearsList = getFilteredYears();
        currentDetailsObj['slno'] = formState.finRows.length + 1;
        setCurrentDetailsObj(currentDetailsObj);
        setState({ ...state, showForm: true, yearsList: state.yearsList });
    };
    const footerBodyTemplate = () => {
        return (
            <React.Fragment>
                {props.formType !== 'view' && !state.showForm && (
                    <div className="comment-header p-l-10">
                        <span
                            onClick={() => addNewRow()}
                            className={`comment-color pointer ${state.showForm || state.yearsList?.length <= 0 ? 'disabled' : ''}`}
                        >
                            + Add New
                        </span>
                    </div>
                )}
            </React.Fragment>
        );
    };

    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={2} />
                <Column footer={formState.totalAmount > 0 && formState.totalAmount + ' ' + currency} footerStyle={{ textAlign: 'right' }} />
                {props.formType !== 'view' && <Column />}
            </Row>
            <Row>
                <Column className="btn-footer" footer={footerBodyTemplate} colSpan={state.columns?.length} />
            </Row>
        </ColumnGroup>
    );

    return (
        <Modal
            visible={props.showModal}
            onCancel={props.handleCancel}
            className="common_css financial-sanction-modal add_user"
            backdropClosable={false}
        >
            <ModalHeader>Financial Sanction</ModalHeader>
            <ModalBody>
                <div>
                    <SecondaryDataTable
                        className="secondary-table sanction-modal-footer"
                        rows={formState?.finRows}
                        columns={state.columns}
                        emptyMessage="Financials Sanctions are yet to be created"
                        handleActionsInParent={handleActions}
                        noPaginator={true}
                        footerGroup={footerGroup}
                        scrollable
                    />
                </div>
                {state.showForm && (
                    <div>
                        <div className="row m-b-15 m-t-10">
                            {FormSchema.FLD_CD_FIN_SANC_YEAR.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_FIN_SANC_YEAR.name}
                                        {FormSchema.FLD_CD_FIN_SANC_YEAR.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <Dropdown
                                        name="year"
                                        value={currentDetailsObj.year}
                                        onChange={(e) => handleChange(e.value, 'year')}
                                        options={state.yearsList}
                                        placeholder="Select Year"
                                        className="w-60"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_FIN_SANC_REF.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_FIN_SANC_REF.name}
                                        {FormSchema.FLD_CD_FIN_SANC_REF.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="fin_sanc_ref"
                                        type="text"
                                        value={currentDetailsObj.fin_sanc_ref}
                                        maxLength={15}
                                        onChange={(e) => handleChange(e, 'fin_sanc_ref')}
                                        placeholder="Enter Financial Sanction Ref"
                                        alphanumeric="true"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_FIN_SANC_REF_INR.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_FIN_SANC_REF_INR.name}
                                        {FormSchema.FLD_CD_FIN_SANC_REF_INR.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="fin_sanc_inr"
                                        type="text"
                                        value={currentDetailsObj.fin_sanc_inr}
                                        maxLength={15}
                                        onChange={(e) => handleChange(e, 'fin_sanc_inr')}
                                        placeholder={'Enter Financial Sanction (' + currency + ')'}
                                        hasError={errors && errors.fin_sanc_inr}
                                        errMsg="Financial Sanction is mandatory"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="m-t-10">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveFinSanc()}>
                                {state.actionType === 'edit' ? 'Update Financial Sanction' : 'Add Financial Sanction'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                className="m-r-20"
                                onClick={() => {
                                    setCurrentDetailsObj(emptyObj);
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </ModalBody>
            <ModalFooter className={props?.loading ? 'mask p-10' : 'p-10'}>
                <Button
                    size="sm"
                    kind="primary"
                    className="m-r-10"
                    disabled={state.showForm || formState?.finRows?.length <= 0}
                    onClick={() => {
                        props.handleSave(formState);
                    }}
                >
                    OK
                </Button>
                <Button
                    size="sm"
                    kind="secondary"
                    className=""
                    onClick={() => {
                        setFormState(emptyFormObj);
                        props.handleCancel();
                    }}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default FinancialSanctionModal;
