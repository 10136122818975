import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import '../../../../styles/Dialog.scss';
import FormSchema from '../formSchema';
import TextAreaComponent from '../../../../components/InputComponent/TextAreaComponent';
import InputComponent from '../../../../components/InputComponent/InputComponent';

export function RemarksModal(props) {
    const [remarks, setRemarks] = useState(props.currentDetailsObj.remarks);
    const [amount, setAmount] = useState(props.currentDetailsObj.amount);
    let currency = localStorage.getItem('CURRENCY') ? localStorage.getItem('CURRENCY') : 'INR';

    const handleChange = (ev, key) => {
        if (key === 'amount' && isNaN(ev)) {
            return;
        }
        if (key === 'amount') {
            setAmount(ev);
            return;
        }
        setRemarks(ev);
    };

    return (
        <Modal visible={props.showModal} onCancel={props.handleCancel} className="common_css xl-modal add_user" backdropClosable={false}>
            <ModalHeader>Financial Sanction</ModalHeader>
            <ModalBody>
                <div className="m-l-20 m-r-20">
                    <div className="row m-b-15">
                        {FormSchema.FLD_CD_OTH_DED_AMOUNT.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex">
                                <b className="w-40 fs-14">
                                    {FormSchema.FLD_CD_OTH_DED_AMOUNT.name}
                                    {FormSchema.FLD_CD_OTH_DED_AMOUNT.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                </b>
                                <InputComponent
                                    className="w-60"
                                    inputBoxSize="sm"
                                    name="amount"
                                    type="text"
                                    value={amount}
                                    maxLength={15}
                                    onChange={(e) => handleChange(e, 'amount')}
                                    placeholder={'Amount (' + currency + ')'}
                                />
                            </div>
                        )}
                    </div>
                    <div className="row m-b-15">
                        {FormSchema.FLD_CD_OTH_DED_REMARKS.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex">
                                <b className="w-40 fs-14">
                                    {FormSchema.FLD_CD_OTH_DED_REMARKS.name}
                                    {FormSchema.FLD_CD_OTH_DED_REMARKS.is_mandatory !== -1 ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                                <TextAreaComponent
                                    className="w-60"
                                    textBoxSize="lg"
                                    name="remarks"
                                    type="text"
                                    value={remarks}
                                    placeholder="Remarks"
                                    maxLength={200}
                                    onChange={(e) => handleChange(e, 'remarks')}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className={props?.loading ? 'mask p-10' : 'p-10'}>
                <Button
                    size="sm"
                    kind="primary"
                    className="m-r-10"
                    onClick={() => {
                        props.handleSave(remarks, amount);
                    }}
                    disabled={!remarks || !amount}
                >
                    OK
                </Button>
                <Button size="sm" kind="secondary" className="" onClick={() => props.handleCancel()} disabled={!remarks || !amount}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default RemarksModal;
