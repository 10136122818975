export const RemittanceSchema = {
    elrm: {
        name: 'Employee-Loans Mapping',
        modalTitle: 'Employee Loan',
        fecthAllQuery: 'RemitCodeConfig',
        booleanType: 'can_use_in_emp_loan',
        updateOneQuery: 'UpdateRvnRemitCodes',
        tableProps: {
            buttonLabel: 'Add Employee-Loan Code'
        },
        noRecords: 'Employee-Loans Mappings are yet to be created',
        deleteGrowl: 'Employee-Loans Mapping deleted successfully',
        createGrowl: 'Employee-Loans Mapping created  successfully'
    },
    plrm: {
        name: 'Pension-Loans Mapping',
        modalTitle: 'Pension Loan',
        fecthAllQuery: 'RemitCodeConfig',
        booleanType: 'can_use_in_pension_loan',
        updateOneQuery: 'UpdateRvnRemitCodes',
        tableProps: {
            buttonLabel: 'Add Pension-Loan Code'
        },
        noRecords: 'Pension-Loans Mappings are yet to be created',
        deleteGrowl: 'Pension-Loans Mapping deleted successfully',
        createGrowl: 'Pension-Loans Mapping created  successfully'
    },
    adrm: {
        name: 'Contract-Advances Mapping',
        modalTitle: 'Contract Advance',
        fecthAllQuery: 'RemitCodeConfig',
        booleanType: 'can_use_in_contr_adv',
        updateOneQuery: 'UpdateRvnRemitCodes',
        tableProps: {
            buttonLabel: 'Add Contract-Advances Code'
        },
        noRecords: 'Contract-Advances Mappings are yet to be created',
        deleteGrowl: 'Contract-Advances Mapping deleted successfully',
        createGrowl: 'Contract-Advances Mapping created  successfully'
    },
    oirm: {
        name: 'Office-Imprest Mapping',
        modalTitle: 'Office-Imprest',
        fecthAllQuery: 'RemitCodeConfig',
        booleanType: 'can_use_in_ofc_imprest',
        updateOneQuery: 'UpdateRvnRemitCodes',
        tableProps: {
            buttonLabel: 'Add Office-Imprest Code'
        },
        noRecords: 'Office-Imprest Mappings are yet to be created',
        deleteGrowl: 'Office-Imprest Mapping deleted successfully',
        createGrowl: 'Office-Imprest Mapping created  successfully'
    }
};
