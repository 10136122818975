import React, { lazy, Suspense, useState } from 'react';
import SubDashboard from '../pages/subDashboard/SubDashboard';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import { Switch, Redirect, Route } from 'react-router-dom';
import Dashboard from '../pages/dashboard/Dashboard';
import Notifications from '../components/notifications/ViewAllNotifications';
import GeneralNotifications from '../components/notifications/GeneralNotifications';
import ToggleMenu from '../ToggleMenu';
import OrganizationRouting from './OrganizationRouting';
import UsersRouting from './UsersRouting';
import ExpBillsRouting from './ExpBillsRouting';
import ExpMastersRouting from './ExpMastersRouting';
import CoaRouting from './CoaRouting';
import RolesRouting from './RolesRouting';
import CommonMastersRouting from './CommonMastersRouting';
import WorkFlowRouting from './WorkFlowRouting';
import ContractMangMastersRouting from './ContractMangMastersRouting';
import SupplierMangRouting from './SupplierMangRouting';
import ContractMangRouting from './ContractMangRouting';
import GlobalConfigRouting from './GlobalConfigRouting';
import RevenueMangMastersRouting from './RevenueMangMastersRouting';
import GenerateChallanRouting from './GenerateChallanRouting';
import NonFmisRouting from './NonFmisRouting';
import DepositVoucherRouting from './DepositVoucherRouting';
import GlobalMastersRouting from './GlobalMastersRouting';
import CalendarBlockingRouting from '../routings/CalendarBlockingRouting';
import ReportsRouting from './ReportsRouting';
import PaymentManagementRouting from './PaymentManagementRouting';
import ChequeManagementRouting from './ChequeManagementRouting';
import EmployeeMangMastersRouting from './EmployeeMangMastersRouting';
import EmployeeMangementRouting from './EmployeeManagementRouting';
import CollectionCashBooksRouting from './CollectionCashBooksRouting';
import AuditUniverseManagementRouting from './AuditUniverseManagementRouting';
import ResourceManagementRouting from './ResourceManagementRouting';
import StrategicAuditPlanRouting from './StrategicAuditPlanRouting';
import AnnualAuditPlanRouting from './AnnualAuditPlanRouting';
import AapeRouting from './AapeRouting';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Auth from '../pages/auth/Auth';
import Requests from '../pages/userRegistration/URRequests/Requests';
import IsAuthorized from '../commons/IsAuthorized';
import store from '..';

const CreateOrEditJSource = lazy(() => import('../pages/generalLedger/modules/masters/journal_source/CreateOrEdit'));
const CreateOrEditGlSetup = lazy(() => import('../pages/generalLedger/modules/masters/gl_setup/CreateOrEdit'));
const JournalSourceList = lazy(() => import('../pages/generalLedger/modules/masters/journal_source/JournalSourceList'));
const GLSetupList = lazy(() => import('../pages/generalLedger/modules/masters/gl_setup/GlSetupList'));
const JournalList = lazy(() => import('../pages/generalLedger/modules/journals_and_ledgers/journals/JournalsList'));
const CreateOrEditJournal = lazy(() => import('../pages/generalLedger/modules/journals_and_ledgers/journals/CreateOrEdit'));
const SideMenu = lazy(() => import('../components/sideMenu/SideMenu'));
const FormManagement = lazy(() => import('../pages/formManagement/FormManagement'));
const journalLedgerMenuItems = MenuItemsSchema.JOURNAL_LEDGER_ITEMS;
const glMasterMenuItems = MenuItemsSchema.GL_MASTER_ITEMS;

const usermenuItems = MenuItemsSchema.USER_ITEMS;
const coamenuItems = MenuItemsSchema.COA_ITEMS;
const commonMastersItems = MenuItemsSchema.COMMON_MASTERS;
const workflowItems = MenuItemsSchema.WF_CONFIG;
const globalConfigItems = MenuItemsSchema.GLOBAL_CONFIG;
const cmMasterItems = MenuItemsSchema.CM_MASTER;
const supplierManagementItems = MenuItemsSchema.SUPL_MNGMNT;
const contarctCreationItems = MenuItemsSchema.CNTRCT_CREATION;
const revenueManagementItems = MenuItemsSchema.REVENUE_MNGMNT;
const generateChallanItems = MenuItemsSchema.GENERATE_CHALLAN;
const nonFmisNewChallanItems = MenuItemsSchema.NON_FMIS;
const expMasterItems = MenuItemsSchema.EXP_MASTER;
const billsManageItems = MenuItemsSchema.BILLS_MANAGEMENT;
const collectionCashBooksItems = MenuItemsSchema.COLLECTION_CASH_BOOK;
const depositeVoucherItems = MenuItemsSchema.DEPOSIT_VOUCHER;
const standardReportsItems = MenuItemsSchema.REPORTS;
const manageFormItems = MenuItemsSchema.MANAGE_FORMS;
const paymentManagementItems = MenuItemsSchema.PAYM_MNGMNT;
const chequeManagementItems = MenuItemsSchema.CHEQUE_MNGMNT;
const employeeMasterItems = MenuItemsSchema.EMP_MASTERS;
const employeeManagementItems = MenuItemsSchema.EMPLOYEE_MANAGEMENT;
const globalMastersItems = MenuItemsSchema.GLOBAL_MASTERS;
const auditUniverseManagementItems = MenuItemsSchema.AUDIT_UNIVERSE_MANAGEMENT;
const resourceManagementItems = MenuItemsSchema.RESOURCE_MANAGEMENT;
const calendarBlockingItems = MenuItemsSchema.CALENDAR_BLOCKING;
const strategicauditplan = MenuItemsSchema.STRATEGIC_AUDIT_PLAN;
const annualauditplan = MenuItemsSchema.ANNUAL_AUDIT_PLAN;
const aapeTypeList = MenuItemsSchema.AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION;
const aapeTypeListTaker = MenuItemsSchema.AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION_TAKER;
let aapeList = [];

const journalLedgerBreadcrumb = (props) => {
    let MainBreadCrumbValues = [{ name: 'General Ledger', redirectTo: '/generalledger' }];
    const pathArray = ['journals'];
    return buildBreadCrumb(MainBreadCrumbValues, pathArray, props);
};

const glMasterBreadcrumb = (props) => {
    let MainBreadCrumbValues = [{ name: 'General Ledger', redirectTo: '/generalledger' }];
    const pathArray = ['glSetups', 'journalSources', 'create'];
    return buildBreadCrumb(MainBreadCrumbValues, pathArray, props);
};

const buildBreadCrumb = (MainBreadCrumbValues, pathArray, props) => {
    let obj;
    const pathTotalValue = props.location.pathname;
    let subPath = pathTotalValue.split('/');
    if (pathArray.indexOf(subPath[2]) !== -1 || pathArray.indexOf(subPath[1]) !== -1) {
        obj = [
            {
                key: subPath[1],
                name: subPath[1].charAt(0).toUpperCase() + subPath[1].slice(1),
                redirectTo: ''
            }
        ];
    }
    MainBreadCrumbValues = [...MainBreadCrumbValues, ...obj];
    return MainBreadCrumbValues;
};

const commonMasterBreadCrmb = (props) => {
    let AllCoaBreadcrumbValues = [{ name: 'System Administration', redirectTo: '/systemadministration' }];
    const pathTotalValue = props.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/commonmasters/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};

const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};

const getAuthMenuItems = (items) => {
    let menuItems = [];
    let userPrivileges = store.getState()?.appReducer?.auth?.privileges;
    items.forEach((a) => {
        if (userPrivileges[a.VIEW]) {
            menuItems.push(a);
        }
        if (a.subMenu?.length > 0) {
            let obj = JSON.parse(JSON.stringify(a));
            let subItems = [];
            a.subMenu.forEach((b) => {
                if (userPrivileges[b.VIEW]) {
                    subItems.push(b);
                }
            });
            if (subItems.length > 0) {
                obj.subMenu = subItems;
                menuItems.push(obj);
            }
        }
    });
    return menuItems;
};

const AuthRoutes = (props) => {
    const routeProps = props.props;
    if (window.INTERNAL_AUDIT) {
        if (
            routeProps.location.pathname === '/aape' ||
            routeProps.location.pathname === '/aape/aapexecution' ||
            routeProps.location.pathname.includes('/aape/aapexecution/scheduleaudits') ||
            routeProps.location.pathname.includes('/aape/aapexecution/administrativeactivities') ||
            routeProps.location.pathname.includes('/aape/aapexecution/auditregister') ||
            routeProps.location.pathname.includes('/aape/aapexecution/followup')
        ) {
            localStorage.setItem('audit_assignment_type', '');
            localStorage.setItem('audit_assignment_id', '');
            localStorage.setItem('audit_assignment_name', '');
            localStorage.setItem('aap_detail_id', '');
            localStorage.setItem('aap_item_id', '');
            localStorage.setItem('AAPE_CONCLUSION_STATUS', '');
            const aapet = MenuItemsSchema.AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION_TAKER;
            const aapeb = MenuItemsSchema.AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION_TAKER_BACKUP;
            MenuItemsSchema.AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION_TAKER.splice(0, aapet.length, ...aapeb);
        }
        let type = localStorage.getItem('audit_assignment_type');
        let aapeExecutionList = aapeTypeList;
        let finalArr = [];
        aapeExecutionList.filter((elememt) => {
            if (elememt.subMenu) {
                let arr = elememt.subMenu.filter((sub) => {
                    return sub?.type?.includes(type) && sub;
                });
                elememt.subMenu = arr;
                finalArr.push(elememt);
            } else {
                if (elememt?.type?.includes(type)) finalArr.push(elememt);
            }
        });
        aapeList = finalArr;
    }

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Route
                render={(prop) => (
                    <div className="content">
                        <Header {...routeProps} />
                        <div
                            className={
                                routeProps.location.pathname === '/dashboard'
                                    ? 'inner-content-dashboard p-l-20 p-r-20'
                                    : routeProps.location.pathname === '/systemadministration' ||
                                      routeProps.location.pathname === '/contractmanagement' ||
                                      routeProps.location.pathname === '/revenuemanagement' ||
                                      routeProps.location.pathname === '/paymentmanagement'
                                    ? 'inner-content'
                                    : 'outer-scroll m-t-10'
                            }
                        >
                            <Switch>
                                <Route exact path="/dashboard" component={Dashboard}></Route>
                                <Route exact path="/generalLedger" component={SubDashboard} />
                                <Route
                                    path="/journals"
                                    render={({ match: { path } }) => JournalsAndLedgers(path, JournalList, prop, props)}
                                />
                                <Route path="/glSetups" render={({ match: { path } }) => GlMasters(path, GLSetupList, prop, props)} />
                                <Route
                                    path="/journalSources"
                                    render={({ match: { path } }) => GlMasters(path, JournalSourceList, prop, props)}
                                />
                                <Route exact path="/dashboard/notifications" component={Notifications}></Route>
                                <Route exact path="/dashboard/gennotifications" component={GeneralNotifications}></Route>
                                <Route exact path="/systemadministration" component={SubDashboard}></Route>
                                <Route exact path="/contractmanagement" component={SubDashboard}></Route>
                                <Route exact path="/paymentmanagement" component={SubDashboard}></Route>
                                <Route exact path="/revenuemanagement" component={SubDashboard}></Route>
                                <Route exact path="/expendituremanagement" component={SubDashboard}></Route>
                                <Route exact path="/employeemanagement" component={SubDashboard}></Route>
                                <Route exact path="/masters" component={SubDashboard}></Route>
                                <Route exact path="/saap" component={SubDashboard}></Route>
                                <Route exact path="/aape" component={SubDashboard}></Route>
                                <Route exact path="/reportsanddashboards" component={SubDashboard}></Route>
                                <Route
                                    path="/organizationLevel"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(usermenuItems), 'organizationLevel')
                                    }
                                />
                                <Route
                                    path="/users"
                                    render={({ match: { path } }) => matchedPath(path, prop, props, usermenuItems, 'users')}
                                />
                                <Route
                                    path="/coa"
                                    render={({ match: { path } }) => matchedPath(path, prop, props, getAuthMenuItems(coamenuItems), 'COA')}
                                />
                                <Route
                                    path="/commonmasters"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(commonMastersItems), 'commonMasters')
                                    }
                                />
                                <Route
                                    path="/workflow"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(workflowItems), 'workFlow')
                                    }
                                />
                                <Route
                                    path="/cmm"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(cmMasterItems), 'contractMangMaster')
                                    }
                                />
                                <Route
                                    path="/cm"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(supplierManagementItems), 'supplierMang')
                                    }
                                />
                                <Route
                                    path="/cc"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(contarctCreationItems), 'contractMang')
                                    }
                                />
                                <Route
                                    path="/rm"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(revenueManagementItems), 'revenueMasters')
                                    }
                                />
                                <Route
                                    path="/rmm"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(generateChallanItems), 'generateChallan')
                                    }
                                />
                                <Route
                                    path="/rnf"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(nonFmisNewChallanItems), 'newChallan')
                                    }
                                />
                                <Route
                                    path="/cb"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(collectionCashBooksItems), 'collectionCashBook')
                                    }
                                />
                                <Route
                                    path="/expmaster"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(expMasterItems), 'expMasters')
                                    }
                                />
                                <Route
                                    path="/expbills"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(billsManageItems), 'expBills')
                                    }
                                />
                                <Route
                                    path="/dv"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(depositeVoucherItems), 'depositVoucher')
                                    }
                                />
                                <Route
                                    path="/pm"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, paymentManagementItems, 'paymentMangement')
                                    }
                                />
                                <Route
                                    path="/pmm"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, chequeManagementItems, 'chequeManagement')
                                    }
                                />
                                <Route
                                    path="/rmp"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(standardReportsItems), 'standardReports')
                                    }
                                />
                                <Route
                                    path="/usermasters"
                                    render={({ match: { path } }) => matchedPath(path, prop, props, usermenuItems, 'roles')}
                                />
                                <Route
                                    path="/global"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(globalConfigItems), 'globalConfig')
                                    }
                                />
                                <Route
                                    path="/empmaster"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(employeeMasterItems), 'employeeMaster')
                                    }
                                />
                                <Route
                                    path="/empfunction"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(employeeManagementItems), 'employeeMang')
                                    }
                                />
                                <Route
                                    path="/globalmaster"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(globalMastersItems), 'globalmaster')
                                    }
                                />
                                <Route
                                    path="/audituniversemang"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(auditUniverseManagementItems), 'audituniversemang')
                                    }
                                />
                                <Route
                                    path="/resourcemang"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(resourceManagementItems), 'resourcemang')
                                    }
                                />
                                <Route
                                    path="/calendarblock"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(calendarBlockingItems), 'calendarblocking')
                                    }
                                />
                                <Route
                                    path="/strategicauditplan"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(strategicauditplan), 'strategicauditplan')
                                    }
                                />
                                <Route
                                    path="/annualauditplan"
                                    render={({ match: { path } }) =>
                                        matchedPath(path, prop, props, getAuthMenuItems(annualauditplan), 'annualauditplan')
                                    }
                                />
                                <Route
                                    path="/aape"
                                    render={({ match: { path } }) =>
                                        matchedPath(
                                            path,
                                            prop,
                                            props,
                                            getAuthMenuItems(
                                                routeProps.location.pathname === '/aape' ||
                                                    routeProps.location.pathname === '/aape/aapexecution' ||
                                                    routeProps.location.pathname.includes('/aape/aapexecution/scheduleaudits') ||
                                                    routeProps.location.pathname.includes('/aape/aapexecution/administrativeactivities') ||
                                                    routeProps.location.pathname.includes('/aape/aapexecution/auditregister') ||
                                                    routeProps.location.pathname.includes('/aape/aapexecution/followup')
                                                    ? aapeTypeListTaker
                                                    : aapeList
                                            ),
                                            'aape'
                                        )
                                    }
                                />
                                <Route path="/URRequests" render={({ match: { path } }) => matchedPath(path, Requests, prop, props)} />
                                <Route path="/formmanagement" render={({ match: { path } }) => manageForms(path, prop, props)} />
                                <Redirect to="/dashboard" />
                            </Switch>
                        </div>
                        <Footer />
                    </div>
                )}
            />
        </Suspense>
    );
};

const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};

const JournalsAndLedgers = (path, component, props, routeProps) => {
    return (
        <div className="left-menu-grid-hight">
            <div className={routeProps.collapse === true ? 'collapsed-menu-grid' : 'menu-grid'}>
                <div className="menu-icon">
                    <ToggleMenu toggleMenu={routeProps.toggleMenu} />
                </div>
                <SideMenu menuItems={journalLedgerMenuItems} {...props} />
            </div>
            <div className={routeProps.collapse === true ? 'collapsed-content-grid' : 'content-grid'}>
                <div className="coa-card p-10">
                    <BreadCRumb items={journalLedgerBreadcrumb(props)} homeRoute="/dashboard" />
                    <Switch>
                        <Route exact path={path} component={component}></Route>
                        <Route exact path={`${path}/create`} component={CreateOrEditJournal} name={'Create Manual Journal'} />
                        <Route exact path={`${path}/edit/:id`} component={CreateOrEditJournal} name={'Edit Manual Journal'} />
                        <Route
                            exact
                            path={`${path}/view/:id`}
                            name={'Manual Journal'}
                            render={(ownProps) => <CreateOrEditJournal {...ownProps} editMode={false} />}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

const GlMasters = (path, component, props, routeProps) => {
    let CreateOrEditComponent;
    let name;
    if (path === '/glSetups') {
        CreateOrEditComponent = CreateOrEditGlSetup;
        name = 'GL Setup';
    }
    if (path === '/journalSources') {
        CreateOrEditComponent = CreateOrEditJSource;
        name = 'Journal Source';
    }

    return (
        <div className="left-menu-grid-hight">
            <div className={routeProps?.collapse === true ? 'collapsed-menu-grid' : 'menu-grid'}>
                <div className="menu-icon">
                    <ToggleMenu toggleMenu={routeProps?.toggleMenu} />
                </div>
                <SideMenu menuItems={glMasterMenuItems} {...props} isCollapsed={routeProps?.collapse} />
            </div>
            <div className={routeProps?.collapse === true ? 'collapsed-content-grid' : 'content-grid'}>
                <div className="coa-card p-10">
                    <BreadCRumb items={glMasterBreadcrumb(props)} homeRoute="/dashboard" />
                    <Switch>
                        <Route exact path={path} component={component}></Route>
                        <Route exact path={`${path}/create`} component={CreateOrEditComponent} name={name} />
                        <Route exact path={`${path}/edit/:id`} component={CreateOrEditComponent} name={name} />
                        <Route
                            exact
                            path={`${path}/view/:id`}
                            name={name}
                            render={(ownProps) => <CreateOrEditComponent {...ownProps} editMode={false} />}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
};
const matchedPath = (path, props, routeProps, sidemenus, page) => {
    return (
        <div className="left-menu-grid-hight">
            <div className={routeProps?.collapse === true ? 'collapsed-menu-grid' : 'menu-grid'}>
                <div className="menu-icon">
                    <ToggleMenu toggleMenu={routeProps?.toggleMenu} />
                </div>
                <SideMenu menuItems={sidemenus} {...props} isCollapsed={routeProps?.collapse} />
            </div>
            <div className={routeProps?.collapse === true ? 'collapsed-content-grid' : 'content-grid'}>
                {(() => {
                    switch (page) {
                        case 'organizationLevel':
                            return <OrganizationRouting path={path} parentProps={props}></OrganizationRouting>;
                        case 'users':
                            return <UsersRouting path={path} parentProps={props}></UsersRouting>;
                        case 'roles':
                            return <RolesRouting path={path} parentProps={props}></RolesRouting>;
                        case 'expBills':
                            return <ExpBillsRouting path={path} parentProps={props}></ExpBillsRouting>;
                        case 'expMasters':
                            return <ExpMastersRouting path={path} parentProps={props}></ExpMastersRouting>;
                        case 'COA':
                            return <CoaRouting path={path} parentProps={props}></CoaRouting>;
                        case 'commonMasters':
                            return <CommonMastersRouting path={path} parentProps={props}></CommonMastersRouting>;
                        case 'workFlow':
                            return <WorkFlowRouting path={path} parentProps={props}></WorkFlowRouting>;
                        case 'contractMangMaster':
                            return <ContractMangMastersRouting path={path} parentProps={props}></ContractMangMastersRouting>;
                        case 'supplierMang':
                            return <SupplierMangRouting path={path} parentProps={props}></SupplierMangRouting>;
                        case 'contractMang':
                            return <ContractMangRouting path={path} parentProps={props}></ContractMangRouting>;
                        case 'globalConfig':
                            return <GlobalConfigRouting path={path} parentProps={props}></GlobalConfigRouting>;
                        case 'revenueMasters':
                            return <RevenueMangMastersRouting path={path} parentProps={props}></RevenueMangMastersRouting>;
                        case 'generateChallan':
                            return <GenerateChallanRouting path={path} parentProps={props}></GenerateChallanRouting>;
                        case 'newChallan':
                            return <NonFmisRouting path={path} parentProps={props}></NonFmisRouting>;
                        case 'collectionCashBook':
                            return <CollectionCashBooksRouting path={path} parentProps={props}></CollectionCashBooksRouting>;
                        case 'depositVoucher':
                            return <DepositVoucherRouting path={path} parentProps={props}></DepositVoucherRouting>;
                        case 'standardReports':
                            return <ReportsRouting path={path} parentProps={props}></ReportsRouting>;
                        case 'paymentMangement':
                            return <PaymentManagementRouting path={path} parentProps={props}></PaymentManagementRouting>;
                        case 'chequeManagement':
                            return <ChequeManagementRouting path={path} parentProps={props}></ChequeManagementRouting>;
                        case 'employeeMaster':
                            return <EmployeeMangMastersRouting path={path} parentProps={props}></EmployeeMangMastersRouting>;
                        case 'employeeMang':
                            return <EmployeeMangementRouting path={path} parentProps={props}></EmployeeMangementRouting>;
                        case 'globalmaster':
                            return <GlobalMastersRouting path={path} parentProps={props}></GlobalMastersRouting>;
                        case 'audituniversemang':
                            return <AuditUniverseManagementRouting path={path} parentProps={props}></AuditUniverseManagementRouting>;
                        case 'resourcemang':
                            return <ResourceManagementRouting path={path} parentProps={props}></ResourceManagementRouting>;
                        case 'calendarblocking':
                            return <CalendarBlockingRouting path={path} parentProps={props}></CalendarBlockingRouting>;
                        case 'strategicauditplan':
                            return <StrategicAuditPlanRouting path={path} parentProps={props}></StrategicAuditPlanRouting>;
                        case 'annualauditplan':
                            return <AnnualAuditPlanRouting path={path} parentProps={props}></AnnualAuditPlanRouting>;
                        case 'aape':
                            return <AapeRouting path={path} parentProps={props}></AapeRouting>;
                        default:
                    }
                })()}
            </div>
        </div>
    );
};

const manageForms = (path, props, routeProps) => {
    return (
        <div className="left-menu-grid-hight">
            <div className={routeProps?.collapse === true ? 'collapsed-menu-grid' : 'menu-grid'}>
                <div className="menu-icon">
                    <ToggleMenu toggleMenu={routeProps?.toggleMenu} />
                </div>
                <SideMenu menuItems={getAuthMenuItems(manageFormItems)} isCollapsed={routeProps?.collapse} />
            </div>
            <div className={routeProps?.collapse === true ? 'collapsed-content-grid' : 'content-grid'}>
                <div className="coa-card p-10">
                    <BreadCRumb items={commonMasterBreadCrmb(props)} homeRoute="/dashboard" />
                    <Switch>
                        <AuthGaurd
                            {...props}
                            component={FormManagement}
                            exact
                            path={`${path}/manageforms`}
                            name="Manage Form"
                            menuItems={manageFormItems}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

const Routing = (props) => {
    const [collapse, setCollapse] = useState(true);
    const toggleMenu = () => {
        if (collapse) {
            setCollapse(false);
        } else {
            setCollapse(true);
        }
    };
    if (props.location?.search?.split('=')[0].replace('?', '') === 'token') {
        localStorage.clear();
    }
    const token = localStorage.getItem('AUTH_TOKEN');

    if (!token || (token && token.length <= 0)) return UnAuthRoutes();
    return <AuthRoutes props={props} collapse={collapse} toggleMenu={toggleMenu}></AuthRoutes>;
};
export default Routing;
