import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/IsAuthorized';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
const SupplyCategoryMaster = lazy(() => import('../pages/contractManagementMasters/SupplierManagementMaster/SupplyCategoryMaster'));
const AddOrEditSupplyCategory = lazy(() => import('../pages/contractManagementMasters/SupplierManagementMaster/AddSuplyCategoryMaster'));
const DepositDeduction = lazy(() => import('../pages/contractManagementMasters/securityDepositDeduction/depositAndDeductionHOC'));
const AdvanceMaster = lazy(() => import('../pages/contractManagementMasters/AdvanceMaster/AdvanceMaster'));
const AddAdvanceMaster = lazy(() => import('../pages/contractManagementMasters/AdvanceMaster/AddAdvanceMaster'));
const ProjectMaster = lazy(() => import('../pages/contractManagementMasters/ProjectMaster/ProjectMaster'));
const AddProjectMaster = lazy(() => import('../pages/contractManagementMasters/ProjectMaster/AddProjectMaster'));
const SanctioningAgencyMaster = lazy(() => import('../components/agencymaster/AgencyMaster'));
const ImplementingAgencyMaster = lazy(() => import('../components/agencymaster/AgencyMaster'));
const AdmissibleVariance = lazy(() => import('../pages/contractManagementMasters/admissibleVariance/AdmissibleVariance'));
const cmMasterItems = MenuItemsSchema.CM_MASTER;
const setBreadCrumbItems = (props, path) => {
    let AllCoaBreadcrumbValues = [{ name: 'Contract Management', redirectTo: '/contractmanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: path + '/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '', key: subPath[2] }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const ContractMangMastersRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={setBreadCrumbItems(props, props?.path)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={SupplyCategoryMaster}
                    exact
                    path={`${props?.path}/supplycategorymaster`}
                    name="Supply Category Master"
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddOrEditSupplyCategory}
                    exact
                    path={`${props?.path}/supplycategorymaster/create`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddOrEditSupplyCategory}
                    exact
                    path={`${props?.path}/supplycategorymaster/view/:id`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddOrEditSupplyCategory}
                    exact
                    path={`${props?.path}/supplycategorymaster/edit/:id`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddOrEditSupplyCategory}
                    exact
                    path={`${props?.path}/supplycategorymaster/edit/:id/draft`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddOrEditSupplyCategory}
                    exact
                    path={`${props?.path}/supplycategorymaster/view/:id/draft`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={DepositDeduction}
                    exact
                    path={`${props?.path}/securitydepositdeduction`}
                    name="Supply Category Master"
                    menuItems={cmMasterItems}
                />
                <AuthGaurd {...props} component={ProjectMaster} exact path={`${props?.path}/projectMaster`} menuItems={cmMasterItems} />
                <AuthGaurd
                    {...props}
                    component={AddProjectMaster}
                    exact
                    path={`${props?.path}/projectMaster/create`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddProjectMaster}
                    exact
                    path={`${props?.path}/projectMaster/edit/:id`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AdvanceMaster}
                    exact
                    path={`${props?.path}/advancemaster`}
                    name="Advance Master"
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAdvanceMaster}
                    exact
                    path={`${props?.path}/advancemaster/create`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAdvanceMaster}
                    exact
                    path={`${props?.path}/advancemaster/edit/:id`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAdvanceMaster}
                    exact
                    path={`${props?.path}/advancemaster/view/:id`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAdvanceMaster}
                    exact
                    path={`${props?.path}/advancemaster/edit/:id/draft`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAdvanceMaster}
                    exact
                    path={`${props?.path}/advancemaster/view/:id/draft`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={SanctioningAgencyMaster}
                    exact
                    path={`${props?.path}/sanctioningMaster`}
                    key={`${props?.path}/sanctioningMaster`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImplementingAgencyMaster}
                    exact
                    path={`${props?.path}/implementingMaster`}
                    key={`${props?.path}/implementingMaster`}
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AdmissibleVariance}
                    exact
                    path={`${props?.path}/admissibleVariance`}
                    name="Admissible Varaince"
                    menuItems={cmMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAdvanceMaster}
                    exact
                    path={`${props?.path}/advancemaster/edit/:id`}
                    menuItems={cmMasterItems}
                />
            </Switch>
        </div>
    );
};
export default ContractMangMastersRouting;
