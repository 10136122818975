/* eslint-disable max-lines */
let currency = localStorage.getItem('CURRENCY') ? localStorage.getItem('CURRENCY') : 'INR';
export const TableSchema = {
    IMPREST_ADJUST_ACC_BILL_DETAILS: [
        {
            header: 'Invoice Number',
            field: 'invoice_num',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Invoice Date',
            field: 'dateString',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'HOA',
            field: 'hoa',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Amount',
            field: 'amount',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Description',
            field: 'description',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Attachment',
            field: 'fileName',
            width: '100px',
            fieldType: 'view_clickable'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false
        }
    ],
    IMPREST_ADJUST_ACC_BILL_DETAILS_PRINT: [
        {
            header: 'Sl. No.',
            field: 'slNum',
            width: '10%'
        },
        {
            header: 'Invoice Number',
            field: 'invoice_num',
            width: '10%'
        },
        {
            header: 'Invoice Date',
            field: 'dateString',
            width: '10%'
        },
        {
            header: 'HOA',
            field: 'hoa',
            width: '10%'
        },
        {
            header: 'Gross Bill Amount',
            field: 'amount',
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '10%'
        },
        {
            header: 'Description',
            field: 'description',
            width: '15%'
        }
    ],
    BILL_DETAILS: [
        {
            header: 'Sl. No',
            field: 'slNo',
            filter: false,
            width: '100px'
        },
        {
            header: 'HOA',
            field: 'hoa',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Amount',
            field: 'grossBill',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Deductions',
            field: 'deduction',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Net Payment',
            field: 'netPayment',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            body: '',
            width: '100px'
        }
    ],
    BILL_DETAILS_PRINT: [
        {
            header: 'Sl. No',
            field: 'slNo'
        },
        {
            header: 'HOA',
            field: 'hoa',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Amount',
            field: 'grossBill'
        },
        {
            header: 'Deductions',
            field: 'deduction'
        },
        {
            header: 'Net Payment',
            field: 'netPayment'
        }
    ],
    EXP_OTHER_ACC_BILL_DETAILS: [
        {
            header: 'Invoice Number',
            field: 'invoice_num',
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Invoice Date',
            field: 'dateString',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Amount',
            field: 'amount',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Description',
            field: 'description',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Attachment',
            field: 'fileName',
            width: '150px',
            fieldType: 'view_clickable'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            view: true,
            width: '120px'
        }
    ],
    EXP_OTHER_ACC_BILL_DETAILS_PRINT: [
        {
            header: 'Sl. No.',
            field: 'slNum',
            width: '10%'
        },
        {
            header: 'Invoice Number',
            field: 'invoice_num',
            width: '20%'
        },
        {
            header: 'Invoice Date',
            field: 'dateString',
            width: '20%'
        },
        {
            header: 'Gross Bill Amount',
            field: 'amount',
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '20%'
        },
        {
            header: 'Description',
            field: 'description',
            width: '30%'
        }
    ],
    EXP_OTHER_ACC_DEDUCTIONS: [
        {
            header: 'Deduction',
            field: 'deduction.name',
            fieldType: 'islatest',
            width: '150px'
        },
        {
            header: 'Value',
            field: 'deduction.value',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Type',
            field: 'deduction.type',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount',
            field: 'amount',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Remarks',
            field: 'remarks',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            width: '100px'
        }
    ],
    EXP_OTHER_ACC_DEDUCTIONS_PRINT: [
        {
            header: 'Sl. No.',
            field: 'slNum',
            width: '10%'
        },
        {
            header: 'Deduction',
            field: 'deduction.name',
            fieldType: 'islatest',
            width: '20%'
        },
        {
            header: 'Value',
            field: 'deduction.value',
            width: '20%'
        },
        {
            header: 'Amount',
            field: 'amount',
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '20%'
        },
        {
            header: 'Remarks',
            field: 'remarks',
            width: '30%'
        }
    ],
    PAYMENT_MILESTONE: [
        {
            header: '',
            fieldType: 'radio',
            field: 'checked',
            width: '5%'
        },
        {
            header: 'Sl. No',
            field: 'slNo',
            width: '5%'
        },
        {
            header: 'Payment Milestone',
            field: 'payment',
            width: '20%',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount Inclusive of Taxes',
            field: 'amountInclTax',
            width: '15%',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount Exclusive of Taxes',
            field: 'amountExclTax',
            width: '15%',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Outstanding Amount Inclusive of Taxes',
            field: 'outStandingInclAmount',
            width: '20%',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Outstanding Amount Exclusive of Taxes',
            field: 'outStandingExclAmount',
            width: '20%',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        }
    ],
    PAYMENT_MILESTONE_VIEW: [
        {
            header: 'Payment Milestone',
            field: 'payment',
            width: '20%',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount Inclusive of Taxes (' + currency + ')',
            field: 'amountInclTax',
            width: '18%',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount Exclusive of Taxes (' + currency + ')',
            field: 'amountExclTax',
            width: '20%',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        }
    ],
    ADVANCE_RECOVERY: [
        {
            header: 'Sl. No',
            field: 'slNo',
            width: '7%'
        },
        {
            header: 'Bill Type',
            field: 'billType',
            width: '13%',
            fieldType: 'tooltip'
        },
        {
            header: 'Bill Number',
            field: 'billNum',
            width: '20%',
            fieldType: 'tooltip'
        },
        {
            header: 'Bill Approval Date',
            field: 'billDate',
            width: '20%',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount Given',
            field: 'amountGiven',
            width: '20%',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount Recovered',
            field: 'amountRecoverd',
            width: '20%',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        }
    ],
    SECURITY_DEPOSIT_RECOVERY: [
        {
            header: 'Sl. No',
            field: 'slNo',
            width: '10%'
        },
        {
            header: 'Bill Type',
            field: 'billType',
            width: '20%',
            fieldType: 'tooltip'
        },
        {
            header: 'Bill Number',
            field: 'billNum',
            width: '20%',
            fieldType: 'tooltip'
        },
        {
            header: 'Bill Approval Date',
            field: 'billDate',
            width: '20%',
            fieldType: 'tooltip'
        },
        {
            header: 'Security Deposit Refund',
            field: 'deposit',
            width: '20%',
            fieldType: 'tooltip'
        },
        {
            header: 'Refunded Amount',
            field: 'refunded',
            width: '20%',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        }
    ],
    SUP_BANK_ACCOUNT: [
        {
            header: '',
            fieldType: 'radio',
            field: 'checked',
            width: '100px'
        },
        {
            header: 'Account Category',
            field: 'bank_account.category',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Type',
            field: 'bank_account.type',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Number',
            field: 'bank_account.number',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'bank_account.bank.ifsc',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'bank_account.bank.name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Branch Name',
            field: 'bank_account.bank.branch_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Holder Name',
            field: 'bank_account.holder_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'bank_account.is_active',
            width: '100px',
            fieldType: 'tooltip'
        }
    ],
    PAYMENT_STATUS: [
        {
            header: 'Sl. No',
            field: 'slNo',
            width: '100px'
        },
        {
            header: 'Payee',
            field: 'payee.payeeName',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Account No.',
            field: 'payee.accNumber',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'payee.ifsc',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount',
            field: 'payee.amount',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip',
            className: 'amountColAlign'
        },
        {
            header: 'Payment Release Date',
            field: 'release_date',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Payment Status',
            field: 'status',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Date & Time',
            field: 'version_date',
            width: '100px',
            fieldType: 'tooltip'
        }
    ],
    BEN_BANK_ACCOUNT: [
        {
            header: '',
            fieldType: 'radio',
            field: 'checked',
            width: '100px'
        },
        {
            header: 'Account Category',
            field: 'account.category',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Type',
            field: 'account.type',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Number',
            field: 'account.number',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'account.bank.ifsc',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'account.bank.name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Branch Name',
            field: 'account.bank.branch_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Holder Name',
            field: 'account.holder_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'account.is_active',
            width: '100px',
            fieldType: 'tooltip'
        }
    ],
    BEN_BANK_ACCOUNT_Table: [
        {
            header: 'Account Category',
            field: 'account.category',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Type',
            field: 'account.type',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Number',
            field: 'account.number',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'account.bank.ifsc',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'account.bank.name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Branch Name',
            field: 'account.bank.branch_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Holder Name',
            field: 'account.holder_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'account.is_active',
            width: '100px',
            fieldType: 'tooltip'
        }
    ],
    DOCUMENTS: [
        {
            header: 'Sl. No',
            field: 'slNo',
            width: '10%'
        },
        {
            header: 'Document Category',
            field: 'advanceID',
            width: '20%'
        },
        {
            header: 'Document Description',
            field: 'category',
            width: '20%'
        },
        {
            header: 'Document',
            field: 'advanceType',
            width: '20%'
        },
        {
            header: '',
            field: 'InlineActions',
            fieldType: 'InlineActions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '10%'
        }
    ],
    CONTRACT_COLUMN: [
        {
            header: '',
            fieldType: 'radio',
            field: 'checked',
            filter: false,
            width: '40px'
        },
        {
            header: 'Contract Name',
            field: 'name',
            filterPlaceholder: 'Search by Contract Name',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: false,
            fieldType: 'tooltip'
        },
        {
            header: 'Contract Number',
            field: 'ref',
            filterPlaceholder: 'Search by Contract Number',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: false,
            fieldType: 'tooltip'
        }
    ],
    SUPPLIER_COLUMN: [
        {
            header: '',
            fieldType: 'radio',
            field: 'checked',
            filter: false,
            width: '40px'
        },
        {
            header: 'Supplier Name',
            field: 'name',
            filterPlaceholder: 'Search by Supplier Name',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: false,
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier Number',
            field: 'ref',
            filterPlaceholder: 'Search by Supplier Number',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: false,
            fieldType: 'tooltip'
        }
    ],
    EXP_BILLS: [
        {
            header: 'Bill Number',
            field: 'billNum',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Bill Number',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Bill Type',
            field: 'billType',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterPlaceholder: 'Search by Bill Type',
            filterElement: 'dropdown',
            filterOptions: 'BILL_TYPES',
            filterMatchMode: 'contains',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Financial Year',
            field: 'year_id',
            sortable: true,
            filterMatchMode: 'contains',
            filterElement: 'search',
            filterPlaceholder: 'Search by Financial Year',
            filter: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Financial Period',
            field: 'period',
            sortable: true,
            filterMatchMode: 'contains',
            filterElement: 'search',
            filterPlaceholder: 'Search by Financial Period',
            filter: true,
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sanctioning Agency',
            field: 'sanct_agency',
            filter: true,
            sortable: true,
            sortableDisabled: true,
            filterPlaceholder: 'Search by Sanction ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Contract Name & Number',
            field: 'contractinfo',
            filter: true,
            sortable: true,
            sortableDisabled: true,
            filterPlaceholder: 'Search by Contract Name & Number',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '280px',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier Name & Number',
            field: 'supplier',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterPlaceholder: 'Search by Supplier Name & Number',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Amount',
            field: 'grossBill',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterPlaceholder: 'Search by Gross Bill Amount',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '180px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Net Payment',
            field: 'netPayment',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Net Payment',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '215px',
            fieldType: 'date'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            body: '',
            width: '80px'
        }
    ],
    EXP_OTHER_BILLS: [
        {
            header: 'Bill Number',
            field: 'ref',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Bill Number',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Bill Type',
            field: 'type_code',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterPlaceholder: 'Search by Bill Type',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Financial Year',
            field: 'year_id',
            sortable: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Financial Year',
            filter: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Financial Period',
            field: 'period',
            sortable: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Financial Period',
            filter: true,
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Office',
            field: 'bedgetValue',
            filterElement: 'search',
            sortable: true,
            sortableDisabled: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Office Id',
            filter: true,
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier/Beneficiary Name & ID',
            field: 'supbenefyValue',
            filter: true,
            sortable: true,
            sortableDisabled: true,
            filterPlaceholder: 'Search by Supplier/Beneficiary Name & ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Amount',
            field: 'gross_bill_amount',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Gross Bill Amount',
            width: '180px',
            headerAlign: 'right',
            bodyStyle: 'right',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Net Payment',
            field: 'net_payment_amount',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Net Payment',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '200px',
            fieldType: 'date'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            body: '',
            width: '80px'
        }
    ],
    EXP_OTHER_BILLS_CONFIG: [
        {
            header: 'Other Bill Type Code',
            field: 'code',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Other Bill Type Code',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '150px'
        },
        {
            header: 'Other Bill Type Description',
            field: 'description',
            sortable: true,
            filter: true,
            filterElement: 'search',
            filterPlaceholder: 'Search by Bill Type',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Revenue Refund Related?',
            field: 'is_refund_related',
            filter: false,
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Include Sanction Details?',
            field: 'incl_santion_detail',
            filter: false,
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Include Deductions?',
            field: 'incl_deductions',
            filter: false,
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            width: '150px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterElement: 'date',
            filterPlaceholder: 'Last Update Date & Time',
            width: '170px',
            filterMatchMode: 'contains',
            fieldType: 'date'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            edit: true,
            delete: false,
            width: '80px'
        }
    ],
    EXP_BILL_IMPREST_RELEASE: [
        {
            header: 'Imprest ID',
            field: 'ref',
            filter: true,
            filterPlaceholder: 'Search by Imprest ID',
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Budget Office',
            field: 'budgetValue',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Budget Office Id',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Beneficiary Name & ID',
            field: 'benefyValue',
            filterElement: 'search',
            sortable: true,
            sortableDisabled: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Beneficiary Name & ID',
            filter: false,
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Imprest Release Amount',
            field: 'amount',
            sortable: true,
            filterPlaceholder: 'Search by Imprest Release Amount',
            filter: false,
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Imprest Unconsumed',
            field: 'out_standing_amount',
            sortable: true,
            sortableDisabled: true,
            filterPlaceholder: 'Search by Imprest Unconsumed',
            filter: false,
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '210px',
            fieldType: 'date'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    EXP_BILL_IMPREST_ADJUST: [
        {
            header: 'Imprest ID',
            field: 'ref',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Imprest ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Budget Office',
            field: 'budgetValue',
            sortable: true,
            sortableDisabled: true,
            filter: false,
            filterPlaceholder: 'Search by Budget Office',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Imprest Release ID',
            field: 'imprest.ref',
            filter: false,
            sortable: true,
            sortableDisabled: true,
            filterPlaceholder: 'Search by Imprest Release ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Beneficiary Name & ID',
            field: 'benefyValue',
            filterElement: 'search',
            sortable: true,
            sortableDisabled: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Beneficiary Name & ID',
            filter: false,
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Imprest Release Amount',
            field: 'imprest.amount',
            sortable: true,
            sortableDisabled: true,
            filterPlaceholder: 'Search by Imprest Release Amount',
            filter: false,
            width: '130px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill',
            field: 'gross_bill',
            sortable: true,
            filterPlaceholder: 'Search by Gross Bill',
            filter: false,
            width: '120px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Imprest Balance',
            field: 'imprest.out_standing_amount',
            filterPlaceholder: 'Search by Imprest Balance',
            filter: false,
            sortable: true,
            sortableDisabled: true,
            width: '110px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '210px',
            fieldType: 'date'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            edit: true,
            delete: true,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '80px'
        }
    ],
    EXP_MASTER_IMPREST_LIMIT: [
        {
            header: 'Budget Office',
            field: 'bedgetValue',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Budget Office ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Monthly Imprest Limit Amount',
            field: 'amount',
            sortable: true,
            filterPlaceholder: 'Search by Monthly Imprest Limit Amount',
            filter: false,
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Adjustment Time Peroid',
            field: 'adjustment_time',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Adjustment Time Peroid',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '170px',
            fieldType: 'date'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '40px',
            edit: true,
            delete: true
        }
    ],
    DEDUCTION_CONFIGURATION: [
        {
            header: 'Sl. No.',
            field: 'slNo',
            width: '5%'
        },
        {
            header: 'Deduction',
            field: 'name',
            width: '15%',
            fieldType: 'islatest'
        },
        {
            header: 'Deduction Type',
            field: 'type',
            width: '15%'
        },
        {
            header: 'Deduction Value',
            field: 'value',
            width: '15%'
        },
        {
            header: 'Is Mandatory',
            field: 'is_mandatory',
            width: '10%'
        },
        {
            header: 'Supply Category',
            field: 'supply',
            width: '20%',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            body: '',
            width: '10%'
        }
    ],
    EXP_MASTER_BILLS: [
        {
            header: 'Contract Bill Type Description',
            field: 'bill_type',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Bill Type',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '150px'
        },
        {
            header: 'Contract Bill Type Code',
            field: 'bill_code',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Bill Code',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '140px',
            fieldType: 'tooltip'
        },
        {
            header: 'Showcase Contract performance',
            field: 'perf_showcase',
            sortable: true,
            width: '130px',
            fieldType: 'tooltip'
        },
        {
            header: 'Include Sanction Details?',
            field: 'incl_sanctions',
            sortable: true,
            width: '130px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active?',
            field: 'is_active',
            sortable: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            fieldType: 'date',
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '180px'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    DEDUCTION_DETAILS: [
        {
            header: '',
            expander: true,
            field: 'expander',
            fieldType: 'expander',
            width: '40px'
        },
        {
            header: 'Deduction',
            field: 'name',
            fieldType: 'dropdown',
            inputPlaceholder: 'Enter Deduction Name',
            filter: false,
            width: '100px'
        },
        {
            header: 'Deduction Value',
            field: 'value',
            fieldType: 'input',
            inputType: 'Number',
            inputPlaceholder: 'Enter Deduction Value',
            filter: false,
            width: '100px'
        },
        {
            header: 'Amount',
            field: 'amount',
            fieldType: 'input',
            inputType: 'Number',
            inputPlaceholder: 'Amount',
            headerAlign: 'right',
            bodyStyle: 'right',
            filter: false,
            width: '100px'
        },
        {
            header: 'Remarks',
            field: 'remarks',
            fieldType: 'input',
            inputType: 'string',
            inputPlaceholder: 'Enter Remarks',
            filter: false,
            width: '100px'
        },
        {
            header: '',
            field: 'EditDeleteActions',
            fieldType: 'EditDeleteActions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '50px'
        }
    ],
    DEDUCTION_SCHEMA: [
        {
            header: 'S.No',
            field: 'sNo',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction',
            field: 'name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction Type',
            field: 'type',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction Value',
            field: 'value',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Mandatory',
            field: 'is_mandatory',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            width: '100px'
        }
    ],
    SUPPLIER_LIST: [
        {
            header: '',
            fieldType: 'radio',
            field: 'checked',
            filter: false,
            width: '40px'
        },
        {
            header: 'Supplier Name',
            field: 'name',
            width: '200px',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Supplier Name',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier ID',
            field: 'ref',
            width: '200px',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Supplier ID',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier Category',
            field: 'category.name',
            width: '200px',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Supplier Category',
            fieldType: 'tooltip'
        }
    ],
    BENIFY_LIST: [
        {
            header: '',
            fieldType: 'radio',
            field: 'checked',
            filter: false,
            width: '40px'
        },
        {
            header: 'Beneficiary Name',
            field: 'name',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Beneficiary Name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Beneficiary ID',
            field: 'ref',
            width: '200px',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Beneficiary ID',
            fieldType: 'tooltip'
        },
        {
            header: 'Beneficiary Category',
            field: 'type.name',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Beneficiary Category',
            width: '200px',
            fieldType: 'tooltip'
        }
    ],
    BUDGET_LIST: [
        {
            header: '',
            field: 'checked',
            selectionMode: 'single',
            width: '70px',
            fieldType: 'radio'
        },
        {
            header: 'Budget Name',
            field: 'budgetName',
            filter: false,
            width: '200px'
        },
        {
            header: 'Budget ID',
            field: 'budgetId',
            width: '200px'
        }
    ],
    EMP_PAYROLL_VOUCHER: [
        {
            header: 'Bill Number',
            field: 'ref',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Bill Number',
            filterMatchMode: 'contains',
            filterElement: 'search'
        },
        {
            header: 'Financial Year',
            field: 'year_id',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Financial Year',
            filterMatchMode: 'contains',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Financial Period',
            field: 'fin_period_name',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Financial Period',
            filterMatchMode: 'contains',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Office',
            field: 'office_name',
            width: '185px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Office ID',
            filterMatchMode: 'contains',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Group',
            field: 'emp_group_names',
            width: '160px',
            sortable: true,
            sortableDisabled: true,
            filter: false,
            filterPlaceholder: 'Search by Employee Group',
            filterMatchMode: 'contains',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Budget Office',
            field: 'budget_office_names',
            width: '160px',
            sortable: true,
            sortableDisabled: true,
            filter: false,
            filterPlaceholder: 'Search by Budget Office',
            filterMatchMode: 'contains',
            filterElement: 'search',
            fieldType: 'tooltip'
        },
        {
            header: 'Net Payment',
            field: 'amount',
            width: '160px',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Net Payment',
            filterMatchMode: 'contains',
            filterElement: 'search',
            headerAlign: 'right',
            bodyStyle: 'right',
            numericInput: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterMatchMode: 'contains',
            filterElement: 'date',
            width: '200px',
            fieldType: 'date'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            sortable: true,
            filterPlaceholder: 'Search by Is Active',
            filter: true,
            filterElement: 'dropdown',
            filterOptions: ['Yes', 'No'],
            filterMatchMode: 'contains',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            body: '',
            width: '80px'
        }
    ],
    EMP_PAYROLL_CNFM: [
        {
            header: 'Budget Office',
            field: 'officeName',
            width: '100px'
        },
        {
            header: 'Total No. of Employees',
            field: 'total',
            width: '100px'
        },
        {
            header: 'No. of Employees not Successfully Processed in the Scheduler',
            field: 'scheduler_unprocessed',
            width: '200px'
        },
        {
            header: 'No. of Employees whose Payroll Creation is Pending',
            field: 'payroll_pending',
            width: '200px'
        }
    ],
    EMP_VARIANCE: [
        {
            header: 'Pay Component',
            field: 'name',
            width: '130px',
            fieldType: 'tooltip'
        },
        {
            header: 'Current Period Amount',
            field: 'updated_amount',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Last Period Amount',
            field: 'previous_month_amount',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Variance',
            field: 'variance_amount',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        }
    ],
    EMP_PAYROLL_EMP_TABLE: [
        {
            header: '',
            field: 'checked',
            fieldType: 'checkbox',
            selectionMode: 'multiple',
            width: '55px'
        },
        {
            header: 'SI. No',
            field: 'slno',
            width: '65px'
        },
        {
            header: 'Employee Name & ID',
            field: 'emp_ref_name',
            fieldType: 'button',
            width: '120px'
        },
        {
            header: 'Employee Group',
            field: 'emp_group',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Budget Office',
            field: 'emp_budget_ofc',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Number of Days',
            field: 'updated_days',
            fieldType: 'inputNumber',
            inputMaxlength: 20,
            inputType: 'number',
            inputPlaceholder: 'Number of Days',
            maxKey: 'days',
            width: '100px'
        },
        {
            header: 'Current Net Pay',
            field: 'updated_amount',
            width: '140px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Last Net Pay',
            field: 'previous_month_amount',
            width: '135px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Variance',
            field: 'variance_amount',
            fieldType: 'button',
            buttonType: 'popupcheckcondition',
            checkField1: 'is_edited',
            checkField2: 'is_days_updated',
            width: '140px',
            headerAlign: 'right',
            bodyStyle: 'right'
        },
        {
            header: '',
            field: 'InlineActions',
            fieldType: 'InlineActions',
            filter: false,
            width: '100px'
        }
    ],
    EMP_PAYSLIP: [
        {
            header: 'Sl. No',
            field: 'slno',
            width: '80px'
        },
        {
            header: 'Components',
            field: 'name',
            width: '150px'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: false,
            delete: false,
            view: true,
            width: '50px'
        }
    ],
    EMP_ARRIERS: [
        {
            header: 'Sl. No',
            field: 'slno',
            width: '50px',
            fieldType: 'tooltip'
        },
        {
            header: 'Components',
            field: 'name',
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Earning Amount',
            field: 'earningAmt',
            width: '180px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction Amount',
            field: 'dedAmt',
            width: '180px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Reference ID',
            field: '',
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Earning Amount',
            field: 'lastEarningAmt',
            width: '180px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction Amount',
            field: 'lastDedAmt',
            width: '180px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Reference ID',
            field: '',
            width: '180px',
            fieldType: 'tooltip'
        }
    ],
    EMP_ARRIERS_MONTHLY: [
        {
            header: 'Sl. No',
            field: 'slno',
            width: '50px',
            fieldType: 'tooltip'
        },
        {
            header: 'Components',
            field: 'name',
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Earning Amount',
            field: 'earningAmt',
            width: '180px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction Amount',
            field: 'dedAmt',
            width: '180px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        },

        {
            header: '',
            fieldType: 'actions',
            width: '50px'
        },
        {
            header: 'Earning Amount',
            field: 'lastEarningAmt',
            width: '180px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction Amount',
            field: 'lastDedAmt',
            width: '180px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        }
    ],
    EMP_ARRIERS_BENIFY: [
        {
            header: 'Bank Account Holder',
            field: 'holder_name',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Account Number',
            field: 'account_number',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'ifsc_code',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'bank_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Branch Name',
            field: 'branch_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Share(%)',
            field: 'share',
            fieldType: 'inputNumber',
            inputMaxlength: 20,
            inputType: 'number',
            inputMode: 'decimal',
            inputPlaceholder: 'Share(%)',
            width: '150px'
        },
        {
            header: 'Payment Amount',
            field: 'amount',
            fieldType: 'inputNumber',
            inputMaxlength: 20,
            inputType: 'number',
            inputMode: 'decimal',
            inputPlaceholder: 'Payment Amount',
            width: '150px'
        },
        {
            header: '',
            field: 'InlineActions',
            fieldType: 'InlineActions',
            filter: false,
            width: '100px'
        }
    ],
    PAY_BENIFY_LIST: [
        {
            header: '',
            fieldType: 'radio',
            field: 'checked',
            filter: false,
            width: '40px'
        },
        {
            header: 'Beneficiary Name',
            field: 'name',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Beneficiary Name',
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Beneficiary ID',
            field: 'ref',
            width: '150px',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Beneficiary ID',
            fieldType: 'tooltip'
        },
        {
            header: 'Beneficiary Category',
            field: 'type.name',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Beneficiary Category',
            width: '160px',
            fieldType: 'tooltip'
        }
    ],
    BANK_DETAILS_VIEW: [
        {
            header: 'Account Category',
            field: 'account.category',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Type',
            field: 'account.type',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Number',
            field: 'account.number',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'account.bank.ifsc',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'account.bank.name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Branch Name',
            field: 'account.bank.branch_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Currency',
            field: 'account.ccy',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '80px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Holder Name',
            field: 'account.holder_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            validation: 'unique',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            className: 'activeInactiveSwitch',
            width: '100px',
            headerAlign: 'center'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            className: 'activeInactiveSwitch',
            filterElement: null,
            sortable: false,
            width: '100px',
            bodyStyle: 'center',
            headerAlign: 'center'
        }
    ],
    ADDRESS_DETAILS: [
        {
            header: 'Address',
            field: 'address.line1',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'City',
            field: 'address.district',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Pin Code',
            field: 'address.pin_code',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Country',
            field: 'address.country',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'State',
            field: 'address.state',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub-District/Block/Tehsil',
            field: 'address.sub_district',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Village',
            field: 'address.village',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Panchayat',
            field: 'address.panchayat',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            validation: 'unique',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        }
    ],
    EMP_PAY_ENTITLEMENT: [
        {
            header: 'S.No.',
            field: 'slno',
            filterElement: null,
            sortable: true,
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Earnings And Deductions',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Employee Earnings And Deductions',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Earning Amount',
            field: 'earning_amt',
            filter: true,
            filterPlaceholder: 'Earning Amount',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Deduction Amount',
            field: 'deduction_amt',
            filter: true,
            filterPlaceholder: 'Deduction Amount',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        }
    ],
    MONTHS: {
        JAN: 'January',
        FEB: 'February',
        MAR: 'March',
        APR: 'April',
        MAY: 'May',
        JUN: 'June',
        JUL: 'July',
        AUG: 'August',
        SEP: 'September',
        OCT: 'October',
        NOV: 'November',
        DEC: 'December'
    },
    MONTHS_: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    EXP_PERSONNEL_BILLS: [
        {
            header: 'Bill Number',
            field: 'ref',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Bill Number',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Employee Name & ID',
            field: 'empName',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bill Type',
            field: 'billTypeCode',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterPlaceholder: 'Search by Bill Type',
            filterElement: 'dropdown',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub bill type',
            field: 'subBillTypeCode',
            filter: true,
            sortable: true,
            sortableDisabled: true,
            filterPlaceholder: 'Search by Sub bill type',
            filterElement: 'dropdown',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Budget Office',
            field: 'budget_office',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Amount',
            field: 'gross_bill_amount',
            width: '130px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Net Payment',
            field: 'net_payment',
            width: '110px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            width: '170px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '210px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            edit: true,
            delete: true,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '80px'
        }
    ],
    EXP_PERSONNEL_BILLS_CONFIG: [
        {
            header: 'Personnel Bill Type ID',
            field: 'ref',
            sortable: true,
            filter: true,
            filterPlaceholder: 'Search by ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '150px'
        },
        {
            header: 'Bill Type Code',
            field: 'code',
            sortable: true,
            filter: true,
            filterElement: 'search',
            filterPlaceholder: 'Search by Code',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bill Type Description',
            field: 'description',
            sortable: false,
            filter: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub Bill Type',
            field: 'sub_bill_type',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterElement: 'search',
            filterPlaceholder: 'Search by Sub Bill Type',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Value',
            field: 'bill_value_type',
            sortable: true,
            sortableDisabled: true,
            filter: true,
            filterElement: 'search',
            filterPlaceholder: 'Gross Bill Value',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Employee Status',
            field: 'applicable_emp_status',
            filter: false,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterElement: 'search',
            filterPlaceholder: 'Workflow Status',
            sortable: true,
            width: '150px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            sortable: true,
            filterElement: 'date',
            filterPlaceholder: 'Last Update Date & Time',
            width: '170px',
            filterMatchMode: 'contains',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            width: '140px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            edit: true,
            delete: false,
            width: '80px'
        }
    ],
    JOURNEY_DETAILS: [
        {
            header: 'Date & Time',
            field: 'departure_date',
            fieldType: 'tooltip',
            sortable: true,
            width: '150px'
        },
        {
            header: 'From',
            field: 'departure_from',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Date & Time',
            field: 'arrival_date',
            fieldType: 'tooltip',
            sortable: true,
            width: '150px'
        },
        {
            header: 'To',
            field: 'arrival_to',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Distance in Kms',
            field: 'distance',
            sortable: true,
            sortableDisabled: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Mode of Travel',
            field: 'travel_mode',
            sortable: true,
            sortableDisabled: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Ticket No',
            field: 'ticket_no',
            sortable: true,
            sortableDisabled: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Entitled Class',
            field: 'travel_class',
            sortable: true,
            sortableDisabled: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Fare Paid',
            field: 'fare_paid',
            sortable: true,
            sortableDisabled: true,
            width: '120px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Claim Allowed',
            field: 'claim_allowed',
            sortable: true,
            sortableDisabled: true,
            width: '120px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    EXP_SUB_TYPE: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '40px'
        },
        {
            header: 'Personnel Bill Sub-Type Code',
            field: 'code',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Personnel Bill Sub-Type Description',
            field: 'description',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active?',
            fieldType: 'Boolean',
            field: 'is_active',
            width: '50px'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    EXP_PER_DEDUCT_DETAILS: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '50px'
        },
        {
            header: 'Deduction',
            field: 'deduction.name',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Value',
            field: 'deduction.value',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Mandatory?',
            field: 'is_mandatory',
            fieldType: 'Boolean',
            width: '100px'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    EXP_BILL_DETL_CONFIG: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '50px'
        },
        {
            header: 'Field',
            field: 'field',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Visible?',
            field: 'is_visible',
            fieldType: 'checkboxLabel',
            width: '100px',
            alignTo: 'center'
        },
        {
            header: 'Is Mandatory?',
            field: 'is_mandatory',
            fieldType: 'checkboxLabel',
            disableKey: 'mandateDisable',
            width: '100px',
            alignTo: 'center'
        }
    ],
    EXP_ADG_RECOVERIES: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '50px'
        },
        {
            header: 'Recovery Type',
            field: 'recovery_type',
            width: '120px'
        },
        {
            header: 'Is Mandatory?',
            field: 'is_mandatory',
            fieldType: 'Boolean',
            width: '100px'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Day_Allowance: [
        {
            header: 'Days',
            field: 'days',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },

        {
            header: 'Rate',
            field: 'rate',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'No. of Persons',
            field: 'no_of_persons',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount',
            field: 'amount',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Stay_Details: [
        {
            header: 'Date From',
            field: 'from_date',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },

        {
            header: 'Date To',
            field: 'to_date',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Stay Type',
            field: 'stay_type',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Halting Charges',
            field: 'halting_charge',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            bodyStyle: 'right',
            headerAlign: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Family_Members: [
        {
            header: 'Name',
            field: 'name',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },

        {
            header: 'Age',
            field: 'age',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Relationship',
            field: 'relationship',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Transportation_Charges_of_Personnel_Effect: [
        {
            header: 'Date',
            field: 'date',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },

        {
            header: 'Mode',
            field: 'mode',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Station From',
            field: 'station_from',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Station To',
            field: 'station_to',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Weight',
            field: 'weight',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Rate',
            field: 'rate',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount',
            field: 'amount',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Transportation_Charges_of_Private_Conveyance: [
        {
            header: 'Mode',
            field: 'mode',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Station',
            field: 'station',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Weight',
            field: 'weight',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Rate',
            field: 'rate',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Distance(Kms)',
            field: 'distance',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Packaging Charge',
            field: 'packing_charge',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount',
            field: 'amount',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Transfer_Chargers: [
        {
            header: 'Distance(Kms)',
            field: 'distance',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },

        {
            header: 'Rate',
            field: 'rate',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Date of Home journey',
            field: 'home_journey_date',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Transfer Grant',
            field: 'transfer_grant',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip',
            headerAlign: 'right',
            bodyStyle: 'right'
        },
        {
            header: 'Amount',
            field: 'amount',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Recoveries: [
        {
            header: 'Recovery Type',
            field: 'recoveryType',
            sortable: true,
            width: '150px'
        },

        {
            header: 'Recovery ID',
            field: 'recoveryID',
            sortable: true,
            sortableDisabled: true,
            width: '150px'
        },
        {
            header: 'Outstanding Amount',
            field: 'outstandingAmount',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right'
        },
        {
            header: 'Recovery in this bill',
            field: 'recoveryInBill',
            sortable: true,
            sortableDisabled: true,
            width: '150px'
        },
        {
            header: 'Remaining Amount',
            field: 'remainingAmount',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Deductions: [
        {
            header: 'Deduction',
            field: 'deduction.name',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },

        {
            header: 'Value',
            field: 'dedValue',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount',
            field: 'amount',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Remarks',
            field: 'remarks',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Previous_BILLS: [
        {
            header: 'Bill Number',
            field: 'ref',
            /*
             * fieldType: 'clickable',
             * customView: true,
             */
            width: '170px',
            fieldType: 'tooltip'
        },
        {
            header: 'Financial Year',
            field: 'fin_year',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Approval Date',
            field: 'version_date',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Amount',
            field: 'gross_bill_amount',
            width: '130px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Net Payment',
            field: 'net_payment',
            width: '110px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        }
    ],
    THIRD_PARTY: [
        {
            header: 'SL NO',
            field: 'slno',
            width: '80px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Account Holder',
            field: 'holder_name',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Account Number',
            field: 'account_number',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'ifsc_code',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'bank_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Branch',
            field: 'branch_name',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Share',
            field: 'share',
            fieldType: 'inputNumber',
            inputMaxlength: 20,
            inputType: 'number',
            inputMode: 'decimal',
            inputPlaceholder: 'Share',
            width: '150px'
        },
        {
            header: 'Payment Amount',
            field: 'amount',
            fieldType: 'inputNumber',
            inputMaxlength: 20,
            inputType: 'number',
            inputMode: 'decimal',
            inputPlaceholder: 'Payment Amount',
            width: '150px'
        },
        {
            header: '',
            field: 'InlineActions',
            fieldType: 'InlineActions',
            filter: false,
            width: '100px'
        }
    ]
};
export default TableSchema;
