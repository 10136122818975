/* eslint-disable max-depth */
import React, { useEffect, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import Schema from '../../schema/AppSchema';
import { useQuery } from '@apollo/client';
import { eventClient } from '../../apollo';
import { FmisFunctions } from './fmisFunctions';

const pendingVA = process.env.PUBLIC_URL + '/assets/icons/PendingVA.svg';
const ActiceContracts = process.env.PUBLIC_URL + '/assets/icons/ActiceContracts.svg';
const ActiceContractsSel = process.env.PUBLIC_URL + '/assets/icons/ActiceContractsSel.svg';
const PendingVASel = process.env.PUBLIC_URL + '/assets/icons/PendingVASel.svg';
const TotalContractsSel = process.env.PUBLIC_URL + '/assets/icons/TotalContractsSel.svg';
const PendingWS = process.env.PUBLIC_URL + '/assets/icons/PendingWS.svg';
const PendingWSSel = process.env.PUBLIC_URL + '/assets/icons/PendingWSSel.svg';

const imgsrc = {
    pendingVA: pendingVA,
    PendingWS: PendingWS,
    PendingWSSel: PendingWSSel,
    TotalContractsSel: TotalContractsSel,
    ActiceContracts: ActiceContracts,
    PendingVASel: PendingVASel,
    ActiceContractsSel: ActiceContractsSel
};

const TopCardComponent = (props) => {
    const cardsList = [
        {
            label: props.approvedLabel || 'Approved',
            count: 0,
            default_src: 'pendingVA',
            highlight_src: 'TotalContractsSel',
            ids: [],
            status_obj: {},
            selected: true
        }
    ];

    const [cards, setCards] = useState(FmisFunctions[props.fmis_function] ? [] : props.cards ? props.cards : cardsList);

    useQuery(Schema.fetchWFStats, {
        client: eventClient,
        skip: !FmisFunctions[props.fmis_function],
        variables: { fmis_function: FmisFunctions[props.fmis_function], fmis_sub_function: props.fmis_sub_function },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.wf_stats && data.wf_stats.stats && data.wf_stats.stats.length > 0) {
                data.wf_stats.stats[0].label = props.approvedLabel || 'Approved';
                if (props.module === 'AAPE') {
                    if (props.auditType === window.AUDIT_TYPE.SCHEDULE_AUDIT || props.auditType === window.AUDIT_TYPE.FOLLOW_UP_AUDIT) {
                        data.wf_stats.stats[1].count =
                            data.wf_stats.stats[1].new_objs.filter((obj) => obj.audit_assignment_id === props.assignmentID).length +
                            Object.keys(data.wf_stats?.stats[1].status_obj).length;
                    } else {
                        data.wf_stats.stats[1].count =
                            data.wf_stats.stats[1].new_objs.filter((obj) => obj.aap_item_id === props.aapItemID).length +
                            Object.keys(data.wf_stats?.stats[1].status_obj).length;
                    }
                }
                data.wf_stats.stats[0].count = props.cardCount >= 0 ? props.cardCount : data.wf_stats.stats[0].count;

                if (props?.selected?.label) {
                    let obj = data.wf_stats.stats.find((i) => i.label === props.selected.label);
                    obj.selected = obj ? true : false;
                } else {
                    data.wf_stats.stats[0].selected = true;
                }
                setCards(data.wf_stats.stats);
            }
        },

    });
    useEffect(() => {
        cards[0] && (cards[0].count = props.cardCount || cards[0].count);
        setCards(cards);
    }, [props]);

    const [hoverText, setHoverText] = useState('');

    const onClickCard = (item, index) => {
        if (props.navigateTo) {
            props.navigateTo(item);
        } else {
            cards.forEach((a) => {
                a.selected = false;
                if (item.label === a.label) a.selected = true;
            });
            let columns = JSON.parse(JSON.stringify(props.columns));
            let variables;
            if (index === 0) {
                variables = { is_latest: { equals: true } };
            } else {
                const colVarObj = getColumnsAndVariables(item, columns);
                variables = colVarObj.variables;
                columns = colVarObj.columns;
            }
            props.fetchParent(variables, item, columns);
        }
    };
    const getColumnsAndVariables = (item, columns) => {
        let variables;
        if (item.ids?.length > 0) {
            variables = { id: { in: item.ids } };
            columns[0].field = 'txnId';
            columns[0].filterPlaceholder = 'Search By Transaction ID';
            columns[0].header = 'Transaction ID';
        }
        return { variables: variables, columns: columns };
    };
    let op;
    const onHover = (e, item) => {
        if (item !== '') {
            setHoverText(item);
            op.toggle(e);
        } else {
            op.hide(e);
        }
    };

    return (
        <div onWheel={(e) => onHover(e, '')}>
            <div className="row-card m-b-10">
                {cards?.length > 0 &&
                    cards.map((item, ind) => (
                        <div
                            key={ind}
                            className={cards.length > 1 ? (props.class ? props.class : 'thumbnail') : 'thumbnail w-23 '}
                            onClick={() => onClickCard(item, ind)}
                            onMouseOver={(e) => onHover(e, item.label)}
                            onMouseOut={(e) => onHover(e, '')}
                        >
                            <div className="hover-card">
                                <img
                                    className={'w-100 cardimg'}
                                    src={
                                        item.selected
                                            ? imgsrc[item.highlight_src]
                                                ? imgsrc[item.highlight_src]
                                                : imgsrc[item.highlightSrc]
                                            : imgsrc[item.default_src]
                                            ? imgsrc[item.default_src]
                                            : imgsrc[item.defaultSrc]
                                    }
                                    alt={item.label}
                                />
                                <span className={`caption ${item.selected ? 'light-color' : ''}`}>
                                    <span>
                                        <b className="font-40 m-r-10">
                                            {props.topGridProps
                                                ? props.topGridProps?.[item.countField]
                                                    ? props.topGridProps?.[item.countField]
                                                    : 0
                                                : item.count}
                                        </b>
                                    </span>
                                    <span className="word-break-word">
                                        <b className="status-card-font">{item.label}</b>
                                    </span>
                                </span>
                            </div>
                        </div>
                    ))}

                <OverlayPanel ref={(el) => (op = el)} className="overlaypanel-width">
                    {hoverText !== '' ? hoverText : 'Information not defined'}
                </OverlayPanel>
            </div>
        </div>
    );
};

export default TopCardComponent;
