/* eslint-disable max-depth */
import React, { useState } from 'react';
import Schema from '../../../schema/AppSchema';
import TableSchema from '../CoMasTableSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useQuery, useMutation } from '@apollo/client';
import FDataTable from '../../../components/fDataTable/FDataTable';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import { Toast } from 'primereact/toast';
import TopCardComponent from '../../../components/topComponents/TopCardComponent';
import { getDate, getMonth, getDateVal, convertTextCase } from '../../../util';
import { Button } from 'appkit-react';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';
import IsAuthorized from '../../../commons/IsAuthorized';
import AuditComponent from '../../../components/auditLog/AuditComponent';
import RecallButton from '../../../commons/RecallButton';
import { eventClient } from '../../../apollo';

const DocumentCategory = (props) => {
    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.COMMON_MASTERS.find((a) => a.routerLink === loc[1]);

    let tableColumns = JSON.parse(JSON.stringify(TableSchema.Dcocument_Category));
    const [state, setState] = useState({
        customizedRows: [],
        selectedData: {},
        showModal: false,
        loading: true,
        createModal: false,
        where: { is_latest: { equals: true } },
        columns: tableColumns,
        howConfirmModal: false,
        commentRequired: false
    });
    const [growl, setGrowl] = useState();
    const [selected, setSelected] = useState({});
    const [cardCount, setCardCount] = useState('');
    const [reference, setReference] = useState({});
    const [recallObj, setRecallObj] = useState();
    const [showAudit, setShowAudit] = useState(false);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalRecords, setTotalRecords] = useState();
    const [pageRows, setPageRows] = useState(10);
    const [searchFieldKey, setSearchFieldKey] = useState({});
    const [sortField, setSortField] = useState('version_date');
    const [tableLoader, setTableLoader] = useState(false);
    const [where, setWhere] = useState(
        props?.whereQry ? { ...props.whereQry, ...{ is_latest: { equals: true } } } : { is_latest: { equals: true } }
    );
    const [sortOrder, setSortOrder] = useState('asc');

    const assignedTo = {
        header: 'Assigned To',
        field: 'assignedTo',
        filter: true,
        sortable: true,
        filterMatchMode: 'contains',
        filterPlaceholder: 'Search By Assigned To',
        width: '200px',
        fieldType: 'tooltip'
    };
    const [recallLoader, setRecallLoader] = useState(false);
    const onClickRecall = (rowData) => {
        setState({ ...state, selectedData: rowData });
    };
    const setCustomizedRows = () => {
        let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
        state.customizedRows.splice(index, 1);
        setState({ ...state, selectedData: {}, showConfirmModal: false, customizedRows: state.customizedRows });
        setRecallLoader(false);
    };
    const handleCalRecallStatus = (flag) => {
        setState({ ...state, showConfirmModal: true, commentRequired: flag });
    };
    const handleCancelModal = () => {
        setState({ ...state, showConfirmModal: false });
    };
    const handleLoader = (flag) => {
        setRecallLoader(flag);
    };

    let variables = { where: state.where, orderBy: { [sortField]: sortOrder } };
    if (!selected.label || selected.label === 'Total Document Category Masters') {
        variables.skip = skip;
        variables.take = take;
    }

    let {
        refetch: fetchDocuments,
        error,
        loading: docsLoader
    } = useQuery(Schema.documentCategories, {
        fetchPolicy: 'no-cache',
        variables: variables,
        onCompleted: (response) => {
            if (response?.documentCategories) {
                prepareRows(response);
                return;
            }
            setState({ ...state, customizedRows: response.documentCategories });
        },
        onError: () => {
            setState({ ...state, loading: false });
            setTableLoader(false);
        }
    });
    const prepareRows = (response) => {
        if (selected.new_objs?.length > 0 && selected.label === 'Pending With Self') {
            response.documentCategories = [...response.documentCategories, ...selected.new_objs];
        }
        for (let index = 0; index < response.documentCategories.length; index++) {
            let ele = response.documentCategories[index];
            if (
                selected.ids?.length > 0 &&
                selected.label !== 'Total Document Category Masters' &&
                selected.status_obj &&
                selected.status_obj[ele.id] &&
                selected?.status_obj[ele.id]?.record
            ) {
                let record = selected?.status_obj[ele.id]?.record;
                record.active_status = record.is_active === true ? 'Yes' : 'No';
                record.fileSize = record.file_size ? record.file_size + ' MB' : '';
                record.updated_at_local = getDate(record.version_date);

                record.updated_at_local = getDate(record.version_date);
                record.status = selected.status_obj[ele.id].status;
                response.documentCategories[index] = record;
            } else if (
                selected.ids?.length > 0 &&
                selected.label !== 'Total Document Category Masters' &&
                selected.status_obj &&
                selected.status_obj[ele.id]
            ) {
                ele.status = selected.status_obj[ele.id].status;
                ele.txnId = selected.status_obj[ele.id].txnId;
                ele.assignedTo = convertTextCase(selected.status_obj[ele.id].assignedTo, false) || '';
            }
            ele.upload_type = ele.upload_type ? convertTextCase(ele.upload_type, true) : '';
            ele.active_status = ele.is_active === true ? 'Yes' : 'No';
            ele.file_type = ele.file_type ? ele.file_type?.split(',')?.join(', ') : '';
            ele.fileSize = ele.file_size ? ele.file_size + ' MB' : '';
            ele.updated_at_local = getDate(ele.version_date);
        }

        let uploadType = response.documentCategories?.map((a) => a.upload_type)?.filter((item, i, ar) => ar.indexOf(item) === i);
        let fileSize = response.documentCategories?.map((a) => a.fileSize)?.filter((item, i, ar) => ar.indexOf(item) === i);
        let status = response.documentCategories?.map((a) => a.status)?.filter((item, i, ar) => ar.indexOf(item) === i);
        let activeStatus = response.documentCategories?.map((a) => a.active_status)?.filter((item, i, ar) => ar.indexOf(item) === i);
        state.columns.forEach((a) => {
            if (a.field === 'upload_type') {
                a.filterOptions = uploadType;
            }
            if (a.field === 'fileSize') {
                a.filterOptions = fileSize;
            }
            if (a.field === 'status') {
                a.filterOptions = status;
            }
            if (a.field === 'active_status') {
                a.filterOptions = activeStatus;
            }
        });

        setState({
            ...state,
            customizedRows: response.documentCategories,
            showModal: false,
            selectedData: {},
            columns: state.columns,
            loading: false
        });
        setTableLoader(false);
    };

    const [deleteWorkFlowAction, { loading: delLoader }] = useMutation(Schema.deleteOneEntityRecord, {
        client: eventClient,
        onCompleted: (data) => {
            if (data) {
                let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
                state.customizedRows.splice(index, 1);
                setState({
                    ...state,
                    customizedRows: state.customizedRows,
                    loading: false,
                    showModal: false,
                    selectedData: {}
                });
                growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_MASTERS.DeleteDocumentCategoryMaster });
                return;
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const handleCancel = () => {
        setState({ ...state, showModal: false, selectedData: {}, createModal: false, loading: false });
    };
    const handleDelete = () => {
        if (state.selectedData?.entity_id) {
            deleteWorkFlowAction({
                variables: {
                    where: { id: state.selectedData.entity_id }
                }
            });
            return;
        }
    };

    const onClickDelete = (rowData) => {
        setState({ ...state, selectedData: rowData, showModal: true });
    };

    const navigateTo = (query, select, columns) => {
        if (select.label !== selected.label) {
            let tWhere = JSON.parse(JSON.stringify(where));
            setWhere(query);

            if (select.label === 'Pending For Verification/Approval') {
                setRecallObj(select.canRecall);
                columns.splice(columns.length - 1, 0, assignedTo);
            }
            setState({
                ...state,
                where: query,
                columns: columns,
                loading: true
            });
            setSelected(select);
            setSkip(0);
            setTake(10);
            setPageRows(10);
            setSortField('version_date');
            setSortOrder('asc');
            setSearchFieldKey({});
            setTableLoader(true);
            if (query?.id?.in.toString() === tWhere?.id?.in.toString()) {
                fetchDocuments({ variables: { where: query } }).then((response) => {
                    if (response) prepareRows(response.data);
                });
            }
        }
    };

    const onClickEdit = (rowData, action) => {
        if (rowData.entity_id) {
            props.history.push(
                action
                    ? 'documentcategory/' + action + '/' + rowData['entity_id'] + '/draft'
                    : 'documentcategory/view/' + rowData['entity_id'] + '/draft'
            );
            localStorage.setItem('transaction_id', rowData.txnId);
            return;
        }
        props.history.push(action ? 'documentcategory/' + action + '/' + rowData['id'] : 'documentcategory/view/' + rowData['id']);
        localStorage.setItem('transaction_id', rowData.txnId);
    };
    const onClickAudit = (rowData) => {
        setReference(rowData);
        setShowAudit(true);
    };
    const goBack = () => {
        setShowAudit(false);
    };
    const actionTemplate = (rowData) => {
        const showRecallBtn = recallObj && recallObj[rowData.id];
        return (
            <>
                {selected.label === 'Pending With Self' && (
                    <IsAuthorized
                        privRequired={Privileges?.UPDATE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-r-5 m-l-5"
                                onClick={() => onClickEdit(rowData, 'edit')}
                            >
                                <img
                                    title="Edit"
                                    className="pointer"
                                    src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                    alt="Edit Icon"
                                    height="20px"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status === 'Draft' && selected.label === 'Pending With Self' && (
                    <IsAuthorized
                        privRequired={Privileges?.DELETE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                onClick={() => onClickDelete(rowData)}
                            >
                                <img
                                    className="pointer"
                                    height="20px"
                                    title="Delete"
                                    src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                    alt="Delete Icon"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status !== 'Draft' && (
                    <button className="a-btn-background-transparent a-icon-container m-l-5 m-r-5" onClick={() => onClickAudit(rowData)}>
                        <img
                            className="a-font-18"
                            title="Audit"
                            src={process.env.PUBLIC_URL + '/assets/icons/audit_trail.svg'}
                            alt="view Icon"
                        />
                    </button>
                )}
                {showRecallBtn && selected.label === 'Pending For Verification/Approval' && (
                    <RecallButton
                        rowData={rowData}
                        setCustomizedRows={setCustomizedRows}
                        onClickRecall={onClickRecall}
                        source_id={state.selectedData?.id}
                        handleCalRecallStatus={handleCalRecallStatus}
                        handleCancelModal={handleCancelModal}
                        handleLoader={handleLoader}
                        commentRequired={state.commentRequired}
                        showConfirmModal={state.showConfirmModal}
                        growl={growl}
                        module={'System Administration'}
                        fmis_function={'SYS_DOC_CATEGORY'}
                    />
                )}
            </>
        );
    };
    const { refetch: refetchCount } = useQuery(Schema.documentCategoryCount, {
        fetchPolicy: 'no-cache',
        variables: { where: state.where },
        skip: selected.label && selected.label !== 'Total Document Category Masters',
        onCompleted: (resp) => {
            setTotalRecords(resp.documentCategoryCount);
            if (!cardCount || cardCount === '') {
                setCardCount(resp.documentCategoryCount);
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const onPageChange = (event) => {
        setTableLoader(true);
        setSkip(event.first);
        setTake(event.rows);
        setPageRows(event.rows);
        if (!selected.label || selected.label === 'Total Document Category Masters') {
            refetchCount();
        }
    };
    const searchRecord = (e, column, filterKeys) => {
        setTableLoader(true);
        Object.keys(filterKeys).forEach((filterKey) => {
            if (filterKeys[filterKey].contains === null) {
                delete filterKeys[filterKey];
            }
            return filterKey;
        });
        let keyData = { ...filterKeys };
        setSearchFieldKey(keyData);
        let obj;
        obj = { is_latest: { equals: true } };
        if (filterKeys && filterKeys['status']) {
            if (filterKeys['status'].contains) {
                filterKeys['status'] = { contains: filterKeys['status'].contains, mode: 'insensitive' };
            } else {
                delete filterKeys['status'];
            }
        }
        if (filterKeys['updated_at_local']) {
            if (filterKeys['updated_at_local'].contains) {
                const time = new Date(filterKeys['updated_at_local'].contains);
                const date = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T00:00:00.000Z';
                const date2 = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T23:59:59.000Z';
                filterKeys['version_date'] = { gte: date, lte: date2 };
                delete filterKeys['updated_at_local'];
            } else {
                delete filterKeys['updated_at_local'];
            }
        }
        if (filterKeys['active_status']) {
            if (filterKeys['active_status'].contains) {
                filterKeys['is_active'] = { equals: filterKeys['active_status'].contains === 'Yes' ? true : false };
            }
            delete filterKeys['active_status'];
        }
        if (filterKeys['fileSize']) {
            if (filterKeys['fileSize'].contains) {
                let val = filterKeys['fileSize'].contains.replace(' MB', '');
                filterKeys['file_size'] = { equals: val ? parseFloat(val) : 0 };
            }
            delete filterKeys['fileSize'];
        }

        if (Object.keys(state.where).length === 1 && Object.keys(filterKeys).length === 0) {
            setTableLoader(false);
        }
        setState({ ...state, where: { ...obj, ...filterKeys } });
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
        if (!selected.label || selected.label === 'Total Document Category Masters') {
            refetchCount();
        }
    };
    const onSort = (event) => {
        setTableLoader(true);
        let srtField = event.sortField;
        switch (event.sortField) {
            case 'updated_at_local':
                srtField = 'version_date';
                break;
            default:
        }
        let srtOrder = 'asc';
        if (sortField === srtField) {
            srtOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        }
        setSortOrder(srtOrder);
        setSortField(srtField);
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
    };

    if (state.columns.length) state.columns[state.columns.length - 1].body = actionTemplate;

    if (error) return `Error! ${error.message}`;

    return (
        <div>
            {!showAudit ? (
                <div className={`${state.loading || delLoader || recallLoader || docsLoader ? ' mask page-body' : 'page-body'}`}>
                    <Toast ref={(el) => setGrowl(el)} />
                    {(state.loading || docsLoader) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                    <div>
                        <div className="page-header">
                            <span className="light-color">Document Category Master</span>
                        </div>
                        {
                            <div className="coa-card-content">
                                {!state.loading && !delLoader && !recallLoader && (
                                    <TopCardComponent
                                        fetchParent={(query, select, columns) => navigateTo(query, select, columns)}
                                        showPopUp={() => {
                                            props.history.push('documentcategory/create');
                                            localStorage.setItem('transaction_id', '');
                                        }}
                                        selected={selected}
                                        fmis_function="SYS_DOC_CATEGORY"
                                        approvedLabel="Total Document Category Masters"
                                        columns={tableColumns}
                                        cardCount={cardCount}
                                    />
                                )}
                                <div className="m-t-10">
                                    <div className="row flex-end  m-t-10 m-r-0 m-b-10">
                                        <IsAuthorized
                                            privRequired={Privileges.CREATE}
                                            officeCode={Privileges.OFFICE_CODE}
                                            yes={() => (
                                                <Button
                                                    size="md"
                                                    className="float-right"
                                                    onClick={() => {
                                                        props.history.push('documentcategory/create');
                                                        localStorage.setItem('transaction_id', '');
                                                    }}
                                                >
                                                    Add New Document Category
                                                </Button>
                                            )}
                                        ></IsAuthorized>
                                    </div>
                                </div>
                                {!state.loading && (
                                    <FDataTable
                                        rows={state.customizedRows}
                                        columns={state.columns}
                                        onClickDetails={(rowData) => onClickEdit(rowData)}
                                        deletePriv={Privileges.DELETE}
                                        updatePriv={Privileges.UPDATE}
                                        officeCode={Privileges.OFFICE_CODE}
                                        emptyMessage="Document Category Master are yet to be created"
                                        loading={tableLoader}
                                        className="primary-table"
                                        totalRecords={totalRecords}
                                        count={skip}
                                        onPageChange={(e) => onPageChange(e)}
                                        pageRows={pageRows}
                                        searchRecord={searchRecord}
                                        filterKeys={searchFieldKey}
                                        lazyLoad={!selected.label || selected.label === 'Total Document Category Masters' ? true : false}
                                        onSort={onSort}
                                        sortField={sortField}
                                        sortOrder={sortOrder === 'asc' ? 1 : -1}
                                        scrollable
                                    />
                                )}
                            </div>
                        }
                        {state.showModal && (
                            <DeleteConfirmModal
                                showModal={state.showModal}
                                handleCancel={handleCancel}
                                type={'Document Category Master'}
                                name={
                                    state.selectedData.entity_id
                                        ? `Transaction ID: ${state.selectedData.txnId}`
                                        : 'Document Category Master: ' + state.selectedData.txnId
                                }
                                handleDelete={handleDelete}
                                state={{ loading: delLoader }}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <AuditComponent
                    refence_label="Document Category Master"
                    goBack={goBack}
                    transaction_Id={reference.txnId}
                    reference_value={reference.ref}
                    sourceData={reference}
                />
            )}
        </div>
    );
};
export default DocumentCategory;
