import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Button } from 'appkit-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './HOADetailsModal.scss';
import { RadioButton } from 'primereact/radiobutton';
import InputComponent from '../InputComponent/InputComponent';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { convertTextCase } from '../../util';

export function HOADetailsModal(props) {
    let segmentList = props.hoaList ? props.hoaList : [];
    const [state, setState] = useState({
        loading: props?.hoaDetails?.variables ? true : false,
        searchText: '',
        selectedHOA: props?.selectedHOA ? props.selectedHOA : '',
        segmentsToRender: props.hoaList ? props.hoaList : [],
        selectedSegments: {},
        selected: {},
        emptyMessage: '',
        selectedCodes: []
    });

    useEffect(() => {
        if (!props.hoaDetails.variables) {
            let hoa = state.selectedHOA.split('-');
            if (props.selectedHOA) {
                props.hoaList.forEach((a, ind) => {
                    state.selectedSegments[ind] = hoa[ind];
                    prepareSegemnts(a, hoa, ind, true);
                    sortFunction(a);
                });
            } else {
                props.hoaList.forEach((a, ind) => {
                    state.selectedSegments = {};
                    prepareSegemnts(a, hoa, ind, false);
                    sortFunction(a);
                });
            }
        }
    }, [props.hoaList]);

    const prepareSegemnts = (a, hoa, ind, key) => {
        a.segments.map((b) => {
            if (key) {
                b.filterLabel = b.full_code + '-' + b.name;
                if (hoa[ind] === b.full_code) {
                    b.checked = true;
                    a.checked = true;
                }
            } else {
                b.checked = false;
                a.checked = false;
            }
            return b;
        });
        return a;
    };

    const { data, called } = useQuery(props.hoaDetails.fetchCompositionQry, {
        variables: props.hoaDetails.variables,
        skip: !props.hoaDetails.variables,
        client: props.hoaDetails.client || '',
        fetchPolicy: 'no-cache',
        onCompleted: (obj) => {
            let hoa = state.selectedHOA.split('-');
            if (obj && obj[props.hoaDetails['responseObj']]?.compositionWiseSegments?.length > 0) {
                obj[props.hoaDetails['responseObj']].compositionWiseSegments.forEach((a, ind) => {
                    if (state.selectedHOA) {
                        state.selectedSegments[ind] = hoa[ind];
                        a.segments.map((b) => {
                            b.filterLabel = b.full_code + '-' + b.name;
                            if (hoa[ind] === b.full_code) {
                                b.checked = true;
                                a.checked = true;
                            }
                            return b;
                        });
                        sortFunction(a);
                    } else {
                        state.selectedSegments = {};
                        a.segments.map((b) => {
                            b.checked = false;
                            a.checked = false;
                            return b;
                        });
                        sortFunction(a);
                    }
                });
                let segList = obj[props.hoaDetails['responseObj']].compositionWiseSegments;
                setState((prevState) => {
                    return { ...prevState, segmentsToRender: segList, loading: false };
                });
            } else {
                setState({ ...state, loading: false });
            }
        },
        onError: (res) => {
            let mappingsMsg = '';
            if (res?.graphQLErrors?.length > 0) {
                mappingsMsg = res.graphQLErrors[0].message;
            }
            setState({ ...state, loading: false, emptyMessage: mappingsMsg });
        }
    });
    if (called && data) {
        let hoa = state.selectedHOA.split('-');
        if (data[props.hoaDetails['responseObj']] && data[props.hoaDetails['responseObj']].compositionWiseSegments) {
            data[props.hoaDetails['responseObj']].compositionWiseSegments.forEach((a, ind) => {
                a.segments.map((b) => {
                    b.filterLabel = b.full_code + '-' + b.name;
                    if (hoa[ind] === b.full_code) {
                        b.checked = true;
                        a.checked = true;
                    }
                    return b;
                });
            });
        }
        let segList = data[props.hoaDetails['responseObj']].compositionWiseSegments;
        segmentList = segList;
    }
    const sortFunction = (a) => {
        return a.segments.sort((b, c) => {
            const keyA = b.id;
            const keyB = c.id;
            if (keyA && !keyB) {
                return -1;
            }
            if (keyA && keyB && parseInt(b.code) > parseInt(c.code)) {
                return 1;
            } else if (keyA && keyB && parseInt(c.code) > parseInt(b.code)) {
                return -1;
            }
            if (parseInt(b.code) > parseInt(c.code) && !keyA && !keyB) {
                return 1;
            } else if (parseInt(c.code) > parseInt(b.code) && !keyA && !keyB) {
                return -1;
            }
            return 0;
        });
    };

    const handleSubFunChange = (e, name) => {
        let selectedSegments = state.selectedCodes.length > 0 ? state.selectedSegments : {};
        segmentList.forEach((element) => {
            if (element.name === name) {
                element.segments.forEach((seg) => {
                    seg.checked = false;
                });
            }
        });
        let segments = state.segmentsToRender.map((el, index) => {
            let eleSegments = el.segments.map((temp) => {
                if (e.value === temp.id) {
                    el.checked = true;
                    selectedSegments[index] = temp.full_code;
                    temp.checked = el.name === name && e.value === temp.id;
                }
                if (temp.checked) selectedSegments[index] = temp.full_code;
                return temp;
            });
            el.segments = eleSegments;
            return el;
        });
        let selected = selectedSegments && Object.values(selectedSegments).join('-');
        setState({ ...state, selectedSegments: selectedSegments, segments, selectedHOA: selected });
    };
    const searchSegments = (e, index) => {
        let list = [];
        list = segmentList.map((el, id) => {
            let newElt = Object.assign({}, el);
            newElt.segments = newElt.segments.filter((temp) => {
                return index === id ? temp.filterLabel.toLowerCase().includes(e) : temp;
            });
            return newElt;
        });
        setState({ ...state, segmentsToRender: list, selected: { [index]: e } });
    };

    const ifNoInputValue = () => {
        setState({
            ...state,
            selectedHOA: ''
        });
    };
    // eslint-disable-next-line no-unused-vars
    const updateSegements = (codesArray, idx) => {
        let segmentsToRender = [...state.segmentsToRender];
        if (codesArray.length === 0) {
            segmentsToRender.forEach((obj) => {
                obj.segments.forEach((obj1) => {
                    obj1.checked = false;
                });
            });
        } else {
            codesArray.forEach((element, index) => {
                segmentsToRender[index].segments.forEach((obj) => {
                    obj.checked = obj.full_code === element;
                });
            });
        }
        return segmentsToRender;
    };

    const handleGlobalSearch = (ev) => {
        let codesArray = [];
        let emptyIndex;
        if (ev === '' || ev === null || ev === undefined) {
            ifNoInputValue();
        } else if (ev.includes('-')) {
            codesArray = ev.split('-');
        } else {
            codesArray.push(ev);
        }
        codesArray.filter((el, i) => {
            if (el === '') {
                emptyIndex = i;
            }
            return el;
        });
        if (codesArray.length === 0) {
            setState({
                ...state,
                selectedSegments: {}
            });
        }

        setState({
            ...state,
            selectedCodes: codesArray
        });
        if (codesArray.length > segmentList.length) return;
        setState({
            ...state,
            segmentsToRender: updateSegements(codesArray, emptyIndex),
            selectedHOA: ev
        });
    };

    const handleSave = () => {
        setState({ ...state, loading: true });
        let filteredArray = [];
        state.segmentsToRender.forEach((segment) => {
            return segment.segments.forEach((subSegment) => {
                let obj = {};
                if (subSegment.checked && props.hoaList) {
                    obj['seg_def_name'] = segment.seg_def_name;
                    obj['segment_code'] = subSegment.full_code;
                    obj['segment_full_code'] = subSegment.segment_full_code;
                    obj['segment_name'] = subSegment.segment_name;
                    obj['level_name'] = subSegment.level_name;
                } else if (subSegment.checked && !props.hoaList) {
                    obj['seg_def_name'] = segment.name;
                    obj['segment_code'] = subSegment.code;
                    obj['segment_full_code'] = subSegment.full_code;
                    obj['segment_name'] = subSegment.name;
                    obj['level_name'] = subSegment.seg_def.name;
                }
                filteredArray.push(obj);
            });
        });
        props.updateHOAInParent(filteredArray, state.selectedHOA, props.type);
        if (props.validateHOA) props.validateHOA(true);
        props.errors(false);
        props.handleErrors(true);
    };
    const getEmptyMsg = (searchTxt, name) => {
        let val = '';
        if (searchTxt !== '') {
            val = (
                <span>
                    {'No '}
                    <b>
                        &apos;
                        {convertTextCase(name, false)}
                        &apos;
                    </b>
                    {' details found'}
                </span>
            );
        } else {
            val = (
                <span>
                    {'Codes for '}
                    <b>
                        &apos;
                        {convertTextCase(name, false)}
                        &apos;
                    </b>
                    {' are yet to be created'}
                </span>
            );
        }
        return val;
    };
    const disableSave = () => {
        let exists = [];
        // Checking for atleast one code is checked
        state.segmentsToRender.forEach((element) => {
            element.segments.forEach((seg) => {
                if (seg.checked) {
                    exists.push(seg.checked);
                }
            });
        });
        return exists.length !== state?.segmentsToRender?.length || exists.length < state?.segmentsToRender?.length;
    };

    return (
        <div>
            <Modal
                visible={props.showModal}
                onCancel={props.handleHOACancel}
                className={state.loading ? 'common_css-mask hoa-modal xl-modal' : ' xl-modal hoa-modal'}
                backdropClosable={false}
            >
                <ModalHeader>
                    <div className="p-grid hoa-header p-justify-between">
                        <div className="p-col-4 m-t-15">
                            <b>HOA Details</b>
                        </div>
                        <div className="p-col-4 m-t-15 p-offset-3 p-r-45">
                            <Input
                                inputBoxSize="sm"
                                name="searchText"
                                type="text"
                                placeholder="Search"
                                maxLength={50}
                                onChange={(e) => handleGlobalSearch(e)}
                                value={state.selectedHOA}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <hr />
                </ModalHeader>
                <ModalBody className="p-15 p-b-0">
                    {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                    <div className="col-md-12 p-0 hoa-table radio-spacing">
                        {state.segmentsToRender?.length > 0 && (
                            <div className="p-grid hoa-banner">
                                <div className="col-md-4 p-0">
                                    <b className="f-14">Selected HOA : </b>
                                </div>
                                <div className="col-md-8">
                                    <b>{state.selectedHOA}</b>
                                </div>
                            </div>
                        )}
                        {!state.loading && (
                            <div className="row m-l-0 w-100 flex-row flex-nowrap">
                                {state.segmentsToRender?.length > 0
                                    ? state.segmentsToRender.map((seg, ind) => (
                                          <div
                                              key={seg.name}
                                              className={`p-0 ${state.segmentsToRender.length > 3 ? 'col-md-3' : 'col-md-4'}`}
                                          >
                                              <div>
                                                  <div className="hoa-colum-header m-t-10">
                                                      {<b>{convertTextCase(seg.name, false)}</b>}
                                                      <InputComponent
                                                          className="w-100 p-t-5"
                                                          inputBoxSize="sm"
                                                          name={'fimsSearch' + ind}
                                                          type="text"
                                                          value={state.selected[ind] || ''}
                                                          onChange={(e) => searchSegments(e, ind)}
                                                          placeholder={'Search by ' + convertTextCase(seg.name, false)}
                                                      />
                                                  </div>
                                                  <div className="slmapp-ht">
                                                      {seg.segments && seg.segments.length > 0 ? (
                                                          seg.segments.map((fm, index) => (
                                                              <div key={`${fm.name}${fm.code}${index}`} className="p-b-10 flex-row">
                                                                  <RadioButton
                                                                      inputId={fm.id}
                                                                      value={fm.id}
                                                                      onChange={(e) => handleSubFunChange(e, seg.name)}
                                                                      checked={fm.checked}
                                                                  />
                                                                  <label htmlFor={fm.id} className="p-radiobutton-label option-label">
                                                                      <EllipsisWithTooltip placement="auto">
                                                                          {fm.filterLabel}
                                                                      </EllipsisWithTooltip>
                                                                  </label>
                                                              </div>
                                                          ))
                                                      ) : (
                                                          <div className="fs-12 m-t-20">{getEmptyMsg(state.selected[ind], seg.name)}</div>
                                                      )}
                                                  </div>
                                              </div>
                                          </div>
                                      ))
                                    : state.emptyMessage !== '' && (
                                          <div className="display-flex">
                                              <img src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
                                              <span className="m-l-10 icon-primary font-15 info-message p_5">{state.emptyMessage}</span>
                                          </div>
                                      )}
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter className={state.loading ? 'mask p-15' : 'p-15'}>
                    <div className="m-0">
                        <Button size="sm" kind="primary" disabled={disableSave()} className="m-r-10" onClick={handleSave}>
                            Save
                        </Button>
                        <Button size="sm" kind="secondary" onClick={props.handleHOACancel}>
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}
export default HOADetailsModal;
