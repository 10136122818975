import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { Button } from 'appkit-react';

const SearchFilter = (props) => {
    const [state, setState] = useState({
        segments: props.options,
        selectAll: props.selectAll,
        selectedCodes: props.selectedCodes,
        searchText: ''
    });
    useEffect(() => {
        setState((prevState) => {
            return {
                ...prevState,
                selectedCodes: props.selectedCodes
            };
        });
    }, [props.selectedCodes]);
    useEffect(() => {
        setState((prevState) => {
            return {
                ...prevState,
                segments: props.options,
                selectAll: props.selectAll
            };
        });
    }, [props.options]);
    useEffect(() => {
        setState((prevState) => {
            return {
                ...prevState,
                selectAll: props.selectAll
            };
        });
    }, [props.selectAll]);

    const hadleCheckAll = (e) => {
        let obj = {};
        state.segments.forEach((b) => !b.hideMe && (obj[b.id] = e.checked));
        setState({ ...state, segments: state.segments, selectedCodes: { ...state.selectedCodes, ...obj }, selectAll: e.checked });
    };

    const handleChange = (e) => {
        if (e.checked) {
            state.selectedCodes[e.value] = true;
        } else {
            state.selectedCodes[e.value] = false;
        }
        setState({
            ...state,
            selectedCodes: state.selectedCodes
        });
    };

    const handleCancel = () => {
        setState({ ...state, selectAll: false });
        props.closeFilter(props.index);
    };
    const handleSave = () => {
        props.handleSearchChange(state.selectedCodes, props.name);
        handleCancel();
    };

    const customList = () => {
        return (
            <div className="p-t-10">
                <div className="panel-scroll">
                    <div className="border-btm display-flex align-center m-l-10 p-b-10">
                        <Checkbox
                            inputId={'all'}
                            className="align-chk"
                            value={state.selectAll}
                            onChange={(e) => hadleCheckAll(e)}
                            checked={state.selectAll}
                        ></Checkbox>
                        <label htmlFor={'all'} className="p-checkbox-label m-l-10 text-ellipses m-b-0" title="Select All Search Results">
                            Select All Search Results
                        </label>
                    </div>
                    <div className="scroll-flow">
                        <div className="m-l-10 p-t-5 p-b-5 scrollable-content">
                            {state.segments?.length > 0 &&
                                state.segments.map((fm, ind) => (
                                    <div
                                        key={`${fm.name}${fm.code}${ind}`}
                                        className={`p-t-5 p-b-5 align-chk ${fm.hideMe ? 'display-none' : ''}`}
                                    >
                                        <Checkbox
                                            inputId={fm.id}
                                            value={fm.id}
                                            onChange={(e) => handleChange(e)}
                                            checked={state.selectedCodes[fm.id]}
                                        ></Checkbox>
                                        <label htmlFor={fm.name} className="p-checkbox-label m-l-10 m-b-0 text-ellipses">
                                            <EllipsisWithTooltip placement="auto">{fm.filterLabel}</EllipsisWithTooltip>
                                        </label>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Button className="save-icon-green m-r-10 m-l-auto" kind="custom" size="md" onClick={handleSave}>
                        <span title="Add Filter" className="appkiticon fs-12 icon-check-mark-fill m-r-0"></span>
                    </Button>
                    <Button kind="custom" className="cancel-icon-red" size="md" onClick={handleCancel}>
                        <span title="Cancel" className="appkiticon fs-12 icon-close-fill m-r-0"></span>
                    </Button>
                </div>
            </div>
        );
    };

    return <div>{customList()}</div>;
};

export default SearchFilter;
