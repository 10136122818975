import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import HOASelection from '../../../../components/HOADetails/HOASelection';
import Schema from '../../../../schema/AppSchema';
import TextAreaComponent from '../../../../components/InputComponent/TextAreaComponent';
import { getDate } from '../../../../util';
import * as FormValidation from '../../../../Validation';

const Bills = (props) => {
    const [state, setState] = useState({
        showHOA: false
    });
    const [formState, setFormState] = useState(props.formStateObj?.BILLS);
    const [isHOAValid, setHOA] = useState(props.mode !== 'create');
    const [errors, setErrors] = useState({});
    const [subBillsList, setSubBillsList] = useState(getSubBills(formState?.billType?.sub_bill_details));

    const fields = [{ key: 'grossBillAmt', validators: 'numbersOnly', maxLength: 15 }];

    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);
        if (err[key] || (key === 'grossBillAmt' && parseInt(ev) <= 0) || (typeof ev === 'string' && ev.trim() === '')) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        props.updateErrorStatus(Object.keys(errors).length > 0);
        setErrors(errors);
    };

    function getSubBills(list) {
        if (list?.length > 0) return list.filter((a) => a.is_active);
        else return [];
    }

    const handleChange = (ev, key) => {
        if (key === 'billType') {
            formState.billSubType = '';
            formState.hoa = '';
            formState.grossBillAmt = ev?.config_details[0]?.amount;
            setSubBillsList(getSubBills(ev?.sub_bill_details));
            props.getHoaCodes({
                variables: {
                    where: { bill_conf_id: { equals: ev.id } },
                    orderBy: { name: 'desc' }
                }
            });
        }
        validateFeild(ev, key);
        setFormState({
            ...formState,
            [key]: ev
        });
    };

    const handleHOAPopup = (e, hoa) => {
        setFormState({
            ...formState,
            hoa: hoa
        });
        setState({
            ...state,
            showHOA: e
        });
    };

    const updateSelectedHoa = (filteredArray, selectedHOA) => {
        setHOA(true);
        setState({
            ...state,
            showHOA: false,
            filteredArray: filteredArray
        });
        setFormState({
            ...formState,
            hoa: selectedHOA
        });
    };

    useEffect(() => {
        props.saveFormData('BILLS', formState);
        // eslint-disable-next-line
    }, [formState]);

    const getEmpName = () => {
        if (formState.employee?.first_name && formState.employee?.last_name) {
            return formState.employee?.first_name + ' ' + formState.employee?.last_name;
        } else if (formState.employee?.first_name && !formState.employee?.last_name) {
            return formState.employee?.first_name;
        } else if (!formState.employee?.first_name && formState.employee?.last_name) {
            return formState.employee?.last_name;
        }
        return '';
    };

    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">Financial Year{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.mode !== 'view' && (
                                <Dropdown
                                    name="finYear"
                                    value={formState.finYear}
                                    onChange={(e) => handleChange(e.value, 'finYear')}
                                    appendTo={document.self}
                                    options={props.finYearsList}
                                    placeholder="Select Financial Year"
                                    className="w-100"
                                    optionLabel="name"
                                    optionValue="id"
                                />
                            )}
                            {props.mode === 'view' && formState.finYear}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">
                                Financial Period{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.mode !== 'view' && (
                                <Dropdown
                                    name="finPeriod"
                                    value={formState.finPeriod}
                                    onChange={(e) => handleChange(e.value, 'finPeriod')}
                                    appendTo={document.self}
                                    options={props.finPeriodsList}
                                    placeholder="Select Financial Period"
                                    className="w-100"
                                />
                            )}
                            {props.mode === 'view' && formState.finPeriod}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">
                                Personnel Bill Type{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.mode !== 'view' && (
                                <Dropdown
                                    name="billType"
                                    value={formState.billType}
                                    onChange={(e) => handleChange(e.value, 'billType')}
                                    appendTo={document.self}
                                    options={props.billTypeList}
                                    placeholder="Select Personnel Bill Type"
                                    className="w-100"
                                    optionLabel="code"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    filterBy="code"
                                />
                            )}
                            {props.mode === 'view' && formState.billType?.code}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">
                                Personnel Bill SubType
                                {props.mode !== 'view' && formState.billType?.code && subBillsList?.length > 0 ? (
                                    <span className="icon-primary"> *</span>
                                ) : (
                                    ' :'
                                )}
                            </b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.mode !== 'view' && (
                                <Dropdown
                                    name="billSubType"
                                    value={formState.billSubType}
                                    onChange={(e) => handleChange(e.value, 'billSubType')}
                                    appendTo={document.self}
                                    options={subBillsList}
                                    placeholder="Select Personnel Bill SubType"
                                    className="w-100"
                                    optionLabel="code"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    filterBy="code"
                                    disabled={subBillsList?.length <= 0}
                                />
                            )}
                            {props.mode === 'view' && (formState?.billSubType?.code ? formState?.billSubType?.code : '–')}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">Employee ID{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.mode !== 'view' && (
                                <Dropdown
                                    name="employee"
                                    value={formState.employee}
                                    onChange={(e) => handleChange(e.value, 'employee')}
                                    appendTo={document.self}
                                    options={props.employeeList}
                                    placeholder="Select Employee ID"
                                    className="w-100"
                                    optionLabel="ref"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    filterBy="ref,first_name,last_name"
                                />
                            )}
                            {props.mode === 'view' && (formState?.employee?.ref ? formState?.employee?.ref : '–')}
                        </div>
                    </div>
                </div>
                {formState.employee && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Employee Name
                                    {props.mode !== 'view' && props.mode !== 'create' ? <span className="icon-primary"> *</span> : ' :'}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 fs-14 m-t-5 m-b-5">{getEmpName()}</div>
                        </div>
                    </div>
                )}
                {formState.employee && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">Budget Office :</b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 fs-14 m-t-5 m-b-5">
                                {formState.employee?.budget_office + ' - ' + formState.employee?.budget_office_name}
                            </div>
                        </div>
                    </div>
                )}
                {formState.employee && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">Deputation Office :</b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 fs-14 m-t-5 m-b-5">
                                {formState.employee?.deputation_office + ' - ' + formState.employee?.deputation_office_name}
                            </div>
                        </div>
                    </div>
                )}
                {formState.employee &&
                formState.billType?.config_details?.length &&
                formState.billType?.config_details[0]?.include_gpf_pran_epf ? (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">GPF/PRAN/EPF :</b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 fs-14 m-t-5 m-b-5">
                                {formState.employee?.special_info?.gpf_type + ' - ' + formState.employee?.special_info?.gpf_num}
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {formState.employee &&
                formState.billType?.config_details?.length &&
                formState.billType?.config_details[0]?.include_superannuation_date ? (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">Superannuation Date :</b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 fs-14 m-t-5 m-b-5">
                                {formState.employee?.special_info?.super_annuation_date
                                    ? getDate(formState.employee?.special_info?.super_annuation_date, true)
                                    : ''}
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">HOA{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className={`col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 ${!formState.billType ? 'disabled' : ''}`}>
                            {props.mode !== 'view' && (
                                <HOASelection
                                    handleHOAPopup={handleHOAPopup}
                                    hoa={formState.hoa}
                                    placeHolder="Select HOA"
                                    showHoaModal={state.showHOA}
                                    updateHOAInParent={updateSelectedHoa}
                                    selectedHOA={state.selectedHOA}
                                    hoaList={props.hoaCodes}
                                    hoaDetails={{
                                        fetchCompositionQry: Schema.getMeMappingId,
                                        variables: undefined,
                                        responseObj: 'GetMeFunctionWiseSegments'
                                    }}
                                    function="Personnel Bills"
                                    handleHOACancel={() => setState({ ...state, showHOA: false })}
                                    validateHOA={(e) => setHOA(e)}
                                    isHOAValid={isHOAValid}
                                />
                            )}
                            {props.mode === 'view' && (formState?.hoa ? formState.hoa : '–')}
                        </div>
                    </div>
                </div>
            </div>
            {formState.billType && (
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Gross Bill Amount
                                    {props.mode !== 'view' &&
                                    formState.billType?.config_details?.length &&
                                    formState.billType?.config_details[0]?.bill_value_type === 'MANUAL' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 fs-14">
                                {props.mode !== 'view' &&
                                    formState.billType?.config_details?.length &&
                                    formState.billType?.config_details[0]?.bill_value_type === 'MANUAL' && (
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="grossBillAmt"
                                            type="text"
                                            value={formState.grossBillAmt}
                                            maxLength={15}
                                            onChange={(e) => handleChange(e, 'grossBillAmt')}
                                            placeholder="Enter Gross Bill Amount"
                                            hasError={errors && errors.grossBillAmt}
                                            errMsg="Please enter valid Gross Bill Amount"
                                        />
                                    )}
                                {(props.mode === 'view' ||
                                    (formState.billType?.config_details?.length &&
                                        formState.billType?.config_details[0]?.bill_value_type !== 'MANUAL')) &&
                                    formState.grossBillAmt}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">
                                Bill Description{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                            {props.mode !== 'view' && (
                                <>
                                    <TextAreaComponent
                                        textBoxSize="xl"
                                        rows="4"
                                        name="billDescription"
                                        placeholder="Enter Bill Description"
                                        maxLength={300}
                                        value={formState.billDescription}
                                        onChange={(e) => handleChange(e, 'billDescription')}
                                        alphanumeric='true'
                                    />
                                    {errors && errors.billDescription && (
                                        <p className="a-form-error">Please enter valid Bill Description</p>
                                    )}
                                </>
                            )}
                            {props.mode === 'view' && (formState?.billDescription ? formState.billDescription : '–')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bills;
