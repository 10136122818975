import React, { useState, useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../../../../../components/InputComponent/InputComponent';
import * as FormValidation from '../../../../../Validation';
import CalendarComponent from '../../../../../components/Calendar/CalendarComponent';
import { getDateOnly, serverDate } from '../../../../../util';

const EmployeeClassification = (props) => {
    const [errors] = useState({});
    const fields = [{ key: 'daily_rate', validators: 'numbersOnly', maxLength: 6 }];
    const [formState, setFormState] = useState(props.formStateObj?.EMPLOYEE_CLASSIFICATION);

    useEffect(() => {
        setFormState(props.formStateObj?.EMPLOYEE_CLASSIFICATION);
        // eslint-disable-next-line
    }, [props.formStateObj?.EMPLOYEE_CLASSIFICATION]);

    function getMDAs() {
        let list = [];
        if (props.sysAdminData?.departmentWithDeputationOffices?.length > 0) {
            props.sysAdminData.departmentWithDeputationOffices.forEach((re) => {
                list.push({
                    id: re?.department?.id,
                    name: re?.department?.segment_name,
                    fullCode: re?.department?.segment_full_code,
                    displayLabel: re?.department?.segment_full_code + ' - ' + re?.department?.segment_name,
                    deputaionOfcsList: re?.deputation_office
                });
            });
        }
        return list;
    }

    function getDeputationOffices() {
        let list = [];
        if (formState.MDA?.deputaionOfcsList?.length > 0) {
            formState.MDA.deputaionOfcsList.forEach((re) => {
                list.push({
                    id: re.id,
                    name: re.segment_name,
                    fullCode: re.segment_full_code,
                    displayLabel: re.segment_full_code + ' - ' + re.segment_name
                });
            });
        }
        return list;
    }

    const [state] = useState({
        loading: false
    });

    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);
        if (err[key] || (key === 'daily_rate' && parseInt(ev) <= 0)) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        props.updateErrorStatus(Object.keys(errors).length > 0);
    };
    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setFormState({
            ...formState,
            [key]: ev
        });
    };

    useEffect(() => {
        props.saveFormData('EMPLOYEE_CLASSIFICATION', formState);
        // eslint-disable-next-line
    }, [formState]);

    const handleDynChange = (ev, field) => {
        let inx = formState.dynamicFields.findIndex((a) => a === field);
        formState.dynamicFields.forEach((a, index) => {
            if (a === field) {
                a.value = ev;
            }
            if (index > inx) {
                a.fieldOptions = [];
                a.value = {};
                a.isDisabled = true;
            }
        });

        if (ev.child_codes?.length > 0) {
            let filteredOptions = ev.child_codes;
            formState.dynamicFields[inx + 1]['fieldOptions'] = filteredOptions;
            formState.dynamicFields[inx + 1]['isDisabled'] = false;
            formState.dynamicFields[inx + 1]['value'] = {};
        }
        if (field.key === 'Grade') {
            setFormState({
                ...formState,
                dynamicFields: formState.dynamicFields,
                showEffectiveDate: field.key === 'Grade',
                effective_from: ''
            });
        } else {
            setFormState({ ...formState, dynamicFields: formState.dynamicFields });
        }
    };

    return (
        <div className={`coa-card p-10 overflow-initial ${state.loading ? 'mask' : ''}`}>
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div className="row">
                {formState.dynamicFields.map((field, idx) => {
                    return (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10" key={idx}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                    <b>
                                        {field.name}
                                        {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    {props.formType !== 'view' && (
                                        <Dropdown
                                            className="w-100"
                                            name={field['name']}
                                            disabled={field.isDisabled}
                                            value={field['value']}
                                            onChange={(e) => handleDynChange(e.value, field)}
                                            appendTo={document.self}
                                            options={field.fieldOptions}
                                            placeholder={`Select ${field.name}`}
                                            optionLabel="name"
                                            filter={true}
                                            filterPlaceholder="Search"
                                            filterBy="name"
                                        />
                                    )}
                                    {props.formType === 'view' && (
                                        <span className="p-b-10">{field.value?.name ? field.value?.name : '–'}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>MDA{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <Dropdown
                                    className="w-100"
                                    name="MDA"
                                    value={formState.MDA}
                                    onChange={(e) => handleChange(e.value, 'MDA')}
                                    appendTo={document.self}
                                    options={getMDAs()}
                                    placeholder="Select MDA"
                                    optionLabel="displayLabel"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    filterBy="displayLabel"
                                />
                            )}
                            {props.formType === 'view' && (formState?.MDA?.displayLabel ? formState?.MDA?.displayLabel : '–')}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Deputation Office{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <Dropdown
                                    className="w-100"
                                    name="deputation_office"
                                    value={formState.deputation_office}
                                    onChange={(e) => handleChange(e.value, 'deputation_office')}
                                    appendTo={document.self}
                                    options={getDeputationOffices()}
                                    placeholder="Select Deputation Office"
                                    optionLabel="displayLabel"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    disabled={!formState.MDA}
                                    filterBy="displayLabel"
                                />
                            )}
                            {props.formType === 'view' &&
                                (formState?.deputation_office?.displayLabel ? formState?.deputation_office?.displayLabel : '–')}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Office Location{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <Dropdown
                                    className="w-100"
                                    name="office_location"
                                    value={formState.office_location}
                                    onChange={(e) => handleChange(e.value, 'office_location')}
                                    appendTo={document.self}
                                    options={props.sysAdminData?.countries || []}
                                    optionLabel="name"
                                    placeholder="Select Office Location"
                                />
                            )}
                            {props.formType === 'view' && (formState?.office_location?.name ? formState?.office_location?.name : '–')}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>
                                Daily Rate
                                {props.formType !== 'view' && !props.checkIsApproved ? <span className="icon-primary"> *</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && !props.checkIsApproved && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="daily_rate"
                                    type="text"
                                    value={formState.daily_rate}
                                    maxLength={window.INTERNAL_AUDIT ? 15 : 6}
                                    onChange={(e) => handleChange(e, 'daily_rate')}
                                    placeholder="Enter Daily Rate"
                                    hasError={errors && errors.daily_rate}
                                    errMsg="Please enter valid Daily Rate"
                                />
                            )}
                            {(props.formType === 'view' || props.checkIsApproved) && (formState?.daily_rate ? formState?.daily_rate : '–')}
                        </div>
                    </div>
                </div>
                {formState.showEffectiveDate && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b>
                                    Effective Date
                                    {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.formType !== 'view' && (
                                    <CalendarComponent
                                        className="w-100"
                                        minDate={new Date(serverDate())}
                                        placeholder="Select Effective Date"
                                        appendTo={document.self}
                                        value={formState.effective_from ? new Date(formState.effective_from) : ''}
                                        onChange={(e) => handleChange(e.target.value, 'effective_from')}
                                        dateFormat="dd M yy"
                                        showIcon
                                    />
                                )}
                                {props.formType === 'view' && (formState?.effective_from ? getDateOnly(formState?.effective_from) : '–')}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmployeeClassification;
