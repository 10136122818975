import React from 'react';
import { Input, Button } from 'appkit-react';

function Login(props) {
    const handleChange = (value, key) => {
        props.handleChange(value, key);
    };

    const onClickLogin = (e) => {
        if (e.keyCode === 13) {
            props.onClickLogin();
        }
    };

    return (
        <div className="m-l-30 m-r-30">
            <form onKeyDown={(e) => onClickLogin(e)}>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-60">
                    <Input
                        inputBoxSize="lg"
                        key="username"
                        placeholder="User ID/Email ID"
                        type="text"
                        autoComplete="off"
                        value={props.properties?.username}
                        onChange={(e) => handleChange(e, 'username')}
                    />
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 p-t-20">
                    <Input
                        inputBoxSize="lg"
                        key="username"
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        value={props.properties?.password}
                        onChange={(e) => handleChange(e, 'password')}
                    ></Input>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 p-t-20 p-b-20 primary-color">
                    {props.properties.tax_payer_login && (
                        <span className="float-left pointer reg-new-user-label" onClick={props.taxpayerRegister}>
                            Register New User?
                        </span>
                    )}
                    <span className="float-right pointer forgot-pw-label" onClick={props.onClickForgotPw}>
                        Forgot Password?
                    </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12">
                    <Button
                        size="lg"
                        className={
                            props.properties?.username !== '' && props.properties?.password !== ''
                                ? 'w-100 m-t-20'
                                : 'disabled w-100 m-t-20'
                        }
                        onClick={props.onClickLogin}
                    >
                        Login
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default Login;
