import React, { lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import IsAuthorized from '../commons/IsAuthorized';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import Auth from '../pages/auth/Auth';

const RiskMaturityAssessment = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/riskMaturityAssessment/RiskMaturityAssessment')
);
const AuditPlanSchedule = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/auditPlanSchedule/AuditPlanSchedule')
);
const AuditPlanStatus = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/auditPlanStatus/AuditPlanStatus')
);
const RiskAssessment = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/riskAssessment/RiskAssessment')
);
const CreateRiskAssessment = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/riskAssessment/AddRiskAssessment')
);
const StartRiskAssessment = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/riskAssessment/StartAssessment')
);
const RankedAuditUniverse = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/rankedAuditUniverse/RankedAuditUniverse')
);

const AddRiskMaturityAssessment = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/riskMaturityAssessment/AddRiskMaturityAssessment')
);
const CreateAuditSchedule = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/auditPlanSchedule/CreateAuditSchedule')
);
const AddSAP = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/strategicAuditPlan/AddStrategicAuditPlan/AddSAP')
);

const StrategicPlan = lazy(() =>
    import('../pages/internalAudit/strategicAndAnnualAuditPlan/strategicAuditPlan/strategicAuditPlan/StrategicAuditPlan')
);

const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const strategicAuditPlanItems = MenuItemsSchema.STRATEGIC_AUDIT_PLAN;
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const strategicAuditPlanBreadCrmb = (props) => {
    let strategicAuditPlanValues = [{ name: 'Strategic Audit Plan', redirectTo: '/saap' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            {
                name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1),
                redirectTo: '/strategicauditplan/' + subPath[2],
                key: subPath[2]
            },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    strategicAuditPlanValues = [...strategicAuditPlanValues, ...obj];
    return strategicAuditPlanValues;
};
const StrategicAuditPlanRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={strategicAuditPlanBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={RiskMaturityAssessment}
                    exact
                    path={`${props?.path}/riskmaturityassessment`}
                    name="Risk Maturity Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskMaturityAssessment}
                    exact
                    path={`${props?.path}/riskmaturityassessment/create`}
                    name="Risk Maturity Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskMaturityAssessment}
                    exact
                    path={`${props?.path}/riskmaturityassessment/edit/:id`}
                    name="Risk Maturity Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskMaturityAssessment}
                    exact
                    path={`${props?.path}/riskmaturityassessment/view/:id`}
                    name="Risk Maturity Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskMaturityAssessment}
                    exact
                    path={`${props?.path}/riskmaturityassessment/view/:id/draft`}
                    name="Risk Maturity Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskMaturityAssessment}
                    exact
                    path={`${props?.path}/riskmaturityassessment/edit/:id/draft`}
                    name="Risk Maturity Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditPlanSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule/create`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule/view/:id`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule/view/:id/existapproved`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule/view/:id/approved`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule/edit/:id/approved`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule/edit/:id`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule/view/:id/draft`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule/edit/:id/draft`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAuditSchedule}
                    exact
                    path={`${props?.path}/audiplanschedule/view/:id/existdraft`}
                    name="Audit Plan Schedule"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditPlanStatus}
                    exact
                    path={`${props?.path}/auditplanstatus`}
                    name="Audit Plan Status"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={RiskAssessment}
                    exact
                    path={`${props?.path}/riskassessment`}
                    name="Risk Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateRiskAssessment}
                    exact
                    path={`${props?.path}/riskassessment/create`}
                    name="Risk Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={StartRiskAssessment}
                    exact
                    path={`${props?.path}/riskassessment/:id`}
                    name="Start Risk Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={StartRiskAssessment}
                    exact
                    path={`${props?.path}/riskassessment/edit/:id/draft`}
                    name="Risk Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={StartRiskAssessment}
                    exact
                    path={`${props?.path}/riskassessment/view/:id/draft`}
                    name="Risk Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={StartRiskAssessment}
                    exact
                    path={`${props?.path}/riskassessment/view/:id`}
                    name="Risk Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={StartRiskAssessment}
                    exact
                    path={`${props?.path}/riskassessment/edit/:id`}
                    name="Risk Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={StartRiskAssessment}
                    exact
                    path={`${props?.path}/riskassessment/edit/:id/approved`}
                    name="Risk Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={StartRiskAssessment}
                    exact
                    path={`${props?.path}/riskassessment/view/:id/approved`}
                    name="Risk Assessment"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={RankedAuditUniverse}
                    exact
                    path={`${props?.path}/rankedaudituniverse`}
                    name="Ranked Audit Universe"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={StrategicPlan}
                    exact
                    path={`${props?.path}/strategicadtplan`}
                    name="Strategic Audit Plan"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSAP}
                    exact
                    path={`${props?.path}/strategicadtplan/create`}
                    name="Strategic Audit Plan"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSAP}
                    exact
                    path={`${props?.path}/strategicadtplan/view/:id`}
                    name="Strategic Audit Plan"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSAP}
                    exact
                    path={`${props?.path}/strategicadtplan/edit/:id`}
                    name="Strategic Audit Plan"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSAP}
                    exact
                    path={`${props?.path}/strategicadtplan/view/:id/draft`}
                    name="Strategic Audit Plan"
                    menuItems={strategicAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddSAP}
                    exact
                    path={`${props?.path}/strategicadtplan/edit/:id/draft`}
                    name="Strategic Audit Plan"
                    menuItems={strategicAuditPlanItems}
                />
            </Switch>
        </div>
    );
};
export default StrategicAuditPlanRouting;
