import { Tree } from 'primereact/tree';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import './OrganizationLevel.scss';
import { useQuery } from '@apollo/client';
import Schema from '../../../schema/URSchema';
import URRightContainer from '../components/UserRegistrationComponents/URRightContainer/URRightContainer';
import { ProgressSpinner } from 'primereact/progressspinner';

const Departments = () => {
	const [state, setState] = useState({
		node: null,
		selectedCOASegment: { name: null },
		previousCOASegment: { name: null },
		COASegments: [],
		OrganizationLevels: [],
		showSaveComfirmation: false,
		loading: true
	});
	const [expandedKeys, setExpandedKeys] = useState({});

	const onToggleNodes = (e) => {
		let value = e.value;
		if (Object.keys(value).length !== 0) setExpandedKeys(value);
		else setExpandedKeys({});
	};
	const handleExpandNode = (e) => {
		if (e.node.grouping_type === 'SEGMENT') {
			let obj = preparekeysObj([e.node], {});

			setExpandedKeys(...expandedKeys, obj);
		}
	};
	const preparekeysObj = (list, obj) => {
		list &&
			list.length > 0 &&
			list.forEach((a) => {
				obj[a.key] = true;
				if (a.children && a.children.length > 0) {
					return preparekeysObj(a.children, obj);
				}
			});
		return obj;
	};

	const nodeTemplate = (node) => {
		return (
			<span className="tree_nodes  p-link ">
				<span className={'parent-span font-14'}>{node.seg_def.name + ' - ' + node.label}</span>
			</span>
		);
	};

	useEffect(() => {
		const expandTree = (index) => {
			let _expandedKeys = { ...expandedKeys };
			if (_expandedKeys[index]) delete _expandedKeys[index];
			else _expandedKeys[index] = true;
			setExpandedKeys({ ..._expandedKeys });
		};

		getTree().forEach((item, i) => {
			item.addEventListener(
				'click',
				() => {
					expandTree(i);
				},
				false
			);
		});
	}, [expandedKeys]);

	useLayoutEffect(() => {
		const expandTree = (index) => {
			let _expandedKeys = { ...expandedKeys };
			if (_expandedKeys[index]) delete _expandedKeys[index];
			else _expandedKeys[index] = true;
			setExpandedKeys({ ..._expandedKeys });
		};
		getTree().forEach((item, i) => {
			item.addEventListener(
				'click',
				() => {
					expandTree(i);
				},
				false
			);
		});
	}, [setExpandedKeys]);

	const getTree = () => {
		return document.querySelectorAll('.p-treenode-label');
	};

	const MutateOLData = (node, keys, code) => {
		if (!code) {
			code = '';
		}

		for (let i = 0; i < node?.length; i++) {
			let nodeKey = '';
			if (!keys && keys !== 0) nodeKey = i;
			else nodeKey = keys + '-' + i;
			let nodeCode = code + node[i].code;
			node[i]['key'] = nodeKey;
			node[i]['label'] = nodeCode + '-' + node[i].name;
			if (node[i].children && node[i].children.length !== 0) {
				node[i].children = MutateOLData(node[i].children, nodeKey, nodeCode);
			}
		}
		return node;
	};

	useQuery(Schema.coaSegmentConfs, {
		fetchPolicy: 'no-cache',
		onCompleted: (res) => {
			if (res && res.coaSegmentConfs) {
				let oragnizationLevel = MutateOLData(res.coaSegmentConfs[0].dept_codes);
				setState({ ...state, OrganizationLevels: oragnizationLevel, loading: false });
			}
		},
		onError: (error) => {
			setState({
				...state,
				loading: false
			});
			return error;
		}
	});
	return (
		<div className={`${'coa-ht'} ${state.loading ? 'mask' : ''}`}>
			{state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
			<URRightContainer title={'Organization Levels'} hasBack={false}>
				<div className="organizationLevelTree">
					<Tree
						value={state.OrganizationLevels}
						handleExpand={handleExpandNode}
						nodeTemplate={nodeTemplate}
						className="org-tree-struct w-100"
						expandedKeys={expandedKeys}
						onToggle={onToggleNodes}
					/>
				</div>
			</URRightContainer>
		</div>
	);
};
export default Departments;
