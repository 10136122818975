import React, { useState, useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import Calendar from '../../../../../components/Calendar/CalendarComponent';
import InputComponent from '../../../../../components/InputComponent/InputComponent';
import { getDate, serverDate } from '../../../../../util';
import * as FormValidation from '../../../../../Validation';

const EmployeeInformation = (props) => {
    const INTERNAL_EMAIL_DOMAINS =
        props?.INTERNAL_EMAIL_DOMAINS?.length > 0 ? props.INTERNAL_EMAIL_DOMAINS[0].value1?.replace(/,/g, '|') : '';

    const [state] = useState({
        employeeTypeList: [],
        serviceTypeList: [],
        cadreList: [],
        designationList: [],
        budgetOfcList: [],
        deputationOfcList: [],
        statusList: [],
        genderList: ['Male', 'Female', 'Transgender'],
        maritalStatusList: ['Single', 'Widowed', 'Married', 'Seperated', 'Divorced'],
        reservationList: ['General', 'OBC', 'ST', 'SC', 'MBC', 'Other'],
        loading: false
    });
    const [errors] = useState({});
    const [selectedCountry, setCountry] = useState('');

    let nationsList =
        props.nationList && props.nationList.countries && props.nationList.countries.length > 0 ? props.nationList.countries : [];
    nationsList = nationsList.sort((a, b) => (a.name > b.name ? 1 : -1));
    const [formState, setFormState] = useState(props.formStateObj?.EMPLOYEE_INFORMATION);

    useEffect(() => {
        if (props.formType !== 'create') {
            let editObj = nationsList.filter((val) => val.name === props.formStateObj?.EMPLOYEE_INFORMATION?.nationality);
            setCountry(editObj[0]);
        }
        setFormState(props.formStateObj?.EMPLOYEE_INFORMATION);
        // eslint-disable-next-line
    }, [props.formStateObj?.EMPLOYEE_INFORMATION]);
    const fields = [
        { key: 'emp_first_name', validators: 'isAlpha', maxLength: 30 },
        { key: 'emp_last_name', validators: 'isAlpha', maxLength: 30 },
        { key: 'emp_fathers_name', validators: 'isAlpha', maxLength: 30 },
        { key: 'emp_mother_name', validators: 'isAlpha', maxLength: 30 },
        { key: 'TPIN', validators: 'alphaNumeric', maxLength: 10 },
        { key: 'national_id_Card', validators: props.empDetails?.is_latest ? 'numbersOnly' : 'alphaNumeric', maxLength: 12 },
        { key: 'mobile_no', validators: 'phone', pattern: '^([a-zA-Z0-9 .]+){3,50}$' },
        {
            key: 'email_id',
            validators: 'email',
            pattern:
                INTERNAL_EMAIL_DOMAINS !== ''
                    ? '^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(' + INTERNAL_EMAIL_DOMAINS + ')$'
                    : undefined
        },
        { key: 'office_phone_no', validators: 'phone', pattern: '^([a-zA-Z0-9 .]+){3,50}$' },
        { key: 'disability', validators: 'isAlpha', maxLength: 50 },
        { key: 'percentOfDisability', validators: 'numbersOnly', maxLength: 2 }
    ];

    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);
        console.log(err, 'err');
        if (
            err[key] ||
            ((key === 'national_id_Card' || key === 'mobile_no' || key === 'office_phone_no' || key === 'percentOfDisability') &&
                parseInt(ev) <= 0)
        ) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        props.updateErrorStatus(Object.keys(errors).length > 0);
    };

    const handleChange = async (ev, key) => {
        if (typeof ev.trim === 'function' && ev.trim(' ') === '') {
            ev = ev.trim(' ');
        }
        validateFeild(ev, key);
        if (key === 'nationality') {
            setCountry(ev);
            await setFormState({
                ...formState,
                nationality: ev.name
            });
        } else {
            await setFormState({
                ...formState,
                [key]: ev
            });
        }
    };

    let currYear = serverDate().getFullYear();
    let dobRange = 1900 + ':' + parseInt(currYear - 18).toString();

    useEffect(() => {
        props.saveFormData('EMPLOYEE_INFORMATION', formState);
        // eslint-disable-next-line
    }, [formState]);

    const handleRadioChanage = (e, key) => {
        if (key === 'isDisabled') {
            formState.percentOfDisability = '';
            formState.disability = '';
        }
        setFormState({ ...formState, [key]: e.value });
    };

    return (
        <div className={`coa-card overflow-initial employeeDetails p-10 ${state.loading ? 'mask' : ''}`}>
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Employee First Name{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="emp_first_name"
                                    type="text"
                                    value={formState.emp_first_name}
                                    maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                    onChange={(e) => handleChange(e, 'emp_first_name')}
                                    placeholder="Enter Employee First Name"
                                    hasError={errors && errors.emp_first_name}
                                    errMsg="Please enter valid Employee First Name  E.g.: James"
                                />
                            )}
                            {props.formType === 'view' && (formState?.emp_first_name ? formState?.emp_first_name : '–')}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Employee Last Name{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="emp_last_name"
                                    type="text"
                                    value={formState.emp_last_name}
                                    maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                    onChange={(e) => handleChange(e, 'emp_last_name')}
                                    placeholder="Enter Employee Last Name"
                                    hasError={errors && errors.emp_last_name}
                                    errMsg="Please enter valid Employee Last Name  E.g.: Sophe"
                                />
                            )}
                            {props.formType === 'view' && (formState?.emp_last_name ? formState?.emp_last_name : '–')}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Date of Birth {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <Calendar
                                    className="w-100"
                                    readOnlyInput={true}
                                    placeholder="Select Date of Birth"
                                    appendTo={document.self}
                                    value={formState.dob}
                                    onChange={(e) => handleChange(e.target.value, 'dob')}
                                    dateFormat="dd M yy"
                                    showIcon
                                    yearRange={dobRange}
                                    yearNavigator={true}
                                    monthNavigator={true}
                                />
                            )}
                            {props.formType === 'view' && getDate(formState.dob, true)}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Gender{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        {props.formType !== 'view' && (
                            <div className="col-sm-5 col-xs-12 col-md-6 col-lg-7 col-xl-7">
                                <Dropdown
                                    className="w-100"
                                    name="gender"
                                    value={formState.gender}
                                    onChange={(e) => handleChange(e.value, 'gender')}
                                    appendTo={document.self}
                                    options={state.genderList}
                                    placeholder="Select Gender"
                                    resetFilterOnHide={true}
                                />
                            </div>
                        )}
                        {props.formType === 'view' && (
                            <div className="col-sm-5 col-xs-12 col-md-4 col-lg-3 col-xl-3">
                                {formState?.gender ? formState?.gender : '–'}
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Father’s Name {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="emp_fathers_name"
                                    type="text"
                                    value={formState.emp_fathers_name}
                                    maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                    onChange={(e) => handleChange(e, 'emp_fathers_name')}
                                    placeholder="Enter Father’s Name"
                                    hasError={errors && errors.emp_fathers_name}
                                    errMsg="Please enter valid Father’s Name  E.g.: Smith"
                                />
                            )}
                            {props.formType === 'view' && (formState?.emp_fathers_name ? formState?.emp_fathers_name : '–')}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Mother’s Name {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="emp_mother_name"
                                    type="text"
                                    value={formState.emp_mother_name}
                                    maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                    onChange={(e) => handleChange(e, 'emp_mother_name')}
                                    placeholder="Enter Mother’s Name"
                                    hasError={errors && errors.emp_mother_name}
                                    errMsg="Please enter valid Mother’s Name  E.g.: Lila"
                                />
                            )}
                            {props.formType === 'view' && (formState?.emp_mother_name ? formState?.emp_mother_name : '–')}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Nationality {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <Dropdown
                                    className="w-100"
                                    name="nationality"
                                    value={selectedCountry}
                                    onChange={(e) => handleChange(e.value, 'nationality')}
                                    appendTo={document.self}
                                    options={nationsList}
                                    placeholder="Select Nationality"
                                    optionLabel="name"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    filterBy="name"
                                />
                            )}
                            {props.formType === 'view' && formState?.nationality}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>TPIN{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="TPIN"
                                    type="text"
                                    value={formState.TPIN}
                                    maxLength={10}
                                    onChange={(e) => handleChange(e, 'TPIN')}
                                    placeholder="Enter TPIN"
                                    hasError={errors && errors.TPIN}
                                    errMsg="Please enter valid TPIN E.g.: Ab123 A123"
                                />
                            )}
                            {props.formType === 'view' && (formState?.TPIN ? formState?.TPIN : '–')}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>
                                {props.empDetails?.is_latest ? 'National ID Card Reference Number' : 'National ID Card'}
                                {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="national_id_Card"
                                    type="text"
                                    value={formState.national_id_Card}
                                    maxLength={12}
                                    onChange={(e) => handleChange(e, 'national_id_Card')}
                                    placeholder="Enter National ID Card"
                                    hasError={errors && errors.national_id_Card}
                                    errMsg={
                                        props.empDetails?.is_latest
                                            ? 'Please enter valid National ID Card  E.g.: 567843567894'
                                            : 'Please enter valid National ID Card E.g.: Abc84de67894'
                                    }
                                />
                            )}
                            {props.formType === 'view' && (formState?.national_id_Card ? formState?.national_id_Card : '–')}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Mobile Number{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        {props.formType !== 'view' && (
                            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-r-0">
                                <div className={'mobile-div'}>
                                    <span className="opacity-5 ellipses h1-name">{selectedCountry ? selectedCountry.isd_code : ''} </span>
                                </div>
                            </div>
                        )}
                        <div
                            className={`${
                                props.formType !== 'view'
                                    ? 'col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-l-0'
                                    : 'col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7'
                            }`}
                        >
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="mobile_no"
                                    type="text"
                                    value={formState.mobile_no}
                                    maxLength={10}
                                    onChange={(e) => handleChange(e, 'mobile_no')}
                                    placeholder="Enter Mobile Number"
                                    hasError={errors && errors.mobile_no}
                                    errMsg="Please enter valid Mobile Number E.g.: 8989787878"
                                />
                            )}

                            {props.formType === 'view' && (
                                <span>
                                    {formState.mobile_no ? (
                                        <span>
                                            {selectedCountry ? selectedCountry.isd_code : ''} {formState?.mobile_no}
                                        </span>
                                    ) : (
                                        '–'
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Email ID{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="email_id"
                                    type="text"
                                    value={formState.email_id}
                                    maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                    onChange={(e) => handleChange(e, 'email_id')}
                                    placeholder="Enter Email ID"
                                    hasError={errors && errors.email_id}
                                    errMsg={
                                        'Please enter valid Email ID ' +
                                        (INTERNAL_EMAIL_DOMAINS && typeof INTERNAL_EMAIL_DOMAINS === 'string'
                                            ? 'E.g.: smith@' + INTERNAL_EMAIL_DOMAINS
                                            : '')
                                    }
                                />
                            )}
                            {props.formType === 'view' && (formState?.email_id ? formState?.email_id : '–')}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>Office Phone Number{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="office_phone_no"
                                    type="text"
                                    value={formState.office_phone_no}
                                    maxLength={10}
                                    onChange={(e) => handleChange(e, 'office_phone_no')}
                                    placeholder="Enter Office Phone Number"
                                    hasError={errors && errors.office_phone_no}
                                    errMsg="Please enter valid Office Phone Number  E.g.: 1234234567"
                                />
                            )}
                            {props.formType === 'view' && (formState?.office_phone_no ? formState?.office_phone_no : '–')}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b>If Disabled?{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        </div>
                        {props.formType !== 'view' && (
                            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-7 col-xl-7">
                                <RadioButton
                                    inputId="rb1"
                                    value={true}
                                    name="yes"
                                    onChange={(e) => handleRadioChanage(e, 'isDisabled')}
                                    checked={formState.isDisabled}
                                />
                                <label htmlFor="rb1" className="p-radiobutton-label p-r-20">
                                    Yes
                                </label>
                                <RadioButton
                                    inputId="rb2"
                                    value={false}
                                    name="no"
                                    onChange={(e) => handleRadioChanage(e, 'isDisabled')}
                                    checked={!formState.isDisabled}
                                />
                                <label htmlFor="rb2" className="p-radiobutton-label">
                                    No
                                </label>
                            </div>
                        )}
                        {props.formType === 'view' && (
                            <div className="col-sm-5 col-xs-12 col-md-4 col-lg-3 col-xl-3">
                                <span>{formState.isDisabled ? 'Yes' : 'No'}</span>
                            </div>
                        )}
                    </div>
                </div>
                {formState.isDisabled && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b>Type of Disability{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.formType !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="disability"
                                        type="text"
                                        value={formState.disability}
                                        maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                        onChange={(e) => handleChange(e, 'disability')}
                                        placeholder="Enter Type of Disability"
                                        hasError={errors && errors.disability}
                                        errMsg="Please enter valid Type of Disability  E.g.: Handicapped"
                                    />
                                )}
                                {props.formType === 'view' && formState?.disability}
                            </div>
                        </div>
                    </div>
                )}
                {formState.isDisabled && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b>% of Disability{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.formType !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="percentOfDisability"
                                        type="text"
                                        value={formState.percentOfDisability}
                                        maxLength={2}
                                        onChange={(e) => handleChange(e, 'percentOfDisability')}
                                        placeholder="Enter % of Disability"
                                        hasError={errors && errors.percentOfDisability}
                                        errMsg="Please enter valid % of Disability  E.g.: 12"
                                    />
                                )}
                                {props.formType === 'view' && formState?.percentOfDisability}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmployeeInformation;
