import { gql } from '@apollo/client';
export const Schema = {
	coaSegmentConfs: gql`
        query {
            coaSegmentConfs {
                dept_level
                dept_codes {
                    seg_def {
                        name
                    }
                    name
                    code
                    children {
                        seg_def {
                            name
                        }
                        name
                        code
                        children {
                            seg_def {
                                name
                            }
                            name
                            code
                        }
                    }
                }
            }
        }
    `,
	updateOneAppSetting: gql`
        mutation updateOneAppSetting($data: AppSettingUpdateInput!) {
            updateOneAppSetting(data: $data, where: { id: "ORG_SEGMENT" }) {
                id
            }
        }
    `,
	employees: gql`
        query employees($where: EmployeeWhereInput) {
            employees(where: $where) {
                id
                first_name
                ref
                last_name
                deputation_office
                deputation_office_name
                budget_office
                budget_office_name
                email
                middle_name
                status
            }
        }
    `,
	employeeUnregistered: gql`
        query employeeUnregistered {
            employees: employeeUnregistered {
                id
                first_name
                ref
                last_name
                deputation_office
                deputation_office_name
                budget_office
                budget_office_name
                email
                middle_name
                status
            }
        }
    `,
	fetchRolesToAssign: gql`
        query {
            roles(where: { is_active: { equals: true }, is_effective: { equals: true } }) {
                id
                ref
                name
                type
                offices {
                    segment_name
                    segment_full_code
                }
                is_active
            }
        }
    `,
	fetchRoles: gql`
        query roles($where: RoleWhereInput) {
            roles(where: $where) {
                id
                ref
                name
                type
                dept_office_list
            }
        }
    `,
	masterRoles: gql`
        query rolesDisplay {
            rolesDisplay {
                status
                headers
                data
            }
        }
    `,
	getOrgWiseSegments: gql`
        query GetOrgWiseSegments {
            GetOrgWiseSegments {
                status
                compositionWiseSegments {
                    level_name
                    segments {
                        name
                        full_code
                    }
                }
            }
        }
    `,
	getOrgWiseSegmentsLevel: gql`
        query GetOrgWiseSegments {
            GetOrgWiseSegments {
                status
                compositionWiseSegments {
                    level_name
                    segments {
                        id
                        name
                        full_code
                        children {
                            name
                            code
                            children {
                                name
                                code
                            }
                        }
                    }
                }
            }
        }
    `,
	getPrivileges: gql`
        query privilegeConfs {
            privilegeConfs {
                id
                function
                sub_module
                sub_function
                module
                applicability
                function_code
                can_manage_hoa
                hoa_activities {
                    name
                    code
                    type
                    id
                }
            }
        }
    `,
	getAccessPrivilegesByRoleId: gql`
        query role($where: RoleWhereUniqueInput!) {
            role(where: $where) {
                name
                privileges {
                    privilege_conf {
                        applicability
                        id
                        function
                        module
                        sub_module
                        sub_function
                        function_code
                        can_manage_hoa
                        hoa_activities {
                            name
                            code
                            type
                            id
                        }
                    }
                    can_create
                    can_view
                    can_edit
                    can_delete
                }
            }
        }
    `,
	orgSegmentLevels: gql`
        query orgSegmentLevels {
            orgSegmentLevels {
                status
                levels {
                    id
                    name
                    seg_def_id
                }
            }
        }
    `,
	admCoaSegments: gql`
        query admCoaSegments($where: AdmCOASegmentWhereInput) {
            admCoaSegments(where: $where) {
                id
                name
                full_code
                seg_def {
                    name
                }
            }
        }
    `,
	createOneRole: gql`
        mutation createOneRole($data: RoleCreateInput!) {
            createOneRole(data: $data) {
                id
                name
            }
        }
    `,
	updateOneRole: gql`
        mutation updateOneRole($data: RoleUpdateInput!, $where: RoleWhereUniqueInput!) {
            updateOneRole(data: $data, where: $where) {
                id
                name
            }
        }
    `,
	createOneUser: gql`
        mutation createOneUser($data: UserCreateInput!) {
            createOneUser(data: $data) {
                employee_id
            }
        }
    `,
	updateOneUser: gql`
        mutation updateOneUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
            updateOneUser(where: $where, data: $data) {
                employee_id
            }
        }
    `,
	getRoleDetails: gql`
        query roles($where: RoleWhereInput, $orderBy: [RoleOrderByInput!], $take: Int, $skip: Int) {
            roles(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                type
                name
                ref
                is_active
                version_user
                version_date
                created_by
                status
                offices {
                    segment_name
                    segment_full_code
                }
                departments {
                    segment_full_code
                    segment_name
                }
            }
        }
    `,
	getRoleDetailsByRoleId: gql`
        query roles($where: RoleWhereInput) {
            roles(where: $where) {
                id
                type
                name
                ref
                is_active
                version_no
                version_user
                is_latest
                status
                offices {
                    segment_name
                    segment_full_code
                }
                departments {
                    segment_full_code
                    segment_name
                }
                privileges {
                    id
                    privilege_conf {
                        applicability
                        function
                        sub_function
                        module
                        sub_module
                        id
                        function_code
                        can_manage_hoa
                        hoa_activities {
                            name
                            code
                            type
                            id
                        }
                    }
                    can_view
                    can_edit
                    can_create
                    can_delete
                }
            }
        }
    `,
	fetchUsers: gql`
        query users($where: UserWhereInput) {
            users(where: $where) {
                id
                user_name
                employee
				user_unblock
                is_active
                status
                version_no
                version_user
            }
        }
    `,
	fetchUserRoleDetails: gql`
        query userRoles($where: UserRoleWhereInput) {
            userRoles(where: $where) {
                dept_office_list
                role {
                    id
                    ref
                    name
                    type
                    is_active
                }
                privileges {
                    id
                    privilege_conf {
                        applicability
                        function
                        sub_function
                        module
                        sub_module
                        id
                        function_code
                        can_manage_hoa
                        hoa_activities {
                            name
                            code
                            type
                            id
                        }
                    }
                    can_view
                    can_edit
                    can_create
                    can_delete
                }
                user_privs {
                    access_privs {
                        id
                        can_view
                        can_edit
                        can_create
                        can_delete
                        privilege_conf {
                            applicability
                            function
                            sub_function
                            module
                            sub_module
                            id
                            function_code
                            can_manage_hoa
                            hoa_activities {
                                name
                                code
                                type
                                id
                            }
                        }
                    }
                    exception_trans {
                        id
                        can_view
                        can_edit
                        can_create
                        can_delete
                        privilege_conf {
                            applicability
                            function
                            sub_function
                            module
                            sub_module
                            id
                        }
                    }
                }
                id
                is_active
                manager_id
                manager {
                    user_name
                    employee
                }
                user_hoa_selections {
                    id
                    function_code
                    activity_code
                    entity_id
                    details {
                        seg_def_name
                        level_name
                        segment_full_code
                        segment_name
                    }
                }
            }
        }
    `,
	coaSegmentConfLevel: gql`
        query coaSegmentConf($where: COASegmentConfWhereUniqueInput!) {
            coaSegmentConf(where: $where) {
                org_segment
                dept_level
                dept_codes {
                    name
                    full_code
                }
            }
        }
    `,
	passwordConfigs: gql`
        query passwordConfigs($where: PasswordConfigWhereInput!) {
            passwordConfigs(where: $where) {
                id
				password_type
				password_min_length
				password_max_length
				is_active
				version_user
				version_date
            }
        }
    `,

	createOnePasswordConfig: gql`
        mutation createOnePasswordConfig($data: PasswordConfigCreateInput!) {
            createOnePasswordConfig(data: $data) {
				id
				password_type
				password_min_length
				password_max_length
            }
        }
    `,
	lockFacilityQuery: gql`
        query appSetting($where: AppSettingWhereUniqueInput!) {
            appSetting(where: $where) {
                id
                value1
                value2
            }
        }
    `,
	lockFacilityQueryList: gql`
	query appSettings($where: AppSettingWhereInput!) {
		appSettings(where: $where) {
			id
			value1
			value2
		}
	}
`,
	updateOneLockFacilityQuery: gql`
        mutation updateOneAppSetting($data: AppSettingUpdateInput!, $where: AppSettingWhereUniqueInput!) {
            updateOneAppSetting(data: $data, where: $where) {
                id
                value1
                value2
                value3
            }
        }
    `,
	passwordExpiry: gql`
        query appSetting($where: AppSettingWhereUniqueInput!) {
            appSetting(where: $where) {
                id
                value1
                value2
            }
        }
    `,
	removeRole: gql`
        mutation removeRole($userRoleId: String!) {
            removeRole(userRoleId: $userRoleId)
        }
    `,
	getDepartmentsAndOffices: gql`
        query deptAndOffices($fin_year: String) {
            deparmentWithOfficeList(authorize: true, privilegeCode: "SYS_ROLE_MASTER_R", fin_year: $fin_year) {
                department {
                    name
                    full_code
                }
                offices {
                    name
                    full_code
                }
            }
        }
    `,
	fetch_departments_offices_by_roleId: gql`
        query roles($id: String!) {
            roles(where: { id: { equals: $id } }) {
                id
                type
                dept_office_list
                is_latest
            }
        }
    `,

	fetchConfiguredData: gql`
        query {
            getClientConfig {
                id
                code
                group
                value1
                value2
                value3
                value4
            }
        }
    `,
	getPendingActions: gql`
        query havePendingActions($role_id: String!, $office_code: String!) {
            havePendingActions(role_id: $role_id, office_code: $office_code)
        }
    `,
	getHOADeselectionCodes: gql`
        query hoaDeselectionCodes($functionCode: String!, $activityCode: String, $entityId: String) {
            hoaDeselectionCodes(functionCode: $functionCode, activityCode: $activityCode, entityId: $entityId)
        }
    `,
	supplyCategoryForDeselectHOA: gql`
        query cntrSupplyCategories($orderBy: [CntrSupplyCategoryOrderByInput!], $where: CntrSupplyCategoryWhereInput) {
            cntrSupplyCategories(orderBy: $orderBy, where: $where) {
                ref
                id
                name
            }
        }
    `,
	advanceMasterForDeselectHOA: gql`
        query cntrAdvances($orderBy: [CntrAdvanceOrderByInput!], $where: CntrAdvanceWhereInput) {
            cntrAdvances(orderBy: $orderBy, where: $where) {
                id
                name
                ref
            }
        }
    `,
	userHoaSelections: gql`
        query userHoaSelections($where: UserHOASelectionWhereInput) {
            userHoaSelections(where: $where) {
                id
                function_code
                activity_code
                entity_id
                details {
                    seg_def_name
                    level_name
                    segment_full_code
                }
            }
        }
    `,
	otherBillConf: gql`
        query otherBillConfs($where: OtherBillConfWhereInput, $orderBy: [OtherBillConfOrderByInput!]) {
            otherBillConfs(where: $where, orderBy: $orderBy) {
                id
                description
                ref
            }
        }
    `,
	usersCount: gql`
        query usersCount($where: UserWhereInput) {
            usersCount(where: $where)
        }
    `,
	rolesCount: gql`
        query rolesCount($where: RoleWhereInput) {
            rolesCount(where: $where)
        }
    `,
	iaMdaDeputationOffice: gql`
        query mdaAndDeputationOffice {
            mdaAndDeputationOffice {
                id
                name
                code
                full_code
                seg_def {
                    name
                }
                children {
                    id
                    name
                    code
                    full_code
                    seg_def {
                        name
                    }
                }
            }
        }
    `
};
export default Schema;
