import React, { useState } from 'react';
import { Toast } from 'primereact/toast';
import { useQuery, useLazyQuery } from '@apollo/client';
import Schema from '../../../schema/AppSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import DocumentCategoryActions from './DocumentCategoryActions';
import { eventClient } from '../../../apollo';
import { Sidebar } from 'primereact/sidebar';
import WorklowSidebarContent from '../../../components/WorklowSidebarContent/WorklowSidebarContent';
import { getFormTypeAndRecordId } from '../../../services/commonService';
import InputComponent from '../../../components/InputComponent/InputComponent';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import * as FormValidation from '../../../Validation';
import { Dropdown } from 'primereact/dropdown';
import { convertTextCase } from '../../../util';
import CommonInlineTable from '../../../components/commonTable/CommonInlineTable';
import TableSchema from '../CoMasTableSchema';

const CreateDocumentCategory = (props) => {
    const [growl, setGrowl] = useState();
    const [state, setState] = useState({
        loading: true,
        showWorkflowStatus: false,
        listToShowComment: [],
        fileSizes: [],
        fileTypes: [],
        selectedRows: []
    });
    const formTypeAndId = getFormTypeAndRecordId(props.location.pathname);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    let isDraft = formTypeAndId['isDraft'];
    let transactionId = localStorage.getItem('transaction_id') || '';
    const [mode, setMode] = useState(formType);
    const [versionNo, setversionNo] = useState();
    const [versionUser, setversionUser] = useState();
    const [entityId, setEntityId] = useState('');
    const [recID, setID] = useState('');
    const [docDetails, setDocDetails] = useState();
    const [mapDocList, setMapDocList] = useState([]);
    const [errors, setErrors] = useState({});
    const [formState, setFormState] = useState({
        name: '',
        upload_type: '',
        file_type: [],
        file_size: '',
        is_active: formType === 'create' ? true : ''
    });

    useQuery(Schema.appEnums, {
        fetchPolicy: 'no-cache',
        variables: { where: { enum_type: { in: ['SYS_DOC_FILE_SIZE', 'SYS_DOC_FILE_TYPE'] }, enum_group: { equals: 'SYS_ADMIN' } } },
        onCompleted: (response) => {
            if (response.appEnums) {
                setState({
                    ...state,
                    fileSizes: response.appEnums
                        .filter((a) => a.enum_type === 'SYS_DOC_FILE_SIZE')
                        .map((b) => {
                            b.display_value = 'Up to ' + b.display_value;
                            return b;
                        }),
                    fileTypes: response.appEnums.filter((a) => a.enum_type === 'SYS_DOC_FILE_TYPE' && a.enum_value !== 'ALL'),
                    loading: false
                });
                if (recordID && isDraft) {
                    getEntityRecord();
                } else if (recordID) {
                    getDocCategory();
                }
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const prepareData = (response) => {
        if (response.id) setID(response.id);
        setversionNo(response?.version_no);
        setversionUser(response?.version_user);
        setDocDetails(response);
        let mappings = response?.mappings?.create ? response?.mappings?.create : response?.mappings ? response?.mappings : [];
        let selected = [];
        mapDocList.forEach((val) => {
            let obj = mappings.filter((v) => val.value4 === v.fmis_function_code)[0];
            if (obj) {
                val.is_mandatory = obj.is_mandatory;
                selected.push(val);
            }
        });
        setFormState({
            ...formState,
            name: response.name,
            upload_type: response.upload_type,
            file_type: response.file_type ? response.file_type?.split(',') : [],
            file_size: response.file_size ? state.fileSizes.find((a) => parseInt(a.enum_value) === response.file_size) : '',
            is_active: response.is_active
        });
        setState({ ...state, selectedRows: selected });
    };

    const [getDocCategory, { loading: catLoader }] = useLazyQuery(Schema.documentCategory, {
        // get api for data by ID
        fetchPolicy: 'no-cache',
        variables: { where: { id: recordID } },
        onCompleted: (response) => {
            if (response?.documentCategory) {
                prepareData(response.documentCategory);
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    useQuery(Schema.appSettings, {
        variables: { where: { group: { equals: 'SYS_ADMIN' }, code: { equals: 'MAPPING_FUNCTION' } } },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.appSettings) setMapDocList(data?.appSettings);
        }
    });

    const [getEntityRecord, { loading: entityLoader }] = useLazyQuery(Schema.entityRecord, {
        // get api for data by ID in Draft mode
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: { where: { id: recordID } },
        onCompleted: (data) => {
            if (data?.entityRecord?.record) {
                setEntityId(data?.entityRecord?.record?.entity_id);
                prepareData(data.entityRecord.record);
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const fields = [{ key: 'name', validators: 'isAlphabetSpecial' }];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev);

        if (err[key]) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setFormState({
            ...formState,
            [key]: ev
        });
    };

    const handleLoader = (status) => {
        if (status) {
            setState({ ...state, loading: true });
            return;
        }
        setState({ ...state, loading: status });
    };

    const handleCancel = () => {
        props.history.goBack();
    };

    const showGrowl = (msg, type) => {
        growl.show({ life: 6000, severity: type, summary: msg });
        setTimeout(() => {
            if (type !== 'error') handleCancel();
        }, 1000);
    };

    const navigateToEdit = () => {
        setMode('edit');
    };
    const showWorkflow = (flag) => {
        setState({ ...state, showWorkflowStatus: flag });
    };
    const handleCommentClick = (selRow, flag) => {
        const lstToShowComment = state.listToShowComment;
        lstToShowComment[selRow.id] = flag;
        setState({ ...state, listToShowComment: lstToShowComment });
    };

    const setActionsStatus = (hasActions) => {
        setState({ ...state, hasActions });
    };

    const updateSelctedRows = (e, data, obj) => {
        setState({
            ...state,
            selectedRows: [...data],
            ...obj
        });
    };

    const updateData = (e, rowData, value, column) => {
        mapDocList.forEach((va) => {
            if (va.value1 === rowData.value1 && va.value2 === rowData.value2) {
                rowData[column.field] = e.checked;
            }
        });
        setMapDocList(mapDocList);
    };

    return (
        <div className={`page-body pos-rel  ${state.loading || entityLoader || catLoader ? 'mask' : ''}`}>
            {(state.loading || entityLoader || catLoader) && (
                <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />
            )}
            <Toast ref={(el) => setGrowl(el)} />
            <>
                <div className="page-header">
                    {!state.loading && !entityLoader && !catLoader && (
                        <span className="light-color">
                            {recordID
                                ? transactionId && transactionId !== 'undefined'
                                    ? `Transaction Number : ${transactionId}`
                                    : `Document Category : ${docDetails?.ref}`
                                : 'Add New Document Category'}
                        </span>
                    )}

                    {!state.loading && !entityLoader && !catLoader && (
                        <span className="backBtn pointer">
                            {recordID && versionNo !== 0 && (
                                <img
                                    alt=""
                                    className="header-icon"
                                    title="Workflow Stages"
                                    src={process.env.PUBLIC_URL + '/assets/icons/workflow.svg'}
                                    onClick={() => showWorkflow(true)}
                                ></img>
                            )}
                            <span
                                onClick={() => {
                                    localStorage.setItem('transaction_id', '');
                                    props.history.goBack();
                                }}
                            >
                                <span className="appkiticon icon-left-chevron-outline icon_size"></span>Back
                            </span>
                        </span>
                    )}
                </div>
                <div className={`${state.hasActions || mode === 'create' ? 'page-content-ht-with-btns' : 'page-content-ht-without-btns'}`}>
                    <Sidebar
                        visible={state.showWorkflowStatus}
                        onHide={() => showWorkflow(false)}
                        position="right"
                        dismissable={true}
                        closeOnEscape={false}
                        showCloseIcon={false}
                    >
                        <WorklowSidebarContent
                            sourceId={recordID}
                            listToShowComment={state.listToShowComment}
                            showWorkflow={showWorkflow}
                            handleCommentClick={handleCommentClick}
                        />
                    </Sidebar>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                    <b className="fs-14">
                                        Document Category Name {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7  bill-desc word-break-word">
                                    {mode !== 'view' ? (
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="name"
                                            type="text"
                                            value={formState.name}
                                            maxLength={window.INTERNAL_AUDIT ? window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT : '50'}
                                            onChange={(e) => handleChange(e.toUpperCase(), 'name')}
                                            placeholder="Enter Document Category Name"
                                            hasError={errors && errors.name}
                                            errMsg="Please enter valid Document Category Name"
                                            validations={['isAlphabetSpecial']}
                                        />
                                    ) : formState.name ? (
                                        formState.name
                                    ) : (
                                        ' - '
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                    <b className="fs-14">Upload Type {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    {mode !== 'view' ? (
                                        <>
                                            <RadioButton
                                                inputId="rb1"
                                                value={'SINGLE'}
                                                name="Single"
                                                onChange={(e) => handleChange(e.value, 'upload_type')}
                                                checked={formState.upload_type === 'SINGLE'}
                                            />
                                            <label htmlFor="rb1" className="p-radiobutton-label p-r-20 ">
                                                Single
                                            </label>
                                            <RadioButton
                                                inputId="rb2"
                                                value={'MULTIPLE'}
                                                name="Multiple"
                                                onChange={(e) => handleChange(e.value, 'upload_type')}
                                                checked={formState.upload_type === 'MULTIPLE'}
                                            />
                                            <label htmlFor="rb2" className="p-radiobutton-label">
                                                Multiple
                                            </label>
                                        </>
                                    ) : (
                                        <span>{formState?.upload_type ? convertTextCase(formState.upload_type, true) : ''}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                    <b className="fs-14">File Type {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    {mode !== 'view' ? (
                                        <MultiSelect
                                            name="file_type"
                                            appendTo={document.self}
                                            onChange={(e) => handleChange(e.value, 'file_type')}
                                            value={formState.file_type}
                                            options={state.fileTypes}
                                            optionValue="display_value"
                                            optionLabel="display_value"
                                            className="w-100"
                                            placeholder="-Select-"
                                            filter={true}
                                            filterPlaceholder="Search"
                                        ></MultiSelect>
                                    ) : (
                                        <span className="word-break">
                                            {formState.file_type?.length > 0 ? formState.file_type.toString() : '–'}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                    <b className="fs-14">File Size {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    {mode !== 'view' ? (
                                        <Dropdown
                                            name="file_size"
                                            value={formState.file_size}
                                            onChange={(e) => handleChange(e.value, 'file_size')}
                                            placeholder="-Select-"
                                            appendTo={document.self}
                                            options={state.fileSizes}
                                            optionLabel="display_value"
                                            className="w-100"
                                        />
                                    ) : formState.file_size?.display_value ? (
                                        formState.file_size.display_value
                                    ) : (
                                        ' - '
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                    <b className="fs-14">Is Active {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    {mode !== 'view' ? (
                                        <>
                                            <RadioButton
                                                inputId="rb1"
                                                value={true}
                                                name="is_active"
                                                onChange={(e) => handleChange(e.value, 'is_active')}
                                                checked={formState.is_active === true}
                                            />
                                            <label htmlFor="rb1" className="p-radiobutton-label p-r-20 ">
                                                Yes
                                            </label>
                                            <RadioButton
                                                inputId="rb2"
                                                value={false}
                                                name="is_active"
                                                onChange={(e) => handleChange(e.value, 'is_active')}
                                                checked={formState.is_active === false}
                                            />
                                            <label htmlFor="rb2" className="p-radiobutton-label">
                                                No
                                            </label>
                                        </>
                                    ) : formState.is_active === true ? (
                                        'Yes'
                                    ) : (
                                        'No'
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-b-10">
                            <div className="f-14">
                                <b> Applicable For</b>
                                {mode !== 'view' ? <span className="icon-primary p-l-5">*</span> : ' :'}
                            </div>
                            <div className="row m-b-10">
                                <div className="col-sm-12">
                                    <CommonInlineTable
                                        selectionMode={mode !== 'view' && 'checkbox'}
                                        className={`secondary-table m-t-10 ${mode === 'view' ? 'selection-disable' : ''}`}
                                        rows={mapDocList}
                                        columns={TableSchema.DOC_CAT_MAPPINGS}
                                        emptyMessage="No records found"
                                        noPaginator={false}
                                        paginatorLength={5}
                                        formType={mode}
                                        selectedRows={state.selectedRows}
                                        updateInparent={(e, data) => updateSelctedRows(e, data, {})}
                                        isUpdateInParentTable={true}
                                        updateInparentTable={updateData}
                                        scrollable
                                    ></CommonInlineTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DocumentCategoryActions
                    formState={formState}
                    handleLoader={handleLoader}
                    handleCancel={handleCancel}
                    showGrowl={showGrowl}
                    formType={mode}
                    setActionsStatus={setActionsStatus}
                    recordID={recordID}
                    navigateToEdit={navigateToEdit}
                    recID={recID}
                    entity_id={entityId}
                    version_no={versionNo}
                    version_user={versionUser}
                    errorStatus={errors}
                    selectedRows={state.selectedRows}
                />
            </>
        </div>
    );
};

export default CreateDocumentCategory;
