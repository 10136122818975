import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/IsAuthorized';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
const collectionCashBooksItems = MenuItemsSchema.COLLECTION_CASH_BOOK;
const CollectionCashBookDashboard = lazy(() =>
    import('../pages/revenueManagement/collectionCashBooks/dashboard/collectionCashBookDashboard')
);
const DailyCashReport = lazy(() => import('../pages/revenueManagement/collectionCashBooks/cashReport/dailyCashReport'));
const revenueManagementBreadCrmb = (props) => {
    let AllCoaBreadcrumbValues = [{ name: 'Revenue Management', redirectTo: '/revenuemanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/rmm/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '', key: subPath[2] }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const userInfo = JSON.parse(localStorage.getItem('USER_INF'));
const CollectionCashBooksRouting = (props) => {
    if (userInfo?.role?.code !== 'CASHIER') {
        collectionCashBooksItems.filter((router) => router.routerLink !== '/cb/collectorReport');
    } else if (userInfo?.role?.code !== 'COLLECTOR') {
        collectionCashBooksItems.filter((router) => router.routerLink !== '/cb/cashReport');
    } else {
        collectionCashBooksItems.filter((router) => router.routerLink !== '/cb/cashReport' && router.routerLink !== '/cb/collectorReport');
    }
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={revenueManagementBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={CollectionCashBookDashboard}
                    exact
                    path={`${props?.path}/cashDashboard`}
                    name="Dashboard"
                    menuItems={collectionCashBooksItems}
                />
                {userInfo?.role?.code !== 'CASHIER' && (
                    <Route
                        exact
                        path={`${props?.path}/cashReport`}
                        component={() => <DailyCashReport user={userInfo} name="Daily Cash Report" type="cashier" />}
                    ></Route>
                )}
                {userInfo?.role?.code !== 'COLLECTOR' && (
                    <Route
                        exact
                        path={`${props?.path}/collectorReport`}
                        component={() => <DailyCashReport user={userInfo} name="Daily Collection Report" type="collector" />}
                    ></Route>
                )}
            </Switch>
        </div>
    );
};
export default CollectionCashBooksRouting;
