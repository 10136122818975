import React, { useState, useEffect } from 'react';
import Calendar from '../../../../components/Calendar/CalendarComponent';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import * as FormValidation from '../../../../Validation';
import { getDate, serverDate } from '../../../../util';
import '../../../../styles/utils.scss';

const SanctionDetails = (props) => {
    const [formState, setFormState] = useState(props.formStateObj?.SANCTION_DETAILS);
    const [errors] = useState({});

    const fields = [
        { key: 'number', validators: 'numbersOnly', maxLength: 30 },
        { key: 'amount', validators: 'numbersOnly', maxLength: 15 }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);
        if (
            err[key] ||
            (key === 'number' && parseInt(ev) <= 0) ||
            (key === 'amount' && parseFloat(ev) > parseFloat(props.formStateObj.BILLS?.grossBillAmt)) ||
            (typeof ev === 'string' && ev.trim() === '')
        ) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        props.updateErrorStatus(Object.keys(errors).length > 0);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setFormState({
            ...formState,
            [key]: ev
        });
    };

    useEffect(() => {
        props.saveFormData('SANCTION_DETAILS', formState);
        // eslint-disable-next-line
    }, [formState]);
    useEffect(() => {
        setFormState(props.formStateObj?.SANCTION_DETAILS);
        // eslint-disable-next-line
    }, [props.formStateObj?.SANCTION_DETAILS]);

    return (
        <div className="coa-card p-10">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">Sanction Number :</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                            {props.mode !== 'view' ? (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="number"
                                    type="text"
                                    value={formState.number}
                                    maxLength={30}
                                    onChange={(e) => handleChange(e, 'number')}
                                    placeholder="Enter Sanction Number"
                                    hasError={errors && errors.number}
                                    errMsg="Please enter valid Sanction Number"
                                />
                            ) : formState.number ? (
                                formState.number
                            ) : (
                                '–'
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">Sanction Date :</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            {props.mode !== 'view' ? (
                                <Calendar
                                    className="w-100"
                                    maxDate={serverDate()}
                                    readOnlyInput={true}
                                    placeholder="Select Sanction Date"
                                    appendTo={document.self}
                                    value={formState.date}
                                    onChange={(e) => handleChange(e.target.value, 'date')}
                                    dateFormat="dd M yy"
                                    showIcon
                                />
                            ) : formState.date && getDate(formState.date) ? (
                                formState.date && getDate(formState.date, true)
                            ) : (
                                '–'
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">Sanctioning Authority :</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                            {props.mode !== 'view' ? (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="authority"
                                    type="text"
                                    value={formState.authority}
                                    maxLength={30}
                                    onChange={(e) => handleChange(e, 'authority')}
                                    placeholder="Enter Sanctioning Authority"
                                    hasError={errors && errors.authority}
                                    errMsg="Please enter valid Sanctioning Authority"
                                    alphanumeric='true'
                                />
                            ) : formState.authority ? (
                                formState.authority
                            ) : (
                                '–'
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                            <b className="fs-14">Sanction Amount :</b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                            {props.mode !== 'view' ? (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="amount"
                                    type="text"
                                    value={formState.amount}
                                    maxLength={15}
                                    onChange={(e) => handleChange(e, 'amount')}
                                    placeholder="Enter Sanction Amount"
                                    hasError={errors && errors.amount}
                                    errMsg="Please enter valid Sanction Amount less than Gross Bill Amount"
                                    disabled={!props.formStateObj.BILLS?.grossBillAmt < 0}
                                />
                            ) : formState.amount ? (
                                formState.amount
                            ) : (
                                '–'
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SanctionDetails;
