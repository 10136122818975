import store from '../index';

const getPrivileges = () => {
    return store.getState()?.appReducer?.auth?.privileges;
};

const IsAuthorized = (props) => {
    const privs = getPrivileges();
    const offices = privs[props.privRequired];
    if (!offices || offices.length === 0) {
        return props.no();
    }
    // sometimes comming year like 2021 added offices[0]?.length
    if (offices[0] === 'ALL' || offices[0]?.length > 0) {
        return props.yes();
    }
    return offices.includes(props.officeCode) ? props.yes() : props.no();
};

IsAuthorized.defaultProps = {
    yes: () => null,
    no: () => null
};

export default IsAuthorized;
