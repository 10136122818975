import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import ErrorBoundary from './commons/ErrorBoundary';
import { adminClient } from './apollo';
import { ApolloProvider } from '@apollo/client';
import 'primeicons/primeicons.css';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './app-redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import * as Flatted from 'flatted';
import { unregister } from './registerServiceWorker';

export const transformCircular = createTransform(
    (inboundState) => Flatted.stringify(inboundState),
    (outboundState) => Flatted.parse(outboundState)
);
const persistConfig = {
    key: 'root',
    transforms: [transformCircular],
    storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = [];
const store = createStore(persistedReducer, applyMiddleware(...middleware));
const persistor = persistStore(store);
export default store;
ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary>
            <ApolloProvider client={adminClient} store={store}>
                <PersistGate persistor={persistor}>
                    <App />
                </PersistGate>
            </ApolloProvider>
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root')
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */

unregister();
