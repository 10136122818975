import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/IsAuthorized';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import AuditOpinionGuideline from '../pages/internalAudit/masters/globalMasters/others/auditGuidelines/AuditOpinionGuideline';
import AddGuideline from '../pages/internalAudit/masters/globalMasters/others/auditGuidelines/AddGuideline';
import CreateAAHierarchy from '../pages/internalAudit/masters/globalMasters/others/AAHierarchy/CreateAAHierarchy';

const AuditHierarchy = lazy(() => import('../pages/internalAudit/masters/globalMasters/auditHierarchy/AuditHierarchy'));
const AuditPlanConfiguration = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/auditConfiguration/AuditPlanConfiguration/AuditPlanConfiguration')
);
const AddAuditPlanConfiguration = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/auditConfiguration/AuditPlanConfiguration/AddAuditPlanConfiguration')
);
const AuditProgramConfiguration = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/auditConfiguration/AuditProgramConfiguration')
);

const RiskDefinition = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/risksAndControlsDefinition/RiskDefinition/RiskDefinition')
);
const AddRiskDefinition = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/risksAndControlsDefinition/RiskDefinition/AddRiskDefinition')
);
const ControlsDefinition = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/risksAndControlsDefinition/ControlsDefinition/ControlsDefinition')
);
const AddControlsDefinition = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/risksAndControlsDefinition/ControlsDefinition/AddControlDefinition')
);
const EntiyiRiskMaturity = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/risksAndControlsConfiguration/EntityRiskMaturity')
);
const TransactionRiskandControls = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/risksAndControlsConfiguration/TransactionRiskAndControls')
);
const RiskandControlDashboard = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/risksAndControlsConfiguration/RiskandControlDashboard')
);
const TransactionRiskRanking = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/risksAndControlsConfiguration/TransactionRiskRanking')
);
const CreateTransactionRiskRanking = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/risksAndControlsConfiguration/CreateTransactionRiskRanking')
);
const AuditableAreaAndHierarchy = lazy(() =>
    import('../pages/internalAudit/masters/globalMasters/others/AAHierarchy/AuditableAreaAndHierarchy')
);

const FileNumber = lazy(() => import('../pages/internalAudit/masters/globalMasters/others/FileNumber'));
const FollowUpAuditConfiguration = lazy(() => import('../pages/internalAudit/masters/globalMasters/others/FollowUpAuditConfiguration'));

const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const globalMastersItems = MenuItemsSchema.GLOBAL_MASTERS;
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const globalMastersBreadCrmb = (props) => {
    let globalMasterValues = [{ name: 'Masters', redirectTo: '/masters' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/globalmaster/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    globalMasterValues = [...globalMasterValues, ...obj];
    return globalMasterValues;
};
const GlobalMastersRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={globalMastersBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={AuditHierarchy}
                    path={`${props?.path}/audithierarchy`}
                    name="Audit Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditPlanConfiguration}
                    exact
                    path={`${props?.path}/auditplanconfig`}
                    name="Audit Plan Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAuditPlanConfiguration}
                    exact
                    path={`${props?.path}/auditplanconfig/create`}
                    name="Audit Plan Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAuditPlanConfiguration}
                    exact
                    path={`${props?.path}/auditplanconfig/edit/:id`}
                    name="Audit Plan Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAuditPlanConfiguration}
                    exact
                    path={`${props?.path}/auditplanconfig/edit/:id/draft`}
                    name="Audit Plan Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAuditPlanConfiguration}
                    exact
                    path={`${props?.path}/auditplanconfig/view/:id`}
                    name="Audit Plan Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAuditPlanConfiguration}
                    exact
                    path={`${props?.path}/auditplanconfig/view/:id/approved`}
                    name="Audit Plan Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAuditPlanConfiguration}
                    exact
                    path={`${props?.path}/auditplanconfig/edit/:id/approved`}
                    name="Audit Plan Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAuditPlanConfiguration}
                    exact
                    path={`${props?.path}/auditplanconfig/view/:id/draft`}
                    name="Audit Plan Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditProgramConfiguration}
                    path={`${props?.path}/auditprogram`}
                    name="Audit Program Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={RiskDefinition}
                    exact
                    path={`${props?.path}/riskdef`}
                    name="Risk Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskDefinition}
                    exact
                    path={`${props?.path}/riskdef/create`}
                    name="Risk Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskDefinition}
                    exact
                    path={`${props?.path}/riskdef/edit/:id`}
                    name="Risk Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskDefinition}
                    exact
                    path={`${props?.path}/riskdef/edit/:id/draft`}
                    name="Risk Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskDefinition}
                    exact
                    path={`${props?.path}/riskdef/view/:id`}
                    name="Risk Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskDefinition}
                    exact
                    path={`${props?.path}/riskdef/view/:id/approved`}
                    name="Risk Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskDefinition}
                    exact
                    path={`${props?.path}/riskdef/edit/:id/approved`}
                    name="Risk Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRiskDefinition}
                    exact
                    path={`${props?.path}/riskdef/view/:id/draft`}
                    name="Risk Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={ControlsDefinition}
                    exact
                    path={`${props?.path}/controlsdef`}
                    name="Controls Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddControlsDefinition}
                    exact
                    path={`${props?.path}/controlsdef/create`}
                    name="Controls Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddControlsDefinition}
                    exact
                    path={`${props?.path}/controlsdef/view/:id`}
                    name="Controls Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddControlsDefinition}
                    exact
                    path={`${props?.path}/controlsdef/view/:id/approved`}
                    name="Controls Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddControlsDefinition}
                    exact
                    path={`${props?.path}/controlsdef/edit/:id`}
                    name="Controls Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddControlsDefinition}
                    exact
                    path={`${props?.path}/controlsdef/edit/:id/approved`}
                    name="Controls Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddControlsDefinition}
                    exact
                    path={`${props?.path}/controlsdef/view/:id/draft`}
                    name="Controls Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddControlsDefinition}
                    exact
                    path={`${props?.path}/controlsdef/edit/:id/draft`}
                    name="Controls Definition"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={EntiyiRiskMaturity}
                    exact
                    path={`${props?.path}/entityriskmaturity`}
                    name="Entity Risk Maturity"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={TransactionRiskandControls}
                    exact
                    path={`${props?.path}/transriskandcontrols/view/:id`}
                    name="Transaction Risk and Controls"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={RiskandControlDashboard}
                    exact
                    path={`${props?.path}/transriskandcontrols`}
                    name="Transaction Risk and Controls"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={TransactionRiskRanking}
                    exact
                    path={`${props?.path}/transriskranking`}
                    name="Transaction Risk Ranking"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateTransactionRiskRanking}
                    exact
                    path={`${props?.path}/transriskranking/view/:id`}
                    name="Transaction Risk Ranking"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditableAreaAndHierarchy}
                    exact
                    path={`${props?.path}/auditareaandhierarchy`}
                    name="Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAAHierarchy}
                    exact
                    path={`${props.path}/auditareaandhierarchy/create`}
                    name="Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAAHierarchy}
                    exact
                    path={`${props.path}/auditareaandhierarchy/edit/:id`}
                    name="Edit Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAAHierarchy}
                    exact
                    path={`${props.path}/auditareaandhierarchy/edit/:id/approved`}
                    name="Edit Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAAHierarchy}
                    exact
                    path={`${props.path}/auditareaandhierarchy/view/:id/approved`}
                    name="View Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAAHierarchy}
                    exact
                    path={`${props.path}/auditareaandhierarchy/view/:id/existapproved`}
                    name="View Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAAHierarchy}
                    exact
                    path={`${props.path}/auditareaandhierarchy/view/:id/draft`}
                    name="Edit Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAAHierarchy}
                    exact
                    path={`${props.path}/auditareaandhierarchy/view/:id/existdraft`}
                    name="View Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAAHierarchy}
                    exact
                    path={`${props.path}/auditareaandhierarchy/edit/:id/draft`}
                    name="Edit Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateAAHierarchy}
                    exact
                    path={`${props.path}/auditareaandhierarchy/view/:id`}
                    name="View Auditable Area and Hierarchy"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={FileNumber}
                    exact
                    path={`${props?.path}/filenumber`}
                    name="File Number"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={FollowUpAuditConfiguration}
                    exact
                    path={`${props?.path}/followupauditconfig`}
                    name="Follow-Up Audit Configuration"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGuideline}
                    exact
                    path={`${props?.path}/guidelineauditopinion/create`}
                    name="Guidline for Audit Opinion"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGuideline}
                    exact
                    path={`${props?.path}/guidelineauditopinion/view/:id/draft`}
                    name="Guidline for Audit Opinion"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGuideline}
                    exact
                    path={`${props?.path}/guidelineauditopinion/view/:id`}
                    name="Guidline for Audit Opinion"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGuideline}
                    exact
                    path={`${props?.path}/guidelineauditopinion/view/:id/approved`}
                    name="Guidline for Audit Opinion"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGuideline}
                    exact
                    path={`${props?.path}/guidelineauditopinion/edit/:id/draft`}
                    name="Guidline for Audit Opinion"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGuideline}
                    exact
                    path={`${props?.path}/guidelineauditopinion/edit/:id`}
                    name="Guidline for Audit Opinion"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGuideline}
                    exact
                    path={`${props?.path}/guidelineauditopinion/edit/:id/approved`}
                    name="Guidline for Audit Opinion"
                    menuItems={globalMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AuditOpinionGuideline}
                    exact
                    path={`${props?.path}/guidelineauditopinion`}
                    name="Guidline for Audit Opinion"
                    menuItems={globalMastersItems}
                />
            </Switch>
        </div>
    );
};
export default GlobalMastersRouting;
