import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../../styles/Dialog.scss';

export function ConfirmationModal(props) {
    const [loading, setLoading] = useState(false);
    return (
        <Modal visible={props.showModal} onCancel={() => props.handleCancel()} className="common_css add_user" backdropClosable={false}>
            <ModalHeader>Delete {props.type}</ModalHeader>
            <ModalBody className="p-0">
                <div>
                    {(loading || props?.state?.loading) && (
                        <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />
                    )}
                    <div className="flex align-items-center">
                        <i className="pi pi-exclamation-triangle m-r-10 font-size-24" />
                        <div className="f-14">Are you sure you want to delete {props.name} ? </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className={loading || props?.state?.loading ? 'mask p-10' : 'm-0'}>
                <Button
                    className="m-r-10"
                    size="sm"
                    kind="primary"
                    onClick={() => {
                        props.showSpinner && setLoading(true);
                        props.handleDelete(true);
                    }}
                >
                    Yes
                </Button>
                <Button size="sm" kind="secondary" onClick={() => props.handleCancel()}>
                    No
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ConfirmationModal;
