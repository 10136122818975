import { MenuItemsSchema } from '../../commons/MenuItemsSchema';
export const RoutingSchema = {
    systemadministration: {
        name: 'System Administration',
        panels: [
            {
                type: 'CHART_OF_ACCOUNTS',
                name: 'Chart Of Accounts',
                icon: process.env.PUBLIC_URL + '/assets/icons/coa.svg',
                description: 'Configuring COA structure, codes, composition and mapping.',
                menuItems: MenuItemsSchema.COA_ITEMS,
                routerLink: '/coa/segments'
            },
            {
                type: 'USER_MANAGEMENT',
                name: 'User Management',
                icon: process.env.PUBLIC_URL + '/assets/icons/UserReg.svg',
                description: 'Configuring user registration, user privileges and user roles.',
                menuItems: MenuItemsSchema.USER_ITEMS,
                routerLink: '/organizationLevel'
            },
            {
                type: 'COMMON_MASTERS',
                name: 'Masters',
                icon: process.env.PUBLIC_URL + '/assets/icons/Common_Masters.svg',
                noborder: true,
                description: 'Configuring common application masters.',
                menuItems: MenuItemsSchema.COMMON_MASTERS,
                routerLink: '/commonmasters/exchangecurrency'
            },
            {
                type: 'WORKFLOW_CONFIGURATION',
                name: 'Workflow Configuration',
                icon: process.env.PUBLIC_URL + '/assets/icons/workflowConfiguration.svg',
                description: 'Configuring workflow for application.',
                menuItems: MenuItemsSchema.WF_CONFIG,
                routerLink: '/workflow'
            },
            {
                type: 'GLOBAL_CONFIG',
                name: 'Global Variable Configuration',
                icon: process.env.PUBLIC_URL + '/assets/icons/Common_Masters.svg',
                noborder: true,
                description: 'Configuring common fields.',
                menuItems: MenuItemsSchema.GLOBAL_CONFIG,
                routerLink: '/global/globalconfiguration'
            }
        ]
    },

    generalledger: {
        name: 'General Ledger',
        panels: [
            {
                type: 'GL_MASTER',
                name: 'Masters',
                icon: process.env.PUBLIC_URL + '/assets/icons/generalLedgerIcon.svg',
                description: 'Configuring general ledger masters and others',
                menuItems: MenuItemsSchema.GL_MASTER_ITEMS,
                routerLink: '/gl_masters'
            },
            {
                type: 'JOURNAL_LEDGER',
                name: 'Journals & Ledgers',
                icon: process.env.PUBLIC_URL + '/assets/icons/generalLedgerIcon.svg',
                description: 'Configuring journals and viewing ledgers',
                menuItems: MenuItemsSchema.JOURNAL_LEDGER_ITEMS,
                routerLink: '/gl_journals_and_ledgers'
            }
        ]
    },
    contractmanagement: {
        name: 'Supplier Management',
        panels: [
            {
                type: 'SUPPLIER_MANAGEMENT',
                name: 'Supplier Management',
                icon: process.env.PUBLIC_URL + '/assets/icons/Supplier_Management.svg',
                description: 'Configuring supplier category, supplier registration and supplier management.',
                menuItems: MenuItemsSchema.SUPL_MNGMNT,
                routerLink: '/cm/configuresuppliercategory',
                noborder: true
            },
            {
                type: 'CONTRACT_MANAGEMENT',
                name: 'Contract Management',
                icon: process.env.PUBLIC_URL + '/assets/icons/Contract_Management.svg',
                description: 'Supports in Contract creation, amendment and management.',
                menuItems: MenuItemsSchema.CNTRCT_CREATION,
                routerLink: '/cc/contracts',
                noborder: true
            },
            {
                type: 'MASTERS',
                name: 'Masters',
                icon: process.env.PUBLIC_URL + '/assets/icons/Common_Masters.svg',
                noborder: true,
                description: ' Configure masters related to supplier and contract management.',
                menuItems: MenuItemsSchema.CM_MASTER,
                routerLink: '/cmm/supplycategorymaster'
            }
        ]
    },
    revenuemanagement: {
        name: 'Revenue Management',
        panels: [
            {
                type: 'GENERATE_CHALLANS',
                name: 'Generate Challans(FMIS)',
                icon: process.env.PUBLIC_URL + '/assets/icons/challan.svg',
                description: 'Different activities of Generate Challans(FMIS) can be managed here.',
                menuItems: MenuItemsSchema.GENERATE_CHALLAN,
                routerLink: '/rmm/dashboard'
            },
            {
                type: 'COLLECTION_AND_CASH_BOOKS',
                name: 'Collection & Cash Books',
                icon: process.env.PUBLIC_URL + '/assets/icons/pool.svg',
                description: 'Different activities of Collection & Cash Books can be managed here.',
                menuItems: MenuItemsSchema.COLLECTION_CASH_BOOK,
                routerLink: '/cb/cashDashboard'
            },
            {
                type: 'DEPOSIT_VOUCHER',
                name: 'Deposit Voucher',
                icon: process.env.PUBLIC_URL + '/assets/icons/deposit.svg',
                description: 'Different activities of Deposit Voucher can be managed here.',
                menuItems: MenuItemsSchema.DEPOSIT_VOUCHER,
                routerLink: '/dv/dvDashboard'
            },
            {
                type: 'REPORTS',
                name: 'Reports',
                icon: process.env.PUBLIC_URL + '/assets/icons/reportIcon.svg',
                description: 'Different activities of Reports can be managed here.',
                menuItems: MenuItemsSchema.REPORTS,
                routerLink: '/rmp/standardreports'
            },
            {
                type: 'MASTER_CONFIGURATION',
                name: 'Masters',
                icon: process.env.PUBLIC_URL + '/assets/icons/Common_Masters.svg',
                noborder: true,
                description: 'Different activities of Masters can be managed here.',
                menuItems: MenuItemsSchema.REVENUE_MNGMNT,
                routerLink: '/rm/remittanceconfig'
            },
            {
                type: 'NON_FMIS',
                name: 'Non - FMIS',
                icon: process.env.PUBLIC_URL + '/assets/icons/non_fmis.svg',
                noborder: true,
                description: 'Different activities of Masters can be managed here.',
                menuItems: MenuItemsSchema.NON_FMIS,
                routerLink: '/rnf/dashboard'
            }
        ]
    },
    paymentmanagement: {
        name: 'Payment Management',
        panels: [
            {
                type: 'GENERATE_PAYMENT_INSTRUCTIONS',
                name: 'Generate Payment Instructions',
                icon: process.env.PUBLIC_URL + '/assets/icons/generatePaymentInstructions.svg',
                description: 'Different activities of Generate Payment Instructions can be managed here.',
                menuItems: MenuItemsSchema.PAYM_MNGMNT,
                routerLink: '/pm/bills'
            },
            {
                type: 'CHEQUE_MANAGEMENT',
                name: 'Cheque Management',
                icon: process.env.PUBLIC_URL + '/assets/icons/chequeManagement.svg',
                description: 'Different activities of Cheque Management can be managed here.',
                menuItems: MenuItemsSchema.CHEQUE_MNGMNT,
                routerLink: '/pmm/bankmaster'
            }
        ]
    },
    expendituremanagement: {
        name: 'Expenditure Management',
        panels: [
            {
                type: 'BILLS_MANAGEMENT',
                name: 'Bills Management',
                icon: process.env.PUBLIC_URL + '/assets/icons/billsManageIcon.svg',
                description: 'Different activities of Bills Management can be managed here',
                menuItems: MenuItemsSchema.BILLS_MANAGEMENT,
                routerLink: '/expbills/contractbill'
            },
            {
                type: 'MASTER_CONFIGURATION',
                name: 'Masters',
                icon: process.env.PUBLIC_URL + '/assets/icons/Common_Masters.svg',
                noborder: true,
                description: 'Different activities of Masters can be managed here',
                menuItems: MenuItemsSchema.EXP_MASTER,
                routerLink: '/expmaster/contractbill'
            }
        ]
    },
    employeemanagement: {
        name: 'Employee Management',
        panels: [
            {
                type: 'EMPLOYEE_MANAGEMENT',
                name: 'Employee Management',
                icon: process.env.PUBLIC_URL + '/assets/icons/Emp.svg',
                description: 'Different activities of Employee Management can be managed here',
                menuItems: MenuItemsSchema.EMPLOYEE_MANAGEMENT,
                routerLink: window.INTERNAL_AUDIT ? '/empfunction/employee' : '/empfunction/empdetails'
            },
            {
                type: 'EMPLOYEE_MASTERS',
                name: 'Master Configuration',
                icon: process.env.PUBLIC_URL + '/assets/icons/Common_Masters.svg',
                noborder: true,
                description: 'Different activities of Masters Configuration can be managed here',
                menuItems: MenuItemsSchema.EMP_MASTERS,
                routerLink: '/empmaster/empclsstructure'
            }
        ]
    },
    masters: {
        name: 'Masters',
        panels: [
            {
                type: 'GLOBAL_MASTERS',
                name: 'Global Masters',
                icon: process.env.PUBLIC_URL + '/assets/icons/global_masters.svg',
                description: 'Different activities of Global Masters can be managed here',
                menuItems: MenuItemsSchema.GLOBAL_MASTERS,
                routerLink: '/globalmaster/audithierarchy'
            },
            {
                type: 'AUDIT_UNIVERSE_MANAGEMENT',
                name: 'Audit Universe Management',
                icon: process.env.PUBLIC_URL + '/assets/icons/audit_universe_management.svg',
                noborder: true,
                description: 'Different activities of Audit Universe Management can be managed here',
                menuItems: MenuItemsSchema.AUDIT_UNIVERSE_MANAGEMENT,
                routerLink: '/audituniversemang/stakeholders'
            },
            {
                type: 'RESOURCE_MANAGEMENT',
                name: 'Resource Management',
                icon: process.env.PUBLIC_URL + '/assets/icons/resource_management.svg',
                noborder: true,
                description: 'Different activities of Resource Management can be managed here',
                menuItems: MenuItemsSchema.RESOURCE_MANAGEMENT,
                routerLink: '/resourcemang/humanresourcesplan'
            }
        ]
    },
    saap: {
        name: 'Strategic and Annual Audit Planning',
        panels: [
            {
                type: 'STRATEGIC_AUDIT_PLAN',
                name: 'Strategic Audit Plan',
                icon: process.env.PUBLIC_URL + '/assets/icons/sap.svg',
                noborder: true,
                description: 'Different activities of Strategic Audit Plan can be managed here',
                menuItems: MenuItemsSchema.STRATEGIC_AUDIT_PLAN,
                routerLink: '/strategicauditplan/riskmaturityassessment'
            },
            {
                type: 'ANNUAL_AUDIT_PLAN',
                name: 'Annual Audit Plan',
                icon: process.env.PUBLIC_URL + '/assets/icons/aap.svg',
                noborder: true,
                description: 'Different activities of Annual Audit Plan can be managed here',
                menuItems: MenuItemsSchema.ANNUAL_AUDIT_PLAN,
                routerLink: '/annualauditplan/annualauditplan'
            }
        ]
    },
    aape: {
        name: 'Audit Assignment Planning and Execution',
        panels: [
            {
                type: 'AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION',
                name: 'Audit Assignment Planning and Execution',
                icon: process.env.PUBLIC_URL + '/assets/icons/Common_Masters.svg',
                noborder: true,
                description: 'Different activities of Audit Assignment Planning and Execution can be managed here',
                menuItems: MenuItemsSchema.AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION_TAKER,
                routerLink: '/aape/aapexecution'
            }
        ]
    },
    reportsanddashboards: { name: 'Reports and Dashboards', panels: [] }
};
export default RoutingSchema;
