import { CommonTable } from '../../../components/commonTable/CommonTable';
import React, { useState } from 'react';
import Schema from '../../../schema/AppSchema';
import ConfirmationModal from '../../../components/CreateOrConfirmModal/CreateOrConfirmModal';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import TopDropdownComponent from '../../../components/topComponents/TopDropdownComponent';
import IsAuthorized from '../../../commons/IsAuthorized';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';
import { GetFinYear } from '../../../commons/commons';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

const fetchAllQuery = Schema.getCompositionsForDisplay;
const deleteOneQuery = Schema.deleteCOACompositionAlongWithCompDetail;
const admYearsQuery = Schema.admFinYears;

const Composition = (props) => {
    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.COA_ITEMS.find((a) => a.routerLink === loc[1]);

    const [columns, setColumns] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [growl, setGrowl] = useState();
    const [statusField, setstatusField] = useState('');
    let finYear = GetFinYear();
    const [state, setState] = useState({
        showModal: false,
        selectedData: {},
        finYear: finYear
    });

    const { data: yearObj, loading: yearLoading } = useQuery(admYearsQuery, {
        variables: {
            where: { structures: { some: { id: { not: { equals: '' } } } } },
            orderBy: { name: 'desc' }
        },
        onCompleted: (res) => {
            setState({ ...state, selectedYear: res && res.admFinYears.length > 0 ? res.admFinYears[0].id : '' });
            if (res?.admFinYears?.length !== 0) {
                fetchCompositions();
            }
        },
        fetchPolicy: 'no-cache'
    });

    const [fetchCompositions, { loading, data }] = useLazyQuery(fetchAllQuery, {
        variables: {
            data: { year_id: yearObj && state.selectedYear }
        },
        onCompleted: () => {
            prepareRowsNcolumns();
        },
        fetchPolicy: 'no-cache'
    });

    const prepareRowsNcolumns = () => {
        let columnsData = [];
        if (data.getCompositionsForDisplay.headers) {
            data.getCompositionsForDisplay.headers.forEach((header) => {
                if (header.header === 'Status') {
                    setstatusField(header.field);
                }
                columnsData.push({
                    header: header.header,
                    frozen: header.field === 'Function' || header.field === 'SubFunction',
                    field:
                        header.field === 'Function' || header.field === 'SubFunction' || header.field === 'Status'
                            ? header.field
                            : header.field + '.name',
                    filter: true,
                    filterPlaceholder: 'Search by ' + header.header,
                    filterElement: null,
                    filterMatchMode: 'contains',
                    body: '',
                    fieldType: header.field !== 'Function' && header.field !== 'SubFunction' && header.header !== 'Status' && 'tooltip',
                    width:
                        header.field === 'Function' || header.field === 'SubFunction'
                            ? '250px'
                            : header.field === 'Status'
                            ? '150px'
                            : '350px'
                });
            });
            columnsData.push({
                header: '',
                field: 'Actions',
                filter: false,
                filterPlaceholder: '',
                filterElement: null,
                body: '',
                width: '100px'
            });
            setColumns(columnsData);
        }
        if (data.getCompositionsForDisplay.data) {
            data.getCompositionsForDisplay.data.forEach((ele) => {
                ele.SubFunction = ele.SubFunction && ele.SubFunction !== '' ? ele.SubFunction : '–';
            });
            setRowsData(data.getCompositionsForDisplay.data);
        }
    };

    const handleEdit = (rowData) => {
        props.history.push('compositions/edit/' + rowData.composition.id);
    };

    const confirmDelete = (rowData) => {
        setState({
            ...state,
            showModal: true,
            selectedData: rowData
        });
    };

    const handleCancel = () => {
        setState({
            ...state,
            showModal: false,
            selectedData: {}
        });
    };

    const [removeComposition] = useMutation(deleteOneQuery, {
        onCompleted: (deletedData) => {
            if (deletedData) {
                growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_COA.Delete });
                setTimeout(() => {
                    setState({ ...state, showModal: false });
                    fetchCompositions();
                }, 1000);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const handleDelete = () => {
        removeComposition({
            variables: {
                where1: { comp_id: { equals: state.selectedData.composition.id } },
                where2: { id: state.selectedData.composition.id }
            }
        });
    };

    const actionTemplate = (rowData) => {
        return (
            state.selectedYear === finYear && (
                <>
                    {(rowData.composition.mappings.length === 0 ||
                        (rowData.composition.mappings.length > 0 && rowData.composition.mappings[0].status !== 'Approved')) && (
                        <IsAuthorized
                            privRequired={Privileges.UPDATE}
                            officeCode={Privileges.OFFICE_CODE}
                            yes={() => (
                                <button
                                    className="a-btn-background-transparent a-icon-container m-r-5 m-l-5"
                                    onClick={() => handleEdit(rowData)}
                                >
                                    <img
                                        title="Edit"
                                        className="pointer"
                                        src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                        alt="Edit Icon"
                                        height="18px"
                                    />
                                </button>
                            )}
                        ></IsAuthorized>
                    )}
                    {rowData[statusField] === 'Draft' && (
                        <IsAuthorized
                            privRequired={Privileges.DELETE}
                            officeCode={Privileges.OFFICE_CODE}
                            yes={() => (
                                <button
                                    className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                    onClick={() => confirmDelete(rowData)}
                                >
                                    <img
                                        className="pointer"
                                        height="18px"
                                        title="Delete"
                                        src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                        alt="Delete Icon"
                                    />
                                </button>
                            )}
                        ></IsAuthorized>
                    )}
                </>
            )
        );
    };

    const onClickView = (rowData) => {
        if (rowData[statusField] === 'Draft') {
            props.history.push('/coa/compositions/edit/' + rowData.composition.id);
            return;
        }
        props.history.push('/coa/compositions/view/' + rowData.composition.id);
    };

    const viewFmisFunction = (rowData) => {
        return (
            <span className="pointer icon-primary" onClick={() => onClickView(rowData)}>
                <EllipsisWithTooltip placement="bottom">
                    <p className="m-0 p-ellipsis">{rowData[columns[0].field]}</p>
                </EllipsisWithTooltip>
            </span>
        );
    };

    const viewFmisSubFunction = (rowData) => {
        return (
            <EllipsisWithTooltip placement="bottom">
                <p className="m-0 p-ellipsis">{rowData[columns[1].field]}</p>
            </EllipsisWithTooltip>
        );
    };

    const setSelectedYear = (e) => {
        setState({ ...state, selectedYear: e });
    };

    const disableAdd = () => {
        let selectedYear = yearObj.admFinYears.filter((admYear) => {
            return state.selectedYear === admYear.id;
        });
        if (selectedYear.length > 0) {
            return selectedYear[0].name !== finYear;
        }
        return true;
    };

    const propsObj = {
        title: 'COA Compositions',
        type: 'COA Composition',
        dataKey: 'admCoaCompositions',
        topGridProps: {
            buttonLabel: 'Define Composition',
            disableAdd: !yearLoading && yearObj ? disableAdd() : true,
            selectedYear: state.selectedYear,
            yearObj: yearObj,
            setSelectedYear: setSelectedYear,
            createPriv: Privileges.CREATE,
            officeCode: Privileges.OFFICE_CODE
        },
        disableClone: !yearLoading && yearObj && (yearObj.admFinYears.length <= 1 || rowsData.length > 0),
        createRouter: '/coa/compositions/create',
        cloneRouter: 'compositions/clone',
        fetchAllQuery: Schema.admCoaCompositions,
        createOneQuery: Schema.createMultiComposition,
        updateOneQuery: Schema.updateOneAdmCOAComposition,
        deleteOneQuery: Schema.deleteOneAdmCOAComposition,
        columns: columns.length > 0 && columns,
        customizedActionTemplate: actionTemplate,
        customizedRows: rowsData,
        FieldsToApplyColor: [statusField],
        emptyMessage: 'COA Compositions are yet to be created',
        noBottomPadding: true,
        frozenWidth: '500px',
        resizableColumns: true,
        scrollable: true
    };

    if (!loading && columns.length > 0) {
        columns[0].body = viewFmisFunction;
        columns[1].body = viewFmisSubFunction;
    }

    if (yearLoading || loading) return <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />;

    const CompositionHoc = CommonTable(propsObj, ConfirmationModal, TopDropdownComponent);

    return (
        <div className="h-100">
            {state.showModal && (
                <DeleteConfirmModal
                    showModal={state.showModal}
                    handleCancel={handleCancel}
                    type={'Composition'}
                    name={
                        'Composition : Function: ' +
                        state.selectedData.Function +
                        (state.selectedData.SubFunction ? ', Sub Function: ' + state.selectedData.SubFunction : '')
                    }
                    handleDelete={handleDelete}
                    showSpinner={true}
                />
            )}
            <Toast ref={(el) => setGrowl(el)} />
            {!loading && !yearLoading && !state.selectedYear ? <p>There are no approved COA Structures</p> : <CompositionHoc />}
        </div>
    );
};

export default Composition;
