/* eslint-disable max-depth */
import React, { useState } from 'react';
import TextAreaComponent from '../../../components/InputComponent/TextAreaComponent';
import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody } from 'appkit-react';
import './contract.scss';
import GeneralInformationForm from './GeneralInformationForm';
import ContractDetailsForm from './ContractDetails/ContractDetailsForm';
import BankGuaranteeForm from './BankGuaranteeForm';
import PaymentTermsForm from './PaymentTermsForm';
import { Dropdown } from 'primereact/dropdown';
import { useQuery, useLazyQuery } from '@apollo/client';
import Schema from '../../../schema/AppSchema';
import ContractActions from './ContractActions';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import ContactModal from './ContactModal';
import { contractClient, eventClient, adminClient } from '../../../apollo';
import { getDate, serverDate, uniqueId } from '../../../util';
import FDataTable from '../../../components/fDataTable/FDataTable';
import TableSchema from '../CMTableSchema';
import { getFinancialYears, getFormTypeAndRecordId } from '../../../services/commonService';
import * as FormValidation from '../../../Validation';
import { Sidebar } from 'primereact/sidebar';
import WorklowSidebarContent from '../../../components/WorklowSidebarContent/WorklowSidebarContent';
import UploadDocuments from '../../../commons/UploadDocuments';

const CreateContract = (props) => {
    const APP_CONFIGURATION_VALUES = JSON.parse(localStorage.getItem('APP_CONFIGURATION_VALUES'));
    const formTypeAndId = getFormTypeAndRecordId(props.location.pathname);
    const supplierId = props.match?.params.supplierId;
    let formType = formTypeAndId['formType'];
    let recordID = props.match?.params.id;
    let isDraft = formTypeAndId['isDraft'];
    let transactionID = '';
    transactionID = localStorage.getItem('transaction_id');
    const [mode, setMode] = useState(formType);
    const [errors, setErrors] = useState(false);
    const [islatest, setIsLatest] = useState(false);
    const [entityID, setEntityId] = useState('');
    const [recID, setID] = useState('');

    let acc =
        mode === 'view'
            ? ['General_Contract_Information', 'Contract_Details', 'Performance_Bank_Guarantee', 'Payment_Terms', 'Documents_Upload']
            : [];
    const [state, setState] = useState({
        suppliersList: [],
        supplierContactList: [],
        bankList: [],
        contractDetails: {},
        activeAccordion: acc,
        loading: true,
        contactsModal: false,
        contarctNo: '',
        showWorkflowStatus: false,
        listToShowComment: []
    });
    const [growl, setGrowl] = useState();
    let genCntrctObj = {
        sanct_agency_id: '',
        impl_agency_id: '',
        signing_date: serverDate(),
        closing_date: '',
        govt_ref: '',
        project: '',
        adm_ref: '',
        tech_ref: '',
        proc_ref: '',
        ccy:
            APP_CONFIGURATION_VALUES && APP_CONFIGURATION_VALUES.length > 0
                ? APP_CONFIGURATION_VALUES.filter((configData) => configData.code === 'CURRENCY')[0].value1
                : 'INR'
    };
    let cntrctDetaislObj = {
        contracType: 'supplyCat',
        rows: [],
        totals: {
            cntrct_fin_sanc: {
                totalAmount: 0
            },
            cntrct_val_inc_tax: '',
            cntrct_val_exc_tax: ''
        }
    };
    const [formState, setFormState] = useState({
        year_id: '',
        contractName: '',
        supplier: '',
        supplierContact: '',
        description: '',
        General_Contract_Information: genCntrctObj,
        Contract_Details: cntrctDetaislObj,
        Performance_Bank_Guarantee: {
            rows: []
        },
        Payment_Terms: {
            paymentMode: ''
        }
    });

    const [deductionIDS, setDeductionIDS] = useState([]);
    const [loader, setLoader] = useState(false);
    const [formObj, setFormObj] = useState();
    const [cntrContarct, setCntrContract] = useState();
    const [showUpdateInfo, setUpdateInfo] = useState('');
    const [advanceIDS, setAdvanceIDS] = useState([]);
    const [inActiveAccs, setInActiveAccs] = useState([]);
    const [docList, setDocumentsList] = useState([]);

    let admisVar = '';
    let gte = new Date(serverDate().setHours(0, 0, 0, 0));
    let lte = new Date(serverDate().setHours(23, 0, 0, 0));

    const { data: supplierData } = useQuery(Schema.createContactSuppliers, {
        client: contractClient,
        variables: {
            where: { from: { lte: lte }, to: { gte: gte }, is_effective: { equals: true } },
            cntrwhere: { is_effective: { equals: true } }
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.cntrSuppliers) {
                getAdminDetails();
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [getAdminDetails, { data: adminData }] = useLazyQuery(Schema.createContractForm, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.appEnums?.length > 0) {
                admisVar = data.appEnums[0].enum_value;
            }
            if (data?.current_fin_year?.id && (mode !== 'edit' || mode !== 'view')) {
                formState.year_id = data.current_fin_year.id;
            }
            const suppliersList = supplierData.cntrSuppliers.filter((a) => (a.nameNumber = a.name + ' (' + a.ref + ')'));
            let cntrDetails = { contractDetails: {} };
            if (supplierId) {
                const supplier = suppliersList?.filter((supplierL) => supplierL.id === supplierId);
                cntrDetails = handleSuppChange(supplier[0], true);
            }

            setState({
                ...state,
                bankList: data?.banks || [],
                suppliersList: suppliersList,
                contractDetails: { admis_var: admisVar, supply_catagories: cntrDetails.contractDetails?.supply_catagories },
                loading: mode === 'edit' || mode === 'view',
                supplierContactList: cntrDetails.arr
            });
            if (mode === 'edit' || mode === 'view') {
                if (isDraft) getDraftData();
                else getContactByID();
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const setData = (docs) => {
        setDocumentsList(docs);
    };

    const [getDeductions] = useLazyQuery(Schema.deductionIns, {
        // to get deductions by ids from selcted contract dedcutions to check if latest or not
        variables: { where: { id: { in: deductionIDS } } },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.deductions.length > 0) {
                let prepData = JSON.parse(JSON.stringify(data.deductions));
                prepData.forEach((a) => {
                    a.amount = a.value;
                    delete a.value;
                });
                if (cntrContarct?.on_total_val) {
                    let deductionRows = isDraft
                        ? cntrContarct?.more_data?.create?.deductions?.create
                        : cntrContarct?.more_data[0]?.deductions;
                    let obj = prepareDeductions(prepData, deductionRows);
                    formObj.Contract_Details.moreDetailsObj.Other_Deductions = {
                        rows: obj.rows,
                        totalAmount: obj.totalAmount
                    };
                } else {
                    let supplyRows = isDraft ? cntrContarct.supplies?.create : cntrContarct.supplies;
                    supplyRows.forEach((a, index) => {
                        let dedRows = isDraft ? a.more_data?.create?.deductions?.create : a.more_data[0]?.deductions;

                        let obj = prepareDeductions(prepData, dedRows);
                        formObj.Contract_Details.rows[index].moreDetailsObj.Other_Deductions = {
                            rows: obj.rows,
                            totalAmount: obj.totalAmount
                        };
                    });
                }
            }
            setFormObj(formObj);
            getAdvances();
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    useQuery(Schema.getDocumentMappings, {
        client: adminClient,
        fetchPolicy: 'no-cache',
        skip: isDraft,
        variables: { function_code: 'CNTR_CONTRACT_CREATION', source_id: recordID ? recordID : '' },
        onCompleted: (data) => {
            if (data.getDocumentMappings) {
                data.getDocumentMappings.forEach((val) => {
                    let ref = val?.ref && val?.ref?.includes('_') ? val?.ref + ' - ' : '';
                    val['name_ref'] = ref + val['name'];
                    val['description'] = val?.document?.description;
                    val['category_id'] = val?.mapping?.category_id || val?.document?.category_id;
                    val['attachments'] = val?.document?.attachments;
                    val['document_id'] = val?.document?.id;
                    val['source_id'] = val?.document?.source_id;
                    val['fileType'] = val?.file_type?.replaceAll(',', ',.');
                    val['document_ref'] = val?.document?.ref;
                    val['unique_Id'] = uniqueId(5);
                    if (val?.attachments && val?.attachments?.length > 0 && val?.attachments[0].id) {
                        val['fileName'] = val?.attachments[0]?.name;
                        val['file_id'] = val?.attachments[0]?.file_id;
                        val['is_rejected'] = val?.attachments[0]?.is_rejected;
                        val['remarks'] = val?.attachments[0]?.remarks;
                        val['local_date'] = getDate(val?.attachments[0]?.version_date);
                        val['version_user'] = val?.attachments[0]?.version_user;
                        val['attachment_id'] = val?.attachments[0]?.id;
                    } else val['is_rejected'] = false;
                    val['is_mandatory'] = val?.mapping?.is_mandatory ? val?.mapping?.is_mandatory : false;
                });
                setDocumentsList(data.getDocumentMappings);
            }
        },

    });

    const prepareDeductions = (prepData, cntrData) => {
        // set deductions if not latest and prepare data
        let list = [];
        let totalAmount = 0;
        prepData.forEach((ded, i) => {
            let typeval = ded?.type + (ded?.type === 'Percentage' ? '(' + ded.amount + ')' : '');
            let findObj = cntrData.find((a) => a.deduction_id === ded.id);
            let obj = {
                slno: i + 1,
                deduction: ded || {},
                dedType: typeval,
                amount: findObj?.amount ? findObj.amount : ded.amount?.toString(),
                islatest: ded?.is_latest
            };
            if (cntrData && cntrData.find((a) => a.deduction_id === ded.id)) {
                if (!isNaN(parseFloat(obj.amount))) totalAmount = totalAmount + parseFloat(obj.amount);
                list.push(obj);
            }
        });
        return {
            rows: list,
            totalAmount: totalAmount
        };
    };

    const [getAdvances] = useLazyQuery(Schema.advIns, {
        // to get advances by ids from selcted contract advances to check if latest or not
        variables: { where: { id: { in: advanceIDS } } },
        client: contractClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data?.advances?.length > 0) {
                let prepData = JSON.parse(JSON.stringify(data.advances));
                prepData.forEach((a) => {
                    a.amount = a.value;
                    delete a.value;
                });
                if (cntrContarct?.on_total_val) {
                    let advRows = isDraft ? cntrContarct?.more_data?.create?.advances?.create : cntrContarct.more_data[0].advances;
                    let obj = prepareAdvances(prepData, advRows);
                    let payObj = preparePaySchedules(data.advances, formObj.Contract_Details.moreDetailsObj.Payment_Schedule);
                    formObj.Contract_Details.moreDetailsObj.Advance = {
                        rows: obj.rows
                    };
                    formObj.Contract_Details.moreDetailsObj.Payment_Schedule = {
                        rows: payObj.rows
                    };
                } else {
                    let supplyRows = isDraft ? cntrContarct.supplies?.create : cntrContarct.supplies;

                    supplyRows.forEach((a, index) => {
                        let advRows = isDraft ? a.more_data?.create?.advances?.create : a.more_data[0].advances;
                        let obj = prepareAdvances(prepData, advRows);
                        let payObj = preparePaySchedules(
                            data.advances,
                            formObj.Contract_Details.rows[index].moreDetailsObj.Payment_Schedule
                        );
                        formObj.Contract_Details.rows[index].moreDetailsObj.Advance = {
                            rows: obj.rows
                        };
                        formObj.Contract_Details.rows[index].moreDetailsObj.Payment_Schedule = {
                            rows: payObj.rows
                        };
                    });
                }
            }
            setFormState(formObj);
            let val = checkValidations(formObj);
            setUpdateInfo(val);
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const preparePaySchedules = (advances, payschedules) => {
        // set payment milestone name if advance is latest or not
        if (payschedules && advances.length > 0) {
            let list = JSON.parse(JSON.stringify(payschedules));
            list.rows.forEach((a) => {
                let val = advances.find((ad) => ad.id === a.pay_milestone);
                if (val) a.pay_milestone = val.name;
            });
            return list;
        } else {
            return [];
        }
    };

    const prepareAdvances = (prepData, cntrData) => {
        // prepare advances list based on islatest flag
        let list = [];
        prepData.forEach((adv) => {
            let findObj = cntrData.find((a) => (isDraft ? a.advance?.connect?.id : a.advance_id) === adv.id);
            if (findObj) {
                let obj = {
                    adv_type: adv || {},
                    adv_rate: findObj.rate?.toString(),
                    adv_amount: findObj.amount?.toString(),
                    hoa: findObj.hoa,
                    adv_rec_mthd: findObj.recovery_method,
                    islatest: adv.is_latest
                };
                list.push(obj);
            }
        });
        return {
            rows: list
        };
    };

    const checkValidations = (dataObject, type, arrs) => {
        if (type === 'Performance_Bank_Guarantee') {
            let inActiceAccs = arrs.rows.filter((a) => !a.bankName?.is_active);
            setInActiveAccs(inActiceAccs);
        }
        let suplCats = [];
        if (dataObject?.Contract_Details?.rows?.length > 0) {
            // financial sanction years greater than closing date
            for (let i = 0; i < dataObject.Contract_Details.rows.length; i++) {
                const element = dataObject.Contract_Details.rows[i];
                if (element.cntrct_fin_sanc?.finRows?.length > 0 && dataObject.General_Contract_Information?.closing_date) {
                    let years = getFinancialYears(
                        dataObject.General_Contract_Information?.signing_date,
                        dataObject.General_Contract_Information?.closing_date
                    );
                    let exists = element.cntrct_fin_sanc.finRows.some((a) => years.indexOf(a.year) === -1);
                    if (exists) {
                        element.cntrct_fin_sanc.finRows.forEach((a) => {
                            if (years.indexOf(a.year) === -1) suplCats.push(element.sup_cat?.name + '(' + element.hoa + ')');
                        });
                    }
                }
            }
            if (suplCats.length > 0)
                return 'Closing date is modified, kindly update Financial Sanction for Supply Categories(HOA) : ' + suplCats.toString();
        }

        let closeDate = new Date(new Date(dataObject.General_Contract_Information.closing_date).setHours(5, 30, 0, 0));
        if (dataObject.Performance_Bank_Guarantee?.rows?.length > 0) {
            // closing date validation with bank gaurantees
            for (let i = 0; i < dataObject.Performance_Bank_Guarantee.rows.length; i++) {
                const element = dataObject.Performance_Bank_Guarantee.rows[i];
                let expDate = new Date(new Date(element.expiryDate).setHours(5, 30, 0, 0));
                if (expDate < closeDate) return 'Closing date is modified, kindly update Performance Bank Guarantee expiry date';
            }
        }

        if (dataObject?.Contract_Details?.contracType === 'supplyCat') {
            let dedChanged = [];
            let advChanged = [];
            let payDueDate = [];
            for (let i = 0; i < dataObject.Contract_Details.rows.length; i++) {
                const element = dataObject.Contract_Details.rows[i];
                if (element?.moreDetailsObj?.Advance?.rows?.length > 0) {
                    // check for advances are latest or not
                    element.moreDetailsObj.Advance.rows.forEach((a) => {
                        if (!a.islatest) advChanged.push(a.adv_type?.name);
                    });
                }
                if (advChanged.length === 1)
                    return (
                        'The advance ' + advChanged.toString() + ' is modified in masters, kindly update the details specific to contract.'
                    );
                if (advChanged.length > 1)
                    return (
                        'The advance(s) ' +
                        advChanged.toString() +
                        ' are modified in masters, kindly update the details specific to contract.'
                    );

                if (element?.moreDetailsObj?.Other_Deductions?.rows?.length > 0) {
                    // check for deductions are latest or not
                    element.moreDetailsObj.Other_Deductions.rows.forEach((a) => {
                        if (!a.islatest) dedChanged.push(a.deduction?.name);
                    });
                }
                if (dedChanged.length === 1)
                    return (
                        'The deduction ' +
                        dedChanged.toString() +
                        ' is modified in masters, kindly update the details specific to contract.'
                    );
                if (dedChanged.length > 1)
                    return (
                        'The deduction(s) ' +
                        dedChanged.toString() +
                        ' are modified in masters, kindly update the details specific to contract.'
                    );

                if (element?.moreDetailsObj?.Payment_Schedule?.rows?.length > 0) {
                    // check for payment schedule due date are according to closing date
                    let incAmt = 0;
                    element.moreDetailsObj.Payment_Schedule.rows.forEach((a) => {
                        let dueDate = new Date(new Date(a.due_date).setHours(5, 30, 0, 0));
                        if (dueDate > closeDate)
                            payDueDate.push(`${element.sup_cat?.name}` + '('`${element.hoa}` + ')' + ' - '`${a.pay_milestone}`);
                        if (!a.type || a.type !== 'advance') {
                            incAmt = incAmt + parseFloat(a.amt_inc_tax);
                        }
                    });
                    if (parseFloat(element.cntrct_val_inc_tax) < parseFloat(incAmt))
                        return (
                            'The ' +
                            element.sup_cat?.name +
                            '('`${element.hoa}``${')'}` +
                            ' contract Value Incl of Taxes is modified, kindly update the details in Payment Schedule'
                        );
                    let netPay = element.moreDetailsObj.Payment_Schedule.rows.some((a) => a.net_pay_amt < 0);
                    if (netPay) return 'Net Payment Amount should not be a negative value, kindly update the details in Payment Schedule';
                }
                if (dedChanged.length === 1)
                    return (
                        'The deduction ' +
                        dedChanged.toString() +
                        ' is modified in masters, kindly update the details specific to contract.'
                    );
                if (dedChanged.length > 1)
                    return (
                        'The deduction(s) ' +
                        dedChanged.toString() +
                        ' are modified in masters, kindly update the details specific to contract.'
                    );
            }
            if (payDueDate.length > 0)
                return 'Closing date is modified, kindly update Payment Milestone for Supply Category(HOA) : ' + payDueDate.toString();
        }
        if (dataObject?.Contract_Details?.contracType === 'totalCntrct') {
            let dedChanged = [];
            let advChanged = [];
            let payDueDate = [];

            if (dataObject?.Contract_Details?.moreDetailsObj?.Advance?.rows?.length > 0) {
                // check for advances are latest or not
                dataObject.Contract_Details.moreDetailsObj.Advance.rows.forEach((a) => {
                    if (!a.islatest) advChanged.push(a.adv_type?.name);
                });
            }
            if (advChanged.length === 1)
                return 'The advance ' + advChanged.toString() + ' is modified in masters, kindly update the details specific to contract.';
            if (advChanged.length > 1)
                return (
                    'The advance(s) ' + advChanged.toString() + ' are modified in masters, kindly update the details specific to contract.'
                );

            if (dataObject?.Contract_Details?.moreDetailsObj?.Other_Deductions?.rows?.length > 0) {
                // check for deductions are latest or not
                dataObject.Contract_Details.moreDetailsObj.Other_Deductions.rows.forEach((a) => {
                    if (!a.islatest) dedChanged.push(a.deduction?.name);
                });
            }
            if (dedChanged.length === 1)
                return (
                    'The deduction ' + dedChanged.toString() + ' is modified in masters, kindly update the details specific to contract.'
                );
            if (dedChanged.length > 1)
                return (
                    'The deduction(s) ' +
                    dedChanged.toString() +
                    ' are modified in masters, kindly update the details specific to contract.'
                );

            if (dataObject?.Contract_Details?.moreDetailsObj?.Payment_Schedule?.rows?.length > 0) {
                // check for payment schedule due date are according to closing date
                let incAmt = 0;
                dataObject.Contract_Details.moreDetailsObj.Payment_Schedule.rows.forEach((a) => {
                    let dueDate = new Date(new Date(a.due_date).setHours(5, 30, 0, 0));
                    if (dueDate > closeDate) payDueDate.push(a.pay_milestone);
                    if (!a.type || a.type !== 'advance') {
                        incAmt = incAmt + parseFloat(a.amt_inc_tax);
                    }
                });
                if (parseFloat(dataObject.Contract_Details?.totals?.cntrct_val_inc_tax) < parseFloat(incAmt))
                    return 'The contract value Incl of Taxes is modified, kindly update the details in Payment Schedule';
                let netPay = dataObject.Contract_Details.moreDetailsObj.Payment_Schedule.rows.some((a) => a.net_pay_amt < 0);
                if (netPay) return 'Net Payment Amount should not be a negative value, kindly update the details in Payment Schedule';
            }
            if (payDueDate.length > 0) return 'Closing date is modified, kindly update Payment Milestone : ' + payDueDate.toString();
        }
        return '';
    };

    const prepareSupplierObj = (data) => {
        if (isDraft) {
            return state.suppliersList?.find((a) => a.id === data?.connect?.id) || {};
        }
        return state.suppliersList?.find((a) => a.id === data.id) || {};
    };

    const prepareContactObj = (contactObj, data) => {
        if (isDraft) {
            return contactObj.arr?.find((a) => a.id === data?.connect?.id);
        }
        return contactObj.arr?.find((a) => a.id === data?.id);
    };

    const prepareBabkData = (data) => {
        // prepare bank rows
        if (data?.length > 0) {
            data.forEach((a) => {
                a.bankName = state.bankList?.find((bank) => bank.id === a.bank_id) || {};
                a.amountBGaurnte = a.amount?.toString();
                a.bGauNo = a.ref?.toString();
                a.expiryDate = getDate(a.expiry);
            });
        }

        let inActiceAccs = data.filter((a) => !a.bankName?.is_active);
        setInActiveAccs(inActiceAccs);
        return data;
    };

    const getFinRows = (data) => {
        // prepare finacial sanction rows for each contract details
        let list = [];
        let totalAmount = 0;
        if (data?.length > 0) {
            data.forEach((a, ind) => {
                totalAmount = totalAmount + a.amount;
                let obj = {
                    slno: ind + 1,
                    year: a.year_id,
                    fin_sanc_ref: a.ref?.toString(),
                    fin_sanc_inr: a.amount?.toString()
                };
                list.push(obj);
            });
        }
        return {
            rows: list,
            totalAmount: totalAmount
        };
    };

    const getAdvDetails = (data) => {
        let list = [];
        if (data?.length > 0) {
            data.forEach((a, i) => {
                let advObj = a.advance;
                if (isDraft) {
                    advObj = supplierData?.advances.find((adObj) => adObj.id === a.advance?.connect?.id);
                }
                let obj = {
                    slno: i + 1,
                    adv_type: advObj?.name,
                    edit: a.is_edited,
                    enableEdit: false,
                    adv_inc_amount: a.amount,
                    adv_rec_mthd: a.is_edited ? 'Manual' : 'At Advance Rate',
                    is_edited: a.is_edited,
                    id: advObj?.id
                };
                list.push(obj);
            });
        }
        return list;
    };

    const getDeductionDetails = (data) => {
        let list = [];
        if (data?.length > 0) {
            data.forEach((a, i) => {
                let dedObj = a.deduction;
                if (isDraft) {
                    dedObj = adminData?.deductions.find((adObj) => adObj.id === a.deduction_id);
                }
                let typeval = dedObj?.type + (dedObj?.type === 'Percentage' ? '(' + dedObj.amount + ')' : '');
                let obj = {
                    slno: i + 1,
                    deduction: dedObj || {},
                    dedType: typeval,
                    pay_amount: a.amount,
                    remarks: a.remarks,
                    edit: a.is_edited,
                    enableEdit: false,
                    is_edited: a.is_edited
                };
                list.push(obj);
            });
        }
        return list;
    };

    const getMilestoneName = (item) => {
        return supplierData.advances?.find((b) => b.id === item.mile_stone)?.name || item.mile_stone;
    };

    const getPayScheduledData = (data) => {
        let list = [];
        if (data?.length > 0) {
            data.forEach((a, i) => {
                let advRows = isDraft ? a?.advance_recoveries?.create : a.advance_recoveries;
                let dedRows = isDraft ? a?.deduction_details?.create : a.deduction_details;

                let obj = {
                    slno: i + 1,
                    pay_milestone: a.adv_recov_amount === -1 ? getMilestoneName(a) : a.mile_stone,
                    hoa: a.hoa,
                    due_date: a.due_date,
                    amt_exc_tax: a.amount_excl_tax?.toString(),
                    amt_inc_tax: a.amount_incl_tax?.toString(),
                    adavce_recovery: a.adv_recov_amount === -1 ? 'NA' : a.adv_recov_amount,
                    security_depst_dedct: a.sec_dep_amount === -1 ? 'NA' : a.sec_dep_amount,
                    other_deduction: a.other_deduction_amount === -1 ? 'NA' : a.other_deduction_amount,
                    net_pay_amt: a.net_pay_amount,
                    advance_recoveries: getAdvDetails(advRows),
                    deduction_details: getDeductionDetails(dedRows),
                    delete: true
                };
                if (a.adv_recov_amount === -1) {
                    obj['delete'] = false;
                    obj['type'] = 'advance';
                    obj['advance_id'] = supplierData.advances?.find((b) => b.id === a.mile_stone)?.id || '';
                }
                list.push(obj);
            });
        }
        return {
            rows: list
        };
    };

    const getMoreDetails = (data) => {
        let payScheduleRows = isDraft ? data?.pay_schedules?.create : data?.pay_schedules;
        let paymentScheduleData = getPayScheduledData(payScheduleRows);

        return {
            Advance: {
                rows: []
            },
            Security_Deposit_Deduction: {
                scc_depo_deduc_rate:
                    supplierData?.cntrDeductionRates?.find(
                        (a) => a.id === (isDraft ? data?.sec_deposit?.connect?.id : data?.sec_deposit?.id)
                    ) || {},
                scc_depo_refund_time: data?.refund_in_months?.toString()
            },
            Other_Deductions: {
                rows: [],
                totalAmount: ''
            },
            Payment_Schedule: {
                rows: paymentScheduleData.rows
            }
        };
    };

    const preparContractDetails = (data, supplydata, responseObj) => {
        let list = [];
        let cntrctValIncTax = 0;
        let cntrctValExcTax = 0;
        let finalAmount = 0;
        if (data?.length > 0) {
            data.forEach((a, index) => {
                cntrctValIncTax = cntrctValIncTax + a.amount_incl_tax;
                cntrctValExcTax = cntrctValExcTax + a.amount_excl_tax;
                let finsanc = getFinRows(isDraft ? a.fin_sanctions?.create : a.fin_sanctions);
                finalAmount = finalAmount + finsanc.totalAmount;
                let obj = {
                    id: a.id,
                    slno: index + 1,
                    admissable_var: a.variance?.toString(),
                    cntrct_val_inc_tax: a.amount_incl_tax?.toString(),
                    cntrct_val_exc_tax: a.amount_excl_tax?.toString(),
                    hoa: a.hoa,
                    sup_cat:
                        supplydata?.supply_catagories?.find(
                            (b) => b.id === (isDraft ? a.supply_category?.connect?.id : a.supply_category?.id)
                        ) || {},
                    cntrct_fin_sanc: {
                        finRows: finsanc.rows || [],
                        totalAmount: finsanc.totalAmount || ''
                    },
                    moreDetails: !responseObj.on_total_val
                };
                if (!responseObj.on_total_val) {
                    let moreData = isDraft ? a.more_data?.create : a.more_data.length > 0 ? a.more_data[0] : {};
                    let dedRows = isDraft ? moreData?.deductions?.create : moreData?.deductions;
                    let advRows = isDraft ? moreData?.advances?.create : moreData?.advances;
                    // to set deduction ids
                    if (dedRows?.length > 0) {
                        let ids = dedRows.map((ded) => ded.deduction_id).filter((ad) => ad !== null && ad !== '' && ad !== undefined);
                        deductionIDS.push(...ids);
                        let vals = deductionIDS.filter((item, i, ar) => ar.indexOf(item) === i);
                        setDeductionIDS(vals);
                    }
                    // to set advance ids
                    if (advRows?.length > 0) {
                        let aids = advRows
                            .map((adv) => (isDraft ? adv.advance?.connect?.id : adv.advance_id))
                            .filter((ad) => ad !== null && ad !== '' && ad !== undefined);
                        advanceIDS.push(...aids);
                        let aVals = advanceIDS.filter((item, i, ar) => ar.indexOf(item) === i);
                        setAdvanceIDS(aVals);
                    }
                    obj['moreDetailsObj'] = getMoreDetails(moreData);
                }
                list.push(obj);
            });
        }
        return {
            rows: list,
            cntrct_val_inc_tax: cntrctValIncTax || 0,
            cntrct_val_exc_tax: cntrctValExcTax || 0,
            cntrct_fin_sanc: {
                totalAmount: finalAmount || 0
            }
        };
    };

    const [getDraftData] = useLazyQuery(Schema.entityRecord, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: {
            where: { id: recordID }
        },
        onCompleted: (data) => {
            if (data?.entityRecord?.record) {
                setEntityId(data?.entityRecord?.record?.entity_id);
                prepareData(data.entityRecord.record);
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [getContactByID] = useLazyQuery(Schema.getContractByID, {
        variables: { where: { id: recordID } },
        client: contractClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.cntrContract) {
                prepareData(data.cntrContract);
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const prepareData = (response) => {
        let responseObj = response;
        setCntrContract(response);
        setIsLatest(responseObj?.is_latest);
        if (response.id) setID(response.id);

        let supplierObj = prepareSupplierObj(responseObj.supplier) || {};
        let contactObj = getContractDetails(supplierObj) || {};
        let selContact = prepareContactObj(contactObj, responseObj.supplier_contact) || {};
        setState({
            ...state,
            contractDetails: contactObj.contractDetails,
            supplierContactList: contactObj.arr,
            contarctNo: responseObj.ref
        });
        let contractRows = isDraft ? responseObj.supplies?.create : responseObj.supplies;
        let contrctDetails = preparContractDetails(contractRows, contactObj.contractDetails, responseObj);

        let bankRows = isDraft ? responseObj?.bank_guarantees?.create : responseObj?.bank_guarantees;
        let obj = {
            ...formState,
            year_id: responseObj.year_id,
            contractName: responseObj.name,
            description: responseObj.description,
            supplier: supplierObj,
            supplierContact: selContact,
            version_no: parseInt(responseObj.version_no),
            version_user: responseObj.version_user,
            General_Contract_Information: {
                sanct_agency_id: responseObj.sanct_agency_id,
                impl_agency_id: responseObj.impl_agency_id,
                signing_date: new Date(responseObj.signing_date),
                closing_date: responseObj.closing_date ? new Date(responseObj.closing_date) : '',
                govt_ref: responseObj.govt_ref,
                project: isDraft ? responseObj?.project?.connect?.id : responseObj.project_id,
                adm_ref: responseObj.adm_ref,
                tech_ref: responseObj.tech_ref,
                proc_ref: responseObj.proc_ref,
                ccy: responseObj.ccy
            },
            Contract_Details: {
                contracType: responseObj.on_total_val ? 'totalCntrct' : 'supplyCat',
                rows: contrctDetails.rows,
                totals: {
                    cntrct_fin_sanc: contrctDetails.cntrct_fin_sanc,
                    cntrct_val_inc_tax: contrctDetails.cntrct_val_inc_tax,
                    cntrct_val_exc_tax: contrctDetails.cntrct_val_exc_tax
                }
            },
            Performance_Bank_Guarantee: {
                rows: prepareBabkData(bankRows || [])
            },
            Payment_Terms: {
                paymentTerm: responseObj.pay_term,
                paymentMode: responseObj.pay_method
            }
        };
        if (responseObj.on_total_val) {
            let moreData = isDraft ? responseObj.more_data?.create : responseObj.more_data.length > 0 ? responseObj.more_data[0] : {};
            let dedRows = isDraft ? moreData.deductions?.create : moreData.deductions;
            let advRows = isDraft ? moreData.advances?.create : moreData.advances;

            // set all deduction ids
            let ids = dedRows.map((ded) => ded.deduction_id).filter((ad) => ad !== null && ad !== '' && ad !== undefined);
            deductionIDS.push(...ids);
            setDeductionIDS(deductionIDS);
            // set all adavnce ids
            let aids = advRows
                .map((adv) => (isDraft ? adv.advance?.connect?.id : adv.advance_id))
                .filter((ad) => ad !== null && ad !== '' && ad !== undefined);
            advanceIDS.push(...aids);
            setAdvanceIDS(advanceIDS);
            obj.Contract_Details.moreDetailsObj = getMoreDetails(moreData);
        }
        if (response?.documents) setDocumentsList(response?.documents);
        setFormObj(obj);
        getDeductions();
    };

    const handleChange = (ev, key) => {
        if (key === 'contractName' || key === 'description') {
            validateFeild(ev, key);
        }
        setFormState({
            ...formState,
            [key]: ev
        });
    };
    const validateFeild = (ev, key) => {
        let err = {};

        const text = fields.filter((obj) => {
            return key === obj['key'];
        });

        err = FormValidation.validateFileld(text[0].validators, key, ev);
        let preverrors = { ...errors, ...err };
        setErrors(preverrors);
    };

    const fields = [
        { key: 'contractName', validators: 'required,notOnlyDigits' },
        { key: 'description', validators: 'required' }
    ];

    const handleLoader = (status) => {
        if (status) {
            setState({ ...state, activeAccordion: [], loading: true });
            return;
        }
        setState({ ...state, loading: status });
    };

    const handleCancel = () => {
        /*
         * if (mode === 'create') {
         *     handleGoBack();
         * } else {
         *     setMode('view');
         *     setState({
         *         ...state,
         *         activeAccordion: ['General_Contract_Information', 'Contract_Details', 'Performance_Bank_Guarantee', 'Payment_Terms'],
         *         loading: false
         *     });
         * }
         */
        handleGoBack();
    };

    const handleGoBack = () => {
        localStorage.setItem('transaction_id', '');
        props.history.goBack();
    };

    const showGrowl = (msg, type) => {
        growl.show({ life: 6000, severity: type, summary: msg });
        setTimeout(() => {
            if (type !== 'error') handleCancel();
        }, 1000);
    };

    const handleAccordionChange = (e) => {
        let val = state.activeAccordion.find((a) => a === e);
        if (val)
            state.activeAccordion = state.activeAccordion.filter((a) => {
                return a !== e;
            });
        else state.activeAccordion.push(e);
        setState({ ...state, activeAccordion: state.activeAccordion });
    };

    const saveFormsData = (type, data) => {
        let obj = JSON.parse(JSON.stringify(formState));
        obj[type] = data;
        let val = checkValidations(obj, type, data);
        setUpdateInfo(val);

        setFormState({
            ...formState,
            [type]: data
        });
    };

    const getContractDetails = (e) => {
        let arr = e.contacts;
        if (arr?.length > 0) {
            arr.forEach((a) => {
                a.contact.name =
                    (a.contact.salutation ? a.contact.salutation + ' ' : '') +
                    (a.contact.first_name ? a.contact.first_name + ' ' : '') +
                    (a.contact.middle_name ? a.contact.middle_name : '') +
                    (a.contact.last_name ? a.contact.last_name + ' ' : '');
            });
        }
        return {
            arr: arr,
            contractDetails: { ...state.contractDetails, supply_catagories: e.supplies?.map((a) => a.supply) }
        };
    };

    const handleSuppChange = (e, returnObj) => {
        if (e?.contacts?.length === 1) {
            setFormState({
                ...formState,
                supplier: e,
                supplierContact: e?.contacts[0],
                Contract_Details: cntrctDetaislObj
            });
        } else {
            setFormState({
                ...formState,
                supplier: e,
                supplierContact: '',
                Contract_Details: cntrctDetaislObj
            });
        }
        let obj = getContractDetails(e);
        if (returnObj) return obj;
        setState({
            ...state,
            contractDetails: obj.contractDetails,
            supplierContactList: obj.arr,
            activeAccordion: []
        });
    };

    const handleContactModal = (type) => {
        setState({ ...state, contactsModal: type });
    };

    const saveConatctInfo = (type, data) => {
        saveFormsData(type, data);
        handleContactModal(false);
    };

    const navigateToEdit = () => {
        setMode('edit');
        setState({ ...state, activeAccordion: [] });
    };
    const showWorkflow = (flag) => {
        setState({ ...state, showWorkflowStatus: flag });
    };
    const handleCommentClick = (selRow, flag) => {
        const lstToShowComment = state.listToShowComment;
        lstToShowComment[selRow.id] = flag;
        setState({ ...state, listToShowComment: lstToShowComment });
    };

    const setActionsStatus = (hasActions) => {
        setState({ ...state, hasActions });
    };

    return (
        <div className={`page-body f-14 ${state.loading ? 'mask pos-rel' : 'pos-rel'}`}>
            {(state.loading || loader) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <Toast ref={(el) => setGrowl(el)} />
            <div className="page-header">
                <div className="col-md-6 p-0">
                    {!state.loading && (
                        <span className="light-color">
                            {mode === 'view' || mode === 'edit'
                                ? islatest
                                    ? 'Contract Number : ' + state.contarctNo
                                    : 'Transaction Number : ' + transactionID
                                : ' Create Contract'}
                        </span>
                    )}
                </div>

                <div className="col-md-6 text-right p-0 light-color pointer">
                    {formObj?.version_no > 0 && recordID && (
                        <img
                            alt=""
                            className="header-icon"
                            title="Workflow Stages"
                            src={process.env.PUBLIC_URL + '/assets/icons/workflow.svg'}
                            onClick={() => showWorkflow(true)}
                        ></img>
                    )}
                    <span className="backBtn pointer" onClick={handleGoBack}>
                        <span className="appkiticon icon-left-chevron-outline icon_size" />
                        Back
                    </span>
                </div>
            </div>
            <div className={`${state.hasActions || mode === 'create' ? 'page-content-ht-with-btns' : 'page-content-ht-without-btns'}`}>
                <Sidebar
                    visible={state.showWorkflowStatus}
                    onHide={() => showWorkflow(false)}
                    position="right"
                    dismissable={true}
                    closeOnEscape={false}
                    showCloseIcon={false}
                >
                    <WorklowSidebarContent
                        sourceId={recordID}
                        listToShowComment={state.listToShowComment}
                        showWorkflow={showWorkflow}
                        handleCommentClick={handleCommentClick}
                    />
                </Sidebar>
                {(cntrContarct?.status === 'Draft' || mode === 'create') && showUpdateInfo && (
                    <div className="display-flex m-b-20">
                        <img src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
                        <span className="m-l-5 icon-primary font-15 info-message p_5"> {showUpdateInfo}</span>
                    </div>
                )}
                {state.hasActions && inActiveAccs.length > 0 && (
                    <div className="display-flex m-b-20">
                        <img src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
                        <span className="m-l-5 icon-primary font-15 info-message p_5">
                            IFSC {inActiveAccs.length === 1 ? 'code' : 'codes'}
                            {inActiveAccs?.map((val) => val?.bankName?.ifsc)?.toString()} {inActiveAccs.length === 1 ? 'is' : 'are'} in
                            Inactive status, Kindly update Performance Bank Guarantee
                        </span>
                    </div>
                )}
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex m-b-10">
                        <b className="w-50 fs-14">Financial Year :</b>
                        <b className="fs-18">{formState?.year_id}</b>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex m-b-10">
                        <b className="w-50 fs-14">
                            Supplier Name / Supplier No
                            {mode !== 'view' && mode !== 'edit' ? <span className="icon-primary"> *</span> : ' :'}
                        </b>
                        {mode !== 'view' && mode !== 'edit' && (
                            <Dropdown
                                name="supplierNumber"
                                value={formState.supplier}
                                onChange={(e) => handleSuppChange(e.value)}
                                appendTo={document.self}
                                options={state.suppliersList}
                                placeholder="Select Supplier Name / Supplier No"
                                className="w-50 h-36"
                                optionLabel="nameNumber"
                                filter={true}
                                filterPlaceholder="Search"
                                filterBy="nameNumber"
                                resetFilterOnHide={true}
                                disabled={mode === 'edit'}
                            />
                        )}
                        {(mode === 'view' || mode === 'edit') && formState.supplier.nameNumber}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex m-b-10">
                        <b className="w-50 fs-14">
                            Supplier Contact <span> : </span>
                        </b>
                        {mode !== 'view' && (
                            <div
                                className={`input-div pointer w-50 ${!formState.supplier ? 'disabled' : ''}`}
                                onClick={() => handleContactModal(true)}
                            >
                                <span className="ellipses link-color">
                                    {formState.supplierContact ? formState?.supplierContact?.contact?.name : '–'}
                                </span>
                            </div>
                        )}
                        {mode === 'view' && formState?.supplierContact?.contact?.name}
                    </div>
                </div>
                {mode === 'view' && (
                    <div className="m-b-10 m-t-10">
                        <FDataTable
                            className="secondary-table"
                            rows={[formState.supplierContact]}
                            columns={TableSchema.CONTACT_MODAL.filter((a) => a.fieldType !== 'radio')}
                            emptyMessage="Contacts are yet to be created"
                            noPaginator={true}
                            scrollable
                        />
                    </div>
                )}
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                        <b className="w-50 fs-14">Contract Name {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}</b>
                        {mode !== 'view' && (
                            <TextAreaComponent
                                className="w-50"
                                textBoxSize="xl"
                                rows="4"
                                name="contractName"
                                placeholder="Enter Contract Name"
                                maxLength={200}
                                value={formState.contractName}
                                onChange={(e) => handleChange(e, 'contractName')}
                                validations={['notOnlyDigits']}
                                alphanumeric="true"
                            />
                        )}
                        {mode === 'view' && formState.contractName}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                        <b className="w-50 fs-14">Description {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'} </b>
                        {mode !== 'view' && (
                            <TextAreaComponent
                                className="w-50"
                                textBoxSize="xl"
                                rows="4"
                                name="description"
                                placeholder="Enter Description"
                                maxLength={200}
                                value={formState.description}
                                onChange={(e) => handleChange(e, 'description')}
                                alphanumeric="true"
                            />
                        )}
                        {mode === 'view' && formState.description}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex m-b-10">
                        <b className="w-50 fs-14"></b>
                        {errors && errors.contractName && (
                            <div className="m-l-10 m-t-0 a-form-error">
                                {formState.contractName !== '' ? 'Please enter valid Contract Name' : 'Contract Name is mandatory'}
                            </div>
                        )}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex m-b-10">
                        <b className="w-50 fs-14"></b>
                        {errors && errors.description && <div className="m-l-10 m-t-0 a-form-error">Description is mandatory</div>}
                    </div>
                </div>

                {state.contactsModal && (
                    <ContactModal
                        rows={state.supplierContactList}
                        showModal={state.contactsModal}
                        closeModal={() => handleContactModal(false)}
                        saveFormData={(type, data) => saveConatctInfo(type, data)}
                        selectedRows={formState.supplierContact}
                    />
                )}

                {formState.supplierContact !== '' && (
                    <div>
                        <Accordions multiOpen={true} activeItem={state.activeAccordion} onChange={(e) => handleAccordionChange(e)}>
                            <AccordionItem itemId="General_Contract_Information" className="accordian-item m-b-10 code-acc">
                                <AccordionItemHeader title={'General Contract Information'} />
                                <AccordionItemBody>
                                    <GeneralInformationForm
                                        ccy={APP_CONFIGURATION_VALUES?.filter((configData) => configData.code === 'CURRENCY')[0].value1}
                                        saveFormData={saveFormsData}
                                        formStateObj={formState}
                                        growl={growl}
                                        formType={mode}
                                        adminData={adminData}
                                    />
                                </AccordionItemBody>
                            </AccordionItem>
                            <AccordionItem itemId="Contract_Details" className="accordian-item m-b-10 code-acc">
                                <AccordionItemHeader title={'Contract Details'} />
                                <AccordionItemBody>
                                    <ContractDetailsForm
                                        contractDetails={state.contractDetails}
                                        saveFormData={saveFormsData}
                                        formStateObj={formState}
                                        formType={mode}
                                        supplierData={supplierData}
                                        adminData={adminData}
                                    />
                                </AccordionItemBody>
                            </AccordionItem>
                            <AccordionItem itemId="Performance_Bank_Guarantee" className="accordian-item m-b-10 code-acc">
                                <AccordionItemHeader title={'Performance Bank Guarantee'} />
                                <AccordionItemBody>
                                    <BankGuaranteeForm
                                        saveFormData={saveFormsData}
                                        Performance_Bank_Guarantee={formState.Performance_Bank_Guarantee}
                                        formType={mode}
                                        bankList={state.bankList ? state.bankList.filter((a) => a.is_active) : []}
                                        formStateObj={formState}
                                    />
                                </AccordionItemBody>
                            </AccordionItem>
                            <AccordionItem itemId="Payment_Terms" className="accordian-item m-b-10 code-acc">
                                <AccordionItemHeader title={'Payment Terms'} />
                                <AccordionItemBody>
                                    <PaymentTermsForm
                                        saveFormData={saveFormsData}
                                        Payment_Terms={formState.Payment_Terms}
                                        formType={mode}
                                    />
                                </AccordionItemBody>
                            </AccordionItem>
                            <AccordionItem itemId="Documents_Upload" className="accordian-item m-b-10 code-acc">
                                <AccordionItemHeader title={'Documents'} />
                                <AccordionItemBody>
                                    <UploadDocuments
                                        fmis_function="CNTR_CONTRACT_CREATION"
                                        source_id={recID}
                                        saveStateData={setData}
                                        document_details={
                                            cntrContarct?.version_no > 0
                                                ? docList
                                                : docList.filter((val) => val.is_mandatory || val.fileName)
                                        }
                                        mode={mode}
                                        growl={growl}
                                        version_no={cntrContarct?.version_no}
                                        version_user={cntrContarct?.version_user}
                                        loading={(have) => setLoader(have)}
                                    ></UploadDocuments>
                                </AccordionItemBody>
                            </AccordionItem>
                        </Accordions>
                    </div>
                )}
            </div>
            <ContractActions
                formState={formState}
                handleLoader={handleLoader}
                handleCancel={handleCancel}
                showGrowl={showGrowl}
                formType={mode}
                setActionsStatus={setActionsStatus}
                recordID={recordID}
                recID={recID}
                navigateToEdit={navigateToEdit}
                showUpdateInfo={showUpdateInfo}
                entity_id={entityID}
                inActiveAccs={inActiveAccs}
                docList={docList}
            />
        </div>
    );
};
export default CreateContract;
