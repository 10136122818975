import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, TextArea } from 'appkit-react';
import '../../styles/Dialog.scss';
import '../../styles/overrides.scss';
import { useQuery } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';

export function ConfirmationModal(props) {
	const [state, setState] = useState({
		comments: [],
		commentOptions: [],
		otherComment: '',
		assignedUser: props.workflowUsers?.assignment_type === 'MANUAL' ? '' : props.workflowUsers?.assign_to
	});
	const { loading, error } = useQuery(Schema.commentMappingDetails, {
		variables: {
			where: {
				mapping: {
					fmis_function_code: { equals: props.fmis_function }
				},
				action: { equals: props.currentAction?.name.replace(/ /g, '_').toUpperCase() }
			}
		},
		skip: !props.module || !props.fmis_function,
		onCompleted: (data) => {
			if (data?.commentMappingDetails.length > 0) {
				let commentOptions = [];
				data.commentMappingDetails.forEach((comment) => {
					if (comment.comment.is_active) commentOptions.push({ label: comment.comment.text, id: comment.comment_id });
				});
				commentOptions.push({ label: 'Other', id: 'Other' });
				setState({
					...state,
					commentOptions: commentOptions
				});
			}
		},
		fetchPolicy: 'no-cache'
	});

	const restrictSpace = (e) => {
		if (e.which === 32 && e?.currentTarget?.selectionStart === 0) e.preventDefault();
	};

	const isDisabled = () => {
		return (
			(props.currentAction?.comments_required && state.commentOptions.length > 0 && state?.comments?.length === 0) ||
			(props.currentAction?.comments_required && state.commentOptions.length === 0 && state.otherComment.trim() === '') ||
			props.loading ||
			loading ||
			(state.comments.find((c) => c.id === 'Other') && state.otherComment.trim() === '') ||
			(props.workflowUsers?.users_available?.length > 0 && (!state.assignedUser || state.assignedUser === ''))
		);
	};

	const handleChange = (e) => {
		setState({ ...state, assignedUser: e });
	};

	if (error) return `Error! ${error.message}`;

	const modalBodyMsg = () => {
		if (props.fullBodyContent && props.fullBodyContent?.text) {
			return `${props.fullBodyContent?.text} ?`;
		} else {
			return `Are you sure you want to ${props.bodyContent ? props.bodyContent : ''} ${props.currentAction?.name ? props.currentAction?.name?.toLowerCase() : ''
				} ?`;
		}
	};

	return (
		<Modal
			visible={props.showModal}
			onCancel={() => props.handleCancelModal('cancel')}
			className="conf_modal_width common_css add_user"
			backdropClosable={false}
		>
			<ModalHeader>
				{props.currentAction?.name === 'Recall' ? 'Recall Request' : props.currentAction?.name}
				{props.name}
			</ModalHeader>
			<ModalBody>
				<div>
					{loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
					<div>
						<div className="col-md-12 font-14 p-0">{modalBodyMsg()}</div>
					</div>
					{!props.noComments && props.currentAction.type !== 'DraftSave' && (
						<div
							className={`col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 ${props.currentAction.name !== 'Discard' && props.currentAction.type !== 'DraftSave' && 'p-t-10'
								} `}
						>
							{props.currentAction.name !== 'Discard' && props.workflowUsers?.assignment_type === 'MANUAL' && (
								<div className="row m-b-10">
									<div className="col-md-3 p-0">
										<label>
											Users Available <span className="icon-primary">*</span>
										</label>
									</div>
									<div className="col-md-9 p-0">
										<Dropdown
											name="supplierNumber"
											value={state.assignedUser}
											onChange={(e) => handleChange(e.value)}
											options={[...new Set(props.workflowUsers.users_available)]}
											placeholder="Select User"
											className="w-100 h-36"
										/>
									</div>
								</div>
							)}
							{props.currentAction.name !== 'Discard' && props.currentAction.type !== 'DraftSave' && (
								<div className="row">
									<div className="col-md-3 p-0">
										<label>
											Comments {props.currentAction?.comments_required && <span className="icon-primary"> *</span>}
										</label>
									</div>
									{!loading &&
										(state.commentOptions.length > 0 ? (
											<div className="col-md-9 p-0">
												<MultiSelect
													value={state.comments}
													options={state.commentOptions}
													optionLabel="label"
													className="w-100"
													placeholder="Select Comments "
													onChange={(e) => setState({ ...state, comments: e.value })}
													resetFilterOnHide={true}
												/>
											</div>
										) : (
											<div className="col-md-9 p-0">
												<TextArea
													onKeyDown={(ev) => restrictSpace(ev)}
													name="comment"
													onChange={(e) => {
														setState({ ...state, otherComment: e });
													}}
													placeholder="Enter Comment"
													maxLength="300"
													autoComplete="off"
													required={props.currentAction?.comments_required}
												/>
											</div>
										))}
									{state.comments.find((c) => c.id === 'Other') && (
										<>
											<div className="col-md-3 p-0">&nbsp;</div>
											<div className="col-md-9 m-t-10 p-0">
												<TextArea
													onKeyDown={(ev) => restrictSpace(ev)}
													name="otherComment"
													autoComplete="off"
													onChange={(e) => {
														setState({ ...state, otherComment: e });
													}}
													placeholder="Enter Comment"
													maxLength="300"
													required={props.currentAction?.comments_required}
												/>
											</div>
										</>
									)}
								</div>
							)}
						</div>
					)}
				</div>
			</ModalBody>
			<ModalFooter className="p-10">
				<Button
					size="sm"
					kind="primary"
					className="m-r-10"
					disabled={props.currentAction?.name === 'Save' ? false : isDisabled()}
					onClick={() => props.handleContinue(props.currentAction, state)}
				>
					Yes
				</Button>
				<Button size="sm" kind="secondary" className="" onClick={() => props.handleCancelModal('cancel')}>
					No
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export default ConfirmationModal;
