import React, { useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import './TreeCheckBox.scss';
import { Checkbox } from 'primereact/checkbox';

const TreeCheckBox = (props) => {
	const [selectedKey, setSelectedKey] = useState(null);

	useEffect(() => {
		document.querySelectorAll('.treeCheckBox .p-checkbox-box').forEach((item) => {
			if (!props.chkDisabled && item.classList.contains('disabled')) {
				item.classList.remove('disabled');
			} else if (!item.classList.contains('disabled') && props.chkDisabled) {
				item.classList.add('disabled');
			}
		});
		setSelectedKey(props.selectedRiskItems);
	}, [props]);

	const onSelectedKey = (e) => {
		if (!props.chkDisabled) {
			setSelectedKey(e.value);
			props.handleSelectedKey(e.value);
		}
	};

	const hadleCheckAllKeys = (e) => {
		props.selecteAllKeys(e);
	}

	return (
		<div className="m-t-10">
			<div className={props.title ? 'componentWrapper' : ''}>
				{props.title && <div className="header">{props.title}</div>}
				{props.showSelectAll &&
					<div className="col-12" >
						<div className='col-1'></div>
						<div className='col-11 p-l-28' >
							<Checkbox
								inputId="cb1"
								value={props.isAllChecked}
								onChange={(e) => hadleCheckAllKeys(e)}
								checked={props.isAllChecked}
								disabled={props.chkDisabled}
							>
							</Checkbox>
							<span htmlFor="cb1" className="f-14 p-l-12">Select All</span>
						</div>
					</div>
				}
				<div className={`treeCheckBox ${props.className}`}>
					<Tree
						value={props.auditHierarchyList}
						selectionMode="checkbox"
						selectionKeys={selectedKey}
						onSelectionChange={onSelectedKey}
					/>
				</div>
			</div>
		</div>
	);
};

export default TreeCheckBox;
