import React, { useState, useEffect } from 'react';
import FormSchema from '../formSchema';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import { Dropdown } from 'primereact/dropdown';

const SecurityDepositForm = (props) => {
    let securityDeductions = props.security_deductions;

    const [state] = useState({
        scc_depo_deduc_rate_List: securityDeductions || [],
        isEditable: (securityDeductions?.length > 0 && securityDeductions[0].deposit_deduction?.is_editable) || true
    });

    let secObj = {
        scc_depo_deduc_rate: getDeductionRate(),
        scc_depo_refund_time: props.Security_Deposit_Deduction.scc_depo_refund_time
    };

    function getDeductionRate() {
        let val = props.Security_Deposit_Deduction?.scc_depo_deduc_rate;
        if (
            (props.formType !== 'edit' || props.formType !== 'view') &&
            (!props.Security_Deposit_Deduction?.scc_depo_deduc_rate || props.Security_Deposit_Deduction?.scc_depo_deduc_rate === '')
        ) {
            val = securityDeductions.find((a) => a.is_default);
        }
        return val;
    }

    const [formState, setFormState] = useState(secObj);

    useEffect(() => {
        props.saveFormData('Security_Deposit_Deduction', formState);
        // eslint-disable-next-line
    }, [formState]);

    const handleChange = (ev, key) => {
        if (key === 'scc_depo_refund_time' && (isNaN(ev) || ev.indexOf('.') >= 0 || parseInt(ev) === 0)) {
            return;
        }
        setFormState({
            ...formState,
            [key]: ev
        });
    };

    return (
        <div className={'coa-card sdd p-10'}>
            <div className="row">
                {FormSchema.FLD_CD_SDD_RATE.is_visible !== -1 && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                        <b className="w-40 fs-14">
                            {FormSchema.FLD_CD_SDD_RATE.name}
                            {FormSchema.FLD_CD_SDD_RATE.is_mandatory !== -1 && props.formType !== 'view' ? (
                                <span className="icon-primary"> *</span>
                            ) : (
                                ' :'
                            )}
                        </b>
                        {props.formType !== 'view' && (
                            <Dropdown
                                name="scc_depo_deduc_rate"
                                value={formState?.scc_depo_deduc_rate}
                                disabled={!state.isEditable}
                                onChange={(e) => handleChange(e.value, 'scc_depo_deduc_rate')}
                                options={state.scc_depo_deduc_rate_List}
                                className="w-100 h-36"
                                appendTo={document.body}
                                optionLabel="rate"
                                placeholder="-Select-"
                            />
                        )}
                        {props.formType === 'view' && <b>{formState?.scc_depo_deduc_rate?.rate}</b>}
                    </div>
                )}
                {FormSchema.FLD_CD_SDD_REFUND_TIME.is_visible !== -1 && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                        <b className="w-40 fs-14">
                            {FormSchema.FLD_CD_SDD_REFUND_TIME.name}
                            {FormSchema.FLD_CD_SDD_REFUND_TIME.is_mandatory !== -1 && props.formType !== 'view' ? (
                                <span className="icon-primary"> *</span>
                            ) : (
                                ' :'
                            )}
                        </b>
                        {props.formType !== 'view' && (
                            <InputComponent
                                className="w-60"
                                inputBoxSize="sm"
                                name="scc_depo_refund_time"
                                type="text"
                                value={formState?.scc_depo_refund_time}
                                maxLength={3}
                                onChange={(e) => handleChange(e, 'scc_depo_refund_time')}
                                placeholder="In Months"
                            />
                        )}
                        {props.formType === 'view' && <b>{formState.scc_depo_refund_time}</b>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SecurityDepositForm;
