export const ApprovedRecordEntityCheckVariable = (rowData) => {
    return {
        where: {
            ref: { equals: rowData?.ref },
            status: { equals: 'Draft' },
            version_no: { gt: rowData?.version_no },
            version_date: { gt: rowData?.version_date }
        }
    };
};

export const ApprovedRecordApproveCheckVariable = (rowData) => {
    return {
        where: {
            ref: { equals: rowData?.ref },
            status: { equals: 'Draft' },
            version_no: { gt: rowData?.version_no }
        }
    };
};

export default {
    ApprovedRecordEntityCheckVariable,
    ApprovedRecordApproveCheckVariable
};
