import React, { useState } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'appkit-react';
import Calendar from '../../../components/Calendar/CalendarComponent';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../../../styles/Dialog.scss';
import './Masters.scss';
import { serverDate } from '../../../util';

const MastersForm = (props) => {
    const date = serverDate();
    const [state, setState] = useState({
        name: props.selectedRow.name ? props.selectedRow.name : date.getFullYear() + '-' + (date.getFullYear() + 1),
        start_date: props.selectedRow.start_date ? new Date(props.selectedRow.start_date) : '',
        end_date: props.selectedRow.end_date ? new Date(props.selectedRow.end_date) : '',
        master_start_date: props.selectedRow.master_start_date ? new Date(props.selectedRow.master_start_date) : '',
        master_end_date: props.selectedRow.master_end_date ? new Date(props.selectedRow.master_end_date) : ''
    });
    const handleSave = () => {
        if (
            !isValidData(state.start_date) ||
            !isValidData(state.end_date) ||
            !isValidData(state.master_start_date) ||
            !isValidData(state.master_end_date)
        ) {
            props.growl.show({ life: 6000, severity: 'error', summary: window.SYSTEM_ADMINISTRATION_COA.FieldsMandatory });
            return;
        }
        setState({ ...state, loading: true });
        if (props.edit) {
            updateRow();
            handleCancel();
            return;
        }
        createRow();
        handleCancel();
    };

    function updateRow() {
        props.updateRow({
            variables: {
                where: { id: props.selectedRow.id },
                data: {
                    name: { set: state.name },
                    start_date: { set: new Date(new Date(state.start_date).setHours(5, 30, 0, 0)) },
                    end_date: { set: new Date(new Date(state.end_date).setHours(5, 30, 0, 0)) },
                    master_start_date: !window.INTERNAL_AUDIT
                        ? { set: new Date(new Date(state.master_start_date).setHours(5, 30, 0, 0)) }
                        : { set: new Date(new Date(state.start_date).setHours(5, 30, 0, 0)) },
                    master_end_date: !window.INTERNAL_AUDIT
                        ? { set: new Date(new Date(state.master_end_date.setHours(5, 30, 0, 0))) }
                        : { set: new Date(new Date(state.end_date).setHours(5, 30, 0, 0)) }
                }
            }
        });
    }

    function createRow() {
        props.createRow({
            variables: {
                data: {
                    name: state.name,
                    id: state.name,
                    start_date: new Date(new Date(state.start_date).setHours(5, 30, 0, 0)),
                    end_date: new Date(new Date(state.end_date).setHours(5, 30, 0, 0)),
                    master_start_date: !window.INTERNAL_AUDIT
                        ? new Date(new Date(state.master_start_date).setHours(5, 30, 0, 0))
                        : new Date(new Date(state.start_date).setHours(5, 30, 0, 0)),
                    master_end_date: !window.INTERNAL_AUDIT
                        ? new Date(new Date(state.master_end_date.setHours(5, 30, 0, 0)))
                        : new Date(new Date(state.end_date).setHours(5, 30, 0, 0))
                }
            }
        });
    }

    const isValidData = (value) => {
        if ((value === null || value === undefined || typeof value === 'string') && toString(value).trim() === '') {
            return false;
        }
        return true;
    };

    const handleCancel = () => {
        setState({
            ...state,
            name: '',
            start_date: '',
            end_date: '',
            master_end_date: '',
            master_start_date: ''
        });
        props.handleCancel();
    };

    const modalBody = () => {
        return (
            <div className="row f-14">
                {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12">
                    <div className="row">
                        <div className="col-md-5">
                            <b>
                                Financial Year
                                {!props.edit ? <span className="icon-primary p-l-5">*</span> : ' :'}
                            </b>
                        </div>
                        <div className="col-md-7">{state.name}</div>
                    </div>
                    {!window.INTERNAL_AUDIT && (
                        <div className="row p-t-10">
                            <div className="col-md-5">
                                <b className="p-t-5">
                                    From Date
                                    <span className="icon-primary p-l-5">*</span>
                                </b>
                            </div>
                            <div className="col-md-7">
                                <Calendar
                                    minDate={new Date(serverDate().getFullYear(), 0, 1)}
                                    readOnlyInput={true}
                                    maxDate={
                                        state.master_end_date ? state.master_end_date : new Date(serverDate().getFullYear() + 1, 2, 31)
                                    }
                                    value={state.master_start_date}
                                    onChange={(e) => setState({ ...state, master_start_date: e.value })}
                                    placeholder="DD/MM/YYYY"
                                    dateFormat="dd M yy"
                                    showIcon
                                />
                            </div>
                        </div>
                    )}
                    {!window.INTERNAL_AUDIT && (
                        <div className="row p-t-10">
                            <div className="col-md-5">
                                <b className="p-t-5">
                                    To Date
                                    <span className="icon-primary p-l-5">*</span>
                                </b>
                            </div>
                            <div className="col-md-7">
                                <Calendar
                                    minDate={state.master_start_date ? state.master_start_date : new Date(serverDate().getFullYear(), 0, 1)}
                                    maxDate={new Date(serverDate().getFullYear() + 1, 2, 31)}
                                    readOnlyInput={true}
                                    value={state.master_end_date}
                                    onChange={(e) => setState({ ...state, master_end_date: e.value })}
                                    placeholder="DD/MM/YYYY"
                                    dateFormat="dd M yy"
                                    showIcon
                                />
                            </div>
                        </div>
                    )}
                    <div className="row p-t-10">
                        <div className="col-md-5">
                            <b className="p-t-5">
                                Masters From Date
                                <span className="icon-primary p-l-5">*</span>
                            </b>
                        </div>
                        <div className="col-md-7">
                            <Calendar
                                minDate={new Date(serverDate().getFullYear(), 0, 1)}
                                readOnlyInput={true}
                                maxDate={state.end_date ? state.end_date : new Date(serverDate().getFullYear() + 1, 2, 31)}
                                value={state.start_date}
                                onChange={(e) => setState({ ...state, start_date: e.value })}
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd M yy"
                                showIcon
                            />
                        </div>
                    </div>
                    <div className="row p-t-10">
                        <div className="col-md-5">
                            <b className="p-t-5">
                                Masters To Date
                                <span className="icon-primary p-l-5">*</span>
                            </b>
                        </div>
                        <div className="col-md-7">
                            <Calendar
                                minDate={state.start_date ? state.start_date : new Date(serverDate().getFullYear(), 0, 1)}
                                maxDate={new Date(serverDate().getFullYear() + 1, 2, 31)}
                                readOnlyInput={true}
                                value={state.end_date}
                                onChange={(e) => setState({ ...state, end_date: e.value })}
                                placeholder="DD/MM/YYYY"
                                dateFormat="dd M yy"
                                showIcon
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Modal visible={props.showModal} onCancel={props.handleCancel} className="master_css add_user" backdropClosable={false}>
            <ModalHeader>
                <div>{props.edit ? 'Edit Period' : 'Define Period'}</div>
            </ModalHeader>
            <ModalBody>{modalBody()}</ModalBody>
            <ModalFooter className="modal-footer p-10">
                <div>
                    <Button
                        size="sm"
                        kind="primary"
                        className="m-r-10"
                        disabled={
                            window.INTERNAL_AUDIT
                                ? state.end_date === '' || state.start_date === ''
                                : state.end_date === '' ||
                                  state.start_date === '' ||
                                  state.master_start_date === '' ||
                                  state.master_end_date === ''
                        }
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Button className=" " size="sm" kind="secondary" onClick={props.handleCancel}>
                        Cancel
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default MastersForm;
