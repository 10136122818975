import React, { useState } from 'react';
import Schema from '../../../schema/AppSchema';
import CMTableSchema from '../CMTableSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useQuery, useMutation } from '@apollo/client';
import FDataTable from '../../../components/fDataTable/FDataTable';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import { Toast } from 'primereact/toast';
import TopCardComponent from '../../../components/topComponents/TopCardComponent';
import TopCardComponentItems from '../../../components/topComponents/TopCardComponentItems';
import { contractClient, eventClient } from '../../../apollo';
import { getDate, getMonth, getDateVal, convertTextCase } from '../../../util';
import TopButtonComponent from '../../../components/topComponents/TopButtonComponent';
import AuditComponent from '../../../components/auditLog/AuditComponent';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';
import IsAuthorized from '../../../commons/IsAuthorized';
import RecallButton from '../../../commons/RecallButton';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

const Contract = (props) => {
    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.CNTRCT_CREATION.find((a) => a.routerLink === loc[1]);
    const contractBillsPrivileges = MenuItemsSchema.BILLS_MANAGEMENT.find((a) => a.routerLink === '/expbills/contractbill');
    let tableColumns = JSON.parse(JSON.stringify(CMTableSchema.CONTRACTS));
    const [state, setState] = useState({
        loading: true,
        customizedRows: [],
        selectedData: {},
        showModal: false,
        createModal: false,
        cards: TopCardComponentItems.CONTRACTS,
        variables: { where: { is_latest: { equals: true } } },
        columns: tableColumns,
        commentRequired: false,
        showConfirmModal: false
    });
    const variable = { is_latest: { equals: true } };
    const [growl, setGrowl] = useState();
    const [reference, setReference] = useState({});
    const [showAudit, setShowAudit] = useState(false);
    const [selected, setSelected] = useState({});
    const [recallLoader, setRecallLoader] = useState(false);
    const [recallObj, setRecallObj] = useState();
    const [variables, setVariables] = useState(variable);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalRecords, setTotalRecords] = useState();
    const [pageRows, setPageRows] = useState(10);
    const [searchFieldKey, setSearchFieldKey] = useState({});
    const [sortField, setSortField] = useState('version_date');
    const [sortOrder, setSortOrder] = useState('desc');
    const [tableLoader, setTableLoader] = useState(false);
    const [where, setWhere] = useState(
        props?.whereQry ? { ...props.whereQry, ...{ is_latest: { equals: true } } } : { is_latest: { equals: true } }
    );
    const assignedTo = {
        header: 'Assigned To',
        field: 'assignedTo',
        filter: true,
        sortable: true,
        filterMatchMode: 'contains',
        filterPlaceholder: 'Search By Assigned To',
        width: '200px',
        fieldType: 'tooltip'
    };

    const { refetch: refetchCount } = useQuery(Schema.cntrContractCount, {
        fetchPolicy: 'no-cache',
        client: contractClient,
        variables: { where: variables },
        skip: selected.label && selected.label !== 'Total Users',
        onCompleted: (resp) => {
            setTotalRecords(resp.cntrContractCount);
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    let variableData = { where: variables, orderBy: { [sortField]: sortOrder } };
    if (!selected.label || selected.label === 'Total Contracts') {
        variableData.skip = skip;
        variableData.take = take;
    }
    let { refetch: fetchContracts, error } = useQuery(Schema.getContracts, {
        client: contractClient,
        fetchPolicy: 'no-cache',
        variables: variableData,
        onCompleted: (response) => {
            if (response?.cntrContracts) {
                prepareRows(response);
                return;
            }
            setState({ ...state, loading: false, customizedRows: response.cntrContracts });
        },
        onError: () => {
            setState({ ...state, loading: false });
            setTableLoader(false);
        }
    });
    const prepareRows = (response) => {
        for (let index = 0; index < response.cntrContracts.length; index++) {
            let ele = response.cntrContracts[index];
            if (ele.supplies?.length > 0) {
                ele.supply_catogories = [...new Set(ele.supplies.map((b) => b.supply_category?.name))].toString();
                let amount = 0;
                ele.supplies.forEach((c) => {
                    amount = amount + c.amount_incl_tax;
                });
                ele.cntrctVal = amount;
            }
            let selectedLabelNotTotalContracts =
                selected.ids?.length > 0 && selected.label !== 'Total Contracts' && selected.status_obj && selected.status_obj[ele.id];
            if (selectedLabelNotTotalContracts) {
                ele.status = selected.status_obj[ele.id].status;
                ele.txnId = selected.status_obj[ele.id].txnId;
                ele.assignedTo = convertTextCase(selected.status_obj[ele.id].assignedTo, false) || '';
            }
            let record = selected?.status_obj && selected?.status_obj[ele.id]?.record;
            if (selectedLabelNotTotalContracts && record) {
                record.version_date = getDate(record.version_date);
                record.version_date_local = getDate(record.version_date);
                record.status = selected.status_obj[ele.id].status;
                record.c_status = 'Active';
            }
            if (selectedLabelNotTotalContracts && record?.supplies?.length > 0) {
                record.supply_catogories = [...new Set(record.supplies.map((b) => b.supply_category?.name))].toString();
                let amount = 0;
                record.supplies.forEach((c) => {
                    amount = amount + c.amount_incl_tax;
                });
                record.cntrctVal = amount;
            }
            if (record) response.cntrContracts[index] = record;
            ele.c_status = 'Active';
            ele.version_date = getDate(ele.version_date);
            ele.version_date_local = getDate(ele.version_date);
        }
        if (selected.new_objs?.length > 0 && selected.label === 'Pending With Self') {
            for (let index = 0; index < selected.new_objs.length; index++) {
                const element = selected.new_objs[index];
                element.c_status = 'Active';
                element.version_date = getDate(element.version_date);
                element.version_date_local = getDate(element.version_date);
                element.supply_catogories = [...new Set(element.supplies.map((b) => b.supply_category?.name))].toString();
                let amount = 0;
                element.supplies.forEach((c) => {
                    amount = amount + c.amount_incl_tax;
                });
                element.cntrctVal = amount;
                response.cntrContracts.push(element);
            }
        }

        let arr = response.cntrContracts?.map((a) => a.status)?.filter((item, i, ar) => ar.indexOf(item) === i);
        state.columns.forEach((a) => {
            if (a.field === 'status') {
                a.filterOptions = arr;
            }
        });
        setState({
            ...state,
            customizedRows: response.cntrContracts,
            loading: false,
            showModal: false,
            selectedData: {},
            columns: state.columns
        });
        setTableLoader(false);
    };

    const [removeRow, { loading: deleteLoader }] = useMutation(Schema.deleteOneCntrContract, {
        client: contractClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
                state.customizedRows.splice(index, 1);
                setState({ ...state, customizedRows: state.customizedRows, loading: false, showModal: false, selectedData: {} });

                growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.CONTRACT_MANAGEMENT_CONTRACT_CREATION.DeleteContract
                });
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [deleteWorkFlowAction, { loading: delLoader }] = useMutation(Schema.deleteOneEntityRecord, {
        client: eventClient,
        onCompleted: (data) => {
            if (data) {
                let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
                state.customizedRows.splice(index, 1);
                setState({ ...state, customizedRows: state.customizedRows, loading: false, showModal: false, selectedData: {} });
                growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.CONTRACT_MANAGEMENT_CONTRACT_CREATION.DeleteContract
                });
                return;
            }
            setState({ ...state, loading: false });
        },

    });

    const handleCancel = () => {
        setState({ ...state, showModal: false, selectedData: {}, createModal: false });
    };
    const handleDelete = () => {
        if (state.selectedData?.entity_id) {
            deleteWorkFlowAction({
                variables: {
                    where: { id: state.selectedData.entity_id }
                }
            });
            return;
        }
        removeRow({
            variables: {
                where: { id: state.selectedData.id }
            }
        });
    };

    const onClickDelete = (rowData) => {
        setState({ ...state, selectedData: rowData, showModal: true });
    };

    const onClickRecall = (rowData) => {
        setState({ ...state, selectedData: rowData });
    };
    const setCustomizedRows = () => {
        let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
        state.customizedRows.splice(index, 1);
        setState({ ...state, selectedData: {}, showConfirmModal: false, customizedRows: state.customizedRows });
        setRecallLoader(false);
    };
    const handleCalRecallStatus = (flag) => {
        setState({ ...state, showConfirmModal: true, commentRequired: flag });
    };
    const handleCancelModal = () => {
        setState({ ...state, showConfirmModal: false });
    };
    const handleLoader = (flag) => {
        setRecallLoader(flag);
    };

    const navigateTo = (query, selectedCard, columns) => {
        let tWhere = JSON.parse(JSON.stringify(where));
        setWhere(query);

        if (selectedCard.label === 'Pending For Verification/Approval') {
            setRecallObj(selectedCard.canRecall);
            columns.splice(columns.length - 1, 0, assignedTo);
        }
        setState({ ...state, columns: columns, loading: true });
        setSelected(selectedCard);
        setSkip(0);
        setTake(10);
        setPageRows(10);
        setSearchFieldKey({});
        setSortField('version_date');
        setSortOrder('asc');
        setVariables(query);
        setTableLoader(true);
        if (query?.id?.in.toString() === tWhere?.id?.in.toString()) {
            fetchContracts({
                variables: { where: query }
            }).then((response) => {
                if (response) prepareRows(response.data);
            });
        }
    };

    const onClickAudit = (rowData) => {
        setReference(rowData);
        setShowAudit(true);
    };
    const goBack = () => {
        setShowAudit(false);
    };
    const getOfficeCode = (rowData) => {
        return rowData.sanct_agency?.segment_full_code;
    };
    const onClickEdit = (rowData, action) => {
        if (rowData.entity_id && rowData.status !== 'Approved') {
            props.history.push(
                action ? 'contracts/' + action + '/' + rowData['entity_id'] + '/draft' : 'contracts/view/' + rowData['entity_id'] + '/draft'
            );
            localStorage.setItem('transaction_id', rowData.txnId);
            return;
        }
        props.history.push(action ? 'contracts/' + action + '/' + rowData['id'] : 'contracts/view/' + rowData['id']);
        localStorage.setItem('transaction_id', rowData.txnId);
    };

    const onPageChange = (event) => {
        setTableLoader(true);
        setSkip(event.first);
        setTake(event.rows);
        setPageRows(event.rows);
        if (!selected.label || selected.label === 'Total Contracts') {
            refetchCount();
        }
        fetchContracts();
    };

    const searchRecord = (e, column, filterKeys) => {
        setTableLoader(true);
        Object.keys(filterKeys).forEach((filterKey) => {
            if (filterKeys[filterKey].contains === null) {
                delete filterKeys[filterKey];
            }
            return filterKey;
        });
        let keyData = { ...filterKeys };
        if (filterKeys['ref']) {
            if (filterKeys['ref'].contains) {
                filterKeys['ref'] = { contains: filterKeys['ref'].contains, mode: 'insensitive' };
            } else {
                delete filterKeys['ref'];
            }
        }
        if (filterKeys['name']) {
            if (filterKeys['name'].contains) {
                filterKeys['name'] = { contains: filterKeys['name'].contains, mode: 'insensitive' };
            } else {
                delete filterKeys['name'];
            }
        }
        if (filterKeys['supply_catogories']) {
            if (filterKeys['supply_catogories'].contains) {
                filterKeys['supplies'] = {
                    some: { supply_category: { name: { contains: filterKeys['supply_catogories'].contains, mode: 'insensitive' } } }
                };
                delete filterKeys['supply_catogories'];
            } else {
                delete filterKeys['supply_catogories'];
            }
        }
        if (filterKeys['cntrctVal']) {
            if (filterKeys['cntrctVal'].contains) {
                filterKeys['supplies'] = {
                    some: { amount_incl_tax: { equals: parseFloat(filterKeys['cntrctVal'].contains) } }
                };
                delete filterKeys['cntrctVal'];
            } else {
                delete filterKeys['cntrctVal'];
            }
        }
        if (filterKeys['supplier.name']) {
            if (filterKeys['supplier.name'].contains) {
                filterKeys['supplier'] = { name: { contains: filterKeys['supplier.name'].contains, mode: 'insensitive' } };
                delete filterKeys['supplier.name'];
            } else {
                delete filterKeys['supplier.name'];
            }
        }
        if (filterKeys['status']) {
            if (filterKeys['status'].contains) {
                filterKeys['status'] = { contains: filterKeys['status'].contains, mode: 'insensitive' };
            } else {
                delete filterKeys['status'];
            }
        }
        if (filterKeys['version_date_local']) {
            if (filterKeys['version_date_local'].contains) {
                const time = new Date(filterKeys['version_date_local'].contains);
                const date = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T00:00:00.000Z';
                const date2 = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T23:59:59.000Z';
                filterKeys['version_date'] = { gte: date, lte: date2 };
                delete filterKeys['version_date_local'];
            } else {
                delete filterKeys['version_date_local'];
            }
        }

        if (filterKeys['c_status']) {
            if (filterKeys['c_status'].contains) {
                filterKeys['is_active'] = {
                    equals: filterKeys['c_status'].contains.toLowerCase() === 'active' ? true : false
                };
                delete filterKeys['c_status'];
            } else {
                delete filterKeys['c_status'];
            }
        }
        if (Object.keys(variables).length === 0 && Object.keys(filterKeys).length === 0) {
            setTableLoader(false);
        }
        setVariables({ ...variable, ...filterKeys });
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
        setSearchFieldKey(keyData);
    };

    const onSort = (event) => {
        setTableLoader(true);
        let srtField = event.sortField;
        if (event.sortField === 'version_date_local') {
            srtField = 'version_date';
        }
        if (event.sortField === 'c_status') {
            srtField = 'is_active';
        }
        let srtOrder = 'asc';
        if (sortField === srtField) {
            srtOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        }
        setSortOrder(srtOrder);
        setSortField(srtField);
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
    };
    const handleGenerateBills = (rowData) => {
        props.history.push('/expbills/contractbill/create/' + rowData.id);
    };

    const actionTemplate = (rowData) => {
        const showRecallBtn = recallObj && recallObj[rowData.id];
        return (
            <div className="d-flex">
                {selected.label === 'Pending With Self' && (
                    <IsAuthorized
                        privRequired={Privileges?.UPDATE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-r-5 m-l-5"
                                onClick={() => onClickEdit(rowData, 'edit')}
                            >
                                <img
                                    title="Edit"
                                    className="pointer"
                                    src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                    alt="Edit Icon"
                                    height="20px"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status.toLowerCase() === 'approved' && (
                    <IsAuthorized
                        privRequired={contractBillsPrivileges?.CREATE}
                        officeCode={contractBillsPrivileges?.OFFICE_CODE}
                        yes={() => (
                            <span
                                className="primary-color cursor-pointer word-ellipses"
                                onClick={() => {
                                    handleGenerateBills(rowData);
                                }}
                            >
                                <EllipsisWithTooltip placement="auto">Generate Bills</EllipsisWithTooltip>
                            </span>
                        )}
                    ></IsAuthorized>
                )}
                {(rowData.status === 'Draft' || rowData.status === 'Created') && selected.label === 'Pending With Self' && (
                    <IsAuthorized
                        privRequired={Privileges?.DELETE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                onClick={() => onClickDelete(rowData)}
                            >
                                <img
                                    className="pointer"
                                    height="20px"
                                    title="Delete"
                                    src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                    alt="Delete Icon"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status !== 'Draft' && (
                    <button className="a-btn-background-transparent a-icon-container m-l-5 m-r-5" onClick={() => onClickAudit(rowData)}>
                        <img
                            className="a-font-18"
                            title="Audit"
                            src={process.env.PUBLIC_URL + '/assets/icons/audit_trail.svg'}
                            alt="view Icon"
                        />
                    </button>
                )}
                {showRecallBtn && selected.label === 'Pending For Verification/Approval' && (
                    <RecallButton
                        rowData={rowData}
                        setCustomizedRows={setCustomizedRows}
                        onClickRecall={onClickRecall}
                        source_id={state.selectedData.id}
                        handleCalRecallStatus={handleCalRecallStatus}
                        handleCancelModal={handleCancelModal}
                        handleLoader={handleLoader}
                        commentRequired={state.commentRequired}
                        showConfirmModal={state.showConfirmModal}
                        growl={growl}
                        module={'Contract Management'}
                        fmis_function={'CNTR_CONTRACT_CREATION'}
                    />
                )}
            </div>
        );
    };
    if (state.columns.length) state.columns[state.columns.length - 1].body = actionTemplate;

    if (error) return `Error! ${error.message}`;

    return !showAudit ? (
        <div className={`${state.loading || recallLoader ? ' mask page-body' : 'page-body'}`}>
            <Toast ref={(el) => setGrowl(el)} />
            {(state.loading || recallLoader) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div>
                <div className="page-header">
                    <span className="light-color">Contracts</span>
                </div>
                {!deleteLoader && !delLoader && !recallLoader && (
                    <div className="coa-card-content">
                        <TopCardComponent
                            cards={state.cards}
                            fetchParent={(query, select, columns) => navigateTo(query, select, columns)}
                            showPopUp={() => props.history.push('contracts/create')}
                            selected={selected}
                            fmis_function="createContract"
                            approvedLabel="Total Contracts"
                            columns={tableColumns}
                            cardCount={totalRecords}
                        />
                        <div className="m-t-10">
                            <TopButtonComponent
                                topGridProps={{
                                    buttonLabel: 'Create Contract',
                                    createPriv: Privileges.CREATE,
                                    officeCode: Privileges.OFFICE_CODE
                                }}
                                showPopUp={() => {
                                    props.history.push('contracts/create');
                                    localStorage.setItem('transaction_id', '');
                                }}
                            />
                        </div>
                        {!state.loading && (
                            <FDataTable
                                rows={state.customizedRows}
                                columns={state.columns}
                                onClickDetails={(rowData) => onClickEdit(rowData)}
                                deletePriv={Privileges.DELETE}
                                updatePriv={Privileges.UPDATE}
                                officeCode={Privileges.OFFICE_CODE}
                                emptyMessage="Contracts are yet to be created"
                                loading={tableLoader}
                                getOfficeCode={getOfficeCode}
                                className="primary-table"
                                totalRecords={totalRecords}
                                count={skip}
                                onPageChange={(e) => onPageChange(e)}
                                pageRows={pageRows}
                                searchRecord={searchRecord}
                                filterKeys={searchFieldKey}
                                lazyLoad={!selected.label || selected.label === 'Total Contracts' ? true : false}
                                sortField={sortField}
                                sortOrder={sortOrder === 'asc' ? 1 : -1}
                                onSort={onSort}
                                scrollable
                            />
                        )}
                    </div>
                )}

                {state.showModal && (
                    <DeleteConfirmModal
                        showModal={state.showModal}
                        handleCancel={handleCancel}
                        type={'Contracts'}
                        name={
                            state.selectedData.entity_id
                                ? `Transaction ID: ${state.selectedData.txnId}`
                                : `Contract:  ${state.selectedData.name}`
                        }
                        handleDelete={handleDelete}
                        state={{ loading: deleteLoader }}
                    />
                )}
            </div>
        </div>
    ) : (
        <AuditComponent
            refence_label="Contract"
            goBack={goBack}
            reference_value={reference.ref}
            sourceData={reference}
            transaction_Id={reference.txnId}
        />
    );
};
export default Contract;
