import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import FDataTable from '../fDataTable/FDataTable';
import IsAuthorized from '../../commons/IsAuthorized';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useHistory } from 'react-router-dom';
import HOADetailsModal from '../HOADetails/HOADetailsModal';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

export function CommonTable(props, PopupComponent, TopComponent, key) {
	return function As() {
		let history = useHistory();
		let rows = [];
		const [state, setState] = useState({
			selectedRow: {},
			showPopup: false,
			showHOA: false,
			action: '',
			loading: props.customizedRows ? false : true,
			disableActions: false
		});
		const [growl, setGrowl] = useState();
		// ++++++++++++ Mutation Queries+++++++++++
		const [createRow] = useMutation(props.createOneQuery, {
			fetchPolicy: 'no-cache',
			client: props.client ? props.client : '',
			onCompleted: (res) => {
				if (res) {
					refetchRows().then((response) => {
						if (response) setRowsData(response.data);
					});
					setState({
						...state,
						showPopup: false,
						loading: false,
						selectedRow: {}
					});
					growl.show({
						life: 6000,
						severity: 'success',
						summary: props.type + ' created successfully'
					});
				}
			},
			onError: () => {
				setState({ ...state, loading: false });
			}
		});
		const [updateRow] = useMutation(props.updateOneQuery, {
			fetchPolicy: 'no-cache',
			client: props.client ? props.client : '',
			onCompleted: (res) => {
				if (res) {
					refetchRows().then((response) => {
						if (response) setRowsData(response.data);
					});
					setState({
						...state,
						showPopup: false,
						loading: false,
						selectedRow: {}
					});
					growl.show({
						life: 6000,
						severity: 'success',
						summary: props.type + ' updated successfully'
					});
				}
			},
			onError: () => {
				setState({ ...state, loading: false });
			}
		});

		const [removeRow] = useMutation(props.deleteOneQuery, {
			fetchPolicy: 'no-cache',
			client: props.client ? props.client : '',
			onCompleted: (res) => {
				if (res) {
					refetchRows().then((response) => {
						if (response) setRowsData(response.data);
					});
					setState({
						...state,
						showPopup: false,
						selectedRow: {},
						delete: false,
						loading: false
					});
					growl.show({
						life: 6000,
						severity: 'success',
						summary: props.type + ' deleted successfully'
					});
				}
			},
			onError: () => {
				setState({ ...state, loading: false });
			}
		});

		const handleCancel = () => {
			setState({
				...state,
				showPopup: false,
				showHOA: false,
				selectedRow: {},
				action: ''
			});
		};
		const setRowsData = (rowsList) => {
			rows = rowsList[props.dataKey];
			if (props.detailsType && props?.detailsType?.length > 0 && rows.length > 0) {
				rows.forEach((ele) => {
					if (typeof ele.is_active === 'boolean') {
						if (ele.is_active) {
							ele.is_active = props.detailsType[0];
						} else {
							ele.is_active = props.detailsType[1];
						}
					}
				});
			}
		};
		const { loading, data, error, called, refetch: refetchRows } = useQuery(props.fetchAllQuery, {
			client: props.client ? props.client : '',
			fetchPolicy: 'no-cache',
			skip: props.customizedRows,
			variables: props.queryObj ? props.queryObj : {},
			onCompleted: (res) => {
				if (res) {
					setState({
						...state,
						loading: false
					});
				}
			}
		});

		if (called && !loading && data) {
			setRowsData(data);
		}
		if (error) return `Error! ${error.message}`;
		const onClickEdit = (rowData) => {
			if (props.editRouter) {
				history.push(props.editRouter + '/' + rowData['id']);
				return;
			}
			setState({
				...state,
				selectedRow: rowData,
				showPopup: true,
				action: 'Edit'
			});
		};
		const onClickDelete = (rowData) => {
			setState({
				...state,
				selectedRow: rowData,
				showPopup: true,
				action: 'Delete'
			});
		};
		const actionTemplate = (rowData) => {
			if (props.deletePriv || props.updatePriv) {
				return (
					<div>
						<IsAuthorized
							privRequired={props.updatePriv}
							officeCode={props.officeCode}
							yes={() => (
								<button
									className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
									onClick={() => onClickEdit(rowData)}
								>
									<img
										title="Edit"
										className="pointer"
										src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
										alt="Edit Icon"
										height="20px"
									/>
								</button>
							)}
						></IsAuthorized>
						<IsAuthorized
							privRequired={props.deletePriv}
							officeCode={props.officeCode}
							yes={() => (
								<button
									className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
									onClick={() => onClickDelete(rowData)}
								>
									<img
										className="pointer"
										height="20px"
										title="Delete"
										src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
										alt="Delete Icon"
									/>
								</button>
							)}
						></IsAuthorized>
					</div>
				);
			}
			return '';
		};
		if (
			!props.rowEditor &&
			props.columns &&
			actionTemplate != '' &&
			!props.customizedActionTemplate &&
			props.columns[props.columns.length - 1]?.field === 'Actions'
		) {
			props.columns[props.columns.length - 1].body = actionTemplate;
		}
		if (props.columns && props.customizedActionTemplate) {
			props.columns[props.columns.length - 1].body = props.customizedActionTemplate;
		}
		const applyColorToField = (rowData, field) => {
			return <span className={rowData[field.field]}>{rowData[field.field]}</span>;
		};

		if (props.columns && props.FieldsToApplyColor) {
			props.FieldsToApplyColor.forEach((field) => {
				let index = props.columns.findIndex((column) => {
					return column.field === field;
				});
				if (index !== -1) props.columns[index].body = applyColorToField;
			});
		}

		const viewData = (rowData) => {
			let field = rowData[props.columns[0].field];
			let splittedField = props.columns[0].field.split('.');
			if (splittedField.length > 1) {
				let value = rowData;
				splittedField.forEach((split) => {
					value = value[split];
				});
				field = value;
			}
			return (
				<span
					className="pointer icon-primary"
					onClick={() =>
						history.push(
							rowData.status === 'Draft' ? props.editRouter + '/' + rowData['id'] : props.viewRouter + '/' + rowData['id']
						)
					}
				>
					<EllipsisWithTooltip placement="bottom">
						<p className="m-0 p-ellipsis">{field}</p>
					</EllipsisWithTooltip>
				</span>
			);
		};
		if (props.viewRouter) {
			props.columns[0].body = viewData;
		}
		if (props.customizedRows) {
			rows = props.customizedRows;
		}
		const onClickAdd = () => {
			if (props.createPage) {
				history.push(props.createRouter);
				return;
			}
			setState({
				...state,
				showPopup: true,
				action: 'Add'
			});
		};
		const onClickInput = (bool) => {
			setState({
				...state,
				showHOA: bool
			});
		};
		const deleteRow = () => {
			setState({ ...state, loading: true });
			if (props.type === 'Role') {
				removeRow({
					variables: {
						id: state.selectedRow.id
					}
				});
			} else {
				removeRow({
					variables: {
						where: {
							id: state.selectedRow.id
						}
					}
				});
			}
		};
		const handleLoader = (object) => {
			state.loading = object.loading;
			setState({ ...state, loading: state.loading });
		};
		const onClickDetails = (rowData) => {
			props.onClickDetails(rowData);
		};

		return (
			<div className={`${props.title ? 'page-body' : ''} ${loading || state.loading ? 'mask' : ''}`}>
				{(loading || state.loading) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
				{props.title !== '' || props.backButton ? (
					<div className="page-header">
						<span className="light-color">{props.title}</span>
						{props.backButton && <span className={'backBtn pointer '} onClick={props.handleBackPress}>
							<span className="appkiticon icon-left-chevron-outline icon_size"></span>
							<span>Back</span>
						</span>}
					</div>
				) : (
					''
				)}
				<div className={`${props.bodyScroll === false ? '' : 'coa-card-content'}`}>
					{TopComponent && (
						<TopComponent
							createPriv={props.createPriv}
							officeCode={props.officeCode}
							showPopUp={onClickAdd}
							showHOA={onClickInput}
							topGridProps={props.topGridProps}
							data={data}
						></TopComponent>
					)}
					<div className={`${'row'} ${key === 'mappingTable' ? 'm-l-0 m-r-0 m-b-10' : ''}`}>
						<div className={`col-md-12 ${props.title ? '' : 'p-0'}`}>
							{rows && props.columns && (
								<FDataTable
									{...props}
									rowAddButtonLabel={props.rowAddButtonLabel}
									rowEditor={props.rowEditor}
									rows={rows.length > 0 ? rows : props[props.dataKey]}
									columns={props.columns}
									emptyMessage={props?.emptyMessage}
									loading={props.tableLoader}
									noPaginator={props.noPaginator}
									onClickDetails={onClickDetails}
									className="primary-table"
									onClickEdit={onClickEdit}
									onClickAudit={props.onClickAudit}
									onClickDelete={onClickDelete}
									updateRow={updateRow}
									screen={props.screen}
									disabledActions={props.disableActions}
									totalRecords={props.totalRecords}
									count={props.skip}
									onPageChange={(e) => props.onPageChange(e)}
									pageRows={props.pageRows}
									searchRecord={props.searchRecord}
									filterKeys={props.filterKeys}
									lazyLoad={props.lazyLoad}
									onSort={props.onSort}
									sortField={props.sortField}
									sortOrder={props.sortOrder}
									scrollable
								/>
							)}
							{state.showPopup && (
								<PopupComponent
									type={props.type}
									state={state}
									showModal={state.showPopup}
									handleCancel={handleCancel}
									updateRow={updateRow}
									handleDelete={deleteRow}
									createRow={createRow}
									handleLoader={handleLoader}
									{...props}
									fetchRoles={props.fetchRoles}
									growl={growl}
									data={data}
									name={props.type + ' : ' + state.selectedRow[props.primaryKey]}
								></PopupComponent>
							)}
							{state.showHOA && (
								<HOADetailsModal showModal={state.showHOA} handleCancel={handleCancel} {...props}></HOADetailsModal>
							)}
						</div>

						<Toast ref={(el) => setGrowl(el)} />
					</div>
				</div>
			</div>
		);
	};
}
