import React, { useState } from 'react';
import Schema from '../../../../schema/AppSchema';
import { useMutation } from '@apollo/client';
import ActionsComponents from '../../../../components/actionsComponent/ActionsComponents';
import { MenuItemsSchema } from '../../../../commons/MenuItemsSchema';
import { expenditureClient } from '../../../../apollo';
import { getUTCTime, uniqueId } from '../../../../util';

const createOneQuery = Schema.createOnePersonnelBill;
const updateOneQuery = Schema.updateOnePersonnelBill;

const PersonnelBillActions = (props) => {
    const [state, setState] = useState({ buttonStatus: '' });
    const [versionNo, setVersionNo] = useState(props?.version_no);
    const [versionUser, setVersionUser] = useState(props?.version_user);
    const [currentAction, setCurrentAction] = useState('');
    const [showWfBtns, setWFBtns] = useState(true);
    let Privileges = {};
    let prev = props.location.pathname.split('/');
    let path = prev[0] + '/' + prev[1] + '/' + prev[2];
    if (prev.length > 0 && path) {
        MenuItemsSchema['BILLS_MANAGEMENT'].forEach((a) => {
            let val = '';
            if (a.subMenu)
                val = a.subMenu.find((b) => {
                    return b.routerLink === path;
                });
            else val = MenuItemsSchema['BILLS_MANAGEMENT'].find((v) => v.routerLink === path);
            if (val) {
                Privileges = val;
                return;
            }
        });
    }

    const [creatPersonnelBills] = useMutation(createOneQuery, {
        client: expenditureClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let growlMsg =
                    currentAction?.name && currentAction?.name === 'Submit'
                        ? 'Personnel Bills submitted successfully'
                        : 'Personnel Bills created successfully';
                props.showGrowl(growlMsg, 'success');
                if (currentAction?.name && currentAction?.name === 'Submit') {
                    setWFBtns(currentAction?.name && currentAction?.name === 'Submit' ? false : true);
                }
            }
        },
        onError: () => {
            props.handleLoader(false);
        }
    });

    const [updatePersonnelBills] = useMutation(updateOneQuery, {
        variables: { where: { id: props.recID } },
        client: expenditureClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let growlMsg = currentAction?.name
                    ? currentAction?.name + ' action taken successfully'
                    : 'Personnel Bills updated successfully';
                props.showGrowl(growlMsg, 'success');
                setVersionNo(parseInt(data.updateOneEmployee.version_no));
                setVersionUser(data.updateOneEmployee.version_user);
                setWFBtns(true);
            }
        },
        onError: () => {
            props.handleLoader(false);
        }
    });

    const checkValidations = () => {
        if (props.showUpdateInfo) {
            return props.showUpdateInfo;
        }
    };

    const getDisplayData = () => {
        return {
            bill_type: props.formState?.BILLS?.billType,
            bill_sub_type: props.formState?.BILLS?.billSubType,
            employee: {
                budget_office: props.formState?.BILLS.employee?.budget_office || '',
                budget_office_name: props.formState?.BILLS.employee?.budget_office_name || '',
                first_name: props.formState?.BILLS.employee?.first_name || '',
                last_name: props.formState?.BILLS.employee?.last_name || '',
                ref: props.formState?.BILLS.employee?.ref || ''
            },
            gross_bill_amount: props.formState?.BILLS?.grossBillAmt ? props.formState.BILLS.grossBillAmt.toString() : '',
            net_payment: props.netPay()
        };
    };

    const addDateTime = (date) => {
        var d = new Date(date);
        d.setHours(d.getHours() + 5);
        d.setMinutes(d.getMinutes() + 30);
        return d;
    };

    const getJourneyDetails = () => {
        let list = [];
        props.formState.Journey_Details.forEach((ob) => {
            let obj = { ...ob };
            obj.arrival_date = ob.arrival_date ? addDateTime(ob.arrival_date) : '';
            obj.departure_date = ob.departure_date ? addDateTime(ob.departure_date) : '';
            obj.distance = parseFloat(ob.distance);
            obj.fare_paid = parseFloat(ob.fare_paid);
            obj.claim_allowed = parseFloat(ob.claim_allowed);
            delete obj.index;
            list.push(obj);
        });
        return list;
    };

    const getDayAllowances = () => {
        let list = [];
        props.formState.Day_Allowance.forEach((ob) => {
            let obj = { ...ob };
            obj.days = parseFloat(ob.days);
            obj.rate = parseFloat(ob.rate);
            obj.amount = parseFloat(ob.amount);
            obj.no_of_persons = parseInt(ob.no_of_persons);
            delete obj.index;
            list.push(obj);
        });

        return list;
    };

    const getFamilyMembers = () => {
        let list = [];
        props.formState.Family_Members.forEach((ob) => {
            let obj = { ...ob };
            obj.age = parseInt(ob.age);
            delete obj.index;
            list.push(obj);
        });
        return list;
    };

    const getStayDetails = () => {
        let list = [];
        props.formState.Stay_Details.forEach((ob) => {
            let obj = { ...ob };
            obj.halting_charge = parseFloat(ob.halting_charge);
            obj.from_date = ob.from_date ? getUTCTime(new Date(ob.from_date)) : '';
            obj.to_date = ob.to_date ? getUTCTime(new Date(ob.to_date)) : '';
            delete obj.index;
            list.push(obj);
        });
        return list;
    };

    const getTCPE = () => {
        let list = [];
        props.formState.Transportation_Charges_of_Personnel_Effect.forEach((ob) => {
            let obj = { ...ob };
            obj.weight = parseFloat(ob.weight);
            obj.rate = parseFloat(ob.rate);
            obj.date = ob.date ? getUTCTime(new Date(ob.date)) : '';
            delete obj.amount;
            delete obj.index;
            list.push(obj);
        });
        return list;
    };

    const getTCPC = () => {
        let list = [];
        props.formState.Transportation_Charges_of_Private_Conveyance.forEach((ob) => {
            let obj = { ...ob };
            obj.weight = parseFloat(ob.weight);
            obj.rate = parseFloat(ob.rate);
            obj.distance = parseFloat(ob.distance);
            obj.packing_charge = parseFloat(ob.packing_charge);
            delete obj.index;
            delete obj.amount;
            list.push(obj);
        });
        return list;
    };

    const getTranferCharges = () => {
        let list = [];
        props.formState.Transfer_Chargers.forEach((ob) => {
            let obj = { ...ob };
            obj.transfer_grant = parseFloat(ob.transfer_grant);
            obj.rate = parseFloat(ob.rate);
            obj.distance = parseFloat(ob.distance);
            obj.home_journey_date = ob.home_journey_date ? getUTCTime(new Date(ob.home_journey_date)) : '';
            delete obj.index;
            delete obj.amount;
            list.push(obj);
        });
        return list;
    };

    const getDeductions = () => {
        let list = [];
        props.formState.Deductions.forEach((ob) => {
            let obj = { ...ob };
            obj.amount = ob.amount ? parseFloat(ob.amount) : 0;
            obj.deduction_id = ob.deduction?.id;
            delete obj.dedValue;
            delete obj.deduction;
            delete obj.index;
            list.push(obj);
        });
        return list;
    };

    const getThirdParties = () => {
        let list = [];
        props.thirdParties.forEach((ob) => {
            if (ob.beneficiary_id) {
                let obj = {};
                obj.beneficiary_id = ob.beneficiary_id;
                obj.share = parseFloat(ob.share);
                obj.amount = parseFloat(ob.amount);
                obj.id = uniqueId(25);
                list.push(obj);
            }
        });
        return list;
    };

    const getValue = (value, isTempSave) => {
        if (!isTempSave && (versionNo > 0 || (!versionNo && props?.version_no > 0))) return { set: value };
        return value;
    };

    const getRecord = (isTempSave = true) => {
        let formObj = JSON.parse(JSON.stringify(props.formState));
        let sanctionObj = {
            ...formObj.SANCTION_DETAILS,
            amount: formObj?.SANCTION_DETAILS?.amount ? parseFloat(formObj?.SANCTION_DETAILS?.amount) : 0
        };
        if (formObj?.SANCTION_DETAILS?.date) {
            sanctionObj['date'] = getUTCTime(new Date(formObj.SANCTION_DETAILS.date));
        }
        if (formObj?.SANCTION_DETAILS?.authority) {
            sanctionObj['authority'] = formObj.SANCTION_DETAILS.authority.trim();
        }
        let addiObj = {
            ...formObj.Additional_Bill_Details,
            headquarter: formObj?.Additional_Bill_Details?.headquarter ? formObj?.Additional_Bill_Details?.headquarter?.trim() : '',
            journey_purpouse: formObj?.Additional_Bill_Details?.journey_purpouse
                ? formObj?.Additional_Bill_Details?.journey_purpouse?.trim()
                : '',
            headquarter_old: formObj?.Additional_Bill_Details?.headquarter_old
                ? formObj?.Additional_Bill_Details?.headquarter_old?.trim()
                : '',
            headquarter_new: formObj?.Additional_Bill_Details?.headquarter_new
                ? formObj?.Additional_Bill_Details?.headquarter_new?.trim()
                : '',
            order_no: formObj?.Additional_Bill_Details?.order_no ? formObj?.Additional_Bill_Details?.order_no?.trim() : '',
            no_of_leaves: formObj?.Additional_Bill_Details?.no_of_leaves ? parseFloat(formObj.Additional_Bill_Details.no_of_leaves) : 0,
            encashment_days: formObj?.Additional_Bill_Details?.encashment_days
                ? parseFloat(formObj?.Additional_Bill_Details?.encashment_days)
                : 0,
            other_expenses: formObj?.Additional_Bill_Details?.other_expenses
                ? parseFloat(formObj?.Additional_Bill_Details?.other_expenses)
                : 0
        };
        if (formObj?.Additional_Bill_Details?.block_from_date) {
            addiObj['block_from_date'] = getUTCTime(new Date(formObj.Additional_Bill_Details.block_from_date));
        } else {
            delete addiObj['block_from_date'];
        }
        if (formObj?.Additional_Bill_Details?.block_to_date) {
            addiObj['block_to_date'] = getUTCTime(new Date(formObj.Additional_Bill_Details.block_to_date));
        } else {
            delete addiObj['block_to_date'];
        }
        if (formObj?.Additional_Bill_Details?.from_date) {
            addiObj['from_date'] = getUTCTime(new Date(formObj.Additional_Bill_Details.from_date));
        } else {
            delete addiObj['from_date'];
        }
        if (formObj?.Additional_Bill_Details?.to_date) {
            addiObj['to_date'] = getUTCTime(new Date(formObj.Additional_Bill_Details.to_date));
        } else {
            delete addiObj['to_date'];
        }

        let queryObj = {
            fin_year: getValue(formObj?.BILLS?.finYear, isTempSave),
            fin_period: getValue(formObj?.BILLS?.finPeriod, isTempSave),
            bill_type: { connect: { id: formObj?.BILLS?.billType?.id } },
            emp_id: getValue(formObj?.BILLS?.employee?.id, isTempSave),
            hoa: getValue(formObj?.BILLS?.hoa, isTempSave),
            gross_bill_amount: getValue(formObj?.BILLS?.grossBillAmt ? formObj.BILLS.grossBillAmt.toString() : '', isTempSave),
            description: getValue(formObj?.BILLS?.billDescription?.trim(), isTempSave),
            net_payment: getValue(parseFloat(props.netPay()))
        };
        if (formObj?.BILLS?.billType?.config_details?.length > 0 && formObj?.BILLS?.billType?.config_details[0]?.include_sanction_details) {
            queryObj['sanction_details'] = { create: sanctionObj };
        }
        if (
            formObj.BILLS?.billType?.include_additional_details &&
            props.additionalDetailsObj?.isVisibleObj &&
            Object.keys(props.additionalDetailsObj.isVisibleObj).some((k) => props.additionalDetailsObj.isVisibleObj[k])
        ) {
            queryObj['additional_details'] = { create: addiObj };
        }
        if (formObj?.BILLS?.billSubType?.id) {
            queryObj['bill_sub_type'] = { connect: { id: formObj?.BILLS?.billSubType?.id } };
        }
        if (props.formState.Journey_Details?.length > 0) {
            queryObj['journey_details'] = { create: getJourneyDetails() };
        }
        if (props.formState.Day_Allowance?.length > 0) {
            queryObj['day_allowances'] = { create: getDayAllowances() };
        }
        if (props.formState.Family_Members?.length > 0) {
            queryObj['family_members'] = { create: getFamilyMembers() };
        }
        if (props.formState.Stay_Details?.length > 0) {
            queryObj['stay_details'] = { create: getStayDetails() };
        }
        if (props.formState.Transportation_Charges_of_Personnel_Effect?.length > 0) {
            queryObj['personnel_expenses'] = { create: getTCPE() };
        }
        if (props.formState.Transportation_Charges_of_Private_Conveyance?.length > 0) {
            queryObj['conveyance_expenses'] = { create: getTCPC() };
        }
        if (props.formState.Transfer_Chargers?.length > 0) {
            queryObj['transfer_charges'] = { create: getTranferCharges() };
        }
        if (props.formState.Deductions?.length > 0) {
            queryObj['deductions'] = { create: getDeductions() };
        }
        if (
            props.thirdParties?.length > 0 &&
            formObj.BILLS?.billType?.config_details?.length &&
            formObj.BILLS?.billType?.config_details[0]?.include_thrirdparty_details
        ) {
            queryObj['third_party_payment'] = { create: getThirdParties() };
        }

        if (versionNo || props?.version_no) queryObj.version_no = versionNo || props?.version_no;
        if (props.entity_id) queryObj.entity_id = props.entity_id;
        if (props.recID) queryObj.id = props.recID;
        return queryObj;
    };

    const handleSubmitORSave = (status, wfParams, action) => {
        let isValid = checkValidations();
        if (isValid) {
            props.showGrowl(isValid, 'error');
            return;
        }
        setCurrentAction(action);
        let dataObj = getRecord(false);
        dataObj.wf_params = wfParams;
        delete dataObj.entity_id;
        delete dataObj.version_no;

        state.buttonStatus = status;
        setState({ ...state, buttonStatus: state.buttonStatus });
        props.handleLoader(true);

        if (versionNo > 0 || (!versionNo && props.version_no > 0)) {
            delete dataObj.id;
            setWFBtns(false);
            updatePersonnelBills({
                variables: {
                    data: dataObj
                }
            });
            return;
        }
        creatPersonnelBills({
            variables: {
                data: dataObj
            }
        });
    };

    const addDetails = [
        {
            field: 'block_from_date',
            code: 'Block_From__Date'
        },
        {
            field: 'block_to_date',
            code: 'Block_To__Date'
        },
        {
            field: 'no_of_leaves',
            code: 'No_of_Leave_days'
        },
        {
            field: 'journey_purpouse',
            code: 'Purpose_of_Journey'
        },
        {
            field: 'from_date',
            code: 'From_Date'
        },
        {
            field: 'to_date',
            code: 'To_Date'
        },
        {
            field: 'encashment_days',
            code: 'EL_Encashment__No_of_Days'
        },
        {
            field: 'headquarter',
            code: 'Headquarter'
        },
        {
            field: 'headquarter_old',
            code: 'HeadquarterOld'
        },
        {
            field: 'headquarter_new',
            code: 'HeadquarterNew'
        },
        {
            field: 'order_no',
            code: 'Order_No'
        },
        {
            field: 'other_expenses',
            code: 'Other_Expenses'
        },
        {
            field: 'block_from_date',
            code: 'Block_From__Date'
        }
    ];

    const checkAccordions = () => {
        if (props.formState.BILLS?.billType?.config_details?.length > 0) {
            if (props.formState.BILLS?.billType?.config_details[0]?.include_journey_details && props.formState.Journey_Details?.length <= 0)
                return true;
            if (
                props.formState.BILLS?.billType?.config_details[0]?.include_personnel_expenses &&
                props.formState.Transportation_Charges_of_Personnel_Effect?.length <= 0
            )
                return true;
            if (
                props.formState.BILLS?.billType?.config_details[0]?.include_private_expenses &&
                props.formState.Transportation_Charges_of_Private_Conveyance?.length <= 0
            )
                return true;
            if (props.formState.BILLS?.billType?.config_details[0]?.include_stay_details && props.formState.Stay_Details?.length <= 0)
                return true;
            if (props.formState.BILLS?.billType?.config_details[0]?.include_day_allowances && props.formState.Day_Allowance?.length <= 0)
                return true;
            if (
                props.formState.BILLS?.billType?.config_details[0]?.include_transfer_charges &&
                props.formState.Transfer_Chargers?.length <= 0
            )
                return true;
            if (props.formState.BILLS?.billType?.config_details[0]?.include_family_members && props.formState.Family_Members?.length <= 0)
                return true;
            if (props.formState.BILLS?.billType?.include_deductions && props.formState.Deductions?.length <= 0) return true;
        }
    };
    const disableButton = () => {
        let netPay = props.netPay();
        let mandate = false;
        addDetails.forEach((add) => {
            if (
                props.additionalDetailsObj?.isMandatory &&
                props.additionalDetailsObj?.isMandatory[add.code] &&
                props.formState?.Additional_Bill_Details &&
                !props.formState.Additional_Bill_Details[add.field]
            ) {
                mandate = true;
                return;
            }
        });
        let accoChecks = checkAccordions();
        return (
            props.errorStatus ||
            !props.formState.BILLS?.billType ||
            (props.formState.BILLS?.billType &&
                props.formState.BILLS?.billType?.sub_bill_details?.length > 0 &&
                !props.formState.BILLS?.billSubType) ||
            !props.formState.BILLS?.finPeriod ||
            !props.formState.BILLS?.employee ||
            !props.formState.BILLS?.hoa ||
            !props.formState.BILLS?.billDescription ||
            !props.formState.BILLS?.grossBillAmt ||
            (props.formState.BILLS?.grossBillAmt && props.formState.BILLS?.grossBillAmt < 0) ||
            (parseFloat(netPay) && parseFloat(netPay) < 0) ||
            mandate ||
            accoChecks
        );
    };

    const minFieldsRequired = () => {
        let netPay = props.netPay();
        if (!props.formState || props.errorStatus || !props.formState.BILLS?.billType || (parseFloat(netPay) && parseFloat(netPay) < 0))
            return true;
    };

    return (
        <div>
            {showWfBtns && (
                <ActionsComponents
                    navigateToEdit={() => props.navigateToEdit()}
                    mode={props.formType}
                    setActionsStatus={props.setActionsStatus}
                    canDisable={disableButton()}
                    data={props.formState}
                    onSave={(wfParams, action) => handleSubmitORSave('Approved', wfParams, action)}
                    onCancel={() => props.handleCancel()}
                    version_no={versionNo || props.version_no}
                    version_user={versionUser || props.formState?.version_user}
                    privRequired={Privileges?.UPDATE}
                    module="Expenditure Management"
                    fmis_function="EXP_PERSONNEL_BILL"
                    fmis_sub_function="NA"
                    officeCode={props.formState?.BILLS.employee?.budget_office}
                    minFieldsRequired={minFieldsRequired()}
                    getRecord={getRecord}
                    getDisplayData={getDisplayData}
                    handleLoader={props.handleLoader}
                    screenName={'Personnel Bills'}
                    source_id={props.recID}
                />
            )}
        </div>
    );
};

export default PersonnelBillActions;
