import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../TableSchema';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import ConfirmationModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../Validation';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { uniqueId } from '../../../../util';

const DayAllowances = (props) => {
    const [rows, setRows] = useState(props.formStateObj?.Day_Allowance);
    useEffect(() => {
        setRows(props.formStateObj?.Day_Allowance);
        // eslint-disable-next-line
    }, [props.formStateObj?.Day_Allowance]);
    let viewMode = TableSchema.Day_Allowance.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.mode !== 'view' ? TableSchema.Day_Allowance : viewMode;
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false
    });
    let emptyObj = {
        days: '',
        rate: '',
        no_of_persons: '',
        amount: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    const fields = [
        { key: 'rate', validators: 'numbersOnly', maxLength: 6 },
        { key: 'days', validators: 'numbersOnly', maxLength: 3 },
        { key: 'no_of_persons', validators: 'numbersOnly', maxLength: 3 }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);

        if (err[key] || ((key === 'rate' || key === 'days' || key === 'no_of_persons') && parseInt(ev) <= 0)) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const saveForm = () => {
        currentDetailsObj.amount =
            parseInt(currentDetailsObj.days) * parseInt(currentDetailsObj.rate) * parseInt(currentDetailsObj.no_of_persons);
        if (state.actionType === 'edit') {
            let indx = rows.findIndex((a) => a.id === editObj.id);
            rows[indx] = currentDetailsObj;
            setRows(rows);
        } else {
            currentDetailsObj.id = uniqueId(20);
            rows.push(currentDetailsObj);
            setRows(rows);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Day_Allowance', rows);
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                key !== 'amount' &&
                (currentDetailsObj[key] === undefined ||
                    currentDetailsObj[key] === '' ||
                    (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === ''))
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = rows.filter((a) => {
            return a.id !== currentDetailsObj.id;
        });
        setRows(filteredRows);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Day_Allowance', filteredRows);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    const getSumOfRows = () => {
        let Total = 0;
        rows.forEach((ro) => {
            Total = Total + parseInt(ro.amount);
        });
        return isNaN(Total) ? '' : Total;
    };

    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={3} />
                <Column footer={getSumOfRows()} footerStyle={{ textAlign: 'right' }} />
                {props.mode !== 'view' && <Column />}
            </Row>
        </ColumnGroup>
    );
    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Day Allowance are yet to be created"
                    className="secondary-table"
                    scrollable
                    mode={props?.mode}
                    footerGroup={footerGroup}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Days{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="days"
                                            type="text"
                                            value={currentDetailsObj.days}
                                            maxLength={3}
                                            onChange={(e) => handleChange(e, 'days')}
                                            placeholder="Enter Days"
                                            hasError={errors && errors.days}
                                            errMsg="Please enter valid Days"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Rate{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="rate"
                                            type="text"
                                            value={currentDetailsObj.rate}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e, 'rate')}
                                            placeholder="Enter Rate"
                                            hasError={errors && errors.rate}
                                            errMsg="Please enter valid Rate"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            No. of Persons{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="no_of_persons"
                                            type="text"
                                            value={currentDetailsObj.no_of_persons}
                                            maxLength={3}
                                            onChange={(e) => handleChange(e, 'no_of_persons')}
                                            placeholder="Enter No. of Persons"
                                            hasError={errors && errors.no_of_persons}
                                            errMsg="Please enter valid No. of Persons"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.mode !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (rows?.length === 0 ? '' : 'Another ') + 'Day Allowance'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Day Allowance"
                    showModal={state.showDeleteModal}
                    name={'Day Allowance : Days: ' + currentDetailsObj.days + ' & Rate: ' + currentDetailsObj.rate}
                />
            )}
        </div>
    );
};

export default DayAllowances;
