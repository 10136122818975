import React from 'react';

const ToggleMenu = (props) => {
    const toggleMenu = () => {
        props.toggleMenu();
    };

    return (
        <div>
            <i className="pi pi-align-justify pointer f-s-20 menu-icon-alg" onClick={toggleMenu}></i>
        </div>
    );
};

export default ToggleMenu;
