import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';

function ConfirmationModal(props) {
    return (
        <Modal visible={props.showModal} onCancel={() => props.handleCancel()} className="common_css add_user" backdropClosable={false}>
            <ModalHeader>{props.header}</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12">
                        <div className="p-l-10 p-r-10 header-style fs-14">{props.content}</div>
                    </div>
                    {props.note && (
                        <div className="col-md-12 m-t-10">
                            <div className="p-l-10 p-r-10">
                                <b>Note: </b>
                                {props.note}
                            </div>
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter className="p-10">
                {props.fstButton && (
                    <Button size="sm" kind="primary" className="m-r-10" onClick={() => props.handleClick(true)}>
                        {props.fstButton}
                    </Button>
                )}
                {props.secondBtn && (
                    <Button size="sm" kind="secondary" className="" onClick={() => props.handleClick(false)}>
                        {props.secondBtn}
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
}

export default ConfirmationModal;
