import React, { useState } from 'react';
import Schema from '../../../schema/AppSchema';
import TopButtonComponent from '../../../components/topComponents/TopButtonComponent';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useQuery, useMutation } from '@apollo/client';
import FDataTable from '../../../components/fDataTable/FDataTable';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import { Toast } from 'primereact/toast';
import { RemittanceSchema } from './RemittanceSchema';
import CoMasTableSchema from '../CoMasTableSchema';
import CreateRemitCatMap from './CreateRemitCatMap';
import { revenueClient } from '../../../apollo';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';

const RemittanceCatMap = (props) => {
    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.REVENUE_MNGMNT.find((a) => a.routerLink === loc[1]);

    let urlType = '';
    if (props.location.pathname) {
        let arr = window.location.href.split('/');
        urlType = arr[arr.length - 1];
    }

    const [state, setState] = useState({
        remitType: RemittanceSchema[urlType],
        loading: true,
        customizedRows: [],
        selectedData: {},
        showModal: false,
        createModal: false,
        columns: CoMasTableSchema.REMITANCE_MAPPING,
        dropdownValues: []
    });

    const [growl, setGrowl] = useState();
    let { refetch: refetchRows } = useQuery(Schema[state.remitType.fecthAllQuery], {
        client: revenueClient,
        fetchPolicy: 'no-cache',
        variables: { data: { [state.remitType.booleanType]: true }, addData: { remitCode: true } },
        onCompleted: (response) => {
            if (response?.getDropdown?.remittanceList?.length > 0) {
                response.getDropdown.remittanceList.forEach((a) => {
                    a.ids = a.sub_remittances.map((b) => b.id)?.join(', ');
                });
            }
            if (response?.getTable?.remittanceList?.length > 0) {
                prepareRowsData(response);
                return;
            }
            setState({ ...state, dropdownValues: response?.getDropdown?.remittanceList, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const prepareRowsData = (response) => {
        response.getTable.remittanceList.forEach((a) => {
            a.sub_remittance = a.sub_remittances.map((b) => b.sub_remit_name)?.join(', ');
            a.ids = a.sub_remittances.map((b) => b.id).toString();
        });

        if (response?.getDropdown?.remittanceList?.length > 0) {
            response.getDropdown.remittanceList.forEach((a) => {
                a.ids = a.sub_remittances.map((b) => b.id).toString();
            });
        }

        setState({
            ...state,
            customizedRows: response.getTable.remittanceList,
            dropdownValues: response?.getDropdown?.remittanceList,
            loading: false,
            showModal: false,
            createModal: false
        });
    };

    const [updateRemitCatMap] = useMutation(Schema[state.remitType.updateOneQuery], {
        client: revenueClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.UpdateRvnRemitCodes) {
                refetchRows().then((res) => {
                    if (res?.data) {
                        prepareRowsData(res.data);
                        growl.show({
                            life: 6000,
                            severity: 'success',
                            summary: state.createModal ? state.remitType.createGrowl : state.remitType.deleteGrowl
                        });
                    }
                });
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const handleCancel = () => {
        setState({ ...state, showModal: false, selectedData: {}, createModal: false });
    };
    const handleDelete = () => {
        updateRemitCatMap({
            variables: {
                id: state.selectedData.ids,
                data: { [state.remitType.booleanType]: false }
            }
        });
    };

    const onClickDelete = (rowData) => {
        setState({ ...state, selectedData: rowData, showModal: true });
    };

    const handleAdd = () => {
        setState({ ...state, createModal: true });
    };

    const handleCreate = (ids) => {
        updateRemitCatMap({
            variables: {
                id: ids,
                data: { [state.remitType.booleanType]: true }
            }
        });
    };

    return (
        <div className={`page-body ${state.loading ? 'mask' : ''}`}>
            <Toast ref={(el) => setGrowl(el)} />
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div className="page-header">
                <span className="light-color">{state.remitType.name}</span>
            </div>
            <div className="coa-card-content">
                {
                    <div>
                        {state.columns?.length <= 0 && !state.loading && (
                            <div className="m-t-10 display-flex">
                                <img src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
                                <span className="m-l-10 icon-primary font-15 info-message p_5">{state.remitType.noRecords}</span>
                            </div>
                        )}
                        {state.createModal && (
                            <CreateRemitCatMap
                                showModal={state.createModal}
                                handleCancel={handleCancel}
                                remitType={state.remitType}
                                handleCreate={handleCreate}
                                showSpinner={true}
                                dropdownValues={state.dropdownValues}
                            />
                        )}
                        {state.showModal && (
                            <DeleteConfirmModal
                                showModal={state.showModal}
                                handleCancel={handleCancel}
                                type={state.remitType.modalTitle}
                                handleDelete={handleDelete}
                                showSpinner={true}
                                name={'Remittance : ' + state.selectedData.remittance_name}
                            />
                        )}
                        <TopButtonComponent
                            topGridProps={{
                                buttonLabel: state.remitType.tableProps.buttonLabel,
                                createPriv: Privileges.CREATE,
                                officeCode: Privileges.OFFICE_CODE
                            }}
                            showPopUp={() => handleAdd()}
                        />
                        {state.columns.length > 0 && (
                            <>
                                <FDataTable
                                    rows={state.customizedRows}
                                    onClickDelete={onClickDelete}
                                    columns={state.columns}
                                    emptyMessage={state.remitType.noRecords}
                                    loading={state.loading}
                                    updatePriv={Privileges.UPDATE}
                                    deletePriv={Privileges.DELETE}
                                    officeCode={Privileges.OFFICE_CODE}
                                    className="primary-table"
                                    scrollable
                                />
                            </>
                        )}
                    </div>
                }
            </div>
        </div>
    );
};

export default RemittanceCatMap;
