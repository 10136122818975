import UserMasters from '../pages/userRegistration/userMasters/Masters';
import { Switch } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import AuthGaurd from './AuthGaurd';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
const AddRoleAndFunctionMapping = lazy(() => import('../pages/userRegistration/userMasters/AddRoleAndFunctionMapping'));
const usersMasterBreadcrumb = (props) => {
    let MainBreadCrumbValues = [{ name: 'System Administration', redirectTo: '/systemadministration' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    const pathArray = ['usermasters', 'organizationLevel', 'create', 'mapfunctions'];
    if (pathArray.indexOf(subPath[2]) !== -1 || pathArray.indexOf(subPath[1]) !== -1) {
        obj = [
            {
                key: subPath[1],
                name: subPath[1].charAt(0).toUpperCase() + subPath[1].slice(1),
                redirectTo: ''
            }
        ];
    }
    MainBreadCrumbValues = [...MainBreadCrumbValues, ...obj];
    return MainBreadCrumbValues;
};
const MapFunctions = lazy(() => import('../pages/userRegistration/userMasters/MapFunctions'));
const RolesRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={usersMasterBreadcrumb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd exact path={props?.path} component={UserMasters} menuItems={MenuItemsSchema.USER_ITEMS}></AuthGaurd>
                <AuthGaurd
                    exact
                    path={`${props?.path}/create`}
                    component={AddRoleAndFunctionMapping}
                    name={' Add Role & Function Mapping'}
                    menuItems={MenuItemsSchema.USER_ITEMS}
                ></AuthGaurd>
                <AuthGaurd
                    exact
                    path={`${props?.path}/view/:id`}
                    component={AddRoleAndFunctionMapping}
                    name={' Add Role & Function Mapping'}
                    menuItems={MenuItemsSchema.USER_ITEMS}
                ></AuthGaurd>
                <AuthGaurd
                    exact
                    path={`${props?.path}/view/:id/draft`}
                    component={AddRoleAndFunctionMapping}
                    name={' Add Role & Function Mapping'}
                    menuItems={MenuItemsSchema.USER_ITEMS}
                ></AuthGaurd>
                <AuthGaurd
                    exact
                    path={`${props?.path}/edit/:id`}
                    component={AddRoleAndFunctionMapping}
                    name={' Add Role & Function Mapping'}
                    menuItems={MenuItemsSchema.USER_ITEMS}
                ></AuthGaurd>
                <AuthGaurd
                    exact
                    path={`${props?.path}/edit/:id/draft`}
                    component={AddRoleAndFunctionMapping}
                    name={' Add Role & Function Mapping'}
                    menuItems={MenuItemsSchema.USER_ITEMS}
                ></AuthGaurd>
                <AuthGaurd
                    exact
                    path={`${props?.path}/mapfunctions`}
                    component={MapFunctions}
                    name={'Map Functions'}
                    menuItems={MenuItemsSchema.USER_ITEMS}
                ></AuthGaurd>
            </Switch>
        </div>
    );
};

export default RolesRouting;
