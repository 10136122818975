export const TableSchema = {
    MDA_Profiles: [
        {
            header: 'MDA Profile ID',
            field: 'ref',
            // fieldType: 'clickable',
            // customView: true,
            width: '180px',
            sortable: true,
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Profile ID'
        },
        {
            header: 'MDA',
            field: 'mda',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by MDA',
            width: '180px',
            fieldType: 'tooltip',
            sortable: true
        },
        {
            header: 'Auditable Area',
            field: 'auditable_area_code',
            filter: true,
            filterElement: 'search',
            fieldType: 'tooltip',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Auditable Area',
            width: '180px',
            sortable: true
        },
        {
            header: 'Applicable From (In Years)',
            field: 'from',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Applicable From (In Years)',
            width: '200px',
            sortable: true
        },
        {
            header: 'Applicable To (In Years)',
            field: 'to',
            width: '200px'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            sortable: true,
            width: '160px'
        },
        {
            header: 'Last Update Date & Time',
            fieldType: 'date',
            field: 'updated_at_local',
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '250px'
        },
        {
            header: '',
            field: 'Actions',
            width: '100px',
            body: ''
        }
    ],
    Key_Stake_Holders: [
        {
            header: 'Key Stakeholder ID',
            field: 'ref',
            // fieldType: 'clickable',
            // customView: true,
            sortable: true,
            width: '150px',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Key Stakeholder ID'
        },
        {
            header: 'Code',
            field: 'code',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Code'
        },
        {
            header: 'Stakeholder Name',
            field: 'name',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Stakeholder Name'
        },
        {
            header: 'Stakeholder Category',
            field: 'category',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip',
            filter: true,
            filterElement: 'dropdown',
            filterOptions: 'STAKEHOLDER_CATEGORY',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Stakeholder Category'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            sortable: true,
            width: '160px'
        },
        {
            header: 'Last Update Date & Time',
            fieldType: 'date',
            field: 'updated_at_local',
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '250px'
        },
        {
            header: '',
            field: 'Actions',
            width: '100px',
            body: ''
        }
    ],
    Key_Stratagic_Objectives: [
        {
            header: 'S. No.',
            field: 'slno',
            sortable: true,
            width: '30px',
            fieldType: 'tooltip'
        },
        {
            header: 'Strategic Objectives',
            field: 'objectives',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Summary of Strategies',
            field: 'summary',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Key_Stake_Holder_External: [
        {
            header: 'S. No.',
            field: 'slno',
            sortable: true,
            width: '30px',
            fieldType: 'tooltip'
        },
        {
            header: 'Code',
            field: 'ref',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'External Stakeholder Name',
            field: 'name',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        }
    ],
    Key_Stake_Holder_Internal: [
        {
            header: 'S. No.',
            field: 'slno',
            sortable: true,
            width: '30px',
            fieldType: 'tooltip'
        },
        {
            header: 'Code',
            field: 'ref',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Internal Stakeholder Name',
            field: 'name',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        }
    ],
    InternalHolders: [
        {
            header: 'All',
            field: 'checked',
            selectionMode: 'multiple',
            width: '80px',
            fieldType: 'checkbox'
        },
        {
            header: 'Code',
            field: 'ref',
            filterPlaceholder: 'Search by Code',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: false,
            fieldType: 'tooltip'
        },
        {
            header: 'Internal Stakeholder Name',
            field: 'name',
            filterPlaceholder: 'Search by Internal Stakeholder Name',
            filter: true,
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: false,
            fieldType: 'tooltip'
        }
    ],
    Reports: [
        {
            header: 'S.No.',
            field: 'slno',
            fieldType: 'tooltip',
            width: '80px'
        },
        {
            header: 'File No./Ref No.(R) (X-X-X-XX-XXX-XXXX-XX)',
            field: 'file_no',
            fieldType: 'tooltip',
            width: '100px'
        },
        {
            header: 'Audit Name',
            field: 'audit_name',
            fieldType: 'tooltip',
            width: '150px'
        },
        {
            header: 'Audit Classification',
            field: 'audit_classification',
            fieldType: 'tooltip',
            width: '150px'
        },
        {
            header: 'Audit Type',
            field: 'audit_type',
            fieldType: 'tooltip',
            width: '150px'
        },
        {
            header: 'Year of Audit',
            field: 'year_of_audit',
            fieldType: 'tooltip',
            width: '120px'
        },
        /*
         * {
         *     header: 'Reports',
         *     field: 'reports',
         *     fieldType: 'tooltip',
         *     width: '80px',
         *     className: 'icon-primary'
         * },
         */
        {
            header: 'Documents',
            field: null,
            width: '80px',
            more: true,
            fieldType: 'actions'
        },
        {
            header: 'Actions',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    MANAGEMENTS_CONTRACTS: [
        {
            header: 'S.No. ',
            field: 'slno',
            fieldType: 'tooltip',
            width: '80px'
        },
        {
            header: 'ID Number',
            field: 'ref.ref',
            fieldType: 'tooltip',
            width: '150px'
        },
        {
            header: 'Name',
            field: 'name',
            fieldType: 'tooltip',
            width: '150px'
        },
        {
            header: 'Designation',
            field: 'designation',
            fieldType: 'tooltip',
            width: '150px'
        },
        {
            header: 'Email',
            field: 'ref.email',
            fieldType: 'tooltip',
            width: '150px'
        },
        {
            header: 'Phone Number',
            field: 'mobile',
            fieldType: 'tooltip',
            width: '150px'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    Documents_List: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '50px'
        },
        {
            header: 'File Name',
            field: 'name',
            fieldType: 'clickable',
            customView: true,
            width: '150px'
        },
        {
            header: 'Actions',
            fieldType: 'actions',
            delete: true,
            width: '50px'
        }
    ],
    INTERNAL_AUDIT_UNITS: [
        {
            header: 'Internal Audit Unit ID',
            field: 'ref',
            customView: true,
            sortable: true,
            width: '150px',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Internal Audit Unit ID'
        },
        {
            header: 'Code',
            field: 'code',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Internal Audit Code'
        },
        {
            header: 'Internal Audit Unit Name',
            field: 'alias',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Internal Audit Unit Name'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            sortable: true,
            width: '160px'
        },
        {
            header: 'Last Update Date & Time',
            fieldType: 'date',
            field: 'updated_at_str_local',
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '250px'
        },
        {
            header: '',
            field: 'Actions',
            width: '100px',
            body: ''
        }
    ]
};

export default TableSchema;
