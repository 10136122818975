import React, { useState } from 'react';
import './login.scss';
import { Button } from 'appkit-react';
import { Toast } from 'primereact/toast';
import Schema from '../../schema/AppSchema';
import Login from './Login';

import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp';
import { useDispatch } from 'react-redux';
import { onLogin } from '../../app-redux/app-action';
import { useMutation, useQuery } from '@apollo/client';
import ChangePassword from './ChangePassword';
import store from '../../index';

const USER_LOGIN = Schema.userLogin;
const RESET_PASSWORD = Schema.resetPassword;
const GENERATE_RESET_PASSWORD_LINK = Schema.generateResetPasswordLink;
const UPDATE_PASSWORD = Schema.updatePassword;

function Auth(props) {
    let dispatch = useDispatch();
    const [growl, setGrowl] = useState();
    const growlFromStore = store?.getState()?.appReducer.growl;
    const tokenType = props.location.search?.split('=')[0].replace('?', '');
    const token = props.location.search?.split('=')[1];

    useQuery(Schema.fetchConfiguredData, {
        onCompleted: (data) => {
            if (data.getClientConfig) {
                localStorage.setItem('APP_CONFIGURATION_VALUES', JSON.stringify(data.getClientConfig));
                let ccy =
                    data.getClientConfig.length > 0
                        ? data.getClientConfig.filter((configData) => configData.code === 'CURRENCY')[0].value1
                        : 'INR';

                localStorage.setItem('CURRENCY', ccy);
            }
        }
    });

    let copyRightYear = JSON.parse(localStorage.getItem('APP_CONFIGURATION_VALUES'))?.filter(
        (configData) => configData.code === 'COPY_RIGHT'
    );

    const [properties, setProperties] = useState({
        username: '',
        password: '',
        forgot_password: false,
        tax_payer_login: false,
        tax_payer_register: false,
        reset_Password: tokenType === 'token' ? true : false,
        confirm_password: '',
        new_password: '',
        password_update_required: false,
        data: {},
        loginData: {}
    });

    const [userLogin] = useMutation(USER_LOGIN, {
        onCompleted: (data) => {
            if (data && data.userLogin && data.userLogin.password_update_required) {
                setProperties({
                    ...properties,
                    password_update_required: true,
                    data: data
                });
            } else {
                setProperties({ ...properties, loginData: data });
                localStorage.setItem('AUTH_TOKEN', data.userLogin.token);
                localStorage.setItem('USER_INF', JSON.stringify(data.userLogin.user));
                dispatch(onLogin(data?.userLogin));
                props.history.push('/dashboard');
            }
        },
        onError: () => {
            return 'Error';
        }
    });
    const [resetPassword] = useMutation(RESET_PASSWORD, {
        onCompleted: () => {
            growl.show({ life: 6000, severity: 'success', summary: window.AUTH.ResetPassword });
            backToLogin();
        },
        onError: () => {
            return 'Error';
        }
    });
    const [generateResetPasswordLink] = useMutation(GENERATE_RESET_PASSWORD_LINK, {
        onCompleted: () => {
            growl.show({ life: 6000, severity: 'success', summary: window.AUTH.RegisterMail });
            setProperties({ ...properties, forgot_password: false });
        },
        onError: () => {
            return 'Error';
        }
    });

    const handleChange = (value, key) => {
        setProperties({ ...properties, [key]: value });
    };
    const onClickLogin = () => {
        growlFromStore.clear();
        if (properties.username.length > 0 && properties.password.length > 0) {
            userLogin({
                variables: { username: properties.username?.trim(), password: properties.password }
            });
        } else {
            growl.show({ life: 6000, severity: 'error', sticky: true, summary: window.AUTH.Fields });
        }
    };
    const onClickForgotPw = () => {
        setProperties({ ...properties, forgot_password: true });
    };
    const sendResetLink = () => {
        generateResetPasswordLink({ variables: { username: properties.username } });
    };
    const backToLogin = () => {
        setProperties({
            ...properties,
            forgot_password: false,
            tax_payer_register: false,
            tax_payer_login: false,
            reset_Password: false,
            password: '',
            password_update_required: false,
            username: ''
        });
    };
    let taxpayerRegister = () => {
        // show taxpayer registration form
        setProperties({ ...properties, tax_payer_register: true });
    };
    const [updatePassword] = useMutation(UPDATE_PASSWORD, {
        onCompleted: (response) => {
            if (response) {
                localStorage.setItem('AUTH_TOKEN', properties.data.userLogin.token);
                localStorage.setItem('USER_INF', JSON.stringify(properties.data.userLogin.user));
                dispatch(onLogin(properties.data?.userLogin));
                props.history.push('/dashboard');
            }
        }
    });

    const onChangPasswordClick = () => {
        if (properties.password_update_required) {
            updatePassword({
                variables: {
                    id: properties.data.userLogin.user.id,
                    password: properties.password,
                    newPassword: properties.confirm_password
                }
            });
        } else {
            resetPassword({ variables: { token: token, newPassword: properties.confirm_password } });
        }
    };
    const onChangePasswordCancel = () => {
        backToLogin();
    };
    /*
     *const getImg = () => {
     *     return window._env_.REACT_APP_ENVIRONMENT_PORT + 'api/sysadmin/stream/' + window._env_.REACT_APP_HOME_BG_IMAGE;
     * };
     * const getLogo = () => {
     *     return window._env_.REACT_APP_ENVIRONMENT_PORT + 'api/sysadmin/stream/' + window._env_.REACT_APP_CLIENT_LOGO;
     * };
     */

    return (
        <div className="row ht-100 w-100 m-0">
            <Toast ref={(el) => setGrowl(el)} />
            <div className="col-xs-9 col-sm-9 col-lg-9 col-md-8 col-xl-9 p-0">
                <div className="bg-image">{<img src={process.env.PUBLIC_URL + '/assets/images/fmis_bg.png'} alt="" />}</div>
                <div className="light-color text-pos">
                    <h2 className="fs-45">Welcome to</h2>
                    <i>
                        <h4 className="fs-28 f-sty-italic">
                            {window.INTERNAL_AUDIT ? 'Audit Management System' : window._env_.REACT_APP_CLIENT_NAME}
                        </h4>
                    </i>
                </div>
            </div>
            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-4 col-xl-3 p-0 login-right-grid-bg">
                <div className="row m-0 ht-100">
                    <div className="top">
                        <div className="col-md-12 p-0">
                            {!properties.forgot_password &&
                                !properties.tax_payer_register &&
                                !properties.reset_Password &&
                                !properties.password_update_required && (
                                    <Login
                                        properties={properties}
                                        handleChange={handleChange}
                                        onClickLogin={onClickLogin}
                                        onClickForgotPw={onClickForgotPw}
                                        taxpayerRegister={taxpayerRegister}
                                    ></Login>
                                )}
                            {properties.forgot_password && (
                                <ForgotPassword
                                    properties={properties}
                                    handleChange={handleChange}
                                    onClickLogin={onClickLogin}
                                    sendResetLink={sendResetLink}
                                    backToLogin={backToLogin}
                                ></ForgotPassword>
                            )}
                            {properties.tax_payer_register && <SignUp properties={properties} backToLogin={backToLogin}></SignUp>}
                            {properties.reset_Password && (
                                <ChangePassword
                                    properties={properties}
                                    onChangPasswordClick={onChangPasswordClick}
                                    onChangePasswordCancel={onChangePasswordCancel}
                                    backToLogin={backToLogin}
                                    handleChange={handleChange}
                                ></ChangePassword>
                            )}
                            {properties.tax_payer_login && !properties.tax_payer_register && (
                                <div className="m-l-30 m-r-30">
                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-20 text-center">
                                        <span>Or</span>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12">
                                        <Button size="lg" className="w-100 m-t-20">
                                            Continue Without Login
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {properties.password_update_required && (
                                <ChangePassword
                                    properties={properties}
                                    onChangPasswordClick={onChangPasswordClick}
                                    onChangePasswordCancel={onChangePasswordCancel}
                                    backToLogin={backToLogin}
                                    handleChange={handleChange}
                                ></ChangePassword>
                            )}
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="text-center">
                            <div>
                                <div>
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/icons/logo.png'}
                                        alt="Tax Payer"
                                        className="bottom-pwc-logo"
                                    />
                                </div>
                            </div>
                            <div className="p-20 pwc-labe">
                                {copyRightYear && copyRightYear[0]?.value1 && <span>{copyRightYear[0].value1}</span>} PwC. All rights
                                reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Auth;
