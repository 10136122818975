import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'appkit-react';
import '../../styles/Dialog.scss';
const FDialog = (props) => {
    const handleCancel = () => {
        props.handleCancel();
    };
    return (
        <div>
            {props && (
                <Modal visible={props.showModal} onCancel={handleCancel} className={props.className} backdropClosable={false}>
                    {props.FModalHeader ? <ModalHeader>{props.FModalHeader()}</ModalHeader> : ''}

                    <ModalBody>{props.FModalBody && props.FModalBody()}</ModalBody>
                    {props.FModalFooter ? <ModalFooter className="p-10">{props.FModalFooter()}</ModalFooter> : ''}
                </Modal>
            )}
        </div>
    );
};
export default FDialog;
