import { gql } from '@apollo/client';

export const Schema = {
    keyStakeHoldersBycategory: gql`
        query keyStakeHoldersBycategory {
            keyStakeHoldersBycategory
        }
    `,
    keyStackSegmentCodes: gql`
        query keyStackSegmentCodes($fin_year: String) {
            keyStackSegmentCodes(fin_year: $fin_year) {
                id
                name
                full_code
            }
        }
    `,
    keyStakeHolders: gql`
        query keyStakeHolders($where: KeyStakeHolderWhereInput, $orderBy: [KeyStakeHolderOrderByInput!], $take: Int, $skip: Int) {
            keyStakeHolders(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                name
                ref
                code
                category
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    keyStakeHolder: gql`
        query keyStakeHolder($where: KeyStakeHolderWhereUniqueInput!) {
            keyStakeHolder(where: $where) {
                id
                name
                ref
                code
                category
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    keyStakeHolderCount: gql`
        query keyStakeHolderCount($where: KeyStakeHolderWhereInput) {
            keyStakeHolderCount(where: $where)
        }
    `,
    createOneKeyStakeHolder: gql`
        mutation createOneKeyStakeHolder($data: KeyStakeHolderCreateInput!) {
            createOneKeyStakeHolder(data: $data) {
                id
                name
                ref
                code
                category
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    updateOneKeyStakeHolder: gql`
        mutation updateOneKeyStakeHolder($where: KeyStakeHolderWhereUniqueInput!, $data: KeyStakeHolderUpdateInput!) {
            updateOneKeyStakeHolder(where: $where, data: $data) {
                id
                name
                ref
                code
                category
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    deleteOneKeyStakeHolder: gql`
        mutation deleteOneKeyStakeHolder($where: KeyStakeHolderWhereUniqueInput!) {
            deleteOneKeyStakeHolder(where: $where) {
                id
                name
                ref
                code
                category
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    mdaProfileDefs: gql`
        query mdaProfileDefs($where: MDAProfileDefWhereInput, $orderBy: [MDAProfileDefOrderByInput!], $take: Int, $skip: Int) {
            mdaProfileDefs(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                ref
                mda_code
                mda
                auditable_area
                auditable_area_code
                from
                to
                status
                is_latest
                is_effective
                version_user
                version_no
                version_date
            }
        }
    `,
    mdaProfileDef: gql`
        query mdaProfileDef($where: MDAProfileDefWhereUniqueInput!) {
            mdaProfileDef(where: $where) {
                id
                ref
                mda_code
                office_code
                mda
                auditable_area
                auditable_level_id
                auditable_area_code
                from
                to
                it_in_use
                applicalble_subsequent_level
                purpose_mission_mandate
                key_products_services
                is_organization_coa
                status
                version_user
                version_no
                version_date
                is_latest
                is_effective
                key_strategic_objectives {
                    objective
                    id
                    summary_of_strategies
                }
                internal_key_stake_holders {
                    id
                    code
                    name
                }
                external_key_stake_holders(where: { is_active: { equals: true } }) {
                    id
                    code
                    name
                }
                policies_processes_other_info {
                    id
                    nature_of_document
                    name
                    description
                    document_id
                    document_link
                    date_of_upload
                }
                previous_audit_reports {
                    id
                    file_id
                    file_ref_no
                    audit_name
                    audit_classification
                    audit_type
                    year_of_audit
                }
                management_contacts {
                    employee_id
                }
                addresses {
                    id
                    address_id
                    is_primary
                    address {
                        id
                        line1
                        line2
                        line3
                        pin_code
                        country
                        state
                        district
                        sub_district
                        village
                        panchayat
                        is_active
                        tier_id
                    }
                }
                auditable_area_details {
                    auditable_area
                    auditable_area_code
                    auditable_area_id
                    auditable_level_id
                    parent_id
                }
            }
        }
    `,
    mDAProfileDefCount: gql`
        query mDAProfileDefCount($where: MDAProfileDefWhereInput) {
            mDAProfileDefCount(where: $where)
        }
    `,
    stakeholderDefCount: gql`
        query keyStakeHolderCount($where: KeyStakeHolderWhereInput) {
            keyStakeHolderCount(where: $where)
        }
    `,
    createOneMDAProfileDef: gql`
        mutation createOneMDAProfileDef($data: MDAProfileDefCreateInput!) {
            createOneMDAProfileDef(data: $data) {
                id
                ref
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    updateOneMDAProfileDef: gql`
        mutation updateOneMDAProfileDef($where: MDAProfileDefWhereUniqueInput!, $data: MDAProfileDefUpdateInput!) {
            updateOneMDAProfileDef(where: $where, data: $data) {
                id
                ref
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    iaMdaDeputationOffice: gql`
        query mdaAndDeputationOffice($fin_year: String) {
            mdaAndDeputationOffice(fin_year: $fin_year) {
                id
                name
                code
                full_code
                seg_def {
                    name
                }
                children {
                    id
                    name
                    code
                    full_code
                    seg_def {
                        name
                    }
                }
            }
        }
    `,
    iaMasterData: gql`
        query iaMasterData($where: CountryWhereInput, $fin_year: String) {
            mdaAndDeputationOffice: mdaAndDeputationOffice(fin_year: $fin_year) {
                id
                name
                code
                full_code
                seg_def {
                    name
                    id
                }
                children {
                    id
                    name
                    code
                    full_code
                    seg_def {
                        name
                        id
                    }
                }
            }
            countries: countries(where: $where) {
                id
                name
                ccy
                ccy_code
                states {
                    id
                    name
                }
            }
            appSetting: appSetting(where: { id: "DESIGNATION_LEVEL" }) {
                id
                value1
                value2
            }
            admFinYears: admFinYears {
                master_start_date
            }
        }
    `,
    tiers: gql`
        query tiers {
            tiers {
                id
                name
            }
        }
    `,
    singleUpload: gql`
        mutation singleUpload($file: Upload) {
            singleUpload(file: $file) {
                id
                path
                input_name
                size
                mimetype
            }
        }
    `,
    files: gql`
        query files($where: FileWhereInput) {
            files(where: $where) {
                id
                path
                input_name
                size
                mimetype
                version_no
                version_user
                version_date
            }
        }
    `,
    getMDAProfileSubsequentLevels: gql`
        query getMDAProfileSubsequentLevels($auditableAreaCode: String!, $year: Int!) {
            getMDAProfileSubsequentLevels(auditableAreaCode: $auditableAreaCode, year: $year) {
                id
                ref
                from
                to
                mda
                mda_code
                status
                office_code
                auditable_level_id
                auditable_area
                auditable_area_code
                applicalble_subsequent_level
                purpose_mission_mandate
                key_products_services
                is_organization_coa
                it_in_use
                key_strategic_objectives {
                    id
                    objective
                    summary_of_strategies
                }
                previous_audit_reports {
                    id
                    file_id
                    file_ref_no
                    audit_name
                    audit_type
                    audit_classification
                    year_of_audit
                }
            }
        }
    `,
    createOneIAUDevision: gql`
        mutation createOneIAUDevision($data: IAUDevisionCreateInput!) {
            createOneIAUDevision(data: $data) {
                id
            }
        }
    `,
    updateOneIAUDevision: gql`
        mutation updateOneIAUDevision($where: IAUDevisionWhereUniqueInput!, $data: IAUDevisionUpdateInput!) {
            updateOneIAUDevision(where: $where, data: $data) {
                id
            }
        }
    `,
    deleteOneIAUDevision: gql`
        mutation deleteOneIAUDevision($where: IAUDevisionWhereUniqueInput!) {
            deleteOneIAUDevision(where: $where) {
                id
            }
        }
    `,
    iauDevisionCount: gql`
        query iauDevisionCount($where: IAUDevisionWhereInput) {
            iauDevisionCount(where: $where)
        }
    `,
    iauDevisions: gql`
        query iauDevisions($where: IAUDevisionWhereInput, $orderBy: [IAUDevisionOrderByInput!], $take: Int, $skip: Int) {
            iauDevisions(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                ref
                id
                code
                full_code
                name
                alias
                version_date
                version_no
                version_user
                status
                is_effective
                is_latest
            }
        }
    `,
    iauDevision: gql`
        query iauDevision($where: IAUDevisionWhereUniqueInput!) {
            iauDevision(where: $where) {
                id
                ref
                code
                full_code
                name
                alias
                version_user
                version_no
                status
                is_effective
                is_latest
                details {
                    mda_profile_id
                    iau_devision_id
                    mda_profile_id
                    mda_profile {
                        id
                        mda_code
                        mda
                        office_code
                        auditable_area_code
                        auditable_area
                        auditable_level_id
                    }
                }
            }
        }
    `
};
export default Schema;
