export const CoMasTableSchema = {
    COMMENTS_CONFIGURATION: [
        {
            header: 'Module',
            field: 'module',
            filter: true,
            filterPlaceholder: 'Search by Module',
            filterElement: 'dropdown',
            width: '270px',
            filterMatchMode: 'contains',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Function / Master',
            field: 'fmis_function',
            filter: true,
            filterPlaceholder: 'Search by Function / Master',
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            width: '300px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Sub-Function',
            field: 'fmis_sub_function',
            filter: true,
            filterPlaceholder: 'Search by Sub-Function',
            filterElement: 'dropdown',
            width: '250px',
            filterMatchMode: 'contains',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            width: '180px',
            bodyStyle: 'center',
            body: ''
        }
    ],
    OVERALL_COMMENTS: [
        {
            header: 'Comments',
            field: 'text',
            filter: true,
            filterPlaceholder: 'Search by Comments',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '300px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Default for all Functions / Masters',
            field: 'is_default',
            filter: true,
            filterPlaceholder: 'Search by Default for all Functions / Masters',
            filterElement: 'dropdown',
            filterOptions: 'YES_NO_OPTIONS',
            filterMatchMode: 'contains',
            width: '300px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Active/ Inactive',
            field: 'is_active',
            filter: true,
            fieldType: 'switch',
            filterPlaceholder: 'Search by Active/ Inactive',
            filterElement: 'dropdown',
            filterOptions: 'CONF_SUPL_ACTIVE_INACTIVE',
            filterMatchMode: 'contains',
            sortable: true,
            width: '300px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            edit: true,
            delete: true,
            filterPlaceholder: '',
            width: '100px',
            filterElement: null,
            bodyStyle: 'center',
            body: ''
        }
    ],
    COMMENTS_ACCORIAND: [
        {
            header: 'Predefined Comments',
            field: 'text',
            fieldType: 'text',
            inputPlaceholder: 'Select Comments',
            filter: false,
            sortable: false,
            width: '80%'
        },
        {
            header: '',
            field: 'InlineActions',
            fieldType: 'InlineActions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '20%'
        }
    ],
    DEDUCTION_MASTERS: [
        {
            header: 'Deduction Code',
            field: 'code',
            filter: true,
            filterPlaceholder: 'Search by Deduction Code',
            filterMatchMode: 'contains',
            width: '150px'
        },
        {
            header: 'Deduction Name',
            field: 'name',
            filter: true,
            fieldType: 'tooltip',
            filterPlaceholder: 'Search by Deduction Name',
            filterMatchMode: 'contains',
            width: '150px'
        },
        {
            header: 'Type',
            field: 'type',
            filter: true,
            filterPlaceholder: 'Search by Type',
            filterElement: 'dropdown',
            filterOptions: 'VALUE_TYPES',
            filterMatchMode: 'contains',
            sortable: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Value',
            field: 'value',
            filter: true,
            filterPlaceholder: 'Search by Value',
            filterMatchMode: 'contains',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Editable?',
            field: 'is_editable',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Capture Remarks in case of edition?',
            field: 'can_add_remarks',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Information to be displayed if any?',
            field: 'info',
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active?',
            field: 'is_active',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            filter: true,
            fieldType: 'workflowStatus',
            filterPlaceholder: 'Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            sortableDisabled: true,
            width: '150px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_str_local',
            filter: true,
            fieldType: 'date',
            filterPlaceholder: 'Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '120px'
        }
    ],
    LOCALITIES_CODES: [
        {
            header: 'Locality',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Search by Locality',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'District',
            field: 'district',
            filter: true,
            filterPlaceholder: 'Search by District',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'City',
            field: 'city',
            filter: true,
            filterPlaceholder: 'Search by City',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'State',
            field: 'state',
            filter: true,
            filterPlaceholder: 'Search by State',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            width: '80px',
            field: null,
            fieldType: 'actions',
            edit: true,
            editRoute: 'popup',
            delete: true,
            deleteRoute: 'popup'
        }
    ],
    IFSC_CODES: [
        {
            header: 'IFSC Code',
            field: 'ifsc',
            filter: true,
            filterPlaceholder: 'Search by IFSC Code',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Search by Bank Name',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Branch Name',
            field: 'branch_name',
            filter: true,
            filterPlaceholder: 'Search by Branch Name',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Active / Inactive',
            field: 'is_active',
            filterOptions: 'ACTIVE_INACTIVE',
            filter: true,
            filterPlaceholder: 'Active / Inactive',
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            fieldType: 'date',
            filter: true,
            filterPlaceholder: 'Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '180px'
        },
        {
            header: '',
            field: null,
            fieldType: 'actions',
            edit: true,
            editRoute: 'popup',
            width: '95px'
        }
    ],
    REMITANCE_MAPPING: [
        {
            header: 'Remittance',
            field: 'remittance_name',
            filter: true,
            filterPlaceholder: 'Search By Remittance',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub Remittance',
            field: 'sub_remittance',
            filter: true,
            filterPlaceholder: 'Search By Sub Remittance',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: null,
            fieldType: 'actions',
            delete: true,
            bodyStyle: 'center',
            width: '50px'
        }
    ],
    REMITANCE_PURPOSE_MAPPING: [
        {
            header: 'Remittance Purpose ID',
            field: 'ref',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search By Remittance Purpose ID',
            width: '80px'
        },
        {
            header: 'Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            sortable: true,
            filterElement: 'dropdown',
            filterOptions: [],
            filterPlaceholder: 'Search By Status',
            width: '80px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'version_date_local',
            fieldType: 'date',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            alignTo: 'center',
            width: '80px'
        },
        {
            header: '',
            field: 'Actions',
            width: '30px',
            body: ''
        }
    ],
    EXCHANGE_CURRENCY: [
        {
            header: 'Currency',
            field: 'country.ccy_code',
            filter: true,
            filterPlaceholder: 'Search by Currency',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Country',
            field: 'country.name',
            filter: true,
            filterPlaceholder: 'Search by Country',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Exchange Rate',
            field: 'exch_rate',
            filter: true,
            filterPlaceholder: 'Search by Exchange Rate',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            fieldType: 'date',
            filter: true,
            filterPlaceholder: 'Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            alignTo: 'center',
            width: '180px'
        },
        {
            header: 'Is Editable',
            field: 'is_editable',
            fieldType: 'checkbox',
            alignTo: 'center',
            bodyStyle: 'center',
            width: '100px'
        },
        {
            header: '',
            field: null,
            fieldType: 'actions',
            edit: true,
            editRoute: 'popup',
            delete: true,
            deleteRoute: 'popup',
            width: '80px'
        }
    ],
    BENEFICIARY_DETAILS: [
        {
            header: 'Beneficiary ID',
            field: 'ref',
            filter: true,
            filterPlaceholder: 'Search by Beneficiary ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '150px'
        },
        {
            header: 'Beneficiary Name',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Search by Beneficiary Name',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '180px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Beneficiary Category Name',
            field: 'category_name',
            filter: false,
            filterPlaceholder: 'Search by Beneficiary Category',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '180px',
            sortable: true,
            sortableDisabled: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Status',
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            width: '130px',
            sortable: true,
            sortableDisabled: true
        },
        {
            header: 'Is Active?',
            field: 'is_active',
            width: '70px',
            sortable: true,
            sortableDisabled: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            fieldType: 'date',
            filter: true,
            filterPlaceholder: 'Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            alignTo: 'center',
            width: '180px',
            sortable: true,
            sortableDisabled: true
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '125px'
        }
    ],
    BANK_DETAILS: [
        {
            header: 'Account Category',
            field: 'category',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Type',
            field: 'type',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Number',
            field: 'number',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'ifsc',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'bank_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Branch Name',
            field: 'branch_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Status',
            field: 'bank_active',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Currency',
            field: 'ccy',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '80px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Holder Name',
            field: 'holder_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            validation: 'unique',
            fieldType: 'switch',
            updatedField: 'is_active',
            filter: false,
            filterElement: null,
            sortable: false,
            label: 'Primary Account',
            width: '100px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '100px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            width: '100px'
        }
    ],
    SCHEDULED_JOBS: [
        {
            header: 'Scheduled Job ID',
            field: 'id',
            filter: true,
            filterPlaceholder: 'Search by Scheduled Job ID',
            filterMatchMode: 'contains',
            filterElement: null,
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Payroll Processing Month',
            field: 'month',
            filter: true,
            filterPlaceholder: 'Search by Payroll Processing Month',
            filterMatchMode: 'contains',
            filterElement: null,
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'From Date',
            field: 'start_date',
            filter: true,
            filterPlaceholder: 'Search by From Date',
            filterMatchMode: 'contains',
            filterElement: 'date',
            sortable: true,
            width: '200px',
            fieldType: 'date'
        },
        {
            header: 'To Date',
            field: 'end_date',
            filter: true,
            filterPlaceholder: 'Search by To Date',
            filterMatchMode: 'contains',
            filterElement: 'date',
            sortable: true,
            width: '200px',
            fieldType: 'date'
        },
        {
            header: 'Automatic Centralized Scheduler Run Date',
            field: 'run_date',
            filter: true,
            filterPlaceholder: 'Search by Automatic Centralized Scheduler Run Date',
            filterMatchMode: 'contains',
            filterElement: 'date',
            sortable: true,
            width: '200px',
            fieldType: 'date'
        },
        {
            header: 'Automatic Centralized Scheduler Run Time',
            field: 'run_time',
            filter: true,
            filterPlaceholder: 'Search by Automatic Centralized Scheduler Run Time',
            filterMatchMode: 'contains',
            filterElement: null,
            sortable: true,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Job Status',
            field: 'job_status',
            filter: false,
            filterElement: null,
            sortable: true,
            width: '120px',
            fieldType: 'tooltip'
        }
    ],
    Dcocument_Category: [
        {
            header: 'Document Category ID',
            field: 'ref',
            fieldType: 'clickable',
            customView: true,
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Document Category ID',
            width: '180px'
        },
        {
            header: 'Document Category Name',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Search by Document Category Name',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '200px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Upload Type',
            field: 'upload_type',
            filter: true,
            filterPlaceholder: 'Search by Upload Type',
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            width: '180px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'File Type',
            field: 'file_type',
            filter: true,
            filterPlaceholder: 'Search by File Type',
            filterElement: 'search',
            filterMatchMode: 'contains',
            width: '180px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'File Size',
            field: 'fileSize',
            filter: true,
            filterPlaceholder: 'Search by File Size',
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Status',
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            width: '130px'
        },
        {
            header: 'Active/Inactive',
            field: 'active_status',
            width: '150px',
            filter: true,
            filterPlaceholder: 'Search by Active/Inactive',
            filterElement: 'dropdown',
            filterMatchMode: 'contains'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            fieldType: 'date',
            filter: true,
            filterPlaceholder: 'Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            alignTo: 'center',
            width: '200px'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '125px'
        }
    ],
    DOC_CAT_MAPPINGS: [
        {
            header: '',
            field: 'checked',
            fieldType: 'checkbox',
            selectionMode: 'multiple',
            width: '55px'
        },
        {
            header: 'Module Name',
            field: 'value1',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Function',
            field: 'value2',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub Function',
            field: 'value3',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Attachment Mandatory? ',
            field: 'is_mandatory',
            width: '200px',
            fieldType: 'checkbox'
        }
    ]
};

export default CoMasTableSchema;
