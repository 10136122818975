import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../TableSchema';
import { Dropdown } from 'primereact/dropdown';
import Calendar from '../../../../components/Calendar/CalendarComponent';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import ConfirmationModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../Validation';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { getDate, serverDate, uniqueId } from '../../../../util';

const StayDetails = (props) => {
    const [rows, setRows] = useState(props.formStateObj?.Stay_Details);
    useEffect(() => {
        setRows(props.formStateObj?.Stay_Details);
        // eslint-disable-next-line
    }, [props.formStateObj?.Stay_Details]);
    let viewMode = TableSchema.Stay_Details.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.mode !== 'view' ? TableSchema.Stay_Details : viewMode;
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false,
        stayList: ['Hotel', 'Govt. Quarters', 'Other']
    });
    let emptyObj = {
        from_date: '',
        to_date: '',
        stay_type: '',
        halting_charge: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    const fields = [{ key: 'halting_charge', validators: 'numbersOnly', maxLength: 6 }];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);

        if (err[key] || (key === 'halting_charge' && parseInt(ev) <= 0)) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        if (key === 'from_date') {
            currentDetailsObj.to_date = '';
        }
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const saveForm = () => {
        currentDetailsObj.from_date = getDate(currentDetailsObj.from_date, true);
        currentDetailsObj.to_date = getDate(currentDetailsObj.to_date, true);
        if (state.actionType === 'edit') {
            let indx = rows.findIndex((a) => a.id === editObj.id);
            rows[indx] = currentDetailsObj;
            setRows(rows);
        } else {
            currentDetailsObj.id = uniqueId(20);
            rows.push(currentDetailsObj);
            setRows(rows);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Stay_Details', rows);
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                currentDetailsObj[key] === undefined ||
                currentDetailsObj[key] === '' ||
                (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === '')
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            newObj['from_date'] = new Date(newObj.from_date);
            newObj['to_date'] = new Date(newObj.to_date);
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = rows.filter((a) => {
            return a.id !== currentDetailsObj.id;
        });
        setRows(filteredRows);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Stay_Details', filteredRows);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        let detObj = JSON.parse(JSON.stringify(emptyObj));
        detObj.stay_type = 'Hotel';
        setCurrentDetailsObj(detObj);
        setEditObj({});
    };

    let footerGroup = () => {
        let total = 0;
        rows.forEach((a) => {
            total = total + parseInt(a.halting_charge);
        });
        return (
            <ColumnGroup>
                <Row>
                    <Column footer="Total" colSpan={3} />
                    <Column footer={isNaN(total) ? '' : total} footerStyle={{ textAlign: 'right' }} />
                    {props.mode !== 'view' && <Column />}
                </Row>
            </ColumnGroup>
        );
    };
    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Stay Details are yet to be created"
                    className="secondary-table"
                    scrollable
                    mode={props?.mode}
                    footerGroup={footerGroup()}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Date From{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Calendar
                                            className="w-100"
                                            maxDate={new Date(serverDate().setDate(serverDate().getDate() - 1))}
                                            readOnlyInput={true}
                                            placeholder="Select Date From"
                                            appendTo={document.self}
                                            value={currentDetailsObj.from_date}
                                            onChange={(e) => handleChange(e.target.value, 'from_date')}
                                            dateFormat="dd M yy"
                                            showIcon
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Date To{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Calendar
                                            className="w-100"
                                            minDate={
                                                new Date(
                                                    new Date(currentDetailsObj.from_date)?.setDate(
                                                        new Date(currentDetailsObj.from_date)?.getDate() + 1
                                                    )
                                                )
                                            }
                                            disabled={!currentDetailsObj.from_date}
                                            readOnlyInput={true}
                                            placeholder="Select Date To"
                                            appendTo={document.self}
                                            value={currentDetailsObj.to_date}
                                            onChange={(e) => handleChange(e.target.value, 'to_date')}
                                            dateFormat="dd M yy"
                                            showIcon
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Stay Type{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="stay_type"
                                            value={currentDetailsObj.stay_type}
                                            onChange={(e) => handleChange(e.value, 'stay_type')}
                                            appendTo={document.self}
                                            options={state.stayList}
                                            placeholder="Select Stay Type"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Halting Charges{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="halting_charge"
                                            type="text"
                                            value={currentDetailsObj.halting_charge}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e, 'halting_charge')}
                                            placeholder="Enter Halting Charges"
                                            hasError={errors && errors.halting_charge}
                                            errMsg="Please enter valid Halting Charges"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.mode !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (rows?.length === 0 ? '' : 'Another ') + 'Stay Details'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Stay Details"
                    showModal={state.showDeleteModal}
                    name={'Stay Details : From: ' + currentDetailsObj.from_date + ' - To: ' + currentDetailsObj.to_date}
                />
            )}
        </div>
    );
};

export default StayDetails;
