import React, { useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import TableSchema from '../../../expenditureManagement/TableSchema';
import CommonInlineTable from '../../../../components/commonTable/CommonInlineTable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import Beneficiaries from './Beneficiaries';
import ExpenditureSchema from '../../../../schema/ExpenditureSchema';
import { employeeClient, expenditureClient } from '../../../../apollo';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

const ThirdParty = (props) => {
    let [rows, setRows] = useState([]);
    const [disableupdate, setDisableupdate] = useState(false);
    const [currentTotal] = useState(props.netPay);
    const [showPopup, setPopup] = useState(false);
    const [selectedObj, setSelObj] = useState({});

    const calculateAmounts = (arr) => {
        arr.forEach((val) => {
            let amount = (currentTotal / 100) * val?.share;
            val.amount = amount ? parseFloat(amount).toFixed(2) : amount;
            val['original_amount'] = amount;
            val['original_share'] = val.share;
        });
        return arr;
    };

    let { loading: empLoader, data: empBankAccounts } = useQuery(ExpenditureSchema.employeeBankAccounts, {
        fetchPolicy: 'no-cache',
        client: employeeClient,
        variables: {
            whereEmp: { emp_id: { equals: props.formState?.BILLS?.employee?.id || '' } }
        },
        onCompleted: (rec) => {
            if (rec && props.recordID && props.thirdParties?.length > 0) {
                getBeneficiaries({
                    variables: {
                        where: {
                            beneficiary_id: {
                                in: props.thirdParties.map((a) => a.beneficiary_id)
                            },
                            is_primary: { equals: true }
                        }
                    }
                });
            } else if (rec.employeeBankAccounts?.length > 0) {
                if (!props.recordID && props.thirdParties?.length > 0) {
                    let arr = [...setEmpAccounts(), ...JSON.parse(JSON.stringify(props.thirdParties))];
                    let reCalculateAmounts = calculateAmounts(arr);
                    setRows(reCalculateAmounts);
                } else {
                    setData([]);
                }
            }
        },

    });

    const mapData = (list, key) => {
        return list
            .map((val) => {
                return val[key];
            })
            .reduce(function (a, b) {
                return a + b;
            }, 0);
    };

    const setEmpAccounts = () => {
        let empAccounts = empBankAccounts.employeeBankAccounts.filter((v) => {
            return v.is_primary;
        });
        let share = mapData(props.thirdParties, 'share');
        let amount = mapData(props.thirdParties, 'amount');
        let empRows = [];
        empAccounts &&
            empAccounts.forEach((val) => {
                let obj = {};
                obj['holder_name'] = val?.account?.holder_name;
                obj['account_number'] = val?.account?.number;
                obj['ifsc_code'] = val?.account?.bank?.ifsc;
                obj['bank_name'] = val?.account?.bank?.name;
                obj['branch_name'] = val?.account?.bank?.branch_name;
                obj['edit'] = false;
                obj['delete'] = false;
                obj['isEdit'] = true;
                obj['hideEdit'] = true;
                obj['hideDelete'] = true;
                obj['share'] = obj['original_share'] = Number.isInteger(100 - share) ? 100 - share : parseFloat(100 - share).toFixed(2);
                obj['amount'] = obj['original_amount'] = parseFloat(parseFloat(currentTotal) - parseFloat(amount)).toFixed(2);
                empRows.push(obj);
            });
        return empRows;
    };

    const setData = (empBenify) => {
        rows = setEmpAccounts();
        empBenify &&
            empBenify.forEach((val) => {
                let beObj = props.thirdParties.find((a) => a.beneficiary_id === val.beneficiary_id);
                let obj = {};
                obj['holder_name'] = val?.account?.holder_name;
                obj['account_number'] = val?.account?.number;
                obj['ifsc_code'] = val?.account?.bank?.ifsc;
                obj['bank_name'] = val?.account?.bank?.name;
                obj['branch_name'] = val?.account?.bank?.branch_name;
                obj['edit'] = false;
                obj['delete'] = false;
                obj['isEdit'] = true;
                obj['share'] = parseFloat(beObj?.share);
                obj['amount'] = parseFloat(beObj?.amount).toFixed(2);
                obj['original_share'] = beObj?.share;
                obj['original_amount'] = beObj?.amount;
                obj['id'] = beObj?.id;
                obj['beneficiary_id'] = val.beneficiary_id;
                obj['validation'] = true;
                rows.push(obj);
            });
        rows.forEach((a, index) => (a.slno = index + 1));
        let reCalculateAmounts = calculateAmounts(rows);
        setRows(reCalculateAmounts);
    };

    const [getBeneficiaries, { loading: beneLoader }] = useLazyQuery(ExpenditureSchema.beneficiaryAccounts, {
        fetchPolicy: 'no-cache',
        onCompleted: (rec) => {
            if (rec) {
                let empBenify = rec.beneficiaryAccounts;
                setData(empBenify);
            }
        },

    });
    const [createBeneficiary, { loading: createLoader }] = useMutation(ExpenditureSchema.createOnePBThirdPartyPayment, {
        client: expenditureClient,
        onCompleted: (data) => {
            if (data) {
                selectedObj.id = data.createOnePBThirdPartyPayment?.id;
                rows.push(selectedObj);
                props.setThirdPartyPayments(rows);
                setRows(rows);
                setSelObj('');
                props.growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.Beneficiary
                });
            }
        },

    });

    const [updateBeneficiary, { loading: updateLoader }] = useMutation(ExpenditureSchema.updateOnePBThirdPartyPayment, {
        client: expenditureClient,
        onCompleted: (data) => {
            if (data) {
                rows.forEach((val) => {
                    if (val.id === selectedObj.id) {
                        val['original_share'] = val.share = selectedObj?.share;
                        val['original_amount'] = val.amount = selectedObj?.amount;
                    }
                });
                props.setThirdPartyPayments(rows);
                setRows(rows);
                setSelObj('');
                props.growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.BeneficiaryUpdate
                });
            }
        },
        onError: () => {
            rows.forEach((val) => {
                val['share'] = val?.original_share;
                val['amount'] = val?.original_amount;
            });
        }
    });

    const [deleteBeneficiary, { loading: deleteLoader }] = useMutation(ExpenditureSchema.deleteOnePBThirdPartyPayment, {
        client: expenditureClient,
        onCompleted: (data) => {
            if (data) {
                deleteRows(selectedObj);
                setSelObj('');
                props.growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.BeneficiaryDelete
                });
            }
        },

    });

    const getPaymentAmt = () => {
        let benifyTotal = 0;
        rows && rows.forEach((a) => (benifyTotal = benifyTotal + parseFloat(a.amount)));
        return parseFloat(benifyTotal).toFixed(2);
    };

    const footer = () => {
        return (
            <ColumnGroup>
                <Row>
                    <Column footer="Total" colSpan={7} />
                    <Column footer={() => getPaymentAmt()} />
                    <Column footer="" />
                </Row>
            </ColumnGroup>
        );
    };

    const getTotals = (list, key) => {
        return list
            .map((val, i) => {
                return i !== 0 ? parseFloat(val[key]) : 0;
            })
            .reduce(function (a, b) {
                return parseFloat(a) + parseFloat(b);
            }, 0);
    };

    const handleChange = (updatedRows, index, field) => {
        if (updatedRows[index][field] === null) updatedRows[index][field] = 0;
        if (currentTotal === 0) {
            props.growl.show({
                life: 6000,
                severity: 'error',
                summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.CheckNetPayment
            });
            return;
        }
        if (field === 'share') {
            let currentShare = updatedRows[index][field] ? updatedRows[index][field] : 0;
            let currAmount = (currentTotal / 100) * currentShare;
            updatedRows[index]['amount'] = parseFloat(currAmount).toFixed(2);

            let totalShare = getTotals(rows, 'share');
            let totalAmount = getTotals(rows, 'amount');

            updatedRows[0]['amount'] = parseFloat(currentTotal - totalAmount).toFixed(2);
            let shareVal = 100 - totalShare;
            updatedRows[0]['share'] = Number.isInteger(shareVal) ? shareVal : parseFloat(shareVal).toFixed(2);
        }
        if (field === 'amount') {
            let currAmount = updatedRows[index][field] ? updatedRows[index][field] : 0;
            let currentShare = (currAmount / currentTotal) * 100;
            updatedRows[index]['share'] = parseFloat(currentShare).toFixed(2);

            let totalShare = getTotals(rows, 'share');
            let totalAmount = getTotals(rows, 'amount');

            updatedRows[0]['amount'] = parseFloat(currentTotal - totalAmount).toFixed(2);
            let shareVal = 100 - totalShare;
            updatedRows[0]['share'] = Number.isInteger(shareVal) ? shareVal : parseFloat(shareVal).toFixed(2);
        }
        if (updatedRows[0]['amount'] < 0 || updatedRows[0]['share'] < 0) setDisableupdate(true);
        else setDisableupdate(false);
        setRows(updatedRows);
    };

    const setDataToRows = (tableRows) => {
        tableRows.forEach((a, i) => (a.slno = i + 1));
        return tableRows;
    };

    const updateRecord = (rowData, submit) => {
        if (submit) {
            if (parseInt(rowData?.share) < 0 || parseInt(rowData?.amount) < 0) {
                props.growl.show({
                    life: 6000,
                    severity: 'error',
                    summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.BeneficiaryShareValue
                });
                rows.forEach((val) => {
                    val['share'] = val?.original_share;
                    val['amount'] = val?.original_amount;
                });
                return;
            }
            if (
                rowData?.share === 0 ||
                rowData?.amount === 0 ||
                rowData?.share === '0.00' ||
                rowData?.amount === '0.00' ||
                !rowData?.share ||
                !rowData?.amount
            ) {
                props.growl.show({
                    life: 6000,
                    severity: 'error',
                    summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.BeneficiaryShare
                });
                rows.forEach((val) => {
                    val['share'] = val?.original_share;
                    val['amount'] = val?.original_amount;
                });
                return;
            }

            if (props.recordID && !props.isDraft) {
                setSelObj(rowData);
                let obj = {
                    beneficiary_id: { set: rowData?.beneficiary_id },
                    share: { set: parseFloat(rowData.share) },
                    amount: { set: parseFloat(rowData.amount) }
                };
                updateBeneficiary({
                    variables: {
                        data: obj,
                        where: {
                            id: rowData.id
                        }
                    }
                });
            } else {
                rows.forEach((val) => {
                    if (val.id === rowData.id) {
                        val['original_share'] = val.share = rowData?.share;
                        val['original_amount'] = val.amount = rowData?.amount;
                    }
                });
                setRows(rows);
                props.setThirdPartyPayments(rows);
                props.growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.BeneficiaryUpdate
                });
            }
        } else {
            rows.forEach((val) => {
                val['share'] = val?.original_share;
                val['amount'] = val?.original_amount;
            });
        }
    };

    const deleteRows = (rowData) => {
        let filteredRows = rows.filter((re) => re.id !== rowData.id);
        if (filteredRows?.length > 0) {
            let totalShare = getTotals(filteredRows, 'share');
            let totalAmount = getTotals(filteredRows, 'amount');
            filteredRows[0]['amount'] = parseFloat(currentTotal - totalAmount).toFixed(2);
            let shareVal = 100 - totalShare;
            filteredRows[0]['share'] = Number.isInteger(shareVal) ? shareVal : parseFloat(shareVal).toFixed(2);
        }
        setRows(filteredRows);
        props.setThirdPartyPayments(filteredRows);
    };

    const handleDeleteBenificiary = (rowData) => {
        if (props.recordID && !props.isDraft) {
            setSelObj(rowData);
            deleteBeneficiary({
                variables: {
                    where: { id: rowData.id }
                }
            });
        } else {
            deleteRows(rowData);
            props.growl.show({
                life: 6000,
                severity: 'success',
                summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.BeneficiaryDelete
            });
        }
    };

    const handleAddBenificiary = (data) => {
        let primaryAcc = data?.selected?.accounts?.find((a) => a.is_primary);
        let selObj = {};
        selObj['holder_name'] = primaryAcc?.account?.holder_name;
        selObj['account_number'] = primaryAcc?.account?.number;
        selObj['ifsc_code'] = primaryAcc?.account?.bank?.ifsc;
        selObj['bank_name'] = primaryAcc?.account?.bank?.name;
        selObj['branch_name'] = primaryAcc?.account?.bank?.branch_name;
        selObj['beneficiary_id'] = primaryAcc?.beneficiary_id;
        selObj['id'] = primaryAcc?.id;
        selObj['original_share'] = selObj['share'] = 0;
        selObj['original_amount'] = selObj['amount'] = 0;
        selObj['edit'] = true;
        selObj['delete'] = true;
        selObj['isEdit'] = true;
        selObj['validation'] = true;
        if (props.recordID && !props.isDraft) {
            let obj = { beneficiary_id: data?.selected?.id, share: 0, amount: 0, bill: { connect: { id: props.recordID } } };
            setSelObj(selObj);
            createBeneficiary({
                variables: {
                    data: obj
                }
            });
        } else {
            rows.push(selObj);
            setRows(rows);
            props.setThirdPartyPayments(rows);
            props.growl.show({
                life: 6000,
                severity: 'success',
                summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.Beneficiary
            });
        }
        setPopup(false);
    };

    const handleModel = () => {
        let totalShare = rows
            .map((val, i) => {
                return i !== 0 ? val.share : 0;
            })
            .reduce(function (a, b) {
                return a + b;
            }, 0);
        if (totalShare < 100) setPopup(true);
        else {
            props.growl.show({
                life: 6000,
                severity: 'error',
                summary: window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.TotalShare
            });
        }
    };

    const getIds = () => {
        let list = [];
        if (rows.length > 0) {
            list = rows.filter((a) => a.beneficiary_id)?.map((b) => b.beneficiary_id);
        }
        return list;
    };

    return (
        <div className="pos-rel">
            {(empLoader || beneLoader || deleteLoader || updateLoader || createLoader) && (
                <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />
            )}
            <>
                <div className="page-header">
                    {<span className="light-color">Third Party Payment</span>}

                    <span className="backBtn pointer">
                        <span
                            onClick={() => {
                                props.handleBack();
                            }}
                        >
                            <span className="appkiticon icon-left-chevron-outline icon_size"></span>Back
                        </span>
                    </span>
                </div>
                <div className="page-content-ht-without-btns">
                    <CommonInlineTable
                        className="secondary-table m-t-20"
                        rows={() => setDataToRows(rows)}
                        columns={TableSchema.THIRD_PARTY}
                        emptyMessage="No records found"
                        noPaginator={true}
                        formType={props?.formType}
                        footerGroup={footer()}
                        updateTableRows={handleChange}
                        type="Beneficiary"
                        disableUpdate={disableupdate}
                        updateRecord={updateRecord}
                        deleteRecord={handleDeleteBenificiary}
                        scrollable={true}
                    ></CommonInlineTable>
                    {props?.formType !== 'view' && (
                        <div className="comment-header f-14">
                            <span className="comment-color pointer m-l-5" onClick={() => handleModel()}>
                                +&nbsp;Select Beneficiary
                            </span>
                        </div>
                    )}
                </div>
            </>
            {showPopup && (
                <Beneficiaries
                    showModal={showPopup}
                    ids={getIds()}
                    handleCancel={() => setPopup(false)}
                    handleSave={handleAddBenificiary}
                    columns={TableSchema.PAY_BENIFY_LIST}
                ></Beneficiaries>
            )}
        </div>
    );
};

export default ThirdParty;
