export const FilterConfig = {
    CONF_SUPL_ACTIVE_INACTIVE: [
        { label: 'Active', value: 'true' },
        { label: 'Deactive', value: 'false' }
    ],
    CONF_SUPL_WRKFLOW_STATUS: [
        { label: 'Draft', value: 'Draft' },
        { label: 'Approved', value: 'Approved' }
    ],
    CONF_CON_PROJECT_STATUS: [
        { label: 'Open', value: 'Open' },
        { label: 'Closed', value: 'Closed' }
    ],
    ACTIVE_INACTIVE: [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' }
    ],
    GLOBAL_NONGLOBAL: [
        { label: 'Global', value: 'Global' },
        { label: 'Non-Global', value: 'Non-Global' }
    ],
    YES_NO: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' }
    ],
    YES_NO_OPTIONS: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' }
    ],
    DEPOSIT_STATUS: [
        { label: 'Amount Deposited', value: 'Amount Deposited' },
        { label: 'DV Generated', value: 'DV Generated' },
        { label: 'DV Failed', value: 'DV Failed' },
        { label: 'Rejected', value: 'Rejected' }
    ],
    VALUE_TYPES: [
        { label: 'Manual', value: 'Manual' },
        { label: 'Fixed', value: 'Fixed' },
        { label: 'Percentage', value: 'Percentage' }
    ],
    BILL_TYPES: [
        { label: 'Advance Bill', value: 'Advance Bill' },
        { label: 'Running Bill', value: 'Running Bill' },
        { label: 'Final Bill', value: 'Final Bill' },
        { label: 'Security Deposit Refund', value: 'Security Deposit Refund' }
    ],
    PAYMENT_MODE: [
        { label: 'POS', value: 'POS' },
        { label: 'Cash', value: 'Cash' },
        { label: 'Cheque', value: 'Cheque' },
        { label: 'DD', value: 'DD' },
        { label: 'UPI', value: 'UPI' },
        { label: 'NEFT', value: 'NEFT' }
    ],
    AMOUNT_STATUS: [
        { label: 'Pending', value: 'Pending' },
        { label: 'Submitted', value: 'Submitted' }
    ],
    STAKEHOLDER_CATEGORY: [
        { label: 'Donors', value: 'Donors' },
        { label: 'Banks', value: 'Banks' },
        { label: 'Others', value: 'Others' }
    ],
    MANAGEMENT_RESPONSE: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        { label: 'Alternative', value: 'Alternative' }
    ],
    AUDIT_METHODS: [
        { label: 'Substantive Test', value: 'Substantive Test' },
        { label: 'Control Test', value: 'Control Test' },
        { label: 'Both', value: 'Both' }
    ]
};

export default FilterConfig;
