import React, { useState, useEffect } from 'react';
import FormSchema from './formSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';

const PaymentTermsForm = (props) => {
    const [state] = useState({
        loading: false,
        paymentModeList: ['EFT', 'Cash', 'Cheque'],
        paymentTermList: []
    });

    const [formState, setFormState] = useState({
        paymentTerm: '30 days',
        paymentMode: props.Payment_Terms.paymentMode
    });

    useEffect(() => {
        props.saveFormData('Payment_Terms', formState);
        // eslint-disable-next-line
    }, [formState]);

    return (
        <div className={`coa-card overflow-initial p-10 ${state.loading ? 'mask' : ''}`}>
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div className="row scdropdown">
                {FormSchema.FLD_PT_PAY_MODE.is_visible !== -1 && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                        <b className="w-40 fs-14">
                            {FormSchema.FLD_PT_PAY_MODE.name}
                            {FormSchema.FLD_PT_PAY_MODE.is_mandatory !== -1 && props.formType !== 'view' ? (
                                <span className="icon-primary"> *</span>
                            ) : (
                                ' :'
                            )}
                        </b>
                        {props.formType !== 'view' && (
                            <Dropdown
                                className="w-60"
                                appendTo={document.self}
                                name="supplierContact"
                                value={formState.paymentMode}
                                onChange={(e) => setFormState({ ...formState, paymentMode: e.value })}
                                options={state?.paymentModeList}
                                placeholder="Select Payment Mode"
                            />
                        )}
                        {props.formType === 'view' && formState.paymentMode}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentTermsForm;
