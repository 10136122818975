import React from 'react';
import { Input, Button } from 'appkit-react';

function SignUp(props) {
    return (
        <div className="p-l-30 p-r-30">
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-60">
                <Input
                    inputBoxSize="lg"
                    key="username"
                    placeholder="Enter Name"
                    type="text"
                    autoComplete="off"
                    value={props.properties.username}
                    onChange={(e) => props.handleChange(e, 'username')}
                />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-20">
                <Input
                    inputBoxSize="lg"
                    key="username"
                    placeholder="Enter Mobile Number"
                    type="number"
                    autoComplete="off"
                    value={props.properties.password}
                ></Input>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-20">
                <Input
                    inputBoxSize="lg"
                    key="username"
                    placeholder="Enter Email ID"
                    type="text"
                    value={props.properties.username}
                    autoComplete="off"
                />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-20">
                <Input
                    inputBoxSize="lg"
                    key="username"
                    placeholder="Enter Password"
                    type="password"
                    autoComplete="off"
                    value={props.properties.password}
                ></Input>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-20">
                <Input
                    inputBoxSize="lg"
                    key="username"
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="off"
                    value={props.properties.password}
                ></Input>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12">
                <Button size="lg" className="w-100 m-t-20" onClick={props.backToLogin}>
                    Submit
                </Button>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-20 text-center">
                <span>Or</span>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12">
                <Button size="lg" className="w-100 m-t-20" onClick={props.backToLogin}>
                    Back To Login
                </Button>
            </div>
        </div>
    );
}

export default SignUp;
