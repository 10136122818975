import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'appkit-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';

export function CreateRemitCatMap(props) {
    const [state, setState] = useState({
        remitances: props.dropdownValues || [],
        remitance: [],
        loading: false
    });

    const handleSave = () => {
        if (state.remitance) {
            setState({ ...state, loading: true });
            props.handleCreate(state.remitance.toString());
        }
    };

    const handleModalBody = () => {
        return (
            <div className="row">
                {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12">
                    <div className="row">
                        <div className="col-md-5">
                            <b className="f-14">
                                Remittance<span className="icon-primary p-l-5">*</span>
                            </b>
                        </div>
                        <div className="col-md-7">
                            <Dropdown
                                name="remitance"
                                value={state.remitance}
                                onChange={(e) => setState({ ...state, remitance: e.value })}
                                options={state.remitances}
                                className="w-100"
                                validations={['required']}
                                optionValue="ids"
                                optionLabel="remittance_name"
                                filter={true}
                                placeholder="Select Remittance"
                                filterPlaceholder="Search"
                                filterBy="remittance_name"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Modal
            visible={props.showModal}
            backdropClosable={false}
            onCancel={props.handleCancel}
            className={state.loading ? 'mask common_css add_user' : 'common_css add_user'}
        >
            <ModalHeader>
                <div>Add New {props.remitType.modalTitle}</div>
            </ModalHeader>

            <ModalBody>{handleModalBody()}</ModalBody>
            <ModalFooter className="p-10">
                <div className={`m-r-0 ${state.loading ? 'mask' : ''}`}>
                    <Button size="sm" kind="primary" className="m-r-10" disabled={state.remitance?.length <= 0} onClick={handleSave}>
                        Save
                    </Button>
                    <Button size="sm" kind="secondary" onClick={props.handleCancel}>
                        Cancel
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default CreateRemitCatMap;
