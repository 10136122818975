export const FormSchema = {
    SEC_SUP_DTLS: { name: 'Supplier Details', desc: 'test' },
    SEC_BK_ACC: { name: 'Bank Account Details', desc: 'test' },
    SEC_ADDR: { name: 'Address', desc: 'test' },
    SEC_CNTCT_DETAILS: { name: 'Contact Details' },
    SEC_LNK_PRNT_SBSDRY_BRNCH: { name: 'Link Parent or Subsidiary/Branch' },
    SEC_TRANS_DTLS: { name: 'Transaction Details' },
    SEC_OTHER_INV: { name: 'Other Invoices' },
    SEC_DCMNTS: { name: 'Documents' },
    SEC_CHKLST: { name: 'Checklist' },

    FLD_SUP_DTLS_SUP_NAME: {
        name: 'Supplier Name',
        desc: 'Supplier Name',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_SUP_DTLS_ALT_NAME: {
        name: 'Alternate Name',
        desc: 'Alternate Name',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_SUP_DTLS_SPLY_CTGRY: {
        name: 'Supply Category',
        desc: 'Supply Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    FLD_BK_ACC_CTGRY: {
        name: 'Account Category',
        key: 'acc_category',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_BK_ACC_TYP: {
        name: 'Account Type',
        key: 'acc_type',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_BK_ACC_NO: {
        name: 'Account Number',
        key: 'acc_number',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_BK_ACC_IFSC: {
        name: 'IFSC Code',
        key: 'ifsc_code',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_BK_ACC_NAME: {
        name: 'Bank Name',
        key: 'bank_name',
        desc: 'Account Category',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_BK_ACC_BRANCH: {
        name: 'Branch Name',
        key: 'branch_name',
        desc: 'Account Category',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_BK_ACC_CCY: {
        name: 'Currency',
        key: 'ccy',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_BK_ACC_HLDR_NAME: {
        name: 'Account Holder Name',
        key: 'acc_holder_name',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_BK_ACC_IS_PRMRY: {
        name: 'Is Primary ?',
        key: 'is_primary',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_BK_ACC_IS_ACTV: {
        name: 'Is Active ?',
        key: 'is_active',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    FLD_ADDR_ADDR_LINE1: {
        name: 'Address Line 1',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_ADDR_LINE2: {
        name: 'Address Line 2',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_ADDR_LINE3: {
        name: 'Address Line 3',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_PIN: {
        name: 'PIN Code',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_CNTRY: {
        name: 'Country',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_STATE: {
        name: 'State',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_DIST_CTY: {
        name: 'District/City',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_SUB_DIST_BLK_TEHSIL: {
        name: 'Sub-District/Block/Tehsil',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_VLG: {
        name: 'Village',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_PNCHYT: {
        name: 'Panchayat',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_IS_PRMRY: {
        name: 'Is Primary ?',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_ADDR_IS_ACTV: {
        name: 'Is Active ?',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    FLD_CTRCT_SALTIN: {
        name: 'Salutation',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_FST_NM: {
        name: 'First Name',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_MDL_NM: {
        name: 'Middle Name',
        desc: 'Account Category',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_LST_NM: {
        name: 'Last Name',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_DSGNTIN: {
        name: 'Designation',
        desc: 'Account Category',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_MBL_NBR: {
        name: 'Mobile Number',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_ALT_CNTCT_NMB: {
        name: 'Alternative Contact Number',
        desc: 'Account Category',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_EMIAL: {
        name: 'Email ID',
        desc: 'Account Category',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_FX_NMB: {
        name: 'Fax Number',
        desc: 'Account Category',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_IS_ACTV: {
        name: 'Is Active ?',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CTRCT_IS_PRMRY: {
        name: 'Is Primary ?',
        desc: 'Account Category',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    FLD_LINK_PRNT_IS_ENTITY_CRTD: {
        name: 'Is Entity Created Parent or Subsidiary/Branch ?',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_LINK_PRNT_IS_ENTITY_SPLIER: {
        name: 'Supplier',
        desc: 'Account Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    }
};

export default FormSchema;
