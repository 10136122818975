/* eslint-disable max-depth */
import store from './index';

const serverDate = () => {
    return new Date(localStorage.getItem('SERVER_DATE'));
};

const getDate = (date, dateWithoutTime) => {
    const sDate = localStorage.getItem('SERVER_DATE');
    if (!date) {
        date = new Date(sDate);
    }
    date = new Date(date);
    let DATE_OPTIONS = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };
    if (dateWithoutTime) {
        DATE_OPTIONS = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };
    }
    return date.toLocaleDateString('en-US', DATE_OPTIONS);
};

const getDateOnly = (date) => {
    return getDate(date, true);
};

const getDateUTCFormat = (param) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateObj = new Date(param);
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate() - 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    const output = month + '\n' + day + ', ' + year;
    return output;
};

const getUTCTime = (date) => {
    return date === '' || date === null ? date : new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

const uniqBy = (arr, predicate) => {
    const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];
    return [
        ...arr
            .reduce((map, item) => {
                const key = item === null || item === undefined ? item : cb(item);
                map.has(key) || map.set(key, item);
                return map;
            }, new Map())
            .values()
    ];
};

const uniqueId = (length) => {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};
const getMonth = (val) => {
    return val > 9 ? '-' + val : '-0' + val;
};

const getDateVal = (val) => {
    return val > 9 ? val : '0' + val;
};
const convertTextCase = (name, param) => {
    let result;
    if (param) {
        result = name?.charAt(0)?.toUpperCase() + name?.substr(1)?.toLowerCase();
    } else {
        result = name?.charAt(0)?.toUpperCase() + name?.substr(1);
    }
    return result;
};
const getUTCGteLteFromDate = (dateVal) => {
    const time = new Date(dateVal);
    const previousDay = new Date(dateVal - 1);
    const gte =
        previousDay.getFullYear() + getMonth(previousDay.getMonth() + 1) + '-' + getDateVal(previousDay.getDate()) + 'T18:31:00.000Z';
    const lte = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T18:29:00.000Z';

    return { gte: gte, lte: lte };
};

let userInfo = JSON.parse(localStorage.getItem('USER_INF'));
const SUPER_ADMIN = 'SUPER_ADMIN';
const CONCURRENT_APPROVER = 'CONCURRENT_APPROVER';

const isSuperAdmin = () => {
    if (userInfo) {
        let userRoles = userInfo.roles;
        for (let i = 0; i < userRoles.length; i++) {
            if (userRoles[i].role.name === SUPER_ADMIN) {
                return true;
            }
        }
    }
    return false;
};

const isConcurrentApprover = () => {
    userInfo = JSON.parse(localStorage.getItem('USER_INF'));
    if (userInfo) {
        let userRoles = userInfo.roles;
        for (let i = 0; i < userRoles.length; i++) {
            if (userRoles[i].role.name === CONCURRENT_APPROVER) {
                return true;
            }
        }
    }
    return false;
};

const isAssignmentAuthorized = (param) => {
    let isAllow = false;
    let auditHistory = localStorage.getItem('audit_history');
    let conclusionStatus = JSON.parse(localStorage.getItem('AAPE_CONCLUSION_STATUS'));
    if (param && param === 'COI') {
        if (conclusionStatus?.is_auditor) isAllow = true;
    } else if (param && param === 'AUDITOR_PROGRAM') {
        if (conclusionStatus?.is_auditor && conclusionStatus?.is_coi_declared && !conclusionStatus?.is_auditor_replaced) isAllow = true;
    } else {
        if (conclusionStatus?.is_auditor && conclusionStatus?.is_coi_declared) isAllow = true;
    }
    return auditHistory === 'yes' ? false : isAllow;
};

const getPrivileges = () => {
    let userPrivileges = store.getState()?.appReducer?.auth?.privileges;
    return userPrivileges;
};

const isValidCUID = (id) => {
    if (typeof id !== 'string') return false;
    if (id.startsWith('c')) return true;
    return false;
};

const cuid = () => {
    var letter = 'c',
        timestamp = new Date().getTime().toString('36'),
        random = uniqueId(16);
    return letter + timestamp + random;
};

export {
    getDate,
    getDateOnly,
    getUTCTime,
    uniqueId,
    uniqBy,
    getMonth,
    getDateVal,
    convertTextCase,
    getUTCGteLteFromDate,
    isSuperAdmin,
    isConcurrentApprover,
    serverDate,
    getDateUTCFormat,
    isAssignmentAuthorized,
    getPrivileges,
    isValidCUID,
    cuid
};
