import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../TableSchema';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import ConfirmationModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../Validation';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { uniqueId } from '../../../../util';
import '../../../../styles/utils.scss';

const FamilyMembers = (props) => {
    const [rows, setRows] = useState(props.formStateObj?.Family_Members);
    useEffect(() => {
        setRows(props.formStateObj?.Family_Members);
        // eslint-disable-next-line
    }, [props.formStateObj?.Family_Members]);
    let viewMode = TableSchema.Family_Members.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.mode !== 'view' ? TableSchema.Family_Members : viewMode;
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false,
        relationList: ['Father', 'Mother', 'Son', 'Daughter', 'Wife', 'Husband', 'Guardian']
    });
    let emptyObj = {
        name: '',
        age: '',
        relationship: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    const fields = [
        { key: 'name', validators: 'isAlpha', maxLength: 30 },
        { key: 'age', validators: 'numbersOnly', maxLength: 3 }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);

        if (err[key] || (key === 'age' && parseInt(ev) <= 0) || (typeof ev === 'string' && ev.trim() === '')) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const saveForm = () => {
        currentDetailsObj.name = currentDetailsObj.name?.trim();
        if (state.actionType === 'edit') {
            let indx = rows.findIndex((a) => a.id === editObj.id);
            rows[indx] = currentDetailsObj;
            setRows(rows);
        } else {
            currentDetailsObj.id = uniqueId(20);
            rows.push(currentDetailsObj);
            setRows(rows);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Family_Members', rows);
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                currentDetailsObj[key] === undefined ||
                currentDetailsObj[key] === '' ||
                (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === '')
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = rows.filter((a) => {
            return a.id !== currentDetailsObj.id;
        });
        setRows(filteredRows);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Family_Members', filteredRows);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    let footerGroup = () => {
        let claims = 0;
        let fare = 0;
        if (rows.length > 0) {
            rows.forEach((ro) => {
                claims = claims + parseInt(ro.claim);
                fare = fare + parseInt(ro.fare);
            });
        }
        return (
            <ColumnGroup>
                <Row>
                    <Column footer="Total No. Of Members" colSpan={1} />
                    <Column footer={rows.length} className="align-left" colSpan={3} />
                </Row>
            </ColumnGroup>
        );
    };

    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Family Members are yet to be created"
                    className="secondary-table"
                    footerGroup={footerGroup()}
                    scrollable
                    mode={props?.mode}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Name{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="name"
                                            type="text"
                                            value={currentDetailsObj.name}
                                            maxLength={30}
                                            onChange={(e) => handleChange(e, 'name')}
                                            placeholder="Enter Name"
                                            hasError={errors && errors.name}
                                            errMsg="Please enter valid Name"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Age{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="age"
                                            type="text"
                                            value={currentDetailsObj.age}
                                            maxLength={3}
                                            onChange={(e) => handleChange(e, 'age')}
                                            placeholder="Enter Age"
                                            hasError={errors && errors.age}
                                            errMsg="Please enter valid Age"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Relationship{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="relationship"
                                            value={currentDetailsObj.relationship}
                                            onChange={(e) => handleChange(e.value, 'relationship')}
                                            appendTo={document.self}
                                            options={state.relationList}
                                            placeholder="Select Relationship"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.mode !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (rows?.length === 0 ? '' : 'Another ') + 'Family Members'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Family Members"
                    showModal={state.showDeleteModal}
                    name={'Family Member : ' + currentDetailsObj.name}
                />
            )}
        </div>
    );
};

export default FamilyMembers;
