import React from 'react';

export default function URRightContainer(props) {
    return (
        <div>
            <div className="page-header w-100 justify-content-between">
                <span className="light-color">{props.title}</span>
                {props.hasBack ? (
                    <span className={'backBtn pointer '} onClick={() => props.history.goBack()}>
                        <span className="appkiticon icon-left-chevron-outline icon_size"></span>
                        <span>Back</span>
                    </span>
                ) : (
                    ''
                )}
            </div>
            <div className="coa-card-content">{props.children}</div>
        </div>
    );
}
