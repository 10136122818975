export const FmisFunctions = {
    supplyCategory: 'CNTR_SUPPLIER_CTGRY_MASTER',
    supplyRegister: 'CNTR_SUPPLIER_REGISTRATION',
    createContract: 'CNTR_CONTRACT_CREATION',
    EXP_OTHER_BILL_CREATION: 'EXP_OTHER_BILL_CREATION',
    expContractBill: 'EXP_CONTRACT_ADVANCE_BILL,EXP_CONTRACT_RUNNING_BILL,EXP_CONTRACT_FINAL_BILL,EXP_CONTRACT_SEC_DEPOSIT_REFUND',
    EXP_IMPREST_RELEASE_CREATION: 'EXP_IMPREST_RELEASE_CREATION',
    EXP_IMPREST_ADJ_CREATION: 'EXP_IMPREST_ADJ_CREATION',
    paymentBills: 'PAYMENT_BILL',
    paymentInstructions: 'PAYMENT_INSTRUCTION',
    journal: 'JOURNAL_VOUCHER',
    createEconomimc: 'COA_ECONOMIC_CODES_MASTER',
    concurrentApprover: 'CONCURRENT_APPROVER',
    SYS_ROLE_MASTER: 'SYS_ROLE_MASTER',
    SYS_USER_MASTER: 'SYS_USER_MASTER',
    employeeGroup: 'EMPLOYEE_GROUP',
    emplolyee_details: 'EMP_CREATION',
    EMP_PAY_COMMISSION_MASTER: 'EMP_PAY_COMMISSION_MASTER',
    EMP_CLASSIFICATION_MASTER: 'EMP_CLASSIFICATION_MASTER',
    EMP_PAY_ENTITLEMENT_MASTER: 'EMP_PAY_ENTITLEMENT_MASTER',
    EMP_PAY_COMPONENT_MASTER: 'EMP_PAY_COMPONENT_MASTER',
    EMP_GROUP_MASTER: 'EMP_GROUP_MASTER',
    EMP_PAY_COMPONENT_DEF_MASTER: 'EMP_PAY_COMPONENT_DEF_MASTER',
    EMP_CREATION: 'EMP_CREATION',
    EMP_PAY_ENTITLEMENT_CHANGE: 'EMP_PAY_ENTITLEMENT_CHANGE',
    SYS_BENEFICIARY_DETAILS: 'SYS_BENEFICIARY_DETAILS',
    EMP_PAYROLL_VOUCHER: 'EMP_PAYROLL_VOUCHER',
    SYS_DEDUCTION_MASTER: 'SYS_DEDUCTION_MASTER',
    SYS_ADVANCE_MASTER: 'SYS_ADVANCE_MASTER',
    suppplyCategoryMaster: 'SYS_SUPPLY_CTGRY_MASTER',
    secureDepositDeductionMaster: 'SYS_SEC_DEP_DEDUCTION_MASTER',
    RVN_REMITTANCE_PURPOSE_CONFIG: 'RVN_REMITTANCE_PURPOSE_CONFIG',
    RVN_REPORT_CONFIG: 'RVN_REPORT_CONFIG',
    EXP_OTHER_BILL_MASTER: 'EXP_OTHER_BILL_MASTER',
    SYS_DEPT_MASTER: 'SYS_DEPT_MASTER',
    EXP_CONTRACT_BILL_MASTER: 'EXP_CONTRACT_BILL_MASTER',
    EXP_PERSONNEL_BILL_MASTER: 'EXP_PERSONNEL_BILL_MASTER',
    EXP_PERSONNEL_BILL: 'EXP_PERSONNEL_BILL',
    SYS_DOC_CATEGORY: 'SYS_DOC_CATEGORY',
    IA_AUDIT_OBJECTIVE_DEF: 'IA_AUDIT_OBJECTIVE_DEF',
    IA_AUDIT_PROCEDURE_DEF: 'IA_AUDIT_PROCEDURE_DEF',
    IA_RISK_DEFINITION: 'IA_RISK_DEFINITION',
    IA_CONTROLS_DEFINITION: 'IA_CONTROLS_DEFINITION',
    IA_MDA_PROFILE: 'IA_MDA_PROFILE',
    IA_KEY_STAKE_HOLDER: 'IA_KEY_STAKE_HOLDER',
    IA_AUDITABLE_AREA_HIERARCHY: 'IA_AUDITABLE_AREA_HIERARCHY',
    IA_GUIDELINE_AUDIT_OPINION: 'IA_GUIDELINE_AUDIT_OPINION',
    IA_AUDIT_PLAN_CONFIG: 'IA_AUDIT_PLAN_CONFIG',
    IA_RATE_CARD: 'IA_RATE_CARD',
    IA_LOCATION_ALLOWANCE_MAPPING: 'IA_LOCATION_ALLOWANCE_MAPPING',
    IA_CALENDAR_EVENT: 'IA_CALENDAR_EVENT',
    IA_OBJECTIVE_PROCEDURE_DEF_MAPPING: 'IA_OBJECTIVE_PROCEDURE_DEF_MAPPING',
    IA_RISK_MATURITY_ASSESSMENT: 'IA_RISK_MATURITY_ASSESSMENT',
    IA_AUDIT_PLAN_SCHEDULE: 'IA_AUDIT_PLAN_SCHEDULE',
    IA_STRATEGIC_AUDIT_PLAN: 'IA_STRATEGIC_AUDIT_PLAN',
    IA_AUDIT_TEST_RESULT: 'IA_AUDIT_TEST_RESULT',
    IA_RISK_ASSESSMENT: 'IA_RISK_ASSESSMENT',
    IA_SCHEDULE_AUDIT_AAP: 'IA_SCHEDULE_AUDIT_AAP',
    IA_FOLLOW_UP_AUDIT_AAP: 'IA_FOLLOW_UP_AUDIT_AAP',
    IA_AD_HOC_AUDIT_AAP: 'IA_AD_HOC_AUDIT_AAP',
    IA_ANNUAL_AUDIT_PLAN: 'IA_ANNUAL_AUDIT_PLAN',
    IA_CONSULTING_ASSIGNMENT_AUDIT_AAP: 'IA_CONSULTING_ASSIGNMENT_AUDIT_AAP',
    IA_ADMINISTRATIVE_ACTIVITY_AUDIT_AAP: 'IA_ADMINISTRATIVE_ACTIVITY_AUDIT_AAP',
    IA_COI_DECLARATION: 'IA_COI_DECLARATION',
    IA_SCHEDULE_AAP_SUBASSIGNMENT: 'IA_SCHEDULE_AAP_SUBASSIGNMENT',
    IA_ADHOC_AAP_SUBASSIGNMENT: 'IA_ADHOC_AAP_SUBASSIGNMENT',
    IA_AUDIT_COMMENCEMENT_FORM: 'IA_AUDIT_COMMENCEMENT_FORM',
    IA_AAP_ADMIN_ACTIVITY_EXEC: 'IA_AAP_ADMIN_ACTIVITY_EXEC',
    IA_SUMMARY_AUDITABLE_AREA: 'IA_SUMMARY_AUDITABLE_AREA',
    IA_SUMMARY_AUDIT_SUBJECT: 'IA_SUMMARY_AUDIT_SUBJECT',
    IA_INHERENT_RISK: 'IA_INHERENT_RISK',
    IA_REQUEST_LIST_RESPONSE: 'IA_REQUEST_LIST_RESPONSE',
    IA_AUDIT_SCOPE: 'IA_AUDIT_SCOPE',
    IA_CONTROL_ASSESSMENT: 'IA_CONTROL_ASSESSMENT',
    IA_AUDIT_ASSIGNMENT_PLAN: 'IA_AUDIT_ASSIGNMENT_PLAN',
    IA_RESIDUAL_RISK_ASSESSMENT: 'IA_RESIDUAL_RISK_ASSESSMENT',
    IA_RISK_ASSESSMENT_CONCLUSION: 'IA_RISK_ASSESSMENT_CONCLUSION',
    IA_AUDIT_FINDING: 'IA_AUDIT_FINDING',
    IA_AUDIT_MEETING: 'IA_AUDIT_MEETING',
    IA_AUDIT_CLOSURE_FORM: 'IA_AUDIT_CLOSURE_FORM',
    IA_AUDIT_PROGRAM: 'IA_AUDIT_PROGRAM',
    IA_AUDIT_TEST_WORKSHEET: 'IA_AUDIT_TEST_WORKSHEET',
    IA_REPORT_ACTUAL: 'IA_REPORT_ACTUAL',
    IA_ENGAGEMENT_FORM: 'IA_ENGAGEMENT_FORM',
    IA_AUDIT_REGISTER: 'IA_AUDIT_REGISTER',
    IA_FOLLOW_UP_AAP_SUBASSIGNMENT: 'IA_FOLLOW_UP_AAP_SUBASSIGNMENT',
    IA_IAU_DIVISION: 'IA_IAU_DIVISION',
    IA_REPORT: 'IA_REPORT',
    IA_AUDIT_HIERARCHY_DETAIL: 'IA_AUDIT_HIERARCHY_DETAIL',
    IA_RESOURCE_PLAN_CONFIG: 'IA_RESOURCE_PLAN_CONFIG'
};
