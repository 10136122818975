import React, { useState } from 'react';
import Schema from '../AUMQueries';
import { useMutation } from '@apollo/client';
import ActionsComponents from '../../../../../components/actionsComponent/ActionsComponents';
import { MenuItemsSchema } from '../../../../../commons/MenuItemsSchema';
import { iaClient } from '../../../../../apollo';
import IsAuthorized from '../../../../../commons/IsAuthorized';
import { Button } from 'appkit-react';
import { serverDate } from '../../../../../util';

const createOneQuery = Schema.createOneKeyStakeHolder;
const updateOneQuery = Schema.updateOneKeyStakeHolder;

const StakeholdersActions = (props) => {
	const [state, setState] = useState({ buttonStatus: '' });
	const [versionNo, setVersionNo] = useState(props?.version_no);
	const [versionUser, setVersionUser] = useState(props?.version_user);
	const [currentAction, setCurrentAction] = useState('');
	const [showWfBtns, setWFBtns] = useState(true);

	let Privileges = '';
	let loc = window.location.href.split('#');
	if (loc.length > 0) Privileges = MenuItemsSchema?.AUDIT_UNIVERSE_MANAGEMENT?.find((a) => loc[1].includes(a.routerLink)) || '';

	const [creatStakeholders] = useMutation(createOneQuery, {
		client: iaClient,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data) {
				let growlMsg =
					currentAction?.name && currentAction?.name === 'Submit'
						? window.AUDIT_HEIRARCHY_DETAILS.SubmitStakeHolder
						: window.AUDIT_HEIRARCHY_DETAILS.CreateStakeHolder;
				props.showGrowl(growlMsg, 'success');
				if (currentAction?.name && currentAction?.name === 'Submit') {
					setWFBtns(currentAction?.name && currentAction?.name === 'Submit' ? false : true);
				}
			}
		},
		onError: () => {
			props.handleLoader(false);
		}
	});

	const [updateStakeholders] = useMutation(updateOneQuery, {
		variables: { where: { id: props.recID } },
		client: iaClient,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data) {
				let growlMsg = currentAction?.name
					? currentAction?.name + ' action taken successfully'
					: window.AUDIT_HEIRARCHY_DETAILS.UpdateStakeHolder;
				props.showGrowl(growlMsg, 'success');
				setVersionNo(parseInt(data.updateOneEmployee.version_no));
				setVersionUser(data.updateOneEmployee.version_user);
				setWFBtns(true);
			}
		},
		onError: () => {
			props.handleLoader(false);
			setWFBtns(true);
		}
	});

	const checkValidations = () => {
		if (props.showUpdateInfo) {
			return props.showUpdateInfo;
		}
	};

	const getDisplayData = () => {
		return {
			name: props.formState.name,
			code: props.formState.code,
			category: props.formState.category,
			ref: props.keyStakeRef,
			versionNo:
				props.approveVersionNo && props.approveVersionNo !== '' && props.approveVersionNo > 0
					? props.approveVersionNo
					: versionNo || props.version_no
		};
	};

	const getValue = (value, isTempSave) => {
		if (!isTempSave && (versionNo > 0 || (!versionNo && props?.version_no > 0)) && !props.isApproved) return { set: value };
		return value;
	};

	const getRecord = (isTempSave = true) => {
		let formObj = JSON.parse(JSON.stringify(props.formState));
		let queryObj = {};
		queryObj = {
			name: getValue(formObj.name, isTempSave),
			code: getValue(formObj.code, isTempSave),
			category: getValue(formObj.category, isTempSave)
		};
		if (props.checkIsApproved) queryObj.version_date = serverDate();
		if (versionNo || props?.version_no || props.checkIsApproved)
			queryObj.version_no =
				props.approveVersionNo > 0 ? Number(props.approveVersionNo) + 1 : Number(versionNo || props?.version_no) + 1;
		if (props.checkIsApproved) {
			queryObj.approved_version_no =
				props.approveVersionNo && props.approveVersionNo !== '' && props.approveVersionNo > 0
					? props.approveVersionNo
					: versionNo || props.version_no;
		}
		if (props.checkIsApproved) queryObj.ref = props.keyStakeRef;
		if (props.entity_id) queryObj.entity_id = props.entity_id;
		if (props.recID && !props.isApproved) queryObj.id = props.recID;
		return queryObj;
	};

	const handleSubmitORSave = (status, wfParams, action) => {
		let isValid = checkValidations();
		if (isValid) {
			props.showGrowl(isValid, 'error');
			return;
		}
		setCurrentAction(action);
		let dataObj = getRecord(false);
		dataObj.wf_params = wfParams;
		delete dataObj.entity_id;
		delete dataObj.version_date;
		if (props.isApproved) delete dataObj.id;
		if (props.checkIsApproved) delete dataObj.approved_version_no;
		else delete dataObj.version_no;

		state.buttonStatus = status;
		setState({ ...state, buttonStatus: state.buttonStatus });
		props.handleLoader(true);
		if ((versionNo > 0 || (!versionNo && props.version_no > 0)) && !props.isApproved) {
			delete dataObj.id;
			delete dataObj.version_no;
			setWFBtns(false);
			if (props.checkIsApproved) dataObj.ref = { set: props.keyStakeRef };
			updateStakeholders({ variables: { data: dataObj } });
			return;
		}
		creatStakeholders({ variables: { data: dataObj } });
	};

	const minFieldsRequired = () => {
		if (!props.formState || props.errorStatus || !props.formState?.name || !props.formState?.code || !props.formState?.category)
			return true;
	};

	return (
		!(props.keyStakeCount > 0 && !props.isApproved && props.formType === 'view') && (
			<div>
				{props.showActions ? (
					showWfBtns && (
						<ActionsComponents
							navigateToEdit={() => props.navigateToEdit()}
							mode={props.isApproved ? 'create' : props.formType}
							setActionsStatus={props.setActionsStatus}
							canDisable={minFieldsRequired()}
							data={props.formState}
							onSave={(wfParams, action) => handleSubmitORSave('Approved', wfParams, action)}
							onCancel={() => props.handleCancel()}
							version_no={props.isApproved ? 0 : versionNo || props.version_no}
							version_user={versionUser || props.formState?.version_user}
							privRequired={Privileges?.UPDATE}
							officeCode={Privileges?.OFFICE_CODE}
							module="Masters"
							fmis_function="IA_KEY_STAKE_HOLDER"
							fmis_sub_function="NA"
							minFieldsRequired={minFieldsRequired()}
							getRecord={getRecord}
							getDisplayData={getDisplayData}
							handleLoader={props.handleLoader}
							screenName={'Key Stakeholders'}
							source_id={props.isApproved ? '' : props.recID}
							isApproved={props.isApproved}
						/>
					)
				) : (
					<div className="bottom-left-btm-div">
						<IsAuthorized
							privRequired={Privileges?.UPDATE}
							officeCode={Privileges.OFFICE_CODE}
							yes={() => (
								<Button size="sm" kind="primary" className="m-r-10 m-t-10" onClick={() => props.navigateToEdit()}>
									Edit
								</Button>
							)}
						></IsAuthorized>
					</div>
				)}
			</div>
		)
	);
};

export default StakeholdersActions;
