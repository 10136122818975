import React, { useState } from 'react';
import Schema from '../../../../../schema/AppSchema';
import { useMutation } from '@apollo/client';
import ActionsComponents from '../../../../../components/actionsComponent/ActionsComponents';
import { MenuItemsSchema } from '../../../../../commons/MenuItemsSchema';
import { employeeClient } from '../../../../../apollo';
import { serverDate, uniqueId } from '../../../../../util';
import IsAuthorized from '../../../../../commons/IsAuthorized';
import { Button } from 'appkit-react';

const createOneQuery = Schema.createOneEmployee;
const updateOneQuery = Schema.updateOneEmployee;

const EmployeeActions = (props) => {
	const [state, setState] = useState({ buttonStatus: '' });
	const [versionNo, setVersionNo] = useState(props?.version_no);
	const [versionUser, setVersionUser] = useState(props?.version_user);
	const [currentAction, setCurrentAction] = useState('');
	const [showWfBtns, setWFBtns] = useState(true);

	let Privileges = '';
	let loc = window.location.href.split('#');
	if (loc.length > 0) Privileges = MenuItemsSchema.EMPLOYEE_MANAGEMENT.find((a) => loc[1].includes(a.routerLink));

	const [creatEmpDetails] = useMutation(createOneQuery, {
		client: employeeClient,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data) {
				let growlMsg =
					currentAction?.name && currentAction?.name === 'Submit'
						? 'Employee Details submitted successfully'
						: 'Employee Details created successfully';
				props.showGrowl(growlMsg, 'success');
				if (currentAction?.name && currentAction?.name === 'Submit') {
					setWFBtns(currentAction?.name && currentAction?.name === 'Submit' ? false : true);
				}
			}
		},
		onError: () => {
			props.handleLoader(false);
		}
	});

	const [updateEmpDetails] = useMutation(updateOneQuery, {
		variables: { where: { id: props.recID } },
		client: employeeClient,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data) {
				let growlMsg = currentAction?.name
					? currentAction?.name + ' action taken successfully'
					: 'Employee Details updated successfully';
				props.showGrowl(growlMsg, 'success');
				setVersionNo(parseInt(data.updateOneEmployee.version_no));
				setVersionUser(data.updateOneEmployee.version_user);
				setWFBtns(true);
			}
		},
		onError: () => {
			props.handleLoader(false);
		}
	});

	const checkValidations = () => {
		if (props.showUpdateInfo) {
			return props.showUpdateInfo;
		}
	};

	const getDisplayData = () => {
		let recordObj = props.formState;
		return {
			group_id: recordObj?.empGroup?.id,
			mda: recordObj?.EMPLOYEE_CLASSIFICATION?.MDA?.id,
			mda_name: recordObj?.EMPLOYEE_CLASSIFICATION?.MDA?.displayLabel,
			deputation_office: recordObj?.EMPLOYEE_CLASSIFICATION?.deputation_office?.fullCode,
			deputation_office_name: recordObj?.EMPLOYEE_CLASSIFICATION?.deputation_office?.name,
			office_location: recordObj?.EMPLOYEE_CLASSIFICATION?.office_location,
			daily_rate: recordObj?.EMPLOYEE_CLASSIFICATION?.daily_rate,
			classif_details: getDraftDetails(),
			first_name: recordObj?.EMPLOYEE_INFORMATION?.emp_first_name,
			last_name: recordObj?.EMPLOYEE_INFORMATION?.emp_last_name,
			ref: props.employeeRef,
			versionNo:
				props.approveVersionNo && props.approveVersionNo !== '' && props.approveVersionNo > 0
					? props.approveVersionNo
					: versionNo || props.version_no
		};
	};

	const getQualificationsInfo = () => {
		const array = [];
		if (props.formState.Qualificiations?.length > 0) {
			props.formState.Qualificiations.forEach((obj) => {
				let bankObj = {
					q1ualification: obj.qualification,
					university: obj.university,
					cert_number: obj.certificate_number,
					year_of_passing: obj.year_passing,
					grade: obj.grade,
					percentage: parseFloat(obj.percentage)
					// id: obj.id
				};
				array.push(bankObj);
			});
		}
		return array;
	};

	const getAddresses = () => {
		let list = [];
		if (props.formState.address_list?.length > 0) {
			props.formState.address_list.forEach((obj) => {
				let addObj = {
					is_primary: obj.is_primary,
					//genereate uniqueId for EOA
					id: ((versionNo > 0 || (!versionNo && props.version_no > 0)) && !props.isApproved && obj?.recordID) ? obj?.recordID : uniqueId(25),
					address: {
						create: {
							line1: obj.line1 || '',
							line2: obj.line2 || '',
							line3: obj.line3 || '',
							pin_code: obj.pin_code || '',
							country: obj.country || '',
							state: obj.state || '',
							district: obj.district || '',
							sub_district: obj.sub_district || '',
							village: obj.village || '',
							panchayat: obj.panchayat || '',
							is_active: obj.is_active || false,
							tier_id: obj.tier?.id,
							//genereate uniqueId for EOA
							id: ((versionNo > 0 || (!versionNo && props.version_no > 0)) && !props.isApproved && obj?.id) ? obj?.id : uniqueId(25),
						}
					}
				};
				//No need to send id
				//if (obj.id) addObj.id = obj.id;
				list.push(addObj);
			});
		}
		return list;
	};

	const getClassifDetails = () => {
		let list = [];
		if (props.formState.EMPLOYEE_CLASSIFICATION?.dynamicFields?.length > 0) {
			props.formState.EMPLOYEE_CLASSIFICATION.dynamicFields.forEach((a) => {
				list.push({
					classif_id: a.id,
					classif_code: a.value?.id
				});
			});
		}
		return list;
	};

	const getDraftDetails = () => {
		let list = [];
		if (props.formState.EMPLOYEE_CLASSIFICATION?.dynamicFields?.length > 0) {
			props.formState.EMPLOYEE_CLASSIFICATION.dynamicFields.forEach((a) => {
				list.push({
					classif_id: a.id,
					classif_code: a.value?.id,
					classif_code_detail: {
						name: a.value?.name
					}
				});
			});
		}
		return list;
	};

	const getSkills = () => {
		let list = [];
		if (props.formState.EMPLOYEE_SPECIFIC_INFO?.rows?.length > 0) {
			props.formState.EMPLOYEE_SPECIFIC_INFO.rows.forEach((a) => {
				list.push({
					skill_desc: a.skills?.id,
					proficiency: parseFloat(a.proficiency),
					total_experience: parseFloat(a.experience)
					// id: a.id
				});
			});
		}
		return list;
	};

	const getValue = (value, isTempSave) => {
		if (!isTempSave && (versionNo > 0 || (!versionNo && props?.version_no > 0)) && !props.isApproved) return { set: value };
		return value;
	};

	const getRecord = (isTempSave = true) => {
		let recordObj = props.formState;
		let queryObj = {
			group_id: getValue(recordObj?.empGroup?.id, isTempSave),
			img_url: getValue(recordObj?.empPhoto, isTempSave),
			classif_details: { create: getClassifDetails() },
			budget_office: getValue(recordObj?.EMPLOYEE_CLASSIFICATION?.MDA?.fullCode, isTempSave),
			deputation_office: getValue(recordObj?.EMPLOYEE_CLASSIFICATION?.deputation_office?.fullCode, isTempSave),
			effective_from: getValue(recordObj?.EMPLOYEE_CLASSIFICATION?.effective_from, isTempSave),
			mda: getValue(recordObj?.EMPLOYEE_CLASSIFICATION?.MDA?.fullCode, isTempSave),
			first_name: getValue(recordObj?.EMPLOYEE_INFORMATION?.emp_first_name, isTempSave),
			last_name: getValue(recordObj?.EMPLOYEE_INFORMATION?.emp_last_name, isTempSave),
			gender: getValue(recordObj?.EMPLOYEE_INFORMATION?.gender, isTempSave),
			email: getValue(recordObj?.EMPLOYEE_INFORMATION?.email_id, isTempSave),
			last_payment_drawn_outside: getValue(serverDate(), isTempSave),
			general_info: {
				// not required as array............
				create: {
					father_name: recordObj?.EMPLOYEE_INFORMATION?.emp_fathers_name,
					mother_name: recordObj?.EMPLOYEE_INFORMATION?.emp_mother_name,
					dob: recordObj?.EMPLOYEE_INFORMATION?.dob,
					nationality: recordObj?.EMPLOYEE_INFORMATION?.nationality,
					marital_status: recordObj?.EMPLOYEE_INFORMATION?.marital_status,
					spouse_name: '',
					pan_num: recordObj?.EMPLOYEE_INFORMATION?.TPIN,
					aadhar: recordObj?.EMPLOYEE_INFORMATION?.national_id_Card,
					mobile: recordObj?.EMPLOYEE_INFORMATION?.mobile_no,
					other_mobile: recordObj?.EMPLOYEE_INFORMATION?.office_phone_no,
					reservation_category: '',
					caste: '',
					is_disabled: recordObj?.EMPLOYEE_INFORMATION?.isDisabled,
					office_location: recordObj?.EMPLOYEE_CLASSIFICATION?.office_location?.id,
					daily_rate: recordObj?.EMPLOYEE_CLASSIFICATION?.daily_rate
				}
			},
			special_info: {
				create: {
					skills: { create: getSkills() },
					overall_experience: parseFloat(props.formState.EMPLOYEE_SPECIFIC_INFO?.overAllExp),
					gpf_num: '',
					gpf_type: '',
					appointment_order: '',
					appointment_date: serverDate(),
					joining_date: serverDate(),
					super_annuation_date: serverDate()
				}
			}
		};

		if (recordObj?.EMPLOYEE_INFORMATION?.isDisabled && recordObj?.EMPLOYEE_INFORMATION?.disability) {
			queryObj['general_info']['create']['disability_type'] = recordObj?.EMPLOYEE_INFORMATION?.disability;
		}
		if (recordObj?.EMPLOYEE_INFORMATION?.isDisabled && recordObj?.EMPLOYEE_INFORMATION?.percentOfDisability) {
			queryObj['general_info']['create']['disability_percent'] = parseFloat(recordObj?.EMPLOYEE_INFORMATION?.percentOfDisability);
		}
		if (props.formState.Qualificiations?.length > 0) {
			queryObj['qualifications'] = {
				create: getQualificationsInfo()
			};
		}
		if (props.formState.address_list?.length > 0) {
			queryObj['addresses'] = { create: getAddresses() };
		}
		if (props.checkIsApproved) queryObj.version_date = serverDate();
		if (versionNo || props?.version_no || props.checkIsApproved)
			queryObj.version_no =
				props.approveVersionNo > 0 ? Number(props.approveVersionNo) + 1 : Number(versionNo || props?.version_no) + 1;
		if (props.checkIsApproved) {
			queryObj.approved_version_no =
				props.approveVersionNo && props.approveVersionNo !== '' && props.approveVersionNo > 0
					? props.approveVersionNo
					: versionNo || props.version_no;
		}
		if (props.checkIsApproved) queryObj.ref = props.employeeRef;
		if (props.entity_id) queryObj.entity_id = props.entity_id;
		if (props.recID && !props.isApproved) queryObj.id = props.recID;
		return queryObj;
	};

	const handleSubmitORSave = (status, wfParams, action) => {
		let isValid = checkValidations();
		if (isValid) {
			props.showGrowl(isValid, 'error');
			return;
		}
		setCurrentAction(action);
		let dataObj = getRecord(false);
		dataObj.wf_params = wfParams;
		delete dataObj.entity_id;
		delete dataObj.version_date;
		if (props.isApproved) delete dataObj.id;
		if (props.checkIsApproved) delete dataObj.approved_version_no;
		else delete dataObj.version_no;

		state.buttonStatus = status;
		setState({ ...state, buttonStatus: state.buttonStatus });
		props.handleLoader(true);
		if ((versionNo > 0 || (!versionNo && props.version_no > 0)) && !props.isApproved) {
			delete dataObj.id;
			delete dataObj.version_no;
			setWFBtns(false);
			if (props.checkIsApproved) dataObj.ref = { set: props.employeeRef };
			updateEmpDetails({ variables: { data: dataObj } });
			return;
		}
		creatEmpDetails({ variables: { data: dataObj } });
	};

	const disableButton = () => {
		let obj = props.formState;
		return (
			!obj?.empGroup?.id ||
			props.errorStatus ||
			obj.EMPLOYEE_CLASSIFICATION?.dynamicFields?.length <= 0 ||
			obj.EMPLOYEE_CLASSIFICATION?.dynamicFields?.some((a) => !a.value?.id) ||
			!obj?.EMPLOYEE_CLASSIFICATION?.MDA?.id ||
			!obj?.EMPLOYEE_CLASSIFICATION?.deputation_office?.fullCode ||
			!obj?.EMPLOYEE_CLASSIFICATION?.office_location ||
			!obj?.EMPLOYEE_CLASSIFICATION?.daily_rate ||
			!obj?.EMPLOYEE_INFORMATION?.emp_first_name ||
			!obj?.EMPLOYEE_INFORMATION?.emp_last_name ||
			!obj?.EMPLOYEE_INFORMATION?.dob ||
			!obj?.EMPLOYEE_INFORMATION?.email_id ||
			!obj?.EMPLOYEE_INFORMATION?.emp_fathers_name ||
			!obj?.EMPLOYEE_INFORMATION?.emp_mother_name ||
			!obj?.EMPLOYEE_INFORMATION?.nationality ||
			!obj?.EMPLOYEE_INFORMATION?.gender ||
			!obj?.EMPLOYEE_INFORMATION?.TPIN ||
			!obj?.EMPLOYEE_INFORMATION?.national_id_Card ||
			!obj?.EMPLOYEE_INFORMATION?.mobile_no ||
			!obj.EMPLOYEE_SPECIFIC_INFO?.overAllExp ||
			obj.EMPLOYEE_SPECIFIC_INFO?.rows?.length <= 0 ||
			(obj.EMPLOYEE_CLASSIFICATION?.showEffectiveDate && !obj?.EMPLOYEE_CLASSIFICATION?.effective_from) ||
			props.formState.Qualificiations?.length <= 0 ||
			props.formState.address_list?.length <= 0 ||
			(obj?.EMPLOYEE_INFORMATION?.isDisabled && !obj?.EMPLOYEE_INFORMATION?.percentOfDisability) ||
			(obj?.EMPLOYEE_INFORMATION?.isDisabled && !obj?.EMPLOYEE_INFORMATION?.disability)
		);
	};

	const minFieldsRequired = () => {
		if (!props.formState || !props.formState?.empGroup?.id || props.errorStatus) return true;
	};

	return (
		!(props.mdaProfileCount > 0 && !props.isApproved && props.formType === 'view') && (
			<div>
				{props.showActions ? (
					showWfBtns && (
						<ActionsComponents
							navigateToEdit={() => props.navigateToEdit()}
							mode={props.isApproved ? 'create' : props.formType}
							setActionsStatus={props.setActionsStatus}
							canDisable={disableButton()}
							data={props.formState}
							onSave={(wfParams, action) => handleSubmitORSave('Approved', wfParams, action)}
							onCancel={() => props.handleCancel()}
							version_no={props.isApproved ? 0 : versionNo || props.version_no}
							version_user={versionUser || props.formState?.version_user}
							privRequired={Privileges?.UPDATE}
							officeCode={Privileges?.OFFICE_CODE}
							module="Employee Management"
							fmis_function="EMP_CREATION"
							fmis_sub_function="NA"
							minFieldsRequired={minFieldsRequired()}
							getRecord={getRecord}
							getDisplayData={getDisplayData}
							handleLoader={props.handleLoader}
							screenName={'Employee Details'}
							source_id={props.isApproved ? '' : props.recID}
							isApproved={props.isApproved}
						/>
					)
				) : (
					<div className="bottom-left-btm-div">
						<IsAuthorized
							privRequired={Privileges?.UPDATE}
							officeCode={Privileges.OFFICE_CODE}
							yes={() => (
								<Button size="sm" kind="primary" className="m-r-10 m-t-10" onClick={() => props.navigateToEdit()}>
									Edit
								</Button>
							)}
						></IsAuthorized>
					</div>
				)}
			</div>
		)
	);
};

export default EmployeeActions;
