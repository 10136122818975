import React from 'react';
import FormSchema from '../formSchema';
import InputComponent from '../../../../components/InputComponent/InputComponent';

const SupplyCatTopForm = (props) => {
    return (
        <div className="p-l-10 p-r-10">
            <div className="row m-b-10">
                {FormSchema.FLD_CD_SUP_CAT.is_visible !== -1 && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                        <b className="w-40 fs-14">
                            {FormSchema.FLD_CD_SUP_CAT.name}
                            {FormSchema.FLD_CD_SUP_CAT.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                        </b>
                        {props.formType === 'view' ? (
                            <label>{props.selectedRow?.sup_cat?.name}</label>
                        ) : (
                            <InputComponent
                                className="w-60"
                                inputBoxSize="sm"
                                name="cv_inc_tax"
                                type="text"
                                value={props.selectedRow?.sup_cat?.name}
                                placeholder="Contract Value Incl of Taxes"
                            />
                        )}
                    </div>
                )}
                {FormSchema.FLD_CD_HOA.is_visible !== -1 && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                        <b className="w-40 fs-14">
                            {FormSchema.FLD_CD_HOA.name}
                            {FormSchema.FLD_CD_HOA.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                        </b>
                        {props.formType === 'view' ? (
                            <label>{props.selectedRow?.hoa}</label>
                        ) : (
                            <InputComponent
                                className="w-60"
                                inputBoxSize="sm"
                                name="cv_inc_tax"
                                type="text"
                                value={props.selectedRow?.hoa}
                                placeholder="Contract Value Incl of Taxes"
                            />
                        )}
                    </div>
                )}
            </div>
            <div className="row m-b-10">
                {FormSchema.FLD_CD_CV_INC_TAXES.is_visible !== -1 && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                        <b className="w-40 fs-14">
                            {FormSchema.FLD_CD_CV_INC_TAXES.name}
                            {FormSchema.FLD_CD_CV_INC_TAXES.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                        </b>
                        {props.formType === 'view' ? (
                            <label>{props.selectedRow?.cntrct_val_inc_tax}</label>
                        ) : (
                            <InputComponent
                                className="w-60"
                                inputBoxSize="sm"
                                name="cv_inc_tax"
                                type="text"
                                value={props.selectedRow?.cntrct_val_inc_tax}
                                placeholder="Contract Value Incl of Taxes"
                            />
                        )}
                    </div>
                )}
                {FormSchema.FLD_CD_CV_EXCL_TAXES.is_visible !== -1 && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                        <b className="w-40 fs-14">
                            {FormSchema.FLD_CD_CV_EXCL_TAXES.name}
                            {FormSchema.FLD_CD_CV_EXCL_TAXES.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                        </b>
                        {props.formType === 'view' ? (
                            <label>{props.selectedRow?.cntrct_val_exc_tax}</label>
                        ) : (
                            <InputComponent
                                className="w-60"
                                inputBoxSize="sm"
                                name="cv_eccl_tax"
                                type="text"
                                value={props.selectedRow?.cntrct_val_exc_tax}
                                placeholder="Contract Value Excl of Taxes"
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SupplyCatTopForm;
