import React, { useState, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { useLazyQuery, useQuery } from '@apollo/client';
import Schema from '../AUMQueries';
import AppSchema from '../../../../../schema/AppSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { eventClient, iaClient } from '../../../../../apollo';
import { Sidebar } from 'primereact/sidebar';
import WorklowSidebarContent from '../../../../../components/WorklowSidebarContent/WorklowSidebarContent';
import { getFormTypeAndRecordId, InfoMessage } from '../../../../../services/commonService';
import InputComponent from '../../../../../components/InputComponent/InputComponent';
import * as FormValidation from '../../../../../Validation';
import StakeholdersActions from './StakeholdersActions';
import { Dropdown } from 'primereact/dropdown';
import { isValidCUID } from '../../../../../util';
import { ApprovedRecordApproveCheckVariable, ApprovedRecordEntityCheckVariable } from '../../../../../commons/CommonVariables';

const CreateStakeholders = (props) => {
    const [growl, setGrowl] = useState();
    const [errorStatus, setErrorStatus] = useState(false);
    const formTypeAndId = getFormTypeAndRecordId(props.location.pathname);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    let isDraft = formTypeAndId['isDraft'];
    let isApproved = formTypeAndId['isApproved'];
    let transactionId = localStorage.getItem('transaction_id');
    const [mode, setMode] = useState(formType);
    const [versionNo, setversionNo] = useState();
    const [versionUser, setversionUser] = useState();
    const [stakeholders, setStakeholders] = useState();
    const [entityId, setEntityId] = useState('');
    const [recID, setID] = useState('');
    const [errors, setErrors] = useState({});
    const [showActions, setShowActions] = useState(isApproved && formType === 'view' ? false : true);
    const [approvedRecord, setApprovedRecord] = useState(false);
    const [approveVersionNo, setApproveVersionNo] = useState(0);
    const [keyStakeCount, setKeyStakeCount] = useState(0);
    const [ref, setRef] = useState('');

    const [formState, setFormState] = useState({ name: '', code: '', category: '' });
    const [state, setState] = useState({
        loading: false,
        showWorkflowStatus: false,
        showPreview: false,
        listToShowComment: [],
        auditHierarchyList: [],
        categoryList: []
    });

    useEffect(() => {
        if (recordID && isDraft) {
            getEntityRecord();
        } else if (recordID) {
            getstakeHolder();
        }
        // eslint-disable-next-line
    }, []);

    const prepareData = (response) => {
        if (response.id) setID(response.id);
        setStakeholders(response);
        setversionNo(response?.version_no);
        setversionUser(response?.version_user);

        let dataObj = { name: response.name, code: response.code, category: response.category };
        setFormState({ ...formState, ...dataObj });
    };

    useQuery(AppSchema.appEnums, {
        fetchPolicy: 'no-cache',
        variables: { where: { enum_type: { equals: 'EXTERNAL_KEY_STAKEHOLDER_CATEGORY' } } },
        onCompleted: (res) => {
            if (res?.appEnums?.length > 0) {
                let categoryList = [];
                res.appEnums.map((a) => {
                    categoryList.push({ label: a.display_value, value: a.enum_value });
                });
                setState({ ...state, categoryList: categoryList, loading: false });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [checkCount] = useLazyQuery(Schema.keyStakeHolderCount, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (resp) => {
            setKeyStakeCount(resp.keyStakeHolderCount);
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [getstakeHolder, { loading: stakeholderLoader }] = useLazyQuery(Schema.keyStakeHolder, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        variables: { where: { id: recordID } },
        onCompleted: (response) => {
            if (response?.keyStakeHolder) {
                setRef(response?.keyStakeHolder?.ref);
                checkCount({ variables: ApprovedRecordApproveCheckVariable(response.keyStakeHolder) });
                setApprovedRecord(!isValidCUID(response?.keyStakeHolder?.ref));
                prepareData(response.keyStakeHolder);
                return;
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });
    const [getEntityRecord, { loading: entityLoader }] = useLazyQuery(AppSchema.entityRecord, {
        // get api for record data by ID in Draft mode
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: { where: { id: recordID } },
        onCompleted: (data) => {
            if (data?.entityRecord?.record) {
                if (data?.entityRecord?.record?.ref) {
                    setRef(data?.entityRecord?.record?.ref);
                    setApprovedRecord(!isValidCUID(data?.entityRecord?.record?.ref));
                }
                setApproveVersionNo(data?.entityRecord?.record?.approved_version_no);
                setEntityId(data?.entityRecord?.record?.entity_id);
                prepareData(data.entityRecord.record);
                if (
                    data?.entityRecord?.record?.ref &&
                    (isApproved || data?.entityRecord?.record?.approved_version_no > 0 || !isValidCUID(data?.entityRecord?.record?.ref))
                ) {
                    data.entityRecord.record.version_no = data.entityRecord?.record?.approved_version_no;
                    checkCount({ variables: ApprovedRecordEntityCheckVariable(data?.entityRecord?.record) });
                }
                return;
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const showWorkflow = (flag) => {
        setState({ ...state, showWorkflowStatus: flag });
    };
    const handleCommentClick = (selRow, flag) => {
        const lstToShowComment = state.listToShowComment;
        lstToShowComment[selRow.id] = flag;
        setState({ ...state, listToShowComment: lstToShowComment });
    };

    const setActionsStatus = (hasActions) => {
        setState({ ...state, hasActions });
    };

    const handleLoader = (status) => {
        setState({ ...state, loading: status });
    };

    const handleCancel = () => {
        setState({ ...state, loading: false });
        props.history.goBack();
    };

    const showGrowl = (msg, type) => {
        growl.show({ life: 6000, severity: type, summary: msg });
        setTimeout(() => {
            if (type !== 'error') handleCancel();
        }, 1000);
    };

    const navigateToEdit = () => {
        if (isApproved) setShowActions(true);
        setMode('edit');
    };

    const fields = [
        { key: 'code', validators: 'numeric' },
        { key: 'category', validators: 'required' },
        { key: 'name', validators: 'required' }
    ];

    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);
        if (err[key] || (typeof ev === 'string' && ev.trim() === '')) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrorStatus(Object.keys(errors).length > 0);
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setFormState({ ...formState, [key]: ev });
    };

    const checkIsApproved = () => {
        return isApproved || approveVersionNo > 0 || approvedRecord;
    };

    return (
        <div className={`page-body pos-rel ${stakeholderLoader || entityLoader || state.loading ? 'mask' : ''}`}>
            {(stakeholderLoader || entityLoader || state.loading) && (
                <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />
            )}
            <Toast ref={(el) => setGrowl(el)} />
            <>
                <div className="page-header">
                    {!stakeholderLoader && !entityLoader && (
                        <span className="light-color">
                            {recordID
                                ? transactionId && transactionId !== 'undefined'
                                    ? `Transaction Number : ${transactionId}`
                                    : `Key Stakeholder - ${stakeholders?.ref}`
                                : 'Key Stakeholder'}
                        </span>
                    )}

                    {!stakeholderLoader && !entityLoader && (
                        <span className="backBtn pointer">
                            {recordID && versionNo !== 0 && (
                                <img
                                    alt=""
                                    className="header-icon"
                                    title="Workflow Stages"
                                    src={process.env.PUBLIC_URL + '/assets/icons/workflow.svg'}
                                    onClick={() => showWorkflow(true)}
                                ></img>
                            )}
                            <span
                                onClick={() => {
                                    localStorage.setItem('transaction_id', '');
                                    props.history.goBack();
                                }}
                            >
                                <span className="appkiticon icon-left-chevron-outline icon_size"></span>Back
                            </span>
                        </span>
                    )}
                </div>
                <div
                    className={`${
                        state.hasActions || mode === 'create' || checkIsApproved()
                            ? 'page-content-ht-with-btns'
                            : 'page-content-ht-without-btns'
                    }`}
                >
                    <Sidebar
                        visible={state.showWorkflowStatus}
                        onHide={() => showWorkflow(false)}
                        position="right"
                        dismissable={true}
                        closeOnEscape={false}
                        showCloseIcon={false}
                    >
                        <WorklowSidebarContent
                            sourceId={recordID}
                            listToShowComment={state.listToShowComment}
                            showWorkflow={showWorkflow}
                            handleCommentClick={handleCommentClick}
                        />
                    </Sidebar>
                    {keyStakeCount > 0 &&
                        !isApproved &&
                        mode === 'view' &&
                        InfoMessage(window.EDIT_OF_APPROVAL_MESSAGES.ApprovedMessageForView)}
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                            <div className="row m-b-10">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                    <b className="fs-14">
                                        Stakeholder Code
                                        {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    {mode !== 'view' && (
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="code"
                                            type="text"
                                            value={formState.code}
                                            maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                            onChange={(e) => handleChange(e, 'code')}
                                            placeholder="Enter Stakeholder code"
                                            hasError={errors && errors.code}
                                            errMsg="Please enter valid Stakeholder code E.g.: 12345 "
                                        />
                                    )}
                                    {mode === 'view' && formState.code}
                                </div>
                            </div>
                            <div className="row m-b-10">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                    <b className="fs-14">
                                        Stakeholder Name
                                        {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    {mode !== 'view' && (
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="name"
                                            type="text"
                                            value={formState.name}
                                            maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                            alphanumeric="true"
                                            onChange={(e) => handleChange(e, 'name')}
                                            placeholder="Enter Stakeholder Name"
                                            hasError={errors && errors.name}
                                            errMsg="Please enter valid Stakeholder Name"
                                        />
                                    )}
                                    {mode === 'view' && formState.name}
                                </div>
                            </div>
                            <div className="row m-b-10">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                    <b className="fs-14">
                                        Stakeholder Category
                                        {mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    {mode !== 'view' && (
                                        <Dropdown
                                            resetFilterOnHide={true}
                                            name="category"
                                            appendTo={document.self}
                                            onChange={(e) => handleChange(e.value, 'category')}
                                            value={formState.category}
                                            options={state.categoryList}
                                            placeholder={'Select Category'}
                                            className="w-100"
                                            filter={true}
                                            filterBy="label"
                                            filterPlaceholder="Search"
                                        />
                                    )}
                                    {mode === 'view' && formState.category}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <StakeholdersActions
                    formState={formState}
                    handleLoader={handleLoader}
                    handleCancel={handleCancel}
                    showGrowl={showGrowl}
                    formType={mode}
                    setActionsStatus={setActionsStatus}
                    recordID={recordID}
                    navigateToEdit={navigateToEdit}
                    recID={recID}
                    entity_id={entityId}
                    version_no={versionNo}
                    version_user={versionUser}
                    errorStatus={errorStatus}
                    location={props.location}
                    isDraft={isDraft}
                    approveVersionNo={approveVersionNo}
                    checkIsApproved={checkIsApproved()}
                    keyStakeCount={keyStakeCount}
                    keyStakeRef={ref}
                    isApproved={isApproved}
                    showActions={showActions}
                />
            </>
        </div>
    );
};

export default CreateStakeholders;
