import React, { useState } from 'react';
import { Button } from 'appkit-react';
import TableSchema from '../../CMTableSchema';
import { useQuery } from '@apollo/client';
import Schema from '../../../../schema/AppSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryDataTable';
import FormSchema from '../formSchema';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import { Dropdown } from 'primereact/dropdown';
import DeleteConfirmModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import { contractClient } from '../../../../apollo';
import HOASelection from '../../../../components/HOADetails/HOASelection';

const AdvanceForm = (props) => {
    let viewMode = TableSchema.CONTCT_ADVNCE.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.formType !== 'view' ? TableSchema.CONTCT_ADVNCE : viewMode;
    const [state, setState] = useState({
        columns: tablecolms,
        adavnce_options: [],
        hoaOptions: [],
        adv_recovery_options: ['At Advance Rate', 'Manual'],
        loading: true,
        showForm: false,
        actionType: '',
        showHoaModal: false,
        showDeleteModal: false
    });
    const [catHOA, setcatHOA] = useState([]);
    function getAdvOptions() {
        let list = JSON.parse(JSON.stringify(advOptions?.advances));
        list = list.filter((a) => {
            return !formState.rows.find((b) => b?.adv_type.ref === a.ref);
        });
        return list;
    }
    const [formState, setFormState] = useState({
        rows: props.Advance?.rows
    });
    let emptyObj = {
        adv_type: '',
        adv_rate: '',
        adv_amount: '',
        hoa: '',
        adv_rec_mthd: 'At Advance Rate',
        islatest: true
    };
    let queryObj =
        props.formStateObj.contracType !== 'totalCntrct'
            ? {
                  where: {
                      supplies: {
                          some: { supply_id: { equals: props.selectedRow?.sup_cat?.id } }
                      }
                  }
              }
            : '';
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const { data: advOptions } = useQuery(Schema.advanceForm, {
        variables: queryObj,
        fetchPolicy: 'no-cache',
        client: contractClient,
        onCompleted: (data) => {
            if (data?.advances) {
                setState({
                    ...state,
                    adavnce_options: getAdvOptions(),
                    loading: false
                });
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });
    const handleChange = (ev, key) => {
        let pattern = new RegExp(/^\d*(\.\d{0,2})?$/g);
        if (key === 'adv_type') {
            currentDetailsObj.hoa = '';
        }
        if (key === 'adv_type' && ev.codes.length <= 0 && props.suplycats?.length > 0) {
            if (props.formStateObj.contracType !== 'totalCntrct' && props?.selectedRow?.hoa) {
                setcatHOA([props.selectedRow.hoa]);
                currentDetailsObj.hoa = props.selectedRow.hoa;
            } else {
                let list = props.suplycats.map((a) => a.hoa);
                setcatHOA(list);
            }
        }
        if (key === 'adv_amount' && window?._VALIDATIONS_?.CONTRACT_ADVANCE_AMOUNT) {
            if (parseFloat(ev) > parseFloat(props.selectedRow.cntrct_val_inc_tax)) {
                props.growl.show({
                    life: 6000,
                    severity: 'error',
                    summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.AdvanceAmount
                });
                return;
            }
            if (isNaN(ev) || ev.indexOf(' ') >= 0 || parseInt(ev) === 0 || !pattern.test(ev)) return;
            let amt = (parseFloat(ev) * 100) / parseFloat(props.selectedRow.cntrct_val_inc_tax);
            if (isNaN(amt)) amt = '';
            else amt = amt.toFixed(2);
            currentDetailsObj.adv_rate = amt.toString();
        }
        if (key === 'adv_rate' && window?._VALIDATIONS_?.CONTRACT_ADVANCE_RATE) {
            if (isNaN(ev) || ev.indexOf(' ') >= 0 || parseFloat(ev) === 0 || !pattern.test(ev)) return;
            if (parseFloat(ev) > 100) return;
            let amt = (parseFloat(ev) / 100) * parseFloat(props.selectedRow.cntrct_val_inc_tax);
            if (isNaN(amt)) amt = '';
            else amt = amt.toFixed(2);
            currentDetailsObj.adv_amount = amt.toString();
        }
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };
    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let list = props.suplycats.map((a) => a.hoa);
            setcatHOA(list);
            let newObj = JSON.parse(JSON.stringify(rowData));

            if (!newObj.islatest) {
                let prepData = JSON.parse(JSON.stringify(advOptions.advances));
                let adv = prepData?.find((a) => a.ref === newObj.adv_type?.ref);
                newObj.adv_type = adv || {};
                newObj.hoa = '';
                newObj.islatest = true;
            }
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setState({ ...state, showForm: true, actionType: sAction, adavnce_options: [...getAdvOptions(), newObj.adv_type] });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };
    const saveCntrctDetails = () => {
        currentDetailsObj.adv_rate = Number(currentDetailsObj.adv_rate).toFixed(2);
        let amount = parseFloat(currentDetailsObj.adv_amount);
        formState.rows.forEach((a) => {
            if (state.actionType !== 'edit' || (state.actionType === 'edit' && a.adv_type.ref !== editObj.adv_type.ref)) {
                amount = amount + parseFloat(a.adv_amount);
            }
        });

        if (props.selectedRow.cntrct_val_inc_tax < amount) {
            props.growl.show({
                life: 6000,
                severity: 'error',
                summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.TotalAdvanceAmount
            });
            return;
        }
        if (state.actionType === 'edit') {
            let indx = formState.rows.findIndex((a) => a.adv_type.ref === editObj.adv_type.ref);
            formState.rows[indx] = currentDetailsObj;
            setFormState({ ...formState });
        } else {
            formState.rows.push(currentDetailsObj);
            setFormState({ ...formState });
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Advance', formState);
    };
    const saveHoa = (arr, hoa) => {
        setCurrentDetailsObj({ ...currentDetailsObj, hoa: hoa });
        handleCancel('showHoaModal');
    };
    const handleDeleteRow = () => {
        formState.rows = formState.rows.filter((a) => {
            return a.adv_type.id !== currentDetailsObj.adv_type.id;
        });
        setFormState({ ...formState, rows: formState.rows });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Advance', formState);
        handleCancel('showDeleteModal');
    };
    const addNewRow = () => {
        state.adavnce_options = getAdvOptions();
        setState({ ...state, showForm: true, adavnce_options: state.adavnce_options });
    };
    const disableSave = () => {
        for (let key in currentDetailsObj) {
            if (
                !currentDetailsObj.adv_type ||
                (key === 'hoa' && props.formStateObj.contracType === 'totalCntrct' && !currentDetailsObj.hoa)
            ) {
                return true;
            }
        }
        return false;
    };

    const handleHOAPopup = (e, hoa) => {
        setState({
            ...state,
            showHoaModal: true
        });
        setCurrentDetailsObj({
            ...currentDetailsObj,
            hoa: hoa
        });
    };

    return (
        <div className={`coa-card p-10 ${state.loading ? 'mask' : ''}`}>
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div>
                <SecondaryDataTable
                    rows={formState?.rows}
                    columns={state.columns}
                    emptyMessage="Advances are yet to be added"
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    className="secondary-table inner-table-overflow"
                />
                {props.formType !== 'view' && (
                    <div className="comment-header">
                        <span onClick={() => addNewRow()} className={`comment-color pointer  ${state.showForm ? 'disabled' : ''}`}>
                            + Add New
                        </span>
                    </div>
                )}

                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_ADV_TYPE.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_ADV_TYPE.name}
                                        {FormSchema.FLD_CD_ADV_TYPE.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                    <Dropdown
                                        name="adv_type"
                                        value={currentDetailsObj.adv_type}
                                        onChange={(e) => handleChange(e.value, 'adv_type')}
                                        appendTo={document.body}
                                        options={state.adavnce_options}
                                        placeholder="Select Advance Type"
                                        className="w-60"
                                        optionLabel="name"
                                    />
                                </div>
                            )}
                            {FormSchema.FLD_CD_ADV_HOA.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_ADV_HOA.name}
                                        {FormSchema.FLD_CD_ADV_HOA.is_mandatory !== -1 ||
                                        props.formStateObj.contracType === 'totalCntrct' ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    {currentDetailsObj.adv_type?.codes?.length > 0 ? (
                                        <div className={!currentDetailsObj.adv_type ? 'disabled w-60' : 'w-60'}>
                                            <HOASelection
                                                handleHOAPopup={handleHOAPopup}
                                                hoa={currentDetailsObj.hoa}
                                                function="Advance Master"
                                                placeHolder="Select HOA"
                                                showHoaModal={state.showHoaModal}
                                                handleHOACancel={(e) => handleCancel(e)}
                                                updateHOAInParent={saveHoa}
                                                selectedHOA={currentDetailsObj.hoa}
                                                hoaDetails={{
                                                    fetchCompositionQry: Schema.GetCntrAdvanceMappings,
                                                    variables: {
                                                        category_id: currentDetailsObj?.adv_type?.id,
                                                        functionCode: 'CNTR_CONTRACT_CREATION',
                                                        officeCode: 'GLOBAL',
                                                        activityCode: 'ADVANCE_MASTER'
                                                    },
                                                    emptyMessage: 'HOAs are yet to be created',
                                                    responseObj: 'GetCntrAdvanceMappings',
                                                    client: contractClient
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <Dropdown
                                            name="hoa"
                                            value={currentDetailsObj.hoa}
                                            onChange={(e) => handleChange(e.value, 'hoa')}
                                            disabled={!currentDetailsObj.adv_type}
                                            appendTo={document.body}
                                            options={catHOA}
                                            placeholder="HOA"
                                            className="w-60"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_ADV_RATE.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_ADV_RATE.name}
                                        {FormSchema.FLD_CD_ADV_RATE.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="adv_rate"
                                        type="text"
                                        value={currentDetailsObj.adv_rate}
                                        onChange={(e) => handleChange(e, 'adv_rate')}
                                        placeholder="Enter Advance Rate"
                                    />
                                </div>
                            )}
                            {FormSchema.FLD_CD_ADV_AMOUNT.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_ADV_AMOUNT.name}
                                        {FormSchema.FLD_CD_ADV_AMOUNT.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="adv_amount"
                                        type="text"
                                        value={currentDetailsObj.adv_amount}
                                        maxLength={15}
                                        onChange={(e) => handleChange(e, 'adv_amount')}
                                        placeholder="Enter Advance Amount"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row m-b-15">
                            {FormSchema.FLD_CD_ADV_RECVRY_MTD.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_ADV_RECVRY_MTD.name}
                                        {FormSchema.FLD_CD_ADV_RECVRY_MTD.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <Dropdown
                                        name="adv_rec_mthd"
                                        value={currentDetailsObj.adv_rec_mthd}
                                        onChange={(e) => handleChange(e.value, 'adv_rec_mthd')}
                                        appendTo={document.body}
                                        options={state.adv_recovery_options}
                                        placeholder="Advance Recovery Method"
                                        className="w-60"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="m-t-10">
                            <Button
                                size="sm"
                                disabled={disableSave()}
                                kind="primary"
                                className="m-r-10"
                                onClick={() => saveCntrctDetails()}
                            >
                                {state.actionType === 'edit' ? 'Update Advance' : 'Add Advance'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {state.showDeleteModal && (
                <DeleteConfirmModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Contract Details"
                    showModal={state.showDeleteModal}
                    name={'Adavance Type : ' + currentDetailsObj.adv_type.name}
                />
            )}
        </div>
    );
};
export default AdvanceForm;
