import React from 'react';
import { TextArea } from 'appkit-react';

const TextAreaComponent = (props) => {
    const restrictSpace = (e) => {
        if (e.which === 32 && e?.currentTarget?.selectionStart === 0) {
            e.preventDefault();
        }

        if (props.alphabets) {
            let pattern = new RegExp(/^[a-zA-Z\s]*$/);
            if (!pattern.test(e.key)) {
                e.preventDefault();
            }
        }

        if (props.alphanumeric) {
            let pattern = new RegExp(/^[A-Za-z0-9 ]+$/g);
            if (!pattern.test(e.key)) {
                e.preventDefault();
            }
        }
        if (props.alphabetsandspecs) {
            let pattern = new RegExp(/^[a-zA-Z-.()$#@!%^&*_<>{}"'\s]*$/g);
            if (!pattern.test(e.key)) {
                e.preventDefault();
            }
        }
        if (props.alphabetsnumericspecs) {
            let pattern = new RegExp(/^[a-zA-Z0-9-.()$#@!%^&*_<>{}"'\s]*$/g);
            if (!pattern.test(e.key)) {
                e.preventDefault();
            }
        }
    };
    const onChange = (e) => {
        if (props.alphanumeric) {
            let pattern = new RegExp(/^[A-Za-z0-9 ]+$/g);
            if (pattern.test(e)) {
                props.onChange(e);
            }
        } else {
            props.onChange(e);
        }
    };

    return <TextArea autoComplete="off" onKeyDown={(ev) => restrictSpace(ev)} {...props} onChange={(e) => onChange(e)} />;
};

export default TextAreaComponent;
