import React, { useState } from 'react';
import Schema from '../../../schema/AppSchema';
import URSchema from '../../../schema/URSchema';
import TableSchema from '../TableSchema';
import './Users.scss';
import FDataTable from '../../../components/fDataTable/FDataTable';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';
import store from '../../../index';
import AuditComponent from '../../../components/auditLog/AuditComponent';
import TopGridComponent from '../users/TopGridComponent';
import { Toast } from 'primereact/toast';
import { DownloadFile } from '../../../commons/Downloader';
import ConfirmationModal from './ConfirmationModal';
import TopCardComponent from '../../../components/topComponents/TopCardComponent';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal.jsx';
import { eventClient, uploadClient } from '../../../apollo';
import IsAuthorized from '../../../commons/IsAuthorized.jsx';
import RecallButton from '../../../commons/RecallButton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { convertTextCase } from '../../../util';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

const Users = (props) => {
	let currentUser = store.getState()?.appReducer?.auth;
	const currentUserId = currentUser.user.id;
	let Privileges = '';
	let loc = window.location.href.split('#');
	if (loc.length > 0) Privileges = MenuItemsSchema.USER_ITEMS.find((a) => a.routerLink === loc[1]);

	let tableColums = JSON.parse(JSON.stringify(TableSchema.USERS));

	const [users, setUsers] = useState({});
	const [state, setState] = useState({
		loading: true,
		showPopup: false,
		where: { is_latest: { equals: true } },
		columns: tableColums,
		commentRequired: false,
		showConfirmModal: false,
		showLoginInfo: false,
		showUnBlockPopup: false,
		selectedData: {}
	});
	const [growl, setGrowl] = useState();
	const [usersAuditState, setUsersAuditState] = useState({ reference: {}, showAudit: false });
	const [selected, setSelected] = useState({});
	const [recallLoader, setRecallLoader] = useState(false);
	const [recallObj, setRecallObj] = useState();
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(10);
	const [totalRecords, setTotalRecords] = useState();
	const [pageRows, setPageRows] = useState(10);
	const [searchFieldKey, setSearchFieldKey] = useState({});
	const [sortField, setSortField] = useState('id');
	const [sortOrder, setSortOrder] = useState('asc');
	const [cardCount, setCardCount] = useState('');
	const [tableLoader, setTableLoader] = useState(false);
	const [where, setWhere] = useState(
		props?.whereQry ? { ...props.whereQry, ...{ is_latest: { equals: true } } } : { is_latest: { equals: true } }
	);

	const assignedTo = {
		header: 'Assigned To',
		field: 'assignedTo',
		filter: true,
		sortable: true,
		filterMatchMode: 'contains',
		filterPlaceholder: 'Search By Assigned To',
		width: '200px',
		fieldType: 'tooltip'
	};

	const onClickRecall = (rowData) => {
		setState({ ...state, selectedData: rowData });
	};
	const setCustomizedRows = () => {
		let index = users.findIndex((pro) => pro === state.selectedData);
		users.splice(index, 1);
		setState({ ...state, selectedData: {}, showConfirmModal: false });
		setUsers(users);
		setRecallLoader(false);
	};
	const handleCalRecallStatus = (flag) => {
		setState({ ...state, showConfirmModal: true, commentRequired: flag });
	};
	const handleCancelModal = () => {
		setState({ ...state, showConfirmModal: false });
	};
	const handleLoader = (flag) => {
		setRecallLoader(flag);
	};
	const getUsersFromWorkflow = (seleeData, usersList) => {
		for (let index = 0; index < seleeData.new_objs.length; index++) {
			const element = seleeData.new_objs[index];
			element.isDraft = true;
			usersList.push(element);
		}
		return usersList;
	};

	let variables = { orderBy: { [sortField]: sortOrder }, where: state.where };
	if (!selected.label || selected.label === 'Total Users') {
		variables.skip = skip;
		variables.take = take;
	}
	const { refetch: refetchRows, error } = useQuery(Schema.users, {
		variables: variables,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data) prepareData(data);
		},
		onError: () => {
			setState({ ...state, loading: false });
			setTableLoader(false);
		}
	});

	const prepareData = (data) => {
		let usersList = data.users.filter((user, index) => {
			if (
				user.id !== currentUserId &&
				selected.ids?.length > 0 &&
				selected.label !== 'Total Users' &&
				selected.status_obj &&
				selected.status_obj[user.id]
			) {
				let record = selected?.status_obj[user.id]?.record;
				if (record) {
					record.status = selected.status_obj[user.id].status;
					data.users[index] = record;
				}
				user.status = selected.status_obj[user.id].status;
				user.txnId = selected.status_obj[user.id].txnId;
				user.assignedTo = convertTextCase(selected.status_obj[user.id].assignedTo, false) || '';
				user.entity_id = selected.status_obj[user.id].record?.entity_id;
			}
			user.is_default = true;
			return user.id !== currentUserId;
		});
		if (selected.new_objs?.length > 0 && selected.label === 'Pending With Self') {
			usersList = getUsersFromWorkflow(selected, usersList);
		}
		let arr = data.users.map((a) => a.status).filter((item, i, ar) => ar.indexOf(item) === i);
		state.columns.forEach((a) => {
			if (a.field === 'status') {
				a.filterOptions = arr;
			}
		});
		setState({ ...state, loading: false });
		setUsers(usersList);
		setTableLoader(false);
	};

	const actionTemplate = (rowData) => {
		const showRecallBtn = recallObj && recallObj[rowData.id];
		return (
			<>
				{(selected.label === 'Pending With Self' || rowData.status === 'Approved') && (
					<IsAuthorized
						privRequired={Privileges?.UPDATE}
						officeCode={Privileges?.OFFICE_CODE}
						yes={() => (
							<button
								className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
								onClick={() => {
									rowData.entity_id ? onClickEditDraft(rowData, 'edit') : onClickEdit(rowData, 'edit');
								}}
							>
								<img
									title="Edit"
									className="pointer"
									src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
									alt="Edit Icon"
									height="20px"
								/>
							</button>
						)}
					></IsAuthorized>
				)}
				{(rowData.status === 'Draft' || rowData.status === 'Created') && selected.label === 'Pending With Self' && (
					<IsAuthorized
						privRequired={Privileges.UPDATE}
						officeCode={Privileges.OFFICE_CODE}
						yes={() => (
							<button className="a-btn-background-transparent a-icon-container " onClick={() => onClickDelete(rowData)}>
								<img
									className="pointer m-l-5 m-r-5"
									height="20px"
									title="Delete Role"
									src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
									alt="Delete Icon"
								/>
							</button>
						)}
					></IsAuthorized>
				)}
				{rowData.status !== 'Draft' && (
					<button className="a-btn-background-transparent a-icon-container m-l-5 m-r-5" onClick={() => handleAudit(rowData)}>
						<img
							className="a-font-18"
							title="Audit"
							src={process.env.PUBLIC_URL + '/assets/icons/audit_trail.svg'}
							alt="view Icon"
						/>
					</button>
				)}
				{showRecallBtn && selected.label === 'Pending For Verification/Approval' && (
					<RecallButton
						rowData={rowData}
						setCustomizedRows={setCustomizedRows}
						onClickRecall={onClickRecall}
						source_id={state.selectedData.id}
						handleCalRecallStatus={handleCalRecallStatus}
						handleCancelModal={handleCancelModal}
						handleLoader={handleLoader}
						commentRequired={state.commentRequired}
						showConfirmModal={state.showConfirmModal}
						growl={growl}
						module={'SYSTEM_ADMINISTRATION'}
						fmis_function={'SYS_USER_MASTER'}
					/>
				)}

				{(rowData.status === 'Approved' && rowData.is_nano_pswd_expired) && (
					<button
						className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
						onClick={() => {
							showUserloginInfo(rowData, 'loginInfo');
						}}
					>
						<img
							title="Login Information"
							className="pointer"
							src={process.env.PUBLIC_URL + '/assets/icons/loginInfo.svg'}
							alt="Login Information"
							height="20px"
						/>
					</button>

				)}
				{(rowData.status === 'Approved' && !rowData.user_unblock) && (

					<button
						className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
						onClick={() => {
							updateSelctedRows(rowData, 'blockUser');
						}}
					>
						<img
							title="Unblock User"
							className="pointer"
							src={process.env.PUBLIC_URL + '/assets/icons/blockUser.svg'}
							alt="Unblock User"
							height="20px"
						/>
					</button>

				)}
			</>
		);
	};

	const handleView = (data) => {
		localStorage.setItem('transaction_id', data.txnId ? data.txnId : '');
		props.history.push('users/view/' + data['id']);
	};

	const viewActionTemplate = (selectedData) => {
		if (selectedData.employee) {
			return (
				<EllipsisWithTooltip placement="auto">
					<span
						className="pointer icon-primary"
						onClick={() => {
							selectedData.entity_id ? onClickEditDraft(selectedData) : handleView(selectedData);
						}}
					>
						{selectedData.employee.ref}
					</span>
				</EllipsisWithTooltip>
			);
		} else {
			return null;
		}
	};
	const viewTxnActionTemplate = (selectedData) => {
		if (selectedData.employee) {
			return (
				<EllipsisWithTooltip placement="auto">
					<span
						className="pointer icon-primary"
						onClick={() => {
							selectedData.entity_id ? onClickEditDraft(selectedData) : handleView(selectedData);
						}}
					>
						{selectedData.txnId}
					</span>
				</EllipsisWithTooltip>
			);
		} else {
			return null;
		}
	};

	const handleRegisterNewUserClick = () => {
		props.history.push('users/registerNewUser');
		localStorage.setItem('transaction_id', '');
	};
	const handleAudit = (rowData) => {
		setUsersAuditState({ reference: rowData, showAudit: true });
	};

	const onUploadClick = (e) => {
		setState({
			...state,
			loading: true
		});
		e.stopPropagation();
		e.preventDefault();
		const file = e.target.files[0];
		if (file) {
			uploadUsersList({ variables: { file: file } });
		}
		e.target.value = null;
	};

	const [uploadUsersList] = useMutation(Schema.ImportUsers, {
		fetchPolicy: 'no-cache',
		client: uploadClient,
		onCompleted: (res) => {
			if (res.ImportUsers?.status === 'SUCCESS') {
				growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_USER_REGISTRATION.ImportUsers });
				refetchRows().then((response) => {
					if (response?.data?.users) {
						const usersList = response.data.users.filter((user) => {
							return user.id !== currentUserId;
						});
						setState({ ...state, loading: false });
						setUsers(usersList);
					}
				});
			}
			if (res?.ImportUsers?.status === 'FAIL') {
				setState({ ...state, ImportUsers: res?.ImportUsers, showErrorsPopup: true, loading: false });
			}
		},
		onError: () => {
			setState({ ...state, showErrorsPopup: true, loading: false });
			return 'Error';
		}
	});

	const downloadErrorCodes = () => {
		DownloadFile(state.ImportUsers.errorFile.id, state.ImportUsers.errorFile.input_name);
		growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_USER_REGISTRATION.ErrorCodesFileDownload });
		setState({ ...state, showErrorsPopup: false });
	};

	const [downloadComments] = useLazyQuery(Schema.ExportUserUploadFile, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (data) {
				DownloadFile(data.ExportUserUploadFile.id, data.ExportUserUploadFile.input_name);
				growl.show({
					life: 6000,
					severity: 'success',
					summary: window.SYSTEM_ADMINISTRATION_USER_REGISTRATION.UsersTemplateDownload
				});
			}
		},

	});

	const goBack = () => {
		setUsersAuditState({ reference: {}, showAudit: false });
	};

	const onClickEditDraft = (rowData, action) => {
		localStorage.setItem('transaction_id', rowData.txnId);
		props.history.push(
			action ? 'users/' + action + '/' + rowData['entity_id'] + '/draft' : 'users/view/' + rowData['entity_id'] + '/draft'
		);
	};
	const onClickEdit = (rowData, action) => {
		props.history.push(action ? 'users/' + action + '/' + rowData['id'] : 'users/view/' + rowData['id']);
		localStorage.setItem('transaction_id', rowData.txnId);
	};
	const navigateTo = (query, selectedObj, columns) => {
		if (selectedObj.label !== selected.label) {
			let tWhere = JSON.parse(JSON.stringify(where));
			setWhere(query);
			if (selectedObj.label === 'Pending For Verification/Approval') {
				setRecallObj(selectedObj.canRecall);
				columns.splice(columns.length - 1, 0, assignedTo);
				columns[0].body = viewTxnActionTemplate;
			} else if (selectedObj.label === 'Total Users') {
				columns[0].body = viewActionTemplate;
			} else {
				columns[0].body = viewTxnActionTemplate;
			}
			setState({ ...state, where: query, columns: columns, loading: true });
			setSelected(selectedObj);
			setSkip(0);
			setTake(10);
			setPageRows(10);
			setSortField('id');
			setSortOrder('asc');
			setSearchFieldKey({});
			setTableLoader(true);
			if (query?.id?.in.toString() === tWhere?.id?.in.toString()) {
				refetchRows({ variables: { where: query } }).then((response) => {
					if (response) prepareData(response.data);
				});
			}
		}
	};
	const onClickDelete = (rowData) => {
		setState({ ...state, showDeleteCnfmModal: true, selectedData: rowData });
	};
	const handleCancel = () => {
		setState({ ...state, selectedData: {}, showDeleteCnfmModal: false });
	};
	const handleDelete = () => {
		setState({ ...state, delLoader: true, loading: true, showDeleteCnfmModal: false });
		deleteWorkFlowAction({
			variables: {
				where: { id: state.selectedData.entity_id }
			}
		});
		return;
	};
	const [deleteWorkFlowAction] = useMutation(Schema.deleteOneEntityRecord, {
		client: eventClient,
		onCompleted: (data) => {
			if (data) {
				let index = users.findIndex((pro) => pro === state.selectedData);
				users.splice(index, 1);

				growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_USER_REGISTRATION.DeleteUser });
				setUsers(users);
			}
			setState({ ...state, delLoader: false, loading: false, selectedData: {}, showDeleteCnfmModal: false });
		},
		onError: () => {
			setState({ ...state, loading: false });
		}
	});

	const updateSelctedRows = (rowData, action) => {
		setState({ ...state, showUnBlockPopup: true, selectedData: rowData })
	};
	const unblockUserLogin = (isUnblock) => {
		setState({ ...state, showUnBlockPopup: false })
		if (isUnblock) {
			unBlockUser({
				variables: { userID: state.selectedData.id, user_unblock: !state.selectedData.user_unblock }
			})
		}

	};

	const generateLoginCredetials = (isLogin) => {
		setState({ ...state, showLoginInfo: false })
		console.log(state.selectedData, "state.selectedData.id")
		if (isLogin) {
			generateLoginDetails({
				variables: { source_id: state.selectedData.id, status: "Approved" }
			})
		}

	};

	const showUserloginInfo = (rowData, action) => {
		setState({ ...state, showLoginInfo: true, selectedData: rowData })
	};

	const [unBlockUser] = useMutation(Schema.updateUserUnBlockCheck, {
		onCompleted: () => {
			growl.show({
				life: 6000,
				severity: 'success',
				summary: window.SYSTEM_ADMINISTRATION_USER_REGISTRATION.UnblockUser
			});
			refetchRows();
			setState({
				...state,
				loading: false
			});
		},
		onError: () => {
			setState({ ...state, loading: false });
			return 'Error';
		}
	});

	const [generateLoginDetails] = useMutation(Schema.sendUserCreationMail, {
		onCompleted: () => {
			growl.show({
				life: 6000,
				severity: 'success',
				summary: window.SYSTEM_ADMINISTRATION_USER_REGISTRATION.loginCredentials
			});
			refetchRows();
			setState({
				...state,
				loading: false
			});
		},
		onError: () => {
			setState({ ...state, loading: false });
			return 'Error';
		}
	});

	if (state.columns.length > 0) {
		if (!selected.label) {
			state.columns[0].body = viewActionTemplate;
		}
		state.columns[state.columns.length - 1].body = actionTemplate;
	}
	const { refetch: refetchCount } = useQuery(URSchema.usersCount, {
		fetchPolicy: 'no-cache',
		variables: { where: state.where },
		skip: selected.label && selected.label !== 'Total Users',
		onCompleted: (resp) => {
			setTotalRecords(resp.usersCount);
			if (!cardCount || cardCount === '') {
				setCardCount(resp.usersCount);
			}
		},
		onError: () => {
			setState({ ...state, loading: false });
		}
	});
	const onPageChange = (event) => {
		setTableLoader(true);
		setSkip(event.first);
		setTake(event.rows);
		setPageRows(event.rows);
		if (!selected.label || selected.label === 'Total Users') {
			refetchCount();
		}
		refetchRows();
	};
	const searchRecord = (e, column, filterKeys) => {
		setTableLoader(true);
		Object.keys(filterKeys).forEach((filterKey) => {
			if (filterKeys[filterKey].contains === null) {
				delete filterKeys[filterKey];
			}
			return filterKey;
		});
		let keyData = { ...filterKeys };
		setSearchFieldKey(keyData);
		let obj;
		obj = { is_latest: { equals: true } };
		if (filterKeys && filterKeys['status']) {
			if (filterKeys['status'].contains) {
				filterKeys['status'] = { contains: filterKeys['status'].contains, mode: 'insensitive' };
			} else {
				delete filterKeys['status'];
			}
		}
		setState({ ...state, where: { ...obj, ...filterKeys } });
		setSkip(skip);
		setTake(take);
		setPageRows(pageRows);
		if (!selected.label || selected.label === 'Total Users') {
			refetchCount();
		}
	};
	const onSort = (event) => {
		setTableLoader(true);
		let srtField = event.sortField;
		if (event.sortField === 'employee.ref') {
			srtField = 'ref';
		}
		let srtOrder = 'asc';
		if (sortField === srtField) {
			srtOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		}
		setSortOrder(srtOrder);
		setSortField(srtField);
		setSkip(skip);
		setTake(take);
		setPageRows(pageRows);
	};

	if (error) return `Error! ${error.message}`;

	return (
		<div>
			{state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
			<Toast ref={(el) => setGrowl(el)} />
			{!usersAuditState.showAudit ? (
				<div className={`page-body ${state.loading || recallLoader ? 'mask' : ''}`}>
					<div className="page-header w-100">
						<span className="light-color">Users</span>
					</div>
					<div className="coa-card-content">
						{!state.delLoader && !recallLoader && (
							<TopCardComponent
								cardCount={cardCount}
								fetchParent={(query, selectedObj, columns) => navigateTo(query, selectedObj, columns)}
								columns={tableColums}
								createRouter="users/registerNewUser"
								editRouter="users"
								selected={selected}
								topGridProps={state.topGridProps}
								fmis_function="SYS_USER_MASTER"
								approvedLabel="Total Users"
								fmis_sub_function="NA"
							></TopCardComponent>
						)}
						<TopGridComponent
							onUploadClick={onUploadClick}
							onClickDownload={downloadComments}
							onClickAdd={handleRegisterNewUserClick}
							createPriv={Privileges?.CREATE}
							officeCode={Privileges?.OFFICE_CODE}
						/>
						<div className="row m-l-0 m-r-0">
							<div className="col-md-12 p-0">
								{!state.loading && (
									<FDataTable
										rows={users}
										id="userTable"
										columns={state.columns}
										noResizableColumns={true}
										updatePriv={Privileges.UPDATE}
										officeCode={Privileges.OFFICE_CODE}
										deletePriv={Privileges.DELETE}
										onClickAudit={handleAudit}
										//	updateInparent={updateSelctedRows}
										emptyMessage={'There are no registered users'}
										className="primary-table"
										onClickDelete={onClickDelete}
										totalRecords={totalRecords}
										count={skip}
										onPageChange={(e) => onPageChange(e)}
										pageRows={pageRows}
										searchRecord={searchRecord}
										filterKeys={searchFieldKey}
										lazyLoad={!selected.label || selected.label === 'Total Users' ? true : false}
										onSort={onSort}
										sortField={sortField}
										sortOrder={sortOrder === 'asc' ? 1 : -1}
										loading={tableLoader}
										scrollable={true}
									></FDataTable>
								)}
							</div>
						</div>
						{state.showErrorsPopup && (
							<ConfirmationModal
								showModal={state.showErrorsPopup}
								header={'Upload'}
								content={
									state.ImportUsers?.errorCodes?.length +
									' Error(s) has been found while uploading the file, Please click on the download button to download the file '
								}
								handleClick={() => downloadErrorCodes()}
								handleCancel={() => setState({ ...state, showErrorsPopup: false })}
								secondBtn="Download"
							/>
						)}
						{state.showDeleteCnfmModal && (
							<DeleteConfirmModal
								showModal={true}
								handleCancel={handleCancel}
								type={'Users'}
								name={
									state.selectedData.entity_id
										? `Transaction ID: ${state.selectedData.txnId}`
										: `User :  ${state.selectedData.name}`
								}
								handleDelete={handleDelete}
							/>
						)}
						{state.showLoginInfo && (
							<ConfirmationModal
								showModal={state.showLoginInfo}
								header={'Login Information'}
								content="User login credentials are expired. Are you sure you want to generate login credentials again? "
								handleCancel={() => setState({ ...state, showLoginInfo: false })}
								handleClick={(isLogin) => generateLoginCredetials(isLogin)}
								fstButton="Yes"
								secondBtn="No"
							/>
						)}
						{state.showUnBlockPopup && (
							<ConfirmationModal
								showModal={state.showUnBlockPopup}
								header={'Unblock User'}
								content="Are you sure you want to unblock the user?"
								handleClick={(isUnblock) => unblockUserLogin(isUnblock)}
								handleCancel={() => setState({ ...state, showUnBlockPopup: false })}
								fstButton="Yes"
								secondBtn="No"
							/>
						)}
						<Toast ref={(el) => setGrowl(el)} />
					</div>
				</div>
			) : (
				<AuditComponent
					refence_label="Users"
					goBack={goBack}
					reference_value={usersAuditState.reference.ref ? usersAuditState.reference.ref : ''}
					sourceData={usersAuditState.reference}
					transaction_Id={usersAuditState.reference.txnId}
				/>
			)}
		</div>
	);
};
export default Users;
