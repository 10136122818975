import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/IsAuthorized';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import COASegLevMapping from '../pages/commonMasters/RemitancePurposeMapping/RPM';
import CreateCoaSLMap from '../pages/commonMasters/RemitancePurposeMapping/CreateRPM';
import RemittanceCatMap from '../pages/commonMasters/RemittanceCategoryMapping/RemittanceCatMap';
const revenueManagementItems = MenuItemsSchema.REVENUE_MNGMNT;
const RemittanceSegmentConfig = lazy(() => import('../pages/commonMasters/remittanceSegmentConfig/RemittanceSegmentConfig'));
const ReveueManagement = lazy(() => import('../pages/revenueManagement/mastersConfiguration/remittancePurpose/RemittancePurpose'));
const ConfigureReportsAndFields = lazy(() =>
    import('../pages/revenueManagement/mastersConfiguration/configureReportsAndFields/ConfigureReportsAndFields')
);
const AddConfigureReportsAndFields = lazy(() =>
    import('../pages/revenueManagement/mastersConfiguration/configureReportsAndFields/AddConfigureReportsAndFields')
);
const PanAmountConfiguration = lazy(() =>
    import('../pages/revenueManagement/mastersConfiguration/panAmountConfiguration/PanAmountConfiguration')
);
const MisReportConfig = lazy(() => import('../pages/revenueManagement/mastersConfiguration/misReportConfiguration/MisReportConfiguration'));
const viewAllRemitCodes = lazy(() => import('../pages/commonMasters/RemitancePurposeMapping/ViewAllRemitCodes'));
const revenueMgmtMasterBreadCrmb = (props, path) => {
    let AllCoaBreadcrumbValues = [{ name: 'Revenue Management', redirectTo: '/revenuemanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit' || subPath[3] === 'all') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: path + '/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '', key: subPath[2] }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const RevenueMangMastersRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={revenueMgmtMasterBreadCrmb(props, props?.path)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={RemittanceSegmentConfig}
                    exact
                    path={`${props?.path}/remittanceconfig`}
                    menuItems={revenueManagementItems}
                    name="Remittance Segment Configuration"
                />
                <AuthGaurd
                    {...props}
                    component={COASegLevMapping}
                    exact
                    path={`${props?.path}/rpm`}
                    menuItems={revenueManagementItems}
                    name="Remittance Purpose Mapping"
                />
                <AuthGaurd
                    {...props}
                    component={viewAllRemitCodes}
                    exact
                    path={`${props?.path}/rpm/all`}
                    menuItems={revenueManagementItems}
                    name="Remittance Purpose Mapping"
                />
                <AuthGaurd
                    {...props}
                    component={CreateCoaSLMap}
                    exact
                    path={`${props?.path}/rpm/create`}
                    menuItems={revenueManagementItems}
                    name="Remittance Purpose Mapping"
                />
                <AuthGaurd
                    {...props}
                    component={CreateCoaSLMap}
                    exact
                    path={`${props?.path}/rpm/edit/:id`}
                    name="Remittance Purpose Mapping"
                    menuItems={revenueManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCoaSLMap}
                    exact
                    path={`${props?.path}/rpm/view/:id`}
                    name="Remittance Purpose Mapping"
                    menuItems={revenueManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCoaSLMap}
                    exact
                    path={`${props?.path}/rpm/view/:id/draft`}
                    name="Remittance Purpose Mapping"
                    menuItems={revenueManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateCoaSLMap}
                    exact
                    path={`${props?.path}/rpm/edit/:id/draft`}
                    name="Remittance Purpose Mapping"
                    menuItems={revenueManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={RemittanceCatMap}
                    exact
                    path={`${props?.path}/elrm`}
                    key={`${props?.path}/elrm`}
                    menuItems={revenueManagementItems}
                    name="Employee-Loans Mapping"
                />
                <AuthGaurd
                    {...props}
                    component={RemittanceCatMap}
                    exact
                    path={`${props?.path}/plrm`}
                    key={`${props?.path}/plrm`}
                    menuItems={revenueManagementItems}
                    name="Pension-Loans Mapping"
                />
                <AuthGaurd
                    {...props}
                    component={RemittanceCatMap}
                    exact
                    path={`${props?.path}/adrm`}
                    key={`${props?.path}/adrm`}
                    menuItems={revenueManagementItems}
                    name="Contract-Advances Mapping"
                />
                <AuthGaurd
                    {...props}
                    component={RemittanceCatMap}
                    exact
                    path={`${props?.path}/oirm`}
                    key={`${props?.path}/oirm`}
                    menuItems={revenueManagementItems}
                    name="Office-Imprest Mapping"
                />
                <AuthGaurd
                    {...props}
                    component={ReveueManagement}
                    exact
                    path={`${props?.path}/remittancepurpose`}
                    menuItems={revenueManagementItems}
                    name="Configure Payment Mode"
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureReportsAndFields}
                    exact
                    path={`${props?.path}/crf`}
                    menuItems={revenueManagementItems}
                    name="Configure Reports and Fields"
                />
                <AuthGaurd
                    {...props}
                    component={AddConfigureReportsAndFields}
                    exact
                    path={`${props?.path}/crf/create`}
                    menuItems={revenueManagementItems}
                    name="Configure Reports and Fields"
                />
                <AuthGaurd
                    {...props}
                    component={AddConfigureReportsAndFields}
                    exact
                    path={`${props?.path}/crf/edit/:id`}
                    name="Configure Reports and Fields"
                    menuItems={revenueManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddConfigureReportsAndFields}
                    exact
                    path={`${props?.path}/crf/view/:id`}
                    name="Configure Reports and Fields"
                    menuItems={revenueManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddConfigureReportsAndFields}
                    exact
                    path={`${props?.path}/crf/edit/:id/draft`}
                    name="Configure Reports and Fields"
                    menuItems={revenueManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddConfigureReportsAndFields}
                    exact
                    path={`${props?.path}/crf/view/:id/draft`}
                    name="Configure Reports and Fields"
                    menuItems={revenueManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={PanAmountConfiguration}
                    exact
                    path={`${props?.path}/panamountconfig`}
                    name="Pan Number Amount Configuration"
                    menuItems={revenueManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={MisReportConfig}
                    exact
                    path={`${props?.path}/misreportconfig`}
                    name="MIS Report Configuration"
                    menuItems={revenueManagementItems}
                />
            </Switch>
        </div>
    );
};
export default RevenueMangMastersRouting;
