import React, { useState } from 'react';
import { Panel } from 'appkit-react';
import FDataTable from '../../components/fDataTable/FDataTable';
import TableSchema from '../../commons/TableSchema';
import { prepareURL } from '../../commons/Downloader';
import { useLazyQuery, useQuery } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import { eventClient, iaClient } from '../../apollo';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getDate } from '../../util';
import {
    setDashboardConfigList,
    takeActionNavigationForFmis,
    takeActionNavigationForAms,
    getFmisFunctions,
    auditTypeName
} from '../../commons/commons';
import { useHistory } from 'react-router-dom';
import './Dashboard.scss';
import './Dashboard-Cards.scss';
import { ConfigConstants } from '../../constants/configurationConstant';
import AAPEQueries from '../internalAudit/auditAssignmentPlanningAndExecution/AAPEQueries';

const Dashboard = () => {
    let history = useHistory();
    const fmisFunctions = getFmisFunctions();
    let userObj = JSON.parse(localStorage.getItem('USER_INF'));

    let modules = JSON.parse(localStorage.getItem('APP_CONFIGURATION_VALUES'))?.filter(
        (configData) => configData.code.split('_')[0] === 'MODULE'
    );
    const [userInfo] = useState(userObj);
    const [imgeUrl, setImgurl] = useState(
        userInfo && userInfo.img_url !== '' && userInfo.img_url !== null
            ? userInfo.img_url
            : process.env.PUBLIC_URL + '/assets/icons/face_icon.svg'
    );
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pendingTasks, setPendingTasks] = useState([]);
    const [selectdNotif, setSelectdNotif] = useState({});

    const { loading: tasksLoader } = useQuery(Schema.dashboard_pending_tasks, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: {
            orderBy: { created_at: 'desc' },
            where: { assigned_to: { equals: userInfo.user_name }, is_completed: { equals: 0 }, source_id: { not: { equals: '' } } }
        },
        onCompleted: (data) => {
            setTimeout(setuserImgurl, 1000);
            if (data && data.dashboard_pending_tasks) {
                data.dashboard_pending_tasks.forEach((task) => {
                    task.created_date = getDate(task.version_date);
                    task.office_code = task.office_code ? task.office_code : '–';
                });
                setTasks(data.dashboard_pending_tasks);
                setLoading(false);
            }
        },
        onError: () => {
            setLoading(false);
        }
    });

    const setuserImgurl = () => {
        let userObject = JSON.parse(localStorage.getItem('USER_INF'));
        if (userObject && userObject.img_url && userObject.img_url !== null) {
            setImgurl(prepareURL(userObject.img_url));
        }
        setTimeout(setuserImgurl, 1000);
    };

    const onSelectCategory = (task, e) => {
        e.preventDefault();
        if (task.redirectTo) history.push(task.redirectTo);
    };

    useQuery(Schema.dashboard_stats, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.dashboard_stats) {
                setDashboardCardsData(data.dashboard_stats);
                setLoading(false);
            }
        },
        onError: () => {
            setLoading(false);
        }
    });

    const setConfiguredData = (arr, constantType) => {
        arr.forEach((ar) => {
            const val = ConfigConstants[constantType]?.filter((configConstant) => configConstant.type === ar.redirectTo);
            const configData = modules?.filter((module) => {
                return module.code === val[0]?.code;
            });
            ar.type = configData[0]?.value1;
            ar.title = configData[0]?.value2;
        });
        return arr;
    };

    const setDashboardCardsData = async (data) => {
        let arr = await setDashboardConfigList(data);
        if (modules?.length > 0) {
            arr = setConfiguredData(arr, 'MODULES');
        }
        setPendingTasks(arr);
    };

    const [getTaskNotifData] = useLazyQuery(Schema.annualAuditPlanInfo, {
        client: iaClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.annualAuditPlanInfo) {
                let val = data.annualAuditPlanInfo?.[0];
                localStorage.setItem('audit_assignment_type', val?.audit_assignment_type ? val?.audit_assignment_type : '');
                localStorage.setItem('audit_assignment_id', val?.audit_assignment_id ? val?.audit_assignment_id : '');
                localStorage.setItem('audit_assignment_name', val?.audit_assignment_type ? auditTypeName(val?.audit_assignment_type) : '');
                localStorage.setItem('aap_detail_id', val?.aap_detail_id ? val?.aap_detail_id : '');
                localStorage.setItem('aap_item_id', val?.aap_item_id ? val?.aap_item_id : '');
                localStorage.setItem('office_code', val?.iau_code ? val?.iau_code : '');
                let csVariables = '';
                if (val?.audit_assignment_id === '') {
                    csVariables = { aap_item_id: val?.aap_item_id };
                } else if (val?.aap_item_id === '') {
                    csVariables = { assignment_id: val?.audit_assignment_id };
                } else {
                    csVariables = { aap_item_id: val?.aap_item_id, assignment_id: val?.audit_assignment_id };
                }
                conclusionStatus({ variables: csVariables });
            }
        },
        onError: () => {
            setLoading(false);
        }
    });

    const [conclusionStatus] = useLazyQuery(AAPEQueries.getConclusionStatus, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (resp) => {
            if (resp?.conclusionStatus) {
                localStorage.setItem('AAPE_CONCLUSION_STATUS', JSON.stringify(resp.conclusionStatus));
                localStorage.setItem('transaction_id', selectdNotif.id);
                takeActionNavigationForAms(history, selectdNotif);
                window.location.reload();
                setLoading(false);
            }
        },
        onError: () => {
            setLoading(false);
        }
    });

    const handleInfo = (task) => {
        setSelectdNotif(task);
        if (!window.INTERNAL_AUDIT) {
            takeActionNavigationForFmis(history, task);
        } else {
            let flag = false;
            fmisFunctions.map((ele) => {
                if (ele.code === task.fmis_function) flag = true;
            });
            if (flag) {
                getTaskNotifData({ variables: { where: { source_id: task.source_id, fmis_function: task.fmis_function } } });
            } else {
                takeActionNavigationForAms(history, task);
                localStorage.setItem('transaction_id', task.id);
            }
        }
    };

    const createTemplateColumn = (pt) => {
        let content = [];
        pt.forEach((task, i) => {
            content.push(
                <div key={i} className="cardcolumn d-flex col-12 col-lg-3 col-md-4 col-sm-6 lgcol p-0">
                    <div className="dibox w-100 mr10 pointer" onClick={(e) => onSelectCategory(task, e)}>
                        <div className="imageboxdiv">
                            <div className="whiteoverlay"></div>
                            <div className="w-100">
                                <img src={task.src} alt="" className="w-100" />
                            </div>
                        </div>
                        <div className="contextbox">
                            <div className="cardiconbox">
                                <img src={task.icon} alt="" className="card-icon-ht" />
                            </div>
                            <div className="row m-l-0 m-r-0 fx-dr-rw-in m-b-0 al-itm-fx-end">
                                <h4 className="pendinglabel p-lable">Pending Tasks</h4>
                                <h4 className="pendinglabel count">{task.noOfPendings}</h4>
                            </div>
                            <div className="row m-l-0 m-r-0 m-t-10">
                                <h3 className="cardheading">{task.type}</h3>
                            </div>
                            <div className="row m-l-0 m-r-0">
                                <p className="cardpara">{task.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
        return content;
    };

    return (
        <div className={`${loading || tasksLoader ? 'mask' : ''}`}>
            {(loading || tasksLoader) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div className="row m-b-15 m-t-20 m-r-0">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-5 col-xl-5 p-r-20">
                    <Panel className="h-100">
                        <div className="display-flex p-t-30 p-b-30 profile-card-md">
                            <span className="display-flex">
                                <img className="user-img" src={imgeUrl} alt="" />
                            </span>
                            <div className="welcome-div ">
                                <div className="w-100">
                                    <p className="welcome-label">
                                        Welcome,
                                        <span className="font-color">
                                            {userInfo?.employee?.first_name} {userInfo?.employee?.last_name} !
                                        </span>
                                    </p>
                                </div>
                                <div className="w-100 fs-14">
                                    {!window.INTERNAL_AUDIT ? (
                                        <p className="m-0">
                                            <label className="m-b-0">Establishment clerk - </label>
                                            <span className="opacity-5"> Public works, Water works</span>
                                        </p>
                                    ) : (
                                        <p className="m-0">
                                            <label className="m-b-0 f-14">
                                                {userInfo?.employee?.emp_budget_office?.segment_name}
                                                {userInfo?.employee?.emp_budget_office?.segment_name && ' - '}
                                                {userInfo?.employee?.designation?.name}
                                            </label>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-7 col-xl-7 p-l-0 p-r-0 p-b-0 bg-wt">
                    <div className="row m-0">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-l-0 p-r-0 p-b-0 p-t-3">
                            <b className="task-lbl m-l-5">Tasks</b>
                        </div>
                        <div
                            className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 ${
                                !loading && !tasksLoader && 'task-table-height'
                            }`}
                        >
                            <FDataTable
                                scrollable={true}
                                scrollHeight="180px"
                                rows={tasks}
                                columns={TableSchema.TASKS}
                                emptyMessage="Tasks are yet to be created."
                                className="tasks-table"
                                onClickInfo={handleInfo}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m-l-0 cards-div">{createTemplateColumn(pendingTasks)}</div>
        </div>
    );
};

export default Dashboard;
