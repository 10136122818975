import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'appkit-react';
import { Panel } from 'appkit-react/lib/Panel';
import './CreateOrConfirmModal.scss';
import { useHistory } from 'react-router-dom';

export function ConfirmationModal(props) {
    let history = useHistory();
    const [state, setState] = useState({
        createHoverFlag: false,
        cloneHoverFlag: false
    });
    const handleCreateNew = () => {
        if (props?.topGridProps?.selectedYear && props?.topGridProps?.selectedYear !== '') {
            history.push(props.createRouter + '/' + props.topGridProps.selectedYear);
        } else {
            history.push(props.createRouter);
        }
    };
    const handleClone = () => {
        if (props?.topGridProps?.selectedYear && props?.topGridProps?.selectedYear !== '') {
            history.push(props.cloneRouter + '/' + props.topGridProps.selectedYear);
        } else {
            history.push(props.cloneRouter);
        }
    };

    return (
        <Modal visible={props.showModal} onCancel={props.handleCancel} className="composition-model" backdropClosable={false}>
            <ModalHeader>
                <div></div>
            </ModalHeader>

            <ModalBody>
                <div className="composition-model-body w-80">
                    <Panel
                        className={`composition-panel pointer ${state.createHoverFlag && 'composition-panel-hover'} `}
                        onMouseEnter={() => setState({ ...state, createHoverFlag: true, cloneHoverFlag: false })}
                        onMouseLeave={() => setState({ ...state, createHoverFlag: false })}
                        onClick={() => handleCreateNew()}
                    >
                        <div className="composition-img-div">
                            <img
                                className={state.createHoverFlag ? 'm-l-20 create-img-hover' : 'm-l-20'}
                                src={process.env.PUBLIC_URL + '/assets/icons/createComp.svg'}
                                alt={'Create New'}
                            ></img>
                            <p className={`create-lbl ${state.createHoverFlag && ' icon-primary'}`}>Create New</p>
                        </div>
                    </Panel>
                    <Panel
                        className={`composition-panel pointer m-l-10 p-l-10 p-r-10 ${state.cloneHoverFlag && 'composition-panel-hover'}
					 ${props?.disableClone && 'disabled'}`}
                        onMouseEnter={() => setState({ ...state, cloneHoverFlag: true, createHoverFlag: false })}
                        onMouseLeave={() => setState({ ...state, cloneHoverFlag: false })}
                        onClick={() => handleClone()}
                    >
                        <div className="composition-img-div w-100">
                            <img
                                className={`m-l-45 ${state.cloneHoverFlag && 'clone-img-hover'}`}
                                src={process.env.PUBLIC_URL + '/assets/icons/cloneExistComp.svg'}
                                alt={'Clone Exist'}
                            ></img>
                            <p className={`create-lbl ${state.cloneHoverFlag && 'icon-primary'}`}>Clone From Existing</p>
                        </div>
                    </Panel>
                </div>
            </ModalBody>
            <ModalFooter></ModalFooter>
        </Modal>
    );
}

export default ConfirmationModal;
