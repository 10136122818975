import React, { useState,useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import { eventClient } from '../../apollo';
import { getDate, convertTextCase} from '../../util';
import { ProgressSpinner } from 'primereact/progressspinner';
import './WorkflowSidebarContent.scss';

const WorklowSidebarContent=(props)=>{
	const [rows, setRows] = useState([]);
	const [isloading, setLoading] = useState(true);
	let transactionId=localStorage.getItem('transaction_id');
	transactionId=transactionId&&transactionId!==''?transactionId:localStorage.getItem('workflow_id');
	const skipWorkflowStepsBySourceId=transactionId!=='undefined'&&transactionId!==''&&transactionId!==null;

	useQuery(Schema.fetchWorkflowInstanceStepsBySourceId, {
        client: eventClient,
        variables: { where: { wf_instance: { source_id: { equals: props.sourceId} } }},
		fetchPolicy: 'no-cache',
		skip:skipWorkflowStepsBySourceId,
        onCompleted: (data) => {
            if (data.workflowInstanceSteps?.length > 0) {
                data.workflowInstanceSteps.forEach((res) => {
                    res.version_date_local = getDate(res.version_date);
					res.comments = res?.comments === '' ? null : res?.comments?.split('#');
					res.version_user= convertTextCase(res.version_user,false);
					res.levels=res.phase_type!=='make'&&res.status.toLowerCase()!=='submitted'?`Level ${res.phase_seq} Verification`:'';
				});
                setRows(data.workflowInstanceSteps);
            } else {
				setRows(data.workflowInstanceSteps);
			}
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        }
	});
	useQuery(Schema.fetchWorkflowInstanceSteps, {
        client: eventClient,
        variables: { wf_instance_id:transactionId },
		fetchPolicy: 'no-cache',
		skip:!skipWorkflowStepsBySourceId,
        onCompleted: (data) => {
            if (data.workflowInstanceSteps?.length > 0) {
                data.workflowInstanceSteps.forEach((res) => {
                    res.version_date_local = getDate(res.version_date);
					res.comments = res?.comments === '' ? null : res?.comments?.split('#');
					res.version_user= convertTextCase(res.version_user,false);
					res.levels=res.phase_type!=='make'?`Level ${res.phase_seq} Verification`:'';
				});
                setRows(data.workflowInstanceSteps);
            }
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        }
    });
	useEffect(() => {
		document.querySelectorAll('.p-sidebar').forEach((item) => {
			item.classList.add('z-index');
		});
	}, []);


	return (
        <div className={`z-index ${isloading ? 'mask' : ''}`}>
            {isloading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
			<React.Fragment>
                <div className="d-flex justify-content-between wf-header">
					<span className="selected-fmis-function f-18 f-wt-bold">Workflow status as most recent</span>
					<button className="a-btn-background-transparent a-icon-container m-b-2" onClick={()=>props.showWorkflow(false)}>
						<img height="15px" title="Cancel" src={process.env.PUBLIC_URL + '/assets/icons/cancel.svg'} alt="Cancel" />
					</button>
                </div>
            </React.Fragment>
			<div>
				<ul className="workflow-status">
				{
				rows && rows?.length > 0 ? rows.map((row,key) => (
					<li key={key}>
						<table className='w-100'>
							<tr>
								<td className="name w-50">
								{row.version_user}<span className="backBtn pointer m-l-5">
									{row.comments&&row.comments!==''&&props.listToShowComment&&!props.listToShowComment[row.id]&&<img alt="" src={process.env.PUBLIC_URL + '/assets/icons/comment.svg'}
										onClick={()=>props.handleCommentClick(row,true)}></img>}</span>
								</td>
								<td className="name w-50 text-right">
									{row.version_date_local}
								</td>
							</tr>
							{props.listToShowComment&&props.listToShowComment[row.id]&&<tr>
								<td colSpan="2">
									<div className="tr-comments pos-rel">
										<div className="text-break m-t-5">{row.comments.join(', ')}</div>
										<button onClick={()=>props.handleCommentClick(row,false)}>
											<img height="8px" title="Cancel" src={process.env.PUBLIC_URL + '/assets/icons/cancel.svg'} alt="Cancel" />
										</button>
									</div>
								</td>
							</tr>}
							<tr>
								<td className={`f-15 f-wt-bold w-50 ${row.status.toLowerCase() === 'approved' || row.status.toLowerCase() === 'submitted' ? 'green' : 'red'}`}>
									{row.status}
								</td>
								<td className="name w-50 text-right">
									{row.levels}
								</td>
							</tr>
						</table>
					</li>
				))
				: 'No records found !' }
				</ul>
			</div>
		</div>);
};
export default WorklowSidebarContent;