import React, { useState } from 'react';
import Schema from '../../../schema/AppSchema';
import { useMutation } from '@apollo/client';
import ActionsComponents from '../../../components/actionsComponent/ActionsComponents';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';

const createOneQuery = Schema.createOneDocumentCategory;
const updateOneQuery = Schema.updateOneDocumentCategory;

const DocumentCategoryActions = (props) => {
    const [state, setState] = useState({ buttonStatus: '' });
    const [versionNo, setVersionNo] = useState(props?.version_no);
    const [versionUser, setVersionUser] = useState(props?.version_user);
    const [currentAction, setCurrentAction] = useState('');
    const [showWfBtns, setWFBtns] = useState(true);

    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.COMMON_MASTERS.find((a) => loc[1].includes(a.routerLink));

    const [createDocCategory] = useMutation(createOneQuery, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let growlMsg =
                    currentAction?.name && currentAction?.name === 'Submit'
                        ? 'Document Category Master submitted successfully'
                        : 'Document Category Master created successfully';
                props.showGrowl(growlMsg, 'success');
                if (currentAction?.name && currentAction?.name === 'Submit') {
                    setWFBtns(currentAction?.name && currentAction?.name === 'Submit' ? false : true);
                }
            }
        },
        onError: () => {
            props.handleLoader(false);
        }
    });

    const [updateDocCategory] = useMutation(updateOneQuery, {
        variables: { where: { id: props.recID } },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let growlMsg = currentAction?.name
                    ? currentAction?.name + ' action taken successfully'
                    : 'Document Category Master updated successfully';
                props.showGrowl(growlMsg, 'success');
                setVersionNo(parseInt(data.updateOneDocumentCategory.version_no));
                setVersionUser(data.updateOneDocumentCategory.version_user);
                setWFBtns(true);
            }
        },
        onError: () => {
            props.handleLoader(false);
        }
    });

    const checkValidations = () => {
        if (props.showUpdateInfo) {
            return props.showUpdateInfo;
        }
    };

    const getDisplayData = () => {
        return {
            name: props.formState?.name,
            upload_type: props.formState?.upload_type,
            file_type: props.formState?.file_type.toString(),
            file_size: props.formState?.file_size.enum_value,
            is_active: props.formState?.is_active
        };
    };

    const getValue = (value, isTempSave) => {
        if (!isTempSave && (versionNo > 0 || (!versionNo && props?.version_no > 0))) return { set: value };
        return value;
    };

    const prepareMappings = () => {
        let recs = props.selectedRows.map((val) => {
            return { fmis_function_code: val.value4, is_mandatory: val.is_mandatory ? val.is_mandatory : false };
        });
        return { create: recs };
    };

    const getRecord = (isTempSave = true) => {
        let recordObj = props.formState;
        let queryObj = {
            name: getValue(recordObj?.name, isTempSave),
            upload_type: getValue(recordObj?.upload_type, isTempSave),
            file_type: getValue(recordObj?.file_type?.toString(), isTempSave),
            file_size: getValue(parseFloat(recordObj?.file_size?.enum_value), isTempSave),
            is_active: getValue(recordObj?.is_active, isTempSave)
        };
        queryObj['mappings'] = prepareMappings();
        if (versionNo || props?.version_no) queryObj.version_no = versionNo || props?.version_no;
        if (props.entity_id) queryObj.entity_id = props.entity_id;
        if (props.recID) queryObj.id = props.recID;
        return queryObj;
    };

    const handleSubmitORSave = (status, wfParams, action) => {
        let isValid = checkValidations();
        if (isValid) {
            props.showGrowl(isValid, 'error');
            return;
        }
        setCurrentAction(action);
        let dataObj = getRecord(false);
        dataObj.wf_params = wfParams;
        delete dataObj.entity_id;
        delete dataObj.version_no;

        state.buttonStatus = status;
        setState({ ...state, buttonStatus: state.buttonStatus });
        props.handleLoader(true);

        if (versionNo > 0 || (!versionNo && props.version_no > 0)) {
            delete dataObj.id;
            setWFBtns(false);
            updateDocCategory({
                variables: {
                    data: dataObj
                }
            });
            return;
        }
        createDocCategory({
            variables: {
                data: dataObj
            }
        });
    };

    const disableButton = () => {
        let obj = props.formState;
        return (
            Object.keys(props.errorStatus).some((k) => props.errorStatus[k]) ||
            !obj?.name ||
            !obj?.upload_type ||
            !obj?.file_size?.enum_value ||
            obj?.file_type?.length <= 0
        );
    };

    return (
        <div>
            {showWfBtns && (
                <ActionsComponents
                    navigateToEdit={() => props.navigateToEdit()}
                    mode={props.formType}
                    setActionsStatus={props.setActionsStatus}
                    canDisable={disableButton()}
                    data={props.formState}
                    onSave={(wfParams, action) => handleSubmitORSave('Approved', wfParams, action)}
                    onCancel={() => props.handleCancel()}
                    version_no={versionNo || props.version_no}
                    version_user={versionUser || props.formState?.version_user}
                    privRequired={Privileges?.UPDATE}
                    module="System Administration"
                    fmis_function="SYS_DOC_CATEGORY"
                    fmis_sub_function="NA"
                    officeCode={'ALL'}
                    minFieldsRequired={false}
                    getRecord={getRecord}
                    getDisplayData={getDisplayData}
                    handleLoader={props.handleLoader}
                    screenName={'Document Category Master'}
                    source_id={props.recID}
                />
            )}
        </div>
    );
};

export default DocumentCategoryActions;
