import React from 'react';
import { roundFloat } from '../../services/commonService';
import { getDateOnly } from '../../util';
import '../../styles/utils.scss';
const PrintTable = (props) => {
    let columns = props.columns;
    let rows = props.rows;
    const setTableData = (row, field) => {
        let x = field && field.split('.');
        let val = x ? '-' : '';
        if (x && x.length === 5) {
            val = (row[x[0]]?.[x[1]]?.[x[2]]?.[x[3]]?.[x[4]] ? row[x[0]][x[1]][x[2]][x[3]][x[4]] : '-').toString();
        } else if (x && x.length === 4) {
            val = (row[x[0]]?.[x[1]]?.[x[2]]?.[x[3]] ? row[x[0]][x[1]][x[2]][x[3]] : '-').toString();
        } else if (x && x.length === 3) {
            val = (row[x[0]]?.[x[1]]?.[x[2]] ? row[x[0]][x[1]][x[2]] : '-').toString();
        } else if (x && x.length === 2) {
            val = (row[x[0]]?.[x[1]] ? row[x[0]]?.[x[1]] : '-').toString();
        } else if (x && x.length === 1) {
            val = (row[x[0]] === undefined || row[x[0]] === null ? '-' : row[x[0]]).toString();
        } else {
            val = (row[field] ? row[field] : '-').toString();
        }
        return field === 'release_date' ? getDateOnly(new Date(val)) : val;
    };

    return (
        <div className="pdf-table">
            <table>
                {props.table === 'Journey_Details' && props.headerGroup && (
                    <tr className="table-footer">
                        <td colSpan={2}>
                            <b>Departure</b>
                        </td>
                        <td colSpan={2}>
                            <b>Arrival</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                )}
                <tr>
                    {columns.map((ele, ind) => {
                        return (
                            <th style={{ width: ele.width }} key={ind}>
                                {ele.header}
                            </th>
                        );
                    })}
                </tr>
                {rows && rows.length > 0 ? (
                    rows.map((row, ind) => (
                        <tr className="word-break-word" key={ind}>
                            {columns.map((col1, inde) => (
                                <td key={inde}>{setTableData(row, col1.field)}</td>
                            ))}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={columns.length} className="f-12">
                            No Records Found.
                        </td>
                    </tr>
                )}

                {rows.length > 0 && props.table === 'Bill_Details' && props.footerGroup && (
                    <tr className="table-footer">
                        <td
                            colSpan={
                                props.billInfo?.billType === 'Security Deposit Refund' ||
                                props.type === 'other_bills' ||
                                (props.type === 'contract_bills' && props.billInfo.on_total_val)
                                    ? 3
                                    : 4
                            }
                        >
                            <b>Total</b>
                        </td>
                        <td>
                            {props.type === 'contract_bills' && (
                                <b>
                                    {rows.reduce((total, detail) => {
                                        return total + detail.grossBill;
                                    }, 0)}
                                </b>
                            )}
                            {(props.type === 'other_bills' || props.type === 'imprest_adjustment') && (
                                <b>
                                    {rows.reduce((total, detail) => {
                                        return total + detail.amount;
                                    }, 0)}
                                </b>
                            )}
                        </td>
                        {((props.type === 'contract_bills' && props.billInfo.on_total_val) ||
                            props.type === 'other_bills' ||
                            props.type === 'imprest_adjustment') && <td></td>}
                        {props.type === 'contract_bills' && !props.billInfo.on_total_val && <td></td>}
                        {props.type === 'contract_bills' && (
                            <td>
                                <b>
                                    {rows.reduce((total, detail) => {
                                        return roundFloat(total + detail.netPayment);
                                    }, 0)}
                                </b>
                            </td>
                        )}
                    </tr>
                )}
                {rows.length > 0 && props.table === 'Payment_Status' && props.footerGroup && (
                    <tr className="table-footer">
                        <td colSpan={4}>
                            <b>Total</b>
                        </td>
                        <td>
                            <b>
                                {rows.reduce((total, bill) => {
                                    return total + bill?.payee?.amount;
                                }, 0)}
                            </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                )}
                {rows.length > 0 && props.table === 'Deductions' && props.footerGroup && (
                    <tr className="table-footer">
                        <td colSpan={3}>
                            <b>Total</b>
                        </td>
                        <td>
                            <b>
                                {rows.reduce((total, detail) => {
                                    return total + detail.amount;
                                }, 0)}
                            </b>
                        </td>
                        <td></td>
                    </tr>
                )}
                {rows.length > 0 && props.table === 'Journey_Details' && props.footerGroup && (
                    <tr className="table-footer">
                        <td colSpan={9}>
                            <b>Total</b>
                        </td>
                        {/* <td>
                            <b>
                                {rows.reduce((total, detail) => {
                                    return total + detail.fare_paid;
                                }, 0)}
                            </b>
                        </td> */}
                        <td>
                            <b>
                                {rows.reduce((total, detail) => {
                                    return total + detail.claim_allowed;
                                }, 0)}
                            </b>
                        </td>
                    </tr>
                )}
                {rows.length > 0 && props.table === 'Transportation_Charges_of_Personnel_Effect' && props.footerGroup && (
                    <tr className="table-footer">
                        <td colSpan={6}>
                            <b>Total</b>
                        </td>
                        <td>
                            <b>
                                {rows.reduce((total, detail) => {
                                    return total + detail.amount;
                                }, 0)}
                            </b>
                        </td>
                    </tr>
                )}
                {rows.length > 0 && props.table === 'Transportation_Charges_of_Private_Conveyance' && props.footerGroup && (
                    <tr className="table-footer">
                        <td colSpan={6}>
                            <b>Total</b>
                        </td>
                        <td>
                            <b>
                                {rows.reduce((total, detail) => {
                                    return total + detail.amount;
                                }, 0)}
                            </b>
                        </td>
                    </tr>
                )}
                {rows.length > 0 && props.table === 'Stay_Details' && props.footerGroup && (
                    <tr className="table-footer">
                        <td colSpan={3}>
                            <b>Total</b>
                        </td>
                        <td>
                            <b>
                                {rows.reduce((total, detail) => {
                                    return total + detail.halting_charge;
                                }, 0)}
                            </b>
                        </td>
                    </tr>
                )}
                {rows.length > 0 && props.table === 'Day_Allowance' && props.footerGroup && (
                    <tr className="table-footer">
                        <td colSpan={3}>
                            <b>Total</b>
                        </td>
                        <td>
                            <b>
                                {rows.reduce((total, detail) => {
                                    return total + detail.amount;
                                }, 0)}
                            </b>
                        </td>
                    </tr>
                )}
                {rows.length > 0 && props.table === 'Transfer_Charges' && props.footerGroup && (
                    <tr className="table-footer">
                        <td colSpan={4}>
                            <b>Total</b>
                        </td>
                        <td>
                            <b>
                                {rows.reduce((total, detail) => {
                                    return total + detail.amount;
                                }, 0)}
                            </b>
                        </td>
                    </tr>
                )}
                {rows.length > 0 && props.table === 'Family_Members' && props.footerGroup && (
                    <tr className="table-footer">
                        <td>
                            <b>Total No.of Members</b>
                        </td>
                        <td>
                            <b>{rows.length}</b>
                        </td>
                        <td></td>
                    </tr>
                )}
            </table>
        </div>
    );
};

export default PrintTable;
