import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import '../../styles/Dialog.scss';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../InputComponent/InputComponent';
import * as FormValidation from '../../Validation';

export function AddressModal(props) {
	const [errors, setErrors] = useState(false);
	const [state, setState] = useState({
		isAddNew: false
	});
	const [locationList, setLocationList] = useState(props.locationList);
	const [formState, setFormState] = useState({
		region: props.currentAddressObj?.state ? props.currentAddressObj?.state : '',
		regionName: props.currentAddressObj?.state_name ? props.currentAddressObj?.state_name : '',
		location: props.currentAddressObj?.district ? props.currentAddressObj?.district : '',
		locationName: props.currentAddressObj?.district_name ? props.currentAddressObj?.district_name : ''
	});

	const handleChange = (ev, key) => {
		if (key === 'region') {
			let val = props.stateList?.find((a) => a.id === ev);
			setLocationList(val?.locations || []);
			if (val && props.stateList?.length > 0) {
				formState.regionName = val?.name || '';
			} else {
				formState.regionName = ev;
			}
		}
		if (key === 'location') {
			let locVal = locationList?.find((a) => a.id === ev);
			if (locVal && locationList?.length > 0) {
				formState.locationName = locVal?.name || '';
			} else {
				formState.locationName = ev;
			}
		}
		setFormState({
			...formState,
			[key]: ev
		});
		validateFeild(ev, key);
	};

	const validateFeild = (ev, key) => {
		let err = {};
		err = FormValidation.validateFileld('required', key, ev);
		let preverrors = { ...errors, ...err };
		setErrors(preverrors);
	};

	const handleAddNew = () => {
		setFormState({
			region: '',
			regionName: '',
			location: '',
			locationName: ''
		});
		setState({ ...state, isAddNew: true });
	};

	const isDisplay = (list) => {
		return (
			state.isAddNew ||
			(!state.isAddNew && list?.length <= 0) ||
			(formState.region && list?.length > 0 && !list.find((a) => a.id === formState.region))
		);
	};

	return (
		<Modal visible={props.showModal} onCancel={props.handleCancel} className="common_css" backdropClosable={false}>
			<ModalHeader>Address Details</ModalHeader>
			<ModalBody>
				<>
					<div className="row">
						<div className="col-md-6">
							<b className="f-14">
								Region / State:
								<span className="icon-primary p-l-5">*</span>
							</b>
						</div>
						<div className="col-md-6">
							{props.addressType === 'district' ? (
								formState.regionName
							) : (
								<>
									{isDisplay(props.stateList) ? (
										<InputComponent
											inputBoxSize="sm"
											name="region"
											type="text"
											value={formState.region}
											hasError={errors && errors.region}
											errMsg="Please enter valid Region / State"
											onChange={(e) => handleChange(e, 'region')}
											placeholder="Enter Region / State"
											validations={['requiured']}
											maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
										/>
									) : (
										<Dropdown
											name="states"
											appendTo={document.self}
											value={formState.region}
											filter={true}
											filterBy="name"
											onChange={(e) => handleChange(e.value, 'region')}
											options={props.stateList}
											placeholder="Select Region / State"
											filterPlaceholder="Search"
											filterMatchMode="startsWith"
											className="w-100"
											optionLabel="name"
											optionValue="id"
											resetFilterOnHide={true}
										/>
									)}
								</>
							)}
						</div>
					</div>
					<div className="col-md-6 d-flex align-center fs-14">
						{!isDisplay(props.stateList) && props.addressType === 'state' ? (
							<span className="primary-color pointer" onClick={() => handleAddNew()}>
								+ Add New
							</span>
						) : (
							''
						)}
					</div>

					{
						<div className="row m-t-10">
							<div className="col-md-6">
								<b className="f-14 p-t-5">
									Location / City:
									<span className="icon-primary p-l-5">*</span>
								</b>
							</div>
							<div className="col-md-6">
								{isDisplay(locationList) ? (
									<InputComponent
										inputBoxSize="sm"
										name="location"
										type="text"
										value={formState.location}
										hasError={errors && errors.location}
										errMsg="Please enter valid Location / City"
										onChange={(e) => handleChange(e, 'location')}
										placeholder="Enter Location / City"
										maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
									/>
								) : (
									<Dropdown
										name="location"
										appendTo={document.self}
										value={formState.location}
										filter={true}
										filterBy="name"
										onChange={(e) => handleChange(e.value, 'location')}
										options={locationList}
										placeholder="Select Location / City"
										filterPlaceholder="Search"
										filterMatchMode="startsWith"
										className="w-100"
										optionLabel="name"
										optionValue="id"
										resetFilterOnHide={true}
									/>
								)}
							</div>
							<div className="col-md-6 d-flex align-center fs-14">
								{!isDisplay(locationList) ? (
									<span className="primary-color pointer" onClick={() => handleAddNew()}>
										+ Add New
									</span>
								) : (
									''
								)}
							</div>
						</div>
					}
				</>
			</ModalBody>
			<ModalFooter className={props?.loading ? 'mask p-10' : 'p-10'}>
				<Button
					size="sm"
					kind="primary"
					className="m-r-10"
					disabled={errors.region || errors.location}
					onClick={() => {
						props.handleSave(formState, state.isAddNew);
					}}
				>
					Save
				</Button>
				<Button
					size="sm"
					kind="secondary"
					className=""
					onClick={() => {
						props.handleCancel();
					}}
				>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export default AddressModal;
