let currency = localStorage.getItem('CURRENCY') ? localStorage.getItem('CURRENCY') : 'INR';
const FormSchema = {
    FLD_CC_CONTCT_NAME: { name: 'Contract Name', key: 'contractName', is_mandatory: 1 },
    FLD_CC_CONTCT_DESC: { name: 'Description', key: 'description', is_mandatory: 1 },
    FLD_CC_CONTCT_SUPPLIER: { name: 'Supplier', key: 'supplierName', is_mandatory: 1 },
    FLD_CC_CONTCT_CONTACT: { name: 'Cotact', key: 'supplierContact', is_mandatory: 1 },

    // General contract information
    FLD_GCI_SANC_AGNCY: {
        name: 'Sanctioning Agency',
        key: 'sanct_agency_id',
        desc: 'Sanctioning Agency',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_GCI_IMPL_AGNCY: {
        name: 'Implementing / Purchasing Agency',
        key: 'impl_agency_id',
        desc: 'Implementing / Purchasing Agency',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_GCI_SIGN_DTAE: {
        name: 'Signing Date',
        key: 'signing_date',
        desc: 'Signing Date',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_GCI_CLOSE_DATE: {
        name: 'Closing Date',
        key: 'closing_date',
        desc: 'Closing Date',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_GCI_GOVT_REF_NO: {
        name: 'Contract/Work Order No',
        key: 'govt_ref',
        desc: 'Contract/Work Order No',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_GCI_PRJCT_NAME_ID: {
        name: 'Project Name / Project ID',
        key: 'project',
        desc: 'Project Name / Project ID',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_GCI_ADMN_REF_NO: {
        name: 'Adm. Approval/ F&CC Approval Ref No',
        key: 'adm_ref',
        desc: 'Adm. Approval/ F&CC Approval Ref No',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_GCI_TECH_REF_NO: {
        name: 'Technical Sanction Ref No',
        key: 'tech_ref',
        desc: 'Technical Sanction Ref No',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_GCI_PROCU_REF_NO: {
        name: 'Procurement/NIT Ref No',
        key: 'proc_ref',
        desc: 'Procurement/NIT Ref No',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    // Contract Details
    FLD_CD_SUP_CAT: {
        name: 'Supply Category',
        key: 'supply_cat',
        desc: 'Supply Category',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_HOA: {
        name: 'HOA',
        key: 'hoa',
        desc: 'HOA',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_CV_INC_TAXES: {
        name: 'Contract Value Incl of Taxes',
        key: 'cv_inc_tax',
        desc: 'Contract Value Incl of Taxes',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_CV_EXCL_TAXES: {
        name: 'Contract Value Excl of Taxes',
        key: 'cv_eccl_tax',
        desc: 'Contract Value Excl of Taxes',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    FLD_CD_SUP_CATG: {
        name: 'Supply Category',
        key: 'supply_cat',
        desc: 'Supply Category',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_SUPP_HOA: {
        name: 'HOA',
        key: 'supply_cat',
        desc: 'HOA',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_FIN_SANC_INR: {
        name: 'Financial Sanction (' + currency + ')',
        key: 'supply_cat',
        desc: 'Financial Sanction (' + currency + ')',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_INC_TAX: {
        name: 'Contract Value Incl of Taxes (' + currency + ')',
        key: 'supply_cat',
        desc: 'Contract Value Incl of Taxes (' + currency + ')',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_EXCL_TAX: {
        name: 'Contract Value Excl of Taxes (' + currency + ')',
        key: 'supply_cat',
        desc: 'Contract Value Excl of Taxes (' + currency + ')',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_ADMIS_VAR: {
        name: 'Admissible Variance(%)',
        key: 'supply_cat',
        desc: 'Admissible Variance',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    FLD_CD_FIN_SANC_YEAR: {
        name: 'Year',
        key: 'supply_cat',
        desc: 'Year',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_FIN_SANC_REF: {
        name: 'Financial Sanction Ref',
        key: 'supply_cat',
        desc: 'Financial Sanction Ref',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_FIN_SANC_REF_INR: {
        name: 'Financial Sanction (' + currency + ')',
        key: 'supply_cat',
        desc: 'Financial Sanction (' + currency + ')',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    FLD_CD_ADV_TYPE: {
        name: 'Advance Type',
        key: 'supply_cat',
        desc: 'Advance Type',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_ADV_HOA: {
        name: 'HOA',
        key: 'supply_cat',
        desc: 'HOA',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_ADV_RATE: {
        name: 'Advance Rate(%)',
        key: 'supply_cat',
        desc: 'Advance Rate',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_ADV_AMOUNT: {
        name: 'Advance Amount',
        key: 'supply_cat',
        desc: 'Advance Amount',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_ADV_RECVRY_MTD: {
        name: 'Advance Recovery Method',
        key: 'supply_cat',
        desc: 'Advance Recovery Method',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    FLD_CD_OTH_DEDUCTION: {
        name: 'Deduction',
        key: 'supply_cat',
        desc: 'Deduction',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_OTH_DED_RATE: {
        name: 'Type of Deduction',
        key: 'supply_cat',
        desc: 'Rate of Deduction',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_OTH_DED_AMOUNT: {
        name: 'Amount (' + currency + ')',
        key: 'supply_cat',
        desc: 'Amount (' + currency + ')',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_OTH_DED_REMARKS: {
        name: 'Remarks',
        key: 'supply_cat',
        desc: 'Remarks',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    FLD_CD_PAY_MILESTONE: {
        name: 'Payment Milestone',
        key: 'supply_cat',
        desc: 'Payment Milestone',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_PAY_DUE_DATE: {
        name: 'Due Date',
        key: 'supply_cat',
        desc: 'Due Date',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_PAY_AMT_EXCL_TAX: {
        name: 'Amount Excl of Taxes (' + currency + ')',
        key: 'supply_cat',
        desc: 'Amount Excl of Taxes (' + currency + ')',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_PAY_AMT_INC_TAX: {
        name: 'Amount Incl of Taxes (' + currency + ')',
        key: 'supply_cat',
        desc: 'Amount Incl of Taxes (' + currency + ')',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_PAY_ADV_RECOV: {
        name: 'Advance Recovery (' + currency + ')',
        key: 'supply_cat',
        desc: 'Advance Recovery (' + currency + ')',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_PAY_SDD: {
        name: 'Security Deposit Deduction (' + currency + ')',
        key: 'supply_cat',
        desc: 'Security Deposit Deduction (' + currency + ')',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_PAY_OD: {
        name: 'Other Deduction (' + currency + ')',
        key: 'supply_cat',
        desc: 'Other Deduction (' + currency + ')',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_PAY_NET_PAY_AMT: {
        name: 'Net Payment Amount (' + currency + ')',
        key: 'supply_cat',
        desc: 'Net Payment Amount (' + currency + ')',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    // ---- security deposit deduction----
    FLD_CD_SDD_RATE: {
        name: 'Security Deposit Deduction Rate(%)',
        key: 'scc_depo_deduc_rate',
        desc: 'Security Deposit Deduction Rate',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_CD_SDD_REFUND_TIME: {
        name: 'Security Deposit Refund Time',
        key: 'scc_depo_refund_time',
        desc: 'Security Deposit Refund Time',
        is_mandatory: -1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    // Performance bank guarantee
    FLD_PBG_BNK_NME: {
        name: 'Bank Name',
        key: 'bnk_name',
        desc: 'Bank Name',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_PBG_BRNCH_NME: {
        name: 'Branch Name',
        key: 'brnch_name',
        desc: 'Branch Name',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_PBG_BNK_GAUR_NO: {
        name: 'Bank Guarantee Number',
        key: 'bnk_gurantee_no',
        desc: 'Bank Guarantee Number',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_PBG_EXP_DATE: {
        name: 'Expiry Date',
        key: 'expiry_date',
        desc: 'Expiry Date',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_PBG_AMT_BNK_GAUR: {
        name: 'Amount of Bank Guarantee',
        key: 'amt_bnk_gurantee',
        desc: 'Amount of Bank Guarantee',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },

    // Payment Terms
    FLD_PT_PAY_TERMS: {
        name: 'Payment term',
        key: 'pay_term',
        desc: 'Payment term',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    },
    FLD_PT_PAY_MODE: {
        name: 'Payment Mode',
        key: 'pay_mode',
        desc: 'Payment Mode',
        is_mandatory: 1,
        is_visible: 1,
        validation: { min: '', max: '', regex: '', err_msg: '' }
    }
};
export default FormSchema;
